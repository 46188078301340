import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';
import { FetchIssuesInput } from '../../types/issues-types';
import query from './get-exported-tools-issues.gql';

const getExportedToolsIssues = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<GetExportIssuesInput, Nullable<GetIssuesCSVExportRes>> => {
  return {
    execute: async (getIssuesInput: GetExportIssuesInput) => {
      try {
        const results = await client.query<QueryResponse>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { getIssuesInput },
        });
        if (!results.data) return null;

        return results.data.getIssuesToolsCSVExport;
      } catch (error) {
        logger.error('Failed to fetch exported csv tools issues', error);
        return null;
      }
    },
  };
};

type GetExportIssuesInput = {
  exportsOptions?: {
    isDemoEnabled?: boolean;
  };
} & Pick<
  FetchIssuesInput,
  'dateRange' | 'filters' | 'scanID' | 'owners' | 'sort'
>;

interface GetIssuesCSVExportRes {
  requestId: string;
}
export interface QueryResponse {
  getIssuesToolsCSVExport: Nullable<GetIssuesCSVExportRes>;
}

export default getExportedToolsIssues;
