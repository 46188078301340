import { IconType } from 'ox-common-types';
import { SVGProps } from 'react';
import { SiGithubactions } from 'react-icons/si';
import { BsFillDatabaseFill } from 'react-icons/bs';
import { ReactComponent as ACRIcon } from '../../assets/acr-icon.svg';
import { ReactComponent as AnsibleIcon } from '../../assets/ansible-icon.svg';
import { ReactComponent as GcpArtifactIcon } from '../../assets/artifact_registry.svg';
import { ReactComponent as AzureIcon } from '../../assets/azure-pipeline-icon.svg';
import { ReactComponent as BitbucketIcon } from '../../assets/bitBucket-icon.svg';
import { ReactComponent as BuildkiteLogo } from '../../assets/buildkite-icon.svg';
import { ReactComponent as CircleCiLogo } from '../../assets/circle-ci-logo.svg';
import { ReactComponent as CloudBuildLogo } from '../../assets/cloud-build-icon.svg';
import { ReactComponent as GcpContainerIcon } from '../../assets/container_registry.svg';
import { ReactComponent as DockerIcon } from '../../assets/dockerhub-icon.svg';
import { ReactComponent as DroneCiLogo } from '../../assets/drone-ci-icon.svg';
import { ReactComponent as EcrIcon } from '../../assets/ecr-icon.svg';
import { ReactComponent as FileIcon } from '../../assets/file.svg';
import { ReactComponent as GenericIcon } from '../../assets/generic-icon.svg';
import { ReactComponent as GitlabIcon } from '../../assets/gitlab-icon.svg';
import { ReactComponent as HelmIcon } from '../../assets/helm-logo.svg';
import { ReactComponent as JenkinsIcon } from '../../assets/jenkins.svg';
import { ReactComponent as JfrogIcon } from '../../assets/jfrog-icon.svg';
import {
  ReactComponent as KubernetesClusterIcon,
  ReactComponent as KubernetesIcon,
} from '../../assets/kubernetes-icon.svg';
import { ReactComponent as KubernetesDeploymentIcon } from '../../assets/kubernetesDeploy-icon.svg';
import { ReactComponent as KubernetesIngressIcon } from '../../assets/kubernetesIngress-icon.svg';
import { ReactComponent as KubernetesJobIcon } from '../../assets/kubernetesJob-icon.svg';
import { ReactComponent as KubernetesPersistentVolumeIcon } from '../../assets/kubernetesPersistent-icon.svg';
import { ReactComponent as KubernetesPodIcon } from '../../assets/kubernetesPod-icon.svg';
import { ReactComponent as KubernetesSecretIcon } from '../../assets/kubernetesSecret-icon.svg';
import { ReactComponent as KubernetesServiceIcon } from '../../assets/kubernetesService-icon.svg';
import { ReactComponent as LambdaIcon } from '../../assets/lambda-icon.svg';
import { ReactComponent as NpmIcon } from '../../assets/npm-logo.svg';
import { ReactComponent as ServerlessIcon } from '../../assets/serverless.svg';
import { ReactComponent as TeamCityLogo } from '../../assets/teamCity-icon.svg';
import { ReactComponent as TerraformIcon } from '../../assets/terraform-logo.svg';
import { ReactComponent as TravisIcon } from '../../assets/travis.svg';
import { ReactComponent as ZipIcon } from '../../assets/zip-icon.svg';
import {
  AppFlowToolTipItem,
  ApplicationFlowItemType,
  ArtifactorySecEventSystem,
  ArtifactorySecEventType,
  CiCdTypes,
  KubernetesSubTypes,
  OrchestratorTypes,
} from '../types/app-flow-types';
import {
  CloudDeploymentServices,
  CloudDeploymentTypes,
} from '../types/cloud-deployment-types';
import {
  cloudDeploymentAWSIconMap,
  cloudDeploymentAzureIconMap,
  cloudDeploymentGCPIconMap,
} from './cloud-deployment-icons-utils';

export const generateTypesMap = (flows?: AppFlowToolTipItem[]) => {
  const flowsMap = new Map<string, AppFlowToolTipItem[]>();
  if (!flows) return null;

  flows.forEach(flow => {
    if (flowsMap.has(flow.subType)) {
      flowsMap.get(flow.subType)?.push({
        name: flow.name,
        type: flow.type,
        subType: flow.subType,
        date: flow.date,
        hash: flow.hash,
        size: flow.size,
        system: flow.system,
        location: flow.location,
        link: flow.link,
        linkName: flow.linkName,
        cluster: flow.cluster,
        k8sType: flow.k8sType,
        region: flow.region,
        imageName: flow.imageName,
      });
    } else {
      flowsMap.set(flow.subType, [
        {
          name: flow.name,
          type: flow.type,
          subType: flow.subType,
          date: flow.date,
          hash: flow.hash,
          size: flow.size,
          system: flow.system,
          location: flow.location,
          link: flow.link,
          linkName: flow.linkName,
          cluster: flow.cluster,
          k8sType: flow.k8sType,
          region: flow.region,
          imageName: flow.imageName,
        },
      ]);
    }
  });
  return flowsMap;
};

export const generateCategoryToolTipIcon = (
  type?: string,
  subType?: string,
) => {
  if (subType) {
    switch (type) {
      case ApplicationFlowItemType.Kubernetes:
        return kubernetesIconMap.get(subType);
      case ApplicationFlowItemType.Artifacts:
        return artifactIconMap.get(subType);
      case ApplicationFlowItemType.Orchestrator:
        return orchestratorIconMap.get(subType);
      case CloudDeploymentTypes.AWS:
        return cloudDeploymentAWSIconMap.get(subType.toLowerCase());
      case CloudDeploymentTypes.Azure:
        return cloudDeploymentAzureIconMap.get(subType);
      case CloudDeploymentTypes.GCP:
        return cloudDeploymentGCPIconMap.get(subType);
    }
  }
};

export const getRegistryicon = (subType: string) => {
  return artifactorySecEventTypeconMap.get(subType);
};

export const getCloudDeploymentType = (type?: CloudDeploymentTypes) => {
  switch (type) {
    case CloudDeploymentTypes.AWS:
      return CloudDeploymentServices.AWSServices;
    case CloudDeploymentTypes.Azure:
      return CloudDeploymentServices.AzureServices;
    case CloudDeploymentTypes.GCP:
      return CloudDeploymentServices.GCPServices;
    default:
      return '';
  }
};

export const createKubernetesTypes = (
  kubernetesItems: AppFlowToolTipItem[],
) => {
  return [
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.Cluster,
    ),
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.CronJob,
    ),
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.Deployment,
    ),
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.Ingress,
    ),
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.PersistentVolume,
    ),
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.Pod,
    ),
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.Secret,
    ),
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.Service,
    ),
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.Unknown,
    ),
    kubernetesItems.filter(
      kubernetes => kubernetes.subType === KubernetesSubTypes.K8Container,
    ),
  ];
};

export const createRegistrySystems = (registryItems: AppFlowToolTipItem[]) => {
  return [
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.ECR,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.NPM,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.Generic,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.GitlabRegistry,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.GCPArtifact,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.GCPContainer,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.LAMBDA,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.JfrogRegistry,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.DockerHub,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.ACR,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.DockerContainer,
    ),
    registryItems.filter(
      registry =>
        registry.system === ArtifactorySecEventSystem.JFrogArtifactory,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.GCR,
    ),
    registryItems.filter(
      registry => registry.system === ArtifactorySecEventSystem.QueryIO,
    ),
  ];
};

export const checkSystemsRegistry = (registry: AppFlowToolTipItem[][]) => {
  let counterRegistry = registry.length;
  registry.forEach(item => {
    if (item.length === 0) {
      counterRegistry = counterRegistry - 1;
    }
  });
  if (counterRegistry === 0) {
    return false;
  }
  return true;
};

export const createOrchestratorsTypes = (
  orchestratorsItems: AppFlowToolTipItem[],
) => {
  return [
    orchestratorsItems.filter(
      orchestrator => orchestrator.system === OrchestratorTypes.Ansible,
    ),
    orchestratorsItems.filter(
      orchestrator => orchestrator.system === OrchestratorTypes.Helm,
    ),
    orchestratorsItems.filter(
      orchestrator => orchestrator.system === OrchestratorTypes.Terraform,
    ),
    orchestratorsItems.filter(
      orchestrator => orchestrator.system === OrchestratorTypes.TerraformPlan,
    ),
    orchestratorsItems.filter(
      orchestrator => orchestrator.system === OrchestratorTypes.Unknown,
    ),
  ];
};

export const artifactIconMap = new Map<string | undefined, IconType>([
  [ArtifactorySecEventSystem.ECR, EcrIcon],
  [ArtifactorySecEventSystem.NPM, NpmIcon],
  [ArtifactorySecEventSystem.Generic, GenericIcon],
  [ArtifactorySecEventSystem.GitlabRegistry, GitlabIcon],
  [ArtifactorySecEventSystem.GCPArtifact, GcpArtifactIcon],
  [ArtifactorySecEventSystem.GCPContainer, GcpContainerIcon],
  [ArtifactorySecEventSystem.LAMBDA, LambdaIcon],
  [ArtifactorySecEventSystem.JfrogRegistry, JfrogIcon],
  [ArtifactorySecEventSystem.DockerHub, DockerIcon],
  [ArtifactorySecEventSystem.ACR, ACRIcon],
  [ArtifactorySecEventSystem.JFrogArtifactory, JfrogIcon],
  [ArtifactorySecEventSystem.GCR, GcpContainerIcon],
  [ArtifactorySecEventSystem.QueryIO, BsFillDatabaseFill],
]);

export const artifactorySecEventTypeconMap = new Map<string, IconType>([
  [ArtifactorySecEventType.Docker, DockerIcon],
  [ArtifactorySecEventType.Serverless, ServerlessIcon],
  [ArtifactorySecEventType.Zip, ZipIcon],
  [ArtifactorySecEventType.Unknown, FileIcon],
  [ArtifactorySecEventType.Generic, GenericIcon],
]);

export const kubernetesIconMap = new Map<string, IconType>([
  [KubernetesSubTypes.Deployment, KubernetesDeploymentIcon],
  [KubernetesSubTypes.Ingress, KubernetesIngressIcon],
  [KubernetesSubTypes.PersistentVolume, KubernetesPersistentVolumeIcon],
  [KubernetesSubTypes.Pod, KubernetesPodIcon],
  [KubernetesSubTypes.Secret, KubernetesSecretIcon],
  [KubernetesSubTypes.Service, KubernetesServiceIcon],
  [KubernetesSubTypes.CronJob, KubernetesJobIcon],
  [KubernetesSubTypes.Cluster, KubernetesClusterIcon],
  [KubernetesSubTypes.K8Container, KubernetesIcon],
  [KubernetesSubTypes.Kubernetes, KubernetesIcon],
]);

export const cicdIconsMap = new Map<
  CiCdTypes,
  React.FunctionComponent<SVGProps<SVGSVGElement>> | string | IconType
>([
  [CiCdTypes.GithubActions, SiGithubactions],
  [CiCdTypes.Gitlab, GitlabIcon],
  [CiCdTypes.GitLab, GitlabIcon],
  [CiCdTypes.Generic, GenericIcon],
  [CiCdTypes.AzurePipeLine, AzureIcon],
  [CiCdTypes.DroneCI, DroneCiLogo],
  [CiCdTypes.Jenkins, JenkinsIcon],
  [CiCdTypes.CircleCI, CircleCiLogo],
  [CiCdTypes.TravisCI, TravisIcon],
  [CiCdTypes.Buildkite, BuildkiteLogo],
  [CiCdTypes.BitbucketPipelines, BitbucketIcon],
  [CiCdTypes.CloudBuild, CloudBuildLogo],
  [CiCdTypes.TeamCity, TeamCityLogo],
]);

export const orchestratorIconMap = new Map<string, IconType>([
  [OrchestratorTypes.Helm, HelmIcon],
  [OrchestratorTypes.Terraform, TerraformIcon],
  [OrchestratorTypes.TerraformPlan, TerraformIcon],
  [OrchestratorTypes.Ansible, AnsibleIcon],
]);

export const isCloudDeploymentType = (type: string) => {
  if (
    type === CloudDeploymentTypes.AWS ||
    type === CloudDeploymentTypes.GCP ||
    type === CloudDeploymentTypes.Azure
  ) {
    return true;
  }
  return false;
};
