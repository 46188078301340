import {
  CategoryDisplayName,
  getCategoryByName,
} from '@oxappsec/ox-consolidated-categories';
import { AppPages, encodeForUrl } from 'app-navigator';
import { CategoryRiskNames } from 'ox-common-types';
import { snapshot } from 'valtio';
import { ConditionalFiltersCondition } from '../../../../../utilities/ox-filter-utils/src/filters-utils';
import ArtifactsStore from '../../artifacts/stores/artifacts-store';
import {
  SupplyChainResponseIssuesValues,
  SupplyChainResponseItem,
} from '../types/supply-chain-backend-types';
import {
  IssuesComponentType,
  SupplyChainIssueCounters,
  SupplyChainIssuesLabel,
  SupplyChainItem,
} from '../types/supplychain-types';

export const createSupplyChainIssues = (
  item: SupplyChainResponseItem,
): SupplyChainItem['issues'] => {
  const categoryName = item.header.label;
  const componentType = resolveComponentType(item);
  const counters = createCounters(item.issues.values, categoryName);
  const label = createLabel(item.issues.label, categoryName);

  return {
    componentType,
    counters,
    label,
  };
};

const resolveComponentType = ({
  issues,
  coverage,
}: SupplyChainResponseItem) => {
  if (issues.label) {
    return IssuesComponentType.Label;
  }
  const hasIssues =
    issues.values && Object.values(issues.values).some(value => value > 0);

  if (hasIssues) {
    return IssuesComponentType.Counters;
  } else if (!coverage || coverage.value === 0) {
    return IssuesComponentType.NotApplicable;
  } else if (!hasIssues && coverage?.value > 0) {
    return IssuesComponentType.Checked;
  }

  return undefined;
};

const createCounters = (
  valuesObj: SupplyChainResponseIssuesValues | undefined,
  categoryName: CategoryDisplayName,
) => {
  if (!valuesObj) {
    return [];
  }
  const category = getCategoryByName(categoryName);
  const categoryId = category?.id || categoryName;
  const { selectedArtifact } = snapshot(ArtifactsStore);
  const appId = selectedArtifact?.appDescription?.appName;

  return Object.keys(valuesObj).reduce((acc, severity) => {
    // ignore empty values
    if (!valuesObj[severity]) {
      return acc;
    }
    const filters = {
      categories: [`${categoryId}`],
      criticality: [CategoryRiskNames[severity]],
    };
    if (appId) {
      filters['appId'] = [appId];
    }

    const conditionalFilters = [
      {
        condition: ConditionalFiltersCondition.OR,
        fieldName: 'categories',
        values: [`${categoryId}`],
      },
      {
        condition: ConditionalFiltersCondition.OR,
        fieldName: 'criticality',
        values: [CategoryRiskNames[severity]],
      },
    ];
    if (appId) {
      conditionalFilters.push({
        condition: ConditionalFiltersCondition.OR,
        fieldName: 'apps',
        values: [appId],
      });
    }
    const conditionalFiltersString = encodeForUrl(conditionalFilters);
    // limit counters to 3
    if (acc.length < 3) {
      acc.push({
        severity: severity,
        value: valuesObj[severity],
        issuePageFilters: conditionalFiltersString,
      });
    }
    return acc;
  }, [] as SupplyChainIssueCounters[]);
};

const createLabel = (
  issueLabel: string | undefined,
  category: string,
): SupplyChainIssuesLabel | undefined => {
  if (!issueLabel) {
    return undefined;
  }

  return {
    text: issueLabel,
    link:
      category.toLowerCase() === 'source control'
        ? AppPages.Applications
        : undefined,
  };
};
