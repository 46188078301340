import { snapshot } from 'valtio';
import issuesService from '../../../../../../services';
import NotReachableVulnerabilitiesStore from '../stores/not-reachable-vulnerabilities-store';
import {
  setLoadingNotReachableVulns,
  setLoadingReachableVulns,
  setReachableContextOptions,
  setNotReachableOffset,
  setNotReachableSearchValue,
  setNotReachableTotal,
  setNotReachableVulnerabilities,
  setNotReachableContextOptions,
  setReachableOffset,
  setReachableSearchValue,
  setReachableTotal,
  setReachableVulnerabilities,
  setNotReachableSeverityFactors,
  setReachableSeverityFactors,
} from '../containers/store-actions';
import ReachableVulnerabilitiesStore from '../stores/reachable-vulnerabilities-store';
import IssuesStore from '../../../../../../../active-issues/stores/issues-store';
import { VulnerabilitiesStoreState, VulnerabilityTab } from '../types';
import { Order } from 'ox-common-types';

export const fetchNotReachableVulnerabilities = async (update?: boolean) => {
  setLoadingNotReachableVulns(true);

  if (update) {
    setNotReachableOffset(0);
  }
  const { selectedIssue } = snapshot(IssuesStore);
  if (selectedIssue) {
    const {
      offset,
      total,
      searchValue,
      severityFactors,
      orderField,
      orderDirection,
    } = snapshot(NotReachableVulnerabilitiesStore);
    if (total !== 0 && offset >= total) {
      setLoadingNotReachableVulns(false);
      return;
    }
    const data = await issuesService.getVulnerabilities.execute({
      issueId: selectedIssue.issueId,
      offset,
      tab: VulnerabilityTab.Indirect,
      search: searchValue,
      cveSeverityFactors: severityFactors,
      orderField,
      orderDirection,
    });
    if (data) {
      const { total, vulnerabilities, offset, severityFactorBreakdown } = data;
      setNotReachableTotal(total);
      setNotReachableVulnerabilities(vulnerabilities, update);
      setNotReachableOffset(offset);
      setNotReachableContextOptions(severityFactorBreakdown);
    }
  }
  setLoadingNotReachableVulns(false);
};

export const fetchReachableVulnerabilities = async (update?: boolean) => {
  setLoadingReachableVulns(true);
  const { selectedIssue } = snapshot(IssuesStore);
  if (update) {
    setReachableOffset(0);
  }
  if (selectedIssue) {
    const {
      offset,
      searchValue,
      total,
      severityFactors,
      orderField,
      orderDirection,
    } = snapshot(ReachableVulnerabilitiesStore);
    if (total !== 0 && offset >= total) {
      setLoadingReachableVulns(false);
      return;
    }
    const data = await issuesService.getVulnerabilities.execute({
      issueId: selectedIssue.issueId,
      offset,
      tab: VulnerabilityTab.Direct,
      search: searchValue,
      cveSeverityFactors: severityFactors,
      orderField,
      orderDirection,
    });
    if (data) {
      const { total, vulnerabilities, offset, severityFactorBreakdown } = data;
      setReachableTotal(total);
      setReachableVulnerabilities(vulnerabilities, update);
      setReachableOffset(offset);
      setReachableContextOptions(severityFactorBreakdown);
    }
  }
  setLoadingReachableVulns(false);
};

export const searchNotReachableVulnerabilities = (
  search: string,
  context: string[],
) => {
  setNotReachableSearchValue(search);
  setNotReachableSeverityFactors(context);
  fetchNotReachableVulnerabilities(true);
};

export const searchReachableVulnerabilities = (
  search: string,
  context: string[],
) => {
  setReachableSearchValue(search);
  setReachableSeverityFactors(context);
  fetchReachableVulnerabilities(true);
};

export const sortNotReachableVulnerabilities = (field?: string) => {
  const executeSort = () => fetchNotReachableVulnerabilities(true);
  field
    ? sortByField(NotReachableVulnerabilitiesStore, field, executeSort)
    : sortByDefault(NotReachableVulnerabilitiesStore, executeSort);
};
export const sortReachableVulnerabilities = (field?: string) => {
  const executeSort = () => fetchReachableVulnerabilities(true);
  field
    ? sortByField(ReachableVulnerabilitiesStore, field, executeSort)
    : sortByDefault(ReachableVulnerabilitiesStore, executeSort);
};

const sortByDefault = (
  store: VulnerabilitiesStoreState,
  executeSort: () => void,
) => {
  store.orderField = undefined;
  store.orderDirection = undefined;

  executeSort();
};

const sortByField = (
  store: VulnerabilitiesStoreState,
  field: string,
  executeSort: () => void,
) => {
  store.orderField = field;
  store.orderDirection =
    store.orderDirection === Order.Asc ? Order.Desc : Order.Asc;
  executeSort();
};
