import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  ConnectorAdditionalTabsSettingsResponse,
  GetAdditionalTabsSettingsInput,
} from '../../connectors-types';
import query from './get-additional-tabs-settings.gql';
import { ApolloClientCache } from 'ox-common-types';

const getAdditionalTabsSettings = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getAdditionalTabsSettingsInput: GetAdditionalTabsSettingsInput,
      disableCache?: boolean,
    ) => {
      try {
        const results = await client.query<{
          getConnectorAdditionalTabsSettings: ConnectorAdditionalTabsSettingsResponse;
        }>({
          query,
          variables: { getAdditionalTabsSettingsInput },
          fetchPolicy: ApolloClientCache.NoCache,
        });

        return results.data.getConnectorAdditionalTabsSettings;
      } catch (error) {
        logger.error(`Additional connector's resources query failed`, error);
        logger.error(error);
        throw error;
      }
    },
  };
};

export default getAdditionalTabsSettings;
