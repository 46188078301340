import { createAsyncState } from 'async-utils';
import { Nullable, Order } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import {
  ERelevance,
  IrrelevantApp,
  RawIrrelevantApp,
  Result,
} from '../irrelevant-apps-types';
import createIrrelevantAppsInfo from '../utils/irrelevant-apps-info';

export const defaultOrderBy = {
  field: 'Info',
  direction: Order.Desc,
};
const baseStore = createStore<IrrelevantAppsStoreState>(
  {
    filtersOpen: true,
    apps: [],
    generalError: false,
    errorMessage: null,
    isLoading: true,
    showMakeRelevantDialog: false,
    isRemoveIrrelevantAppLoading: false,
    loading: createAsyncState(),
    selectedReleventApp: null,
    showRelevantModal: false,
    isLoadingRelevet: false,
    relevantType: ERelevance.Relevant,
    searchValue: '',
    orderField: defaultOrderBy.field,
    orderDirection: defaultOrderBy.direction,
    offset: 0,
    total: 0,
    totalFiltered: 0,
    selectedCheckboxes: {},
    allSelected: false,
    hoveredRowAppId: null,
  },
  'Irrelevant Apps Store',
);

export const IrrelevantAppsStore = derive(
  {
    irrelevantApps: (get): Result => {
      const { apps } = get(baseStore);

      return createIrrelevantAppsInfo(apps);
    },
    selectedCount: get => {
      const { selectedCheckboxes } = get(baseStore);
      return Object.keys(selectedCheckboxes).reduce(
        (acc, key) => (selectedCheckboxes[key] ? acc + 1 : acc),
        0,
      );
    },
  },
  {
    proxy: baseStore,
  },
);

export default IrrelevantAppsStore;

export interface IrrelevantAppsStoreState {
  apps: RawIrrelevantApp[];
  orderField: string;
  orderDirection: Order;
  generalError: boolean;
  errorMessage: Nullable<string>;
  isLoading: boolean;
  showMakeRelevantDialog: boolean;
  isRemoveIrrelevantAppLoading: boolean;
  loading: ReturnType<typeof createAsyncState>;
  selectedReleventApp: Nullable<IrrelevantApp>;
  showRelevantModal: boolean;
  isLoadingRelevet: boolean;
  relevantType: ERelevance;
  searchValue: string;
  filtersOpen: boolean;
  offset: number;
  total: number;
  totalFiltered: number;
  selectedCheckboxes: { [key: string]: boolean };
  allSelected: boolean;
  hoveredRowAppId: Nullable<string>;
}
