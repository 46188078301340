import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { reportClient } from '../../../api-clients/src/clients';
import { FetchFilterLabelsResponse, FilterPage } from '../filters-utils';
import query from './get-filter-labels.gql';
import { openSnackbar } from 'snackbar-utils';

const getFilterLabels = async (page: FilterPage, cache: boolean = true) => {
  try {
    const results = await reportClient.query<FetchFilterLabelsResponse>({
      query,
      fetchPolicy: cache
        ? ApolloClientCache.CacheFirst
        : ApolloClientCache.NoCache,
      variables: { page: page },
    });
    if (!results.data) return null;
    return results.data.getFilterLabels;
  } catch (e) {
    openSnackbar(`Failed to fetch filter labels for ${page} page`, {
      variant: 'error',
    });
    logger.error(`Failed to fetch filter labels for ${page} page`, e);
    return null;
  }
};

export default getFilterLabels;
