import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { BaseApplicationInfo } from '../../applications-types';
import query from './get-single-application.gql';

const getSingleApplication = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getSingleApplicationInput: GetSingleApplicationInput,
      cache = true,
    ) => {
      try {
        const results = await client.query<{
          getSingleApplicationInfo: BaseApplicationInfo;
        }>({
          query,
          variables: { getSingleApplicationInput },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });

        return results.data.getSingleApplicationInfo;
      } catch (error) {
        logger.error(
          'Failed to fetch application',
          getSingleApplicationInput,
          error,
        );
        return null;
      }
    },
  };
};

export interface GetSingleApplicationInput {
  applicationId: string;
}

export default getSingleApplication;
