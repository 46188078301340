import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import mutation from './update-app-owner-email.gql';
import { logger } from 'logging-utils';

const updateAppOwnerEmail = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: UpdateAppOwnerEmailInput): Promise<boolean> => {
      try {
        const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
        const results = await client.mutate<UpdateAppOwnerEmailResponse>({
          mutation,
          fetchPolicy,
          variables: { input },
        });

        return results.data?.updateAppOwnerEmail ?? false;
      } catch (error) {
        logger.error('Failed to update app owner email', error);
        return false;
      }
    },
  };
};

interface UpdateAppOwnerEmailResponse {
  updateAppOwnerEmail: boolean;
}

export interface UpdateAppOwnerEmailInput {
  oldEmail: string;
  newEmail: string;
}

export default updateAppOwnerEmail;
