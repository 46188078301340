import { AppEvents } from 'common-events';
import { DashboardResponse } from '../../dashboard/dashboard-types';
import {
  handleSearchConnector,
  loadConnectorsByFamily,
} from '../actions/connector-actions';
import {
  handleBitbucketAppInstallationCallback,
  handleGitHubAppInstallationCallback,
  handleIdpCallback,
  postSuccessAction,
} from '../actions/credentials-actions';
import { CredentialsConfigureResponse } from '../connectors-types';
import { setScanType } from '../store-actions/connectors-store-actions';
import { Location } from 'history';

export const setupConnectorsListeners = () => {
  document.addEventListener(
    AppEvents.Connectors.ConnectorConfigured,
    (event: CustomEventInit<CredentialsConfigureResponse>) => {
      if (event.detail) {
        postSuccessAction(event.detail);
      }
    },
  );
  document.addEventListener(
    AppEvents.Connectors.IdentityProviderCallback,
    (event: CustomEventInit<URLSearchParams>) => {
      if (event.detail) {
        handleIdpCallback(event.detail);
      }
    },
  );
  document.addEventListener(
    AppEvents.Connectors.GitHubAppInstallationCallback,
    (event: CustomEventInit<Location>) => {
      if (event.detail) {
        handleGitHubAppInstallationCallback(event.detail);
      }
    },
  );
  document.addEventListener(
    AppEvents.Connectors.BitbucketAppInstallationCallback,
    (event: CustomEventInit<URLSearchParams>) => {
      if (event.detail) {
        handleBitbucketAppInstallationCallback(event.detail);
      }
    },
  );
  document.addEventListener(
    AppEvents.Connectors.PlusMoreClicked,
    (event: CustomEventInit<string>) => {
      if (event.detail) {
        handleSearchConnector(event.detail);
      }
    },
  );
  document.addEventListener(AppEvents.Scan.ScanEnd, () => {
    loadConnectorsByFamily(true);
  });
  document.addEventListener(AppEvents.DashboardInfo.Loaded, e => {
    const customEvent = e as CustomEvent;
    const { getOrgScanInfo: orgScanInfo } =
      customEvent.detail as DashboardResponse;
    const scanType = orgScanInfo ? orgScanInfo.scanType : undefined;
    setScanType(scanType);
  });
};
