import { Avatar, Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CustomLink } from './CustomLink';

export const User: FC<React.PropsWithChildren<UserProps>> = ({
  value,
  userLink,
  userAvatar,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.owner}>
      <Avatar className={classes.ownerIcon} src={userAvatar} />

      <CustomLink value={value} href={userLink} />
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  owner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ownerIcon: {
    marginInlineEnd: theme.spacing(1),

    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export interface UserProps {
  value: string;
  userLink?: string;
  userAvatar?: string;
}
