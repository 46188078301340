import { Theme } from '@mui/material';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import { FC, ReactNode } from 'react';
import { SnackbarProxy } from 'snackbar-utils';
import { makeStyles } from 'tss-react/mui';

const SnackbarProvider: FC<
  React.PropsWithChildren<{ children?: ReactNode }>
> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <NotistackProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{
        variantInfo: classes.variantInfo,
        variantError: classes.variantError,
        variantSuccess: classes.variantSuccess,
        variantWarning: classes.variantWarning,
        containerAnchorOriginBottomRight: classes.anchorOriginBottomRight,
      }}>
      <SnackbarProxy />
      {children}
    </NotistackProvider>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  variantInfo: {
    backgroundColor: `${theme.palette.background.default} !important`,
    color: `${theme.palette.text.primary} !important`,
  },
  variantError: {
    backgroundColor: `${theme.palette.error} !important`,
  },
  variantSuccess: { backgroundColor: `${theme.palette.success} !important` },
  variantWarning: { backgroundColor: `${theme.palette.warning} !important` },
  anchorOriginBottomRight: {
    '& .SnackbarItem-message': { width: '100%' },
  },
}));

export default SnackbarProvider;
