import create from 'zustand';

type RFState = {
  loading: boolean;
  showMoreMap: {};
  collapsedBranches: {};
  fullMode: boolean;
  issueId: string | null;
  aggType: string | null;
  highlightedNodes: string[];
  expandedGroupNodes: { [key: string]: boolean };
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useCommonGraphStore = create<RFState>((set, get) => ({
  loading: false,
  fullMode: false,
  aggType: null,
  expandedGroupNodes: {},
  issueId: null,
  showMoreMap: {},
  collapsedBranches: {},
  highlightedNodes: [],
}));

export default useCommonGraphStore;
