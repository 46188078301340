import { FC, useMemo } from 'react';
import { Divider, Typography, useTheme } from '@mui/material';
import { CustomLink, MultiLineEllipsis } from 'ox-react-components';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs, docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Box } from '@mui/system';
import { DetectionType } from 'ox-common-types';
import React from 'react';
import { getEvidenceIcon } from 'common-icons';

export const SaasSnippet: FC<React.PropsWithChildren<SaasSnippetProps>> = ({
  items,
}) => {
  const theme = useTheme();
  const grouped = useMemo(
    () =>
      items.reduce((acc, item) => {
        if (acc[item.snippet.detectionType]) {
          acc[item.snippet.detectionType].push(item);
        } else {
          acc[item.snippet.detectionType] = [item];
        }
        return acc;
      }, {} as Record<DetectionType, typeof items>),
    [items],
  );
  return (
    <div>
      <Typography variant='body2' color='text.secondary' mb={0.5}>
        Where Found:
      </Typography>
      {Object.keys(grouped)
        .sort()
        .map((key, index) => {
          const DetectionIcon = getEvidenceIcon(key);

          return (
            <Box display='flex' flexDirection={'column'} key={index}>
              {DetectionType[key as keyof typeof DetectionType] && (
                <Typography variant='body2' sx={{ mt: 1 }} color='text.primary'>
                  {DetectionIcon && (
                    <DetectionIcon
                      style={{ marginInline: 4 }}
                      color={theme.palette.primary.main}
                    />
                  )}

                  {DetectionType[key as keyof typeof DetectionType]}
                </Typography>
              )}
              {grouped[key as DetectionType].map((extra, index) => {
                if (extra.key === 'Snippet' && extra.snippet !== null) {
                  return (
                    <div
                      style={{ marginBlockStart: theme.spacing(0.8) }}
                      key={index}>
                      <MultiLineEllipsis variant='body2' lines={3}>
                        <CustomLink
                          fontWeight='bold'
                          value={extra.snippet.fileName}
                          key={index}
                          href={extra.link}
                        />
                      </MultiLineEllipsis>
                      <SyntaxHighlighter
                        language={
                          extra.snippet.language
                            ? extra.snippet.language
                            : 'javascript'
                        }
                        style={theme.palette.mode === 'dark' ? vs : docco}
                        startingLineNumber={
                          extra.snippet.snippetLineNumber
                            ? Number(extra.snippet.snippetLineNumber)
                            : 1
                        }
                        showLineNumbers>
                        {extra.snippet.text}
                      </SyntaxHighlighter>
                      <Divider />
                    </div>
                  );
                } else if (extra.link !== null && extra.value === null) {
                  return (
                    <CustomLink
                      value={`${index + 1}. ${extra.key}`}
                      key={index}
                      href={extra.link}
                      whiteSpace='pre-line'
                    />
                  );
                } else {
                  return (
                    <CustomLink
                      value={`${index + 1}. ${extra.key}: ${extra.value}`}
                      key={index}
                      href={extra.link}
                    />
                  );
                }
              })}
            </Box>
          );
        })}
    </div>
  );
};

export interface SaasSnippetProps {
  items: {
    snippet: {
      detectionType: DetectionType;
      text: string;
      language: string;
      snippetLineNumber: string;
      fileName: string;
    };
    key: string;
    link: string;
    value: string;
  }[];
}
