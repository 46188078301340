import { AppPages } from 'app-navigator';
import { OneOfValues } from 'ox-common-types';

export const resolveDocsURL = (currentPage: OneOfValues<typeof AppPages>) =>
  `${ROOT_URL}${pageToDocsURLMap.get(currentPage) || ''}`;

const ROOT_URL = 'https://docs.ox.security';
const pageToDocsURLMap = new Map<OneOfValues<typeof AppPages>, string>([
  [AppPages.Dashboard, '/a-tour-of-ox/dashboard'],
  [AppPages.Applications, '/a-tour-of-ox/applications'],
  [AppPages.ActiveIssues, '/a-tour-of-ox/issues'],
  [AppPages.Connectors, '/a-tour-of-ox/connectors'],
  [AppPages.Policies, '/a-tour-of-ox/policies'],
  [AppPages.Exclusions, '/a-tour-of-ox/exclusions'],
  [AppPages.Members, '/a-tour-of-ox/users'],
  [AppPages.AuditLogs, ''],
  [AppPages.Settings, '/a-tour-of-ox/settings'],
  [AppPages.SaasBom, '/a-tour-of-ox/saas-bom'],
  [AppPages.ApiInventory, '/a-tour-of-ox/api-bom'],
  [AppPages.Onboarding, ''],
]);
