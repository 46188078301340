import { createStore } from 'store-utils';
import { Nullable } from 'ox-common-types';

interface ConnectorsNotConnectedModalStoreState {
  modalProps: {
    isOpen: boolean;
    title: Nullable<string>;
    icon: Nullable<string>;
    text: Nullable<string>;
    buttonActionLabel: Nullable<string>;
    onConfirm: Nullable<() => void>;
  };
}

const ConnectorsNotConnectedModalStore =
  createStore<ConnectorsNotConnectedModalStoreState>(
    {
      modalProps: {
        isOpen: false,
        title: null,
        icon: null,
        text: null,
        buttonActionLabel: null,
        onConfirm: null,
      },
    },
    'Connectors Not Connected Modal Store',
  );

export default ConnectorsNotConnectedModalStore;
