import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { HubSpotUserActions, logUserActionToForm } from 'hubspot';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  ExclusionsFilters,
  FetchExclusionsResponse,
} from '../../types/exclusion-types';
import query from './get-exclusions-filters.gql';

const getExclusionsFilters = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (filters: ExclusionsFilters) => {
      try {
        const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
        const results = await client.query<FetchExclusionsResponse>({
          query,
          fetchPolicy,
          variables: { filters },
        });
        if (!results.data) return null;
        logUserActionToForm(HubSpotUserActions.ExclusionsFilters);
        return results.data.getExclusionsFilters;
      } catch (e) {
        logger.error(`Failed to fetch exclusions filters: ${e}`, e);
        return null;
      }
    },
  };
};

export default getExclusionsFilters;
