import { IconButton, Theme, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { RiFlashlightLine } from 'react-icons/ri';
import { makeStyles } from 'tss-react/mui';
import { WhatsNewItem, WhatsNewType } from 'whats-new-logic';
import WhatsNewLink from './WhatsNewLink';

export const WhatsNewItemRenderer: FC<WhatsNewItemProps> = ({
  type,
  description,
  links,
}) => {
  const { classes } = useStyles();

  if (type === WhatsNewType.NewUIVersionAvailable) {
    return (
      <Typography variant='body1' className={classes.newUiItem}>
        <RiFlashlightLine className={classes.newUiItemColorIcon} />
        {description}{' '}
        <Tooltip title='Click to refresh' placement='right' arrow>
          <IconButton
            size='small'
            color='inherit'
            onClick={() => {
              const { href } = window.location;
              window.location.href = href;
            }}>
            <BiRefresh />
          </IconButton>
        </Tooltip>
      </Typography>
    );
  }

  return (
    <Typography variant='body1' className={classes.text} component='span'>
      {description}&nbsp;&nbsp;
      {links.map((link, index) => (
        <WhatsNewLink
          key={index}
          URL={link.URL}
          text={link.text}
          type={link.type}
        />
      ))}
    </Typography>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  text: {
    marginInlineEnd: theme.spacing(1),
  },
  newUiItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    fontWeight: 600,
  },
  newUiItemColorIcon: {
    fill: '#a8ffbd',
    width: '1.3em',
    height: '1.3em',
  },
}));

export type WhatsNewItemProps = Pick<
  WhatsNewItem,
  'type' | 'description' | 'links'
>;
