import { FC } from 'react';
import { Typography } from '@mui/material';
import NodeTooltip from '../NodeTooltip';

const UserItem: FC<React.PropsWithChildren<UserItemProps>> = ({
  user,
  orgRole,
}) => {
  return (
    <NodeTooltip title='User Info'>
      <Typography variant='caption'>
        User: <b>{user}</b>
      </Typography>
      <Typography variant='caption'>
        Role: <b>{orgRole}</b>
      </Typography>
    </NodeTooltip>
  );
};

export interface UserItemProps {
  user: string;
  orgRole: string;
}

export { UserItem };
