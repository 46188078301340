import { FunctionComponent, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import DataViewModal from './DataViewModal';
import { GlobalDataViewSelector } from './global-data-view-selector-store';
import { closeDataViewSelectorModal } from './global-data-view-selector-store-actions';
import { useOxFlags } from '../../../feature-flags/api/feature-flags-api';

const DataViewModalContainer: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const {
    isModalVisible,
    selectedOption,
    isLoadingIdUpdate,
    isLoadingNameUpdate,
    isLoadingDeleteOption,
  } = useSnapshot(GlobalDataViewSelector);
  const { syncOxGroupScopes } = useOxFlags();

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      closeDataViewSelectorModal();
    },
    [],
  );

  if (!selectedOption) {
    return null;
  }

  return (
    <DataViewModal
      open={isModalVisible}
      onClose={handleClose}
      isEditNameEnabled={false}
      isLoading={false}
      isLoadingNameUpdate={isLoadingNameUpdate}
      isLoadingIdUpdate={isLoadingIdUpdate}
      dataViewSelectorOption={selectedOption}
      isValidDisplayName={true}
      shouldShowSSOSyncGroupString={syncOxGroupScopes}
      isLoadingDeleteOption={isLoadingDeleteOption}
    />
  );
};

export default DataViewModalContainer;
