import { IconButton, Tooltip } from '@mui/material';
import { openFeedback } from 'feedback-logic';
import { FC, SyntheticEvent, useState } from 'react';
import { MdOutlineTextsms as FeedbackIcon } from 'react-icons/md';
import { FeedbackPopover } from './FeedbackPopover';

export const FeedbackButton: FC<React.PropsWithChildren<unknown>> = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();

  const handleClick = (event: SyntheticEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
    openFeedback();
  };

  return (
    <>
      <Tooltip arrow title='Send feedback'>
        <IconButton size='small' onClick={handleClick} color='inherit'>
          <FeedbackIcon style={{ padding: 1 }} />
        </IconButton>
      </Tooltip>
      <FeedbackPopover anchorElement={anchorElement} />
    </>
  );
};
