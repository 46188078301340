import { FC, useMemo } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import LearnMoreItem from './LearnMoreItem';
import IssuesDrawerTabsStore from '../../store/issue-drawer-tabs-store';
import { useSnapshot } from 'valtio';

const tabs = [
  'Attack Path',
  'SBOM Checks',
  'SBOM Info',
  'Dependency Graph',
  'Severity Factors',
  'Reachable Vulnerabilities',
  'Not Reachable Vulnerabilities',
  'Vulnerabilities',
];

const DevSummaryLearnMore: FC<React.PropsWithChildren<LearnMoreProps>> = ({
  moveToTab,
}) => {
  const { issueDrawerTabs } = useSnapshot(IssuesDrawerTabsStore);

  const availableTabs = useMemo(() => {
    return tabs.filter(tab =>
      issueDrawerTabs.find(issueDrawerTab => issueDrawerTab.label === tab),
    );
  }, [issueDrawerTabs]);

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Typography color='text.secondary' variant='caption'>
        Learn more about the issue
      </Typography>
      <Box
        display='flex'
        flexDirection='row'
        flexWrap='wrap'
        alignItems='center'
        gap={1}>
        {availableTabs.map((tab, index) => (
          <LearnMoreItem
            onClick={() => {
              moveToTab(tab);
            }}
            title={tab}
          />
        ))}
      </Box>
    </Box>
  );
};

export interface LearnMoreProps {
  moveToTab: (tab: string) => void;
}

export default DevSummaryLearnMore;
