import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  JiraUser,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import query from './get-reporters.gql';

const getReporters = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<string, Nullable<SearchReporterResponse>> => {
  return {
    execute: async (
      searchString,
    ): Promise<Nullable<SearchReporterResponse>> => {
      try {
        const results = await client.query<SearchReporterResponse>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: {
            query: searchString,
            provider: TicketProvider.Jira,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to fetch reporters', error);
        return null;
      }
    },
  };
};

export interface SearchReporterResponse {
  searchReporter: JiraUser[];
}

export default getReporters;
