import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import mutation from './update-app-owner-name.gql';
import { logger } from 'logging-utils';

const updateAppOwnerNameV2 = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: UpdateAppOwnerTagNameInput): Promise<boolean> => {
      try {
        const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
        const results = await client.mutate<UpdateTagRes>({
          mutation,
          fetchPolicy,
          variables: { ...input },
        });

        return results.data?.updateTagAppOwnerName.acknowledge ?? false;
      } catch (error) {
        logger.error('Failed to update app owner email', error);
        return false;
      }
    },
  };
};

export interface UpdateTagRes {
  updateTagAppOwnerName: Acknowledge;
}
export interface Acknowledge {
  acknowledge: boolean;
}

export interface UpdateAppOwnerTagNameInput {
  input: {
    tagId: string;
    name: string;
  };
}

export default updateAppOwnerNameV2;
