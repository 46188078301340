import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { GetViewSettingsResponse } from '../../types';
import query from './get-user-view-settings.gql';

const getUserViewSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async () => {
      try {
        const results = await client.query<{
          getUserViewSettings: GetViewSettingsResponse;
        }>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
        });

        return results.data.getUserViewSettings.settings;
      } catch (error) {
        logger.error('Failed to get user view settings', error);
        return null;
      }
    },
  };
};

export default getUserViewSettings;
