import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  ExportSBOMToCSVResponse,
  GetOrgSbomInput,
} from '../../applications-types';
import query from './export-app-sbom-to-csv.gql';

const exportAppSbomToCsv = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (exportSbomToCsvInput: GetOrgSbomInput) => {
      try {
        const results = await client.query<{
          exportAppSbomToCSV: ExportSBOMToCSVResponse[];
        }>({
          query,
          variables: { exportSbomToCsvInput },
          fetchPolicy: ApolloClientCache.NoCache,
        });

        return results.data.exportAppSbomToCSV;
      } catch (error) {
        logger.error('Failed to fetch applications org sbom', error);
        return null;
      }
    },
  };
};

export default exportAppSbomToCsv;
