import { Theme } from '@mui/material';
import { FC } from 'react';
import { MdHeight as HeightIcon } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

const CustomResizer: FC<React.PropsWithChildren<CustomResizerProps>> = ({
  resizing,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div title='Drag & drop to resize vertically'>
      <div
        className={cx(classes.knobWrapper, { [classes.resizing]: resizing })}>
        <div className={classes.knob}>
          <HeightIcon color='inherit' />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  knobWrapper: {
    top: -10,
    left: 'calc(50% - 15px)',
    height: 25,
    zIndex: 10,
    position: 'absolute',
    paddingBlockStart: 3,
    transition: theme.transitions.create(['transform', 'color']),
    '&:hover': {
      transform: 'scale(1.2)',
      transformOrigin: 'center',
      color: theme.palette.primary.main,
    },
    color: theme.palette.text.secondary,
  },
  resizing: {
    transform: 'scale(1.2)',
    transformOrigin: 'center',
    color: theme.palette.primary.main,
  },
  knob: {
    height: 25,
    width: 30,
    background: theme.palette.background.paper,
    borderRadius: '50%',
    textAlign: 'center',
    boxShadow:
      theme.palette.mode === 'light'
        ? '0px -3px 3px rgba(0, 0, 0, 0.1)'
        : '0px -3px 3px rgba(0, 0, 0, 0.3)',
    overflow: 'hidden',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      position: 'absolute',
      top: 7,
      width: 20,
      height: 20,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
    },
  },
}));

interface CustomResizerProps {
  resizing: boolean;
}

export default CustomResizer;
