import { FC } from 'react';
import { useDebouncer, useIdlePoller, useInterval } from 'react-utils';
import { config, pollWhatsNew } from 'whats-new-logic';

export const WhatsNewPoller: FC = () => {
  const { active } = useIdlePoller({ options: { name: 'deployment-poller' } });
  const { isReady, update } = useDebouncer(config.pollingInterval);

  useInterval(() => {
    if (!active || !isReady()) {
      // 'Skipping polling whats-new since the user is idle or we polled recently
      return;
    }

    // Actually polling whats-new
    pollWhatsNew();
    update();
  }, config.pollingInterval);

  return <></>;
};
