import { Typography, useTheme } from '@mui/material';
import { ModalDialog } from 'ox-react-components';
import { FC } from 'react';
import CreateOrganizationForm from './CreateOrganizationForm';

const CreateOrganizationModal: FC<CreateOrganizationModalProps> = props => {
  const theme = useTheme();

  const {
    open,
    onClose,
    onCreateOrg,
    message,
    isLoading,
    orgDisplayName,
    isValidOrgDisplayName,
    isOrgCreatedSuccessfully,
    invalidOrgDisplayNameHelperText,
  } = props;

  return (
    <ModalDialog
      modalColor={theme.palette.info.main}
      aria-label='Create an Organization'
      open={open}
      onClose={onClose}
      modalHeader='Create an Organization'>
      <CreateOrganizationForm
        onCreateOrg={onCreateOrg}
        onClose={onClose}
        isLoading={isLoading}
        orgDisplayName={orgDisplayName}
        isValidOrgDisplayName={isValidOrgDisplayName}
        invalidOrgDisplayNameHelperText={invalidOrgDisplayNameHelperText}
      />
      <Typography
        variant='body1'
        color={isOrgCreatedSuccessfully ? 'success.main' : 'error.main'}
        sx={{ marginBlockStart: theme.spacing(1.2) }}>
        {message}
      </Typography>
    </ModalDialog>
  );
};

interface CreateOrganizationModalProps {
  open: boolean;
  onClose: () => void;
  onCreateOrg: () => void;
  isCreateOrgEnabled: boolean;
  message: string;
  isLoading: boolean;
  orgDisplayName: string;
  isValidOrgDisplayName: boolean;
  isOrgCreatedSuccessfully: boolean;
  invalidOrgDisplayNameHelperText: string;
}

export default CreateOrganizationModal;
