import { FC } from 'react';
import { Link, Typography } from '@mui/material';
import { stopPropagation } from 'common-utils';
import NodeTooltip from '../NodeTooltip';

const PrItem: FC<React.PropsWithChildren<PrItemProps>> = ({
  prStatus,
  prURL,
  prId,
}) => {
  return (
    <NodeTooltip title='Pr Info'>
      <Typography variant='caption'>
        ID:{' '}
        <Link onPointerDown={stopPropagation} target='_blank' href={prURL}>
          #{prId}
        </Link>
      </Typography>
      <Typography variant='caption'>
        Status: <b>{prStatus}</b>
      </Typography>
    </NodeTooltip>
  );
};

export interface PrItemProps {
  prStatus: string;
  prURL: string;
  prId: string;
}

export { PrItem };
