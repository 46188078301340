import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import query from './get-organization-sbom.gql';

const getApplicationsSbom = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (scanId?: string) => {
      try {
        const results = await client.query<{
          getOrgSBOM: { requestId: string };
        }>({
          query,
          variables: {
            getOrgSbomInput: {
              scanId,
            },
          },
          fetchPolicy: 'no-cache',
        });

        return results.data.getOrgSBOM;
      } catch (error) {
        logger.error('Failed to fetch applications sbom', error);
        return null;
      }
    },
  };
};

export default getApplicationsSbom;
