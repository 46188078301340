import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { FC, useCallback } from 'react';
import { openSnackbar } from 'snackbar-utils';
import { DebugInfo } from '../DebugInfo';

const AggInfoModal: FC<React.PropsWithChildren<AggInfoModalProps>> = ({
  open,
  handleClose,
  additionalInfo,
}) => {
  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(
      JSON.stringify({
        additionalInfo,
      }),
    );
    openSnackbar('Copied!', { variant: 'success' });
  }, [additionalInfo]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      fullWidth
      sx={{ whiteSpace: 'pre-wrap' }}
      maxWidth='lg'>
      <DialogTitle>Resource Information</DialogTitle>
      <Divider />
      <DialogContent>
        <DebugInfo scanAdditionalInfo={additionalInfo} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button autoFocus onClick={copyToClipboard} variant='contained'>
          Copy
        </Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export interface AggInfoModalProps {
  handleClose: () => void;
  open: boolean;
  additionalInfo: Record<string, string[]>;
}

export default AggInfoModal;
