import { createSimpleAsyncAction } from 'async-utils';
import { logger } from 'logging-utils';
import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { snackBarSpplitingName } from '../../exclusions/utils/exclusion-utils';
import {
  ApplicationsFilters,
  ERelevance,
  IrrelevantApp,
} from '../irrelevant-apps-types';
import irrelevantAppsService from '../services';
import {
  setIrrelevantApps,
  setIrrelevantAppsError,
  setIsLoadingIrrelevantApps,
  setIsRemoveIrrelevantAppLoading,
  setOffset,
  showRelevantModal,
  setShowMakeRelevantDialog,
  setTotal,
} from '../store-actions/irrelevant-apps-store-actions';
import IrrelevantAppsStore from '../stores/irrelevant-apps-store';
import ReasonFiltersStore, {
  ReasonFilterType,
} from '../stores/reason-filters-store';
import { setLoadingFilters, updateFilters } from './reason-filters-actions';
import IrrelevantAppsFiltersStore from '../stores/irrelevant-apps-filters-store';
import { setTotalFilteredApps } from '../store-actions/irrelevant-apps-filters-store-actions';
import { getAllTableRowsIdsBetweenSelection } from 'common-utils';

export type LoadParams = {
  update?: boolean;
  cache?: boolean;
};

export const loadIrrelevantApps = async (
  params?: LoadParams,
  reasonFilter?: ReasonFilterType,
) => {
  const { update } = params || {};
  try {
    if (update) {
      setOffset(0);
    }
    if (reasonFilter) {
      updateFilters(reasonFilter);
    }
    const {
      orderField: columnKey,
      orderDirection,
      offset,
      total,
      searchValue,
    } = snapshot(IrrelevantAppsStore);
    const { filterBy } = snapshot(IrrelevantAppsFiltersStore);
    if (offset !== 0 && offset >= total) return;

    const { filters } = snapshot(ReasonFiltersStore);

    setLoadingFilters(true);
    const result = await irrelevantAppsService.getIrrelevantApps.execute({
      applicationFilters: [ApplicationsFilters.Irrelevant],
      irrelevancyFilters: filters,
      filters: filterBy,
      offset,
      limit: 50,
      search: searchValue,
      orderBy: {
        direction: orderDirection,
        field: columnKey,
      },
    });

    if (!result) {
      logger.error('Failed to load irrelevant apps results');
      setIrrelevantAppsError(true, 'Failed to load irrelevant apps results');
    }

    if (!result?.applications.length) {
      setIrrelevantAppsError(false, 'No irrelevant apps found');
    }
    setLoadingFilters(false);
    setIrrelevantApps(result?.applications || [], update);
    setIsLoadingIrrelevantApps(false);
    setTotal(result?.total || 0);
    setOffset(result?.offset || 0);
    setTotalFilteredApps(result?.totalFilteredApps || 0);
  } catch (err) {
    logger.error('Failed to load irrelevant apps results', err);
    setIrrelevantAppsError(
      true,
      `Failed to load irrelevant apps results: ${err}`,
    );
  } finally {
    setLoadingFilters(false);
  }
};

export const removeIrrelevantApp = (appId: string) => {
  const { irrelevantApps } = snapshot(IrrelevantAppsStore);
  const updatedApps = irrelevantApps.result.filter(app => app.appId !== appId);
  IrrelevantAppsStore.irrelevantApps = {
    ...irrelevantApps,
    result: updatedApps,
  };
};

export const removeAppExclusion = createSimpleAsyncAction(
  async (selectedApps: IrrelevantApp[], relevance: ERelevance) => {
    const selectedAppNames = selectedApps.map(app => app.name);

    try {
      setIsRemoveIrrelevantAppLoading(true);
      const res = await irrelevantAppsService.removeAppsExclusion.execute({
        exclusionIds: selectedApps.map(app => app.appId),
        relevance,
      });

      const removedAppsExclusionSucceeded = res.removeAppsExclusion.filter(
        re => re.isSucceeded,
      );
      if (removedAppsExclusionSucceeded.length) {
        const removedAppsExclusionNames = selectedApps
          .filter(app => {
            return (
              res.removeAppsExclusion.findIndex(
                rae => rae.exclusionId === app.appId,
              ) !== -1
            );
          })
          .map(app => app.name);

        loadIrrelevantApps({ update: true });
        setIsRemoveIrrelevantAppLoading(false);
        showRelevantModal(false);
        openSnackbar(
          `The following apps have been made relevant: ${snackBarSpplitingName(
            removedAppsExclusionNames.toString(),
          )}`,
          {
            variant: 'success',
          },
        );
      }
    } catch (e) {
      logger.error(
        `There was an error making this app relevant: ${selectedAppNames}`,
      );

      openSnackbar(
        `There was an error making this app relevant: ${snackBarSpplitingName(
          selectedAppNames.toString(),
        )}`,
        {
          variant: 'error',
        },
      );
    } finally {
      setIsRemoveIrrelevantAppLoading(false);
      setShowMakeRelevantDialog(false);
    }
  },
  {
    asyncState: IrrelevantAppsStore.loading,
    errorMessage: 'Failed to remove app exclusion',
  },
);

export const changeToRelevantApp = (selectedApps: IrrelevantApp[]) => {
  removeAppExclusion(selectedApps, IrrelevantAppsStore.relevantType);
};

export const setInitialFilters = (encodedFilters: string | null) => {
  if (!encodedFilters) return;
  let filters = {};
  try {
    filters = JSON.parse(decodeURI(encodedFilters));
  } catch {}
  IrrelevantAppsFiltersStore.filterBy = filters;
};

export const selectIrrelevantApp = (appId: string, isShiftPressed: boolean) => {
  const { selectedCheckboxes, apps } = snapshot(IrrelevantAppsStore);
  const selectedAppsIds = Object.keys(selectedCheckboxes).filter(
    key => selectedCheckboxes[key],
  );
  const newSelectedCheckboxes = { ...selectedCheckboxes };

  if (isShiftPressed) {
    const selectedUpdatedIds = getAllTableRowsIdsBetweenSelection(
      appId,
      'appId',
      apps,
      selectedAppsIds,
    );

    const selectedUpdated = apps.reduce(
      (acc: { [appId: string]: boolean }, app) => {
        acc[app.appId] = selectedUpdatedIds.includes(app.appId);
        return acc;
      },
      {},
    );
    IrrelevantAppsStore.selectedCheckboxes = selectedUpdated;
  } else {
    newSelectedCheckboxes[appId] = !newSelectedCheckboxes[appId];
    IrrelevantAppsStore.selectedCheckboxes = newSelectedCheckboxes;
  }
};

export const toggleSelectAll = (allSelected: boolean) => {
  const { selectedCheckboxes, irrelevantApps } = snapshot(IrrelevantAppsStore);

  const newSelectedCheckboxes = { ...selectedCheckboxes };

  irrelevantApps.result.forEach(i => {
    newSelectedCheckboxes[i.appId] = allSelected;
  });

  IrrelevantAppsStore.selectedCheckboxes = newSelectedCheckboxes;
  IrrelevantAppsStore.allSelected = allSelected;
};
