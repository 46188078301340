import { Slider, SliderProps, Theme } from '@mui/material';
import { Nullable, important } from 'ox-common-types';
import { FilterTypeToFilter } from 'ox-filter-utils';
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { makeStyles } from 'tss-react/mui';
import OxSliderThumb from './OxSliderThumb';
import OxSliderTicks from './OxSliderTicks';

export const OxSlider: FC<React.PropsWithChildren<OxSliderProps>> = ({
  minDistance = 0,
  step,
  ticks,
  sliderValue,
  maxVal,
  setSliderValue,
  onChange,
  onChangeCommitted,
  filterName,
  handleChangeFilterSlider,
  ...restOfProps
}) => {
  const { classes } = useStyles();
  const sliderRef = useRef<HTMLSpanElement>(null);
  const [railPadding, setRailPadding] = useState<Nullable<string>>(null);

  useEffect(() => {
    const { paddingTop } = window.getComputedStyle(sliderRef.current!);
    setRailPadding(paddingTop);
  }, []);

  const handleChange = useCallback(
    (_: SyntheticEvent | Event, newValue: number | number[]) => {
      const invalidDistance =
        !Array.isArray(newValue) || newValue[1] - newValue[0] < minDistance;
      if (invalidDistance) {
        return;
      }
      if (setSliderValue) {
        setSliderValue(newValue);
      }
    },
    [minDistance, setSliderValue],
  );

  const handleChangeCommitted = useCallback(() => {
    if (filterName && sliderValue) {
      handleChangeFilterSlider &&
        handleChangeFilterSlider(sliderValue, filterName);
    }
    onChangeCommitted && onChangeCommitted(sliderValue as number[]);
  }, [filterName, handleChangeFilterSlider, onChangeCommitted, sliderValue]);

  return (
    <span>
      {railPadding && ticks && (
        <span
          style={{
            position: 'relative',
            bottom: `-${railPadding}`,
          }}
          data-testid='ticks-wrapper'>
          <OxSliderTicks ticks={ticks} />
        </span>
      )}
      <Slider
        {...restOfProps}
        data-testid='slider'
        ref={sliderRef}
        size='medium'
        components={{ Thumb: OxSliderThumb }}
        value={sliderValue}
        max={maxVal}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        classes={{ rail: classes.rail, track: classes.track }}
        step={step}
      />
    </span>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  rail: {
    background: important('linear-gradient(90deg, #5059FF 0%, #FA1414 100%)'),
  },
  track: {
    background: important('transparent'),
    border: important('none'),
  },
}));

export interface OxSliderProps
  extends Omit<SliderProps, 'onChange' | 'onChangeCommitted' | 'value'> {
  minDistance?: number;
  ticks?: number[];
  maxVal: number;
  onChange?(value: number[]): void;
  onChangeCommitted?(value: number[]): void;
  filterName?: FilterTypeToFilter;
  sliderValue?: number[] | number;
  setSliderValue?: (newValue: number[]) => void;
  handleChangeFilterSlider?: (
    range: number[] | number,
    filterName: FilterTypeToFilter,
  ) => void;
}
