import {
  CategoryDisplayName,
  OxCategoriesNames,
} from '@oxappsec/ox-consolidated-categories';
import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import ChatGptContainer from '../../../../../containers/ChatGptContainer';

const ChatGPTTabContainer: FC = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);
  const isSecretScanCategory =
    selectedIssue?.category?.name === OxCategoriesNames.SecretScan;

  return (
    <>
      {selectedIssue && (
        <ChatGptContainer
          key='ChatGPT'
          issueId={selectedIssue.issueId}
          issueLang={
            selectedIssue.aggregations.items?.[0]?.language || 'javascript'
          }
          isPrAvailable={selectedIssue.isPRAvailable}
          appId={selectedIssue.app?.id}
          appName={selectedIssue.app?.name}
          appType={selectedIssue.app?.type}
          category={selectedIssue.category?.name as CategoryDisplayName}
          categoryId={parseInt(selectedIssue.category?.categoryId)}
          issueTitle={selectedIssue.mainTitle}
          policyId={selectedIssue.policy?.id}
          issueSnippet={
            isSecretScanCategory
              ? selectedIssue.aggregations?.items?.[0]?.match
              : selectedIssue.aggregations?.items?.[0]?.snippet
          }
          recommendation={selectedIssue.recommendation}
          match={
            isSecretScanCategory
              ? selectedIssue.aggregations?.items?.[0]?.match
              : undefined
          }
        />
      )}
    </>
  );
};

export default ChatGPTTabContainer;
