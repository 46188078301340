import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../app/stores/top-bar-store';
import ScanStore from '../../new-scan/store/scan-store';
import inventoryService from '../services';
import InventoryFiltersStore from '../stores/inventory-filters-store';
import { setInventoryData } from '../stores/inventory-filters-store-actions';

export const loadInventory = async () => {
  const { filters } = snapshot(InventoryFiltersStore);
  const { dateRange } = snapshot(TopBarStore);
  const [from, to] = calcDateRange(dateRange);
  const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
    GlobalDataViewSelector,
  );
  const { scanID, realScanId } = snapshot(ScanStore);
  const inventoryData = await inventoryService.fetchInventory.execute({
    owners: selectedAppOwnersEmails,
    tagIds: selectedTagIds,
    filters,
    ...(realScanId ? { scanId: realScanId } : scanID && { scanId: scanID }),
    dateRange: { from, to },
  });
  inventoryData && setInventoryData(inventoryData);
};
