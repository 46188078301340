import { SvgIcon, Theme } from '@mui/material';
import { ExternalLinkButton } from 'ox-react-components';
import { FC } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { FaArrowRight, FaLink } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { WhatsNewLinkItem } from 'whats-new-logic';

const WhatsNewLink: FC<WhatsNewLinkItem> = ({ URL, type, text }) => {
  const { classes } = useStyles();

  if (type === 'external') {
    if (text) {
      return (
        <a href={URL} target='_blank' rel='noreferrer' className={classes.link}>
          {text}
          <SvgIcon
            component={BiLinkExternal}
            fontSize='inherit'
            style={{ display: 'inline' }}
          />
        </a>
      );
    }
    return (
      <ExternalLinkButton
        href={URL}
        target='_blank'
        rel='noreferrer'
        sx={{ fontSize: 'inherit' }}
        color='inherit'
      />
    );
  }

  // internal link
  return (
    <NavLink to={URL} className={classes.link}>
      {text || <FaLink />}
      {!!text && <FaArrowRight />}
    </NavLink>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  link: {
    whiteSpace: 'nowrap',
    color: 'inherit',
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    textDecoration: 'none',
    fontWeight: 600,
    marginInlineEnd: theme.spacing(1),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default WhatsNewLink;
