import { tagsClient } from 'api-clients';
import getEditApplicationsTagsData from './get-edit-applications-tags-content/get-edit-applications-tags-content';
import addNewTags from './add-new-tags/add-new-tags';
import modifyAppsTags from './modify-apps-tags/modify-apps-tags';
import getAllTags from './get-all-tags/get-all-tags';

const tagsService = {
  getEditApplicationsTagsData: getEditApplicationsTagsData(tagsClient),
  addNewTags: addNewTags(tagsClient),
  modifyAppsTags: modifyAppsTags(tagsClient),
  getAllTags: getAllTags(tagsClient),
};

export default tagsService;
