import { Box, Paper } from '@mui/material';
import { logger } from 'logging-utils';
import { GeneralErrorPage } from 'ox-react-components';
import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorBoundaryMessage from './ErrorBoundaryMessage';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('ErrorBoundary caught an error', error);
    logger.error('Error Info:', errorInfo);
    this.props.setErrorState(true);
  }

  render() {
    if (this.props.hasError) {
      return (
        <Paper elevation={0} square style={{ width: '100%', height: '100%' }}>
          <Box
            display='flex'
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='center'>
            <GeneralErrorPage
              showOxIcon
              errorTitle='Oops, an error has occurred :('
              errorMsg={<ErrorBoundaryMessage linkToHomepage />}
            />
          </Box>
        </Paper>
      );
    }

    return this.props.children;
  }
}

interface ErrorBoundaryProps {
  children: ReactNode;
  hasError: boolean;
  setErrorState: (hasError: boolean) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default ErrorBoundary;
