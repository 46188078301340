import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';
import { AppStore } from 'app-store/src/app-store';
import { ComponentType } from 'react';
import { useSnapshot } from 'valtio';
import { hasPermissions } from '../permissions-utils';

const withDisabledIfUnauthorized = <
  P extends object & WithDisabledIfUnauthorizedProps,
>(
  WrappedComponent: ComponentType<P>,
  options: {
    requiredPermissions: {
      permissionType: PermissionTypes;
      permissionScope: PermissionScope;
    }[];
    tooltipText?: string;
    tooltipPropName?: string;
  },
) => {
  return (props: P) => {
    const { requiredPermissions, tooltipText, tooltipPropName } = options;

    const { user } = useSnapshot(AppStore);

    const isAuthorized = hasPermissions(requiredPermissions, user?.permissions);

    const tooltipProp = !isAuthorized
      ? {
          [tooltipPropName || 'tooltipText']:
            tooltipText || 'You need permissions to perform this action',
        }
      : undefined;
    return (
      <WrappedComponent
        {...props}
        disabled={isAuthorized ? props.disabled : true}
        {...tooltipProp}
      />
    );
  };
};

export interface WithDisabledIfUnauthorizedProps {
  disabled?: boolean;
  tooltipText?: string;
}

export default withDisabledIfUnauthorized;
