import configJson from './config/whats-new-config.json';

export * from './actions/whats-new-close-action';
export * from './actions/whats-new-load-action';
export * from './actions/whats-new-page-actions';
export * from './listeners/whats-new-listeners';
export * from './store-actions/whats-new-store-actions';
export * from './stores/whats-new-page-store';
export * from './stores/whats-new-store';
export * from './utils/whats-new-utils';
export * from './whats-new-types';
export const config = configJson;
