import { Theme, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { createNewDate } from '../Aggregation/aggregation-utils';
import { CustomLink } from '../Aggregation/elements/CustomLink';
import { AppFlowToolTipItem } from './types/app-flow-types';

export const AppFlowGeneralInfo: FC<AppFlowGeneralInfoProps> = ({ info }) => {
  const { classes } = useStyles();
  const date = createNewDate(info.date);
  return (
    <div className={classes.container}>
      <Typography variant='caption' color='text.primary' component='div'>
        <div className={classes.jobCountContainer}>
          {info.name && (
            <div className={classes.textContainer}>
              <span className={classes.title}>Name: </span>
              {info.linkName ? (
                <CustomLink href={info.linkName} value={info.name} />
              ) : (
                <span>{info.name}</span>
              )}
            </div>
          )}
        </div>
        <div className={classes.jobCountContainer}>
          {info.region && (
            <div className={classes.textContainer}>
              <span className={classes.title}>Region: </span>
              <span>{info.region}</span>
            </div>
          )}
        </div>
        <div className={classes.jobCountContainer}>
          {info.k8sType && (
            <div className={classes.textContainer}>
              <span className={classes.title}>Platform: </span>
              <span>{info.k8sType}</span>
            </div>
          )}
        </div>
        <div className={classes.jobCountContainer}>
          {info.cluster && (
            <div className={classes.textContainer}>
              <span className={classes.title}>Cluster: </span>
              <span>{info.cluster}</span>
            </div>
          )}
        </div>

        <div className={classes.jobCountContainer}>
          {date && date !== null && (
            <div className={classes.textContainer}>
              <span className={classes.title}>Created at: </span>
              <span>{formatDistanceToNow(date, { addSuffix: true })}</span>
            </div>
          )}
        </div>
        {info.location && (
          <div>
            <Typography color='text.secondary' variant='caption'>
              Found in:
            </Typography>
            {info.location.map((locate, index) => {
              return (
                <div key={index}>
                  <CustomLink href={locate.link} value={locate.foundIn} />
                  {locate.runBy && (
                    <div className={classes.textContainer}>
                      <span className={classes.title}>Run by: </span>
                      <span>{locate.runBy}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <div className={classes.jobCountContainer}>
          {info.imageName && (
            <div className={classes.textContainer}>
              <span className={classes.title}>Name: </span>
              <span>{info.imageName}</span>
            </div>
          )}
        </div>
      </Typography>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBlockStart: theme.spacing(0.5),
  },
  jobCountContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.text.secondary,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface AppFlowGeneralInfoProps {
  info: AppFlowToolTipItem;
}
