import { createSimpleAsyncAction } from 'async-utils';
import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../../app/stores/top-bar-store';
import ScanStore from '../../../new-scan/store/scan-store';
import {
  FetchIssuesInput,
  LoadIssuesParams,
} from '../../common/types/issues-types';
import resolvedIssuesService from '../services';
import { ResolvedIssuesOverviewStore } from '../stores/resolved-issues-overview-store';
import ResolvedIssuesStore from '../stores/resolved-issues-store';
import { createMttrItems } from './resolved-issues-mttr-actions';
import { createResolvedIssuesTrendInfo } from './resolved-issues-trend-actions';

export const loadResolvedIssuesOverview = createSimpleAsyncAction(
  async ({
    limit = 50,
    cache = true,
    overrideOpenCheck = false,
  }: LoadIssuesParams & { overrideOpenCheck?: boolean }) => {
    const { open } = snapshot(ResolvedIssuesOverviewStore);
    if (!open && !overrideOpenCheck) {
      return;
    }

    const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
      GlobalDataViewSelector,
    );
    const { dateRange } = snapshot(TopBarStore);
    const [from, to] = calcDateRange(dateRange);
    const { scanID, isScanning, realScanId } = snapshot(ScanStore);
    const { offset, filterIssuesBy, order, orderBy } =
      snapshot(ResolvedIssuesStore);

    const input: FetchIssuesInput = {
      owners: selectedAppOwnersEmails,
      tagIds: selectedTagIds,
      offset,
      limit,
      scanID: realScanId || scanID || undefined,
      filters: filterIssuesBy,
      sort: {
        fields: orderBy,
        order: order,
      },
      dateRange: {
        from,
        to,
      },
    };

    const overviewData =
      await resolvedIssuesService.fetchResolvedIssuesSummary.execute(
        input,
        cache === true || !isScanning,
      );

    if (overviewData) {
      const { getResolvedIssuesTrend, getMttrForResolvedIssues } = overviewData;
      ResolvedIssuesOverviewStore.mttrDays = createMttrItems(
        getMttrForResolvedIssues.mttrDays,
      );

      ResolvedIssuesOverviewStore.trendInfo = createResolvedIssuesTrendInfo(
        getResolvedIssuesTrend.trends,
      );
    }
  },
  {
    asyncState: ResolvedIssuesOverviewStore.loading,
    errorMessage: 'Unable to load resolved issues overview',
  },
);

export const toggleOverviewOpen = async (open: boolean) => {
  const { mttrDays } = snapshot(ResolvedIssuesOverviewStore);

  // data is lazy loaded, in case the overview is expanded and we don't
  // have data, we'll fetch it first and only then expand overview
  if (!open) {
    clearOverviewStore();
  } else if (open && !mttrDays) {
    await loadResolvedIssuesOverview({ overrideOpenCheck: true });
  }

  ResolvedIssuesOverviewStore.open = open;
};

export const clearOverviewStore = () => {
  ResolvedIssuesOverviewStore.mttrDays = undefined;
  ResolvedIssuesOverviewStore.trendInfo = undefined;
};
