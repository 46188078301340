import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';
import {
  FetchIssueInput,
  FetchIssuesResponse,
  IssueDetails,
} from '../../types/issues-types';
import query from './get-issue.gql';

const fetchIssue = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<FetchIssueInput, Nullable<IssueDetails>> => {
  return {
    execute: async (getSingleIssueInput: FetchIssueInput) => {
      const results = await client.query<FetchIssuesResponse>({
        query,
        fetchPolicy: ApolloClientCache.NetworkOnly,
        variables: { getSingleIssueInput },
      });

      if (!results.data) return null;
      return results.data.getSingleIssueInfo;
    },
  };
};

export default fetchIssue;
