import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import { IFetchProfilePoliciesByCategoryIdRes } from '../../types/policies-types';
import { IPoliciesByCategoryAndProfileInput } from '../../types/policy-profile-types';
import query from './fetch-profile-policies-by-category-service.gql';

const fetchProfilePoliciesByCategoryId = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  IPoliciesByCategoryAndProfileInput,
  IFetchProfilePoliciesByCategoryIdRes
> => {
  return {
    execute: async ({
      profileId,
      categoryId,
    }): Promise<IFetchProfilePoliciesByCategoryIdRes> => {
      const results = await client.query<{
        getPoliciesByCategoryIdAndProfileId: IFetchProfilePoliciesByCategoryIdRes;
      }>({
        query,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            profileId,
            categoryId,
          },
        },
      });

      if (results?.data?.getPoliciesByCategoryIdAndProfileId) {
        return results.data.getPoliciesByCategoryIdAndProfileId;
      }

      throw new Error(`Failed to fetch profile policies by category ID`);
    },
  };
};

export default fetchProfilePoliciesByCategoryId;
