import { isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import { snapshot } from 'valtio';
import {
  getAllSelectableTicketFields,
  setDefaultJiraTicketFieldsValues,
  setSelectableTicketFields,
  setLatestProjects,
  setBulkDefaultJiraTicketFieldsValues,
  getPrioritiesAndLatestProjects,
  setBulkIssuesId,
} from 'jira-logic';
import ScanStore from '../../../new-scan/store/scan-store';
import { setShowCreateBulkJiraTicketDialog } from '../../active-issues/store-actions/issue-store-actions';
import IssuesStore from '../../active-issues/stores/issues-store';

import { default as MockGetPreSelectFieldsRes } from '../mocks/getPreSelectFields.json';
import IssuesExclusionsStore from '../stores/issues-exclusions-store';
import { Issue, IssuePages } from '../types/issues-types';
import { getIssueActionsByIssuesPage } from '../utils/issues-jira-utils';
import ConnectorsStore from '../../../connectors/stores/connectors-store';

export const openCreateJiraTicketDialog = async (issuesPage: IssuePages) => {
  const { selectedIssue, showTicketDialog } =
    getIssueActionsByIssuesPage(issuesPage);
  const { isActiveJiraConnection } = snapshot(ConnectorsStore);

  showTicketDialog(true);

  if (!isNull(selectedIssue) && isActiveJiraConnection) {
    try {
      const { isDemoEnabled } = snapshot(ScanStore);
      if (isDemoEnabled) {
        setSelectableTicketFields({
          labels: MockGetPreSelectFieldsRes.data.listLabels,
          priorities: MockGetPreSelectFieldsRes.data.listPriorities,
        });
        setLatestProjects(
          MockGetPreSelectFieldsRes.data.getPreSelectFields,
          MockGetPreSelectFieldsRes.data.getLatestProjects,
        );
        return;
      }
      const { selectedAggs } = snapshot(IssuesExclusionsStore);
      await getAllSelectableTicketFields({
        appId: selectedIssue.app.id,
        category: selectedIssue.category.name,
        issueOwner: selectedIssue.owners[0],
      });
      setDefaultJiraTicketFieldsValues(selectedIssue, selectedAggs);
    } catch (e) {
      logger.error(e);
      showTicketDialog(false);
    }
  }
};

export const openCreateBulkJiraTicketDialog = async () => {
  setShowCreateBulkJiraTicketDialog(true);
  try {
    const { isDemoEnabled } = snapshot(ScanStore);
    if (isDemoEnabled) {
      setSelectableTicketFields({
        labels: MockGetPreSelectFieldsRes.data.listLabels,
        priorities: MockGetPreSelectFieldsRes.data.listPriorities,
      });
      return;
    }
    const { selected } = snapshot(IssuesStore);
    const issueIds = (Object.values(selected) as Issue[])
      .filter(issue => !isNull(issue))
      .map(issue => issue.issueId);
    setBulkIssuesId(issueIds);
    await getPrioritiesAndLatestProjects();
    setBulkDefaultJiraTicketFieldsValues();
  } catch (e) {
    logger.error(e);
    setShowCreateBulkJiraTicketDialog(false);
  }
};
