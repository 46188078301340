import {
  NoConnectivityBannerInfo,
  NoConnectivityReason,
} from '../connector-connectivity-types';
import { ConnectorsByFamilyObject } from '../connectors-types';
import {
  findConfiguredInvalidConnectors,
  findConfiguredSourceControlConnectors,
} from '../utils/connectivity-utils';

export const createConnectivityBannerInfo = (
  connectorsByFamily: ConnectorsByFamilyObject[],
): NoConnectivityBannerInfo | undefined => {
  if (connectorsByFamily.length === 0) {
    return undefined;
  }

  const connectorExist =
    findConfiguredSourceControlConnectors(connectorsByFamily);
  if (!connectorExist) {
    return {
      reason: NoConnectivityReason.NotConnected,
    };
  }

  const { valid, sourceControlConnectors, restOfConnectors } =
    findConfiguredInvalidConnectors(connectorsByFamily);
  if (!valid) {
    return {
      reason: NoConnectivityReason.ExpiredOrRevoked,
      sourceControlConnectors,
      restOfConnectors,
    };
  }
  return undefined;
};
