import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent } from 'react';
import config from '../config/feedback-config.json';
import { setCategory, setText } from '../stores/feedback-store-actions';

export const changeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
  if (e.target.value.length <= config.maxCharacters) {
    setText(e.target.value);
  }
};

export const changeCategory = (e: SelectChangeEvent) => {
  setCategory(e.target.value);
};
