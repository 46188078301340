import { useCallback, useRef, useState } from 'react';

export const useCheckboxGroup = (count: number, defaultValue?: boolean) => {
  const [selected, setSelected] = useState<boolean[]>(
    new Array(count).fill(!!defaultValue),
  );
  const [allSelected, setAllSelected] = useState(!!defaultValue);
  const selectedCount = useRef(0);

  const toggleAll = useCallback(() => {
    selectedCount.current = allSelected ? 0 : selected.length;
    setSelected([...new Array(selected.length).fill(!allSelected)]);
    setAllSelected(!allSelected);
  }, [allSelected, selected.length]);

  const toggle = useCallback(
    (index: number) => {
      setSelected((prev: boolean[]) => {
        const copy = [...prev];
        copy[index] ? selectedCount.current-- : selectedCount.current++;
        copy[index] = !copy[index];
        return copy;
      });
      setAllSelected(selectedCount.current === selected.length);
    },
    [selected.length],
  );

  const updateGroup = useCallback(
    (newSize?: number) => {
      setAllSelected(!!defaultValue);
      setSelected(newSize ? [...new Array(newSize).fill(!!defaultValue)] : []);
      selectedCount.current = 0;
    },
    [defaultValue],
  );

  return { selected, toggle, toggleAll, allSelected, updateGroup };
};
