import { createAsyncState } from 'async-utils';
import { Nullable } from 'ox-common-types';
import { FilterItems, SearchInput } from 'ox-filter-utils';
import { createStore, derive } from 'store-utils';

export const initialSbomFiltersStoreValues = {
  loadingFilters: createAsyncState(),
  librariesFilterValue: '',
  filterBy: {},
  totalFilters: 0,
  searchValues: [],
  filterItems: null,
};

const baseStore = createStore<SbomFiltersStoreState>(
  initialSbomFiltersStoreValues,
  'Sbom Filters Store',
);

const SbomFiltersStore = derive(
  {
    numberOfFilters: get => {
      const { filterBy } = get(baseStore);
      return (
        Object.keys(filterBy).reduce(
          (acc, key) => acc + filterBy[key].length,
          0,
        ) || 0
      );
    },
  },
  { proxy: baseStore },
);

export interface SbomFiltersStoreState {
  loadingFilters: ReturnType<typeof createAsyncState>;
  filterBy: {};
  searchValues: SearchInput[];
  totalFilters: number;
  librariesFilterValue: string;
  filterItems: Nullable<FilterItems>;
}

export default SbomFiltersStore;
