import {
  ActiveMember,
  MemberKind,
  NewMembersData,
  PendingInvitationMember,
  Role,
  UserRequestAccess,
} from '../types/members-types';
import { snapshot } from 'valtio';
import InviteMemberStore from '../stores/invite-user-store';
import MembersStore from '../stores/members-store';
import { isValidEmail } from 'common-utils';
import {
  ScopeConfig,
  parseScopesString,
} from '@oxappsec/ox-unified-permissions/src/scopes';
import { getPermissionsByRoles } from '../../permissions/permissions-utils';
import {
  buildScopesString,
  PermissionsConfig,
  ScopeNames,
  ScopeTypes,
} from '@oxappsec/ox-unified-permissions';
import { Nullable } from 'ox-common-types';
import {
  SCOPE_SELECTION_OPTIONS,
  ScopeOptions,
} from '../types/user-scope-types';

export const reduceRelatedMembersIDs = (activeMembers: ActiveMember[]) => {
  return Object.values(
    activeMembers.reduce(
      (acc: Record<string, ActiveMember>, cur: ActiveMember) => {
        const am: ActiveMember = { ...cur };
        am.relatedIDs = [];
        const found = acc[am.email];
        if (found) {
          found.relatedIDs = [...found.relatedIDs, am.user_id];
        } else {
          acc[am.email] = { ...am, kind: MemberKind.ActiveMember };
        }
        return acc;
      },
      {},
    ),
  );
};

export const enrichPendingInvitationMember = (
  pendingInvitationMembers: PendingInvitationMember[],
): PendingInvitationMember[] => {
  return pendingInvitationMembers.map(p => ({
    ...p,
    kind: MemberKind.PendingInvitationMember,
  }));
};

export const enrichPendingRequests = (
  pendingRequests: UserRequestAccess[],
): UserRequestAccess[] => {
  return pendingRequests.map(p => ({
    ...p,
    kind: MemberKind.PendingRequestAccess,
  }));
};

export const isAlreadyInvited = (email: string) => {
  const { pendingInvitationMembers } = snapshot(InviteMemberStore);
  return pendingInvitationMembers.some(p => p.inviteeEmail === email);
};

export const isAlreadyAMember = (email: string) => {
  const { activeMembers } = snapshot(MembersStore);
  return activeMembers.some(a => a.email === email);
};

export const getInvalidEmailsObj = (emails: string[]) => {
  const defaultObject = { invalidEmails: [], invalidEmailsErrMsg: '' };
  if (emails.length === 0) {
    return defaultObject;
  }
  const invalidEmails = emails.filter(email => !isValidEmail(email));
  if (invalidEmails.length === 0) {
    return defaultObject;
  }
  return {
    invalidEmails,
    invalidEmailsErrMsg: `Invalid emails: ${invalidEmails.join(', ')}.`,
  };
};

export const getAlreadyInvitedOrMemberEmailsObj = (emails: string[]) => {
  const defaultObject = {
    alreadyInvitedOrMemberEmails: [],
    alreadyInvitedOrMemberEmailsErrMsg: '',
  };
  if (emails.length === 0) {
    return defaultObject;
  }
  const alreadyInvitedOrMemberEmails = emails.filter(email => {
    if (isAlreadyAMember(email) || isAlreadyInvited(email)) {
      return true;
    }
    return false;
  });
  if (alreadyInvitedOrMemberEmails.length === 0) {
    return defaultObject;
  }
  return {
    alreadyInvitedOrMemberEmails,
    alreadyInvitedOrMemberEmailsErrMsg: `Emails already invited: ${alreadyInvitedOrMemberEmails.join(
      ', ',
    )}.`,
  };
};

export const getScopeItems = (scopes: string) => {
  const parsedScopes = scopes && parseScopesString(scopes);
  if (parsedScopes) {
    return parsedScopes.reduce((acc: ScopeConfig[], cur) => {
      const item = { ...cur };
      cur.values = cur?.values?.filter(item => !!item);
      acc.push(item);
      return acc;
    }, []);
  }
};

export const countTotalNonReadPermissions = (
  permissions: PermissionsConfig,
): number => {
  return (permissions.Edit?.length ?? 0) + (permissions.Delete?.length ?? 0);
};

export const sortRolesByPermissionsStrength = (roles: Role[]) => {
  return roles.sort((a, b) => {
    const aPermissions = getPermissionsByRoles([a.name]);
    const bPermissions = getPermissionsByRoles([b.name]);

    const aTotalPermissions = countTotalNonReadPermissions(aPermissions);
    const bTotalPermissions = countTotalNonReadPermissions(bPermissions);

    return bTotalPermissions - aTotalPermissions; // Descending order
  });
};

export const createRolesDescriptionMap = (roles: Role[]) => {
  return roles.reduce((acc: Record<string, string>, cur) => {
    acc[cur.name] = cur.description;
    return acc;
  }, {});
};

export const getIsSubmitButtonEnabled = (params: {
  newMembersData: NewMembersData;
  selectedMember: Nullable<ActiveMember>;
  errorMessage: string;
  currentScopeOptionSelected: string;
  activeMembers: ActiveMember[];
}) => {
  const {
    activeMembers,
    newMembersData,
    selectedMember,
    errorMessage,
    currentScopeOptionSelected,
  } = params;

  const newUsersEmails = newMembersData.emails;
  const newUsersRoles = newMembersData.roles;
  const currentEmails = selectedMember
    ? [selectedMember?.email]
    : newUsersEmails;
  const selectedMemberRoles = selectedMember?.roles;
  const currentRoles = selectedMember ? selectedMemberRoles : newUsersRoles;
  const alreadyInvitedOrMemberEmails =
    getAlreadyInvitedOrMemberEmailsObj(
      currentEmails,
    ).alreadyInvitedOrMemberEmails;
  const invalidEmails = getInvalidEmailsObj(currentEmails).invalidEmails;
  const invalidEmailsEntered = selectedMember
    ? false
    : invalidEmails.length !== 0 || alreadyInvitedOrMemberEmails.length !== 0;
  const currentAppOwnersScopeItems = selectedMember
    ? selectedMember.selectedAppOwnersScopeItems
    : newMembersData.selectedAppOwnersScopeItems;
  const currentTagsScopeItems = selectedMember
    ? selectedMember.selectedTagsScopeItems
    : newMembersData.selectedTagsScopeItems;
  const userSelectedCustomScope =
    SCOPE_SELECTION_OPTIONS[ScopeOptions.CustomScope].value ===
    currentScopeOptionSelected;
  const isCustomScopeEmpty = Boolean(
    userSelectedCustomScope &&
      (!currentAppOwnersScopeItems ||
        currentAppOwnersScopeItems.length === 0) &&
      (!currentTagsScopeItems || currentTagsScopeItems.length === 0),
  );
  const currentMemberFromList = activeMembers.find(
    activeMember => activeMember.user_id === selectedMember?.user_id,
  );
  const scopesConfig = generateScopeConfig([
    {
      scopeName: ScopeNames.AppOwner,
      values: selectedMember?.selectedAppOwnersScopeItems,
    },
    {
      scopeName: ScopeNames.Tags,
      values: selectedMember?.selectedTagsScopeItems,
    },
  ]);
  const selectedMemberScope = scopesConfig
    ? buildScopesString(scopesConfig)
    : null;
  const currentMemberFromListScope = currentMemberFromList?.scopes;
  const currentMemberFromListRoles = currentMemberFromList?.roles;
  const currentMemberFromListRolesIds = currentMemberFromListRoles
    ?.map(role => role.id)
    .sort();
  const selectedMemberRolesIds = selectedMemberRoles
    ?.map(role => role.id)
    .sort();
  const noChangeInInput =
    selectedMemberScope &&
    currentMemberFromListScope &&
    selectedMemberScope === currentMemberFromListScope &&
    selectedMemberRolesIds?.join() === currentMemberFromListRolesIds?.join();

  const noEmailsSelected = currentEmails?.length === 0;
  const noRolesSelected = currentRoles?.length === 0;
  if (
    noChangeInInput ||
    noEmailsSelected ||
    invalidEmailsEntered ||
    noRolesSelected ||
    errorMessage ||
    isCustomScopeEmpty
  ) {
    return false;
  } else {
    return true;
  }
};

export const generateScopeConfig = (
  scopeItems: {
    scopeName: ScopeNames;
    values?: Nullable<string[]>;
  }[],
) => {
  return scopeItems.reduce((acc: ScopeConfig[], scopeItem) => {
    if (!scopeItem.values || scopeItem.values.length === 0) {
      return acc;
    }
    acc.push({
      scopeName: scopeItem.scopeName,
      scopeType: ScopeTypes.MultiValue,
      values: scopeItem.values,
    });
    return acc;
  }, []);
};
