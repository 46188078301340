// import { isRemoteSite } from 'env-utils';
import { env } from 'env-utils';
import apiConfig from './api-config.json';

export const resolveServiceUrl = (serviceName: keyof typeof apiConfig) => {
  // if (isRemoteSite()) {
  //   return `${window.location.origin}${apiConfig[serviceName]}`;
  // }

  return env()[`REACT_APP_${serviceName}`] || '';
};
