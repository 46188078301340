import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { trackPageView } from '../utils/hubspot-tracking-utils';
import { AppPages } from 'app-navigator';
import { logger } from 'logging-utils';
import { useSnapshot } from 'valtio';
import OrgInfoStore from '../../../../apps/web/src/organizations/stores/org-info-store';
import { CustomerTier } from '../../../../apps/web/src/organizations/organization-types';
import { useAppStore } from 'app-store';

export const HubSpotTrackingProvider = () => {
  const location = useLocation();
  const prevUrl = useRef('');

  const { organization } = useSnapshot(OrgInfoStore);
  const { user } = useAppStore();

  useEffect(() => {
    if (location.pathname !== prevUrl.current) {
      trackPageView(location.pathname);
      prevUrl.current = location.pathname;
    }
    try {
      const isPaying = organization?.customerTier === CustomerTier.Paying;
      const ixOxUser = user?.email?.includes('@ox.security');
      if (
        [
          AppPages.WorkflowManagement,
          AppPages.Applications,
          AppPages.ActiveIssues,
          AppPages.Home,
        ].includes(location.pathname) ||
        isPaying ||
        ixOxUser
      ) {
        const closeWidget = () => {
          // @ts-ignore
          window.HubSpotConversations?.widget.remove();
        };
        // @ts-ignore
        if (window.HubSpotConversations) {
          closeWidget();
        } else {
          // @ts-ignore
          window.hsConversationsOnReady = [closeWidget];
        }
      } else {
        // @ts-ignore
        window.HubSpotConversations?.widget.load();
      }
    } catch (error) {
      logger.error(
        `Failed to perform action on HubSpotConversations: ${error}`,
      );
    }
  }, [location.pathname, organization?.customerTier, user?.email]);

  return null;
};
