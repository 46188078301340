import { IconType, Nullable } from 'ox-common-types';
import { ReactComponent as AksIcon } from './icons/azure-icons/aks.svg';

export enum AzureIcons {
  aks = 'aks',
}

const azureIconsMap = new Map<AzureIcons | string, IconType>([
  [AzureIcons.aks, AksIcon],
]);

export const resolveAzureIcon = (
  icon: AzureIcons | string,
): Nullable<IconType> => {
  return azureIconsMap.get(icon) || null;
};
