import { ReactComponent as _OscarIcon } from './icons/oscar-icon.svg';

export * from './category-icons';
export * from './dev-lang-icons';
export * from './package-manager-icons';
export * from './repo-icons';
export * from './container-security-icons';
export * from './evidence-icons';
export * from './dependency-icons';
export * from './aws-icons';
export * from './azure-icons';
export * from './k8s-icons';
export const OscarIcon = _OscarIcon;
