import { Box, Switch, Typography } from '@mui/material';
import { FC } from 'react';

const VulnerabilitySwitcher: FC<
  React.PropsWithChildren<VulnerabilitySwitcherProps>
> = props => {
  const { checked, handleChange } = props;
  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <Typography variant='body2' color='text.primary'>
        Show Direct Path
      </Typography>
      <Switch checked={checked} onChange={handleChange} />
      <Typography variant='body2' color='text.primary'>
        Show All
      </Typography>
    </Box>
  );
};

export interface VulnerabilitySwitcherProps {
  handleChange: () => void;
  checked: boolean;
}

export default VulnerabilitySwitcher;
