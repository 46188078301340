import { FC, useCallback, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme, Tooltip } from '@mui/material';
import React from 'react';

const NodeTooltipAwayListener: FC<React.PropsWithChildren<Props>> = ({
  tooltip,
  children,
  openTooltip,
  open,
  closeTooltip,
}) => {
  const { classes } = useStyles();

  const tooltipRef = useRef<HTMLDivElement>(null); // Ref to track the tooltip element

  const handleTooltipClick = useCallback(event => {
    event.stopPropagation();
  }, []);

  const handleDocumentClick = useCallback(
    event => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        closeTooltip();
      }
    },
    [closeTooltip],
  );

  React.useEffect(() => {
    document.addEventListener('pointerdown', handleDocumentClick);

    return () => {
      document.removeEventListener('pointerdown', handleDocumentClick);
    };
  }, [handleDocumentClick]);
  return (
    <Tooltip
      arrow
      classes={{ tooltip: classes.tooltip }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={
        tooltip ? (
          <div ref={tooltipRef} onClick={handleTooltipClick}>
            {tooltip}
          </div>
        ) : (
          ''
        )
      }
      open={open}
      onClose={closeTooltip}
      onOpen={openTooltip}>
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  tooltip: {
    minWidth: 200,
    maxWidth: 500,
    maxHeight: 300,
    overflowY: 'auto',
  },
}));

export interface Props {
  children: React.ReactElement;
  tooltip: React.ReactNode;
  open: boolean;
  openTooltip: () => void;
  closeTooltip: () => void;
}

export default NodeTooltipAwayListener;
