import { isEqual } from 'lodash-es';
import { InventoryFilter } from 'ox-common-types';
import { snapshot } from 'valtio';
import type {
  InventoryFilterType,
  InventoryResponse,
} from '../inventory-types';
import InventoryFiltersStore from './inventory-filters-store';

export const setInventoryData = (inventoryData: InventoryResponse) => {
  InventoryFiltersStore.inventoryData = inventoryData;
};

export const addFilter = (filter: InventoryFilterType) => {
  InventoryFiltersStore.filters.push(filter);
};

export const removeFilter = (filter: InventoryFilterType) => {
  const { filters } = snapshot(InventoryFiltersStore);
  InventoryFiltersStore.filters = filters.filter(f => f !== filter);
};

export const updateFilters = (filter: InventoryFilterType) => {
  filtersExists(filter) ? removeFilter(filter) : addFilter(filter);
  return snapshot(InventoryFiltersStore).filters;
};

export const resetFilters = () => {
  InventoryFiltersStore.filters = [];
};

export const assignFilters = (searchParams: { inventoryFilters: string[] }) => {
  if (isEqual(InventoryFiltersStore.filters, searchParams.inventoryFilters))
    return;
  const validFilters = searchParams.inventoryFilters.filter(
    f => InventoryFilter[f],
  );
  InventoryFiltersStore.filters = validFilters as InventoryFilterType[];
};

export const filtersExists = (filter: InventoryFilterType) => {
  const { filters } = snapshot(InventoryFiltersStore);
  return filters.includes(filter);
};

export const setLoadingFilters = (isLoading: boolean) => {
  InventoryFiltersStore.isLoadingFilters = isLoading;
};

export const clearInventoryStore = () => {
  InventoryFiltersStore.inventoryData = null;
};
