import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';
import {
  ExcludeInput,
  ExcludePolicyResponse,
  Exclusion,
} from '../types/exclusion-types';
import mutation from './exclude-policy.gql';

const excludePolicies = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<ExcludeInput, ExcludePolicyResponse> => {
  return {
    execute: async (input: ExcludeInput): Promise<ExcludePolicyResponse> => {
      const results = await client.mutate<ExcludePolicyResponseAns>({
        mutation,
        variables: { input },
      });
      if (results.data?.excludePolicy) {
        TelemetryManager.setTelemetryAction(
          TelemetryActions.ExcludePolicy,
          input,
        );
        return results.data;
      }
      throw new Error('Faild to exclude policy, no data');
    },
  };
};

export interface ExcludePolicyResponseAns {
  excludePolicy: Exclusion;
}

export default excludePolicies;
