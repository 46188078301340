import {
  OxExclusionLabel,
  OxExclusionMode,
  OxExclusionTypeLabel,
} from '@oxappsec/exclusion-service';
import { AggregationItem, IconType } from 'ox-common-types';
import {
  MdOutlineDoNotDisturbOff,
  MdOutlineKeyOff,
  MdOutlineRemoveCircle as PlaylistRemoveIcon,
} from 'react-icons/md';
import { IconBrowserOff, IconCashOff } from '@tabler/icons-react';
import { ReactComponent as PolicyXIcon } from '../../assets/icons/policyX.svg';
import { ExcludeAlertInput, ExclusionsFilters } from '../types/exclusion-types';
import { IssueDetails } from '../../issues/common/types/issues-types';
import { snapshot } from 'valtio';
import IssuesExclusionsStore from '../../issues/common/stores/issues-exclusions-store';
import { Nullable } from '../../../../../utilities/ox-common-types/src/typescript-types';

export const ExclusionsActionIconMap = new Map<OxExclusionTypeLabel, IconType>([
  [OxExclusionTypeLabel.specificIssue, MdOutlineDoNotDisturbOff],
  [OxExclusionTypeLabel.detectionRuleInAllApps, IconCashOff as IconType],
  [OxExclusionTypeLabel.detectionRuleInApp, IconBrowserOff as IconType],
  [OxExclusionTypeLabel.appIrrelevance, PlaylistRemoveIcon],
  [OxExclusionTypeLabel.disablePolicy, PolicyXIcon],
  [OxExclusionTypeLabel.secretInAllApps, MdOutlineKeyOff],
]);

export enum ExclusionLabel {
  ExcludeThisIssueOnly = 'Exclude this issue only',
  ExcludeTheSecretsInAllApplications = 'Exclude the secret(s) in all applications',
  ExcludeTheDetectionRuleInAllApplications = 'Exclude the detection rule in all applications',
  ExcludeTheDetectionRuleInThisApplication = 'Exclude the detection rule in this application',
}

export const getExclusionTypeFromLabel = (label: OxExclusionLabel) => {
  switch (label) {
    case 'Exclude this issue only':
      return OxExclusionTypeLabel.specificIssue;
    case 'Exclude the secret(s) in all applications':
      return OxExclusionTypeLabel.secretInAllApps;
    case 'Exclude the detection rule in all applications':
      return OxExclusionTypeLabel.detectionRuleInAllApps;
    case 'Exclude the detection rule in this application':
      return OxExclusionTypeLabel.detectionRuleInApp;
    default:
      return OxExclusionTypeLabel.specificIssue;
  }
};

export const snackBarSpplitingName = (input: string) => {
  if (input.length > window.innerWidth / 10) {
    return input.slice(0, window.innerWidth / 10) + '...';
  }
  return input;
};

export const getFilteredFilters = (filterExclusionsBy?: ExclusionsFilters) => {
  return (
    filterExclusionsBy &&
    Object.keys(filterExclusionsBy).reduce((acc: ExclusionsFilters, key) => {
      const filterKey = key as keyof ExclusionsFilters;
      const filterValue = filterExclusionsBy[filterKey] as string[] | undefined;
      if (filterValue && filterValue.length > 0) {
        acc[filterKey] = filterValue;
      }
      return acc;
    }, {})
  );
};

export const getExcludeAlertInput = (input: {
  selectedIssue: Pick<
    IssueDetails,
    | 'issueId'
    | 'falsePostiveIssueComment'
    | 'snoozeExpiredDate'
    | 'isFalsePositive'
    | 'aggregations'
    | 'exclusions'
    | 'exclusionIssueComment'
  >;
  exclusionMode: OxExclusionMode;
  selectedAggs: Nullable<AggregationItem[]>;
}): ExcludeAlertInput => {
  const { excludeOxRuleId, excludeCategoryId } = snapshot(
    IssuesExclusionsStore,
  );
  const { selectedIssue, exclusionMode, selectedAggs } = input;

  const defaultExclusionCategory = selectedIssue.exclusions.find(
    exclusion => exclusion.isDefault,
  );
  return {
    oxIssueId: selectedIssue.issueId,
    comment:
      selectedIssue.exclusionIssueComment ||
      selectedIssue.falsePostiveIssueComment,
    exclusionMode: exclusionMode,
    expiredAt: selectedIssue.snoozeExpiredDate,
    aggItemsSelected: Boolean(
      selectedAggs &&
        selectedIssue.aggregations?.items?.length > selectedAggs.length,
    ),
    rule: {
      oxRuleId: excludeOxRuleId || defaultExclusionCategory?.oxRuleId,
      aggIds: selectedAggs?.map(agg => agg.aggId),
      id: excludeCategoryId || defaultExclusionCategory?.id, // This field is for BC - if oxRuleId is not available then use this field
    },
  };
};
