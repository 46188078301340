import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Divider,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import DevIssueHeaderCell from './DevIssueHeaderCell';
import CategoryIconsMap from '@oxappsec/ox-consolidated-categories/lib/src/ox-categories/icons';
import { CategoryDisplayName } from '@oxappsec/ox-consolidated-categories';
import {
  SettingsName,
  SettingsSubType,
} from '../../../../../../../settings/types/settings-types';
import SettingsStore from '../../../../../../../settings/stores/settings-store';
import { useSnapshot } from 'valtio';
import { formatDistanceToNow } from 'date-fns';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CustomLink } from 'ox-react-components';
import LearnMoreItem from './LearnMoreItem';
import IssueCardSeverity from '../../../../IssueCard/IssueCardSeverity';
import { LatestCommit } from '../../../../../types/issues-types';
import CorrelatedIssue from '../../../../IssueCard/CorrelatedIssue';

const DevIssueSummaryHeader: FC<
  React.PropsWithChildren<DevIssueSummaryHeaderProps>
> = ({
  category,
  severity,
  filters,
  latestCommit,
  moveToTab,
  correlatedIssueId,
  registryType,
  appType,
}) => {
  const { classes } = useStyles();
  const CategoryIcon = CategoryIconsMap.get(category as CategoryDisplayName);
  const theme = useTheme();
  const { orgSettingsMapByName } = useSnapshot(SettingsStore);
  const isEnableAdvancedOptionsToolTip = orgSettingsMapByName
    .find(s => s.name === SettingsName.Tooltips)
    ?.settings.find(
      s => s.settingsSubType === SettingsSubType.AdvancedOptionsToolTip,
    )?.enabled;
  return (
    <Box
      display='flex'
      flexDirection='row'
      gap={2}
      alignItems='center'
      justifyContent='space-between'>
      <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
        <DevIssueHeaderCell title='Category'>
          <Box
            display='flex'
            flexDirection='row'
            gap={1}
            alignItems='flex-start'>
            {CategoryIcon && (
              <CategoryIcon
                color={theme.palette.primary.main}
                style={{ flexShrink: 0, fontSize: 16, marginTop: 3 }}
              />
            )}
            <Typography variant='body1' color='text.primary'>
              {category}
            </Typography>
          </Box>
        </DevIssueHeaderCell>
        <Divider orientation='vertical' flexItem />
        <DevIssueHeaderCell title='Severity'>
          <Box
            display='flex'
            flexDirection='row'
            gap={1}
            alignItems='flex-start'>
            <IssueCardSeverity
              severity={severity}
              isEnableAdvancedOptionsToolTip={isEnableAdvancedOptionsToolTip}
              filters={filters}
            />
          </Box>
        </DevIssueHeaderCell>
        <Divider orientation='vertical' flexItem />
        {latestCommit?.date && (
          <>
            <DevIssueHeaderCell title='Latest Commit'>
              <CustomLink
                href={latestCommit.commitLink}
                variant='body1'
                color='text.primary'
                className={classes.overflow}
                value={formatDistanceToNow(new Date(latestCommit.date), {
                  addSuffix: true,
                })}></CustomLink>
            </DevIssueHeaderCell>
            <Divider orientation='vertical' flexItem />
          </>
        )}

        {latestCommit && (
          <>
            <DevIssueHeaderCell title='Latest Code Match'>
              <Tooltip
                arrow
                componentsProps={{
                  tooltip: { sx: { maxWidth: window.innerWidth } },
                }}
                title={
                  <SyntaxHighlighter
                    language={'javascript'}
                    style={theme.palette.mode === 'light' ? docco : dracula}
                    startingLineNumber={
                      latestCommit.snippetLineNumber
                        ? Number(latestCommit.snippetLineNumber)
                        : 1
                    }
                    showLineNumbers>
                    {latestCommit.snippet}
                  </SyntaxHighlighter>
                }>
                <Typography
                  variant='body1'
                  color='text.primary'
                  className={classes.overflow}>
                  {latestCommit.match}
                </Typography>
              </Tooltip>
            </DevIssueHeaderCell>
            <Divider orientation='vertical' flexItem />
          </>
        )}

        {latestCommit && (
          <LearnMoreItem
            title='View all commits'
            onClick={() => moveToTab('Commits')}
          />
        )}
      </Box>

      {correlatedIssueId && category && (
        <CorrelatedIssue
          className={classes.correlatedIssue}
          correlatedIssueId={correlatedIssueId}
          category={category}
          appType={appType}
          registryType={registryType}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    WebkitLineClamp: 2,
    wordBreak: 'break-word',
    WebkitBoxOrient: 'vertical',
  },
  correlatedIssue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export interface DevIssueSummaryHeaderProps {
  category: string;
  severity: string;
  moveToTab: (tab: string) => void;
  filters: {};
  latestCommit: LatestCommit;
  correlatedIssueId?: string;
  registryType?: string;
  appType?: string;
}

export default DevIssueSummaryHeader;
