import { snapshot, useSnapshot } from 'valtio';
import ScanStore from '../store/scan-store';

export const scanStore = () => {
  return snapshot(ScanStore);
};

export const useScanStore = () => {
  return useSnapshot(ScanStore);
};
