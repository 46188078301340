import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import { PageSetting } from '../../types';
import mutation from './reset-user-view-settings.gql';

const resetUserViewSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (): Promise<Nullable<PageSetting[]>> => {
      try {
        const results = await client.mutate<SetViewSettingsRes>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
        });
        if (!results.data || !results.data.resetUserViewSettings) {
          return null;
        }
        return results.data.resetUserViewSettings.settings;
      } catch (error) {
        logger.error('Failed to reset user view settings', error);
        return null;
      }
    },
  };
};

interface SetViewSettingsRes {
  resetUserViewSettings: { settings: PageSetting[] };
}

export default resetUserViewSettings;
