import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import { UpdateMemberInput } from '../../types/members-types';
import mutation from './update-member.gql';
import { logger } from 'logging-utils';

const updateMember = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      updateMemberInput: UpdateMemberInput,
    ): Promise<Nullable<UpdateMemberInput>> => {
      try {
        const results = await client.mutate({
          mutation,
          variables: { updateMemberInput },
        });

        if (!results || !results.data) {
          logger.error('Failed to update member', updateMemberInput);
          return null;
        }

        return results.data.updateMember;
      } catch (error) {
        logger.error('Failed to update member', updateMemberInput);
        return null;
      }
    },
  };
};

export default updateMember;
