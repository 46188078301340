import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { getEnvVar } from 'env-utils';
import defaultRumValues from './data-dog-default-config.json';
import defaultLogsValues from './data-dog-default-logs-config.json';

export const dataDogLogsConfig = {
  clientToken: getEnvVar(
    'DATADOG_RUM_CLIENT_TOKEN',
    defaultLogsValues.clientToken,
  ) as string,
  site: getEnvVar('DATADOG_RUM_SITE', defaultLogsValues.site) as string,
  forwardErrorsToLogs: getEnvVar(
    'DATADOG_LOGS_FORWARD_ERRORS',
    defaultLogsValues.forwardErrorsToLogs,
  ) as boolean,
  sampleRate: Number(
    getEnvVar('DATADOG_LOGS_SAMPLE_RATE', defaultLogsValues.sampleRate),
  ),
  service: getEnvVar(
    'DATADOG_RUM_SERVICE',
    defaultLogsValues.service,
  ) as string,
  env: getEnvVar('ENVIRONMENT', defaultLogsValues.env) as string,
  version: getEnvVar(
    'DATADOG_LOGS_VERSION',
    defaultLogsValues.version,
  ) as string,
};

export const dataDogRumConfig = {
  applicationId: getEnvVar(
    'DATADOG_RUM_APP_ID',
    defaultRumValues.applicationId,
  ) as string,
  clientToken: getEnvVar(
    'DATADOG_RUM_CLIENT_TOKEN',
    defaultRumValues.clientToken,
  ) as string,
  site: getEnvVar('DATADOG_RUM_SITE', defaultRumValues.site) as string,
  service: getEnvVar('DATADOG_RUM_SERVICE', defaultRumValues.service) as string,
  version: getEnvVar('DATADOG_RUM_VERSION', defaultRumValues.version) as string,
  env: getEnvVar('ENVIRONMENT', defaultRumValues.env) as string,
  sampleRate: Number(
    getEnvVar('DATADOG_RUM_SAMPLE_RATE', defaultRumValues.sampleRate),
  ),
  trackInteractions: getEnvVar(
    'DATADOG_RUM_TRACK_INTERACTIONS',
    defaultRumValues.trackInteractions,
  )
    ? Boolean(
        getEnvVar(
          'DATADOG_RUM_TRACK_INTERACTIONS',
          defaultRumValues.trackInteractions,
        ),
      )
    : undefined,
  defaultPrivacyLevel: getEnvVar(
    'DATADOG_RUM_PRIVACY_LEVEL',
    defaultRumValues.defaultPrivacyLevel,
  ) as DefaultPrivacyLevel,
};
