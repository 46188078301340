import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';

interface PoliciesProfileUIState {
  loadingProfilePoliciesByCategory: boolean;
  loadingProfileOverview: boolean;
  savePoliciesInProgress: boolean;
  createNewProfileInProgress: boolean;
  disableSavePoliciesButton: boolean;
  showSaveAsModal: boolean;
  showUnSavedProfileChanges: boolean;
  changeProfileInContextId: string;
  generalPoliciesPageError: Nullable<{
    errorTitle: string;
    errorMsg: string;
  }>;
}

const PoliciesProfileUIStateStore = createStore<PoliciesProfileUIState>(
  {
    loadingProfilePoliciesByCategory: false,
    loadingProfileOverview: false,
    savePoliciesInProgress: false,
    createNewProfileInProgress: false,
    disableSavePoliciesButton: true,
    showSaveAsModal: false,
    showUnSavedProfileChanges: false,
    changeProfileInContextId: '',
    generalPoliciesPageError: null,
  },
  'Policies Profile UI Store',
);

export default PoliciesProfileUIStateStore;
