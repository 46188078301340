import { snapshot } from 'valtio';
import DrawerStore from '../stores/drawer-store';

export const setOpenDrawer = (isOpen: boolean): void => {
  DrawerStore.open = isOpen;
};

export const isDrawerOpen = (): boolean => {
  const { open } = snapshot(DrawerStore);
  return open;
};
