import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { FetchNearlyExpiredExclusionsResponse } from '../../types/exclusion-types';
import query from './get-nearly-expired-exclusions.gql';
import { ApolloClientCache } from 'ox-common-types';

const fetchNearlyExpiredExclusions = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async () => {
      try {
        const results =
          await client.query<FetchNearlyExpiredExclusionsResponse>({
            query,
            fetchPolicy: ApolloClientCache.NoCache,
          });
        if (!results.data) return null;
        return results.data.getNearlyExpiredExclusions;
      } catch (e) {
        logger.error(`Failed to fetch exclusions filters: ${e}`, e);
        return null;
      }
    },
  };
};

export default fetchNearlyExpiredExclusions;
