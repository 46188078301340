import { addDays } from 'date-fns';
import { GraphInfoOutput, IssuesScore } from './issues-trends-types';
import { SeverityType } from '../../ox-common-types/src';

export const createGraphInfo = (
  issuesScore: IssuesScore[],
  severityTypeFilterList: SeverityType[],
): { data: GraphInfoOutput['data']; labels: number[] } => {
  const data = severityTypeFilterList.reduce((acc, key) => {
    if (issuesScore.some(item => item[key] !== 0)) {
      acc[key] = issuesScore.map(item => item[key]);
    }
    return acc;
  }, {} as Record<string, number[]>);

  const labels = issuesScore.map(item => item.scanDate);

  // in case of one data point - enrich the data
  if (hasOnlyOneDataPoint(labels)) {
    return addEmptyPoint(labels, data);
  }

  return { labels, data };
};

const hasOnlyOneDataPoint = (labels: number[]) => labels.length === 1;

const addEmptyPoint = (l: number[], d: GraphInfoOutput['data']) => {
  const currentDate = l[0];
  const dayBefore = addDays(currentDate, -1).getTime();
  const labels = [dayBefore, l[0]];

  const data = Object.keys(d).reduce((acc, curr) => {
    acc[curr] = [0, ...(acc[curr] || [])];
    return acc;
  }, d);

  return { labels, data };
};
