import {
  OxExclusionId,
  OxExclusionLabel,
  OxExclusionMode,
  OxExclusionScope,
  OxExclusionType,
  OxExclusionTypeLabel,
} from '@oxappsec/exclusion-service';
import { RepoTypes } from 'ox-common-types';
import { FilterItem } from 'ox-filter-utils';
import { Nullable } from '../../../../../utilities/ox-common-types/src/typescript-types';

export interface Exclusion {
  id: string;
  exclusionType: OxExclusionType;
  exclusionId: string;
  modifiedBy: string;
  modifiedIssues: number;
  createdAt: Date;
  updatedAt: Date;
  exclusionAppliedOn: string;
  exclusionMatch: ExclusionMatch[];
  appName: string;
  policyName: string;
  appType: RepoTypes;
  comment: string;
  index: number;
  expiredAt?: Date | null;
  isActive: boolean;
  inDayExpired: boolean | null;
  inWeekExpired: boolean | null;
  label: OxExclusionLabel;
  exclusionScope?: OxExclusionScope;
  exclusionTypeLabel: OxExclusionTypeLabel;
  oxIssueId?: string;
}

export interface ExclusionMatch {
  key: string;
  value: string;
}

export interface ExclusionResponse {
  exclusions: Exclusion[];
  totalExclusions: number;
  totalFilteredExclusions: number;
}

export interface FetchExclusionsResponse {
  getAllExclusions: ExclusionResponse;
  getExclusionsFilters: ExclusionsFilters;
  getExclusions: ExclusionResponse;
}

export interface ExclusionsFiltersLazyResponse {
  getExclusionsFiltersLazy: { exclusionsFilters: FilterItem[] };
}

export interface FetchNearlyExpiredExclusionsResponse {
  getNearlyExpiredExclusions: {
    count: number;
    exclusions: Exclusion[];
  };
}

export enum ExclusionSortKey {
  ExclusionType = 'Type',
  ModifiedIssues = 'Initial Modified issues count',
  ModifiedBy = 'Created By',
  ExcludedDate = 'Excluded Date',
  AppliedOn = 'Issue',
  Match = 'Match',
  AppName = 'Application Name',
  Policy = 'Policy Name',
  Comment = 'Comment',
  MenuOptions = 'Menu Options',
  Snooze = 'Snooze',
}

export enum ExclusionsOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface ExcludeApplicationsResponse {
  exclusions: Exclusion[];
}

export interface ExcludePolicyResponse {
  excludePolicy: Exclusion;
}

export interface ExcludeInput {
  appId: string;
  appName: string;
  issueName: string;
  policyId: string;
  policyName: string;
  issueId: string;
  appType: string;
  policyCategory: string;
  comment?: string;
}

export interface ExcludeAppInput {
  appId: string;
  appName: string;
  comment?: string;
}

export interface ExcludeAlertInput {
  oxIssueId: string;
  rule: {
    oxRuleId?: Nullable<OxExclusionId>;
    aggIds?: string[];
    id?: string; // This field is for BC - if oxRuleId is not available then use this field
  };
  comment?: string;
  exclusionMode: OxExclusionMode;
  expiredAt?: Date;
  aggItemsSelected?: boolean;
  exclusionTypeLabel?: OxExclusionTypeLabel;
}

export interface ReportFPInput {
  reportedAlertInput: ExcludeAlertInput;
  isExclude: boolean;
}
export interface ExcludeAlertsResponse {
  exclusions: Exclusion[];
}

export enum MakeRelevantType {
  Default = 'default',
  Always = 'always',
}

export enum ExclusionsMatchTypes {
  realMatch = 'Match',
  fileName = 'File Name',
  source = 'Source',
  applicaiton = 'Application',
  policy = 'Policy',
  alert = 'Alert',
  sha = 'SHA',
  image = 'Image',
  service = 'Service',
  resource = 'Resource',
  accountId = 'Account ID',
  ruleId = 'Rule ID',
  user = 'User',
  url = 'URL',
}

export interface UpdateExpiredAtByIdInput {
  exclusionId: string;
  expiredAt?: Date | null;
}

export interface FetchExclusionsInput {
  filters?: ExclusionsFilters;
  limit?: number;
  offset?: number;
}

export interface ExclusionsFilters {
  appName?: string[];
  exclusionType?: string[];
  policyName?: string[];
  modifiedBy?: string[];
  issueName?: string[];
}

export type LoadExclusionsParams = {
  getMoreItems?: boolean;
  cache?: boolean;
  limit?: number;
  type?: string;
  isOpen?: boolean;
};

export enum ExclusionsCacheQueryNames {
  GetExclusionsFiltersLazy = 'getExclusionsFiltersLazy',
  GetExclusions = 'getExclusions',
}

export const EXCLUSIONS_CACHE_QUERY_NAMES = [
  ExclusionsCacheQueryNames.GetExclusionsFiltersLazy,
  ExclusionsCacheQueryNames.GetExclusions,
];
