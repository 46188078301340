import { ReactComponent as MaliciousCodeIcon } from './assets/malicious-code.svg';
import { ReactComponent as PublicExploitRegularIcon } from './assets/public-exploit-regular.svg';
import { ReactComponent as PublicExploitManyIcon } from './assets/public-exploit-many.svg';
import { ReactComponent as PublicExploitUnavailableIcon } from './assets/public-exploit-unavailable.svg';
import { ReactComponent as ExploitApplicableIcon } from './assets/exploit-applicable.svg';
import { ReactComponent as ExploitNoApplicableIcon } from './assets/exploit-not-applicable.svg';
import { ReactComponent as WidespreadAttackUsageIcon } from './assets/widespread-attack-usage.svg';
import { ReactComponent as NoKnownAttackUsageIcon } from './assets/no-known-attack-usage.svg';
import { ReactComponent as AttackVectorNetworkIcon } from './assets/attack-vector-network.svg';
import { ReactComponent as AttackVectorLocalIcon } from './assets/attack-vector-local.svg';
import { ReactComponent as AttackVectorPhysicalIcon } from './assets/attack-vector-physical.svg';
import { ReactComponent as AttackVectorAdjacentIcon } from './assets/attack-vector-adjacent.svg';
import { ReactComponent as CommunityBuzzIcon } from './assets/community-buzz.svg';
import { ReactComponent as VulnerableFunctionInUseIcon } from './assets/vulnerable-function-in-use.svg';
import { ReactComponent as VulnerableFunctionNotInUseIcon } from './assets/vulnerable-function-not-in-use.svg';
import { ReactComponent as DirectDependencyIcon } from './assets/direct.svg';
import { ReactComponent as DeepLevelIndirectDependencyIcon } from './assets/indirect.svg';
import { ReactComponent as FirstLevelIndirectDependencyIcon } from './assets/indirect-1.svg';
import { ReactComponent as LowFutureExpoitRiskIcon } from './assets/speed-2.svg';
import { ReactComponent as MediumFutureExpoitRiskIcon } from './assets/speed-1.svg';
import { ReactComponent as HighFutureExpoitRiskIcon } from './assets/speed.svg';
import { ReactComponent as ExtensivePatchingIcon } from './assets/high-cve-mitigation-development.svg';

export enum CveSeverity {
  MaliciousCode = 'Malicious Code in Dependency',
  PublicExploitRegular = 'Public Exploit Exists',
  PublicExploitMany = 'Multiple Public Exploits',
  PublicExploitUnavailable = 'Public Exploit Unavailable',
  ExploitApplicable = 'AI Simulated Exploitable',
  ExploitNoApplicable = 'AI Simulated Unexploitable',
  ActiveAttackUsage = 'KEV - Actively Exploited',
  WidespreadAttackUsage = 'Widespread Active Attack Usage',
  NoKnownAttackUsage = 'No Known Attack Usage',
  AttackVectorNetwork = 'Network Attack Vector',
  AttackVectorLocal = 'Local Attack Vector',
  AttackVectorPhysical = 'Physical Attack Vector',
  AttackVectorAdjacent = 'Adjacent Network Attack Vector',
  CommunityBuzz = 'Community Buzz',
  VulnerableFunctionInUse = 'Vulnerable Function Used',
  VulnerableFunctionNotInUse = 'Vulnerable Function Not Used',
  DirectDependency = 'Direct Dependency',
  IndirectDependency = 'Indirect Dependency',
  DeepLevelIndirectDependency = 'Indirect Dependency: >1 Level',
  FirstLevelIndirectDependency = 'Indirect Dependency: First Level',

  ExtensivePatching = 'Extensive Patching',

  HighFutureExpoitRisk = 'EPSS - High Exploit Risk',
  MediumFutureExpoitRisk = 'EPSS - Medium Exploit Risk',
  LowFutureExpoitRisk = 'EPSS - Low Exploit Risk',
}

export const CveSeverityIconMap = new Map<
  CveSeverity,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
>([
  [CveSeverity.MaliciousCode, MaliciousCodeIcon],
  [CveSeverity.PublicExploitRegular, PublicExploitRegularIcon],
  [CveSeverity.PublicExploitMany, PublicExploitManyIcon],
  [CveSeverity.PublicExploitUnavailable, PublicExploitUnavailableIcon],
  [CveSeverity.ExploitApplicable, ExploitApplicableIcon],
  [CveSeverity.ExploitNoApplicable, ExploitNoApplicableIcon],
  [CveSeverity.ActiveAttackUsage, WidespreadAttackUsageIcon],
  [CveSeverity.WidespreadAttackUsage, WidespreadAttackUsageIcon],
  [CveSeverity.NoKnownAttackUsage, NoKnownAttackUsageIcon],
  [CveSeverity.AttackVectorNetwork, AttackVectorNetworkIcon],
  [CveSeverity.AttackVectorLocal, AttackVectorLocalIcon],
  [CveSeverity.AttackVectorPhysical, AttackVectorPhysicalIcon],
  [CveSeverity.AttackVectorAdjacent, AttackVectorAdjacentIcon],
  [CveSeverity.CommunityBuzz, CommunityBuzzIcon],
  [CveSeverity.VulnerableFunctionInUse, VulnerableFunctionInUseIcon],
  [CveSeverity.VulnerableFunctionNotInUse, VulnerableFunctionNotInUseIcon],
  [CveSeverity.DirectDependency, DirectDependencyIcon],
  [CveSeverity.IndirectDependency, DeepLevelIndirectDependencyIcon],
  [CveSeverity.DeepLevelIndirectDependency, DeepLevelIndirectDependencyIcon],
  [CveSeverity.FirstLevelIndirectDependency, FirstLevelIndirectDependencyIcon],
  [CveSeverity.ExtensivePatching, ExtensivePatchingIcon],
  [CveSeverity.HighFutureExpoitRisk, HighFutureExpoitRiskIcon],
  [CveSeverity.MediumFutureExpoitRisk, MediumFutureExpoitRiskIcon],
  [CveSeverity.LowFutureExpoitRisk, LowFutureExpoitRiskIcon],
]);

export const CveSeverityOrder = {
  [CveSeverity.DirectDependency]: 1,
  [CveSeverity.IndirectDependency]: 1,
  [CveSeverity.FirstLevelIndirectDependency]: 1,
  [CveSeverity.DeepLevelIndirectDependency]: 1,
  [CveSeverity.AttackVectorNetwork]: 2,
  [CveSeverity.AttackVectorLocal]: 2,
  [CveSeverity.AttackVectorPhysical]: 2,
  [CveSeverity.AttackVectorAdjacent]: 2,
  [CveSeverity.MaliciousCode]: 3,
  [CveSeverity.ExploitApplicable]: 4,
  [CveSeverity.ExploitNoApplicable]: 4,
  [CveSeverity.WidespreadAttackUsage]: 5,
  [CveSeverity.ActiveAttackUsage]: 5,
  [CveSeverity.NoKnownAttackUsage]: 5,
  [CveSeverity.HighFutureExpoitRisk]: 5,
  [CveSeverity.MediumFutureExpoitRisk]: 5,
  [CveSeverity.LowFutureExpoitRisk]: 5,
  [CveSeverity.PublicExploitMany]: 6,
  [CveSeverity.PublicExploitRegular]: 6,
  [CveSeverity.PublicExploitUnavailable]: 6,
  [CveSeverity.ExtensivePatching]: 7,
  [CveSeverity.CommunityBuzz]: 8,
  [CveSeverity.VulnerableFunctionInUse]: 9,
  [CveSeverity.VulnerableFunctionNotInUse]: 9,
};
