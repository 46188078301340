import { RequireOnlyOne } from 'ox-common-types';

export interface BaseHubSpotFormInput {
  [HubSpotOxBaseFormFields.Email]?: string;
  [HubSpotOxBaseFormFields.FirstName]?: string;
  [HubSpotOxBaseFormFields.LastName]?: string;
  [HubSpotOxBaseFormFields.UserId]?: string;
}

export interface HubSpotOxAppFormInput extends BaseHubSpotFormInput {
  [HubSpotOxAppFormFields.DemoDataRequested]?: boolean;
  [HubSpotOxAppFormFields.AzureReposConnected]?: boolean;
  [HubSpotOxAppFormFields.GitHubConnected]?: boolean;
  [HubSpotOxAppFormFields.GitLabConnected]?: boolean;
  [HubSpotOxAppFormFields.BitBucketConnected]?: boolean;
  [HubSpotOxAppFormFields.BitBucketStashConnected]?: boolean;
  [HubSpotOxAppFormFields.VerifiedEmail]?: boolean;
}
export interface HubSpotFeedbackFormInput extends BaseHubSpotFormInput {
  [HubSpotFeedbackFormFields.OxAppFeedback]: string;
  [HubSpotFeedbackFormFields.OxAppFeedbackCategory]: string;
}

interface IHubSpotActionsFormInput extends BaseHubSpotFormInput {
  [HubSpotActionsFormFields.LastExclusionDate]?: string;
  [HubSpotActionsFormFields.LastJiraCreationDate]?: string;
  [HubSpotActionsFormFields.LastMemberInvitedDate]?: string;
}

export type HubSpotActionsFormInput = RequireOnlyOne<
  IHubSpotActionsFormInput,
  | HubSpotActionsFormFields.LastExclusionDate
  | HubSpotActionsFormFields.LastJiraCreationDate
  | HubSpotActionsFormFields.LastMemberInvitedDate
>;

export enum HubSpotOxBaseFormFields {
  Email = 'email',
  FirstName = 'firstname',
  LastName = 'lastname',
  UserId = 'userid',
}

export enum HubSpotOxAppFormFields {
  DemoDataRequested = 'demo_data_requested',
  GitHubReposCount = 'github_repos_count',
  GitLabReposCount = 'gitlab_repos_count',
  BitbucketReposCount = 'bitbucket_repos_count',
  AzureReposCount = 'azure_repos_count',
  BitbucketStashReposCount = 'bitbucket_stash_repos_count',
  AWSCodeCommitReposCount = 'aws_codecommit_repos_count',
  GitLabConnected = 'gitlab_connected',
  GitHubConnected = 'github_connected',
  BitBucketConnected = 'bitbucket_connected',
  BitBucketStashConnected = 'bitbucket_stash_connected',
  AzureReposConnected = 'azure_repos_connected',
  VerifiedEmail = 'verified_email',
  EKS = 'eks',
}

export enum HubSpotFeedbackFormFields {
  OxAppFeedback = 'ox_app_feedback',
  OxAppFeedbackCategory = 'ox_app_feedback_category',
}

export enum HubSpotActionsFormFields {
  LastExclusionDate = 'ox_app_last_exclusion_date',
  LastJiraCreationDate = 'ox_app_last_jira_creation_date',
  LastMemberInvitedDate = 'ox_app_last_member_invited_date',
}

export enum HubSpotUserActions {
  Exclusion = 'exclusion',
  ExclusionsFilters = 'exclusions filters',
  CreateJiraTicket = 'jiraTicket',
  InviteMember = 'inviteMember',
}

export enum HubSpotFormsIds {
  OxAppForm = '648d5417-533d-4cd1-97e7-57097499092b',
  OxAppFeedbackForm = 'cd01786a-9a97-43ae-8c93-80924cab6b73',
  OxAppActionsForm = '3b824216-460f-438f-8171-a38cb20809e2',
}
