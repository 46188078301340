import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { IssuesScore } from 'issue-trend-utils';
import { logger } from 'logging-utils';
import { ApolloClientCache, FetchOrgScanInfoInput } from 'ox-common-types';
import query from './get-issues-score.gql';

const getIssuesScore = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getIssuesTrendInput: FetchOrgScanInfoInput | {},
      cache: boolean = true,
    ) => {
      try {
        const results = await client.query<{
          getIssuesTrendData: IssuesScore[];
        }>({
          query,
          variables: { getIssuesTrendInput },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NoCache,
        });

        return results.data.getIssuesTrendData;
      } catch (error) {
        logger.error('Failed to fetch issues score', error);
        return null;
      }
    },
  };
};

export default getIssuesScore;
