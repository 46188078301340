import { policiesClient } from 'api-clients';
import addExclusion from '../exclusions/add-exclusion-service';
import fetchProfilePoliciesByCategoryId from './fetch-profile-policies-by-category-service';

const policiesAPI = {
  fetchProfilePoliciesByCategoryId:
    fetchProfilePoliciesByCategoryId(policiesClient),
  addExclusion: addExclusion(policiesClient),
};
export default policiesAPI;
