import { alpha, Theme, Tooltip, Typography } from '@mui/material';
import { important } from 'ox-common-types';
import { FC } from 'react';
import { MdAccountCircle as AccountCircleIcon } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';
import { MultiLineEllipsis } from './MultiLineEllipsis';

export const OwnerCell: FC<React.PropsWithChildren<OwnerCellProps>> = ({
  owners,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.ownerCell}>
      {owners && (
        <div className={classes.owner}>
          {owners.length > 0 && (
            <div className={classes.iconContainer}>
              <AccountCircleIcon
                color='primary'
                className={classes.ownerIcon}
              />
            </div>
          )}
          <Tooltip
            arrow
            placement='top'
            title={
              owners.length > 1
                ? owners.map((owner, index) => {
                    return (
                      <Typography
                        variant='body2'
                        color='text.primary'
                        ml={1}
                        key={index}>
                        {owner}
                      </Typography>
                    );
                  })
                : ''
            }>
            <div style={{ display: 'inline-flex' }}>
              <MultiLineEllipsis
                lines={2}
                ml={1}
                variant='body2'
                className={classes.text}>
                {owners.join(', ')}
              </MultiLineEllipsis>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  ownerCell: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  owner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ownerIcon: {
    fontSize: important('1rem'),
    color: theme.palette.primary.main,
  },
  owners: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    color: alpha(theme.palette.text.primary, 0.6),
  },
}));

export interface OwnerCellProps {
  owners: string[];
}
