import {
  PermissionScope,
  PermissionTypes,
  PermissionsConfig,
  RolesPermissionsConfig,
  Roles,
} from '@oxappsec/ox-unified-permissions';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';

export const getPermissionsByRoles = (roles: Roles[]): PermissionsConfig => {
  const permissionsConfig: PermissionsConfig = {
    Edit: [],
    Read: [],
    Delete: [],
  };
  // TODO: Make sure each permission scope has unique values in it (for example, no duplicates in Read, Edit, Delete arrays)
  roles?.forEach(role => {
    const rolePermissions = RolesPermissionsConfig[
      Roles[role]
    ] as PermissionsConfig;

    if (!rolePermissions) {
      logger.error(`Failed to get permissions by roles ${role}`);
      return;
    }
    if (rolePermissions.Read)
      permissionsConfig.Read!.push(...rolePermissions.Read);
    if (rolePermissions.Delete)
      permissionsConfig.Delete!.push(...rolePermissions.Delete);
    if (rolePermissions.Edit)
      permissionsConfig.Edit!.push(...rolePermissions.Edit);
  });
  return permissionsConfig;
};

// This is for the next phase with custom roles
export const generatePermissionsConfig = (
  permissionItem: {
    permissionScope: PermissionScope;
    permissionTypes?: Nullable<PermissionTypes[]>;
  }[],
) => {
  return permissionItem.reduce((acc: PermissionsConfig, permissionItem) => {
    if (
      !permissionItem.permissionTypes ||
      permissionItem.permissionTypes.length === 0
    ) {
      return acc;
    }
    acc[permissionItem.permissionScope] = permissionItem.permissionTypes;
    return acc;
  }, {});
};

export const hasPermissions = (
  requiredPermissions: {
    permissionType: PermissionTypes;
    permissionScope: PermissionScope;
  }[],
  userPermissions: PermissionsConfig | undefined | null,
) => {
  return requiredPermissions.every(permission =>
    userPermissions?.[permission.permissionScope]?.includes(
      permission.permissionType,
    ),
  );
};
