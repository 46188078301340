import { isNull } from 'lodash-es';
import { OrganizationsStore } from 'organizations';
import { snapshot, useSnapshot } from 'valtio';
import { Organization } from '../organization-types';
import organizationService from '../services';
import {
  availableOrganizations,
  setChosenOrganization,
  setIsLoadingOrg,
  setOrganizations,
  updateOrganizationInOrgList,
} from '../store-actions/organization-store-actions';
import { setLastLoggedInOrg } from '../utils/local-storage-utils';

export const addOrganization = (organization: Organization): void => {
  const organizations = [...availableOrganizations(), organization];
  setOrganizations(organizations);
  setLastLoggedInOrg(organization);
};

export const getAvailableOrganizations = async () => {
  try {
    const { isLoading } = snapshot(OrganizationsStore);
    if (isLoading) {
      return;
    }
    setIsLoadingOrg(true);
    const organizations = await organizationService.getOrganizations.execute();

    if (isNull(organizations)) {
      throw new Error('fetch user organizations list failed');
    }

    setOrganizations(organizations);

    return organizations;
  } finally {
    setIsLoadingOrg(false);
  }
};

export const getOrgDisplayNameById = (orgId: string): string => {
  const { organizations } = snapshot(OrganizationsStore);

  const organization = organizations.find(org => org.id === orgId);
  if (!organization) {
    throw new Error(
      `Organization with ID ${orgId} was not found in the organizations list`,
    );
  }
  return organization.display_name;
};

export const changeOrganizationName = async (orgDisplayName: string) => {
  const result = await organizationService.changeOrganizationName.execute(
    orgDisplayName,
  );
  if (!result) {
    throw new Error("Failed changing organization's name");
  }
  const { organizations, chosenOrganization } = snapshot(OrganizationsStore);
  if (organizations.length && chosenOrganization) {
    const updatedOrg: Organization = {
      ...chosenOrganization,
      display_name: result.display_name,
    };
    updateOrganizationInOrgList(updatedOrg);
    setChosenOrganization(updatedOrg);
    setLastLoggedInOrg(updatedOrg);
  }
};

export const useOrganizations = () => useSnapshot(OrganizationsStore);
