import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { AddCredentialsInput } from '../../connectors-types';
import mutation from './update-credentials.gql';

const updateCredentials = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      addCredentialsInput: AddCredentialsInput,
    ): Promise<boolean> => {
      const results = await client.mutate<UpdateCredentialsResponse>({
        mutation,
        variables: { addCredentialsInput, credentialsIndex: 0 },
      });

      if (results?.data) {
        return results.data.updateCredentials;
      }

      throw new Error(
        `Failed to update credentials for connector ${addCredentialsInput.connectorID}`,
      );
    },
  };
};

interface UpdateCredentialsResponse {
  updateCredentials: boolean;
}

export default updateCredentials;
