import { AppOwnerRoleType } from 'ox-common-types';
import { Roles } from '@oxappsec/ox-unified-permissions';
import { ITag } from '../../../../apps/web/src/applications/applications-types';

export interface ActiveMember {
  user_id: string;
  name: string;
  picture: string;
  email: string;
  roles: Role[];
  relatedIDs: string[];
  kind:
    | MemberKind.ActiveMember
    | MemberKind.PendingInvitationMember
    | MemberKind.PendingRequestAccess;
}

export enum MemberKind {
  ActiveMember = 'ActiveMember',
  PendingInvitationMember = 'PendingInvitationMember',
  PendingRequestAccess = 'PendingRequestAccess',
}

interface Role {
  id: string;
  name: Roles;
  description: string;
}

export interface AppOwnersByAppIdInput {
  appIds: string[];
  roles: AppOwnerRoleType[];
}

export interface AppOwnerData {
  email?: string;
  name?: string;
  emptyAppOwners?: boolean;
  appIds: string[];
}

export interface AppOwnersByRole {
  owners: AppOwnerData[];
  role: AppOwnerRoleType;
}

export interface AppOwnerTag extends ITag {
  email: string;
  children: string[];
}
