import * as React from 'react';
import { FC, useCallback } from 'react';
import {
  setGeneralError,
  setPageError,
} from '../../actions/app-general-actions';
import RouteChangeListener from '../routing/RouteChangeListener';

const ClearErrorOnRouteChange: FC<React.PropsWithChildren<unknown>> = () => {
  const handleRouteChange = useCallback(() => {
    setGeneralError(false);
    setPageError(false);
  }, []);

  return <RouteChangeListener onRouteChange={handleRouteChange} />;
};

export default ClearErrorOnRouteChange;
