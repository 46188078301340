import { AttackPathType } from '../attack-path-types';

const paddingConfig = {
  [AttackPathType.K8s]: 80,
  [AttackPathType.Image]: 80,
  [AttackPathType.Api]: 55,
  [AttackPathType.Cloud]: 80,
  [AttackPathType.App]: 25,
  [AttackPathType.Action]: 175,
  [AttackPathType.Webhook]: 50,
  [AttackPathType.Artifact]: 50,
  [AttackPathType.User]: 50,
  [AttackPathType.Service]: 50,
  [AttackPathType.Repos]: 50,
  [AttackPathType.SBOMRepos]: 50,
  [AttackPathType.SeverityFactor]: 400,
  [AttackPathType.SeverityFactorCategory]: 100,
  [AttackPathType.Commit]: 200,
  [AttackPathType.ServiceItem]: 20,
  [AttackPathType.UserItem]: 20,
  [AttackPathType.WebhookItem]: 20,
  [AttackPathType.ArtifactItem]: 20,
  [AttackPathType.Oscar]: 20,
  [AttackPathType.CommitItem]: 30,
  [AttackPathType.Cve]: 30,
  [AttackPathType.Cwe]: 30,
  [AttackPathType.Compliance]: 40,
  [AttackPathType.Library]: 30,
  [AttackPathType.JiraTicket]: 20,
  [AttackPathType.Slack]: 20,
  [AttackPathType.Pr]: 20,
  [AttackPathType.Detection]: 50,
  // Default case
  default: 45,
};

const verticalPaddingConfig = {
  [AttackPathType.Detection]: 200,
  [AttackPathType.Action]: 250,
  [AttackPathType.App]: 150,
  [AttackPathType.Tag]: 75,
  [AttackPathType.Commit]: 200,
  [AttackPathType.SeverityFactor]: 200,
  [AttackPathType.SeverityFactorCategory]: 250,
  [AttackPathType.CommitItem]: 175,
  [AttackPathType.Oscar]: 175,
  [AttackPathType.Cve]: 120,
  [AttackPathType.Cwe]: 120,
  [AttackPathType.Compliance]: 120,
  [AttackPathType.Library]: 175,
  [AttackPathType.Webhook]: 170,
  [AttackPathType.Artifact]: 170,
  [AttackPathType.User]: 170,
  [AttackPathType.Service]: 170,
  [AttackPathType.Repos]: 170,
  [AttackPathType.SBOMRepos]: 170,
  [AttackPathType.JiraTicket]: 120,
  [AttackPathType.Slack]: 120,
  [AttackPathType.Pr]: 120,
  // Default case
  default: 120,
};

export const getVerticalPadding = (type: AttackPathType) => {
  return verticalPaddingConfig[type] || verticalPaddingConfig.default;
};

export const getHorizontalPadding = (type: AttackPathType) => {
  return paddingConfig[type] || paddingConfig.default;
};
