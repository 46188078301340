import { PermissionTypes } from '@oxappsec/ox-unified-permissions';

export interface PageSetting {
  page: string;
  value: boolean;
  iconKey?: string;
  type: ViewPreferenceType;
  children?: PageSetting[];
  permissionType?: PermissionTypes;
  aggregation?: string[];
  disabled?: boolean;
}

export enum ViewSettingType {
  User = 'user',
}

export enum ViewPreferenceType {
  Tree = 'tree',
}

export interface GetViewSettingsResponse {
  settings: PageSetting[];
}
