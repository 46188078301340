import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import SeverityChangeReasonList from '../../../../IssueItem/SeverityChangeReason/SeverityChangeReasonList';

const SeverityFactorsTabContainer: FC = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);

  return (
    <>
      {selectedIssue && (
        <SeverityChangeReasonList
          key={'Severity Factors'}
          data={selectedIssue.severityChangedReason}
          withDescription
        />
      )}
    </>
  );
};

export default SeverityFactorsTabContainer;
