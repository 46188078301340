import { aggregationsColumnsConfig as config } from '@oxappsec/ox-consolidated-configs';
import { AggregationsTable } from 'ox-react-components';
import { FC, useEffect } from 'react';
import { useCheckboxGroup } from 'react-utils';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { setSelectedAggs } from '../../../../../store-actions/issues-exclusions-store-actions';
import { AggregationItem } from 'ox-common-types';

const AggregationsTableTabContainer: FC = () => {
  const { selectedIssue } = useSnapshot(IssuesStore) || {};
  const { selected, allSelected, toggle, toggleAll, updateGroup } =
    useCheckboxGroup(selectedIssue!.aggregations?.items?.length, true);
  useEffect(() => {
    updateGroup(selectedIssue?.aggregations.items.length);
  }, [selectedIssue?.aggregations.items, updateGroup]);

  useEffect(() => {
    const selectedAggs = selected.reduce((acc, _, index) => {
      if (selected[index] && selectedIssue?.aggregations?.items[index]) {
        acc.push(selectedIssue?.aggregations?.items[index]);
      }
      return acc;
    }, [] as AggregationItem[]);
    setSelectedAggs(selectedAggs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  if (!selectedIssue) return null;
  return (
    <>
      {selectedIssue && (
        <AggregationsTable
          key='Aggregations'
          config={config[selectedIssue?.aggregations?.type]}
          info={selectedIssue.violationInfoTitle}
          items={selectedIssue.aggregations?.items}
          selected={selected}
          allSelected={allSelected}
          toggle={toggle}
          toggleAll={toggleAll}
          exclusionCategory={selectedIssue.exclusionCategory}
          isInfo
          sourceToolType={selectedIssue.resource?.id}
        />
      )}
    </>
  );
};

export default AggregationsTableTabContainer;
