import { orderBy } from 'lodash-es';
import {
  IrrelevantApp,
  RawIrrelevantApp,
  ReasonFilter,
} from '../irrelevant-apps-types';
import {
  NO_CODE_CHANGES,
  REASON_FILTER_OPTIONS,
} from '../stores/reason-filters-store';

const createIrrelevantAppsInfo = (
  data: RawIrrelevantApp[],
): {
  result: IrrelevantApp[];
  filters: ReasonFilter[];
} => {
  const result = data.map(app => ({
    appId: app.appId,
    name: app.appName,
    reasons: [...new Set(app.irrelevantReasons?.slice(0, 3))] || [],
    lastCodeChange: app.lastCodeChange,
    overrideRelevance: app.overrideRelevance,
    type: app.type,
    fakeApp: app.fakeApp,
  }));

  const convertFilterReason = (filterReason: string) => {
    if (filterReason?.includes(NO_CODE_CHANGES)) {
      return REASON_FILTER_OPTIONS[NO_CODE_CHANGES];
    } else {
      return REASON_FILTER_OPTIONS[filterReason];
    }
  };

  const filters: ReasonFilter[] = [];

  result.forEach(irrelevantApp => {
    irrelevantApp.reasons.forEach(reason => {
      const selectedFilter = filters.find(
        filter => filter.displayName === reason,
      );
      const filterReasonAsFilterInServer = convertFilterReason(reason);
      if (selectedFilter) {
        selectedFilter.count += 1;
      } else if (filterReasonAsFilterInServer) {
        filters.push({
          displayName: reason,
          value: filterReasonAsFilterInServer,
          count: 1,
        });
      }
    });
  });

  const sortedFilters = orderBy(
    filters,
    [filter => filter.displayName.toLowerCase()],
    ['asc'],
  );

  return { result, filters: sortedFilters };
};

export default createIrrelevantAppsInfo;
