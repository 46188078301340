import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable, ServiceExecute } from 'ox-common-types';
import { Exclusion } from '../../types/exclusions-types';
import mutation from './add-exclusion.gql';

const addExclusion = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<Exclusion[], AddExclusionOutput | null> => {
  return {
    execute: async (
      exclusions: Exclusion[],
    ): Promise<Nullable<AddExclusionOutput>> => {
      try {
        const results = await client.mutate<{
          addExclusions: AddExclusionOutput;
        }>({
          mutation,
          variables: { exclusions },
        });
        if (!results.data) {
          throw new Error('Failed to add exclusions');
        }
        return results.data.addExclusions;
      } catch (error) {
        logger.error('Failed to add exclusions', error);
        return null;
      }
    },
  };
};

interface AddExclusionOutput {
  exclusions: { uid: string }[];
}
export default addExclusion;
