export enum NoConnectivityReason {
  NotConnected = 'not-connected',
  ExpiredOrRevoked = 'expired-or-revoked',
}

export interface NoConnectivityBannerInfo {
  reason?: NoConnectivityReason;
  sourceControlConnectors?: NoConnectivityConnectorsInfo[];
  restOfConnectors?: NoConnectivityConnectorsInfo[];
}

export interface NoConnectivityConnectorsInfo {
  id: string;
  name: string;
  parentConnectorId?: string;
}
