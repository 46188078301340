import { Theme } from '@mui/material';
import { important } from 'ox-common-types';
import { OxDataGrid } from 'ox-react-components';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { JiraTicketRef } from '../../../types/issues-types';
import { useTableConfig } from './useTableConfig';

const IssueActionsTable: FC<
  React.PropsWithChildren<IssueActionsTableProps>
> = ({ loading, data }) => {
  const { classes } = useStyles();
  const columns = useTableConfig();
  return (
    <OxDataGrid
      loading={loading}
      className={classes.table}
      columns={columns}
      rows={data}
      rowHeight={35}
      headerRowHeight={35}
    />
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  table: {
    height: '100%',

    '--rdg-header-background-color': important(
      theme.palette.background.default,
    ),
  },
}));

export interface IssueActionsTableProps {
  data: JiraTicketRef[];
  loading: boolean;
}

export default IssueActionsTable;
