import { darken } from '@mui/material';
import { ChartArea } from 'chart.js';

// taken from: https://www.chartjs.org/docs/latest/samples/advanced/linear-gradient.html
export const createChartJSGradient = (
  ctx: CanvasRenderingContext2D,
  chartArea: ChartArea,
  color: string,
  paletteMode: 'dark' | 'light',
) => {
  const gradient = ctx.createLinearGradient(
    0,
    chartArea.bottom,
    0,
    chartArea.top,
  );
  const shadowColor = darken(color, paletteMode === 'dark' ? 0.5 : 0.2);
  gradient.addColorStop(0, shadowColor);
  gradient.addColorStop(0.7, color);
  gradient.addColorStop(1, color);

  return gradient;
};
