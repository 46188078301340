import { logger } from 'logging-utils';
import OscarHoverStore from '../stores/oscar-hover-store';

export const handleCellMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
  if (
    e.currentTarget.dataset.column !== undefined &&
    e.currentTarget.dataset.row !== undefined
  ) {
    const columnNum = Number(e.currentTarget.dataset.column);
    const rowNum = Number(e.currentTarget.dataset.row);
    OscarHoverStore.hoverCell = {
      column: columnNum,
      row: rowNum,
    };
  } else {
    logger.error(
      'handleCellMouseEnter() called without data-column and data-row attributes present on the element',
    );
  }
};

export const handleCellMouseLeave = () => {
  resetHoverStore();
};

export const resetHoverStore = () => {
  OscarHoverStore.hoverCell = undefined;
};
