import { useEffect, useRef } from 'react';

export const useMountEffect = (
  fun: () => void,
  shouldRunAnyway?: boolean,
): void => {
  const isFirstMount = useRef(true);
  useEffect(() => {
    if (isFirstMount.current || shouldRunAnyway) {
      fun();
      isFirstMount.current = false;
    }
  }, [fun, shouldRunAnyway]);
};
