import { marked } from 'marked';
import { DocType } from '../components/DownloadsUsersDialog';
import { Organization } from '../organization-types';
import { Nullable } from 'ox-common-types';

export const downloadUsersToFile = (
  orgId: string,
  organization: Nullable<Organization>,
  devCountFlag: boolean,
  docType?: DocType,
) => {
  if (!organization) {
    return;
  }

  const { users, usersFromApi: apiUsersList } = organization;

  if (!users || !apiUsersList || !docType) {
    return;
  }

  const commiters = users.filter(i => !i.filtered);
  const filteredCommitters = users.filter(i => i.filtered);

  const getUserLink = (link: string): string => {
    if (docType === DocType.html || docType === DocType.md) {
      return `[Link to commit](${link})`;
    } else {
      return link;
    }
  };

  // do not mess with indentation. It is important for markdown formatting
  // header

  let content = `This document includes 3 lists:
    
    1. Organization Users from API 
    2. Committers from last 1 year 
    3. Extended list of unique committers 
  
    Scroll to browse all lists
  
    Users from API: ${apiUsersList.length}
  
    `;

  apiUsersList.forEach((user, index) => {
    content += `
${index} ${user.id} ${user.name} ${user.username}
`;
  });

  content += `
  
  
  Committers from last 1 year: ${commiters.length}
  
    `;

  commiters.forEach((user, index) => {
    content += `
  ${index + 1} <${user.committerEmail}> ${user.committerAuthor}
     ${user.latestSha}
     ${user.latestCommitDate}
     ${getUserLink(user.link)}
  
    `;
  });

  content += `
    
  Extended list of unique committers : ${filteredCommitters.length} \n\n`;
  filteredCommitters.forEach((user, index) => {
    content += `
  ${index + 1} <${user.committerEmail}> ${user.committerAuthor}
     ${user.latestSha}
     ${user.latestCommitDate}
     ${getUserLink(user.link)}
  
    `;
  });

  if (docType === DocType.html) {
    content = marked.parse(content);
  }
  const blob = new Blob([content], { type: `text/${docType}` });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `users-list-${orgId}.${docType}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
