import { SeverityType } from './misc';

export const SeverityTypeNames = {
  [SeverityType.Info]: 'Info',
  [SeverityType.Low]: 'Low',
  [SeverityType.Medium]: 'Medium',
  [SeverityType.High]: 'High',
  [SeverityType.Critical]: 'Critical',
  [SeverityType.Appoxalypse]: 'Appoxalypse',
};

export const severitiesHeatMap = [
  {
    key: SeverityType.Appoxalypse,
    title: SeverityTypeNames[SeverityType.Appoxalypse],
    value: 5,
  },
  {
    key: SeverityType.Critical,
    title: SeverityTypeNames[SeverityType.Critical],
    value: 4,
  },
  {
    key: SeverityType.High,
    title: SeverityTypeNames[SeverityType.High],
    value: 3,
  },
  {
    key: SeverityType.Medium,
    title: SeverityTypeNames[SeverityType.Medium],
    value: 2,
  },
  {
    key: SeverityType.Low,
    title: SeverityTypeNames[SeverityType.Low],
    value: 1,
  },
  {
    key: SeverityType.Info,
    title: SeverityTypeNames[SeverityType.Info],
    value: 0,
  },
];

export const severityToName = severitiesHeatMap
  .sort((a, b) => a.value - b.value)
  .map(s => s.title);

export interface Severities {
  info: number;
  low: number;
  medium: number;
  high: number;
  critical: number;
  appox: number;
}
