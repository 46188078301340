import { Box, TableCell, Typography, useTheme } from '@mui/material';
import { CustomLink } from 'ox-react-components';
import { FC } from 'react';

const SbomRowTitle: FC<React.PropsWithChildren<SbomRowTitleProps>> = props => {
  const { color, title, text, withBorder, link } = props;
  const theme = useTheme();
  return (
    <TableCell
      component='div'
      sx={{
        minHeight: 63,
        verticalAlign: 'top',
        ...(!withBorder && { border: 'none' }),
        padding: theme.spacing(1, 1),
        margin: theme.spacing(0, 1, 0, 0),
      }}>
      <Box display='flex' flexDirection='column' gap={0.5}>
        <Typography
          noWrap
          fontWeight='bold'
          color='text.primary'
          variant='body1'>
          {title}
        </Typography>

        <CustomLink
          value={text!}
          href={link}
          noWrap
          color={color || 'text.secondary'}
          variant='body1'
        />
      </Box>
    </TableCell>
  );
};

export interface SbomRowTitleProps {
  color?: string;
  text?: string;
  title?: string;
  withBorder?: boolean;
  link?: string;
}

export default SbomRowTitle;
