import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import query from './get-labels.gql';

const getLabels = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<{ searchString: string }, Nullable<SearchLabelsResponse>> => {
  return {
    execute: async ({
      searchString,
    }): Promise<Nullable<SearchLabelsResponse>> => {
      try {
        const results = await client.query<SearchLabelsResponse>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: {
            provider: TicketProvider.Jira,
            query: searchString,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to search labels', error);
        return null;
      }
    },
  };
};

export interface SearchLabelsResponse {
  searchLabels: string[];
}

export default getLabels;
