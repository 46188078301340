import {
  FilterData,
  FilterItems,
  FilterPage,
  getFilterItemsLabels,
} from 'ox-filter-utils';
import IrrelevantAppsFiltersStore from '../stores/irrelevant-apps-filters-store';
import { createSimpleAsyncAction } from 'async-utils';
import getFilterLabels from 'ox-filter-utils/src/get-filter-labels/get-filter-labels';
import { snapshot } from 'valtio';
import { AppPages, navigate } from 'app-navigator';
import IrrelevantAppsStore from '../stores/irrelevant-apps-store';

export const setStoreIrrelevantAppsOpenFilters = (
  resultsFilters: FilterData[],
  filterItems: FilterItems,
) => {
  Object.keys(filterItems).forEach(key => {
    const selectedResultFilterItem = resultsFilters.find(
      item => item.type === key,
    );
    if (
      IrrelevantAppsFiltersStore.lazyFilters &&
      IrrelevantAppsFiltersStore.lazyFilters[key] &&
      selectedResultFilterItem
    ) {
      IrrelevantAppsFiltersStore.lazyFilters[key].items =
        selectedResultFilterItem.items;
    }
  });
};

export const setSearchValues = (searchValue: string, type: string) => {
  IrrelevantAppsFiltersStore.searchValues[type] = searchValue;
};

export const setIrrelevantAppsFilterLabels = createSimpleAsyncAction(
  async () => {
    const { filterItems } = snapshot(IrrelevantAppsFiltersStore);

    if (!filterItems) {
      const results = await getFilterLabels(FilterPage.IrrelevantApps);
      const filterItems: FilterItems = getFilterItemsLabels(results);

      IrrelevantAppsFiltersStore.lazyFilters = filterItems;
    }
  },
  {
    asyncState: IrrelevantAppsFiltersStore.loadingFilters,
    errorMessage: 'Failed to load applications filter labels',
  },
);

export const clearAllFilters = () => {
  clearFiltersInStore();
  navigate(AppPages.IrrelevantApps);
};

export const clearFiltersInStore = () => {
  IrrelevantAppsFiltersStore.filterBy = {};
  IrrelevantAppsFiltersStore.numberOfFilters = 0;
  IrrelevantAppsFiltersStore.totalFilteredApps = IrrelevantAppsStore.total;
};

export const setTotalFilteredApps = (total: number) => {
  IrrelevantAppsFiltersStore.totalFilteredApps = total;
};
