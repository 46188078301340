import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  JiraComponent,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import query from './get-components.gql';

const getComponents = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  { searchString: string; projectKey: string },
  Nullable<GetComponentsResponse>
> => {
  return {
    execute: async ({
      searchString,
      projectKey,
    }): Promise<Nullable<GetComponentsResponse>> => {
      try {
        const results = await client.query<GetComponentsResponse>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: {
            query: searchString,
            projectKey,
            provider: TicketProvider.Jira,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to fetch project components', error);
        return null;
      }
    },
  };
};

export interface GetComponentsResponse {
  getComponentsByProjectKey: JiraComponent[];
}

export default getComponents;
