import { ApolloError, isApolloError } from '@apollo/client';
import { LogoutOptions } from '@auth0/auth0-react';
import { logger } from 'logging-utils';
import { openSnackbar } from 'snackbar-utils';
import { handleSelfDeletion } from '../../members/api/members-api';
import { changeOrganizationName } from '../../organizations/api/organizations-api';
import settingsService from '../services';
import {
  disableSaveUpdatedOrgDisplayName,
  getUpdatedOrgDisplayName,
  setIsLoadingDeleteOrganization,
  setIsLoadingUpdateOrgDisplayName,
  setRemoveOrgModalVisibility,
  setSettingErrorMessage,
} from '../store-actions/org-settings-store-actions';

export const deleteOrganization = async (
  logout: (options?: LogoutOptions) => void,
): Promise<void> => {
  try {
    setSettingErrorMessage('');
    setIsLoadingDeleteOrganization(true);

    const deleteOrganization = await settingsService.deleteAllMembers.execute();
    if (!deleteOrganization) {
      throw new Error('API call ended with false response');
    }

    setRemoveOrgModalVisibility(false);
    handleSelfDeletion(logout);
  } catch (error) {
    logger.error('Delete Organization Action Failed', error);
    const apolloError = error as ApolloError;
    let errorMsg =
      isApolloError(apolloError) && apolloError.networkError
        ? apolloError.networkError.message
        : 'Delete organization failed. Please try again later.';

    setSettingErrorMessage(errorMsg);
  } finally {
    setIsLoadingDeleteOrganization(false);
  }
};

export const deleteDemoOrg = async () => {
  const deleteOrganization = await settingsService.deleteOrganization.execute();
  if (!deleteOrganization) {
    logger.error('Failed to remove demo organization');
  }
};

export const updateOrgDisplayName = async () => {
  try {
    const orgDisplayName = getUpdatedOrgDisplayName();
    if (!orgDisplayName) {
      return;
    }

    setIsLoadingUpdateOrgDisplayName(true);
    await changeOrganizationName(orgDisplayName);

    disableSaveUpdatedOrgDisplayName();
  } catch (err) {
    logger.error('failed to update org name', err);
    openSnackbar('Failed to update organization name ', { variant: 'error' });
  } finally {
    setIsLoadingUpdateOrgDisplayName(false);
  }
};
