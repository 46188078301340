import { Theme, Tooltip, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { FC } from 'react';
import { FaMinus } from 'react-icons/fa';
import { makeStyles } from 'tss-react/mui';

export const DateFormat: FC<React.PropsWithChildren<DateFormatProps>> = ({
  date,
  color,
  bigSize,
}) => {
  const { classes } = useStyles();
  return (
    <Tooltip
      arrow
      placement='top'
      title={
        date && date.getFullYear() === 1970
          ? ''
          : date
          ? date.toLocaleString()
          : ''
      }>
      <Typography
        color={color ? color : 'text.secondary'}
        variant={bigSize ? 'body1' : 'body2'}
        className={classes.date}>
        {date && date.getTime() ? (
          formatDistanceToNow(date, { addSuffix: true })
        ) : (
          <FaMinus />
        )}
      </Typography>
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  date: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    display: '-webkit-box',
    whiteSpace: 'pre-wrap',
    lineClamp: 2,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
}));

export interface DateFormatProps {
  date?: Date;
  color?: string;
  bigSize?: boolean;
}
