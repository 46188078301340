import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { SaveConnectorAdditionalResourcesInput } from '../../connectors-types';
import mutation from './save-additional-tabs-settings.gql';

const saveConnectorAdditionalResources = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      saveAdditionalResourceSettingsInput: SaveConnectorAdditionalResourcesInput,
    ) => {
      try {
        const results = await client.mutate<{
          saveAdditionalResourceSettings: { success: boolean };
        }>({
          mutation,
          variables: { saveAdditionalResourceSettingsInput },
        });

        return results.data?.saveAdditionalResourceSettings;
      } catch (error) {
        logger.error(error);
        return null;
      }
    },
  };
};

export default saveConnectorAdditionalResources;
