import { IconButton, Tooltip } from '@mui/material';
import { FC, useCallback } from 'react';
import { MdContentCopy as ContentCopyIcon } from 'react-icons/md';
import { openSnackbar } from 'snackbar-utils';

const CopyOption: FC<React.PropsWithChildren<CopyOptionsProps>> = ({
  value,
}) => {
  const copyToClipboard = useCallback(() => {
    if (value) {
      navigator.clipboard.writeText(value);
    }
    openSnackbar(
      'Copied!',
      {
        variant: 'success',
        autoHideDuration: 2000,
      },
      {
        hideCloseIcon: true,
        maxwidth: 10,
      },
    );
  }, [value]);
  return (
    <Tooltip arrow title={'Copy'} placement='top'>
      <span>
        <IconButton onClick={copyToClipboard} size='small' color='primary'>
          <ContentCopyIcon fontSize='1rem' />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export interface CopyOptionsProps {
  value?: string;
}

export default CopyOption;
