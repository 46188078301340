import { isDevelopment } from 'env-utils';
import {
  FeatureFlagKeys,
  FeatureFlags,
} from '../../feature-flags/feature-flags-types';
import { MenuItemConfig } from '../config/main-menu-config';
import { PageSetting } from '../../view-preferences/types';
import { isViewEnabled } from '../../view-preferences/utils/view-preferences-utils';

export const isItemHidden = (
  item: MenuItemConfig,
  flags: FeatureFlags,
  preferences: PageSetting[],
  parent?: string,
) => {
  let show = true;
  if (item.isDevelopment) {
    show &&= isDevelopment();
  }
  if (item.featureFlagKey !== undefined) {
    show &&= flags[item.featureFlagKey];
  }

  if (!flags[FeatureFlagKeys.viewPreferencesEnabled]) {
    return !show;
  }

  const path = parent ? `${parent}-${item.label}` : item.label;
  show &&= isViewEnabled(path, preferences);

  return !show;
};
