import { Link, Typography } from '@mui/material';
import React, { FC } from 'react';

const ErrorBoundaryMessage: FC<
  React.PropsWithChildren<ErrorBoundaryMessageProps>
> = ({ linkToHomepage }) => {
  return (
    <>
      <Typography variant='body2'>
        Our team has been notified of this error, if it persists please contact
        us at{' '}
        <a href='mailto:support@ox.security' target='_blank' rel='noreferrer'>
          support@ox.security
        </a>
      </Typography>
      {linkToHomepage && (
        <Link href='/' mt={3}>
          Back to Homepage
        </Link>
      )}
    </>
  );
};

interface ErrorBoundaryMessageProps {
  linkToHomepage?: boolean;
}

export default ErrorBoundaryMessage;
