import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { GetApplicationsInput, MinifiedApp } from '../../applications-types';
import query from './get-applications-ids.gql';

const getApplicationsIds = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getApplicationsInput: GetApplicationsInput,
      cache = true,
    ) => {
      try {
        const results = await client.query<{
          getApplications: ApplicationsResponse;
        }>({
          query,
          variables: { getApplicationsInput },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });

        return results.data.getApplications;
      } catch (error) {
        logger.error('Failed to fetch applications ids', error);
        return null;
      }
    },
  };
};

export interface ApplicationsResponse {
  applications: MinifiedApp[];
}

export default getApplicationsIds;
