import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';
import IssueCweList from '../../../../IssueItem/IssueCweList';

const IssueCweListContainer: FC<IssueDrawerTabProps> = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);

  return <>{selectedIssue && <IssueCweList items={selectedIssue.cweList} />}</>;
};

export default IssueCweListContainer;
