import { createSimpleAsyncAction } from 'async-utils';
import { logger } from 'logging-utils';
import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import settingsService from '../services';
import {
  getOrgSettingsByMap,
  setConfiguredTimeHoverAdvancedOptions,
  setCurrentTabValue,
  setDefaultTabValue,
  setDisabledTextInputsSubSettings,
  setIsButtonLoading,
  setLoadingUpdate,
  setOrgSettingsMapByName,
  setOrgSettingsMapByNameResponseState,
  setPersonalSettings,
  setRegexArr,
  setRegexList,
  setRegexListArr,
  setRegexListObj,
  setSelectedSubSettingId,
  setSingleSetting,
  setUpdateInputModal,
  unsetIsButtonLoading,
} from '../store-actions/settings-store-actions';
import SettingsStore from '../stores/settings-store';
import {
  InputTypeSetting,
  ResetSettingsInput,
  SettingsAction,
  SettingsByName,
  SettingsObj,
  SettingsSubType,
  SettingsType,
  TabQueryParam,
  AppBranchesToScanInput,
  UpdateSettingsInput,
  settingsName,
} from '../types/settings-types';
import ApplicationsStore from '../../applications/stores/applications-store';
import {
  setApplications,
  setLoadingDefineBranch,
} from '../../applications/stores/applications-store-actions';

export const loadSingleSettings = async (settingId: string) => {
  const response = await settingsService.getSingleSettings.execute({
    id: settingId,
  });
  if (response) {
    setSingleSetting(response);
  }
};

export const loadSettingsMapByName = createSimpleAsyncAction(
  async () => {
    const response: SettingsByName[] | null =
      await settingsService.getSettingsMap.execute();
    if (response) {
      const clonedResponse = response.map(setting => structuredClone(setting));
      setOrgSettingsMapByName(response);
      setOrgSettingsMapByNameResponseState(clonedResponse);

      response.forEach(settingsByName => {
        settingsByName.settings.forEach(sObj => {
          if (sObj.inputType === InputTypeSetting.SubSettings) {
            sObj.subSettings?.forEach(se => {
              const subSettingsText = se.settings.find(
                sub => sub.inputTypeSubSetting === InputTypeSetting.Text,
              );
              const subSettingsRadio = se.settings.find(
                sub => sub.inputTypeSubSetting === InputTypeSetting.Radio,
              );
              if (subSettingsRadio?.radioOptions) {
                subSettingsRadio?.radioOptions.forEach(radio => {
                  if (radio.isChecked && !subSettingsText?.inputText) {
                    setDisabledTextInputsSubSettings(
                      radio.isChecked,
                      radio.idSubSetting,
                    );
                  } else if (
                    subSettingsText?.inputText &&
                    radio.idSubSetting === subSettingsText.dependsOnSubSetting
                  ) {
                    setDisabledTextInputsSubSettings(true, radio.idSubSetting);
                  } else {
                    setDisabledTextInputsSubSettings(false, radio.idSubSetting);
                  }
                });
              }
            });
          }

          if (sObj.inputType === InputTypeSetting.List) {
            const { settingsSubType, valueList } = sObj;
            setRegexListArr(settingsSubType, valueList || []);
          }
        });
      });
    }
  },
  {
    asyncState: SettingsStore.loading,
    errorMessage: 'Failed to load settings',
  },
);

export const loadPersonalSettings = async (filters?: Partial<SettingsObj>) => {
  const response = await settingsService.getSettings.execute(filters);
  if (response) {
    setPersonalSettings(response);
  }
};

export const updateSingleSettings = async (newSetting: SettingsObj) => {
  const currentSettings = getOrgSettingsByMap();

  setOrgSettingsMapByName(currentSettings);
};

export const updateBranchApp = async (
  input: AppBranchesToScanInput,
  showSnackbar?: boolean,
): Promise<boolean> => {
  try {
    const { applications } = snapshot(ApplicationsStore);
    setLoadingDefineBranch(true);
    const res = await settingsService.defineAppBranchesToScan.execute(input);
    if (res) {
      const updatedApps = applications?.map(app => {
        const selectedApp = input.find(
          selectedApp => selectedApp.appId === app.appId,
        );
        if (selectedApp) {
          return {
            ...app,
            branchesToScan: selectedApp.branches,
          };
        }
        return app;
      });
      setApplications({ applications: updatedApps, update: true });
      if (showSnackbar === undefined) {
        openSnackbar(`Your settings have been successfully updated!`, {
          variant: 'success',
        });
      }
      setUpdateInputModal(false);
      setSelectedSubSettingId('');
      setLoadingDefineBranch(false);
      return true;
    } else {
      openSnackbar(
        `Oops! Something went wrong while updating your settings. Please try again later.`,
        { variant: 'error' },
      );
      setLoadingUpdate(false);
      return false;
    }
  } catch (error) {
    logger.error(`Error in changing scan branch, error:${error}`);
    setLoadingUpdate(false);
    return false;
  }
};

export const updateSettings = async (
  input: UpdateSettingsInput,
  showSnackbar?: boolean,
): Promise<boolean> => {
  try {
    setLoadingUpdate(true);
    input.settingsSubType &&
      setIsButtonLoading(input.settingsSubType, SettingsAction.Update);
    const res = await settingsService.updateSettings.execute(input);
    if (res?.success) {
      if (
        input.settingsSubType ===
        SettingsSubType.ConfiguredAdvancedOptionsToolTipTime
      ) {
        if (input.configured) {
          setConfiguredTimeHoverAdvancedOptions(input.configured * 1000);
        }
      }
      if (showSnackbar === undefined) {
        openSnackbar(`Your settings have been successfully updated!`, {
          variant: 'success',
        });
      }
      loadSettingsMapByName();
      setUpdateInputModal(false);
      setSelectedSubSettingId('');
      setLoadingUpdate(false);
      return true;
    } else {
      if (res?.reasonFailed) {
        openSnackbar(res.reasonFailed, { variant: 'error' });
      } else {
        openSnackbar(
          `Oops! Something went wrong while updating your settings. Please try again later.`,
          { variant: 'error' },
        );
      }

      setLoadingUpdate(false);
      return false;
    }
  } catch (error) {
    logger.error(
      `Error in changing ${
        (input.settingsSubType && settingsName.get(input.settingsSubType)) ||
        input.settingsSubType
      }  settings, please try again later.`,
    );
    setLoadingUpdate(false);
    return false;
  } finally {
    input.settingsSubType &&
      unsetIsButtonLoading(input.settingsSubType, SettingsAction.Update);
  }
};

export const resetSettings = async (
  input: ResetSettingsInput,
): Promise<boolean> => {
  try {
    setIsButtonLoading(input.settingsSubType, SettingsAction.Reset);

    const res = await settingsService.resetSettings.execute(input);
    if (res) {
      loadSettingsMapByName();
      openSnackbar(`Setting was reset successfully!`, { variant: 'success' });

      return true;
    } else {
      openSnackbar(
        `Oops! Something went wrong while updating your settings. Please try again later.`,
        { variant: 'error' },
      );
      return false;
    }
  } catch (error) {
    logger.error(
      `Error in resetting ${
        settingsName.get(input.settingsSubType) || input.settingsSubType
      }  settings, please try again later.`,
    );
    return false;
  } finally {
    unsetIsButtonLoading(input.settingsSubType, SettingsAction.Reset);
  }
};

export const setCurrentTabFromURL = (locationSearch: string) => {
  const urlParams = new URLSearchParams(locationSearch);
  const tabParam = urlParams.get('tab');
  const tab = tabParam as TabQueryParam;
  const isValidTab = Object.values(TabQueryParam).includes(tab);
  if (isValidTab) {
    setCurrentTabValue(tab);
  } else {
    setDefaultTabValue();
  }
};

export const handleAddRegex = (
  event: React.SyntheticEvent<Element, Event>,
  newValue: string[],
  regex: string,
  settingsSubType: string,
) => {
  const newItemToAdd =
    newValue && newValue.length && newValue[newValue.length - 1].trim();

  // @ts-ignore
  if (event?.key === 'Enter' && newItemToAdd) {
    setRegexArr(settingsSubType, newValue);
    setRegexList(settingsSubType, regex);
    setRegexListObj(settingsSubType, regex);
    return true;
  }
  // @ts-ignore
  if (event.type === 'click' || event?.key === 'Backspace') {
    setRegexArr(settingsSubType, newValue);
    setRegexListArr(settingsSubType, newValue);
    return false;
  }

  setRegexList(settingsSubType, regex);
  return false;
};

export const handleInputBlur = (
  event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  input: string,
  settingsSubType: string,
) => {
  if (input === '') {
    return false;
  }
  setRegexListObj(settingsSubType, input);
  return true;
};

export const handleSendRegex = (settings: SettingsObj) => {
  const { settingsSubType, settingsType } = settings;

  const { regexList } = snapshot(SettingsStore);
  updateSettings({
    settingsSubType,
    valueList: regexList[settingsSubType],
    settingsType,
  });
};

export const handleReset = (settings: SettingsObj) => {
  const { settingsSubType } = settings;

  resetSettings({
    settingsSubType,
  });
};

export const handleResetInputText = () => {
  handleChangeInputText('');
};

export const handleChangeInputText = (inputTextUpdate: string) => {
  const { selectedSubSettingId, subSettingType } = snapshot(SettingsStore);
  if (selectedSubSettingId && subSettingType) {
    const input: UpdateSettingsInput = {
      settingsSubType: subSettingType,
      settingsType: SettingsType.Applications,
      subSettings: {
        idSubSetting: selectedSubSettingId,
        inputText: inputTextUpdate,
      },
    };
    updateSettings(input);
    setSelectedSubSettingId('');
  }
};
