import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import query from './get-request-access-configuration.gql';
import { RequestAccessConfiguration } from '../../request-access-types';

const getRequestAccessConfiguration = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (): Promise<Nullable<RequestAccessConfiguration>> => {
      try {
        const results = await client.query<GetRequestAccessConfigurationQuery>({
          query,
        });

        if (!results || !results.data) {
          return null;
        }

        return results.data.getRequestAccessConfiguration;
      } catch {
        return null;
      }
    },
  };
};

interface GetRequestAccessConfigurationQuery {
  getRequestAccessConfiguration: RequestAccessConfiguration;
}

export default getRequestAccessConfiguration;
