import { Chip, Theme, Tooltip, TooltipProps, useTheme } from '@mui/material';
import { CategoryRiskNames, SeverityAlert } from 'ox-common-types';
import React, { FunctionComponent } from 'react';
import { MdOutlineCampaign } from 'react-icons/md';
import { selectTopSeverities } from 'severity-utils';
import { makeStyles } from 'tss-react/mui';

export const IssuesBadgeHeader: FunctionComponent<
  React.PropsWithChildren<IssueBadgeHeaderProps>
> = ({
  severitiesAlerts,
  fontSize,
  handleClick,
  top,
  textColor,
  backgroundColor,
  tooltipPlacement,
  appName,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const topSeverities = selectTopSeverities(severitiesAlerts, top);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleMouseEnter = () => {
    setTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  if (!severitiesAlerts) return null;
  const indexedCategoryRiskNames = CategoryRiskNames as Record<string, string>;

  return (
    <div className={classes.riskIssuesContainer}>
      {topSeverities && (
        <Chip
          variant='outlined'
          icon={
            <Tooltip
              arrow
              title='Issue count by top severities'
              open={tooltipOpen}>
              <div
                style={{ display: 'flex', marginLeft: '16px' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <MdOutlineCampaign color='#5C94ED' size='20px' />
              </div>
            </Tooltip>
          }
          label={topSeverities.map((key, index) => {
            // for someone who did slice(0,2) - use top param from props!!!
            return (
              <Tooltip
                arrow
                title={key && key[0] && indexedCategoryRiskNames[key[0]]}
                key={index}
                placement={tooltipPlacement || 'bottom'}>
                <Chip
                  onClick={handleClick}
                  variant='outlined'
                  label={key && key[1] && key[1]}
                  data-name={key && key[0] && key[0]}
                  data-appname={appName}
                  size='small'
                  sx={{
                    margin: '4px',
                    backgroundColor,
                    borderColor:
                      theme.palette.categoryRiskColor[
                        key && key[0] ? key[0] : 'none'
                      ],
                    borderWidth: 2,
                    fontSize: fontSize
                      ? fontSize
                      : theme.typography.body2.fontSize,
                    fontWeight: 600,
                    color: textColor || theme.palette.text.primary,
                  }}
                />
              </Tooltip>
            );
          })}
          size='medium'
          sx={{
            // backgroundColor: '#5C94ED40',
            borderColor: 'none',
            border: 'none',
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 400,
            color: theme.palette.common.white,
          }}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  riskIssuesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export interface IssueBadgeHeaderProps {
  severitiesAlerts?: SeverityAlert;
  fontSize?: string;
  handleClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  top?: number;
  tooltipPlacement?: TooltipProps['placement'];
  backgroundColor?: string;
  textColor?: string;
  appName?: string;
}
