import { createStore } from 'store-utils';
import { TicketingAction, TicketingVendor } from '../types/ticketing-types';
import { Nullable } from 'ox-common-types';

export const TicketingStore = createStore<TicketingStoreState>(
  {
    showCreateTicketModal: false,
    chosenTicketingVendor: null,
    showLinkUnlinkTicketModal: false,
    showCreateBulkTicketModal: false,
    selectedAction: null,
    isLoadingSubmitAction: false,
    ticketKeyInputControl: '',
    isLoadingStatuses: false,
    isLoadingTicketingStatus: false,
  },
  'Ticketing Store',
);

export interface TicketingStoreState {
  showCreateTicketModal: boolean;
  showCreateBulkTicketModal: boolean;
  chosenTicketingVendor: Nullable<TicketingVendor>;
  showLinkUnlinkTicketModal: boolean;
  selectedAction: Nullable<TicketingAction>;
  isLoadingSubmitAction: boolean;
  ticketKeyInputControl: string;
  isLoadingStatuses: boolean;
  isLoadingTicketingStatus: boolean;
}
