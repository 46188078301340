import { User } from '@auth0/auth0-react';
import { env } from 'env-utils';

const SSO_ORG_ID = `${env().REACT_APP_AUTH0_AUDIENCE}/sso_org_id`;
const SSO_ORG_NAME = `${env().REACT_APP_AUTH0_AUDIENCE}/sso_org_name`;
const SSO_ORG_DISPLAY_NAME = `${
  env().REACT_APP_AUTH0_AUDIENCE
}/sso_org_display_name`;

export const shouldNavigateToSSOLoginPage = (user: User) => {
  return user[SSO_ORG_ID];
};

export const navigateToSSOLoginPage = (user: User) => {
  const url = new URL(`${window.origin}/sso-login`);
  url.searchParams.append('organization', user[SSO_ORG_ID]);
  url.searchParams.append('organization_name', user[SSO_ORG_NAME]);
  url.searchParams.append('display_name', user[SSO_ORG_DISPLAY_NAME]);
  window.location.href = url.toString();
};
