import { AsyncLoadingState, createAsyncState } from 'async-utils';
import { createDate } from 'common-utils';
import { format, formatRelative } from 'date-fns';
import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import { useSnapshot } from 'valtio';
import { SupplyChainItem } from '../../supply-chain/types/supplychain-types';
import {
  LastScanDate,
  OrgScanData,
  OrgScanInfo,
  OrgScanInfoBreakdown,
} from '../dashboard-types';
import {
  createOrgScanInfoBreakdownFromData,
  createOrgScanInfoFromData,
} from '../utils/dashboard-utils';

const baseStore = createStore<DashboardStoreState>(
  {
    issuesScore: undefined,
    supplyChainItems: null,
    supplyChainLoading: createAsyncState(),
    orgScanData: null,
    preventCategories: null,
    noIssuesScore: false,
    loadingTrendGraph: false,
    loadingPreventCategories: false,
  },
  'Dashboard Store',
);

const DashboardStore = derive(
  {
    coveredCategories: get => {
      const { supplyChainItems } = get(baseStore);
      return supplyChainItems?.reduce((acc, item) => {
        if (item.coverage?.covered === true) {
          acc.push(item.header.categoryName);
        }
        return acc;
      }, [] as string[]);
    },
    uncoveredCategories: get => {
      const { supplyChainItems } = get(baseStore);
      return supplyChainItems?.reduce((acc, item) => {
        if (item.coverage?.covered === false) {
          acc.push(item.header.categoryName);
        }
        return acc;
      }, [] as string[]);
    },
    orgScanInfoBreakdownInfo: (get): Nullable<OrgScanInfoBreakdown> => {
      const { orgScanData } = get(baseStore);
      return orgScanData
        ? createOrgScanInfoBreakdownFromData(orgScanData)
        : null;
    },
    orgScanInfo: (get): Nullable<OrgScanInfo> => {
      const { orgScanData } = get(baseStore);
      return orgScanData ? createOrgScanInfoFromData(orgScanData) : null;
    },
    lastScanDate: (get): Nullable<LastScanDate> => {
      const { orgScanData } = get(baseStore);
      if (!orgScanData) return null;
      const scanDate = orgScanData.scanDate;
      const lastFormatScanDate = format(scanDate, 'd LLL');
      const lastFormatScanTime = format(scanDate, 'h:mm a');
      const relativeDate = formatRelative(scanDate, Date.now());
      return {
        lastFormatScanDate,
        lastFormatScanTime,
        relativeDate: relativeDate.includes(' at ')
          ? relativeDate.slice(0, relativeDate.indexOf(' at '))
          : relativeDate,
      };
    },
    scanStartDateFormatted: get => {
      const { orgScanData } = get(baseStore);
      if (!orgScanData) return null;
      return createDate(orgScanData.scanStartDate);
    },
    scanFinishDateFormatted: get => {
      const { orgScanData } = get(baseStore);
      if (!orgScanData) return null;
      return createDate(orgScanData.scanFinishDate);
    },
    scanID: get => {
      const { orgScanData } = get(baseStore);
      if (!orgScanData) return;
      return orgScanData.scanId;
    },
    scanAdditionalInfo: get => {
      const { orgScanData } = get(baseStore);
      if (!orgScanData) return {};
      const { scanType, scanInfoStats } = orgScanData;
      let stats = {};
      try {
        stats = JSON.parse(scanInfoStats);
      } catch {}
      return {
        scanType,
        ...stats,
      };
    },
  },
  { proxy: baseStore },
);

export interface DashboardStoreState {
  issuesScore?: {
    data: { [key: string]: number[] | undefined };
    labels: number[];
  };
  supplyChainItems: Nullable<SupplyChainItem[]>;
  supplyChainLoading: AsyncLoadingState;
  orgScanData: Nullable<OrgScanData>;
  preventCategories: Nullable<string[]>;
  noIssuesScore: boolean;
  loadingTrendGraph: boolean;
  loadingPreventCategories: boolean;
}

export const useDashboardStore = () => useSnapshot(DashboardStore);

export default DashboardStore;
