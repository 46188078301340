import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Connector, ConnectorResponseObject } from '../../connectors-types';
import query from './get-connectors.gql';

const getConnectors = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (disableCache?: boolean): Promise<Connector[]> => {
      try {
        const results = await client.query<{
          getConnectors: ConnectorResponseObject[];
        }>({
          query,
          fetchPolicy: disableCache ? 'no-cache' : undefined,
        });

        return results.data.getConnectors.map(
          connectorResponse => connectorResponse.connector,
        );
      } catch (error) {
        return [];
      }
    },
  };
};

export default getConnectors;
