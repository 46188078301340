import { ListItem, MenuItemProps, Tooltip, TooltipProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { MdArrowForwardIos as ChevronRight } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

export const ExtendedMenuItem: FC<
  React.PropsWithChildren<ExtendedMenuItemProps>
> = props => {
  const { classes } = useStyles();

  const {
    subMenu,
    id,
    withIcon,
    children,
    placement,
    tooltipText,
    ...menuItemProps
  } = props;

  return (
    <Tooltip
      arrow
      placement={placement || 'left-start'}
      title={tooltipText || subMenu}
      classes={
        !tooltipText
          ? {
              tooltip: classes.tooltip,
            }
          : undefined
      }>
      <ListItem
        {...menuItemProps}
        sx={{ '> *': { minWidth: '36px !important' }, ...menuItemProps.sx }}>
        {children}
        {withIcon && <ChevronRight fontSize='small' />}
      </ListItem>
    </Tooltip>
  );
};

const useStyles = makeStyles()({
  tooltip: {
    margin: '0 !important',
    padding: '0 !important',
    maxWidth: 'none',
  },
});

interface ExtendedMenuItemProps extends MenuItemProps {
  id: string;
  subMenu: NonNullable<ReactNode>;
  withIcon?: boolean;
  placement?: TooltipProps['placement'];
  tooltipText?: string;
}
