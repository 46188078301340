import { FC } from 'react';
import { Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import NodeTooltip from '../NodeTooltip';

const SBOMReposItem: FC<React.PropsWithChildren<SBOMReposItemProps>> = ({
  lastCodeDate,
  repoCreator,
  repo,
}) => {
  return (
    <NodeTooltip title='Repo Info'>
      <Typography variant='caption'>
        Repo Name: <b>{repo}</b>
      </Typography>
      {lastCodeDate && (
        <Typography variant='caption'>
          Last Modified: <b>{formatDistanceToNow(new Date(lastCodeDate))}</b>
        </Typography>
      )}
      {repoCreator && (
        <Typography variant='caption'>
          Repo Creator: <b>{repoCreator}</b>
        </Typography>
      )}
    </NodeTooltip>
  );
};

export interface SBOMReposItemProps {
  lastCodeDate: string;
  repoCreator: string;
  repo: string;
}

export { SBOMReposItem };
