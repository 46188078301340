import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { snapshot } from 'valtio';
import {
  Connector,
  ConnectorEnableDisableResponse,
  ConnectorErrorMessages,
  DisableConnectorInput,
  EConnectorFields,
  EnableConnectorInput,
} from '../connectors-types';
import connectorService from '../services';
import {
  updateAggregatedConnectorsStatus,
  updateSingleConnectorsField,
} from '../store-actions/connectors-store-actions';
import ConnectorConfigureStore from '../stores/connector-configure-store';
import { updateSelectedConnectorField } from './connector-configure-actions';

const postSuccessAction = (result: ConnectorEnableDisableResponse) => {
  updateSingleConnectorsField(
    result.id,
    EConnectorFields.isConfigured,
    result.isConfigured,
  );
  updateSelectedConnectorField(
    result.id,
    EConnectorFields.isConfigured,
    result.isConfigured,
  );
  ConnectorConfigureStore.isLoadingEnableDisable = false;
};

const postActionError = (
  graphQLErrors?: GraphQLError[] | string,
  defaultErrorMessage?: string,
) => {
  const { selectedConnector } = snapshot(ConnectorConfigureStore);
  if (!selectedConnector) {
    return;
  }
  const { id: connectorId } = selectedConnector;
  defaultErrorMessage = defaultErrorMessage || 'Oops, we encountered an error';
  let errorMsg: string;
  if (graphQLErrors) {
    errorMsg =
      typeof graphQLErrors === 'string'
        ? graphQLErrors
        : graphQLErrors[0].message;
  } else {
    errorMsg = defaultErrorMessage;
  }
  if (connectorId) {
    updateSingleConnectorsField(
      connectorId,
      EConnectorFields.errorMessage,
      errorMsg,
    );
    updateSelectedConnectorField(
      connectorId,
      EConnectorFields.errorMessage,
      errorMsg,
    );
  }
};

export const disableOxCategoryConnector = async (
  aggregatedConnectors: Connector[],
  oxConnectorId: string,
  oxConnectorName: string,
) => {
  try {
    ConnectorConfigureStore.isLoadingEnableDisable = true;
    const result = await connectorService.bulkConnectorEnableDisable.execute({
      connectorIDs: aggregatedConnectors.map(connector => connector.id),
      oxConnectorName,
      value: false,
    });

    result.forEach(resItem => {
      updateAggregatedConnectorsStatus(
        oxConnectorId,
        resItem.id,
        resItem.isConfigured,
      );
    });
    if (result.some(resItem => resItem.isConfigured)) {
      throw new Error('Disabling OX connector failed for an unknown reason');
    }
    postSuccessAction({
      isConfigured: false,
      id: oxConnectorId,
    });
  } catch (error) {
    const errMsg = `${ConnectorErrorMessages.DisableFailed} ${error}`;
    if (error instanceof ApolloError) {
      postActionError(
        error.graphQLErrors.length
          ? (error.graphQLErrors as GraphQLError[])
          : error.message,
        errMsg,
      );
    } else {
      postActionError(undefined, errMsg);
    }
  } finally {
    ConnectorConfigureStore.isLoadingEnableDisable = false;
  }
};

export const enableOxCategoryConnector = async (
  aggregatedConnectors: Connector[],
  oxConnectorId: string,
  oxConnectorName: string,
) => {
  try {
    ConnectorConfigureStore.isLoadingEnableDisable = true;
    const result = await connectorService.bulkConnectorEnableDisable.execute({
      connectorIDs: aggregatedConnectors.map(connector => connector.id),
      oxConnectorName,
      value: true,
    });

    result.forEach(resItem => {
      postSuccessAction({
        isConfigured: resItem.isConfigured,
        id: resItem.id,
      });
    });
    if (result.some(resItem => !resItem.isConfigured)) {
      throw new Error('Enabling OX connector failed for an unknown reason');
    }
    postSuccessAction({
      isConfigured: true,
      id: oxConnectorId,
    });
  } catch (error) {
    const errMsg = `${ConnectorErrorMessages.EnableFailed} ${error}`;
    if (error instanceof ApolloError) {
      postActionError(
        error.graphQLErrors.length
          ? (error.graphQLErrors as GraphQLError[])
          : error.message,
        errMsg,
      );
    } else {
      postActionError(undefined, errMsg);
    }
  } finally {
    ConnectorConfigureStore.isLoadingEnableDisable = false;
  }
};

export const enableConnector = async (
  connectorEnableInput: EnableConnectorInput,
  dontStopLoader?: boolean,
): Promise<boolean> => {
  try {
    ConnectorConfigureStore.isLoadingEnableDisable = true;
    const result = await connectorService.enableConnector.execute(
      connectorEnableInput,
    );
    if (!result.isConfigured) {
      throw new Error('Enabling connector failed for an unknown reason');
    }

    postSuccessAction(result);
    return true;
  } catch (err) {
    const errMsg = `${ConnectorErrorMessages.EnableFailed} ${err}`;
    if (err instanceof ApolloError) {
      postActionError(
        err.graphQLErrors.length
          ? (err.graphQLErrors as GraphQLError[])
          : err.message,
        errMsg,
      );
    } else {
      postActionError(undefined, errMsg);
    }
    return false;
  } finally {
    if (!dontStopLoader) {
      ConnectorConfigureStore.isLoadingEnableDisable = false;
    }
  }
};

export const disableConnector = async (
  connectorDisableInput: DisableConnectorInput,
  dontStopLoader?: boolean,
): Promise<boolean> => {
  try {
    ConnectorConfigureStore.isLoadingEnableDisable = true;
    const result = await connectorService.disableConnector.execute(
      connectorDisableInput,
    );

    if (result.isConfigured) {
      throw new Error('Disabling connector failed for an unknown reason');
    }

    postSuccessAction(result);
    return true;
  } catch (err) {
    const errMsg = `${ConnectorErrorMessages.DisableFailed} ${err}`;
    if (err instanceof ApolloError) {
      postActionError(
        err.graphQLErrors.length
          ? (err.graphQLErrors as GraphQLError[])
          : err.message,
        errMsg,
      );
    } else {
      postActionError(undefined, errMsg);
    }
    return false;
  } finally {
    if (!dontStopLoader) {
      ConnectorConfigureStore.isLoadingEnableDisable = false;
    }
  }
};
