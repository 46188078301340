import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import { ResetSettingsInput } from '../../types/settings-types';
import mutation from './reset-settings.gql';

const resetSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: ResetSettingsInput): Promise<Nullable<Boolean>> => {
      try {
        const results = await client.mutate<SetSettingsRes>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            input,
          },
        });
        if (!results.data || !results.data.resetSingleSettings) {
          return null;
        }
        return results.data.resetSingleSettings;
      } catch (error) {
        logger.error('Failed to reset settings', error);
        return null;
      }
    },
  };
};

interface SetSettingsRes {
  resetSingleSettings: boolean;
}

export default resetSettings;
