import { setContext } from '@apollo/client/link/context/index.js';
import { getAuthToken } from 'common-auth';

export const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
