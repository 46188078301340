import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import mutation from './revoke-invitation.gql';

const revokeInvitation = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<string, boolean> => {
  return {
    execute: async (invitationId: string): Promise<boolean> => {
      try {
        const results = await client.mutate<{
          deleteInvitation: boolean;
        }>({
          mutation,
          variables: { invitationId },
        });

        if (!results || !results.data) {
          return false;
        }

        return results.data.deleteInvitation;
      } catch {
        return false;
      }
    },
  };
};

export default revokeInvitation;
