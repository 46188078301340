import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import NodeTooltip from '../NodeTooltip';
import { Nullable } from 'ox-common-types';

const License: FC<React.PropsWithChildren<LicenseProps>> = ({
  licenseIssue,
  license,
}) => {
  const theme = useTheme();
  const colors = theme.palette.categoryRiskColor;
  return (
    <NodeTooltip title={`License Check`}>
      {licenseIssue === true && (
        <Typography variant='caption' color={colors.high}>
          Issue with License
        </Typography>
      )}
      {licenseIssue === null && (
        <Typography variant='caption' color={colors.info}>
          No info about licence
        </Typography>
      )}
      {license && <Typography variant='caption'>License: {license}</Typography>}
    </NodeTooltip>
  );
};

export interface LicenseProps {
  licenseIssue: Nullable<boolean>;
  license: string;
}

export { License };
