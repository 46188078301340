import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import {
  ValidateSingleConnectorInput,
  ValidateSingleConnectorResponse,
} from '../../connectors-types';
import mutation from './validate-single-connector.gql';

const validateSingleConnector = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      verifyCredentialsInput: ValidateSingleConnectorInput,
    ): Promise<
      Nullable<
        ValidateSingleConnectorResponse['verifySingleConnectorCredentials']
      >
    > => {
      try {
        const results = await client.mutate<ValidateSingleConnectorResponse>({
          fetchPolicy: ApolloClientCache.NetworkOnly,
          mutation,
          variables: { verifyCredentialsInput },
        });

        if (!results.data) {
          throw new Error('failed to validate credentials');
        }
        return results.data.verifySingleConnectorCredentials;
      } catch (error) {
        return null;
      }
    },
  };
};

export default validateSingleConnector;
