import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';
import {
  ExcludeAppInput,
  ExcludeApplicationsResponse,
  Exclusion,
} from '../types/exclusion-types';
import mutation from './exclude-applications.gql';

const excludeApplications = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<ExcludeAppInput[], ExcludeApplicationsResponse> => {
  return {
    execute: async (
      input: ExcludeAppInput[],
    ): Promise<ExcludeApplicationsResponse> => {
      const results = await client.mutate<ExcludeApplicationsResponseService>({
        mutation,
        variables: { input },
      });
      if (results.data?.excludeApplications.exclusions) {
        TelemetryManager.setTelemetryAction(
          TelemetryActions.ExcludeApplication,
          input,
        );
        return results.data.excludeApplications;
      }
      throw new Error('Faild to exclude app, no data');
    },
  };
};

export interface ExcludeApplicationsResponseService {
  excludeApplications: {
    exclusions: Exclusion[];
    totalExclusions: number;
  };
}

export default excludeApplications;
