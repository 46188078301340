import { createAsyncState } from 'async-utils';
import { createStore, derive } from 'store-utils';
import config from '../config/feedback-config.json';

export const initialStoreState = {
  sending: createAsyncState(),
  text: undefined,
  category: config.defaultCategory,
  open: false,
};

const baseStore = createStore<Omit<FeedbackStoreState, 'isValid'>>(
  initialStoreState,
  'Feedback Store',
);

export const FeedbackStore = derive(
  {
    isValid: get => {
      const { text } = get(baseStore);
      return !!text && text.trim().length > 0;
    },
  },
  {
    proxy: baseStore,
  },
);

interface FeedbackStoreState {
  sending: ReturnType<typeof createAsyncState>;
  text?: string;
  isValid: boolean;
  category: string;
  open: boolean;
}
