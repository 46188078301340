import { AppPages, encodeForUrl } from 'app-navigator';
import { CategoryRiskNames } from 'ox-common-types';
import { FilterTypeToFilter } from 'ox-filter-utils';
import { SeverityEnum } from 'severity-utils';
import { Technique } from '../types/oscar-matrix-types';
import { conditionalFiltersUtils } from '../../issues/common/utils/conditional-filters-utils';

export const createLinkToIssues = (
  techniqueId: Technique['id'],
  techniqueName: Technique['name'],
  severity?: SeverityEnum,
) => {
  let filters = {
    [FilterTypeToFilter.Oscar]: [`${techniqueId}: ${techniqueName}`],
  };

  if (severity) {
    filters[FilterTypeToFilter.Criticality] = [CategoryRiskNames[severity]];
  }

  const conditionalFilters =
    conditionalFiltersUtils.translateFiltersToConditionalFilters(filters);
  const conditionalFiltersEncoded = encodeForUrl(conditionalFilters);
  return `${AppPages.ActiveIssues}?conditionalFilters=${conditionalFiltersEncoded}`;
};
