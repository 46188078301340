import { ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from 'app-theme';
import { FunctionComponent } from 'react';
import { useSnapshot } from 'valtio';
import LayoutStore from '../../stores/layout-store';

const AppThemeProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { themeType } = useSnapshot(LayoutStore);

  return (
    <ThemeProvider theme={themeType === 'light' ? lightTheme : darkTheme}>
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
