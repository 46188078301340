import { whatsNewClient } from 'api-clients';
import dismissWhatNewItems from './dismiss-whats-new-service';
import getWhatsNewHistory from './get-whats-new-history-service';
import getWhatsNew from './get-whats-new-service';

const whatsNewService = {
  getWhatsNew: getWhatsNew(whatsNewClient),
  getWhatsNewHistory: getWhatsNewHistory(whatsNewClient),
  dismissWhatNewItems: dismissWhatNewItems(whatsNewClient),
};

export default whatsNewService;
