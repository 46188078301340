import { Divider, Theme } from '@mui/material';
import { FilterType } from 'ox-filter-utils';
import { FC, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { OxSearchBox } from '../OxSearcBox/OxSearchBox';

export const FilterSearch: FC<React.PropsWithChildren<FilterSearchProps>> = ({
  onSearch,
  filterType,
  searchValue,
  isLoading,
}) => {
  const { classes } = useStyles();
  const [debouncedValue, setDebouncedValue] = useState(searchValue || '');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      setDebouncedValue(inputValue);

      if (onSearch && filterType) {
        // Debounce time in milliseconds
        const debounceTime = 500;

        setTimeout(() => {
          onSearch(inputValue, filterType as FilterType);
        }, debounceTime);
      }
    },
    [filterType, onSearch],
  );

  return (
    <div className={classes.search}>
      <OxSearchBox
        placeholder='Search'
        value={debouncedValue}
        onChange={handleChange}
        isLoading={isLoading}
      />
      <Divider />
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  search: {
    padding: theme.spacing(1),
    fontSize: 11,
  },
}));

export interface FilterSearchProps {
  onSearch?: (searchInput: string, filterType: string) => void;
  filterType?: string;
  searchValue?: string;
  isLoading?: boolean;
}
