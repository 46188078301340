import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  BulkResetIssuesSeverityInput,
  ResetIssuesSeverityResponse,
} from '../../types/issues-types';
import mutation from './bulk-reset-issues-severity.gql';
import { Nullable } from '@oxappsec/ox-consolidated-gpt-types';

const bulkResetIssuesSeverity = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      resetSeverity: BulkResetIssuesSeverityInput,
    ): Promise<Nullable<ResetIssuesSeverityResponse>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: { input: resetSeverity },
        });
        if (results?.data) return results.data.resetIssuesSeverity;
      } catch (e) {
        logger.error('bulk reset issues severity for failed', e);
      }
      return null;
    },
  };
};

interface MutationResponse {
  resetIssuesSeverity: ResetIssuesSeverityResponse;
}

export default bulkResetIssuesSeverity;
