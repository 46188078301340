import { Nullable, RepoTypes } from 'ox-common-types';
import { ReasonFilterType } from './stores/reason-filters-store';
import { SortDirection } from 'react-data-grid';

export interface IrrelevantAppsResponse {
  getApplications: { applications: RawIrrelevantApp[] };
  applications: RawIrrelevantApp[];
  total: number;
  offset: number;
  totalFilteredApps: number;
}

export interface RawIrrelevantApp {
  appName: string;
  appId: string;
  lastCodeChange: string;
  irrelevantReasons: Nullable<string[]>;
  overrideRelevance: Relevance;
  type: RepoTypes;
  fakeApp: boolean;
}
export interface IrrelevantApp {
  appId: string;
  name: string;
  reasons: string[];
  lastCodeChange: string;
  overrideRelevance: Relevance;
  type: RepoTypes;
  fakeApp: boolean;
}

export interface SetRelevanceResponse {
  hasModifiedRelevance: boolean;
}

export interface SetRelevanceInput {
  relevance: Relevance;
  appId: string;
}

export enum Relevance {
  Relevant = 'relevant',
  Irrelevant = 'irrelevant',
  Default = 'default',
}

export enum ERelevance {
  Relevant = 'Relevant',
  Irrelevant = 'Irrelevant',
  Default = 'Default',
}

export interface Result {
  result: IrrelevantApp[];
  filters: ReasonFilter[];
}

export interface ReasonFilter {
  value: ReasonFilterType;
  displayName: string;
  count: number;
}

export interface IrrelevantAppsTableProps {
  irrelevantApps: IrrelevantApp[];
  isLoadingFilters: boolean;
  orderField?: string;
  orderDirection: SortDirection;
}

export enum ApplicationsFilters {
  New = 'new',
  InDevelopment = 'updated',
  DeployedProd = 'deployedProd',
  ExternallyFacing = 'publicVisibility',
  Relevant = 'relevant',
  Irrelevant = 'Irrelevant',
}
