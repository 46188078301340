import * as React from 'react';
import { Location, useLocation } from 'react-router';

export interface IRouteChangeListenerProps {
  onRouteChange(location: Location): void;
}

const RouteChangeListener: React.FC<
  React.PropsWithChildren<IRouteChangeListenerProps>
> = ({ onRouteChange }) => {
  const location = useLocation();

  React.useEffect(() => {
    onRouteChange(location);
  }, [location, onRouteChange]);

  return null;
};

export default RouteChangeListener;
