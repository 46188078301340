import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import mutation from './restore-priority.gql';

const restorePriority = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (restorePriorityInput: ResetPriorityInput) => {
      try {
        const results = await client.mutate<{
          restorePriority: ResetPriorityResponse;
        }>({
          mutation,
          variables: { restorePriorityInput },
        });
        return results?.data?.restorePriority.restoredPriority;
      } catch (error) {
        logger.error(`Failed to reset app priority`, error);
        return null;
      }
    },
  };
};

export interface ResetPriorityInput {
  appId: string[];
}

export interface ResetPriorityResponse {
  restoredPriority: boolean;
}

export default restorePriority;
