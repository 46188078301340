import {
  Button,
  Paper,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { isLocalDevelopment } from 'env-utils';
import { FC, useCallback, useEffect, useState } from 'react';
import { CiWarning } from 'react-icons/ci';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as Logo } from '../../assets/icons/ox-built-in.svg';

const MobileSheild: FC<React.PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesBigScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLocalDev = isLocalDevelopment();
  const [continueAnyway, setContinueAnyway] = useState(false);
  const shouldShow = !matchesBigScreen && !continueAnyway && !isLocalDev;

  useEffect(() => {
    if (localStorage.getItem('mobile-shield-disabled')) {
      setContinueAnyway(true);
    }
  }, []);

  const handleContinueAnyway = useCallback(() => {
    setContinueAnyway(true);
    localStorage.setItem('mobile-shield-disabled', 'true');
  }, []);

  if (shouldShow) {
    return (
      <div className={classes.mobileSheild}>
        <Paper className={classes.paper}>
          <SvgIcon
            component={CiWarning}
            color='error'
            className={classes.icon}
          />
          <Logo style={{ height: '3rem' }} />
          <Typography variant='body1' color='text.primary' px={4}>
            We apologize for the inconvenience, <br />
            we currently don't support low resolution devices like mobile
            phones.
          </Typography>
          <Button
            color='secondary'
            variant='contained'
            onClick={handleContinueAnyway}>
            Continue Anyway
          </Button>
        </Paper>
      </div>
    );
  }

  return <>{children}</>;
};

const useStyles = makeStyles()((theme: Theme) => ({
  mobileSheild: {
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    height: '100%',
    margin: 0,
  },
  paper: {
    display: 'grid',
    placeItems: 'center',
    maxHeight: '90vh',
    maxWidth: '90vw',
    width: '100%',
    height: '100%',
    padding: theme.spacing(3),
    alignContent: 'center',
    gap: theme.spacing(6),
  },
  icon: {
    width: '3rem',
    height: '3rem',
  },
}));

export default MobileSheild;
