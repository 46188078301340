import { getEnvironmentType } from "env-utils";
import { OXCustomEvent, OXFullstoryCustomEvent } from "./types";

export const addFullstoryCustomFieldSuffix = (eventData: OXCustomEvent) => {
  const fullstoryEventData: OXFullstoryCustomEvent = {
    orgId_str: eventData.orgId.value as string,
    env_str: getEnvironmentType(),
  };
  Object.keys(eventData).forEach((key) => {
    if (key === "orgId" || key === "orgName") {
      return;
    }
    const fullStoryTypeKey = eventData[key].type;
    const fullKey = `${key}_${fullStoryTypeKey}`;
    fullstoryEventData[fullKey] = eventData[key].value;
  });
  return fullstoryEventData;
};
