import { isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import jiraService from '../services';
import {
  resetDependentByProjectFields,
  setIsLoadingSelectableTicketFields,
  setProjects,
  setSelectableTicketFields,
} from '../store-actions/jira-ticket-store-actions';
import JiraTicketStore from '../store/jira-ticket-store';

export const getPrioritiesAndLatestProjects = async () => {
  try {
    setIsLoadingSelectableTicketFields(true);
    resetDependentByProjectFields();

    const { fields } = snapshot(JiraTicketStore);
    if (!isNull(fields)) return;
    const results = await jiraService.getPrioritiesAndLatestProjects.execute();
    if (isNull(results)) {
      throw new Error('Invalid response');
    }
    setSelectableTicketFields({
      labels: [],
      priorities: results.listPriorities,
    });
    setProjects(results.getLatestProjects);
  } catch (error) {
    logger.error(error);
    openSnackbar('Jira service is currently unavailable', {
      variant: 'error',
    });
  } finally {
    setIsLoadingSelectableTicketFields(false);
  }
};
