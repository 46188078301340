import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { SbomLibraryItem } from '../../sbom-types';
import query from './get-single-sbom.gql';

const getSingleSbom = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getSingleSbomLibraryInput: GetSbomLibraryInput,
      cache = true,
    ) => {
      try {
        const results = await client.query<{
          getSingleSbomLibrary: SbomLibraryItem;
        }>({
          query,
          variables: { getSingleSbomLibraryInput },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });

        return results.data.getSingleSbomLibrary;
      } catch (error) {
        logger.error('Failed to fetch sbom libraries', error);
        return null;
      }
    },
  };
};

export interface GetSbomLibraryInput {
  appId: String;
  triggerPackage?: string;
  library: string;
  libId?: string;
}

export default getSingleSbom;
