import { OrganizationsStore } from 'organizations';
import { Nullable } from 'ox-common-types';
import { snapshot } from 'valtio';
import { Organization } from '../organization-types';

export const setChosenOrganization = (
  payload: Nullable<Organization>,
): void => {
  const { chosenOrganization } = snapshot(OrganizationsStore);
  if (chosenOrganization && chosenOrganization.id === payload?.id) {
    return;
  }

  OrganizationsStore.chosenOrganization = payload;
};

export const getChosenOrganization = (): Nullable<Organization> => {
  const { chosenOrganization } = snapshot(OrganizationsStore);
  return chosenOrganization as Organization;
};

export const setOrganizations = (payload: Organization[]) => {
  OrganizationsStore.organizations = payload;
};

export const availableOrganizations = (): Organization[] => {
  const { organizations } = snapshot(OrganizationsStore);
  return organizations;
};

export const removeCurrentOrganization = (): void => {
  const { organizations, chosenOrganization } = snapshot(OrganizationsStore);
  if (!chosenOrganization) {
    return;
  }

  OrganizationsStore.chosenOrganization = null;
  OrganizationsStore.organizations = organizations.filter(
    o => o.id !== chosenOrganization.id,
  );
};

export const setIsLoadingOrg = (isLoading: boolean) => {
  OrganizationsStore.isLoading = isLoading;
};

export const updateOrganizationInOrgList = (updatedOrg: Organization) => {
  const { organizations } = snapshot(OrganizationsStore);
  OrganizationsStore.organizations = organizations.reduce(
    (acc: Organization[], curr) => {
      if (curr.id === updatedOrg.id) {
        acc.push(updatedOrg);
      } else {
        acc.push(curr);
      }
      return acc;
    },
    [],
  );
};
