import { isRemoteSite } from 'env-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags, OnPremFeatureFlags } from '../feature-flags-types';

export const useOxFlags = () => {
  const flags = useFlags<FeatureFlags>();
  if (isRemoteSite()) {
    return OnPremFeatureFlags;
  }
  return flags;
};
