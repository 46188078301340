import { Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';

export const FilterItemTooltip: FC<
  React.PropsWithChildren<FilterItemTooltipProps>
> = ({ tooltipEnterDelay, ...props }) => {
  return (
    <Tooltip
      arrow
      placement='right'
      enterDelay={tooltipEnterDelay}
      {...props}
      title={props.title}
    />
  );
};

export interface FilterItemTooltipProps extends TooltipProps {
  tooltipEnterDelay: number;
}
