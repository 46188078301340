import { exclusionClient } from 'api-clients';
import getExclusions from '../services/get-exclusions';
import excludeAlert from './exclude-alert';
import excludeApplications from './exclude-applications-service';
import excludePolicies from './exclude-policies-service';
import getExclusionsFilters from './get-exclusions-filters/get-exclusions-filters';
import removeAlertExclusion from './remove-alert-exclusion/remove-alert-exclusion';
import removeAppExclusion from './remove-app-exclusion/remove-app-exclusion';
import removePolicyExclusion from './remove-policy-exclusion/remove-policy-exclusion';
import updateExpiredAtById from './update-expiredAt-by-id/update-expiredAt-by-id';
import reportFalsePositiveAlert from './report-false-positive';
import excludeBulk from './exclude-bulk/exclude-bulk';
import fetchNearlyExpiredExclusions from './get-nearly-expired-exclusions/get-nearly-expired-exclusions';
import getExclusionsFiltersLazy from './get-exclusions-filters-lazy/get-exclusions-filters-lazy';
import invalidateCachedQueries from 'api-clients/src/graphql-utils';

const exclusionsService = {
  getExclusions: getExclusions(exclusionClient),
  excludeApplications: excludeApplications(exclusionClient),
  excludePolicies: excludePolicies(exclusionClient),
  excludeAlert: excludeAlert(exclusionClient),
  excludeBulkAlert: excludeBulk(exclusionClient),
  reportFPAlert: reportFalsePositiveAlert(exclusionClient),
  removePolicyExclusion: removePolicyExclusion(exclusionClient),
  removeAlertExclusion: removeAlertExclusion(exclusionClient),
  removeAppExclusion: removeAppExclusion(exclusionClient),
  updateExpiredAtById: updateExpiredAtById(exclusionClient),
  getExclusionsFilters: getExclusionsFilters(exclusionClient),
  getNearlyExpiredExclusions: fetchNearlyExpiredExclusions(exclusionClient),
  getExclusionsFiltersLazy: getExclusionsFiltersLazy(exclusionClient),
  invalidateExclusionsCachedQueries: invalidateCachedQueries(exclusionClient),
};

export default exclusionsService;
