import {
  Autocomplete,
  Avatar,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { Nullable } from 'ox-common-types';
import { FC, SyntheticEvent } from 'react';
import { MdKeyboardArrowDown as KeyboardArrowDownOutlinedIcon } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';
import { Organization } from '../organization-types';
import { stringAvatar } from '../utils/avatar-utils';

const OrganizationSelector: FC<
  React.PropsWithChildren<OrganizationSelectorProps>
> = ({ organizations, lastLoggedInOrg, isLoading, handleChange }) => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <Autocomplete
      popupIcon={<KeyboardArrowDownOutlinedIcon fontSize='medium' />}
      size='small'
      value={lastLoggedInOrg || organizations[0]}
      id='org-selector'
      sx={{
        width: 160,
        '& *': {
          borderColor: `${theme.palette.text.secondary} !important`,
          color: `${theme.palette.common.white} !important`,
        },
      }}
      options={organizations}
      autoHighlight
      disableClearable
      noOptionsText={'No such organization'}
      getOptionLabel={option => option.display_name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleChange}
      loading={isLoading || !lastLoggedInOrg}
      disabled={!organizations || organizations.length === 1}
      renderOption={(props, option) =>
        option.branding ? (
          <Box
            component='li'
            sx={{ '& > img': { mr: 2, flexShrink: 0, width: 25, height: 25 } }}
            {...props}
            key={option.id}>
            <img
              loading='lazy'
              width='20'
              src={`${option.branding.logo_url}`}
              alt=''
            />
            {option.display_name}
          </Box>
        ) : (
          <Box
            component='li'
            sx={{
              '& > div': {
                mr: 2,
                flexShrink: 0,
                width: 25,
                height: 25,
                fontSize: '0.75rem',
              },
            }}
            {...props}
            key={option.id}>
            <Avatar
              className={classes.avatarText}
              {...stringAvatar(option.display_name)}
            />
            <Typography
              variant='caption'
              sx={{ wordBreak: 'break-all', lineHeight: 1.2 }}>
              {option.display_name}
            </Typography>
          </Box>
        )
      }
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            sx: {
              padding: 0,
              maxHeight: '1.1rem',
              fontSize: '0.75rem',
              maxWidth: '6.8rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              color: theme.palette.common.white,
            },
          }}
        />
      )}
    />
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  autocomplete: {
    backgroundColor: 'white',
    color: `${theme.palette.text.secondary} !important`,
  },
  avatarText: {
    marginRight: theme.spacing(2),
  },
}));

export interface OrganizationSelectorProps {
  organizations: Organization[];
  lastLoggedInOrg: Nullable<Organization>;
  isLoading: boolean;
  handleChange: (
    _event: SyntheticEvent<Element, Event> | null,
    organization: Organization,
  ) => void;
}

export default OrganizationSelector;
