import { Theme, Tooltip, TooltipProps } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export const CustomTooltip: FunctionComponent<
  PropsWithChildren<CustomTooltipProps>
> = ({
  title,
  children,
  classes,
  enterDelay,
  enterNextDelay,
  disabled,
  ...rest
}) => {
  const { classes: localClasses } = useStyles();
  if (disabled) {
    return children;
  }

  const classesRef = {
    ...classes,
    arrow: localClasses.arrow,
  };

  return (
    <Tooltip
      {...rest}
      arrow
      title={
        <div style={{ maxHeight: 400, maxWidth: 500, overflowY: 'auto' }}>
          {title}
        </div>
      }
      enterDelay={enterDelay || 500}
      enterNextDelay={enterNextDelay || 500}
      classes={classesRef}
      disableInteractive={false}>
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    fontSize: 12,
    opacity: 1,
    border: `1px solid ${theme.palette.divider}`,
  },
  arrow: {
    '&:before': {
      background:
        theme.palette.mode === 'light'
          ? theme.palette.background.paper
          : '#888',
      boxShadow: theme.shadows['1'],
    },
  },
}));

interface CustomTooltipProps extends TooltipProps {
  disabled?: boolean;
}
