import { Typography } from '@mui/material';
import { navigateTospesificPolicy } from 'app-navigator';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RawIssuePolicy } from '../../types/raw-issue-type';

const IssueCardPolicyInfo: FC<
  React.PropsWithChildren<IssueCardPolicyInfoProps>
> = ({ policyInfo, categoryId }) => {
  return (
    <div>
      <Typography variant='body2' color='text.secondary' mb={1}>
        Policy
      </Typography>
      <Typography
        variant='body2'
        color='text.primary'
        to={navigateTospesificPolicy(categoryId, policyInfo.id)}
        component={RouterLink}>
        {policyInfo.name}
      </Typography>
      <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
        Why Should I Care:
      </Typography>
      <Typography variant='body2' color='text.primary'>
        {policyInfo.detailedDescription}
      </Typography>
    </div>
  );
};

export interface IssueCardPolicyInfoProps {
  policyInfo: RawIssuePolicy;
  categoryId: string;
}

export default IssueCardPolicyInfo;
