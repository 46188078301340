import { AppStore } from 'app-store/src/app-store';
import { snapshot } from 'valtio';
import { CUSTOM_SCOPES } from '../types/user-scope-types';
import { getPermissionsByRoles } from '../../permissions/permissions-utils';
import { Roles } from '@oxappsec/ox-unified-permissions';

export const setUserScopes = () => {
  const { user } = snapshot(AppStore);
  const userScopes = user ? user[CUSTOM_SCOPES] : null;
  AppStore.user = {
    ...user,
    scopes: userScopes,
  };
};

export const setUserPermissions = (roles: Roles[]) => {
  const { user } = snapshot(AppStore);

  const permissionsByRoles = getPermissionsByRoles(roles);

  AppStore.user = {
    ...user,
    permissions: permissionsByRoles,
  };
};
