import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchArtifactsInput,
  FilterArtifactsType,
} from '../../types/artifacts-type';
import query from './get-artifacts-filters.gql';

const getArtifactsFilters = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getArtifactsInput: FetchArtifactsInput, cache: boolean) => {
      try {
        const results = await client.query<ArtifactFiltersOutput>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: { getArtifactsInput },
        });
        if (!results.data) return null;
        return results.data.getArtifactsFilters;
      } catch (e) {
        logger.error('Failed to fetch artifacts filters', e);
        return null;
      }
    },
  };
};

export interface ArtifactFiltersOutput {
  getArtifactsFilters: FilterArtifactsType;
}

export default getArtifactsFilters;
