import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import query from './export-issues-pdf.gql';
import { ConditionalFilter } from 'ox-filter-utils';

const exportIssuesPdf = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      input: GenerateIssuesPdfInput,
    ): Promise<Nullable<GenerateIssuesPdfResponse['generateIssuesPdf']>> => {
      try {
        const results = await client.query<GenerateIssuesPdfResponse>({
          query,
          variables: { ...input },
          fetchPolicy: 'no-cache',
        });

        if (
          !results.data ||
          !results.data.generateIssuesPdf ||
          !results.data.generateIssuesPdf.requestId
        ) {
          return null;
        }

        return results.data.generateIssuesPdf;
      } catch (error) {
        logger.error(`failed to generate issues PDF error:`, error);
        return null;
      }
    },
  };
};

interface GenerateIssuesPdfResponse {
  generateIssuesPdf: {
    requestId: string;
  };
}

interface GenerateIssuesPdfInput {
  isDemo: boolean;
  scanId: string;
  filters?: {};
  conditionalFilters?: ConditionalFilter[];
}

export default exportIssuesPdf;
