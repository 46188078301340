import { Nullable } from 'ox-common-types';
import {
  ERelevance,
  IrrelevantApp,
  RawIrrelevantApp,
} from '../irrelevant-apps-types';
import IrrelevantAppsStore from '../stores/irrelevant-apps-store';
import { snapshot } from 'valtio';

export const setIrrelevantApps = (
  apps: RawIrrelevantApp[],
  update?: boolean,
) => {
  if (update) {
    IrrelevantAppsStore.apps = apps;
  } else {
    const { apps: currentApps } = snapshot(IrrelevantAppsStore);
    IrrelevantAppsStore.apps = [...currentApps, ...apps];
  }
};

export const toggleFiltersPanel = () => {
  const { filtersOpen } = snapshot(IrrelevantAppsStore);
  IrrelevantAppsStore.filtersOpen = !filtersOpen;
};

export const setIrrelevantAppsError = (
  isError: boolean,
  errorMessage: Nullable<string>,
) => {
  IrrelevantAppsStore.generalError = isError;
  IrrelevantAppsStore.errorMessage = errorMessage;
};

export const setIsLoadingIrrelevantApps = (isLoading: boolean): void => {
  IrrelevantAppsStore.isLoading = isLoading;
};

export const showRelevantModal = (showRelevantModal: boolean): void => {
  IrrelevantAppsStore.showRelevantModal = showRelevantModal;
};

export const setSelectedReleventApp = (app: IrrelevantApp | null): void => {
  IrrelevantAppsStore.selectedReleventApp = app;
};

export const setShowMakeRelevantDialog = (isLoading: boolean): void => {
  IrrelevantAppsStore.showMakeRelevantDialog = isLoading;
};

export const setIsRemoveIrrelevantAppLoading = (isLoading: boolean): void => {
  IrrelevantAppsStore.isRemoveIrrelevantAppLoading = isLoading;
};

export const setRelevantAppType = (relevantType: ERelevance) => {
  IrrelevantAppsStore.relevantType = relevantType;
};

export const setSearchValue = (value: string) => {
  IrrelevantAppsStore.searchValue = value;
};

export const setTotal = (total: number) => {
  IrrelevantAppsStore.total = total;
};

export const setOffset = (offset: number) => {
  IrrelevantAppsStore.offset = offset;
};
