import { snapshot } from 'valtio';
import ConnectorsStore from '../../../../connectors/stores/connectors-store';
import ConnectorsNotConnectedModalStore from '../store/connector-not-connected-modal-store';
import { ConnectorsNotConnectedModalTypes } from '../types/connector-not-connected-modal-types';
import { navigate, AppPages } from 'app-navigator';

export const openConnectorsNotConnectedModal = (
  modalType: ConnectorsNotConnectedModalTypes,
) => {
  const { connectors } = snapshot(ConnectorsStore);
  const selectedConnector = connectors.find(
    connector => connector.name === modalType,
  );
  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);
  params.set('connectorId', selectedConnector?.id || '');

  const onConfirmFunc = () => {
    navigate(AppPages.Connectors, params.toString());
    handleCloseConnectorsNotConnectedModal();
  };

  const getModalText = () => {
    if (modalType === ConnectorsNotConnectedModalTypes.Slack) {
      return 'To send an issue to Slack, please connect OX to Slack';
    } else {
      return 'To open a ticket in Jira, please connect OX to Jira';
    }
  };

  ConnectorsNotConnectedModalStore.modalProps = {
    isOpen: true,
    title: `${selectedConnector?.name} is not connected`,
    icon: selectedConnector?.iconURL || modalType,
    text: getModalText(),
    buttonActionLabel: `Connect ${selectedConnector?.name} now`,
    onConfirm: onConfirmFunc,
  };
};

export const handleCloseConnectorsNotConnectedModal = () => {
  ConnectorsNotConnectedModalStore.modalProps = {
    isOpen: false,
    title: null,
    icon: null,
    text: null,
    buttonActionLabel: null,
    onConfirm: null,
  };
};
