import { fileDownloadClient } from 'api-clients';
import fetchReadyFile from './services/fetch-ready-file';

export const fileDownloadService = {
  fetchReadyFile: fetchReadyFile(fileDownloadClient),
};

export * from './download-utils-types';
export * from './store-actions/file-download-store-actions';
export * from './store/file-download-store';
export * from './download-utils';
