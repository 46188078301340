import { reportClient } from 'api-clients';
import addCommentToIssue from '../../common/services/add-comment-to-issue/add-comment-to-issue';
import deleteCommentIssue from '../../common/services/delete-comment-issue/delete-comment-issue';
import getResolvedIssue from './get-resolved-issue/get-resolved-issue-service';
import getResolvedIssuesFilters from './get-resolved-issues-filters/get-resolved-issues-filters-service';
import getResolvedIssues from './get-resolved-issues/get-resolved-issues-service';
import { fetchResolvedIssuesOverview } from './resolved-issues-overview/resolved-issues-overview';

const resolvedIssuesService = {
  getIssues: getResolvedIssues(reportClient),
  getIssue: getResolvedIssue(reportClient),
  getIssuesFilters: getResolvedIssuesFilters(reportClient),
  addCommentToIssue: addCommentToIssue(reportClient),
  deleteCommentIssue: deleteCommentIssue(reportClient),
  fetchResolvedIssuesSummary: fetchResolvedIssuesOverview(reportClient),
};

export default resolvedIssuesService;
