import { FC } from 'react';
import { useSnapshot } from 'valtio';

import ExceedScanLimitModal from '../components/ExceedScanLimitModal';
import ScanStore from '../store/scan-store';
import { closeExceedScanLimitModal } from '../store/scan-store-actions';

const ExceedScanLimitContainer: FC<React.PropsWithChildren<unknown>> = () => {
  const { isExceedScanLimitModalVisible, maxScansToRun } =
    useSnapshot(ScanStore);

  return (
    <ExceedScanLimitModal
      open={isExceedScanLimitModalVisible}
      onClose={closeExceedScanLimitModal}
      maxScansAllowed={maxScansToRun}
    />
  );
};

export default ExceedScanLimitContainer;
