import React, { FC, useState } from 'react';
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Chip,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';

import { CveSeverity, CveSeverityIconMap } from './icon-utils';

const ContextAutocomplete: FC<
  React.PropsWithChildren<ContextAutocompleteProps>
> = ({ value, onChange, options }) => {
  const [input, setInput] = useState('');
  return (
    <Autocomplete
      multiple
      sx={{ minWidth: 500, mb: 1.5 }}
      id='context-autocomplete'
      options={Object.keys(options) as CveSeverity[]}
      renderOption={(
        props: React.HTMLAttributes<HTMLLIElement>,
        option: CveSeverity,
      ) => {
        const Icon = CveSeverityIconMap.get(option);
        return (
          <ListItem
            {...props}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}>
            {Icon && <Icon style={{ width: 20, height: 20 }} />}
            <Typography color='text.primary' variant='body2'>
              {option} ({options[option]})
            </Typography>
          </ListItem>
        );
      }}
      onChange={onChange}
      value={value as CveSeverity[]}
      onInputChange={(_, value) => setInput(value)}
      inputValue={input}
      renderTags={renderTags}
      renderInput={renderInput}
    />
  );
};

const renderTags = (
  value: string[],
  getTagProps: AutocompleteRenderGetTagProps,
) =>
  value.map((option: string, index: number) => {
    const Icon = CveSeverityIconMap.get(option as CveSeverity);
    return (
      <Chip
        icon={Icon && <Icon style={{ width: 20, height: 20 }} />}
        variant='outlined'
        label={option}
        {...getTagProps({ index })}
      />
    );
  });

const renderInput = (params: AutocompleteRenderInputParams) => {
  return (
    <TextField
      sx={{ minWidth: 300, mb: 1.5 }}
      variant='standard'
      {...params}
      label='Search by CVE Context'
    />
  );
};

export interface ContextAutocompleteProps {
  value: string[];
  options: Record<CveSeverity, number>;
  onChange: (event: React.SyntheticEvent, value: string[]) => void;
}

export default ContextAutocomplete;
