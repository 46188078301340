import {
  IAggregationRow,
  AggregationColumn,
  AggregationItem,
  ColumnType,
} from 'ox-common-types';
import { createNewDate, filterConfigByTypes } from 'ox-react-components';
import { aggregationsColumnsConfig as config } from '@oxappsec/ox-consolidated-configs';
import { formatDistanceToNow } from 'date-fns';
const ROWS_LIMIT = 15;
export function generateAggregationsTable(
  aggregations,
  selectedAggs: { [x: string]: string }[],
) {
  if (aggregations.type && config[aggregations.type]) {
    const table: IAggregationRow[] = [];

    const columns: AggregationColumn[] = filterConfigByTypes(
      config[aggregations.type].columns,
      aggregations.items,
    );
    const rowHeader: IAggregationRow = {
      value: [],
    };
    for (const column of columns) {
      rowHeader.value.push({
        value: column.header,
      });
    }

    table.push(rowHeader);
    // const { selectedAggs } = snapshot(IssuesExclusionsStore);
    let filterRows: AggregationItem[] =
      selectedAggs.length > 0 ? selectedAggs : aggregations.items;

    const limtedRows = filterRows.slice(0, ROWS_LIMIT);

    for (const item of limtedRows) {
      const rowData: IAggregationRow = {
        value: [],
      };
      for (const column of columns) {
        const value = item[column.key];
        const date = createNewDate(value);

        rowData.value.push({
          value:
            date && column.type === ColumnType.Date
              ? formatDistanceToNow(date) + ' ago'
              : !!!value
              ? ''
              : String(value),
          href: column.href && item[column.href] ? item[column.href] : null,
        });
      }
      table.push(rowData);
    }
    return table;
  }
  return null;
}
