import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Order } from 'ox-common-types';
import query from './get-api-inventories.gql';
import { ApiInventoryItem } from '../../types';

const getApiInventories = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getApiSecurityInput: GetApiInventoriesInput,
      cache = true,
    ) => {
      try {
        const results = await client.query<{
          getApiSecurityItems: GetApiInventoriesResponse;
        }>({
          query,
          variables: { getApiSecurityInput },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });

        return results.data.getApiSecurityItems;
      } catch (error) {
        logger.error('Failed to fetch api inventories', error);
        return null;
      }
    },
  };
};

export interface GetApiInventoriesInput {
  limit?: number;
  offset?: number;
  filters?: {};
  search?: string;
  owners?: string[];
  tagIds?: string[];
  orderBy?: {
    field?: string;
    direction?: Order;
  };
}

export interface GetApiInventoriesResponse {
  apiSecurityItems: ApiInventoryItem[];
  offset: number;
  total: number;
  totalFiltered: number;
}

export default getApiInventories;
