import { isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import { openSnackbar } from 'snackbar-utils';
import jiraService from '../services';
import {
  setJiraTicketsStatusUpdates,
  setLoadingJiraTicketsStatusUpdates,
} from '../store-actions/jira-ticket-store-actions';

export const getJiraTicketsStatusUpdates = async (
  jiraTicketsKeys: string[],
  issueId: string,
  cacheFirst?: boolean,
) => {
  try {
    if (jiraTicketsKeys.length === 0) {
      setJiraTicketsStatusUpdates(null);
      return;
    }
    setLoadingJiraTicketsStatusUpdates(true);
    const results = await jiraService.getTicketIssues.execute({
      jiraTicketsKeys,
      issueId,
      cacheFirst,
    });
    if (isNull(results)) {
      throw new Error('Invalid response');
    }
    setJiraTicketsStatusUpdates(results.getTicketIssues);
    return results.getTicketIssues;
  } catch (error) {
    logger.error(error);
    openSnackbar(
      'Jira tickets latest status information is currently unavailable!',
      {
        variant: 'warning',
      },
    );
  } finally {
    setLoadingJiraTicketsStatusUpdates(false);
  }
};
