import { Badge, Theme, Tooltip, Typography } from '@mui/material';
import { IconType } from 'ox-common-types';
import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AppFlowIcon } from './AppFlowIcon';

export const AppFlowSingleItem: FC<AppFlowSingleItemProps> = ({
  flowIcon: FlowIcon,
  toolTip,
  appFlowItemType,
  servicesNumber,
  iconSize,
  service,
  appFlowItemTypeApp,
  serviceIcon,
}) => {
  const { classes } = useStyles();

  return (
    <Tooltip arrow title={<>{toolTip}</>}>
      <div className={classes.container}>
        <div className={classes.iconSize}>
          {FlowIcon &&
            (servicesNumber ? (
              <Badge color='primary'>
                <AppFlowIcon iconSize={iconSize}>
                  <FlowIcon className={classes.icon} />
                </AppFlowIcon>
              </Badge>
            ) : (
              <AppFlowIcon iconSize={iconSize}>
                <FlowIcon className={classes.icon} />
              </AppFlowIcon>
            ))}
        </div>
        <Typography variant='subtitle2' color='text.primary' mt={1}>
          {appFlowItemType}
        </Typography>
        <Typography variant='caption' color='text.secondary' mt={0.5}>
          {appFlowItemTypeApp}
        </Typography>
        <div className={classes.service}>
          {serviceIcon && FlowIcon && (
            <FlowIcon height={20} style={{ marginInlineEnd: '5px' }} />
          )}
          <Typography variant='subtitle1' color='text.primary' mt={0.5}>
            {service}
          </Typography>
        </div>
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconSize: {
    height: '30px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
  },
  service: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

interface AppFlowSingleItemProps {
  flowIcon?: IconType | string;
  toolTip: ReactNode;
  appFlowItemType?: string;
  servicesNumber?: number;
  iconSize: number;
  service?: string;
  appFlowItemTypeApp?: string;
  serviceIcon?: boolean;
}
