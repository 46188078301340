import { CircularProgress } from '@mui/material';
import { FC } from 'react';

export const PageLoader: FC = () => {
  return (
    <CircularProgress
      sx={{
        position: 'absolute',
        inset: 0,
        margin: 'auto',
      }}
    />
  );
};
