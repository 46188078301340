import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  CredentialsConfigureResponse,
  BitbucketAppInstallationInput,
} from '../../connectors-types';
import mutation from './configure-bitbucket-app-installation.gql';

const configureBitbucketAppInstallation = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      bitbucketAppInstallationInput: BitbucketAppInstallationInput,
    ): Promise<CredentialsConfigureResponse> => {
      const results =
        await client.mutate<ConfigureBitbucketAppInstallationResponse>({
          mutation,
          variables: { bitbucketAppInstallationInput },
        });

      if (results?.data?.configureBitbucketAppInstallation) {
        return results.data.configureBitbucketAppInstallation.connector;
      }

      throw new Error(
        'Received unexpected server response when trying to configure Bitbucket App installation',
      );
    },
  };
};

interface ConfigureBitbucketAppInstallationResponse {
  configureBitbucketAppInstallation: {
    connector: CredentialsConfigureResponse;
  };
}

export default configureBitbucketAppInstallation;
