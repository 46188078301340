import { createStore } from 'store-utils';

const DrawerStore = createStore<DrawerStoreState>(
  {
    open: false,
  },
  'Drawer Store',
);

export interface DrawerStoreState {
  open: boolean;
}

export default DrawerStore;
