import { getCurrentTheme } from 'app-theme';
import { Nullable } from 'ox-common-types';
import {
  OrgScanData,
  OrgScanInfo,
  OrgScanInfoBreakdown,
} from '../dashboard-types';

export const resolveScoreColors = (value: number): [string, string] => {
  const { critical, high, medium, low, passed, appoxalypse } =
    getCurrentTheme().palette.categoryRiskColor;
  if (value <= 25) return [low, passed];
  if (value <= 50) return [passed, medium];
  if (value <= 75) return [medium, high];
  return [critical, appoxalypse];
};

export const createOrgScanInfoFromData = (
  orgScanData: OrgScanData,
): Nullable<OrgScanInfo> => {
  if (!orgScanData) {
    return null;
  }
  return {
    systemsTexts: [orgScanData.systems[0], orgScanData.systems[1]],
    policiesTexts: [orgScanData.policies[0], orgScanData.policies[1]],
    historical: {
      date: new Date(orgScanData.scanDate),
    },
    totalScanRepos: orgScanData.scannedApps,
  };
};

export const createOrgScanInfoBreakdownFromData = (
  orgScanData: OrgScanData,
): Nullable<OrgScanInfoBreakdown> => {
  if (!orgScanData) {
    return null;
  }
  const defaultValues = {
    appox: 0,
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
  };
  const { appox, critical, high, medium, low } =
    orgScanData.severitiesAlerts?.reduce((acc, severitiesAlert) => {
      acc[severitiesAlert.severity] = severitiesAlert.alerts;
      return acc;
    }, defaultValues) || defaultValues;

  return {
    apps: orgScanData.appsRelevant,
    activePolicies: orgScanData.policyCount,
    violations: {
      appox,
      critical,
      high,
      medium,
      low,
    },
    totalIssues: orgScanData.totalIssues,
  };
};
