import { Chip, Tooltip, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';

export const ProtectingDevelopersBadge: FunctionComponent<
  React.PropsWithChildren<ProtectingDevelopersBadgeProps>
> = ({ numberOfDevs, handleClick, tooltip = '' }) => {
  const theme = useTheme();

  if (!numberOfDevs) return null;

  return (
    <div>
      <Tooltip arrow title={tooltip} placement='bottom'>
        <Chip
          onClick={handleClick}
          variant='filled'
          icon={<MdOutlineAdminPanelSettings color='#5C94ED' size='16px' style={{ marginLeft: '8px', marginRight: '1px' }} />}
          label={`Protecting ${numberOfDevs} Developers`}
          size='small'
          sx={{
            backgroundColor: '#5C94ED40',
            borderColor: 'none',
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 400,
            color: theme.palette.common.white,
          }}
        />
      </Tooltip>
    </div>
  );
};

export interface ProtectingDevelopersBadgeProps {
  numberOfDevs: number;
  handleClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  tooltip?: string;
}
