import { isDevelopment } from 'env-utils';
import {
  addDataDogLogsContext,
  initDataDogLogs,
  initDataDogRum,
  setDataDogAction,
  setDataDogContext,
  setDataDogUser,
  startDDRumSession,
} from './data-dog';
import { initFullStory, setFullStoryUserContext } from './fullstory';

export * from './appcues';
export * from './data-dog';
export * from './fullstory';

export enum TelemetryActions {
  Login = 'Login',
  CreatePR = 'CreatePR',
  ViewFix = 'ViewFix',
  JiraTicket = 'JiraTicket',
  LinkToJiraTicket = 'LinkToJiraTicket',
  UnlinkJiraTickets = 'UnlinkJiraTickets',
  ExcludeAlert = 'ExcludeAlert',
  BulkExcludeAlerts = 'BulkExcludeAlerts',
  ReportFalsePositiveAlert = 'ReportFalsePositiveAlert',
  ExcludePolicy = 'ExcludePolicy',
  ExcludeApplication = 'ExcludeApplication',
  UpdateExclusionExpiredAt = 'UpdateExclusionExpiredAt',
  GitPostureFix = 'GitPostureFix',
  SendSlackMessage = 'SendSlackMessage',
  SaveUserFilters = 'SaveUserFilters',
  DeleteUserFilters = 'DeleteUserFilters',
  UpdateUserFilters = 'UpdateUserFilters',
}

export const TelemetryManager = {
  /**
   * @description - Call the init method of the specific telemetry provider.
   */
  init() {
    initDataDogRum();
    initDataDogLogs();
    if (!isDevelopment()) {
      initFullStory();
    }
  },

  /**
   * @description - Set telemetry session with new user info
   * @param user -  The user information object
   */
  setTelemetryUser(user: TelemetryUser) {
    // for the RUM
    setDataDogUser(user);
    if (!isDevelopment()) {
      setFullStoryUserContext(user);
    }
  },

  /**
   * @description - Start a new DD RUM recording session
   */
  startRumRecordingSession() {
    startDDRumSession();
  },

  /**
   * @description - Set telemetry global context info
   * @param context -  The context information object
   */
  setTelemetryContext(context: Record<string, unknown>) {
    setDataDogContext(context);
  },

  /**
   * @description - Add logs telemetry global context info
   * @param key -  The context key to add the value to
   * @param value -  The context value to to assign to the key
   */
  addTelemetryLogsContext(key: string, value: unknown) {
    addDataDogLogsContext(key, value);
  },

  /**
   * @description - Send new action to telemetry server
   * @param action -  The action name
   */
  setTelemetryAction(action: string, payload?: Object) {
    setDataDogAction(action, payload);
  },
};

export default TelemetryManager;

export interface TelemetryUser {
  id: string;
  email: string;
  name: string;
  [key: string]: unknown;
}
