import { darken, useTheme } from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import { FC, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useSnapshot } from 'valtio';
import IssueTrendStore from '../stores/issue-trend-store';
import { getOptions, severityAxis } from '../utils/issue-trend-utils';

const IssuesTrend: FC<React.PropsWithChildren<IssuesGraphProps>> = ({
  height,
}) => {
  const theme = useTheme();
  const colors = theme.palette.categoryRiskColor;

  const {
    labels,
    chartData,
    manualSeverityChangeReason,
    severityChangeReasonsRemoved,
    severityChangedReasonsAdded,
    toolSeverities,
  } = useSnapshot(IssueTrendStore);

  const dataset = useMemo(
    (): ChartData<'line', number[], number> => ({
      labels,
      datasets: Object.keys(chartData).map(key => ({
        key,
        data: [...chartData[key]] || [],

        pointBackgroundColor: context =>
          context.type === 'data'
            ? darken(colors[severityAxis[context.raw as string]], 0.1)
            : colors.none,
        pointBorderColor: context =>
          context.type === 'data'
            ? darken(colors[severityAxis[context.raw as string]], 0.1)
            : colors.none,
        segment: {
          borderColor: context =>
            'raw' in context.p1
              ? darken(colors[severityAxis[context.p1.raw as string]], 0.1)
              : colors.none,
        },
      })),
    }),
    [labels, chartData, colors],
  );

  const options = useMemo(
    (): ChartOptions<'line'> =>
      getOptions(
        labels,
        colors,
        severityChangedReasonsAdded,
        severityChangeReasonsRemoved,
        manualSeverityChangeReason,
        toolSeverities,
      ),
    [
      colors,
      labels,
      manualSeverityChangeReason,
      severityChangeReasonsRemoved,
      severityChangedReasonsAdded,
      toolSeverities,
    ],
  );

  return <Line data={dataset} options={options} height={height || '100px'} />;
};

export interface IssuesGraphProps {
  height?: string;
}

export default IssuesTrend;
