import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import { ConnectionType } from '../types/connection-types';

const baseStore = createStore<ConnectionsConfigureStoreState>(
  {
    isLoadingSave: false,
    domain: '',
    clientId: '',
    clientSecret: '',
    signInEndpoint: '',
    cert: '',
    useCommonEndpoint: true,
    errorMessage: null,
    shouldSyncGroupRoles: false,
    shouldSyncGroupScopes: false,
    shouldAllowAutoProvisioning: false,
    syncGroupRolesPrefix: null,
    selectedConnectionType: null,
  },
  'Connections Configure Store',
);

const ConnectionsConfigureStore = derive(
  {
    isUpdatable: get => {
      const {
        selectedConnectionType,
        domain,
        clientId,
        clientSecret,
        signInEndpoint,
        cert,
      } = get(baseStore);
      switch (selectedConnectionType) {
        case ConnectionType.Github:
        case ConnectionType.GoogleOauth2:
        case ConnectionType.Auth0UsernamePassword:
          return false;
        case ConnectionType.AzureAD:
          return Boolean(domain && clientId && clientSecret);
        case ConnectionType.Okta:
          return Boolean(domain && clientId && clientSecret);
        case ConnectionType.PingFederate:
          return Boolean(signInEndpoint && cert);
        case ConnectionType.SAML:
          return Boolean(domain && signInEndpoint && cert);
        default:
          return false;
      }
    },
  },
  {
    proxy: baseStore,
  },
);

interface ConnectionsConfigureStoreState {
  isLoadingSave: boolean;
  domain: string;
  clientId: string;
  clientSecret: string;
  signInEndpoint: string;
  cert: string;
  useCommonEndpoint: boolean;
  shouldSyncGroupRoles: boolean;
  shouldSyncGroupScopes: boolean;
  shouldAllowAutoProvisioning: boolean;
  syncGroupRolesPrefix: Nullable<string>;
  errorMessage: Nullable<string>;
  selectedConnectionType: Nullable<ConnectionType>;
}

export default ConnectionsConfigureStore;
