export const AppEvents = {
  loading: {
    ready: 'loading:ready',
  },
  Apps: {
    Loaded: 'apps:loaded',
  },
  Scan: {
    ScanStart: 'scan:scan-start',
    ScanEnd: 'scan:scan-end',
    ScanError: 'scan:scan-error',
    ScanKill: 'scan:scan-kill',
    FirstResult: 'scan:first-result',
  },
  DiscoveryPhase: {
    Done: 'discovery-phase:done',
  },
  Connectors: {
    ConnectorConfigured: 'connector-configured',
    IdentityProviderCallback: 'identity-provider-callback',
    GitHubAppInstallationCallback: 'github-app-installation-callback',
    BitbucketAppInstallationCallback: 'bitbucket-app-installation-callback',
    PlusMoreClicked: 'connectors-plus-more-clicked',
  },
  Onboarding: {
    ConnectIdpSuccess: 'connect-idp:success',
    ConnectIdpFailed: 'connect-idp:failure',
  },
  Organizations: {
    ChosenOrgChanged: 'chosen-org-changed',
    NewOrgCreated: 'new-org-created',
  },
  Issues: {
    IssueSelected: 'issue:issue-selected',
  },
  Artifacts: {
    ArtifactSelected: 'artifact:artifact-selected',
  },
  DashboardInfo: {
    Loaded: 'dashboard-info:loaded',
  },
} as const;
