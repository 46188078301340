import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';
import { Nullable } from 'ox-common-types';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { MdContentCopy as ContentCopyIcon } from 'react-icons/md';
import withDisabledIfUnauthorized, {
  WithDisabledIfUnauthorizedProps,
} from '../../../permissions/higher-order-components/withDisabledIfUnauthorized';
import {
  deleteDataViewOption,
  updateDataViewId,
  updateDataViewName,
} from './data-view-actions';
import { GlobalDataViewSelectorOption } from './data-view-selector-types';
import {
  checkIsDataViewDeletable,
  checkIsDataViewEditable,
  getDataViewLabels,
  getSSOGroupString,
  getScopeDisplayName,
} from './data-view-utils';
import {
  validateViewSelectorId,
  validateViewSelectorName,
} from './global-data-view-selector-store-actions';
import { useOxFlags } from '../../../feature-flags/actions/feature-flags-actions';

const DATA_VIEW_NAME_ID = 'data-view-name';
const DATA_VIEW_ID_ID = 'data-view-id';
const DATA_VIEW_NAME_SAVE_BUTTON_ID = 'name-save-button_id';
const DATA_VIEW_ID_SAVE_BUTTON_ID = 'id-save-button_id';

const DataViewModalForm: FC<DataViewModalFormProps> = props => {
  const {
    onClose,
    dataViewSelectorOption,
    shouldShowSSOSyncGroupString,
    isLoadingNameUpdate,
    isLoadingIdUpdate,
    isLoadingDeleteOption,
    disabled,
    tooltipText,
  } = props;
  const { scopeName, id, displayName } = dataViewSelectorOption;

  const theme = useTheme();

  const [copyTooltipTitle, setCopyTooltipTitle] = useState('Copy');
  const [isNameSaveButtonEnabled, setIsNameSaveButtonEnabled] = useState(false);
  const [isIdSaveButtonEnabled, setIsIdSaveButtonEnabled] = useState(false);
  const [currentDisplayName, setCurrentDisplayName] = useState(displayName);
  const [currentId, setCurrentId] = useState(id);
  const [invalidIdMsg, setInvalidIdMsg] = useState<Nullable<string>>('');
  const [invalidNameMsg, setInvalidNameMsg] = useState<Nullable<string>>('');

  const { scopeNameLabel, idLabel, ssoLabel } = getDataViewLabels(scopeName);
  const isDataViewEditable = checkIsDataViewEditable(scopeName);
  const isDataViewDeletable = checkIsDataViewDeletable(scopeName);

  const initialSSOGroupString = getSSOGroupString(scopeName, id, displayName);
  const [SSOGroupString, setSSOGroupString] = useState(initialSSOGroupString);

  const scopeDisplayName = getScopeDisplayName(scopeName);
  const { appOwnersMigration } = useOxFlags();
  const copyToClipboard = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      navigator.clipboard.writeText(SSOGroupString);
      setCopyTooltipTitle('Copied !');
    },
    [SSOGroupString, setCopyTooltipTitle],
  );

  const handleSaveClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      let response: Nullable<boolean> = null;
      const { id: saveButtonId } = e.currentTarget;
      if (saveButtonId === DATA_VIEW_NAME_SAVE_BUTTON_ID) {
        response = await updateDataViewName(
          scopeName,
          currentDisplayName,
          currentId,
          appOwnersMigration,
        );

        if (response) {
          setIsNameSaveButtonEnabled(false);
        }
      } else if (saveButtonId === DATA_VIEW_ID_SAVE_BUTTON_ID) {
        response = await updateDataViewId(
          scopeName,
          id,
          currentId,
          appOwnersMigration,
        );
        if (response) {
          setIsIdSaveButtonEnabled(false);
        }
      }

      if (response) {
        const newSSOGroupString = getSSOGroupString(
          scopeName,
          currentId,
          currentDisplayName,
        );
        setSSOGroupString(newSSOGroupString);
      }
    },
    [appOwnersMigration, scopeName, currentDisplayName, id, currentId],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const { value, id: textFieldId } = e.target;
      if (textFieldId === DATA_VIEW_NAME_ID) {
        setCurrentDisplayName(value);
        const invalidNameMsg = validateViewSelectorName(scopeName, value);
        setInvalidNameMsg(invalidNameMsg);
        setIsNameSaveButtonEnabled(value !== displayName && !invalidNameMsg);
        return;
      } else if (textFieldId === DATA_VIEW_ID_ID) {
        setCurrentId(value);
        const invalidIdMsg = validateViewSelectorId(scopeName, value);
        setIsIdSaveButtonEnabled(value !== id && !invalidIdMsg);
        setInvalidIdMsg(invalidIdMsg);
        return;
      }
    },
    [displayName, id, scopeName],
  );

  const onDeleteClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      deleteDataViewOption(
        scopeName,
        currentDisplayName,
        id,
        appOwnersMigration,
      );
    },
    [appOwnersMigration, scopeName, currentDisplayName, id],
  );

  return (
    <form>
      <Box
        display='flex'
        justifyContent='space-between'
        flexDirection='column'
        gap={3}>
        <TextField
          id={DATA_VIEW_NAME_ID}
          disabled={disabled || !isDataViewEditable}
          variant='outlined'
          label={scopeNameLabel}
          value={currentDisplayName}
          fullWidth
          onChange={handleChange}
          onClick={e => e.stopPropagation()}
          InputProps={{
            endAdornment: isDataViewEditable && (
              <Tooltip arrow title={tooltipText} placement='top'>
                <span>
                  <LoadingButton
                    id={DATA_VIEW_NAME_SAVE_BUTTON_ID}
                    loading={isLoadingNameUpdate}
                    size='small'
                    variant='contained'
                    color='primary'
                    disabled={disabled || !isNameSaveButtonEnabled}
                    onClick={handleSaveClick}>
                    Save
                  </LoadingButton>
                </span>
              </Tooltip>
            ),
          }}
          helperText={
            <Typography component='span' color={theme.palette.error.main}>
              {invalidNameMsg}
            </Typography>
          }
        />
        <TextField
          id={DATA_VIEW_ID_ID}
          disabled={disabled || !isDataViewEditable}
          variant='outlined'
          label={idLabel}
          value={currentId}
          fullWidth
          onChange={handleChange}
          onClick={e => e.stopPropagation()}
          InputProps={{
            endAdornment: isDataViewEditable && (
              <Tooltip arrow title={tooltipText} placement='top'>
                <span>
                  <LoadingButton
                    id={DATA_VIEW_ID_SAVE_BUTTON_ID}
                    loading={isLoadingIdUpdate}
                    size='small'
                    variant='contained'
                    color='primary'
                    disabled={disabled || !isIdSaveButtonEnabled}
                    onClick={handleSaveClick}>
                    Save
                  </LoadingButton>
                </span>
              </Tooltip>
            ),
          }}
          helperText={
            <Typography component='span' color={theme.palette.error.main}>
              {invalidIdMsg}
            </Typography>
          }
        />
        {shouldShowSSOSyncGroupString && (
          <TextField
            disabled
            variant='outlined'
            label={ssoLabel}
            value={SSOGroupString}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Tooltip arrow title={copyTooltipTitle} placement='top'>
                  <span>
                    <IconButton onClick={e => copyToClipboard(e)} size='small'>
                      <ContentCopyIcon color='success' fontSize='small' />
                    </IconButton>
                  </span>
                </Tooltip>
              ),
            }}
          />
        )}
      </Box>
      <Box display='flex' justifyContent='flex-end' mt={4}>
        {isDataViewDeletable ? (
          <Stack gap={2} width='100%' justifyContent='flex-end' direction='row'>
            <Tooltip
              arrow
              title={
                tooltipText ||
                `Delete ${displayName}, note that if ${displayName} ${scopeDisplayName} is used in other flows it might require an update`
              }>
              <span>
                <LoadingButton
                  tabIndex={1}
                  loading={isLoadingDeleteOption}
                  variant='outlined'
                  color='error'
                  disabled={disabled}
                  onClick={onDeleteClick}>
                  Delete
                </LoadingButton>
              </span>
            </Tooltip>
            <span>
              <Button
                variant='contained'
                color='primary'
                tabIndex={2}
                onClick={onClose}>
                Close
              </Button>
            </span>
          </Stack>
        ) : (
          <Button
            size='small'
            variant='outlined'
            color='primary'
            tabIndex={2}
            onClick={onClose}>
            Close
          </Button>
        )}
      </Box>
    </form>
  );
};

export interface DataViewModalFormProps
  extends WithDisabledIfUnauthorizedProps {
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading: boolean;
  dataViewSelectorOption: GlobalDataViewSelectorOption;
  isValidDisplayName: boolean;
  shouldShowSSOSyncGroupString: boolean;
  isLoadingNameUpdate: boolean;
  isLoadingIdUpdate: boolean;
  isLoadingDeleteOption: boolean;
}

export default withDisabledIfUnauthorized(DataViewModalForm, {
  requiredPermissions: [
    {
      permissionScope: PermissionScope.Edit,
      permissionType: PermissionTypes.ApplicationsOwners,
    },
  ],
});
