import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { AppOwnerType } from 'ox-common-types';
import query from './get-applications-owners.gql';

const getApplicationsOwners = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async () => {
      try {
        const results = await client.query<{
          getAppOwners: AppOwnerType[];
        }>({
          query,
          fetchPolicy: 'no-cache',
        });

        return results.data.getAppOwners;
      } catch (error) {
        logger.error('Failed to fetch applications owners', error);
        return null;
      }
    },
  };
};

export default getApplicationsOwners;
