import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import { Organization } from '../../organization-types';
import mutation from './change-org-name.gql';

const changeOrganizationName = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      orgDisplayName: string,
    ): Promise<Nullable<Organization>> => {
      try {
        const changeOrgNameInput = {
          organizationInfo: {
            display_name: orgDisplayName,
          },
        };

        const results = await client.mutate<UpdateOrganizationNameResponse>({
          mutation,
          variables: changeOrgNameInput,
        });

        if (!results.data) {
          throw new Error('failed to update organization name');
        }

        return results.data.updateOrganization;
      } catch (error) {
        logger.error(`Got an error while changing organization name: ${error}`);
        return null;
      }
    },
  };
};

interface UpdateOrganizationNameResponse {
  updateOrganization: Organization;
}

export default changeOrganizationName;
