import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import {
  ActiveMember,
  GetPendingRequestAccessResponse,
  MembersScreenResponse,
  PendingInvitationMember,
  Role,
} from '../../types/members-types';
import query from './get-members-screen.gql';

const getMembersScreen = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<void, MembersScreenResponse> => {
  return {
    execute: async (): Promise<MembersScreenResponse> => {
      try {
        const results = await client.query<MembersScreenQuery>({
          query,
        });

        return {
          activeMembers: results.data.getMembers,
          availableRoles: results.data.getGlobalRoles,
          pendingInvitationMembers: results.data.getInvitations,
          pendingRequests: results.data.getPendingRequestAccess.pendingRequests,
        };
      } catch {
        return {
          activeMembers: [],
          availableRoles: [],
          pendingInvitationMembers: [],
          pendingRequests: [],
        };
      }
    },
  };
};

interface MembersScreenQuery {
  getMembers: ActiveMember[];
  getGlobalRoles: Role[];
  getInvitations: PendingInvitationMember[];
  getPendingRequestAccess: GetPendingRequestAccessResponse;
}

export default getMembersScreen;
