import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { HubSpotUserActions, logUserActionToForm } from 'hubspot';
import { Nullable, ServiceExecute } from 'ox-common-types';
import {
  InvitationInput,
  PendingInvitationMember,
} from '../../types/members-types';
import mutation from './create-multiple-invitations.gql';

const createMultipleInvitations = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<InvitationInput[], Nullable<PendingInvitationMember[]>> => {
  return {
    execute: async (
      invitationsInfo: InvitationInput[],
    ): Promise<Nullable<PendingInvitationMember[]>> => {
      try {
        const results = await client.mutate<{
          createMultipleInvitations: PendingInvitationMember[];
        }>({
          mutation,
          variables: { invitationsInfo },
        });

        if (!results || !results.data) {
          return null;
        }
        logUserActionToForm(HubSpotUserActions.InviteMember);
        return results.data.createMultipleInvitations;
      } catch (error) {
        return null;
      }
    },
  };
};

export default createMultipleInvitations;
