import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';
import { ActiveMember } from '../types/members-types';

const RemoveMemberStore = createStore<RemoveMemberStoreState>(
  {
    isModalVisible: false,
    isLoading: false,
    selectedActiveMember: null,
    removeVerificationValue: '',
    isLastAdminStanding: false,
    activeMemberCount: null,
    isActiveMemberAdmin: false,
    loggedInUserId: null,
  },
  'Remove Member Store',
);

interface RemoveMemberStoreState {
  isModalVisible: boolean;
  isLoading: boolean;
  selectedActiveMember: Nullable<ActiveMember>;
  removeVerificationValue: string;
  isLastAdminStanding: boolean;
  activeMemberCount: Nullable<number>;
  isActiveMemberAdmin: boolean;
  loggedInUserId: Nullable<string>;
}

export default RemoveMemberStore;
