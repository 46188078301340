import { IssueDrawerTabsHeader } from '../../../../types/issues-types';
import { createStore } from 'store-utils';
import { Nullable } from '../../../../../../../../../utilities/ox-common-types/src/typescript-types';

const IssuesDrawerTabsStore = createStore<IssuesDrawerTabsStoreState>(
  {
    issueDrawerTabs: [],
    isLoadingTabsContainer: false,
    isErrorTabsContainer: false,
    loadingInnerTabs: {},
    errorInnerTabs: {},
    selectedTab: null,
  },
  'Issues Drawer Tabs Store',
);

export interface IssuesDrawerTabsStoreState {
  issueDrawerTabs: IssueDrawerTabsHeader[];
  isLoadingTabsContainer: boolean;
  isErrorTabsContainer: boolean;
  loadingInnerTabs: {
    [tabId: string]: boolean;
  };
  errorInnerTabs: {
    [tabId: string]: boolean;
  };
  selectedTab: Nullable<string>;
}

export default IssuesDrawerTabsStore;
