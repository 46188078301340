import { MarkerType, Node } from 'reactflow';
import { AttackPathType } from '../attack-path-types';
import { getHorizontalPadding } from '../configs/padding-config';
import { getParentNodePosition } from '../configs/position-config';
import useAttackPathGraphStore from '../stores/attack-path-store';
import useCommonGraphStore from '../stores/common-graph-store';
import { NodeConfig } from '../configs/node-config';
import { CSSProperties } from 'react';
import { getNodeIdByType } from './utils';

export const layoutAttackPath = (nodes, edges) => {
  const nodesMap = nodes.reduce((acc, node) => {
    acc[node.id] = node;
    return acc;
  }, {});

  const appPos = getParentNodePosition(AttackPathType.App, {});
  const appNodeId = getNodeIdByType(AttackPathType.App);
  const rect1 = buildSector('Api', nodes, edges, nodesMap, {
    level: 0,
    x: appPos.x + 400,
    y: 12,
    topParentId: appNodeId || '',
  });

  const rect2 = buildSector('Image', nodes, edges, nodesMap, rect1);
  const rect3 = buildSector('K8s', nodes, edges, nodesMap, rect2);
  const rect4 = buildSector('Cloud', nodes, edges, nodesMap, rect3);
  const rect5 = buildSector('Internet', nodes, edges, nodesMap, rect4);
  const rect6 = addHubNode(nodes, rect5);
  useAttackPathGraphStore.setState({ offset: rect6.x });

  return { nodes: nodes.filter(n => n.position), edges, position: rect4 };
};

const defaultLength = {
  [AttackPathType.K8s]: 500,
  [AttackPathType.Api]: 500,
  [AttackPathType.Cloud]: 500,
  [AttackPathType.Internet]: 500,
  [AttackPathType.Image]: 500,
};

const maxItems = {
  [AttackPathType.Image]: 3,
  [AttackPathType.Api]: 3,
};

const defaultHeight = 300;

export const buildSector = (
  type,
  nodes,
  edges,
  nodesMap,
  start = { x: 0, y: 0, level: 0, topParentId: '' },
) => {
  const { showMore } = useAttackPathGraphStore.getState();
  const parents = nodes.filter(n => n.data.type === type);
  const sorted = parents.sort((n1, n2) =>
    n1.data?.metadata?.order > n2.data?.metadata?.order ? 1 : -1,
  );
  if (maxItems[type] && sorted.length > maxItems[type] && !showMore[type]) {
    const expandNode = {
      id: `${type}_expand`,
      type: 'custom',

      data: {
        type: 'ExpandAttackPath',
        parentType: type,
        metadata: {
          targetType: type,
          order: 1000,
          total: sorted.length,
          count: sorted.length - maxItems[type],
        },
      },
    };
    sorted.splice(maxItems[type], sorted.length - maxItems[type]);
    edges.push({
      id: `${type}_expand`,
      source: start.topParentId,
      target: expandNode.id,
      type: 'Arrow',
      markerEnd: { type: MarkerType.Arrow },
    });
    nodes.push(expandNode);
    sorted.push(expandNode);
  } else {
    const expandNode = nodes.find(n => n.id === `${type}_expand`);
    if (expandNode) {
      nodes.splice(nodes.indexOf(expandNode), 1);
    }
  }

  const { expandedGroupNodes } = useCommonGraphStore.getState();

  sorted.forEach((n, i) => {
    const subGroupNodes = nodes.filter(
      subNode => subNode.data.metadata?.groupId?.toString() === n.id,
    );

    n.data.level = start.level;
    let groupNodePosition = {
      x: start.x,
      y: start.y + i * defaultHeight,
    };

    if (expandedGroupNodes[n.id]) {
      const { position } = layoutExpandedNodes(
        subGroupNodes,
        { x: start.x, y: start.y + i * defaultHeight, level: start.level },
        nodes,
        edges,
        n,
      );
      groupNodePosition = position;

      n.position = undefined;
    } else {
      hideExpandedNodes(n.id, subGroupNodes, nodes, edges);
      n.position = { x: start.x, y: start.y + i * defaultHeight };
      layoutChildrenNodes(
        n,
        edges,
        expandedGroupNodes,
        nodesMap,
        groupNodePosition,
      );
    }
  });

  return {
    y: start.y,
    level: parents.length ? start.level + 1 : start.level,
    x: parents.length ? defaultLength[type] + start.x : start.x,
    topParentId: sorted.length ? sorted[0].id : start.topParentId,
  };
};

const addHubNode = (nodes, { x, y }) => {
  const hubNode = nodes.find(node => node.data.type === 'Hub');
  if (hubNode) {
    hubNode.position = { x: x, y: y + 10 };
    hubNode.style = {
      ...hubNode.style,
      visibility: 'hidden',
    };
    return { x: x + 400, y: y };
  } else {
    return { x, y };
  }
};

export const getChildPosition = (
  child,
  currentIndex,
  totalLength,
  parentPosition,
  parentType,
) => {
  const padding = getHorizontalPadding(parentType) || 50;
  const verticalOffset = 150;
  const size = 40;
  const level = 0;
  let totalWidth = (totalLength - 1) * size + (totalLength - 1) * padding;
  let startX = parentPosition.x - totalWidth / 2 + 10;

  let childX = startX + (currentIndex % totalLength) * (size + padding);
  let childY =
    parentPosition.y + verticalOffset + level * (verticalOffset / 1.75);

  child.position = { x: childX, y: childY };
};

export const onAttackNodeClick = node => {
  const { originalNodes, originalEdges } = useAttackPathGraphStore.getState();
  const { expandedGroupNodes } = useCommonGraphStore.getState();
  expandedGroupNodes[node.id] = true;
  useCommonGraphStore.setState({ expandedGroupNodes });
  const { nodes, edges } = layoutAttackPath(originalNodes, originalEdges);
  useAttackPathGraphStore.setState({ nodes, edges });
};

export const closeAggregatedNode = (parentId: string) => {
  const { originalEdges, originalNodes } = useAttackPathGraphStore.getState();
  const { expandedGroupNodes } = useCommonGraphStore.getState();
  expandedGroupNodes[parentId] = false;
  useCommonGraphStore.setState({ expandedGroupNodes });
  const { nodes, edges } = layoutAttackPath(originalNodes, originalEdges);
  useAttackPathGraphStore.setState({ nodes, edges });
};

export const layoutExpandedNodes = (
  subGroupNodes,
  start,
  nodes,
  edges,
  parent,
) => {
  const groupNode: {
    id: string;
    type: string;
    data: {
      parent: string;
      type: string;
      level: number;
      parentType: string;
      name: string;
      Icon: React.FC;
      rows?: Node[][];
    };

    style: CSSProperties;
    position?: { x: number; y: number };
  } = {
    id: `${parent.id}-group`,
    type: 'group',
    data: {
      parent: parent.id,
      type: AttackPathType.K8sGroup,
      parentType: parent.type,
      Icon: NodeConfig[parent.data.type](parent.data.metadata).Icon,
      name: resolveGroupNodeName(parent.data.type, parent.data),
      level: start.level,
    },
    style: {
      borderRadius: 10,
      padding: 0,
      borderColor: 'blue',
      height: 150,
      width: 310,
    },
  };
  const rowsObject = subGroupNodes.reduce((acc, item) => {
    if (!acc[item.data.metadata.row]) {
      acc[item.data.metadata.row] = [];
    }

    acc[item.data.metadata.row].push(item);
    return acc;
  }, {});

  let rows: Node[][] = Object.values(rowsObject);
  groupNode.data.rows = rows;

  const aggNode = nodes.find(node => node.id === groupNode.id);
  // TODO: fix this magic numbers
  groupNode.style.height = Math.min(rows.length, 2) * 70 + 45;
  const groupNodePosition = {
    x: start.x - +(groupNode.style.width || 0) / 2 + 40,
    y: start.y - +(groupNode.style?.height || 0) / 2 + 39,
  };

  if (!aggNode) {
    groupNode.position = groupNodePosition;
    nodes.push(groupNode);
  } else {
    aggNode.position = groupNodePosition;
  }

  edges.forEach(edge => {
    edge.source === parent.id && (edge.source = `${parent.id}-group`);
    edge.target === parent.id && (edge.target = `${parent.id}-group`);
  });
  return {
    position: { x: start.x, y: start.y },
    rows: rows.length,
  };
};

const hideExpandedNodes = (parentId, subGroupNodes, nodes, edges) => {
  const aggNode = nodes.find(node => node.id === `${parentId}-group`);
  if (aggNode) {
    aggNode.position = undefined;
  }
  edges.forEach(edge => {
    edge.source === `${parentId}-group` && (edge.source = parentId);
    edge.target === `${parentId}-group` && (edge.target = parentId);
  });
  subGroupNodes.forEach(subNode => (subNode.position = undefined));
};

const layoutChildrenNodes = (
  n,
  edges,
  expandedGroupNodes,
  nodesMap,
  groupNodePosition,
) => {
  const { collapsedBranches } = useCommonGraphStore.getState();
  const children = edges
    .filter(e =>
      e.source === expandedGroupNodes[n.id] ? `${n.id}-group` : n.id,
    )
    .map(e => e.target)
    .filter(id => nodesMap[id]?.data?.metadata?.parentId?.toString() === n.id);
  children.forEach((id, j) => {
    if (collapsedBranches[nodesMap[id]?.data?.metadata?.parentId?.toString()]) {
      nodesMap[id].position = undefined;
    } else {
      getChildPosition(
        nodesMap[id],
        j,
        children.length,
        n.position || { x: groupNodePosition.x + 10, y: groupNodePosition.y },
        n.data.type,
      );
    }
  });
};

export const resolveGroupNodeName = (type: AttackPathType, data) => {
  switch (type) {
    case AttackPathType.K8s:
      return data.metadata.name;

    case AttackPathType.Cloud:
      return data.metadata.accountId;
  }
};

export const onAttackPathExpandClick = data => {
  const { showMore, originalEdges, originalNodes } =
    useAttackPathGraphStore.getState();
  showMore[data.parentType] = true;
  const { nodes, edges } = layoutAttackPath(originalNodes, originalEdges);
  useAttackPathGraphStore.setState({
    nodes,
    edges,
  });
};
