import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  JiraTicketStatus,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import query from './get-issues.gql';

const getTicketIssues = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  { jiraTicketsKeys: string[]; issueId?: string; cacheFirst?: boolean },
  Nullable<GetIssuesResponse>
> => {
  return {
    execute: async ({
      jiraTicketsKeys,
      issueId,
      cacheFirst = false,
    }): Promise<Nullable<GetIssuesResponse>> => {
      try {
        const results = await client.query<GetIssuesResponse>({
          query,
          fetchPolicy: cacheFirst
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: {
            input: {
              jiraTicketsKeys,
              issueId,
            },
            provider: TicketProvider.Jira,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to get jira issues', error);
        return null;
      }
    },
  };
};

export interface GetIssuesResponse {
  getTicketIssues: JiraTicketStatus[];
}

export default getTicketIssues;
