import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  JiraEpic,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import query from './get-epics.gql';

const getEpics = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  { searchString: string; projectKey: string },
  Nullable<GetEpicsResponse>
> => {
  return {
    execute: async ({
      searchString,
      projectKey,
    }): Promise<Nullable<GetEpicsResponse>> => {
      try {
        const results = await client.query<GetEpicsResponse>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: {
            query: searchString,
            projectKey,
            provider: TicketProvider.Jira,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to fetch project epics', error);
        return null;
      }
    },
  };
};

export interface GetEpicsResponse {
  getEpicsByProjectKey: JiraEpic[];
}

export default getEpics;
