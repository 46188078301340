import { Nullable } from 'ox-common-types';
import { useEffect, useRef } from 'react';

export const useInterval = (
  callback: () => void,
  delay: Nullable<number>,
): void => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      const { current } = savedCallback;
      if (current) {
        current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
