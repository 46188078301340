import { ProviderContext } from 'notistack';
import { createStore } from 'store-utils';

const SnackbarStore = createStore<SnackbarStoreState>(
  {
    open: undefined,
    close: undefined,
  },
  'SnackBar Store',
);

interface SnackbarStoreState {
  open?: ProviderContext['enqueueSnackbar'];
  close?: ProviderContext['closeSnackbar'];
}

export default SnackbarStore;
