import { logger } from 'logging-utils';
import { openErrorSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { CredentialsType } from '../connector-configure-types';
import { Connector } from '../connectors-types';
import {
  resetAWSExternalIdFormInput,
  resetFormInputs,
  setApiAccessKeyInput,
  setApiSecretKeyInput,
  setAwsAccessKeyInput,
  setAwsAccessSecretInput,
  setAwsExternalIdInput,
  setAwsRoleArnInput,
  setClientIdInput,
  setClientSecretInput,
  setHostUrlInput,
  setNameInput,
  setPasswordInput,
  setProjectIdInput,
  setSecretAccessKeyInput,
  setSubscriptionIdInput,
  setTenantIdInput,
  setTenantInput,
  setTokenInput,
  setApiKeyInput,
  setOrganizationIdInput,
  setInitialOptionalTabsFields,
  setOptionalTabIsConfigured,
  setOptionalTabsFields,
  setApiUrlInput,
  setAppIdInput,
  setOptionalFields,
  setWebhookUrlInput,
} from '../store-actions/connector-config-form-store-action';
import ConnectorConfigureFormStore from '../stores/connector-configure-form-store';
import ConnectorConfigureStore from '../stores/connector-configure-store';
import { getModalInitialCreds } from '../utils/credentials-utils';
import {
  setIsLoadingDeleteCredentials,
  setIsLoadingVerifyAndSaveCredentials,
} from './connector-configure-actions';
import {
  addCredentials,
  removeCredentials,
  updateCredentials,
} from './credentials-actions';
import {
  BITBUCKET_SESSION_REDIRECT_TO_KEY,
  BITBUCKET_SESSION_REDIRECT_TO_ONBOARDING,
} from 'ox-common-types';

export const setInitialFormValues = () => {
  const { selectedConnector } = snapshot(ConnectorConfigureStore);
  if (!selectedConnector) {
    return;
  }
  const { credentials, isOxBuiltIn, isConfigured } = selectedConnector;
  const isConnectorConfigured =
    (credentials.length > 0 || !!isOxBuiltIn) && isConfigured;
  const initialValues = getModalInitialCreds(selectedConnector);
  if (isConnectorConfigured) {
    setHostUrlInput(selectedConnector.hostURL);
    setNameInput(initialValues.name);
    setPasswordInput(initialValues.password);
    setTokenInput(initialValues.token);
    setClientIdInput(initialValues.clientId);
    setSecretAccessKeyInput(initialValues.secretKey);
    setAwsRoleArnInput(initialValues.awsRoleArn);
    setAwsExternalIdInput(initialValues.awsExternalId);
    setAwsAccessKeyInput(initialValues.awsAccessKey);
    setAwsAccessSecretInput(initialValues.awsAccessSecret);
    setTenantInput(initialValues.tenant);
    setProjectIdInput(initialValues.projectId);
    setWebhookUrlInput(initialValues.webhookUrl);
    setApiAccessKeyInput(initialValues.apiAccessKey);
    setApiSecretKeyInput(initialValues.apiAccessKey);
    setTenantIdInput(initialValues.tenantId);
    setSubscriptionIdInput(initialValues.subscriptionId || '');
    setClientSecretInput(initialValues.clientSecret);
    setApiKeyInput(initialValues.apiKey);
    setOrganizationIdInput(initialValues.organizationId);
    setInitialOptionalTabsFields(initialValues.extraOptionalCreds || {});
    setApiUrlInput(initialValues.apiUrl);
    setAppIdInput(initialValues.appId);
    setOptionalFields(initialValues.optionalFields || {});
  } else {
    setHostUrlInput(selectedConnector.hostURL);
    setApiAccessKeyInput(selectedConnector.apiAccessKey);
    setAwsExternalIdInput(initialValues.awsExternalId || '');
  }
};

export const verifyAndAddCredentials = async (isMultiToken?: boolean) => {
  const {
    hostURL,
    token,
    password,
    name,
    clientId,
    secretKey,
    awsRoleArn,
    awsExternalId,
    awsAccessKey,
    awsAccessSecret,
    tenant,
    projectId,
    apiAccessKey,
    apiSecretKey,
    tenantId,
    clientSecret,
    subscriptionId,
    organizationId,
    apiKey,
    optionalFields,
    gitUsername,
    gitPassword,
    apiUrl,
    appId,
    webhookUrl,
  } = snapshot(ConnectorConfigureFormStore);

  const { selectedConnector } = snapshot(ConnectorConfigureStore);
  if (!selectedConnector) {
    return;
  }
  setIsLoadingVerifyAndSaveCredentials(true);
  const connectorEnableInput = {
    connectorID: selectedConnector.id,
    hostURL,
    credentialsType: selectedConnector.credentialsType,
    credentialsInput: {
      name,
      password,
      gitUsername,
      gitPassword,
      token,
      clientId,
      secretKey,
      awsRoleArn,
      awsExternalId,
      awsAccessKey,
      awsAccessSecret,
      tenant,
      projectId,
      apiUrl,
      apiAccessKey,
      apiSecretKey,
      tenantId,
      subscriptionId,
      clientSecret,
      organizationId,
      apiKey,
      optionalFields,
      appId,
      webhookUrl,
    },
  };

  await addCredentials(connectorEnableInput, selectedConnector, isMultiToken);
};

export const updateOptionalTabsCreds = async (
  removeOptionalCredsForTab?: string,
) => {
  const {
    optionalTabsFields,
    token,
    password,
    name,
    clientId,
    secretKey,
    awsRoleArn,
    awsExternalId,
    awsAccessKey,
    awsAccessSecret,
    tenant,
    projectId,
    apiUrl,
    apiAccessKey,
    apiSecretKey,
    tenantId,
    clientSecret,
    subscriptionId,
    organizationId,
    gitPassword,
    gitUsername,
    apiKey,
    appId,
    webhookUrl,
  } = snapshot(ConnectorConfigureFormStore);
  const { selectedConnector } = snapshot(ConnectorConfigureStore);
  if (!selectedConnector) {
    return;
  }
  const lowerCaseKeysExtraOptionalCreds = Object.keys(
    optionalTabsFields,
  ).reduce((prev, curr) => {
    prev[curr.toLowerCase()] = optionalTabsFields[curr];
    return prev;
  }, {});
  if (removeOptionalCredsForTab) {
    delete lowerCaseKeysExtraOptionalCreds[
      removeOptionalCredsForTab.toLowerCase()
    ];
    setIsLoadingDeleteCredentials(true);
  } else {
    setIsLoadingVerifyAndSaveCredentials(true);
  }
  const credentialsType = selectedConnector.credentials[0]
    ?.credentialsType as CredentialsType;
  if (!credentialsType) {
    return;
  }
  const addCredentialsInput = {
    connectorID: selectedConnector.id,
    credentialsType,
    hostURL: selectedConnector.hostURL,
    credentialsInput: {
      extraOptionalCreds: lowerCaseKeysExtraOptionalCreds,
      name,
      password,
      token,
      clientId,
      secretKey,
      awsRoleArn,
      awsExternalId,
      awsAccessKey,
      awsAccessSecret,
      tenant,
      projectId,
      apiUrl,
      apiAccessKey,
      apiSecretKey,
      tenantId,
      subscriptionId,
      clientSecret,
      organizationId,
      apiKey,
      gitPassword,
      gitUsername,
      appId,
      webhookUrl,
    },
  };
  const result = await updateCredentials(addCredentialsInput);
  const tabName = Object.keys(optionalTabsFields)[0];
  if (removeOptionalCredsForTab) {
    if (result) {
      setOptionalTabIsConfigured(tabName, false);
      Object.keys(optionalTabsFields[tabName]).forEach(inputKey => {
        setOptionalTabsFields(tabName, inputKey, '');
      });
    } else {
      openErrorSnackbar('Failed to remove credentials, please try again later');
    }
    setIsLoadingDeleteCredentials(false);
    return;
  }
  if (result) {
    setOptionalTabIsConfigured(tabName, true);
    Object.keys(optionalTabsFields[tabName]).forEach(inputKey => {
      setOptionalTabsFields(tabName, inputKey, '***************');
    });
  } else {
    openErrorSnackbar(
      'Failed to add credentials, please check your credentials and try again',
    );
  }
  setIsLoadingVerifyAndSaveCredentials(false);
};

export const removeConnectorCredentials = async (credentialsId?: string) => {
  const { selectedConnector } = snapshot(ConnectorConfigureStore);
  if (!selectedConnector) {
    return;
  }
  setIsLoadingDeleteCredentials(true);
  const connectorEnableInput = {
    connectorID: selectedConnector.id,
    credentialsIndex: 0, // TODO:: handle multiple credentials (this needs to be dynamic according to the credentialsId)
  };
  await removeCredentials(connectorEnableInput, selectedConnector);
  resetFormInputs();
  resetAWSExternalIdFormInput();
};

export const redirectToIDP = (connector: Connector, isOnboarding?: boolean) => {
  const idpInfo = connector.identityProviderInfo;
  if (!idpInfo) {
    return;
  }

  const { baseURL, urlParams, scope, user_scope } = idpInfo;
  const preParamsStr = urlParams.split('?')[0];
  const parsedParams = new URLSearchParams(
    urlParams.substring(urlParams.indexOf('?')),
  );
  const stateParam = parsedParams.get('state');
  if (!stateParam) {
    throw new Error(
      'No state parameter was found in identity provider URL parameters',
    );
  }
  try {
    const currState = JSON.parse(decodeURIComponent(stateParam));
    if (isOnboarding) {
      currState.onboarding = true;
    }
    parsedParams.set('state', encodeURIComponent(JSON.stringify(currState)));
  } catch (err) {
    logger.error(`Parsing IDP state params failed: ${err}`);
    throw new Error(`Parsing IDP state params failed: ${err}`);
  }
  let url = `${baseURL}${preParamsStr}?${parsedParams.toString()}&scope=${encodeURIComponent(
    scope,
  )}`;
  if (user_scope) {
    url = url.concat(`&user_scope=${encodeURIComponent(user_scope)}`);
  }
  window.location.href = url;
};

export const redirectToGitHubAppInstallation = (
  connector: Connector,
  isOnboarding?: boolean,
) => {
  const { gitHubAppInfo } = connector;
  if (!gitHubAppInfo) {
    return;
  }
  const stateParams = new URLSearchParams();
  stateParams.set(
    'state',
    encodeURIComponent(JSON.stringify({ onboarding: isOnboarding })),
  );

  const { baseURL, urlPath } = gitHubAppInfo;
  const url = `${baseURL}${urlPath}?${stateParams.toString()}`;

  window.location.href = url;
};

export const redirectToBitbucketAppInstallation = (
  connector: Connector,
  isOnboarding?: boolean,
) => {
  if (isOnboarding)
    window.sessionStorage.setItem(
      BITBUCKET_SESSION_REDIRECT_TO_KEY,
      BITBUCKET_SESSION_REDIRECT_TO_ONBOARDING,
    );

  const { bitbucketAppInfo } = connector;
  if (!bitbucketAppInfo) {
    return;
  }
  const stateParams = new URLSearchParams();
  stateParams.set(
    'state',
    encodeURIComponent(JSON.stringify({ onboarding: isOnboarding })),
  );

  const { baseURL, queryParameters } = bitbucketAppInfo;

  const url = new URL(baseURL);
  queryParameters
    .map(qp => qp.split('='))
    .forEach(([key, value]) => url.searchParams.append(key, value));
  url.searchParams.append('state', stateParams.get('state') || '');
  window.location.href = url.toString();
};
