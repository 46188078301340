import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import mutation from './create-tickets.gql';
import { TicketingVendor } from '../../types/ticketing-types';
import { InputOption } from '../../store/create-ticket-store';
import { IssuePages } from '../../../issues/common/types/issues-types';

const createTickets = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (createTickets: CreateTicketsInput) => {
      try {
        const results = await client.mutate<CreateTicketsResponse>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { createTickets },
        });
        return results.data?.createTickets?.tickets;
      } catch (error) {
        logger.error('Failed to create tickets', error);
        return null;
      }
    },
  };
};

export interface CreateTicketsInput {
  ticketingVendor: TicketingVendor;
  tickets: {
    issueType: IssuePages;
    issueId: string;
    ticket: {
      [fieldKey: string]:
        | string
        | InputOption
        | (string | InputOption)[]
        | null;
    };
  }[];
}

export interface CreateTicketsResponse {
  createTickets: {
    tickets: {
      key: string;
      ticketLink: string;
      created: string;
      updated: string;
      status: string;
      assignTo: { id: string; name: string };
      reporter: { id: string; name: string };
      project: { id: string; name: string };
    }[];
  };
}
export default createTickets;
