import { logger } from 'logging-utils';
import organizationService from '../services';
import { setOrgInfo } from '../store-actions/org-info-store-actions';

export const fetchOrgInfo = async () => {
  try {
    const orgInfo = await organizationService.getOrganizationInfo.execute();
    if (!orgInfo) {
      return;
    }
    setOrgInfo(orgInfo);
  } catch (e) {
    logger.error(`Failed to fetch org info. error: ${e}`);
    return;
  }
};

export const fetchOrgUsersInfo = async () => {
  try {
    const orgInfo =
      await organizationService.getOrganizationUsersInfo.execute();
    if (!orgInfo) {
      return;
    }
    setOrgInfo(orgInfo);
  } catch (e) {
    logger.error(`Failed to fetch org info. error: ${e}`);
    return;
  }
};
