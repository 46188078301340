import { snapshot } from 'valtio';
import { WhatsNewStore } from '../stores/whats-new-store';
import { createWhatsNewItem } from '../utils/whats-new-utils';
import { RawWhatsNewItem, WhatsNewType } from '../whats-new-types';

export const clearWhatsNewItems = () => {
  WhatsNewStore.items = WhatsNewStore.items.filter(
    item => item.type === WhatsNewType.NewUIVersionAvailable,
  );
};

export const setItems = (items: RawWhatsNewItem[]) => {
  WhatsNewStore.items = items
    .map(createWhatsNewItem)
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
};

export const getUILoadTimestamp = () => {
  return snapshot(WhatsNewStore).uiLoadTimestamp;
};

export const getWhatsNewIds = () => {
  const { items } = snapshot(WhatsNewStore);
  return items
    .filter(item => item.type !== WhatsNewType.NewUIVersionAvailable)
    .map(item => item.id);
};
