import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import mutation from './update-role-view-settings.gql';

const updateRoleViewSettings = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      input: UpdateRoleViewSettingsInput,
    ): Promise<Nullable<boolean>> => {
      try {
        const results = await client.mutate<SetRoleViewSettingsRes>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            input,
          },
        });
        if (!results.data || !results.data.updateRoleViewSettings) {
          return null;
        }
        return results.data.updateRoleViewSettings;
      } catch (error) {
        logger.error('Failed to update user view settings', error);
        return null;
      }
    },
  };
};
interface UpdateRoleViewSettingsInput {
  settings: { id: string; value: boolean }[];
  role: string;
}
interface SetRoleViewSettingsRes {
  updateRoleViewSettings: boolean;
}

export default updateRoleViewSettings;
