import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';

import query from './get-artifact-missconfigurations.gql';

const getArtifactMisconfigurations = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getArtifactMissconfigurationsInput: FetchVulnerabilitiesInput,
    ) => {
      try {
        const results = await client.query<{
          getArtifactMissconfigurations: {
            confIssues: Misconfiguration[];
            secretIssues: Misconfiguration[];
          };
        }>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            getArtifactMissconfigurationsInput,
          },
        });
        if (!results.data) {
          logger.error('Failed to fetch missconfigurations');
          return null;
        }
        return results.data.getArtifactMissconfigurations;
      } catch (e) {
        logger.error('Failed to fetch missconfigurations', e);
        return null;
      }
    },
  };
};

export interface FetchVulnerabilitiesInput {
  confIssues: string[];
  secretIssues: string[];
}

export interface Misconfiguration {
  mainTitle: string;
  severity: number;
  issueId: string;
}

export default getArtifactMisconfigurations;
