import { Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const OxSliderTickItem: FC<React.PropsWithChildren<OxSliderTickItemProps>> = ({
  value,
}) => {
  const { classes } = useStyles();
  return (
    <span
      className={classes.tick}
      data-testid='slider-tick'
      style={{ height: `${value}%` }}
    />
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  tick: {
    background: theme.palette.primary.main,
    opacity: 0.25,
    minWidth: 5,
  },
}));

export interface OxSliderTickItemProps {
  value: number;
}

export default OxSliderTickItem;
