import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

const invalidateCachedQueries =
  (client: ApolloClient<NormalizedCacheObject>) => (queryNames: string[]) => {
    const fields = queryNames.reduce(
      (acc: { [x: string]: () => void }, next) => {
        acc[next] = () => {
          // Returning `undefined` for a field will cause it to be refetched
          return undefined;
        };
        return acc;
      },
      {},
    );
    const ack = client.cache.modify({ fields });
    return ack;
  };

export default invalidateCachedQueries;
