import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import ApiInventoryTable from '../../../api-inventory/components/ApiInventoryTable';
import { loadApiInventories } from '../../../api-inventory/actions/api-inventory-actions';
import ApiInventoryStore from '../../../api-inventory/stores/api-inventory-store';

const IssueApiInventories = ({ issueId }: { issueId: string }) => {
  const { loading, inventories, orderField, orderDirection, offset } =
    useSnapshot(ApiInventoryStore);

  useEffect(() => {
    loadApiInventories({ update: true });
  }, [issueId]);

  return (
    <ApiInventoryTable
      inventories={inventories ?? []}
      loading={loading.isPending()}
      orderField={orderField}
      orderDirection={orderDirection}
      offset={offset}
    />
  );
};

export default IssueApiInventories;
