import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';
import React, { FunctionComponent } from 'react';

export const ProgressSpinner: FunctionComponent<
  React.PropsWithChildren<CircularProgressProps & { value: number }>
> = props => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant='determinate' {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          variant='caption'
          component='div'
          color='inherit'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export interface ProgressSpinnerProps {
  text?: string;
  size?: number | string;
  noText?: boolean;
  disablePositionAbsolute?: boolean;
}
