import { datadogLogs } from '@datadog/browser-logs';
import { LoadingStatus } from 'async-utils';
import {
  HubSpotFeedbackFormFields,
  HubSpotFormsIds,
  submitHubSpotForm,
} from 'hubspot';
import { logger } from 'logging-utils';
import {
  bottomRightErrorSnackbar,
  bottomRightSuccessSnackbar,
} from 'snackbar-utils';
import { snapshot } from 'valtio';
import { FeedbackStore } from '../stores/feedback-store';
import {
  closeFeedback,
  setSendingStatus,
} from '../stores/feedback-store-actions';

const saveFeedback = async () => {
  const { text, category, isValid } = snapshot(FeedbackStore);
  if (!isValid || !text) {
    return;
  }

  try {
    setSendingStatus(LoadingStatus.Pending);
    datadogLogs.logger.info('Feedback: New request', {
      category,
      text,
      logType: 'feedback',
    });
    submitHubSpotForm(
      {
        [HubSpotFeedbackFormFields.OxAppFeedback]: text,
        [HubSpotFeedbackFormFields.OxAppFeedbackCategory]: category,
      },
      HubSpotFormsIds.OxAppFeedbackForm,
    );
    setSendingStatus(LoadingStatus.Success);
    bottomRightSuccessSnackbar('Feedback received successfully');
  } catch (err) {
    logger.error('Unable to send feedback', err);
    setSendingStatus(LoadingStatus.Error, err as Error);
    bottomRightErrorSnackbar(
      'There was an error sending your feedback, please try again later',
    );
  }
};

export const saveAndCloseFeedBack = async () => {
  await saveFeedback();
  closeFeedback();
};
