import { Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Tactic } from '../types/oscar-matrix-types';

const shouldShowCounter = (tactic: Tactic) => {
  return tactic.techniquesWithIssues > 0 || tactic.techniques.length > 0;
};

const OscarMatrixHeader: FC<OscarGridHeaderProps> = ({ tactics }) => {
  const { classes } = useStyles();
  return (
    <>
      {tactics.map(tactic => (
        <Typography
          key={tactic.name}
          variant='body2'
          color='text.primary'
          className={classes.oscarMatrixHeader}>
          {tactic.name}&nbsp;
          {shouldShowCounter(tactic) && (
            <span className={classes.counter}>
              ({tactic.techniquesWithIssues}/{tactic.techniques.length})
            </span>
          )}
        </Typography>
      ))}
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  oscarMatrixHeader: {
    lineHeight: 1.2,
    textAlign: 'center',
    background: theme.palette.background.default,
    fontWeight: 600,
    padding: theme.spacing(1),
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(0.5),
  },
  counter: {
    color: theme.palette.text.secondary,
    letterSpacing: 0.7,
  },
}));

export interface OscarGridHeaderProps {
  tactics: Tactic[];
}

export default OscarMatrixHeader;
