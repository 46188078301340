import { DateUnit } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import ScanStore from '../../new-scan/store/scan-store';

const baseStore = createStore<TopBarState>(
  {
    isLoadingTopBarActions: false,
  },
  'Top Bar Store',
);

const TopBarStore = derive(
  {
    dateRange: get => {
      const { isDemoEnabled } = get(ScanStore);
      if (isDemoEnabled) {
        // if demo is enabled, we show 3 months of data as default - request from  sales / marketing: https://oxsecurity.atlassian.net/browse/OXDEV-31656
        return {
          limit: 3,
          unit: DateUnit.Month,
        };
      } else {
        return {
          limit: 1,
          unit: DateUnit.Week,
        };
      }
    },
  },
  { proxy: baseStore },
);

export interface TopBarState {
  isLoadingTopBarActions: boolean;
}

export default TopBarStore;
