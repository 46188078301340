import { Theme, Typography } from '@mui/material';
import { resolveLanguageIcon } from 'common-icons';
import { important } from 'ox-common-types';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import IconWrapper from './IconWrapper';

const Languages: FC<React.PropsWithChildren<LanguagesProps>> = ({
  items,
  className,
}) => {
  const { cx, classes } = useStyles();

  if (items.length === 0) {
    return (
      <Typography variant='body2' color='text.secondary'>
        No languages found
      </Typography>
    );
  }

  return (
    <div className={cx(classes.languages, className)}>
      {items.map(item => {
        const languageIcon = resolveLanguageIcon(item.language.toLowerCase());
        return (
          <div className={classes.languageItem} key={item.language}>
            {languageIcon && (
              <IconWrapper
                size='large'
                icon={languageIcon}
                color='textPrimary'
              />
            )}
            <Typography
              color='text.primary'
              variant='body1'
              className={classes.text}>
              {item.languagePercentage}% {item.language}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  languages: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& > *': {
      lineHeight: important('2'),
    },
  },
  languageItem: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
  },
}));

interface LanguagesProps {
  items: {
    language: string;
    languagePercentage: number;
  }[];
  className?: string;
}

export default Languages;
