import { Nullable } from 'ox-common-types';

export class Polling<T> {
  private poll: () => Nullable<Promise<T>>;
  private callback: (response: Nullable<T>) => boolean;
  private done: () => void;
  private errorCallback: (message: string) => void;
  private timerId: Nullable<NodeJS.Timeout> = null;
  private pollingInterval: number;
  private errorCount: number;
  private callCount: number = 0;

  constructor(
    poll: () => Nullable<Promise<T>>,
    callback: (response: Nullable<T>) => boolean,
    errorCallback: (message: string) => void,
    done: () => void,
    pollingInterval?: number,
  ) {
    this.poll = poll;
    this.callback = callback;
    this.done = done;
    this.pollingInterval = pollingInterval || defaultPollingInterval;
    this.errorCallback = errorCallback;
    this.errorCount = 0;
  }

  public startPolling = () => {
    this.timerId = setTimeout(
      async () => {
        try {
          const response = await this.poll();
          this.callCount++;
          const continuePolling = this.callback(response);
          if (continuePolling) {
            this.startPolling();
          } else {
            this.cancelPolling();
            this.done();
          }
          this.errorCount = 0;
        } catch (e) {
          this.errorCount++;
          if (this.errorCount > 30) {
            const defaultErrMsg =
              'Sorry, we encountered an error while fetching scan data, please refresh the page';
            let errMsg = (e as Error).message || defaultErrMsg;
            if ((e as Error).message.includes('Network error')) {
              errMsg = defaultErrMsg;
            }
            this.errorCallback(errMsg);
          } else {
            this.startPolling();
          }
        }
      },
      this.callCount === 0 ? 0 : this.pollingInterval,
    );
  };

  public cancelPolling = () => {
    this.timerId && clearTimeout(this.timerId);
    this.callCount = 0;
  };

  public getCallCount = () => this.callCount;
}

const defaultPollingInterval = 2500;
