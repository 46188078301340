export const shouldVirtualize = ({
  itemHeight,
  maxHeight,
  itemsCount,
}: shouldVirtualizeOptions): boolean => {
  return itemHeight * itemsCount > maxHeight;
};

interface shouldVirtualizeOptions {
  itemHeight: number;
  maxHeight: number;
  itemsCount: number;
}
