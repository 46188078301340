import React, { FC } from 'react';
import List from '@mui/material/List';
// Assuming this is a custom component
import ApiCallstackItem from './ApiCallstackItem'; // Import the new component
import { UncontrolledCollapsiblePanel } from '../CollapsiblePanel/UncontrolledCollapsiblePanel';
import { Box, useTheme } from '@mui/material';
import { CustomExtLink } from '../Aggregation/elements/CustomExtLink';
import SyntaxHighlighter from 'react-syntax-highlighter';
import vs from 'react-syntax-highlighter/dist/cjs/styles/prism/vsc-dark-plus';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
const ApiCallstack: FC<ApiCallstackProps> = ({ codeLocations }) => {
  const theme = useTheme();
  return (
    <Box sx={{ py: 0.5, overflowY: 'auto' }}>
      {codeLocations.map((codeLocation, index) => (
        <UncontrolledCollapsiblePanel
          key={index}
          defaultState={index === 0}
          text={
            <CustomExtLink
              value={codeLocation.link.split('/').pop() ?? codeLocation.link}
              href={codeLocation.link}
            />
          }
          unmountOnExit={false}
          mountOnEnter={true}>
          <List
            sx={{
              border: '1px solid #ddd',
              borderRadius: '4px',
              overflow: 'hidden',
              p: 0,
            }}
            component='nav'
            aria-label='callstack'>
            {codeLocation.callBranch.map((callEntry, callIndex) => (
              <ApiCallstackItem
                key={callIndex}
                itemIndex={callIndex}
                lastItem={callIndex === codeLocation.callBranch.length - 1}
                entry={parseCallStackEntry(callEntry)}
                isDivider={callIndex < codeLocation.callBranch.length - 1}
              />
            ))}
            {codeLocation.snippet && (
              <SyntaxHighlighter
                language={codeLocation.language}
                style={theme.palette.mode === 'dark' ? vs : docco}
                startingLineNumber={
                  codeLocation.snippetLineNumber
                    ? +codeLocation.snippetLineNumber
                    : 1
                }
                showLineNumbers>
                {codeLocation.snippet}
              </SyntaxHighlighter>
            )}
          </List>
        </UncontrolledCollapsiblePanel>
      ))}
    </Box>
  );
};

type ApiCallstackProps = {
  codeLocations: Array<{
    link: string;
    snippet?: string;
    language?: string;
    snippetLineNumber?: number;
    callBranch: string[];
  }>;
};

const parseCallStackEntry = (entry: string) => {
  const hasMethod = entry.includes('@');
  const [methodWithFile, line] = entry.split('#');
  const [method, file] = hasMethod
    ? methodWithFile.split('@')
    : ['', methodWithFile];
  return { method, file, line };
};
export default ApiCallstack;
