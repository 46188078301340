import { MarkerType, Node } from 'reactflow';
import issueGraphService from '../services';
import useIssueGraphStore from '../stores/issue-graph-store';
import {
  collapseBranch,
  collapseBranches,
  expandBranch,
  formatEdges,
  formatNodes,
  getCollapsedNodes,
} from '../utils/format-utils';
import { layout } from '../utils/layout-utils';
import { layoutAttackPath } from '../utils/attack-path-utils';
import useAttackPathGraphStore from '../stores/attack-path-store';
import useCommonGraphStore from '../stores/common-graph-store';

export const loadIssueGraph = async (issueId: string) => {
  useCommonGraphStore.setState({ loading: true });
  const response = await issueGraphService.getIssueGraph.execute(issueId);

  if (response) {
    const edges = formatEdges(response.edges);

    const attackPathNodes = formatNodes(
      response.nodes.filter(node => node.metaData.graphPart === 'attackPath'),
      edges,
    );

    const attackPathEdges = edges.filter(edge => {
      return attackPathNodes.some(node => node.id === edge.target);
    });

    const issueNodes = formatNodes(
      response.nodes.filter(
        node =>
          node.metaData.graphPart === 'issue' ||
          node.metaData.graphPart === 'saas',
      ),
      edges,
    );

    useIssueGraphStore.setState({
      originalEdges: edges.filter(e => issueNodes.some(n => n.id === e.source)),
      originalNodes: [...issueNodes] as Node[],
    });
    const issueGraphEdges = edges.filter(edge => {
      return issueNodes.some(node => node.id === edge.target);
    });

    useAttackPathGraphStore.setState({
      originalNodes: attackPathNodes as Node[],
      originalEdges: edges.filter(e =>
        attackPathNodes.some(n => n.id === e.target),
      ),
    });

    const { nodes: attackPathNodesWithPositions, edges: ape } =
      layoutAttackPath(attackPathNodes, attackPathEdges);

    useAttackPathGraphStore.setState({
      nodes: attackPathNodesWithPositions as Node[],
      edges: ape,
    });
    const { nodes: newNodes, edges: newEdges } = getCollapsedNodes(
      issueNodes,
      issueGraphEdges,
      {},
      {},
    );

    const nodesWithPosition = layout(newNodes, newEdges);

    useIssueGraphStore.setState({
      edges: [...newEdges],
      nodes: [...nodesWithPosition] as Node[],
    });

    useCommonGraphStore.setState({
      issueId,
      loading: false,
    });

    collapseBranches(nodesWithPosition as Node[]);
  }
};

export const toggleFullMode = () => {
  const { fullMode } = useCommonGraphStore.getState();
  useCommonGraphStore.setState({ fullMode: !fullMode });
};

export const toggleBranch = (nodeId: string, type) => {
  const { collapsedBranches } = useCommonGraphStore.getState();

  if (!collapsedBranches[nodeId]) {
    useCommonGraphStore.setState({
      collapsedBranches: { ...collapsedBranches, [nodeId]: true },
    });
    collapseBranch(nodeId, type);
  } else {
    useCommonGraphStore.setState({
      collapsedBranches: { ...collapsedBranches, [nodeId]: false },
    });
    expandBranch(nodeId, type);
  }
};

export const onNodeMouseEnter = (event, node) => {
  onNodeMouseLeave(event, node);
  const actualConnections = node.data.metadata?.actualConnections?.map(id =>
    id?.toString(),
  );
  if (actualConnections?.length) {
    const { edges } = useIssueGraphStore.getState();
    const newEdges = actualConnections.map(nodeId => ({
      id: `${node.id}-${nodeId}`,
      source: node.id,
      target: nodeId?.toString(),
      type: 'smoothstep',
      data: {
        type: 'hoverEdge',
      },
      markerEnd: { type: MarkerType.Arrow },
      style: {
        stroke: '#6837FF',
        strokeWidth: 2,
      },
    }));

    useIssueGraphStore.setState({
      edges: [...edges, ...newEdges],
    });
    useCommonGraphStore.setState({
      highlightedNodes: [...actualConnections, node.id],
    });
  }
};

export const onNodeMouseLeave = (event, node) => {
  const { edges } = useIssueGraphStore.getState();
  const newEdges = edges.filter(edge => edge.data?.type !== 'hoverEdge');
  useIssueGraphStore.setState({
    edges: newEdges,
  });
  useCommonGraphStore.setState({
    highlightedNodes: [],
  });
};

export const clearAttackPathStore = () => {
  useIssueGraphStore.setState({
    nodes: [],
    edges: [],
    originalEdges: [],
    originalNodes: [],
  });
  useAttackPathGraphStore.setState({
    offset: 0,
    expandedGroupNodes: {},
    nodes: [],
    edges: [],
    originalEdges: [],
    originalNodes: [],
    collapsedBranches: {},
    showMore: {},
  });
  useCommonGraphStore.setState({
    loading: false,
    fullMode: false,
    aggType: null,
    expandedGroupNodes: {},
    issueId: null,
    showMoreMap: {},
    collapsedBranches: {},
    highlightedNodes: [],
  });
};
