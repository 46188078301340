import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useAppStore } from 'app-store';
import { useTokenStore } from 'common-auth';
import { AppSpinner } from 'common-components';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { getLastLoggedInOrg } from '../../../organizations/utils/local-storage-utils';
import PagesErrorBoundaryContainer from '../../containers/PagesErrorBoundaryContainer';

const lastLoggedInOrg = getLastLoggedInOrg();

const PrivateRoute: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { user } = useAuth0();
  const { loadingNewToken } = useTokenStore();
  const { isFirstLogin, isReady } = useAppStore();

  const appInitialized = !loadingNewToken && isReady;
  const requiresOnboarding = !user?.org_id && isFirstLogin;

  if (loadingNewToken || !isReady) {
    return <AppSpinner />;
  }

  if (!requiresOnboarding && !appInitialized) {
    return <AppSpinner />;
  }

  return (
    <PagesErrorBoundaryContainer>
      <Outlet />
    </PagesErrorBoundaryContainer>
  );
};

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <AppSpinner />,
  loginOptions: {
    organization: lastLoggedInOrg ? lastLoggedInOrg.id : undefined,
  },
});
