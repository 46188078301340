import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import {
  DashboardFilter,
  InventoryFilterType,
  InventoryResponse,
} from '../inventory-types';
import { createInventoryFilters, sortFilters } from '../utils/inventory-utils';

const baseStore = createStore<InventoryFiltersState>(
  {
    inventoryData: null,
    filters: [],
    isLoadingFilters: false,
  },
  'Inventory Filters Store',
);

const InventoryFiltersStore = derive(
  {
    inventoryFilters: (get): DashboardFilter[] => {
      const { inventoryData } = get(baseStore);
      if (inventoryData) {
        return sortFilters(createInventoryFilters(inventoryData.inventory));
      }
      return [];
    },
    inventoryAppsNumber: (get): number => {
      const { inventoryData } = get(baseStore);
      return inventoryData ? inventoryData.totalApps : 0;
    },
    inventoryAppsNotRelevant: (get): number => {
      const { inventoryData } = get(baseStore);
      return inventoryData ? inventoryData.notRelevantApps : 0;
    },
  },
  {
    proxy: baseStore,
  },
);
export interface InventoryFiltersState {
  filters: Array<InventoryFilterType>;
  isLoadingFilters: boolean;
  inventoryData: Nullable<InventoryResponse>;
}

export default InventoryFiltersStore;
