import { snapshot, useSnapshot } from 'valtio';
import { RepoNode } from '../onboarding-types';
import RepoSelectionStore, {
  RepoSelectionCacheStore,
} from '../stores/onboarding-repo-selection-store';
import { cloneDeep } from '@apollo/client/utilities';

export const setRepoTreeData = (treeData: RepoNode[]) => {
  RepoSelectionStore.repoTreeData = treeData;
};

export const setFilteredRepoTreeData = (treeData: RepoNode[]) => {
  RepoSelectionStore.filteredRepoTreeData = treeData;
};

export const resetRepoSelectionStore = (
  selectedConnectorId: string,
  resetFromSaveButton?: boolean,
) => {
  if (selectedConnectorId) {
    const repoSelectionStoreCurrState = snapshot(RepoSelectionStore);
    const repoSelectionCacheStoreCurrState = snapshot(RepoSelectionCacheStore);
    if (repoSelectionStoreCurrState.repoTreeData?.length) {
      RepoSelectionCacheStore[selectedConnectorId] = {
        ...repoSelectionCacheStoreCurrState[selectedConnectorId],
        ...repoSelectionStoreCurrState,
      };

      if (resetFromSaveButton) {
        RepoSelectionCacheStore[selectedConnectorId].saveControlObj = cloneDeep(
          repoSelectionStoreCurrState.controlObject,
        );
      } else {
        const isCached =
          Object.keys(
            RepoSelectionCacheStore[selectedConnectorId].saveControlObj || {},
          ).length !== 0;

        if (isCached) {
          RepoSelectionCacheStore[selectedConnectorId].controlObject =
            cloneDeep(
              RepoSelectionCacheStore[selectedConnectorId].saveControlObj!,
            );
        } else {
          RepoSelectionCacheStore[selectedConnectorId].controlObject =
            cloneDeep(repoSelectionStoreCurrState.originalControlState);
        }
      }

      RepoSelectionCacheStore[selectedConnectorId].wereChangesMade = false;
    }
  }
  RepoSelectionStore.filteredRepoTreeData = null;
  RepoSelectionStore.expandedNodes = [];
  RepoSelectionStore.isChooseAllSelected = true;
  RepoSelectionStore.repoTreeData = null;
  RepoSelectionStore.searchValue = '';
  RepoSelectionStore.isLoading = false;
  RepoSelectionStore.monitorAllNewlyCreatedResources = true;
  RepoSelectionStore.controlObject = {};
  RepoSelectionStore.wereChangesMade = false;
};

export const resetRepoSelectionCacheStore = (connectorId: string) => {
  delete RepoSelectionCacheStore[connectorId];
};

export const overrideEntireRepoSelectionStore = (
  newState: typeof RepoSelectionStore,
) => {
  Object.keys(RepoSelectionStore).forEach(key => {
    RepoSelectionStore[key] = cloneDeep(newState[key]);
  });
};

export const setIsChooseAllSelected = (isSelected: boolean) => {
  RepoSelectionStore.isChooseAllSelected = isSelected;
};

export const setSearchValue = (searchString: string) => {
  RepoSelectionStore.searchValue = searchString;
};

export const setExpandedNodes = (expandedNodes: string[]) => {
  RepoSelectionStore.expandedNodes = expandedNodes;
};

export const setIsLoading = (isLoading: boolean) => {
  RepoSelectionStore.isLoading = isLoading;
};

export const setMonitorAllNewlyCreatedResources = (isChecked: boolean) => {
  RepoSelectionStore.monitorAllNewlyCreatedResources = isChecked;
};

export const useRepoSelection = () => useSnapshot(RepoSelectionStore);
