import React from 'react';
import { ReactElement } from 'react';
import { BsCode } from 'react-icons/bs';
import { ReactComponent as DirectIcon } from './icons/direct.svg';
import { ReactComponent as IndirectIcon } from './icons/indirect.svg';

export const getDependencyIcon = (dependencyType: string, color?: string) => {
  const styles = {
    color,
    width: '1rem',
    height: '1rem',
    style: { flexShrink: 0 },
  };
  let icon: ReactElement | null = null;
  if (dependencyType === DependencyTypes.Indirect) {
    icon = <IndirectIcon {...styles} />;
  }

  if (dependencyType === DependencyTypes.Direct) {
    icon = <DirectIcon {...styles} width='0.75rem' />;
  }

  if (dependencyType === DependencyTypes.Development) {
    icon = <BsCode {...styles} />;
  }

  return <div style={{ display: 'inline-flex' }}>{icon}</div>;
};

export enum DependencyTypes {
  Direct = 'Direct',
  Indirect = 'Indirect',
  Development = 'Development',
}
