import create from 'zustand';
import { Edge, Node } from 'reactflow';

export interface AttackPathGraphType {
  nodes: Node[];
  edges: Edge[];
  originalEdges: Edge[];
  showMore: { [key: string]: boolean };
  originalNodes: Node[];
  expandedGroupNodes: { [key: string]: boolean };
  collapsedBranches: { [key: string]: boolean };
  offset: number;
}

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useAttackPathGraphStore = create<AttackPathGraphType>((set, get) => ({
  offset: 0,
  expandedGroupNodes: {},
  nodes: [],
  edges: [],
  originalEdges: [],
  originalNodes: [],
  collapsedBranches: {},
  showMore: {},
}));

export default useAttackPathGraphStore;
