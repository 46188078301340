import { AsyncLoadingState, createAsyncState } from 'async-utils';
import { Nullable, Order } from 'ox-common-types';
import { createStore } from 'store-utils';
import { ApiInventoryItem } from '../types';
import { FilterIssueType } from 'ox-filter-utils';

export const defaultOrderBy = {
  field: undefined,
  direction: undefined,
};

export const initialStoreValues = {
  loading: createAsyncState(),
  inventories: null,
  total: 0,
  offset: 0,
  filtersOpen: true,
  selectedId: null,
  lastDrawerHeight: undefined,
  selectedInventory: null,
  apiItemIssuesStatistics: null,
  apiItemIssuesStatisticsLoading: createAsyncState(),
  topSearchValue: '',
};

const ApiInventoryStore = createStore<ApiInventoryStoreState>(
  initialStoreValues,
  'API BOM Store',
);

export interface ApiInventoryStoreState {
  loading: ReturnType<typeof createAsyncState>;
  orderField?: string;
  orderDirection?: Order;
  inventories: Nullable<ApiInventoryItem[]>;
  total: number;
  offset: number;
  filtersOpen: boolean;
  lastDrawerHeight?: number;
  selectedId: Nullable<string>;
  selectedInventory: Nullable<ApiInventoryItem>;
  apiItemIssuesStatisticsLoading: AsyncLoadingState;
  apiItemIssuesStatistics: Nullable<FilterIssueType>;
  topSearchValue: string;
}

export default ApiInventoryStore;
