import { createStore, derive } from 'store-utils';
import { Role } from '../types/members-types';
import { AppStore } from 'app-store/src/app-store';
import { isRemoteSite } from 'env-utils';

const baseStore = createStore<AvailableRolesStoreState>(
  {
    rawNonFilteredRoles: [],
    isLoading: false,
  },
  'Available Roles Store',
);

const AvailableRolesStore = derive(
  {
    availableRoles: get => {
      const { rawNonFilteredRoles } = get(baseStore);
      if (isRemoteSite()) return rawNonFilteredRoles;
      const { ldClient } = get(AppStore);
      const flags = ldClient?.allFlags();
      if (!flags) return rawNonFilteredRoles;
      const { rbacNewRoles } = flags;
      return rawNonFilteredRoles.filter(role => !!rbacNewRoles[role.name]);
    },
  },
  { proxy: baseStore },
);

interface AvailableRolesStoreState {
  rawNonFilteredRoles: Role[];
  isLoading: boolean;
}

export default AvailableRolesStore;
