import React, { useState } from 'react';
import {
  Chip,
  ClickAwayListener,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import ItemsGroup from './ItemsGroup';
import { TooltipClasses } from '@mui/material/Tooltip/tooltipClasses';

export function StackedItems<T>({
  items,
  ItemComponent,
  ItemGroupComponent,
  tooltipClasses,
  tooltipOnClick = false,
}: {
  items: T[];
  ItemComponent: React.ComponentType<{ item: T; inTooltip?: boolean }>;
  ItemGroupComponent?: React.ComponentType<{ items: T[] }>;
  tooltipClasses?: Partial<TooltipClasses>;
  tooltipOnClick?: boolean;
}) {
  const [firstItem, ...restItems] = items;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipTitle = ItemGroupComponent ? (
    <ItemGroupComponent items={restItems} />
  ) : (
    <ItemsGroup ItemComponent={ItemComponent} items={restItems} />
  );

  const chip = (
    <Chip
      onClick={tooltipOnClick ? () => setTooltipOpen(prev => !prev) : undefined}
      size='small'
      label={<Typography variant='body2'>+{restItems.length}</Typography>}
      variant='outlined'
      color='primary'
    />
  );

  const tooltipProps: TooltipProps = {
    arrow: true,
    classes: tooltipClasses,
    title: tooltipTitle,
    placement: 'top',
    children: chip,
  };

  return firstItem ? (
    <Stack alignItems='center' gap={1} direction='row'>
      <ItemComponent item={firstItem} />
      {restItems.length ? (
        tooltipOnClick ? (
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <div>
              <Tooltip
                {...tooltipProps}
                onClose={() => setTooltipOpen(false)}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              />
            </div>
          </ClickAwayListener>
        ) : (
          <Tooltip {...tooltipProps} />
        )
      ) : null}
    </Stack>
  ) : null;
}
