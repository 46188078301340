import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import {
  UpdateSettingsInput,
  UpdateSettingsRes,
} from '../../types/settings-types';
import mutation from './update-settings.gql';

const updateSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      input: UpdateSettingsInput,
    ): Promise<Nullable<UpdateSettingsRes>> => {
      try {
        const results = await client.mutate<SetSettingsRes>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            input,
          },
        });
        if (!results.data || !results.data.updateSettings) {
          return null;
        }
        return results.data.updateSettings;
      } catch (error) {
        logger.error('Failed to update settings', error);
        return null;
      }
    },
  };
};

interface SetSettingsRes {
  updateSettings: UpdateSettingsRes;
}

export default updateSettings;
