import { orgManagementClient, reportClient, tagsClient } from 'api-clients';
import addUserConsent from './add-user-consent/add-user-consent';
import auditUserLogin from './audit-user-login/audit-user-login';
import getOrgStatus from './get-org-status/get-org-status';
import updateAppOwnerName from './update-app-owner-name/update-app-owner-name';
import deleteAppOwner from './delete-app-owner/delete-app-owner';
import updateAppOwnerEmail from './update-app-owner-email/update-app-owner-email';
import updateAppOwnerNameV2 from './update-app-owner-name-v2/update-app-owner-name';
import deleteAppOwnerV2 from './delete-app-owner-v2/delete-app-owner';
import updateAppOwnerEmailV2 from './update-app-owner-email-v2/update-app-owner-email';

const organizationService = {
  addUserConsent: addUserConsent(orgManagementClient),
  auditUserLogin: auditUserLogin(orgManagementClient),
  getOrgStatus: getOrgStatus(reportClient),
  updateAppOwnerName: updateAppOwnerName(reportClient),
  deleteAppOwner: deleteAppOwner(reportClient),
  updateAppOwnerEmail: updateAppOwnerEmail(reportClient),
  deleteAppOwnerV2: deleteAppOwnerV2(tagsClient),
  updateAppOwnerNameV2: updateAppOwnerNameV2(tagsClient),
  updateAppOwnerEmailV2: updateAppOwnerEmailV2(tagsClient),
};

export default organizationService;
