import { FC } from 'react';
import { useSnapshot } from 'valtio';
import SettingsStore from '../../../../../../../settings/stores/settings-store';
import {
  SettingsName,
  SettingsSubType,
} from '../../../../../../../settings/types/settings-types';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';
import DevSummaryTab from './DevSummaryTab';

const DevSummaryTabContainer: FC<IssueDrawerTabProps> = ({ moveToTab }) => {
  const { selectedIssue, filterIssuesBy } = useSnapshot(IssuesStore);
  const { orgSettingsMapByName } = useSnapshot(SettingsStore);
  const isEnabledChatGpt = orgSettingsMapByName
    .find(s => s.name === SettingsName.ChatGPT)
    ?.settings.find(
      s => s.settingsSubType === SettingsSubType.ChatGPT,
    )?.enabled;

  return (
    <>
      {selectedIssue && (
        <DevSummaryTab
          moveToTab={moveToTab}
          selectedIssue={selectedIssue}
          filterIssuesBy={filterIssuesBy}
          isEnabledChatGpt={Boolean(isEnabledChatGpt)}
        />
      )}
    </>
  );
};

export default DevSummaryTabContainer;
