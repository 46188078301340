import { createStore, derive } from 'store-utils';
import { OscarStoreState, Technique } from '../types/oscar-matrix-types';

export const createMatrixStore = (matrixName: string) => {
  const proxy = createStore<OscarStoreState>(
    {
      tactics: [],
      loading: false,
      error: false,
    },
    `Oscar ${matrixName} Store`,
  );

  const derivedStore = derive(
    {
      flatTechniques: (get): FlatTechniques => {
        const { tactics } = get(proxy);
        if (tactics.length === 0) return [];
        const maxRows = Math.max(...tactics.map(t => t.techniques.length));
        const maxColumns = tactics.length;
        const temp: FlatTechniques = [];

        for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
          for (let columnIndex = 0; columnIndex < maxColumns; columnIndex++) {
            temp.push({
              technique: tactics[columnIndex].techniques[rowIndex],
              columnIndex,
              rowIndex,
            });
          }
        }

        return temp;
      },
    },
    { proxy },
  );

  return derivedStore;
};

type FlatTechniques = {
  technique?: Technique;
  columnIndex: number;
  rowIndex: number;
}[];
