import { CSSProperties, FC } from 'react';
import { SortIconProps } from 'react-data-grid';
import { ReactComponent as IconAsc } from '../assets/sort-asc.svg';
import { ReactComponent as IconDesc } from '../assets/sort-desc.svg';

const sharedStyle: CSSProperties = {
  width: 10,
  height: 10,
  marginInlineStart: '1ch',
};

export const OxSortIcon: FC<SortIconProps> = ({ sortDirection }) => {
  if (sortDirection === 'ASC') {
    return <IconAsc style={sharedStyle} />;
  } else if (sortDirection === 'DESC') {
    return <IconDesc style={sharedStyle} />;
  }

  return null;
};
