import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import IssueActionsTable from '../../../../IssueItem/IssueActionsTable/IssueActionsTable';
import { TicketingStore } from '../../../../../../../ticketing-module/store/ticketing-store';

const JiraTabContainer: FC = () => {
  const { selectedIssue, loadingJiraTicketsStatus } = useSnapshot(IssuesStore);
  const { isLoadingTicketingStatus } = useSnapshot(TicketingStore);

  return (
    <>
      {selectedIssue && (
        <IssueActionsTable
          key='Tickets'
          loading={!!loadingJiraTicketsStatus || isLoadingTicketingStatus}
          data={selectedIssue.tickets}
        />
      )}
    </>
  );
};

export default JiraTabContainer;
