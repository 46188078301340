import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';
import { DiscoveryResponse } from '../../scan-types';
import query from './get-parsed-discovery-progress.gql';

const fetchParsedDiscoveryProgress = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<DiscoveryProgressInput, Nullable<DiscoveryResponse>> => {
  return {
    execute: async (
      getReportDiscoveryDetailsInput: DiscoveryProgressInput,
    ): Promise<Nullable<DiscoveryResponse>> => {
      const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
      const results = await client.query<QueryResponse>({
        query,
        fetchPolicy,
        variables: { getReportDiscoveryDetailsInput },
      });

      if (!results.data) {
        throw new Error('failed to get discovery progress');
      }

      return results.data.getParsedReportDiscoveryOverview;
    },
  };
};

interface QueryResponse {
  getParsedReportDiscoveryOverview: DiscoveryResponse;
}

export interface DiscoveryProgressInput {
  scanID: string;
}

export default fetchParsedDiscoveryProgress;
