import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';
import { useTableConfig } from '../../../../IssueItem/ComplianceTable/useTableConfig';
import { OxDataGrid } from 'ox-react-components';
import { makeStyles } from 'tss-react/mui';
import { important } from 'ox-common-types';

import { Theme } from '@mui/material';

const ComplianceTabContainer: FC<
  React.PropsWithChildren<IssueDrawerTabProps>
> = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);
  const { classes } = useStyles();
  const complianceColumns = useTableConfig(classes);

  return (
    <>
      {selectedIssue && (
        <OxDataGrid
          key='Compliance'
          className={classes.table}
          columns={complianceColumns}
          rows={selectedIssue.compliance}
          rowHeight={70}
          headerRowHeight={35}
        />
      )}
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  table: {
    height: '100%',
    '--rdg-header-background-color': important(
      theme.palette.background.default,
    ),
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    display: '-webkit-box',
    whiteSpace: 'pre-wrap',
    lineClamp: 2,
    WebkitLineClamp: 2,
    lineHeight: 1,
    WebkitBoxOrient: 'vertical',
  },
}));

export default ComplianceTabContainer;
