import {
  darken,
  lighten,
  LinearProgress,
  Theme,
  useTheme,
} from '@mui/material';
import { important } from 'ox-common-types';
import { PropsWithChildren, RefObject, useCallback } from 'react';
import DataGrid, {
  DataGridHandle,
  DataGridProps,
  SortColumn,
} from 'react-data-grid';
import { makeStyles } from 'tss-react/mui';
import { OxSortIcon } from './OxSortIcon';

export const OxDataGrid = <P extends Object>(
  props: PropsWithChildren<OxDataGridProps<P>>,
) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const {
    tableRef,
    activeRowKey,
    activeRowValue,
    loading = false,
    className,
    disableScroll,
    ...dataGridProps
  } = props;
  const { onSort } = dataGridProps;

  const handleRowClass = useCallback(
    (row?: P) => {
      if (activeRowKey) {
        if (
          typeof activeRowKey === 'function' &&
          (activeRowKey as Function)(row) === activeRowValue
        ) {
          return classes.activeRow;
        } else {
          const activeRowKeyStr = activeRowKey as string;
          const rowAsRecord = row as unknown as Record<string, string>;
          if (rowAsRecord[activeRowKeyStr] === activeRowValue) {
            return classes.activeRow;
          }
        }
      }

      return undefined;
    },
    [activeRowKey, activeRowValue, classes.activeRow],
  );

  const handleSort = useCallback(
    (sortColumns: SortColumn[]) => {
      // currently supporting single column sorting
      const columnKey = sortColumns[0]?.columnKey;
      onSort && onSort(columnKey);
    },
    [onSort],
  );

  return (
    <div className={classes.oxDataGrid}>
      {loading && (
        <LinearProgress
          className={classes.loader}
          style={{ top: `${dataGridProps.headerRowHeight || 0}px` }}
        />
      )}
      <DataGrid
        ref={tableRef}
        className={cx(
          classes.table,
          className,
          theme.palette.mode === 'light' ? 'rdg-light' : 'rdg-dark',
          disableScroll && classes.disableScroll,
        )}
        rowClass={handleRowClass}
        components={{
          sortIcon: OxSortIcon,
        }}
        onSortColumnsChange={props.onSort && handleSort}
        {...dataGridProps}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  oxDataGrid: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  table: {
    border: 'none',
    height: '100%',
    width: '100%',
    fontSize: theme.typography.body2.fontSize,
    '--rdg-background-color': theme.palette.background.paper,
    '--rdg-header-background-color': theme.palette.background.paper,
    '--rdg-font-size': '10px',
    '&.rdg-dark': {
      '--rdg-background-color': theme.palette.background.paper,
      '--rdg-header-background-color': theme.palette.background.paper,
      '--rdg-row-hover-background-color': lighten(
        theme.palette.background.default,
        0.1,
      ),
    },
    '& [role="row"]': {
      cursor: 'pointer',
      zIndex: 0,
    },
    '& [role="columnheader"]': {
      fontWeight: 300,
      '& .rdg-header-sort-cell > span:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& [role="columnheader"], [role="gridcell"]': {
      outline: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    '& [role="gridcell"]': {
      outline: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shortest,
      }),
      // Fixes a bug where row borders appear only on even rows (instead of all rows)
      contain: 'inherit',
    },
  },
  activeRow: {
    '& [role="gridcell"]': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? darken(theme.palette.primary.main, 0.6)
          : lighten(theme.palette.primary.light, 0.6),
    },
  },
  loader: {
    position: important('absolute'),
    left: 0,
    width: '100%',
    height: important('1px'),
    zIndex: important('1'),
  },
  disableScroll: {
    overflow: 'hidden',
  },
}));

export interface OxDataGridProps<T> extends DataGridProps<T> {
  activeRowValue?: string | number | null;
  activeRowKey?: ((row: T) => string) | string;
  onSort?: (columnKey?: string) => void;
  loading?: boolean;
  tableRef?: RefObject<DataGridHandle>;
  disableScroll?: boolean;
}
