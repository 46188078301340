import { Theme, Tooltip, Typography } from '@mui/material';
import { stopPropagation } from 'common-utils';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SeverityColor, SeverityEnum } from 'severity-utils';
import { makeStyles } from 'tss-react/mui';
import { Technique } from '../types/oscar-matrix-types';

const OscarMatrixCellChips: FC<OscarMatrixCellChipsProps> = ({
  issueCounters,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.chips}>
      {(Object.keys(issueCounters) as SeverityEnum[]).map((severity, index) => (
        <SeverityColor key={severity} severity={severity}>
          {color => (
            <Tooltip
              title={
                <Typography variant='body2' letterSpacing={0.5}>
                  {severity[0].toUpperCase() + severity.substring(1)}
                </Typography>
              }
              arrow
              placement='top'>
              <Link
                to={issueCounters[severity]!.linkToIssuesPage}
                onClick={stopPropagation}
                className={classes.chip}
                style={{
                  borderColor: color,
                }}>
                {issueCounters[severity]?.count}
              </Link>
            </Tooltip>
          )}
        </SeverityColor>
      ))}
    </div>
  );
};

const CHIP_HEIGHT = 20;
const useStyles = makeStyles()((theme: Theme) => ({
  chips: {
    gap: theme.spacing(0.5),
    display: 'flex',
    marginBlockStart: theme.spacing(1),
  },
  chip: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: 12,
    height: CHIP_HEIGHT,
    minWidth: CHIP_HEIGHT,
    border: '2px solid',
    borderRadius: (CHIP_HEIGHT / 3) * 2,
    paddingInline: theme.spacing(0.5),
    textDecoration: 'none',
    color: theme.palette.text.primary,
    transitionDuration: '0ms',
    transitionDelay: '0ms',
    '&:hover': {
      transition: 'all 150ms ease-in-out',
      transform: 'scale(1.2)',
    },
  },
}));

export interface OscarMatrixCellChipsProps {
  issueCounters: Technique['issueCounters'];
}

export default OscarMatrixCellChips;
