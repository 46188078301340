import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  Slide,
  SlideProps,
  Theme,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { omit } from 'lodash-es';
import { Nullable } from 'ox-common-types';
import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { MdClose as Close } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

export const ModalDialog: FunctionComponent<
  PropsWithChildren<ModalDialogProps>
> = props => {
  const { classes } = useStyles();

  const {
    modalHeader,
    modalFooter,
    children,
    dataTestid,
    contentClassName,
    onClose,
    closeOnClickOutSide = true,
    hideCloseButton = false,
    hideHeader,
    ...rest
  } = props;

  const restOfDialogProps = omit(rest, ['modalColor']);

  const onDialogClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (closeOnClickOutSide && onClose) {
      onClose(e);
      return;
    }

    return undefined;
  };

  return (
    <Dialog
      onClose={onDialogClose}
      fullWidth
      aria-labelledby='modal-dialog'
      data-testid={dataTestid}
      TransitionComponent={Transition}
      {...restOfDialogProps}>
      {!hideHeader && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          className={classes.header}
          data-testid={dataTestid + '-header'}
          pl={2}
          pr={1}
          minHeight={50}>
          {typeof modalHeader === 'string' ? (
            <Typography color='primary.contrastText' variant='h6'>
              {modalHeader}
            </Typography>
          ) : (
            modalHeader
          )}

          {!hideCloseButton && (
            <IconButton size='medium' onClick={onClose}>
              <Close className={classes.closeIcon} />
            </IconButton>
          )}
        </Box>
      )}

      <DialogContent
        data-testid={dataTestid + '-body'}
        dividers
        className={contentClassName}>
        {children}
      </DialogContent>
      {modalFooter && (
        <DialogActions
          className={classes.dialogActions}
          data-testid={dataTestid + '-footer'}>
          {modalFooter}
        </DialogActions>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
  closeIcon: {
    color: theme.palette.common.white,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<SlideProps, 'Slide'>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction={props.in ? 'down' : 'up'} ref={ref} {...props} />;
});

type ModalDialogProps = Omit<DialogProps, 'onClose'> & ModalProps;

interface ModalProps {
  modalColor?: string;
  modalHeader?: Nullable<ReactNode> | string;
  modalFooter?: Nullable<ReactNode>;
  dataTestid?: string;
  contentClassName?: string;
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  closeOnClickOutSide?: boolean;
  hideCloseButton?: boolean;
  hideHeader?: boolean;
}
