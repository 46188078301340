import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import { ITag, OxTagType } from '../../../applications/applications-types';
import query from './get-edit-applications-tags-content.gql';

const getEditApplicationsTagsData = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getAllTagsInput: GetAllTagsInput,
      getAppTagsInput: GetAppsTagsInput,
    ): Promise<Nullable<QueryResponse>> => {
      try {
        const results = await client.query<QueryResponse>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: {
            getTagsInput: getAllTagsInput,
            getAppsTagsInput: getAppTagsInput,
          },
        });

        return results.data;
      } catch (error) {
        logger.error('Failed to fetch ox tags', error);
        return null;
      }
    },
  };
};

interface GetAppsTagsInput {
  filters?: {
    appId?: string[];
    tagType?: OxTagType[];
  };
}

interface GetAllTagsInput {
  filters?: {
    tagType?: OxTagType[];
  };
}
interface QueryResponse {
  getAllTags: { tags: ITag[] };
  getAppTags: {
    appsTags: {
      appId: string;
      tag: ITag;
    }[];
  };
}

export default getEditApplicationsTagsData;
