import { InventoryFilter } from 'ox-common-types';
import inventoryConfig from '../config/inventory-config.json';
import type { DashboardFilter, InventoryResults } from '../inventory-types';

export const createInventoryFilters = (
  inventory: InventoryResults[],
): DashboardFilter[] => {
  return inventory.map(item => ({
    count: item.count,
    label: item.label,
    delta: item.delta,
    tooltip: item.tooltip,
    trend: item.trend as DashboardFilter['trend'],
    type: InventoryFilter[item.name],
  }));
};

export const sortFilters = (filters: DashboardFilter[]) => {
  const { filtersOrder } = inventoryConfig;
  const orderedFilters = filtersOrder.reduce((acc, curr) => {
    const target = filters.find(f => f.label === curr);
    if (target) {
      acc.push(target);
    }
    return acc;
  }, [] as DashboardFilter[]);

  return orderedFilters.concat(
    filters.filter(f => !filtersOrder.includes(f.label)),
  );
};
