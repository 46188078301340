import { FC, ReactNode } from 'react';

import { Box, Divider, Typography } from '@mui/material';

const NodeTooltip: FC<React.PropsWithChildren<NodeTooltipProps>> = ({
  children,
  title,
}) => {
  return (
    <Box px={1} py={0.5} display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='row'>
        <Typography variant='body1'>{title}</Typography>
      </Box>
      <Divider sx={{ my: 1 }} />
      {children}
    </Box>
  );
};

export interface NodeTooltipProps {
  title: string | ReactNode;
}

export default NodeTooltip;
