import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Relevance } from 'ox-common-types';
import mutation from './set-relevance.gql';

const setRelevance = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async ({ relevance, appIds }: SetRelevanceInput) => {
      try {
        const results = await client.mutate<{
          setRelevance: SetRelevanceResponse;
        }>({
          mutation,
          variables: { relevanceInput: { relevance, appIds } },
        });

        return results?.data?.setRelevance?.hasModifiedRelevance;
      } catch (error) {
        logger.error(`Failed to set app relevance for ${appIds}`, error);
        return null;
      }
    },
  };
};

export interface SetRelevanceInput {
  appIds: string[];
  relevance: Relevance;
}

export interface SetRelevanceResponse {
  hasModifiedRelevance: boolean;
}

export default setRelevance;
