import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import query from './get-applications-owners.gql';
import { AppOwnerTag } from '../../application-owners-types';

const getApplicationsOwnersV2 = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async () => {
      try {
        const results = await client.query<{
          getAllTags: { tags: AppOwnerTag[] };
        }>({
          query,
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              filters: {
                tagType: 'owner',
              },
            },
          },
        });

        return results.data.getAllTags.tags;
      } catch (error) {
        logger.error('Failed to fetch applications owners', error);
        return null;
      }
    },
  };
};

export default getApplicationsOwnersV2;
