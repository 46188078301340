import { User } from '@auth0/auth0-react';
import { USER_LOGINS_COUNT } from 'env-utils';
import { logger } from 'logging-utils';
import whatsNewService from '../services';
import * as storeActions from '../store-actions/whats-new-store-actions';
import { closeWhatsNew } from './whats-new-close-action';

export const firstTimeLoadWhatsNew = async (user: User | undefined) => {
  if (!user) {
    return;
  }

  if (user[USER_LOGINS_COUNT] <= 2) {
    await closeWhatsNew(true);
    return;
  }

  loadWhatsNew();
};

export const loadWhatsNew = async (uiLoadTimestamp?: Date) => {
  try {
    const results = await whatsNewService.getWhatsNew.execute(uiLoadTimestamp);
    const shouldShow = results && results.items.length > 0;
    if (shouldShow) {
      storeActions.setItems(results?.items || []);
    }
  } catch (err) {
    logger.error('Failed to fetch whats-new items', err);
  }
};

export const pollWhatsNew = async () => {
  const loadTimestamp = storeActions.getUILoadTimestamp();
  await loadWhatsNew(new Date(loadTimestamp));
};
