import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import mutation from './set-allow-user-to-request-access.gql';
import {
  SetConfigurationInput,
  SetConfigurationResponse,
} from '../../request-access-types';

const setAllowUserToRequestAccess = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      input: SetConfigurationInput,
    ): Promise<Nullable<SetConfigurationResponse>> => {
      try {
        const results =
          await client.mutate<SetAllowUserToRequestAccessMutation>({
            mutation,
            variables: { input },
          });

        if (!results || !results.data) {
          return null;
        }

        return results.data.setAllowUserToRequestAccess;
      } catch {
        return null;
      }
    },
  };
};

interface SetAllowUserToRequestAccessMutation {
  setAllowUserToRequestAccess: SetConfigurationResponse;
}

export default setAllowUserToRequestAccess;
