import { snapshot } from 'valtio';
import {
  setShowCreateJiraTicketDialog as setShowCreateJiraTicketDialogCurrentIssues,
  setShowLinkJiraTicketDialog,
  setShowUnlinkJiraTicketDialog,
} from '../../active-issues/store-actions/issue-store-actions';
import {
  addJiraTicketToSelectedIssue as addJiraToSelectedIssuePipeline,
  removeJiraTicketsFromRawIssue as removeJiraTicketsFromRawIssuePipeline,
  removeJiraTicketsFromSelectedIssue as removeJiraTicketsFromSelectedIssuePipeline,
  setShowCreateJiraTicketDialog as setShowCreateJiraTicketDialogPipeline,
  setShowLinkJiraTicketDialog as setShowLinkJiraTicketDialogPipeline,
  setShowUnlinkJiraTicketDialog as setShowUnlinkJiraTicketDialogPipeline,
} from '../../pipeline-issues/store-actions/pipeline-issues-store-actions';
import { IssuePages } from '../types/issues-types';

import {
  addJiraTicketToSelectedIssue as addJiraToSelectedIssueCurrentIssues,
  getIssuesOpenFilterItems as getIssuesOpenFilterItemsCurrentIssues,
  removeJiraTicketsFromRawIssue as removeJiraTicketsFromRawIssueCurrentIssues,
  removeJiraTicketsFromSelectedIssue as removeJiraTicketsFromSelectedIssueCurrentIssues,
} from '../../active-issues/store-actions/issue-store-actions';
import { getPipelineIssuesFilterItems } from '../../pipeline-issues/actions/pipeline-filter-issues-actions';

import { scanTypes } from 'ox-common-types';
import { updateJiraTicketsLatestStatus as updateJiraTicketsLatestStatusCurrentIssues } from '../../active-issues/actions/active-issues-actions';
import { updateJiraTicketsLatestStatus as updatePipelineJiraTicketsLatestStatus } from '../../pipeline-issues/actions/pipeline-issues-actions';
import { getStoreByPage } from './store-utils';

export const getIssueActionsByIssuesPage = (issuesPage: IssuePages) => {
  const isPipelineIssuesPage = issuesPage === IssuePages.PipelineIssues;
  const store = getStoreByPage(issuesPage);
  const { selectedIssue } = snapshot(store);

  const [
    showTicketDialog,
    showUnlinkTicketDialog,
    showLinkTicketDialog,
    addJiraTicketToSelectedIssue,
    updateJiraTicketsLatestStatus,
    getIssuesOpenFilterItems,
    removeJiraTicketsFromSelectedIssue,
    removeJiraTicketsFromRawIssue,
  ] = isPipelineIssuesPage
    ? [
        setShowCreateJiraTicketDialogPipeline,
        setShowUnlinkJiraTicketDialogPipeline,
        setShowLinkJiraTicketDialogPipeline,
        addJiraToSelectedIssuePipeline,
        updatePipelineJiraTicketsLatestStatus,
        getPipelineIssuesFilterItems,
        removeJiraTicketsFromSelectedIssuePipeline,
        removeJiraTicketsFromRawIssuePipeline,
      ]
    : [
        setShowCreateJiraTicketDialogCurrentIssues,
        setShowUnlinkJiraTicketDialog,
        setShowLinkJiraTicketDialog,
        addJiraToSelectedIssueCurrentIssues,
        updateJiraTicketsLatestStatusCurrentIssues,
        getIssuesOpenFilterItemsCurrentIssues,
        removeJiraTicketsFromSelectedIssueCurrentIssues,
        removeJiraTicketsFromRawIssueCurrentIssues,
      ];

  return {
    selectedIssue,
    showTicketDialog,
    showUnlinkTicketDialog,
    showLinkTicketDialog,
    addJiraTicketToSelectedIssue,
    updateJiraTicketsLatestStatus,
    getIssuesOpenFilterItems,
    removeJiraTicketsFromSelectedIssue,
    removeJiraTicketsFromRawIssue,
  };
};

export const getScanTypeByIssuesPage = (issuesPage: IssuePages) =>
  issuesPage === IssuePages.PipelineIssues
    ? scanTypes.PipelineScan
    : scanTypes.FullScan;
