import { Nullable } from 'ox-common-types';
import { SbomLibraryItem } from '../sbom-types';
import SbomStore from './sbom-store';

export const setSbomOffset = (offset: number) => {
  SbomStore.offset = offset;
};
export const setSbomLibraries = (sbom: SbomLibraryItem[], update: boolean) => {
  if (update) {
    SbomStore.libraries = sbom;
  } else {
    SbomStore.libraries?.push(...sbom);
  }
};

export const setSbomLibrariesTotal = (total: number) => {
  SbomStore.total = total;
};

export const setTopSearchValue = (value: string) => {
  SbomStore.topSearchValue = value;
};

export const toggleFiltersPanel = () => {
  SbomStore.filtersOpen = !SbomStore.filtersOpen;
};

export const setLastDrawerHeight = (height: number) => {
  SbomStore.lastDrawerHeight = height;
};

export const setSelectedSbomData = (sbomData: Nullable<SbomLibraryItem>) => {
  SbomStore.selectedSbomData = sbomData;
};

export const clearSbom = () => {
  SbomStore.libraries = [];
  SbomStore.offset = 0;
  SbomStore.total = 0;
  SbomStore.topSearchValue = '';
  SbomStore.selectedSbomData = null;
  SbomStore.selectedSbom = null;
};
