import { Theme, useTheme } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { FC } from 'react';
import { AiOutlineFork } from 'react-icons/ai';
import { FaDownload, FaStar, FaUserFriends } from 'react-icons/fa';
import { IconCircleDot, IconLicense } from '@tabler/icons-react';
import { makeStyles } from 'tss-react/mui';
import GeneralInfoSection from '../../../applications/components/ApplicationCard/GeneralInfoSection';
import { SbomLibraryItem } from '../../sbom-types';
import SbomRowTitle from './SbomRowTitle';
import SbomTabRow from './SbomTabRow';
import { IconType } from 'ox-common-types/src/icon-types';

const SbomChecks: FC<React.PropsWithChildren<SbomChecksProps>> = ({ sbom }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const { high, passed } = theme.palette.categoryRiskColor;
  const {
    licenseIssue,
    license,
    libLink,
    notPopular,
    latestVersionDate,
    latestVersion,
    libraryVersion,
    contributors,
    downloads,
    maintainers,
    stars,
    forks,
    openIssues,
    isDeprecated,
    notUpdated,
    notImported,
    packageManagerLink,
  } = sbom;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <SbomTabRow
          withBorder
          title={
            <SbomRowTitle
              withBorder
              text={
                licenseIssue === true
                  ? 'Issue with License'
                  : licenseIssue === false
                  ? 'License approved'
                  : 'N/A'
              }
              title='License Check'
              color={licenseIssue === false ? passed : high}
            />
          }>
          <GeneralInfoSection
            title='License'
            value={license || 'Not Found'}
            link={libLink}
            icon={IconLicense as IconType}
          />
        </SbomTabRow>

        <SbomTabRow
          title={
            <SbomRowTitle
              title='Maintenance Check'
              link={packageManagerLink}
              text={
                isDeprecated === true
                  ? 'Deprecated'
                  : isDeprecated === false
                  ? 'Active'
                  : 'N/A'
              }
              color={isDeprecated === false ? passed : high}
            />
          }>
          {maintainers && (
            <GeneralInfoSection
              title='Maintainers'
              value={maintainers}
              icon={FaUserFriends}
            />
          )}

          {contributors && (
            <GeneralInfoSection
              title='Contributors'
              value={contributors}
              icon={FaUserFriends}
            />
          )}
          {openIssues && (
            <GeneralInfoSection
              title='Open Issues'
              value={openIssues}
              icon={IconCircleDot as IconType}
            />
          )}
        </SbomTabRow>
      </div>
      <div className={classes.leftColumn}>
        <SbomTabRow
          title={
            <SbomRowTitle
              title='Update Check'
              text={
                notUpdated === true
                  ? 'Not updated'
                  : notUpdated === false
                  ? 'Updated'
                  : 'N/A'
              }
              color={notUpdated === false ? passed : high}
            />
          }>
          {latestVersion && (
            <GeneralInfoSection title='Latest Version' value={latestVersion} />
          )}
          {latestVersionDate && (
            <GeneralInfoSection
              title='Release Date'
              value={formatDistanceToNow(new Date(latestVersionDate), {
                addSuffix: true,
              })}
            />
          )}
          {libraryVersion && (
            <GeneralInfoSection
              title='Version I use'
              value={`${libraryVersion}`}
            />
          )}
        </SbomTabRow>
      </div>
      <div>
        <SbomTabRow
          withBorder
          title={
            <SbomRowTitle
              withBorder
              title='Popularity Check'
              text={
                notPopular === true
                  ? 'Not Popular Library'
                  : notPopular === false
                  ? 'Popular Library'
                  : 'N/A'
              }
              color={notPopular === false ? passed : high}
            />
          }>
          {downloads && (
            <GeneralInfoSection
              title='Downloads'
              value={downloads}
              icon={FaDownload}
            />
          )}
          {stars && (
            <GeneralInfoSection title='Stars' value={stars} icon={FaStar} />
          )}
          {forks ? (
            <GeneralInfoSection
              title='Forks'
              value={forks}
              icon={AiOutlineFork}
            />
          ) : (
            <></>
          )}
        </SbomTabRow>

        <SbomTabRow
          title={
            <SbomRowTitle
              title='Usage Check'
              text={
                notImported === true
                  ? 'Not Used In Code'
                  : notImported === false
                  ? 'Used In Code'
                  : 'N/A'
              }
              color={notImported === false ? passed : high}
            />
          }></SbomTabRow>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridAutoColumns: 'dense',
    gap: theme.spacing(1),
  },
  leftColumn: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
}));

export interface SbomChecksProps {
  sbom: SbomLibraryItem;
}

export default SbomChecks;
