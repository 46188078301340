import {
  Box,
  Link as MUILink,
  Typography,
  TypographyProps,
} from '@mui/material';
import { stopPropagation } from 'common-utils';
import { FC } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

export const CustomLink: FC<React.PropsWithChildren<CustomLinkProps>> = ({
  value,
  href,
  textClassName,
  linkSize,
  gap,
  ...props
}) => {
  return (
    <Box
      display='flex'
      flexDirection='row'
      gap={gap || 1}
      alignItems='center'
      component='span'>
      <Typography
        variant='caption'
        color='text.primary'
        {...props}
        className={textClassName}>
        {value}
      </Typography>
      {href && (
        <MUILink
          onClick={stopPropagation}
          sx={{ fontSize: linkSize || 8, lineHeight: 1 }}
          href={href}
          target='_blank'>
          <FaExternalLinkAlt style={{ color: '#585cfc' }} />
        </MUILink>
      )}
    </Box>
  );
};

export interface CustomLinkProps extends TypographyProps {
  value: string;
  href?: string;
  textClassName?: string;
  linkSize?: number;
  gap?: number;
}
