import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { GetWhatsNewHistoryResponse } from '../whats-new-types';
import query from './get-whats-new-history.gql';

const getWhatsNewHistory = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async () => {
      try {
        const results = await client.query<GetWhatsNewHistoryResponse>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
        });
        return results.data.getWhatsNewHistory.items;
      } catch (error) {
        logger.error('Failed to fetch whats-new history items', error);
      }
    },
  };
};

export default getWhatsNewHistory;
