import { ApolloError } from '@apollo/client';
import { User } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { AppPages, navigate } from 'app-navigator';
import { AppEvents } from 'common-events';
import {
  HubSpotFormsIds,
  HubSpotOxAppFormFields,
  HubSpotOxBaseFormFields,
  submitHubSpotForm,
} from 'hubspot';
import { snapshot } from 'valtio';
import {
  redirectToBitbucketAppInstallation,
  redirectToGitHubAppInstallation,
} from '../../connectors/actions/connector-configure-form-actions';
import {
  connectorService,
  redirectToIDP,
} from '../../connectors/api/connectors-api';
import {
  AddCredentialsInput,
  Connector,
  CredentialsConfigureResponse,
} from '../../connectors/connectors-types';
import { scanAll } from '../../new-scan/actions/scan-actions';
import { createNewOrganization } from '../../organizations/actions/create-organization-actions';
import { changeOrganizationName } from '../../organizations/api/organizations-api';
import { ConnectionType, OnboardingSteps } from '../onboarding-types';
import {
  setChosenCodeRepo,
  setHostUrl,
  setInitOnboardingInProcess,
  setIsLoading,
  setOnboardingConnectorsError,
  setOnboardingError,
  setOnboardingSkipped,
  setOnboardingStep,
  setOrgName,
  setSkipModalVisibility,
  setTokenOrPassword,
  setUsername,
} from '../store-actions/onboarding-store-actions';
import OnboardingStore from '../stores/onboarding-store';
import { setDemoEnabled } from '../../new-scan/store/scan-store-actions';

export const handleCodeRepoSelection = (codeRepoName: string) => {
  const { codeRepoConnectors } = snapshot(OnboardingStore);
  if (!codeRepoConnectors) {
    throw new Error('Code repository connectors were not found');
  }
  const codeRepo = codeRepoConnectors.find(
    connector => connector.name === codeRepoName,
  );
  if (!codeRepo) {
    throw new Error(
      `Could not find a matching repo connector with this name: ${codeRepoName}`,
    );
  }
  setChosenCodeRepo(codeRepo);
  setHostUrl(codeRepo.hostURL || '');
};

export const handleOrgNameChange = (orgName: string) => {
  setOrgName(orgName);
};

export const initOnboarding = async (user: User) => {
  setInitOnboardingInProcess(true);
  const orgName = user?.nickname ? `${user.nickname} Org` : 'MyFirstOrg';
  datadogLogs.logger.info(
    `Onboarding: Initializing onboarding, creating new organization with name: ${orgName}`,
    { user },
  );
  submitHubSpotForm(
    {
      email: user.email!,
      verified_email: !!user.email_verified,
      firstname: user.given_name || user.name,
      lastname: user.family_name,
    },
    HubSpotFormsIds.OxAppForm,
  );
  const result = await createNewOrganization(orgName);
  if (!result) {
    datadogLogs.logger.error(`Onboarding: Creating org ${orgName} failed`, {
      user,
    });
    setOnboardingError(
      true,
      'Could not create organization, please try refreshing the page',
    );
    return;
  }
  const logMessage = `Onboarding: The org ${orgName} was created successfully`;
  datadogLogs.logger.info(logMessage, {
    user,
    org: result,
    logMessage,
  });
};

export const isOnboardingRequired = (connectors: Connector[]) => {
  const { onboardingSkipped } = snapshot(OnboardingStore);
  if (onboardingSkipped) {
    return false;
  }
  return !connectors.some(connector => connector.isConfigured);
};

export const connectManualCredentials = async (isMultiToken: boolean) => {
  const {
    hostUrl: hostURL,
    tokenOrPassword,
    chosenCodeRepo,
    username,
  } = snapshot(OnboardingStore);
  if (!chosenCodeRepo) {
    return;
  }
  setIsLoading(true);
  const addCredentialsInput: AddCredentialsInput = {
    connectorID: chosenCodeRepo.id,
    hostURL,
    credentialsType: chosenCodeRepo.credentialsType,
    credentialsInput: {
      name: username,
      password: tokenOrPassword,
      token: tokenOrPassword,
      clientId: '',
      secretKey: '',
      awsExternalId: '',
      awsRoleArn: '',
      awsAccessKey: '',
      awsAccessSecret: '',
      tenant: '',
      projectId: '',
      apiAccessKey: '',
      apiSecretKey: '',
      tenantId: '',
      clientSecret: '',
      subscriptionId: '',
      organizationId: '',
      apiKey: '',
      apiUrl: '',
      appId: '',
      webhookUrl: '',
      extraOptionalCreds: {},
    },
  };
  if (
    addCredentialsInput.hostURL !== null &&
    addCredentialsInput.hostURL.endsWith('/')
  ) {
    addCredentialsInput.hostURL = addCredentialsInput.hostURL.substring(
      0,
      addCredentialsInput.hostURL.length - 1,
    );
  }
  try {
    const result = await connectorService.addCredentials.execute(
      addCredentialsInput,
      isMultiToken,
    );
    if (!result) {
      throw new Error('Adding credentials failed due to an unknown error');
    }

    document.dispatchEvent(
      new CustomEvent<CredentialsConfigureResponse>(
        AppEvents.Connectors.ConnectorConfigured,
        {
          detail: result,
        },
      ),
    );
    datadogLogs.logger.info(
      `Onboarding: Connected to ${chosenCodeRepo.displayName} successfully, moving to repo selection`,
    );
    setOnboardingStep(OnboardingSteps.ChooseRepos);
  } catch (error) {
    let updatedConnector: Connector;
    datadogLogs.logger.error(
      `Onboarding: Connecting to ${chosenCodeRepo.displayName} failed due to an error: ${error}`,
    );
    if (error instanceof ApolloError) {
      const errorMsg =
        error.graphQLErrors?.[0].message ||
        `Didn't manage to add credentials due to an error: ${error}`;
      updatedConnector = {
        ...chosenCodeRepo,
        errorMessage: errorMsg,
      };
    } else {
      updatedConnector = {
        ...chosenCodeRepo,
        errorMessage: `Error trying to add credentials: ${error}`,
      };
    }
    setChosenCodeRepo(updatedConnector);
  } finally {
    setIsLoading(false);
  }
};

export const handleConnect = async (isMultiToken: boolean) => {
  const { originalOrgName, orgName, chosenCodeRepo, connectionType } =
    snapshot(OnboardingStore);
  if (!chosenCodeRepo) {
    setOnboardingConnectorsError(
      'A code repository was not chosen, please try and choose again or refresh the page.',
    );
    datadogLogs.logger.error(
      `Onboarding: no chosenCodeRepo existed before handleConnect was called`,
    );
    return;
  }

  datadogLogs.logger.info(
    `Onboarding: connecting ${chosenCodeRepo.displayName} connector via ${connectionType}`,
    { orgName, connectionType, chosenCodeRepo },
  );

  setIsLoading(true);

  if (originalOrgName !== orgName) {
    try {
      datadogLogs.logger.info(
        `Onboarding: Changing org name from ${originalOrgName} to ${orgName}`,
      );
      await changeOrganizationName(orgName);
    } catch (err) {
      setOnboardingConnectorsError(
        'Failed to change organization name, please try again later.',
      );
      datadogLogs.logger.error(
        `Onboarding: failed to change org name to ${orgName} due to an error: ${err}`,
        { error: err },
      );
    }
  }

  if (connectionType === ConnectionType.IDP) {
    redirectToIDP(chosenCodeRepo, true);
  } else if (connectionType === ConnectionType.GitHubApp) {
    redirectToGitHubAppInstallation(chosenCodeRepo, true);
  } else if (connectionType === ConnectionType.BitbucketApp) {
    redirectToBitbucketAppInstallation(chosenCodeRepo, true);
  } else {
    connectManualCredentials(isMultiToken);
  }
};

export const handleAdvancedOptionsInputChange = (
  event: React.ChangeEvent<HTMLInputElement>,
) => {
  switch (event.target.name) {
    case 'hostURL':
      setHostUrl(event.target.value);
      break;
    case 'token':
      setTokenOrPassword(event.target.value);
      break;
    case 'username':
      setUsername(event.target.value);
      break;
  }
};

export const showSkipOnBoarding = () => setSkipModalVisibility(true);
export const closeSkipOnboarding = () => setSkipModalVisibility(false);

export const skipOnboarding = () => {
  const { onboardingStep } = snapshot(OnboardingStore);
  setSkipModalVisibility(false);
  setOnboardingStep(null);
  setOnboardingSkipped(true);
  if (onboardingStep === OnboardingSteps.Discovery) {
    navigate(AppPages.Dashboard);
  } else {
    navigate(AppPages.Connectors);
  }
};

export const startDemoScan = (user?: User) => {
  datadogLogs.logger.info('Onboarding: Demo data requested', {
    demoScanCalled: true,
  });
  setDemoEnabled(true);
  if (user) {
    submitHubSpotForm(
      {
        [HubSpotOxBaseFormFields.Email]: user.email!,
        [HubSpotOxBaseFormFields.FirstName]: user.given_name || user.name,
        [HubSpotOxBaseFormFields.LastName]: user.family_name,
        [HubSpotOxAppFormFields.DemoDataRequested]: true,
      },
      HubSpotFormsIds.OxAppForm,
    );
  }
  setOnboardingSkipped(true);
  scanAll(true);
  navigate(AppPages.Dashboard);
};
