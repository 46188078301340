import { sum } from 'lodash-es';
import { IssueCounters, RawOscarTechnique } from '../types/oscar-matrix-types';
import { createLinkToIssues } from '../utils/oscar-routing-utils';
import { createIssueCounters } from './oscar-issueCounters-model';

export const createTechnique = (rawTechnique: RawOscarTechnique) => {
  const issueCounters = createIssueCounters(
    rawTechnique.issuesCountBySeverity,
    rawTechnique.id,
    rawTechnique.name,
  );
  const sumIssues = sumIssueCounters(issueCounters);

  return {
    id: rawTechnique.id,
    name: rawTechnique.name,
    isCoveredByOx: rawTechnique.isCoveredByOx,
    issueCounters,
    linkToIssuesPage: createLinkToIssues(rawTechnique.id, rawTechnique.name),
    sumIssues,
  };
};

const sumIssueCounters = (issueCounters: Partial<IssueCounters>): number =>
  sum(Object.values(issueCounters).map(counter => counter.count));
