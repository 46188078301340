import {
  Checkbox,
  darken,
  lighten,
  LinearProgress,
  Theme,
  Typography,
} from '@mui/material';
import { important } from 'ox-common-types';
import { FilterDetails, FilterTypeToFilter } from 'ox-filter-utils';
import { FC } from 'react';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { CgCloseR } from 'react-icons/cg';
import {
  MdFiberManualRecord as FiberManualRecordIcon,
  MdOutlineCheckBox,
} from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';
import { ConditionalFiltersCondition } from '../../../ox-filter-utils/src/filters-utils';
import { TrendIcon } from '../TrendIcon/TrendIcon';
import { FilterItemTooltip } from './FilterItemTooltip';

export const FilterItem: FC<FilterItemProps> = ({
  categoryIcon: CategoryIcon,
  name,
  count,
  percent,
  color,
  filterTypeToFilterBy,
  checked,
  onFilterChange,
  splitLabel,
  itemHeight,
  disabled,
  id,
  changeNumber,
  isStatistic,
  loadingFilters,
  excludeFilterChecked,
  isShowExcludeFilterCheckbox,
}) => {
  const { classes } = useStyles();
  if (name === null) {
    return null;
  }

  const FilterItemCheckBoxWithExclude = () => {
    return (
      <div>
        {excludeFilterChecked ? (
          <Checkbox
            color='primary'
            size='small'
            className={`${classes.checkboxWithExclude} ${
              excludeFilterChecked ? classes.excludeFilterChecked : ''
            }  ${loadingFilters && classes.excludeFilterCheckedLoading}`}
            value={id}
            disabled={!!disabled || loadingFilters}
            id={filterTypeToFilterBy}
            onChange={e => {
              if (onFilterChange) {
                onFilterChange(e, ConditionalFiltersCondition.NOT);
              }
            }}
            checked={excludeFilterChecked}
            icon={<CgCloseR size={17} />}
          />
        ) : (
          <Checkbox
            color='primary'
            size='small'
            value={id}
            className={`${classes.checkboxWithExclude} ${
              !checked && classes.checkboxPlaceholderIcon
            }`}
            disabled={!!disabled || loadingFilters}
            id={filterTypeToFilterBy}
            onChange={e => {
              if (onFilterChange) {
                onFilterChange(e, ConditionalFiltersCondition.OR);
              }
            }}
            checked={checked}
          />
        )}
        {!loadingFilters && (
          <Checkbox
            color='primary'
            size='small'
            className={classes.popOut}
            value={id}
            disabled={!!disabled || loadingFilters}
            id={filterTypeToFilterBy}
            onChange={e => {
              if (!onFilterChange) return;

              if (excludeFilterChecked) {
                onFilterChange(e, ConditionalFiltersCondition.OR);
              } else {
                onFilterChange(e, ConditionalFiltersCondition.NOT);
              }
            }}
            icon={
              loadingFilters ? undefined : excludeFilterChecked ? (
                <MdOutlineCheckBox size={21} />
              ) : (
                <AiOutlineCloseSquare
                  size={19}
                  className={classes.excludePlaceholderIcon}
                />
              )
            }
          />
        )}
      </div>
    );
  };

  return (
    <FilterItemTooltip title={isStatistic ? '' : name} tooltipEnterDelay={0}>
      <div
        className={`${classes.filterItem} `}
        style={{ height: itemHeight }}
        data-testid={`filter_subitem filter_item_${id}`}>
        {isShowExcludeFilterCheckbox ? (
          <FilterItemCheckBoxWithExclude />
        ) : (
          <div>
            <Checkbox
              color='primary'
              size='small'
              className={classes.checkbox}
              value={id}
              disabled={!!disabled || loadingFilters}
              id={filterTypeToFilterBy}
              onChange={e => {
                if (onFilterChange) {
                  onFilterChange(e, ConditionalFiltersCondition.OR);
                }
              }}
              checked={checked}
            />
          </div>
        )}
        <span>
          {CategoryIcon &&
            filterTypeToFilterBy === FilterTypeToFilter.Category && (
              <CategoryIcon width={12} height={12} className={classes.icon} />
            )}
          {filterTypeToFilterBy ===
            FilterTypeToFilter.SeverityChangeReasons && (
            <TrendIcon value={changeNumber} />
          )}
          {!CategoryIcon && color && (
            <FiberManualRecordIcon
              style={{
                color,
                display: 'inline-block',
                width: 10,
                height: 10,
              }}
            />
          )}
        </span>

        <Typography
          variant='body2'
          color='text.secondary'
          fontSize='0.7rem'
          component='span'
          className={classes.name}>
          {filterTypeToFilterBy === FilterTypeToFilter.Apps ? splitLabel : name}
        </Typography>

        <Typography
          variant='body2'
          color={loadingFilters ? 'text.secondary' : 'text.primary'}
          fontSize='0.68rem'>
          {loadingFilters ? '--' : count}
        </Typography>
        <LinearProgress value={percent} variant='determinate' />
      </div>
    </FilterItemTooltip>
  );
};

const useStyles = makeStyles<void, 'popOut' | 'checkboxPlaceholderIcon'>()(
  (theme: Theme, _, classes) => ({
    filterItem: {
      display: 'grid',
      gridColumnGap: theme.spacing(0.5),
      gridTemplateColumns: 'auto auto 1fr auto 0.2fr',
      alignItems: 'center',
      paddingInlineEnd: theme.spacing(1.5),
      transition: theme.transitions.create('background', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        background:
          theme.palette.mode === 'light'
            ? lighten(theme.palette.background.default, 0.5)
            : darken(theme.palette.background.default, 0.2),
      },
      [`&:hover .${classes.checkboxPlaceholderIcon}`]: {
        svg: {
          path: {
            d: `path(
                "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"
              )`,
          },
        },
      },

      [`&:hover .${classes.popOut}`]: {
        display: 'inline-grid',
        marginLeft: -8,
        marginRight: -6,
        opacity: 1,
        pointerEvents: 'auto',
      },
    },
    popOut: {
      opacity: 0,
      transition: 'all 0.1s ease-in-out',
      marginLeft: -38,
      pointerEvents: 'none',
    },
    checkbox: {
      padding: important(theme.spacing(0.5)),
    },
    checkboxWithExclude: {
      paddingLeft: theme.spacing(0.5),
    },
    excludeFilterChecked: {
      svg: {
        backgroundColor: theme.palette.error.main,
        width: 15,
        height: 15,
        borderRadius: 2.5,
        marginLeft: 2.5,
        fill: theme.palette.background.default,
        path: {
          d: `path(
                    "M 19 6.41 L 17.59 5 L 12 10.59 L 6.41 5 L 5 6.41 L 10.59 12 L 5 17.59 L 6.41 19 L 12 13.41 L 17.59 19 L 19 17.59 L 13.41 12 Z"
                  )`,
        },
      },
    },
    excludeFilterCheckedLoading: {
      svg: {
        backgroundColor: theme.palette.text.primary,
        opacity: 0.35,
      },
    },
    checkboxPlaceholderIcon: {},
    excludePlaceholderIcon: {
      marginTop: -1,
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    icon: {
      color: theme.palette.primary.main,
      display: 'flex',
    },
  }),
);

export interface FilterItemProps extends FilterDetails {
  checked: boolean;
  excludeFilterChecked: boolean;
  onFilterChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    condition?: ConditionalFiltersCondition,
  ) => void;
  filterTypeToFilterBy?: FilterTypeToFilter;
  itemHeight: number;
  disabled?: boolean;
  isStatistic?: boolean;
  changeNumber: number;
  loadingFilters?: boolean;
  isShowExcludeFilterCheckbox: boolean;
}
