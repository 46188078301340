import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import { LogName } from '@oxappsec/audit-log-service';
import { AppPages, navigate } from 'app-navigator';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { CgNotes } from 'react-icons/cg';
import {
  FaBullhorn,
  FaLock,
  FaCrown,
  FaUserShield,
  FaUserNinja,
} from 'react-icons/fa';
import { FaListCheck } from 'react-icons/fa6';
import { MdAdd as AddIcon, MdLogout as LogoutIcon } from 'react-icons/md';
import { openCreateOrgModal } from '../../../organizations/api/organizations-api';
import { auditUserLogin } from '../../actions/app-login-actions';
import { useSnapshot } from 'valtio';
import { UserStore } from '../../../members/stores/user-roles-store';
import { Roles } from '@oxappsec/ox-unified-permissions';

const UserMenu: FC<React.PropsWithChildren<unknown>> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user, logout } = useAuth0();
  const { roles } = useSnapshot(UserStore);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = React.useCallback(async () => {
    if (user) {
      await auditUserLogin(user, LogName.Logout);
    }

    logout({
      returnTo: window.location.origin,
    });
  }, [logout, user]);

  const navigateToReleaseNotes = useCallback(() => {
    navigate(AppPages.ReleaseNotes);
  }, []);

  const navigateToWhatsNew = useCallback(() => {
    navigate(AppPages.WhatsNew);
  }, []);

  const BadgeContent = React.useMemo(() => {
    if (!roles) {
      return null;
    }
    const role = roles[0];
    switch (role) {
      case Roles.Admin:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaCrown
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      case Roles['Read Only']:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaLock
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      case Roles['Dev Manager / Security Champion']:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaUserShield
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      case Roles.Developer:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaUserNinja
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      case Roles['Policy Manager']:
        return (
          <SmallAvatar alt={roles?.[0] || ''}>
            <FaListCheck
              color='black'
              style={{ backgroundColor: 'yellow', fontSize: '10px' }}
            />
          </SmallAvatar>
        );
      default:
        return null;
    }
  }, [roles]);

  return (
    <>
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={BadgeContent}
        sx={{ marginInlineEnd: 1 }}>
        <IconButton
          sx={{
            padding: 0,
            '& > div': {
              width: 30,
              height: 30,
              fontSize: '0.75rem',
            },
          }}
          size='small'
          id='basic-button'
          aria-controls='basic-menu'
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <Avatar alt={user?.name} src={user?.picture} />
        </IconButton>
      </Badge>

      <Menu
        elevation={5}
        id='user-profile-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-profile-button',
        }}
        onClick={handleClose}>
        <MenuItem onClick={openCreateOrgModal}>
          <ListItemIcon>
            <AddIcon color='action' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Create Organization</ListItemText>
        </MenuItem>
        <Divider component='li' />

        <MenuItem onClick={navigateToWhatsNew}>
          <ListItemIcon>
            <FaBullhorn />
          </ListItemIcon>
          <ListItemText>What's New</ListItemText>
        </MenuItem>

        <MenuItem onClick={navigateToReleaseNotes}>
          <ListItemIcon>
            <CgNotes />
          </ListItemIcon>
          <ListItemText>Release Notes</ListItemText>
        </MenuItem>

        <Divider component='li' />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon color='action' fontSize='small' />
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const SmallAvatar = styled(Avatar)(() => ({
  width: 18,
  height: 18,
  border: `3px solid yellow`,
  backgroundColor: 'yellow',
}));

export default UserMenu;
