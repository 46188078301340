import { createStore } from 'store-utils';
import {
  ICategory,
  ICategoryInfo,
  IProfile,
} from '../types/policy-profile-types';
interface ProfilesState {
  categories: ICategory[];
  activeProfileId: string;
  profiles: IProfile[];
  policiesPerCategoriesInfo: ICategoryInfo[];
  profileInContext?: IProfile;
  enabledPolicies: number;
  totalPolicies: number;
  newProfile: {
    payload: Pick<IProfile, 'active' | 'profileName'>;
    responseErorMessage?: string;
  };
}

export const initNewProfile = {
  payload: { profileName: '', active: true },
  responseErorMessage: '',
};

const ProfileStore = createStore<ProfilesState>(
  {
    categories: [],
    profiles: [],
    profileInContext: undefined,
    newProfile: initNewProfile,
    activeProfileId: '',
    policiesPerCategoriesInfo: [],
    enabledPolicies: 0,
    totalPolicies: 0,
  },
  'Profiles Store',
);

export default ProfileStore;
