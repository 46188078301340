import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import IssueApiInventories from '../../../../../containers/IssueApiInventoriesContainer';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';

const IssueApiInventoriesTabContainer: FC<
  React.PropsWithChildren<IssueDrawerTabProps>
> = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);

  return (
    <>{selectedIssue && <IssueApiInventories issueId={selectedIssue.id} />}</>
  );
};

export default IssueApiInventoriesTabContainer;
