import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import NodeTooltip from '../NodeTooltip';
import { Nullable } from 'ox-common-types';

const Popularity: FC<React.PropsWithChildren<PopularityProps>> = ({
  notPopular,
  stars,
  forks,
}) => {
  const theme = useTheme();
  const colors = theme.palette.categoryRiskColor;
  return (
    <NodeTooltip title={`Popularity Check`}>
      {notPopular === true && (
        <Typography variant='caption' color={colors.high}>
          Not Popular Library
        </Typography>
      )}
      {notPopular === false && (
        <Typography variant='caption' color={colors.passed}>
          Popular Library
        </Typography>
      )}
      {notPopular === null && (
        <Typography variant='caption' color={colors.info}>
          No Info about Popularity
        </Typography>
      )}
      {stars && <Typography variant='caption'>Stars: {stars}</Typography>}
      {forks && <Typography variant='caption'>Forks: {forks}</Typography>}
    </NodeTooltip>
  );
};

export interface PopularityProps {
  notPopular: Nullable<boolean>;
  stars: number;
  forks: number;
}

export { Popularity };
