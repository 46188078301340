import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import mutation from './delete-app-owner.gql';
import { logger } from 'logging-utils';

const deleteAppOwner = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: DeleteAppOwnerInput): Promise<boolean> => {
      try {
        const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
        const results = await client.mutate<DeleteAppOwnerResponse>({
          mutation,
          fetchPolicy,
          variables: { ...input },
        });

        return results.data?.deleteAppOwnerByEmail ?? false;
      } catch (error) {
        logger.error('Failed to delete app owner', error);
        return false;
      }
    },
  };
};

interface DeleteAppOwnerResponse {
  deleteAppOwnerByEmail: boolean;
}

export interface DeleteAppOwnerInput {
  email: string;
}

export default deleteAppOwner;
