import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  Nullable,
  ServiceExecute,
  TicketProvider,
  scanTypes,
} from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';
import mutation from './unlink-jira-tickets-from-issue.gql';
import { UnlinkJiraTicketsFromIssueInput } from '../../types/jira-ticket-types';
import { IssuePages } from '../../../../../../apps/web/src/issues/common/types/issues-types';

const unlinkJiraTickets = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  { input: UnlinkJiraTicketsFromIssueInput; issuesPage: IssuePages },
  Nullable<number>
> => {
  return {
    execute: async ({ input, issuesPage }): Promise<Nullable<number>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: {
            input,
            provider: TicketProvider.Jira,
            scanType:
              issuesPage === IssuePages.PipelineIssues
                ? scanTypes.PipelineScan
                : scanTypes.FullScan,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }
        TelemetryManager.setTelemetryAction(TelemetryActions.UnlinkJiraTickets);
        return results.data.unlinkJiraTicketFromIssue;
      } catch (error) {
        logger.error('Failed to unlink jira tickets from issue', error);
        return null;
      }
    },
  };
};

interface MutationResponse {
  unlinkJiraTicketFromIssue: number;
}

export default unlinkJiraTickets;
