import { Edge, MarkerType, Node } from 'reactflow';
import { AttackPathType, NodeRaw, RawEdge } from '../attack-path-types';
import useIssueGraphStore from '../stores/issue-graph-store';
import { layout } from './layout-utils';
import { handleExternalLinkClick } from './utils';
import { toggleBranch } from '../actions/issue-graph-actions';
import useCommonGraphStore from '../stores/common-graph-store';
import useAttackPathGraphStore from '../stores/attack-path-store';
import { layoutAttackPath } from './attack-path-utils';

export const formatNodes = (nodes: NodeRaw[], edges: Edge[]) => {
  const parentMap = edges.reduce((acc, item) => {
    acc[item.source] = (acc[item.source] || 0) + 1;
    return acc;
  }, {});

  const grouped = nodes.reduce((acc, item) => {
    if (item.metaData.groupId) {
      acc[item.metaData.groupId] = (acc[item.metaData.groupId] || 0) + 1;
    }
    return acc;
  }, {});

  return nodes.map(node => ({
    id: node.id.toString(),
    type: node.metaData.nodeType || 'custom',
    zIndex: node.metaData.groupId ? 1 : 0,
    data: {
      grouped: grouped[node.id],
      topbarText:
        node.type === AttackPathType.Api && !node.metaData.total
          ? 'Call Stack'
          : '',
      childrenCount:
        node.type === AttackPathType.Internet
          ? 0
          : node.metaData.groupId
          ? 0
          : node.metaData.total || parentMap[node.id.toString()],
      type: node.type,
      metadata: node.metaData,
    },
  }));
};

export const formatEdges = (edges: RawEdge[]): Edge[] => {
  return edges.map(edge => ({
    id: edge.id.toString(),
    source: edge.source.toString(),
    target: edge.target.toString(),
    zIndex: edge.type ? 0 : 1,
    markerEnd: edge.type === 'Arrow' ? { type: MarkerType.Arrow } : undefined,
    type: edge.type || 'floating',
    data: {
      label: edge.metaData?.text,
      position: edge.metaData?.position,
    },
  }));
};

const ExpandableParents = [
  AttackPathType.Compliance,
  AttackPathType.User,
  AttackPathType.Artifact,
  AttackPathType.Tag,
  AttackPathType.Webhook,
  AttackPathType.Cve,
  AttackPathType.Cwe,
  AttackPathType.Oscar,
  AttackPathType.SeverityFactorCategory,
  AttackPathType.Commit,
  AttackPathType.Service,
  AttackPathType.Artifact,
  AttackPathType.Slack,
  AttackPathType.JiraTicket,
  AttackPathType.Pr,
  AttackPathType.Repos,
];

const mark = (nodeId, edges, nodeMarkers, edgeMarkers) => {
  nodeMarkers.add(nodeId);
  edges.forEach(edge => {
    if (edge.source === nodeId || edge.target === nodeId) {
      edgeMarkers.add(edge);
      const nextNodeId = edge.target;
      if (!nodeMarkers.has(nextNodeId)) {
        mark(nextNodeId, edges, nodeMarkers, edgeMarkers);
      }
    }
  });
};

export const getCollapsedNodes = (
  nodes,
  edges,
  showMoreMap,
  collapsedBranches,
) => {
  const tempNodes = [...nodes];
  const tempEdges = [...edges];
  let nodesToRemove = new Set(); // Nodes marked for removal
  let edgesToRemove = new Set(); // Edges marked for removal
  const maxWithoutExpand = 2;
  const maxWithExpand = 6;
  nodes.forEach(node => {
    if (ExpandableParents.includes(node.data.type)) {
      let childrenEdges = edges.filter(edge => edge.source === node.id);
      if (node.data.childrenCount > maxWithoutExpand + 1) {
        childrenEdges
          .slice(showMoreMap[node.id] ? maxWithExpand : maxWithoutExpand)
          .forEach(edge =>
            mark(edge.target, edges, nodesToRemove, edgesToRemove),
          );

        if (
          (showMoreMap[node.id] && node.data.childrenCount <= 6) ||
          collapsedBranches[node.id]
        ) {
          return;
        }
        const expandNode = {
          id: `${node.id}_expand`,
          type: 'Expand',
          data: {
            parentId: node.id,
            type: 'Expand',
            parentType: node.data.type,
            metadata: {
              order: 1000,
              total: node.data.metadata.total,
              count: node.data.childrenCount - maxWithoutExpand,
              parentId: node.id,
            },
          },
        };
        tempNodes.push(expandNode);
        tempEdges.push({
          id: `${node.id}_${expandNode.id}`,
          source: node.id,
          target: expandNode.id,
          type: 'Default',
        });
      }
    }
  });

  // Filter out nodes and edges marked for removal
  let newNodes = tempNodes.filter(node => !nodesToRemove.has(node.id));
  let newEdges = tempEdges.filter(edge => !edgesToRemove.has(edge));

  return { nodes: newNodes, edges: newEdges };
};

export const onNodeExpandClick = async (data, aggType) => {
  const { nodes, edges, originalNodes, originalEdges } =
    useIssueGraphStore.getState();

  const { showMoreMap } = useCommonGraphStore.getState();
  const currentNodesMap = new Set(nodes.map(n => n.id));

  let nodesToAdd = new Set(); // Nodes marked for removal
  let edgesToAdd = new Set(); // Edges marked for removal

  const parentId = data.parentId;
  const node = nodes.find(n => n.id === parentId);
  if (!showMoreMap[parentId]) {
    mark(parentId, originalEdges, nodesToAdd, edgesToAdd);
    const newNodes = originalNodes.filter(
      n => nodesToAdd.has(n.id) && !currentNodesMap.has(n.id),
    );
    const newEdges = (Array.from(edgesToAdd) as Edge[]).filter(
      e => !currentNodesMap.has(e.source) || !currentNodesMap.has(e.target),
    );

    const nodesAfterExpand = [...newNodes, ...nodes];
    const edgesAfterExpand = [...newEdges, ...edges];
    const newNodesWithPosition = layout(
      nodesAfterExpand.filter(n =>
        n.type === AttackPathType.Expand &&
        n.data.parentId === parentId &&
        node?.data?.childrenCount <= 6
          ? false
          : true,
      ),
      edgesAfterExpand,
    );

    useIssueGraphStore.setState({
      nodes: newNodesWithPosition as Node[],
      edges: edgesAfterExpand,
    });
    useCommonGraphStore.setState({
      showMoreMap: { ...showMoreMap, [parentId]: true },
    });
  } else {
    handleExternalLinkClick(data.parentType, aggType);
  }
};

export const collapseBranch = (nodeId: string, type) => {
  const nodesToRemove = new Set();
  const edgesToRemove = new Set();
  const store = type === 'issue' ? useIssueGraphStore : useAttackPathGraphStore;
  const { nodes, edges } = store.getState();
  const nodesMap = nodes.reduce((acc, node) => {
    acc[node.id] = node;
    return acc;
  }, {});

  mark(
    nodeId,
    edges.filter(
      e =>
        e.type === 'Default' &&
        nodesMap[e.source] &&
        nodesMap[e.target] &&
        !(
          nodesMap[e.source].data.type === AttackPathType.Root &&
          nodesMap[e.target].data.type === AttackPathType.App
        ),
    ),
    nodesToRemove,
    edgesToRemove,
  );

  const newNodes = nodes.filter(
    n => n.id === nodeId || !nodesToRemove.has(n.id),
  );
  const newEdges = edges.filter(
    edge => edge.target === nodeId || !edgesToRemove.has(edge),
  );

  if (type === 'issue') {
    useIssueGraphStore.setState({ nodes: newNodes, edges: newEdges });
  } else {
    useAttackPathGraphStore.setState({ nodes: newNodes, edges: newEdges });
  }

  return { nodes: newNodes, edges: newEdges };
};

export const expandBranch = (nodeId: string, type) => {
  const store = type === 'issue' ? useIssueGraphStore : useAttackPathGraphStore;
  const { nodes, edges, originalNodes, originalEdges } = store.getState();

  const { collapsedBranches, showMoreMap } = useCommonGraphStore.getState();
  const nodesWithoutExpand = nodes.filter(
    n => n.type !== AttackPathType.Expand,
  );
  const currentNodesSet = new Set(nodesWithoutExpand.map(n => n.id));
  const nodesMap = originalNodes.reduce((acc, n) => {
    acc[n.id] = n;
    return acc;
  }, {});
  let nodesToAdd = new Set();
  let edgesToAdd = new Set();

  mark(
    nodeId,
    originalEdges.filter(
      e =>
        nodesMap[e.source] &&
        nodesMap[e.target] &&
        !(
          nodesMap[e.source].data.type === AttackPathType.Root &&
          nodesMap[e.target].data.type === AttackPathType.App
        ),
    ),
    nodesToAdd,
    edgesToAdd,
  );
  const newNodes = originalNodes.filter(
    n => nodesToAdd.has(n.id) && !currentNodesSet.has(n.id),
  );

  newNodes.forEach(n => (collapsedBranches[n.id] = false));
  const newEdges = (Array.from(edgesToAdd) as Edge[]).filter(
    e => !currentNodesSet.has(e.source) || !currentNodesSet.has(e.target),
  );

  const nodesAfterExpand = [...newNodes, ...nodesWithoutExpand];
  const edgesAfterExpand = [
    ...newEdges,

    ...edges.filter(e => currentNodesSet.has(e.target)),
  ];

  if (type === 'issue') {
    const { nodes: ns, edges: es } = getCollapsedNodes(
      nodesAfterExpand,
      edgesAfterExpand,
      showMoreMap,
      collapsedBranches,
    );
    const nodesWithPositions = layout(ns, es);
    useIssueGraphStore.setState({
      nodes: nodesWithPositions as Node[],
      edges: es,
    });
  } else {
    const { nodes, edges } = layoutAttackPath(originalNodes, edgesAfterExpand);
    useAttackPathGraphStore.setState({
      nodes,
      edges,
    });
  }
};

export const collapseBranches = (nodes: Node[]) => {
  nodes.forEach(n => {
    if (n.data.metadata.collapse) {
      toggleBranch(n.id, n.data.metadata.graphPart);
    }
  });
};
