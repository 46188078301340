import { FC } from 'react';

import { ListItem, Typography, useTheme } from '@mui/material';
import NodeTooltip from '../NodeTooltip';
import { CustomExtLink } from 'ox-react-components';

const K8sApplicationItem: FC<React.PropsWithChildren<MultipleImagesProps>> = ({
  metadata,
  header,
}) => {
  const theme = useTheme();
  const { imageIds } = metadata;

  return (
    <NodeTooltip title={'Workload Images'}>
      {(imageIds as Record<string, string>[])?.map((image, i) => (
        <>
          <ListItem
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'flex-start',
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}>
            <Typography variant='caption' color='text.secondary'>
              Name
            </Typography>

            <Typography variant='caption' style={{ gap: 4 }}>
              {metadata.name as string}
              {metadata.link && (
                <>
                  {' '}
                  <span style={{ marginLeft: 4 }} />
                  <CustomExtLink value='' href={metadata.link as string} />
                </>
              )}
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'flex-start',
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}>
            <Typography variant='caption' color='text.secondary'>
              Type
            </Typography>

            <Typography variant='caption' style={{ gap: 4 }}>
              {metadata.type as string}
            </Typography>
          </ListItem>
          {metadata.namespace && (
            <ListItem
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'flex-start',
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}>
              <Typography variant='caption' color='text.secondary'>
                Namespace
              </Typography>

              <Typography variant='caption' style={{ gap: 4 }}>
                {metadata.namespace as string}
              </Typography>
            </ListItem>
          )}
          <ListItem
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'flex-start',
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}>
            <Typography variant='caption' color='text.secondary'>
              Severity Factors
            </Typography>

            <Typography variant='caption' style={{ gap: 4 }}>
              {metadata.severityFactors as string}
            </Typography>
          </ListItem>
          {items.map(
            (item, index) =>
              image[item.key] && (
                <ListItem
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'flex-start',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}>
                  <Typography variant='caption' color='text.secondary'>
                    {item.label}:&nbsp;
                  </Typography>

                  <Typography variant='caption' style={{ gap: 4 }}>
                    {image[item.key]}
                  </Typography>
                </ListItem>
              ),
          )}
        </>
      ))}
    </NodeTooltip>
  );
};

const items = [
  { key: 'imageId', label: 'Image ID' },
  { key: 'hash', label: 'Hash' },
  { key: 'tag', label: 'Tag' },
];
export interface MultipleImagesProps {
  metadata: Record<string, string | Record<string, string>[]>;
  header: string;
}

export default K8sApplicationItem;
