import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';
import { ConnectionType } from '../types/connection-types';

const SelectedConnectionStore = createStore<SelectedConnectionStoreState>(
  {
    isLoadingAdd: false,
    isLoadingRemove: false,
    selectedConnectionType: null,
    loggedInUserStrategy: null,
  },
  'Selected Connection Store',
);

interface SelectedConnectionStoreState {
  isLoadingAdd: boolean;
  isLoadingRemove: boolean;
  selectedConnectionType: Nullable<ConnectionType>;
  loggedInUserStrategy: Nullable<string>;
}

export default SelectedConnectionStore;
