import { AsyncLoadingState } from 'async-utils';
import { FeedbackStore, initialStoreState } from './feedback-store';

export const openFeedback = () => {
  FeedbackStore.open = true;
};

export const closeFeedback = () => {
  FeedbackStore.open = false;
};

export const setCategory = (category: string) => {
  FeedbackStore.category = category;
};

export const setText = (text: string) => {
  FeedbackStore.text = text;
};

export const setSendingStatus = (
  status: AsyncLoadingState['status'],
  err?: AsyncLoadingState['err'],
) => {
  FeedbackStore.sending.status = status;
  FeedbackStore.sending.err = err || null;
};

export const clearStore = () => {
  FeedbackStore.category = initialStoreState['category'];
  FeedbackStore.open = initialStoreState['open'];
  FeedbackStore.sending = initialStoreState['sending'];
  FeedbackStore.text = initialStoreState['text'];
};
