import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import {
  FetchFileResponse,
  FetchReadyFileInput,
} from '../download-utils-types';
import query from './fetch-ready-file.graphql';

const fetchReadyFile = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      input: FetchReadyFileInput,
    ): Promise<Nullable<FetchFileResponse['fetchReadyFile']>> => {
      try {
        const results = await client.query<FetchFileResponse>({
          query,
          variables: { ...input },
          fetchPolicy: 'no-cache',
        });

        if (
          !results.data ||
          !results.data.fetchReadyFile ||
          !results.data.fetchReadyFile.hasOwnProperty('isFileReady')
        ) {
          return null;
        }

        return results.data.fetchReadyFile;
      } catch (error) {
        logger.error(
          `failed to fetch PBOM for requestId: ${input.requestId} error:`,
          error,
        );
        return null;
      }
    },
  };
};

export default fetchReadyFile;
