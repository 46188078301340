import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  AddCredentialsInput,
  CredentialsConfigureResponse,
} from '../../connectors-types';
import mutation from './add-credentials.gql';
import mutationV2 from './add-credentials-v2.gql';

const addCredentials = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      addCredentialsInput: AddCredentialsInput,
      isV2: boolean = false,
    ): Promise<CredentialsConfigureResponse> => {
      try {
        Object.keys(addCredentialsInput.credentialsInput).forEach(key => {
          if (typeof addCredentialsInput.credentialsInput[key] === 'string') {
            addCredentialsInput.credentialsInput[key] =
              addCredentialsInput.credentialsInput[key]?.trim();
          }
        });
      } catch (error) {
        logger.error('Failed to trim addCredentials input:', error);
      }
      const results = await client.mutate<AddCredentialsResponse>({
        mutation: isV2 ? mutationV2 : mutation,
        variables: { addCredentialsInput },
      });
      const resData = isV2
        ? results?.data?.addCredentialsV2
        : results?.data?.addCredentials;
      if (resData) {
        return resData.connector;
      }

      throw new Error('Failed to add credentials');
    },
  };
};

interface AddCredentialsResponse {
  addCredentials: { connector: CredentialsConfigureResponse };
  addCredentialsV2?: { connector: CredentialsConfigureResponse };
}

export default addCredentials;
