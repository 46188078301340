import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { SettingsInput, SettingsRes } from '../../types/settings-types';
import query from './get-single-settings.gql';

const getSingleSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getSettingsInput: SettingsInput) => {
      try {
        const results = await client.query<{
          getSingleSettings: SettingsRes;
        }>({
          query,
          variables: { id: getSettingsInput.id },
        });
        return results.data.getSingleSettings.settings;
      } catch (error) {
        logger.error('Failed to get settings', error);
        return null;
      }
    },
  };
};

export default getSingleSettings;
