import { Nullable } from 'ox-common-types';
import { FunctionComponent, ReactElement } from 'react';

export enum AttackPathType {
  ExpandAttackPath = 'ExpandAttackPath',
  ApplicationLoadBalancer = 'ApplicationLoadBalancer',
  CloudLoadBalancer = 'CloudLoadBalancer',
  CloudNetworkLoadBalancer = 'CloudNetworkLoadBalancer',
  Image = 'Image',
  ImageId = 'ImageId',
  ImageArtifact = 'ImageArtifact',

  Cloud = 'Cloud',
  ApiCount = 'ApiCount',
  ApiSource = 'ApiSource',
  CloudRegion = 'CloudRegion',
  CloudLink = 'CloudLink',
  CloudDns = 'CloudDns',
  K8sGroup = 'K8sGroup',
  K8s = 'K8s',
  Internet = 'Internet',
  K8sLink = 'K8sLink',
  K8sImageId = 'K8sImageId',
  K8sApplication = 'K8sApplication',
  K8sIngress = 'K8sIngress',
  K8sLoadBalancer = 'K8sLoadBalancer',
  ApiFunction = 'ApiFunction',
  ApiLink = 'ApiLink',
  ApiFramework = 'ApiFramework',
  ApiFileName = 'ApiFileName',
  Api = 'Api',
  AppLanguage = 'AppLanguage',
  AppLink = 'AppLink',
  Expand = 'Expand',
  Root = 'Root',
  IssueOwner = 'IssueOwner',
  Category = 'Category',
  Language = 'Language',
  Oscar = 'Oscar',
  OscarItem = 'OscarItem',
  Cwe = 'Cwe',
  CweItem = 'CweItem',
  Compliance = 'Compliance',
  ComplianceItem = 'ComplianceItem',
  SeverityFactor = 'SeverityFactor',
  SeverityFactorChange = 'SeverityFactorChange',
  SeverityFactorCategory = 'SeverityFactorCategory',
  Commit = 'Commit',
  CommitItem = 'CommitItem',
  CommitItemCommitBy = 'CommitItemCommitBy',
  User = 'User',
  UserItem = 'UserItem',
  Repos = 'Repos',
  ReposItem = 'ReposItem',
  Service = 'Service',
  ServiceItem = 'ServiceItem',
  Webhook = 'Webhook',
  SBOMRepos = 'SBOMRepos',
  SBOMReposItem = 'SBOMReposItem',
  Artifact = 'Artifact',
  ArtifactItem = 'ArtifactItem',
  ArtifactIntegrity = 'ArtifactIntegrity',
  ArtifactIntegrityItem = 'ArtifactIntegrityItem',
  WebhookItem = 'WebhookItem',
  CommitItemCommitDate = 'CommitItemCommitDate',
  CommitItemCommitReviewedBy = 'CommitItemCommitReviewedBy',
  CommitItemFile = 'CommitItemFile',
  Library = 'Library',
  Popularity = 'Popularity',
  Maintenance = 'Maintenance',
  License = 'License',
  Update = 'Update',
  Action = 'Action',
  Slack = 'Slack',
  SlackItem = 'SlackItem',
  JiraTicket = 'JiraTicket',
  JiraTicketItem = 'JiraTicketItem',
  Pr = 'Pr',
  PrItem = 'PrItem',
  App = 'App',
  BusinessPriority = 'BusinessPriority',
  Link = 'Link',
  Cve = 'Cve',
  CveItem = 'CveItem',
  Tag = 'Tag',
  TagItem = 'TagItem',
  Detection = 'Detection',
  Intelligence = 'Intelligence',
  FirstSeen = 'FirstSeen',
  Saas = 'Saas',
}

export enum ChangeCategory {}

export interface NodeContent {
  Icon?: Nullable<FunctionComponent<React.SVGProps<SVGSVGElement>>>;
  backgroundColor?: string;
  iconColor?: string;
  onClick?: () => void;
  borderColor?: Nullable<string>;
  badge?: Nullable<ReactElement>;
  withPulse?: boolean;
  withBadgePulse?: boolean;
  iconScale: number;
  size: number;
}

export interface NodeRaw {
  id: string;
  metaData: {
    order: number;
    nodeType: string;
    total: number;
    groupId: number;
    graphPart: string;
  };
  type: AttackPathType;
}

export interface Edge {
  id: string;
  target: string;
  source: string;
  type: string;
}
export interface AttackPathGraph {
  nodes: NodeRaw[];
  edges: RawEdge[];
}

export enum StandardCategory {
  NIST = 'NIST',
  SOC2 = 'SOC2',
  ISO = 'ISO',
  PCI = 'PCI',
  CIS = 'CIS',
}

export interface ExposedType {
  id: string;
  isOnline: boolean;
  text: string;
}

export enum ClusterExposedType {
  ExposedWorkload = 'ExposedWorkload',
  ExposedCluster = 'ExposedCluster',
  ExposedIssue = 'ExposedIssue',
  ExposedSystem = 'ExposedSystem',
  OfflineCluster = 'Offline',
  OfflineSystem = 'IssueIsNotInternet',
}

export const exposedTypesMap = new Map();

exposedTypesMap.set(ClusterExposedType.ExposedWorkload, {
  isOnline: true,
  text: 'Internet Exposed Workload',
});
exposedTypesMap.set(ClusterExposedType.ExposedCluster, {
  isOnline: true,
  text: 'Internet Exposed Cluster',
});
exposedTypesMap.set(ClusterExposedType.ExposedIssue, {
  isOnline: true,
  text: 'Internet Exposed Issue',
});
exposedTypesMap.set(ClusterExposedType.ExposedSystem, {
  isOnline: true,
  text: 'Internet Exposed System',
});
exposedTypesMap.set(ClusterExposedType.OfflineCluster, {
  isOnline: false,
  text: 'K8s Cluster Offline',
});
exposedTypesMap.set(ClusterExposedType.OfflineSystem, {
  isOnline: false,
  text: 'Offline System',
});

export interface RawEdge {
  id: string;
  type: string;
  source: string;
  target: string;
  metaData: {
    text: ClusterExposedType;
    position: EdgeItemPosition;
  };
}

export enum EdgeItemPosition {
  Start = 'Start',
  End = 'End',
}

export enum AttackPathMetadataCloudType {
  Azure = 'azure',
  Aws = 'aws',
}
