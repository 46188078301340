import { AttackPathType } from '../attack-path-types';

const childDirectionConfig = {
  [AttackPathType.Oscar]: 'Bottom',
  [AttackPathType.OscarItem]: 'Bottom',
  [AttackPathType.Action]: 'Bottom',
  [AttackPathType.JiraTicket]: 'Bottom',
  [AttackPathType.JiraTicketItem]: 'Bottom',
  [AttackPathType.Slack]: 'Bottom',
  [AttackPathType.SlackItem]: 'Bottom',
  [AttackPathType.Compliance]: 'Bottom',
  [AttackPathType.ComplianceItem]: 'Bottom',
  [AttackPathType.Cve]: 'Bottom',
  [AttackPathType.CveItem]: 'Bottom',
  [AttackPathType.Cwe]: 'Bottom',
  [AttackPathType.CweItem]: 'Bottom',
  [AttackPathType.Pr]: 'Bottom',
  [AttackPathType.Library]: 'Bottom',
  [AttackPathType.Intelligence]: 'Bottom',
  [AttackPathType.SeverityFactorCategory]: 'Top',
  [AttackPathType.SeverityFactorChange]: 'Top',
  [AttackPathType.App]: 'Top',
  [AttackPathType.SeverityFactor]: 'Top',
  [AttackPathType.Tag]: 'Top',
  [AttackPathType.User]: 'Left',
  [AttackPathType.Service]: 'Left',
  [AttackPathType.Webhook]: 'Left',
  [AttackPathType.Artifact]: 'Left',
  [AttackPathType.Commit]: 'Left',
  [AttackPathType.CommitItem]: 'Left',
  [AttackPathType.Detection]: 'Left',
  [AttackPathType.Repos]: 'Left',
  [AttackPathType.SBOMRepos]: 'Left',
};

export const getChildDirection = type => childDirectionConfig[type];
