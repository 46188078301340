import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import query from './get-single-api-inventory.gql';
import { ApiInventoryItem } from '../../types';

const getSingleApiInventory = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getSingleApiInventoryInput: GetApiInventoryInput) => {
      try {
        const results = await client.query<{
          getSingleApiInventory: ApiInventoryItem;
        }>({
          query,
          variables: { getSingleApiInventoryInput },
          fetchPolicy: ApolloClientCache.NetworkOnly,
        });

        return results.data.getSingleApiInventory;
      } catch (error) {
        logger.error('Failed to fetch api inventories', error);
        return null;
      }
    },
  };
};

export interface GetApiInventoryInput {
  apiInventoryId: string;
}

export default getSingleApiInventory;
