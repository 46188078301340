import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import NodeTooltip from '../NodeTooltip';
import { getSeverityChangeIcon } from '../../utils/utils';

const SeverityFactorChange: FC<
  React.PropsWithChildren<SeverityFactorChangeProps>
> = ({ changeNumber, reason, shortName }) => {
  const Icon = getSeverityChangeIcon(changeNumber);
  return (
    <NodeTooltip
      title={
        <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
          {shortName} <Icon width={14} height={14} />{' '}
        </Box>
      }>
      <Typography variant='caption'>{reason}</Typography>
    </NodeTooltip>
  );
};

export interface SeverityFactorChangeProps {
  changeNumber: number;
  reason: string;
  shortName: string;
}

export { SeverityFactorChange };
