import { Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

export const PaginationDots: FC<PaginationDotsProps> = ({
  activeIndex,
  dots,
  max = 10,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.paginationIndicator}>
      {Array.from(new Array(Math.min(max + 1, dots))).map((_, index) => (
        <span
          key={index}
          className={cx(classes.dot, {
            [classes.activeDot]:
              activeIndex === index || (activeIndex > max && index === max),
          })}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  paginationIndicator: {
    textAlign: 'center',
  },
  dot: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: theme.palette.grey['500'],
    marginInline: theme.spacing(1),
    transition: `background-color ${theme.transitions.duration.standard}ms ease-in-out`,
  },
  activeDot: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export interface PaginationDotsProps {
  activeIndex: number;
  dots: number;
  max?: number;
}
