import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import IssueCardFixApplied from '../../../../IssueCard/IssueCardFixApplied';

const FixAppliedTabContainer: FC = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);

  return (
    <>
      {selectedIssue?.autoFix?.isFixApplied && (
        <IssueCardFixApplied autoFix={selectedIssue.autoFix} />
      )}
    </>
  );
};

export default FixAppliedTabContainer;
