import {
  Box,
  Divider,
  IconButton,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import { FeedbackStore, clearStore, closeFeedback } from 'feedback-logic';
import { FC } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import { useSnapshot } from 'valtio';
import { FeedbackForm } from './FeedbackForm';

export const FeedbackPopover: FC<
  React.PropsWithChildren<FeedbackPopoverProps>
> = ({ anchorElement }) => {
  const { open } = useSnapshot(FeedbackStore);

  return (
    <Popover
      open={open}
      anchorEl={anchorElement}
      TransitionProps={{ onExited: clearStore }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Toolbar sx={{ paddingInline: 4 }} color='primary'>
        <Typography variant='h1' color='primary' flexGrow={1} fontWeight={400}>
          Feedback
        </Typography>
        <IconButton onClick={closeFeedback} size='small'>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Divider flexItem />
      <Box height='100vh' maxHeight={420} width='100vw' maxWidth={380} p={4}>
        <FeedbackForm />
      </Box>
    </Popover>
  );
};

interface FeedbackPopoverProps {
  anchorElement?: HTMLElement;
}
