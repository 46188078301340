import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import mutation from './update-request-access.gql';
import {
  RequestedAccessStatus,
  UpdateRequestAccessInput,
} from '../../types/members-types';
import { logger } from 'logging-utils';

const updateRequestAccess = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: UpdateRequestAccessInput): Promise<boolean> => {
      try {
        const results = await client.mutate<{
          updateRequestAccess: UpdateRequestAccessResponse;
        }>({
          mutation,
          variables: { input },
        });

        if (!results || !results.data) {
          return false;
        }

        return !!results.data.updateRequestAccess;
      } catch (error) {
        logger.error(`Failed to update request access ${error}`);
        return false;
      }
    },
  };
};

export interface UpdateRequestAccessResponse {
  updateRequestAccess: {
    requestedAccessInfo: RequestedAccessInfo;
  };
}

export interface RequestedAccessInfo {
  email: string;
  status: RequestedAccessStatus;
  requestedAt: Date;
  updatedAt: Date;
}

export default updateRequestAccess;
