import { useSnapshot, snapshot } from 'valtio';
import ConnectorsStore from '../stores/connectors-store';

export {
  findConnectorById,
  loadConnectorResources,
  loadConnectorsByFamily,
  monitorConnectorResources,
} from '../actions/connector-actions';
export { redirectToIDP } from '../actions/connector-configure-form-actions';
export {
  configureIDP,
  validateSingleConnector,
} from '../actions/credentials-actions';
export { default as connectorService } from '../services';
export { useIsLoadingConnectors } from '../store-actions/connectors-store-actions';

export const useFindConnectorById = (connectorId: string) => {
  const { connectors } = useSnapshot(ConnectorsStore);
  return connectors.find(connector => connector.id === connectorId);
};

export const getConnectorsByFamily = () => {
  const { connectorsByFamily } = snapshot(ConnectorsStore);
  return connectorsByFamily;
};
