import { createStore } from 'store-utils';

export const ChatGPTStore = createStore<ChatGPTStoreState>(
  {
    questionsAndAnswersPerIssue: {},
    loading: false,
    isDisclaimerModalOpen: false,
    error: false,
    neverShowDisclaimer: false,
    moveToGptTab: false,
    disclaimersAcceptedByIssue: {},
    isSnippetModalOpen: false,
    doNotShowSnippetAgain: false,
  },
  'Chat GPT Store',
);

export interface ChatGPTStoreState {
  questionsAndAnswersPerIssue: {
    [issueId: string]: IssueChatGPT;
  };
  disclaimersAcceptedByIssue: {
    [issueId: string]: boolean;
  };
  loading: boolean;
  isDisclaimerModalOpen: boolean;
  disclaimerText?: string;
  error: boolean;
  neverShowDisclaimer: boolean;
  moveToGptTab: boolean;
  isSnippetModalOpen: boolean;
  doNotShowSnippetAgain: boolean;
}

export interface IssueChatGPT {
  question: string;
  answer: string;
}

export default ChatGPTStore;
