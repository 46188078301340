import { AsyncLoadingState } from 'async-utils';
import { Nullable } from 'ox-common-types';
import { SupplyChainItem } from '../../supply-chain/types/supplychain-types';
import DashboardStore from '../stores/dashboard-store';

export const setSupplyChainItems = (items: Nullable<SupplyChainItem[]>) => {
  DashboardStore.supplyChainItems = items;
};

export const setSupplyChainLoadingStatus = (
  status: AsyncLoadingState['status'],
) => {
  DashboardStore.supplyChainLoading.status = status;
};
