import { useState } from 'react';
import { IIdleTimerProps, useIdleTimer } from 'react-idle-timer';

export const useIdlePoller = ({
  options,
}: UseIdlePollerInput): UseIdlePollerOutput => {
  const [active, setActive] = useState(true);
  const defaultOptions = {
    startOnMount: true,
    crossTab: true,
    timeout: 1000 * 60,
  };

  const onActive = () => {
    // User is active
    setActive(true);
  };

  const onIdle = () => {
    // User is idle
    setActive(false);
  };

  useIdleTimer({ ...defaultOptions, onActive, onIdle, ...options });

  return {
    active,
  };
};

interface UseIdlePollerInput {
  options: IIdleTimerProps;
}
interface UseIdlePollerOutput {
  active: boolean;
}
