import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  FC,
  PropsWithChildren,
  RefObject,
  ReactNode,
} from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  ClickAwayListener,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { MentionsInput, Mention } from 'react-mentions';
import { Nullable } from 'ox-common-types';
import { MdOutlineClose } from 'react-icons/md';
import { UserOrChannel } from 'slack-logic';
export const ParameterAutocomplete = <T extends UserOrChannel>(
  props: PropsWithChildren<ParameterAutocompleteProps<T>>,
) => {
  const { classes } = useStyles();
  const {
    value,
    onChange,
    options,
    onInputChange,
    getOptionLabel,
    name,
    suggestions,
    getChangeValue,
    placeholder,
    shortPlaceholder,
    EndIcon,
  } = props;
  const mentionRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const [focused, setFocused] = useState(false);
  const inputStyles = {
    fontFamily: 'Roboto, sans-serif',
    width: '100%',

    padding: '6px 10px',
    fontSize: '14px',

    boxSizing: 'border-box',
    outline: 'none',

    input: {
      verticalAlign: 'center',
      padding: '8px  12px',
      border: `1px solid ${
        theme.palette.mode === 'light'
          ? focused
            ? theme.palette.primary.main
            : '#c2c2c2'
          : focused
          ? theme.palette.primary.main
          : '#464a55'
      }`,
      outline: 'none',
      borderWidth: focused ? '2px' : '1px',

      borderShadow:
        '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
      borderRadius: 4,
      fontSize: 14,
      height: 38,
    },
  };

  const [optionsOpen, setOptionsOpen] = useState(false);
  const closeOptions = useCallback(() => {
    setFocused(false);
    setOptionsOpen(false);
  }, []);

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const onBlur = useCallback(() => {
    // closeOptions();
    setFocused(false);
  }, []);
  const inputRef = useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = useState(false);

  const onChangeCall = useCallback(
    (
      event: { target: { value: string } },
      newValue: string,
      reason: string,
    ) => {
      onInputChange &&
        onInputChange(
          {
            ...event,
            target: { ...event.target, name },
          } as unknown as React.SyntheticEvent<Element, Event>,
          newValue,
          reason as AutocompleteInputChangeReason,
        );
      onChange(
        event as unknown as SyntheticEvent<Element, Event>,

        getChangeValue(newValue),
      );
      if (!mentionRef.current) {
        setOptionsOpen(true);
      }
    },
    [getChangeValue, name, onChange, onInputChange],
  );

  return (
    <div
      ref={inputRef}
      style={{ position: 'relative', zIndex: 100000 }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <MentionsInput
        onFocus={onFocus}
        onBlur={onBlur}
        singleLine
        customSuggestionsContainer={children => (
          <SuggestionWindow mentionsRef={mentionRef} onMount={closeOptions}>
            {children}
          </SuggestionWindow>
        )}
        className={classes.mentions}
        name='assignee'
        style={inputStyles}
        value={value || ''}
        onChange={onChangeCall}
        a11ySuggestionsListLabel={'Suggested mentions'}>
        <Mention
          trigger={'@'}
          data={suggestions}
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
            index,
            focused,
          ) => (
            <MenuItem
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
              }}>
              <Typography variant='caption' color='text.primary'>
                {highlightedDisplay}
              </Typography>
            </MenuItem>
          )}
        />
      </MentionsInput>
      <InputLabel
        sx={theme => ({
          px: focused ? 0.5 : 0,
          zIndex: 10001,
          color: focused
            ? theme.palette.primary.main
            : theme.palette.text.secondary,
          backgroundColor: theme.palette.background.paper,
        })}
        shrink={Boolean(value || focused)}
        htmlFor='custom-mentions-input'>
        {focused || value ? shortPlaceholder : placeholder}
      </InputLabel>

      {options?.length > 0 && optionsOpen ? (
        <ClickAwayListener onClickAway={closeOptions}>
          <Paper
            style={{
              zIndex: 10000,
              position: 'absolute',
              top: '100%',
              marginTop: 3,
              left: 0,
              border: `1px solid ${theme.palette.divider}`,
              opacity: 1,
              width: '100%',
              maxHeight: 200,
              overflowY: 'auto',
              backgroundColor: theme.palette.background.paper,
            }}>
            <List>
              {options.map((option, index) => (
                <MenuItem
                  style={{ width: '100%' }}
                  key={index}
                  onMouseDown={event => event.preventDefault()}
                  onClick={event => onChange(event, option)}>
                  <Typography variant='caption' color='text.primary'>
                    {getOptionLabel(option, classes)}
                  </Typography>
                </MenuItem>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      ) : null}

      {hovered && value && (
        <IconButton
          onMouseDown={event => event.preventDefault()}
          onClick={event => onChange(event, null)}
          style={{
            width: 22,
            height: 22,
            padding: 1,
            display: 'inline-flex',
            flexShrink: 0,
            position: 'absolute',
            right: 35, // adjust as needed
            top: 8,
          }}>
          <MdOutlineClose
            size={22}
            style={{
              fontSize: 22,
              width: 22,
              height: 22,
            }}
            color={
              theme.palette.mode === 'light' ? 'rgb(127, 127, 127)' : '#ffffff'
            }
          />
        </IconButton>
      )}
      <IconButton
        onMouseDown={event => event.preventDefault()}
        onClick={() => {
          setFocused(true);
          setOptionsOpen(true);
        }}
        style={{
          width: 24,
          height: 24,
          padding: 0.5,
          display: 'inline-flex',
          flexShrink: 0,
          position: 'absolute',
          right: '10px', // adjust as needed
          top: '20px',
          transform: 'translateY(-50%)',
        }}>
        {EndIcon && <EndIcon focused={focused} />}
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  mentions: {
    '&. mentions__suggestions__list': {
      backgroundColor: 'red',
    },
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  userImage: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  channelIconContainer: {
    marginRight: theme.spacing(1),
  },
  hashtagIcon: {
    fontSize: theme.spacing(2),
  },
  lockIcon: {
    fontSize: theme.spacing(2),
  },
}));

export const containsMention = (inputStr: string) => {
  const mentionRegex = /@\[[^\]]+\]\([^)]+\)/g;
  return mentionRegex.test(inputStr);
};

export const replaceMentionsWithNames = (inputStr: string) => {
  const mentionRegex = /@\[([^\]]+)\]\([^)]+\)/g;
  return inputStr.replace(mentionRegex, (match, name) => name);
};

export interface ParameterAutocompleteProps<T extends UserOrChannel> {
  onChange: (
    event: SyntheticEvent<Element, Event>,
    option: Nullable<T>,
    reason?: AutocompleteChangeReason,
  ) => void;
  value: string;
  onInputChange?: (
    event: SyntheticEvent,
    newValue: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
  options: T[];
  getOptionLabel: (
    option: T,
    classes: Record<string, string>,
  ) => string | ReactNode;
  name: string;
  suggestions: { id: string; display: string; description?: string }[];
  getChangeValue: (value: string) => T;
  shortPlaceholder?: string;
  placeholder?: string;
  EndIcon?: (props: { focused: boolean }) => ReactElement;
}

const SuggestionWindow: FC<PropsWithChildren<SuggestionWindowProps>> = ({
  children,
  onMount,
  mentionsRef,
}) => {
  const theme = useTheme();
  useEffect(() => {
    onMount();
  }, [onMount]);
  return (
    <Paper
      ref={mentionsRef}
      style={{
        marginTop: 7,
        maxHeight: 300,
        overflowY: 'auto',
        position: 'absolute',
        border: `1px solid ${theme.palette.divider}`,
        opacity: 1,
        zIndex: 10000,
        width: 'fit-content',
        backgroundColor: theme.palette.background.paper,
      }}>
      {children}
    </Paper>
  );
};

interface SuggestionWindowProps {
  mentionsRef: RefObject<HTMLDivElement>;
  onMount: () => void;
}
