import { Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export const SidebarLayout: FC<React.PropsWithChildren<SidebarLayoutProps>> = ({
  children,
  aside,
  controls,
  className,
}) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.controls}>{controls}</div>
      <div className={cx(classes.sidebarLayout, className)}>
        <div className={classes.aside}>{aside}</div>
        <div className={classes.main}>{children}</div>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    height: '100%',
    paddingInline: theme.spacing(2),
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  controls: {
    width: '100%',
  },
  sidebarLayout: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    gap: theme.spacing(2),
  },
  main: {
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  aside: {
    flexShrink: 0,
    overflowY: 'auto',
  },
}));

export interface SidebarLayoutProps {
  aside: ReactNode;
  controls: ReactNode;
  className?: string;
}
