import { createStore, derive } from 'store-utils';
import ScanStore from '../../new-scan/store/scan-store';
import { ActiveMember } from '../types/members-types';
import { demoMembersList } from '../types/demo-members-list';

const baseStore = createStore<MembersStoreState>(
  {
    activeMembers: [],
    isLoading: false,
    searchInput: '',
  },
  'Members Store',
);

const MembersStore = derive(
  {
    membersFiltered: get => {
      const { activeMembers, searchInput } = get(baseStore);
      const { isDemoEnabled } = get(ScanStore);
      const membersToFilter = isDemoEnabled
        ? [...activeMembers, ...demoMembersList] // in demo we show demo members as well as active members - request from sales / marketing : https://oxsecurity.atlassian.net/browse/OXDEV-31654
        : activeMembers;
      const lowerCaseValue = searchInput.toLowerCase();

      return membersToFilter.filter(member => {
        return (
          member.name?.toLowerCase().includes(lowerCaseValue) ||
          member.email?.toLowerCase().includes(lowerCaseValue) ||
          member.roles?.some(role =>
            role.name.toLowerCase().includes(lowerCaseValue),
          )
        );
      });
    },
  },
  { proxy: baseStore },
);

interface MembersStoreState {
  activeMembers: ActiveMember[];
  isLoading: boolean;
  searchInput: string;
}

export default MembersStore;
