import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'app-navigator';
import { logger } from 'logging-utils';
import React, { FC } from 'react';
import { Auth0ErrorNames } from '../../AppGeneralTypes';
import {
  handleAccessDenied,
  handleInvalidRequest,
} from '../../utils/auth0ErrorUtils';

const Auth0ErrorHandler: FC<
  React.PropsWithChildren<Auth0ErrorHandlerProps>
> = ({ children }) => {
  const query = useQuery();
  const auth0Error = query.get('error');
  const errorDescription = query.get('error_description');
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  if (isAuthenticated || isLoading) {
    return <>{children}</>;
  }

  switch (auth0Error) {
    case Auth0ErrorNames.accessDenied:
      if (!errorDescription) {
        logger.warn('Callback error returned without a description');
        break;
      }
      handleAccessDenied(errorDescription, loginWithRedirect);
      break;
    case Auth0ErrorNames.invalidRequest:
      if (!errorDescription) {
        logger.warn('Callback error returned without a description');
        break;
      }
      handleInvalidRequest(errorDescription, loginWithRedirect);
      break;
    case Auth0ErrorNames.tooManyAttempts:
      // eslint-disable-next-line no-console
      console.error('not implemented yet');
      break;
    case Auth0ErrorNames.tooManyLogins:
      // eslint-disable-next-line no-console
      console.error('not implemented yet');
      break;
    case Auth0ErrorNames.unauthorized:
      // eslint-disable-next-line no-console
      console.error('not implemented yet');
      break;
    case Auth0ErrorNames.unauthorizedClient:
      // eslint-disable-next-line no-console
      console.error('not implemented yet');
      break;
    default:
      break;
  }

  return <>{children}</>;
};

export interface Auth0ErrorHandlerProps {
  children: React.ReactNode;
}

export default Auth0ErrorHandler;
