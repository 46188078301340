import { createStore } from 'store-utils';

export const TokenStore = createStore<TokenStoreState>(
  {
    authToken: undefined,
    loadingNewToken: true,
    shouldForceLogout: false,
    shouldTryRefresh: false,
  },
  'Token Store',
);

export interface TokenStoreState {
  loadingNewToken: boolean;
  authToken?: string;
  shouldForceLogout: boolean;
  shouldTryRefresh: boolean;
}
