import {
  AppPages,
  navigate,
  navigateToArtifacts,
  navigateWithFilters,
} from 'app-navigator';
import { createSimpleAsyncAction } from 'async-utils';
import { debounce } from 'lodash-es';
import {
  FilterItems,
  FilterPage,
  filterEmptyItems,
  getFilterItemsLabels,
  getOpenFilterItemIds,
} from 'ox-filter-utils';
import getFilterLabels from 'ox-filter-utils/src/get-filter-labels/get-filter-labels';
import { snapshot } from 'valtio';
import artifactsService from '../services';
import {
  setArtifactsTopFiltersList,
  setHashSearch,
  setStoreArtifactsLazyFilters,
} from '../store-actions/artifacts-store-actions';
import ArtifactsStore from '../stores/artifacts-store';
import {
  ArtifactTopFiltersTypes,
  FetchArtifactsInput,
  GetArtifactsFilters,
} from '../types/artifacts-type';
import { getArtifactsRequestParams } from '../utils/artifacts-utils';

export const loadArtifactsTopFilters = createSimpleAsyncAction(
  async (cache: boolean = true) => {
    const limit = 100;
    const requestParams = getArtifactsRequestParams(limit);

    const response = await artifactsService.getArtifactsTopFilters.execute(
      requestParams,
      cache,
    );

    if (response) {
      setArtifactsTopFiltersList(response);
    }
  },
  {
    asyncState: ArtifactsStore.loadingFilters,
    errorMessage: 'Failed to load top artifacts filters',
  },
);

export const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
  const filterId = e.target.id;
  const filterValue = e.target.value;
  const filterByItem = ArtifactsStore.filterArtifactsBy[filterId];

  if (filterByItem) {
    const index = filterByItem.indexOf(filterValue);
    if (index !== -1) {
      filterByItem.splice(index, 1);
    } else {
      filterByItem.push(filterValue);
    }
  } else {
    ArtifactsStore.filterArtifactsBy[filterId] = [filterValue];
  }

  ArtifactsStore.filterArtifactsBy = filterEmptyItems(
    ArtifactsStore.filterArtifactsBy,
  );

  navigateToArtifacts();
};

export const onChangeTopFilter = (filterType: ArtifactTopFiltersTypes) => {
  if (ArtifactsStore.topFiltersArtifactsBy.includes(filterType)) {
    const index = ArtifactsStore.topFiltersArtifactsBy.indexOf(filterType);
    if (index !== -1) {
      ArtifactsStore.topFiltersArtifactsBy.splice(index, 1);
    } else {
      ArtifactsStore.topFiltersArtifactsBy.push(filterType);
    }
  } else {
    ArtifactsStore.topFiltersArtifactsBy.push(filterType);
  }

  navigateToArtifacts();
};

export const clearAllFilters = () => {
  ArtifactsStore.filterArtifactsBy = {};
  ArtifactsStore.numberOfFilters = 0;
  navigate(AppPages.Artifacts);
};

export const clearFilter = (type: string) => {
  ArtifactsStore.filterArtifactsBy[type] = [];
  navigateWithFilters(
    AppPages.Artifacts,
    snapshot(ArtifactsStore.filterArtifactsBy),
  );
};

export const setInitialFilters = (encodedFilters: string | null) => {
  if (!encodedFilters) return;
  let filters = {};
  try {
    filters = JSON.parse(decodeURI(encodedFilters));
  } catch {}
  ArtifactsStore.filterArtifactsBy = filters;
};

export const setArtifactsFiltersLabels = createSimpleAsyncAction(
  async () => {
    const { filterItems } = snapshot(ArtifactsStore);

    if (!filterItems) {
      const results = await getFilterLabels(FilterPage.Artifacts);
      const filterItems: FilterItems = getFilterItemsLabels(results);

      ArtifactsStore.lazyFilters = filterItems;
    }
  },
  {
    asyncState: ArtifactsStore.loadingFilters,
    errorMessage: 'Failed to load artifacts filter labels',
  },
);

export const getArtifactsFilterItems = createSimpleAsyncAction(
  async (params?: GetArtifactsFilters) => {
    const { type, isOpen, cache = true } = params || {};
    const limit = 100;
    const requestParams: FetchArtifactsInput = getArtifactsRequestParams(limit);
    const currentFilterItem =
      ArtifactsStore.lazyFilters && type && ArtifactsStore.lazyFilters[type];

    if (currentFilterItem) {
      currentFilterItem.isOpen = Boolean(isOpen);
      currentFilterItem.isLoading = Boolean(isOpen);
    }

    const { filterItems } = snapshot(ArtifactsStore);
    const filterItem = filterItems && type && filterItems[type];
    const openFilterItemIds = filterItems
      ? getOpenFilterItemIds(filterItems)
      : [];

    const currentFilterItemIsClosed = filterItem && !filterItem.isOpen;
    const allFiltersClosed =
      !openFilterItemIds || openFilterItemIds.length === 0;

    if (allFiltersClosed || currentFilterItemIsClosed) {
      if (currentFilterItem) {
        currentFilterItem.isLoading = false;
      }
      return;
    }

    const results = await artifactsService.getArtifactsFiltersLazy.execute(
      {
        openItems: openFilterItemIds,
        ...requestParams,
      },
      cache,
    );

    if (currentFilterItem) {
      currentFilterItem.isLoading = false;
    }

    if (results && results.filters && filterItems) {
      setStoreArtifactsLazyFilters(results.filters, filterItems);
    }
  },
  {
    asyncState: ArtifactsStore.loadingFilters,
    errorMessage: 'Failed to load artifacts filters',
  },
);

export const onTopSearchChange = debounce(
  (e: React.ChangeEvent<HTMLInputElement>) => {
    setHashSearch(e.target.value);
  },
  500,
);
