import { Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import OxSliderTickItem from './OxSliderTickItem';

const OxSliderTicks: FC<React.PropsWithChildren<SliderTicksProps>> = ({
  ticks,
}) => {
  const { classes } = useStyles();

  return (
    <span className={classes.sliderTicks}>
      {ticks.map((t, index) => (
        <OxSliderTickItem value={t} key={index} />
      ))}
    </span>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  sliderTicks: {
    width: '100%',
    height: 20,
    gap: theme.spacing(0.25),
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    overflow: 'hidden',
  },
}));

export interface SliderTicksProps {
  ticks: number[];
}

export default OxSliderTicks;
