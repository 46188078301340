import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import mutation from './remove-policy-exclusion.gql';

const removePolicyExclusion = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (removePolicyExclusionInput: RemovePolicyExclusionInput) => {
      try {
        const results = await client.mutate<{
          removePolicyExclusionWithUpdate: RemovePolicyExclusionResponse;
        }>({
          mutation,
          variables: { exclusionId: removePolicyExclusionInput.exclusionId },
        });

        if (!results || !results.data) {
          logger.error(
            'Received an invalid response from remove policy exclusion',
          );
          throw new Error('Failed to remove policy exclusion');
        }

        return results.data.removePolicyExclusionWithUpdate;
      } catch (error) {
        logger.error(
          `Failed to remove exclusion for ${removePolicyExclusionInput.exclusionId}`,
          error,
        );
        logger.error(
          `Failed to remove exclusion for ${removePolicyExclusionInput.exclusionId}`,
        );
        throw new Error('Failed to remove policy exclusion');
      }
    },
  };
};

export interface RemovePolicyExclusionInput {
  exclusionId: string;
  policyName: string;
}

export interface RemovePolicyExclusionResponse {
  id: string;
}

export default removePolicyExclusion;
