import { createStore } from 'store-utils';

const localStorageString = localStorage.getItem('theme');

const ThemeStore = createStore<ThemeStoreState>(
  {
    themeType: localStorageString === 'dark' ? 'dark' : 'light',
  },
  'Theme Store',
);

export interface ThemeStoreState {
  themeType: 'light' | 'dark';
}

export default ThemeStore;
