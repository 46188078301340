import { getAppStore } from 'app-store';
import { isOxUserEmail } from 'common-utils';
import { shouldTrack } from '../actions/hubspot-analytics-actions';

// @ts-ignore
const _hsq: [string, unknown?][] = (window._hsq = window._hsq || []);

export const trackPageView = (locationPathName: string) => {
  const { user } = getAppStore();
  if (!user) {
    return;
  }
  const isOxUser =
    isOxUserEmail(user.email) && !user.email?.startsWith('yaniv');

  if (!shouldTrack() || isOxUser) {
    return;
  }

  _hsq.push(['setPath', locationPathName]);
  _hsq.push(['trackPageView']);
};
