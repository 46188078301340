import { env } from 'env-utils';

const domain = env().REACT_APP_AUTH0_DOMAIN || '';
const clientId = env().REACT_APP_AUTH0_CLIENTID || '';
const audience = env().REACT_APP_AUTH0_AUDIENCE || null;

const getConfig = () => {
  return {
    domain,
    clientId,
    ...(audience ? { audience } : null),
  };
};

export { getConfig };
