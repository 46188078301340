import { IconButton, IconButtonProps, SvgIcon } from '@mui/material';
import { FC, HTMLAttributeAnchorTarget } from 'react';
import { BiLinkExternal } from 'react-icons/bi';

export const ExternalLinkButton: FC<
  IconButtonProps<
    'button',
    {
      href?: string;
      target?: HTMLAttributeAnchorTarget;
      rel?: 'noreferrer';
    }
  >
> = props => {
  const { color = 'primary' } = props;
  return (
    <IconButton color={color} {...props}>
      <SvgIcon component={BiLinkExternal} color='inherit' fontSize='inherit' />
    </IconButton>
  );
};
