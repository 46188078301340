import { FC } from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { SeverityDiffStatus } from '../../types/issues-types';
import { getCurrentTheme } from 'app-theme';

const IssueCardSeverityDiff: FC<
  React.PropsWithChildren<IssueCardSeverityDiffProps>
> = ({ diff, date }) => {
  return (
    <List>
      {date && `Date of change: ${new Date(date).toLocaleString()}`}
      {diff.map(d => (
        <ListItem
          sx={{
            p: 1,
            m: 0,
            gap: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          {
            <Typography
              color={getColor(d.status)}
              variant='caption'
              sx={{ textTransform: 'capitalize' }}>
              {d.status}
            </Typography>
          }

          <Typography color={'text.primary'} variant='caption'>
            {d.shortName}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

const getColor = (status: SeverityDiffStatus) => {
  const theme = getCurrentTheme();
  switch (status) {
    case SeverityDiffStatus.New:
      return theme.palette.success.main;

    case SeverityDiffStatus.Increased:
      return theme.palette.error.main;
    case SeverityDiffStatus.Decreased:
      return theme.palette.success.main;
    case SeverityDiffStatus.Removed:
      return theme.palette.error.main;
  }
};

export interface IssueCardSeverityDiffProps {
  diff: {
    shortName: string;
    change: number;
    status: SeverityDiffStatus;
  }[];
  date: string;
}

export default IssueCardSeverityDiff;
