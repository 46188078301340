import { createIssuesActions } from '../../common/actions/create-issues-actions';
import { LoadIssuesFiltersParams } from '../../common/types/issues-types';
import ResolvedIssuesStore from '../../resolved-issues/stores/resolved-issues-store';
import resolvedIssuesService from '../services';
import * as storeActions from '../store-actions/resolved-issue-store-actions';
import { loadResolvedIssuesOverview } from './resolved-issues-overview-actions';

const resolvedIssuesActions = createIssuesActions(
  ResolvedIssuesStore,
  resolvedIssuesService,
  storeActions,
);

// export actions individually
export const loadIssuesResolved = resolvedIssuesActions.loadIssues;
export const loadFiltersResolved = async (params?: LoadIssuesFiltersParams) => {
  await resolvedIssuesActions.loadFiltersWithoutSearch(params);
  loadResolvedIssuesOverview({});
};
export const setIssueDrawer = resolvedIssuesActions.setIssueDrawer;
export const setIssueDrawerResolved = resolvedIssuesActions.setIssueDrawer;
export const clearSelectedIssueResolved =
  resolvedIssuesActions.clearSelectedIssue;
export const setLoadingIssueResolved = resolvedIssuesActions.setLoadingIssue;
export const handleIssueRowClickResolved =
  resolvedIssuesActions.handleIssueRowClick;
export const sortIssuesResolved = resolvedIssuesActions.sortIssues;
export const handleSearchFilterTypeResolved =
  resolvedIssuesActions.handleSearchFilterTypeWithoutNewSearch;
export const setMenuIssueIdResolved = resolvedIssuesActions.setMenuIssueId;
