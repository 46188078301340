import { Button, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export const CustomButton: FC<React.PropsWithChildren<CustomButtonProps>> = ({
  handleClick,
  objIcon: ObjIcon,
  children,
  disabled = false,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Button
      onClick={handleClick}
      sx={{ textTransform: 'none' }}
      className={cx(classes.text, classes.count)}
      startIcon={ObjIcon}
      disabled={disabled}>
      {children}
    </Button>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  count: {
    color: '#5059FF',
    textAlign: 'center',
    padding: theme.spacing(0.5, 1.5),
    background:
      theme.palette.mode === 'dark'
        ? '#1C1F2A'
        : theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  text: {
    color: `${theme.palette.dashInventory.textColor.colorAppsText} !important`,
    whiteSpace: 'nowrap',
    fontSize: 14,
    minWidth: '5rem',
  },
}));

export interface CustomButtonProps {
  handleClick?: () => void;
  objIcon: ReactNode;
  disabled?: boolean;
}
