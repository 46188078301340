import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  CommentToIssueResponse,
  DeleteCommentIssueInput,
} from '../../types/issues-types';
import mutation from './delete-comment-issue.gql';

const deleteCommentIssue = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      deleteCommentIssue: DeleteCommentIssueInput,
    ): Promise<CommentToIssueResponse> => {
      try {
        const results = await client.mutate<DeleteCommentIssueResponse>({
          mutation,
          variables: { input: deleteCommentIssue },
        });
        if (results?.data) return results.data.deleteCommentIssue;
      } catch (e) {
        logger.error('Faild to delete comment', e);
      }
      return { isUpdated: false };
    },
  };
};

interface DeleteCommentIssueResponse {
  deleteCommentIssue: CommentToIssueResponse;
}

export default deleteCommentIssue;
