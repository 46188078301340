import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import {
  ValidateAllCredentialsResponse,
  VerifyConnectorsCredentialsObj,
} from '../../connectors-types';
import mutation from './validate-all-credentials.gql';

const validateAllCredentials = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      orgId: string,
    ): Promise<Nullable<VerifyConnectorsCredentialsObj>> => {
      try {
        const results = await client.mutate<ValidateAllCredentialsResponse>({
          fetchPolicy: 'no-cache',
          mutation,
          variables: { orgId },
        });

        if (!results.data) {
          throw new Error('failed to validate all credentials');
        }

        return results.data.verifyConnectorsCredentials;
      } catch (error) {
        return null;
      }
    },
  };
};

export default validateAllCredentials;
