import { Location } from 'history';
import { FC, useCallback } from 'react';
import { PrimaryMenuItems } from '../../../menu/config/main-menu-config';
import RouteChangeListener from './RouteChangeListener';

const ChangeTitleOnRouteChange: FC = () => {
  const handleRouteChange = useCallback((location: Location) => {
    const currPage = location.pathname;
    const currPageTitle = PrimaryMenuItems.find(item => item.href === currPage);
    if (currPageTitle) {
      document.title = `OX Security - ${currPageTitle.label}`;
    } else {
      if (currPage === '/') {
        document.title = 'OX Security';
      } else {
        const pageTitle = currPage.replace('/', '');
        document.title = `OX Security - ${
          pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1)
        }`;
      }
    }
  }, []);

  return <RouteChangeListener onRouteChange={handleRouteChange} />;
};

export default ChangeTitleOnRouteChange;
