import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, IconButton, Paper, Theme, useTheme } from '@mui/material';
import { Handle, NodeProps, Position } from 'reactflow';
import useCommonGraphStore from '../stores/common-graph-store';
import { onNodeExpandClick } from '../utils/format-utils';
import { FaExternalLinkAlt } from 'react-icons/fa';

const ExpandNode: FC<React.PropsWithChildren<NodeProps>> = props => {
  const { data, isConnectable } = props;
  const { cx, classes } = useStyles();
  const metadata = data.metadata;
  const theme = useTheme();
  const { showMoreMap, aggType } = useCommonGraphStore.getState();
  const onClick = () => onNodeExpandClick(data, aggType);
  const size = 40;
  return (
    <div style={{ position: 'relative' }}>
      <Handle
        type='target'
        position={Position.Left}
        style={{ visibility: 'hidden' }}
        isConnectable={isConnectable}
      />
      <IconButton size='small' onClick={onClick}>
        <Paper
          style={{
            width: size,
            height: size,
          }}
          className={cx(classes.iconContainer)}>
          <div className={classes.container}>
            {showMoreMap[metadata.parentId] ? (
              <div style={{ display: 'inline-flex' }}>
                <FaExternalLinkAlt
                  fontSize={10}
                  width={10}
                  height={10}
                  color='#90C0FF'
                />
              </div>
            ) : (
              <Typography fontSize={12} color='#90C0FF'>
                +{metadata.count}
              </Typography>
            )}
          </div>
        </Paper>
      </IconButton>
      <div className={classes.textContainer}>
        <Typography
          textAlign={'center'}
          variant='body1'
          fontSize={10}
          sx={{
            backgroundColor:
              theme.palette.mode === 'light'
                ? '#edeef4'
                : theme.palette.background.paper,
          }}
          color='text.primary'>
          {metadata.total - 6} more items
        </Typography>
      </div>
      <Handle
        type='source'
        position={Position.Left}
        style={{ visibility: 'hidden' }}
        isConnectable={isConnectable}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',

    borderRadius: '50%',
    borderColor: 'red',
    marginBottom: 2,
    position: 'relative',
  },
  textContainer: {
    position: 'absolute',
    top: '103%',
    marginTop: 1,

    display: 'flex',
    borderRadius: 10,
    pointerEvents: 'all',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '50%',
    borderColor: 'red',
  },
}));

export default ExpandNode;
