import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  BaseApplicationRow,
  GetApplicationsInput,
} from '../../applications-types';
import query from './get-applications.gql';

const getApplications = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getApplicationsInput: GetApplicationsInput,
      cache = true,
    ) => {
      try {
        const results = await client.query<{
          getApplications: ApplicationsResponse;
        }>({
          query,
          variables: { getApplicationsInput },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });

        return results.data.getApplications;
      } catch (error) {
        logger.error('Failed to fetch applications', error);
        return null;
      }
    },
  };
};

export interface ApplicationsResponse {
  applications: BaseApplicationRow[];
  offset: number;
  total: number;
  totalFilteredApps: number;
  totalIrrelevantApps: number;
  selectedPosition: number;
  topOffset: number;
}

export default getApplications;
