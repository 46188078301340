import {
  Collapse,
  CollapseProps,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { makeStyles } from 'tss-react/mui';
import * as React from 'react';

export const UncontrolledCollapsiblePanel: FC<
  React.PropsWithChildren<UncontrolledCollapsiblePanelProps>
> = ({ text, defaultState, ...props }) => {
  const [expanded, setExpanded] = useState(!!defaultState);
  useEffect(() => {
    setExpanded(!!defaultState);
  }, [defaultState]);
  const { classes } = useStyles();
  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton
          color='primary'
          size='small'
          onBlur={e => e.stopPropagation()}
          onClick={handleToggle}>
          {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </IconButton>
        <Typography variant='body2' color='text.secondary'>
          {text}
        </Typography>
      </div>
      <Collapse in={expanded} {...props}>
        {props.children}
      </Collapse>
    </div>
  );
};

export interface UncontrolledCollapsiblePanelProps extends CollapseProps {
  text: string | ReactNode;
  defaultState?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  container: {
    width: '100%',
  },
}));
