import { Theme } from '@mui/material';
import { important } from 'ox-common-types';
import { forwardRef, PropsWithChildren, useCallback, useState } from 'react';
import { Props, Rnd } from 'react-rnd';
import { makeStyles } from 'tss-react/mui';
import CustomResizer from './CustomResizer';

export const ResizablePanel = forwardRef<
  Rnd,
  PropsWithChildren<ResizablePanelProps>
>(
  (
    {
      className,
      defaultHeight = 300,
      children,
      onResizeStop,
      maxHeight = '90%',
    },
    ref,
  ) => {
    const { classes, cx } = useStyles();
    const [resizing, setResizing] = useState(false);

    const handleResizeStart = useCallback(() => {
      setResizing(true);
    }, []);

    const handleResizeStop = useCallback(
      (_: unknown, __: unknown, element: HTMLElement) => {
        setResizing(false);
        onResizeStop && onResizeStop(element.clientHeight);
      },
      [onResizeStop],
    );

    return (
      <Rnd
        ref={ref}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResizeStop}
        maxWidth='100%'
        maxHeight={maxHeight}
        disableDragging
        enableResizing={{
          top: true,
        }}
        className={cx(classes.rnd, className)}
        minHeight={100}
        default={{
          height: defaultHeight,
          width: '100%',
          x: 0,
          y: 0,
        }}
        resizeHandleComponent={{
          top: <CustomResizer resizing={resizing} />,
        }}>
        <div className={classes.content}>{children}</div>
      </Rnd>
    );
  },
);

const useStyles = makeStyles()((theme: Theme) => ({
  rnd: {
    transform: important('none'),
    bottom: important('0'),
    top: important('auto'),
    width: important('100%'),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
}));

export interface ResizablePanelProps {
  className?: string;
  defaultHeight?: number;
  onResizeStop?: (height: number) => void;
  maxHeight?: Props['maxHeight'];
}
