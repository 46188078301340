import { FC } from 'react';
import { Typography, ListItem, useTheme } from '@mui/material';
import NodeTooltip from '../NodeTooltip';
import { CustomExtLink } from 'ox-react-components';

const items = [
  { key: 'name', label: 'Name', link: 'consoleUrl' },
  { key: 'accountName', label: 'Account Name' },
  { key: 'schema', label: 'Schema' },
  { key: 'resourceID', label: 'Resource Id' },
  {
    key: 'WAFEnabled',
    label: 'WAF Enabled',
    formatter: value => (value ? 'Yes' : 'No'),
  },
  { key: 'dns', label: 'DNS' },
  { key: 'vpc', label: 'VPC' },
  {
    key: 'subnet',
    label: 'Subnet',
    formatter: value => (value ? value?.join(', ') : 'N/A'),
  },
  {
    key: 'sg',
    label: 'Security Groups',
    formatter: value => (value ? value?.join(', ') : 'N/A'),
  },
  {
    key: 'listeningPorts',
    label: 'Listening Ports',
    formatter: value => (value ? value?.join(', ') : 'N/A'),
  },
  { key: 'lbName', label: 'Load Balancer Name', link: 'lbLink' },

  { key: 'lbType', label: 'Load Balancer Type' },
  { key: 'lbListeningPort', label: 'Load Balancer Listening Port' },
  { key: 'lbRoute', label: 'Load Balancer Route' },
  { key: 'severityFactors', label: 'Severity Factors' },
];

const CloudItem: FC = metadata => {
  const theme = useTheme();
  return (
    <NodeTooltip title={`AWS Info`}>
      {items.map(
        item =>
          metadata[item.key] && (
            <ListItem
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                display: 'flex',
                gap: 1,
                alignItems: 'flex-start',
              }}>
              <Typography variant='caption' color='text.secondary'>
                {item.label}:&nbsp;
              </Typography>
              {
                <Typography
                  variant='caption'
                  style={{ display: 'flex', gap: 4 }}>
                  {item.formatter
                    ? item.formatter(metadata[item.key])
                    : metadata[item.key]}
                  {item.link && metadata[item.link] && (
                    <CustomExtLink value='' href={metadata[item.link]} />
                  )}
                </Typography>
              }
            </ListItem>
          ),
      )}
    </NodeTooltip>
  );
};

export default CloudItem;
