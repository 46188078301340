import { logger } from 'logging-utils';
import oscarService from '../services';
import OscarIssueStore from '../stores/oscar-issue-store';
import { convertMatrixResponse } from '../utils/oscar-convert-utils';

export const loadOscarIssueMatrix = async (
  techniquesNames: string[],
  appOwnersIds: string[],
  selectedTagIds: string[],
) => {
  try {
    OscarIssueStore.error = false;
    OscarIssueStore.loading = true;
    const rawTactics = await oscarService.fetchIssueMatrix.execute(
      techniquesNames,
      appOwnersIds,
      selectedTagIds,
    );

    OscarIssueStore.tactics = convertMatrixResponse(rawTactics);
  } catch (err) {
    logger.error(
      'Unable to load issue matrix give the following techniques: ' +
        techniquesNames.join(', '),
    );
    OscarIssueStore.error = true;
  } finally {
    OscarIssueStore.loading = false;
  }
};

export const resetOscarIssueStore = () => {
  OscarIssueStore.tactics = [];
  OscarIssueStore.error = false;
  OscarIssueStore.loading = false;
};
