import { ScaVulnerability } from '../../../../../../types/issues-types';
import NotReachableVulnerabilitiesStore from '../stores/not-reachable-vulnerabilities-store';
import ReachableVulnerabilitiesStore from '../stores/reachable-vulnerabilities-store';
import { CveContext } from '../types';

export const setNotReachableVulnerabilities = (
  vulnerabilities: ScaVulnerability[],
  update?: boolean,
) => {
  if (update) {
    NotReachableVulnerabilitiesStore.data = vulnerabilities;
  } else {
    NotReachableVulnerabilitiesStore.data = [
      ...NotReachableVulnerabilitiesStore.data,
      ...vulnerabilities,
    ];
  }
};
export const setNotReachableTotal = (total: number) => {
  NotReachableVulnerabilitiesStore.total = total;
};
export const setNotReachableOffset = (offset: number) => {
  NotReachableVulnerabilitiesStore.offset = offset;
};

export const setReachableVulnerabilities = (
  vulnerabilities: ScaVulnerability[],
  update?: boolean,
) => {
  if (update) {
    ReachableVulnerabilitiesStore.data = vulnerabilities;
  } else {
    ReachableVulnerabilitiesStore.data = [
      ...ReachableVulnerabilitiesStore.data,
      ...vulnerabilities,
    ];
  }
};
export const setReachableTotal = (total: number) => {
  ReachableVulnerabilitiesStore.total = total;
};
export const setReachableOffset = (offset: number) => {
  ReachableVulnerabilitiesStore.offset = offset;
};
export const setLoadingReachableVulns = (loading: boolean) => {
  ReachableVulnerabilitiesStore.loading = loading;
};
export const setLoadingNotReachableVulns = (loading: boolean) => {
  NotReachableVulnerabilitiesStore.loading = loading;
};

export const resetNotReachableVulnerabilities = () => {
  NotReachableVulnerabilitiesStore.data = [];
  NotReachableVulnerabilitiesStore.offset = 0;
  NotReachableVulnerabilitiesStore.total = 0;
  NotReachableVulnerabilitiesStore.contextOptions = [];
  NotReachableVulnerabilitiesStore.severityFactors = [];
};
export const resetReachableVulnerabilities = () => {
  ReachableVulnerabilitiesStore.data = [];
  ReachableVulnerabilitiesStore.contextOptions = [];
  ReachableVulnerabilitiesStore.offset = 0;
  ReachableVulnerabilitiesStore.total = 0;
  ReachableVulnerabilitiesStore.severityFactors = [];
};

export const setNotReachableSearchValue = (searchValue: string) => {
  NotReachableVulnerabilitiesStore.searchValue = searchValue;
};

export const setReachableSearchValue = (searchValue: string) => {
  ReachableVulnerabilitiesStore.searchValue = searchValue;
};

export const setReachableContextOptions = (options: CveContext[]) => {
  ReachableVulnerabilitiesStore.contextOptions = options;
};

export const setNotReachableContextOptions = (options: CveContext[]) => {
  NotReachableVulnerabilitiesStore.contextOptions = options;
};

export const setNotReachableSeverityFactors = (severityFactors: string[]) => {
  NotReachableVulnerabilitiesStore.severityFactors = severityFactors;
};

export const setReachableSeverityFactors = (severityFactors: string[]) => {
  ReachableVulnerabilitiesStore.severityFactors = severityFactors;
};
