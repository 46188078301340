export enum Auth0ErrorNames {
  accessDenied = 'access_denied', // Validation of specific points raised an access issue
  invalidRequest = 'invalid_request', // Several different errors under this name
  unauthorized = 'unauthorized', // Blocked user
  unauthorizedClient = 'unauthorized_client', // The provided client_id is not valid
  tooManyAttempts = 'too_many_attempts',
  tooManyLogins = 'too_many_logins',
}

export enum Auth0AccessDeniedTypes {
  WrongOrg = 'wrongOrg',
  InvalidOrUnknownToken = 'invalidOrUnknownToken',
  BlockSignupForUninvited = 'blockSignupForUninvited',
  Unknown = 'unknown',
  PleaseVerifyYourEmail = 'please_verify_your_email',
}

export enum Auth0InvalidRequestTypes {
  OrgDoesNotExist = 'orgDoesNotExist',
  Unknown = 'unknown',
}

export enum Auth0ErrorDescriptions {
  NoSignupForUninvited = 'no_signups_for_uninvited',
  UnknownOrInvalidRefreshToken = 'Unknown or invalid refresh token',
}
