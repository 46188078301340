import { Theme, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ScoreItem } from '../../types/issues-types';

const Score: FC<React.PropsWithChildren<ScoreProps>> = ({
  severityType,
  value,
  className,
  businessPriority: buisnessPriority,
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const bgColor = !buisnessPriority
    ? theme.palette.categoryRiskColor['medium']
    : theme.palette.categoryRiskColor[severityType];

  return (
    <Typography
      component='span'
      variant='body2'
      className={cx(classes.score, className)}
      style={{
        backgroundColor: bgColor,
        color: theme.palette.getContrastText(bgColor),
      }}>
      {value}
    </Typography>
  );
};
const useStyles = makeStyles()((theme: Theme) => ({
  score: {
    borderRadius: '1em',
    minWidth: 45,
    height: 20,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface ScoreProps extends Pick<ScoreItem, 'value' | 'severityType'> {
  className?: string;
  businessPriority?: boolean;
}

export default Score;
