import { Link, Theme, Typography } from '@mui/material';
import { resolveLanguageIcon } from 'common-icons';
import { isValidHttpUrl } from 'common-utils';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CweList, ExtraInfoTypes } from '../../types/issues-types';
import { FaCodeBranch } from 'react-icons/fa';

const IssueCardAdditionalInfo: FC<
  React.PropsWithChildren<IssueCardAdditionalInfoProps>
> = ({ sourceTools, cwe, extraInfo, fixLink, language, branchScanned }) => {
  const { classes } = useStyles();
  const LanguageIcon = resolveLanguageIcon(language && language.toLowerCase());
  return (
    <div className={classes.container}>
      {extraInfo?.length > 0 && (
        <div className={classes.extraInfo}>
          {extraInfo.map((info, index) => {
            if (
              info.key !== ExtraInfoTypes.OriginalSeverity &&
              info.key !== ExtraInfoTypes.LowerSeverityReason
            ) {
              return (
                <Typography
                  variant='body2'
                  mt={0.5}
                  mb={0.5}
                  component='div'
                  sx={{ whiteSpace: 'pre-wrap' }}
                  key={index}>
                  <span className={classes.key}>{info.key}</span>&nbsp;
                  {isValidHttpUrl(info.value) ? (
                    <Link
                      href={info.value}
                      key={info.value}
                      underline='none'
                      target='_blank'>
                      <span className={classes.linkValue}>{info.value}</span>
                    </Link>
                  ) : (
                    <span className={classes.value}>
                      <br />
                      {info.value}
                    </span>
                  )}
                </Typography>
              );
            } else {
              return <div key={index}></div>;
            }
          })}
        </div>
      )}
      {sourceTools && sourceTools.length > 0 && (
        <Typography variant='body2' className={classes.key} mt={1.5}>
          Source tools
        </Typography>
      )}
      {sourceTools &&
        sourceTools.map((tool, index) => {
          return (
            <Typography key={index} variant='body2' mb={0.5} component='div'>
              <span className={classes.value}>{tool}</span>
            </Typography>
          );
        })}
      {language && (
        <Typography variant='body2' className={classes.key} mt={1.5} mb={0.5}>
          Language
        </Typography>
      )}
      {language && (
        <Typography variant='body2' display='flex' alignItems='center' gap={1}>
          {LanguageIcon && <LanguageIcon />}
          {language}
        </Typography>
      )}
      {branchScanned && (
        <Typography variant='body2' className={classes.key} mt={1.5} mb={0.5}>
          Analyzed Branch
        </Typography>
      )}
      {branchScanned && (
        <Typography variant='body2' display='flex' alignItems='center' gap={1}>
          <FaCodeBranch />
          {branchScanned}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  key: {
    color: theme.palette.text.secondary,
  },
  value: {
    color: theme.palette.text.primary,
  },
  linkValue: {
    color: theme.palette.primary.main,
  },
  cweLink: {
    width: 'fit-content',
  },
  extraInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}));

export interface IssueCardAdditionalInfoProps {
  violationInfoTitle?: string;
  sourceTools?: string[];
  cwe?: CweList[];
  extraInfo: { key: string; value: string }[];
  fixLink?: string;
  language?: string;
  branchScanned?: string;
}

export default IssueCardAdditionalInfo;
