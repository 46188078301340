import { Theme } from '@mui/material';
import { FC } from 'react';
import { FiArrowDownRight, FiArrowRight, FiArrowUpRight } from 'react-icons/fi';
import { makeStyles } from 'tss-react/mui';
import { SeverityTrend } from '../types/issues-types';

const SeverityTrendIcon: FC<SeverityTrendIconProps> = ({ trend }) => {
  const { classes } = useStyles();

  if (trend === SeverityTrend.Neutral) {
    return <FiArrowRight className={classes.neutral} />;
  }
  if (trend === SeverityTrend.Up) {
    return <FiArrowUpRight className={classes.up} />;
  }
  if (trend === SeverityTrend.Down) {
    return <FiArrowDownRight className={classes.down} />;
  }

  return null;
};

const useStyles = makeStyles()((theme: Theme) => ({
  up: {
    color: theme.palette.error.main,
  },
  down: {
    color: theme.palette.success.main,
  },
  neutral: {
    color: theme.palette.text.secondary,
  },
}));

export interface SeverityTrendIconProps {
  trend: SeverityTrend;
}

export default SeverityTrendIcon;
