import { reportClient } from 'api-clients';
import addCommentToIssue from '../../common/services/add-comment-to-issue/add-comment-to-issue';
import deleteCommentIssue from '../../common/services/delete-comment-issue/delete-comment-issue';
import getPipelineSingleIssue from './get-pipeline-issue/get-pipeline-issue-service';
import getPipelineIssuesFilters from './get-pipeline-issues-filters/get-pipeline-issues-filters-service';
import getPipelineIssues from './get-pipeline-issues/get-pipeline-issues-service';
import fetchPipelineIssuesFilterItems from './get-pipeline-issues-filter-items/get-pipeline-issues-filter-items';

const pipelineIssuesService = {
  getIssues: getPipelineIssues(reportClient),
  getIssue: getPipelineSingleIssue(reportClient),
  getIssuesFilters: getPipelineIssuesFilters(reportClient),
  addCommentToIssue: addCommentToIssue(reportClient),
  deleteCommentIssue: deleteCommentIssue(reportClient),
  getPipelineIssuesFilterItems: fetchPipelineIssuesFilterItems(reportClient),
};

export default pipelineIssuesService;
