import { IconButton, Theme, Typography } from '@mui/material';
import { IconType } from 'ox-common-types';
import { CSSProperties, FC } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { MdOutlinePlayCircleFilled as ArrowCircleLeftIcon } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

export const Summary: FC<React.PropsWithChildren<SummaryProps>> = ({
  headerText,
  linkText,
  linkUrl,
  onCollapse,
  navComponent: NavComponent,
  count = 0,
  total,
  summaryBackIcon: Icon,
  className,
}) => {
  const { classes, cx } = useStyles();
  const showCount = count > 0 && count !== total;

  return (
    <div className={cx(classes.summary, className)}>
      <div className={classes.header}>
        <Typography
          variant='body1'
          fontWeight={400}
          color='text.primary'
          lineHeight={1.2}>
          {headerText}
        </Typography>
        <IconButton
          onClick={onCollapse}
          size='small'
          className={classes.button}>
          <ArrowCircleLeftIcon />
        </IconButton>
      </div>
      <div className={classes.body}>
        <div className={classes.counts}>
          {showCount && (
            <>
              <span className={classes.count}>{count.toLocaleString()} </span>
              <span className={classes.totalCount}> / </span>
            </>
          )}
          <span className={cx(showCount ? classes.totalCount : undefined)}>
            {total.toLocaleString()}
          </span>

          <span>Total</span>
        </div>
        {linkUrl && linkText && NavComponent && (
          <NavComponent to={linkUrl} className={classes.link}>
            {linkText}{' '}
            {Icon ? <Icon /> : <FaArrowRight className={classes.icon} />}
          </NavComponent>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  counts: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'flex-end',
  },
  totalCount: {
    ...(theme.typography.body2 as CSSProperties),
  },
  count: {
    fontWeight: 600,
  },
  summary: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.1)',
    width: '100%',
    zIndex: theme.zIndex.drawer,
  },
  header: {
    paddingInline: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 50,
    borderBottom: `1px solid ${theme.palette.divider}`,
    letterSpacing: 0.3,
  },
  body: {
    padding: theme.spacing(2),
    ...(theme.typography.h1 as CSSProperties),
    color: theme.palette.text.primary,
    fontWeight: 400,
  },

  button: {
    transform: 'rotate(180deg)',
  },
  link: {
    marginBlockStart: theme.spacing(1.5),
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    textDecoration: 'none',
    color: theme.palette.primary.main,
    ...(theme.typography.body2 as CSSProperties),
    fontWeight: 400,
    wordBreak: 'break-word',
    lineHeight: 1,
    letterSpacing: 0.01,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    width: '0.8em',
    height: '0.8em',
  },
}));

export interface SummaryProps {
  headerText: string;
  linkText?: string;
  linkUrl?: string;
  onCollapse?(): void;
  count?: number;
  total: number;
  // Not using NavLink directly since Storybook isn't providing a router
  navComponent?: typeof NavLink;
  summaryBackIcon?: IconType;
  className?: string;
}
