import {
  ScopeNames,
  buildScopesString,
  parseScopesString,
} from '@oxappsec/ox-unified-permissions/src/scopes';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import { snapshot } from 'valtio';
import settingsService from '../services';
import {
  updateUserRoles,
  updateUserScopesInStore,
} from '../store-actions/invite-user-store-actions';
import InviteMemberStore from '../stores/invite-user-store';
import { UpdateMemberInput } from '../types/members-types';
import { generateScopeConfig } from '../utils/members-utils';

export const updateSelectedMember = async () => {
  const { selectedMember } = snapshot(InviteMemberStore);
  if (!selectedMember) {
    return;
  }
  const scope = generateScopeConfig([
    {
      scopeName: ScopeNames.AppOwner,
      values: selectedMember.selectedAppOwnersScopeItems,
    },
    {
      scopeName: ScopeNames.Tags,
      values: selectedMember.selectedTagsScopeItems,
    },
  ]);

  const selectedMemberScopesString = scope ? buildScopesString(scope) : null;

  const selectedMemberRoles = selectedMember.roles;
  const selectedMemberRolesIds =
    selectedMemberRoles?.map(role => role.id) || [];
  const updateMemberInput: UpdateMemberInput = {
    roleIDs: selectedMemberRolesIds,
    userId: selectedMember.user_id,
    scopes: selectedMemberScopesString,
  };

  const updateResponse = await settingsService.updateMember.execute(
    updateMemberInput,
  );
  if (!updateResponse) {
    logger.error('Failed to update selected member', selectedMember);
    return;
  }

  updateUserRoles(updateResponse.userId, selectedMemberRoles);
  updateUserScopesInStore(updateResponse.userId, updateResponse.scopes);
  return updateResponse;
};

export const extractScopeItems = (
  scopesString: Nullable<string> | undefined,
  scopeName: ScopeNames,
): Nullable<string[]> => {
  const currentScopes = scopesString ? parseScopesString(scopesString) : [];
  const parseScopesConfig =
    currentScopes && currentScopes.find(scope => scope.scopeName === scopeName);
  return parseScopesConfig && parseScopesConfig.values
    ? parseScopesConfig.values.filter(item => !!item)
    : null;
};
