import { AppPages, navigate } from 'app-navigator';
import { logger } from 'logging-utils';
import { removeOrgFromLocalStorage } from '../../organizations/utils/local-storage-utils';
import {
  Auth0AccessDeniedTypes,
  Auth0ErrorDescriptions,
  Auth0InvalidRequestTypes,
} from '../AppGeneralTypes';

export const handleAccessDenied = (
  errDescription: string,
  loginWithRedirect: () => Promise<void>,
) => {
  const accessDeniedType = getAccessDeniedErrorType(errDescription);
  switch (accessDeniedType) {
    case Auth0AccessDeniedTypes.WrongOrg:
      removeOrgFromLocalStorage();
      navigate(AppPages.Home);
      break;
    case Auth0AccessDeniedTypes.BlockSignupForUninvited:
      removeOrgFromLocalStorage();
      navigate(AppPages.InviteOnly);
      return; // should not loginWithRedirect
    case Auth0AccessDeniedTypes.InvalidOrUnknownToken:
      logger.error('not implemented yet');
      return; // should not loginWithRedirect
    case Auth0AccessDeniedTypes.PleaseVerifyYourEmail:
      navigate(AppPages.EmailVerification);
      return; // should not loginWithRedirect
    case Auth0AccessDeniedTypes.Unknown:
      logger.error('not implemented yet');
      return; // should not loginWithRedirect
  }
  loginWithRedirect();
};

export const handleInvalidRequest = (
  errDescription: string,
  loginWithRedirect: () => Promise<void>,
) => {
  const accessDeniedType = getInvalidRequestErrorType(errDescription);
  switch (accessDeniedType) {
    case Auth0InvalidRequestTypes.OrgDoesNotExist:
      removeOrgFromLocalStorage();
      navigate(AppPages.InviteOnly);
      return; // should not loginWithRedirect
    case Auth0InvalidRequestTypes.Unknown:
      logger.error('not implemented yet');
      break;
  }
  loginWithRedirect();
};

const getInvalidRequestErrorType = (errDescription: string) => {
  if (errDescription.startsWith('parameter organization is invalid')) {
    return Auth0InvalidRequestTypes.OrgDoesNotExist;
  } else {
    return Auth0InvalidRequestTypes.Unknown;
  }
};

const getAccessDeniedErrorType = (errDescription: string) => {
  if (errDescription === Auth0ErrorDescriptions.NoSignupForUninvited) {
    return Auth0AccessDeniedTypes.BlockSignupForUninvited;
  } else if (
    errDescription.startsWith('user') &&
    errDescription.endsWith('organization')
  ) {
    return Auth0AccessDeniedTypes.WrongOrg;
  } else if (errDescription === Auth0AccessDeniedTypes.PleaseVerifyYourEmail) {
    return Auth0AccessDeniedTypes.PleaseVerifyYourEmail;
  } else if (
    errDescription === Auth0ErrorDescriptions.UnknownOrInvalidRefreshToken
  ) {
    return Auth0AccessDeniedTypes.InvalidOrUnknownToken;
  } else {
    return Auth0AccessDeniedTypes.Unknown;
  }
};
