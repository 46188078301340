import { IconButton, Tooltip } from '@mui/material';
import { FC, useCallback } from 'react';
import { MdPlusOne } from 'react-icons/md';
import { setFilter } from '../../../active-issues/actions/filter-issues-actions';

const FilterOption: FC<React.PropsWithChildren<CopyOptionsProps>> = ({
  value,
  isAvailable,
  filterType,
  id,
}) => {
  const addToFilter = useCallback(() => {
    if (value && !id) {
      setFilter(filterType, value);
    } else if (id) {
      setFilter(filterType, id);
    }
  }, [filterType, value, id]);

  return (
    <Tooltip arrow title={'Add to filter'} placement='top'>
      <span>
        <IconButton
          onClick={addToFilter}
          size='small'
          disabled={isAvailable}
          color='primary'>
          <MdPlusOne fontSize='1rem' />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export interface CopyOptionsProps {
  value?: string;
  isAvailable: boolean;
  filterType: string;
  id?: string;
}

export default FilterOption;
