import UserViewPreferencesStore from '../stores/user-view-preference-store';
import { PageSetting } from '../types';

export const setUserViewPreferences = (preferences: PageSetting[]) => {
  UserViewPreferencesStore.preferences = preferences;
};

export const setIsLoadedUserPreferences = (loaded: boolean) => {
  UserViewPreferencesStore.loaded = loaded;
};
