import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import { stopPropagation } from 'common-utils';
import { AggregationColumn, AggregationItem } from 'ox-common-types';
import { FC, memo, useCallback } from 'react';
import { InfoIcon } from '../InfoIcon';
import AggregatedContentResolver from './AggregatedContentResolver';

const AggregatedRow: FC<React.PropsWithChildren<AggregatedRowProps>> = memo(
  ({
    index,
    withSelection,
    item,
    columns,
    selected,
    toggle,
    selectedItem,
    onClick,
    exclusionCategory,
  }) => {
    const toggleCall = useCallback(
      () => toggle && toggle(index, selectedItem),
      [index, toggle, selectedItem],
    );
    const handleClick = useCallback(() => {
      if (onClick && item.additionalToolData) {
        onClick(item.additionalToolData);
      }
    }, [item.additionalToolData, onClick]);

    return (
      <TableRow key={index}>
        {withSelection && (
          <TableCell padding='checkbox'>
            <Checkbox
              checked={!!selected}
              onChange={toggleCall}
              onClick={stopPropagation}
              disabled={exclusionCategory === 'general'}
              color='primary'
            />
          </TableCell>
        )}
        {columns.map(column => (
          <TableCell sx={column.sx} key={`${column.key}${item.index}`}>
            <AggregatedContentResolver item={item} column={column} />
          </TableCell>
        ))}
        {item.additionalToolData && (
          <TableCell>
            <IconButton onClick={handleClick} size='small'>
              <InfoIcon title={''} customColor='#6837FF' />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  },
);

export interface AggregatedRowProps {
  index: number;
  withSelection?: boolean;
  item: AggregationItem;
  columns: AggregationColumn[];
  selected?: boolean;
  toggle?: (index: number, aggId: AggregationItem) => void;
  selectedItem: AggregationItem;
  onClick?: (additionalData: string) => void;
  exclusionCategory?: string;
}

export default AggregatedRow;
