import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';

import query from './get-artifact-vulnerabilities.gql';
import { ScaVulnerability } from '../../../issues/common/types/issues-types';

const getArtifactVulnerabilities = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getArtifactVulnerabilitiesInput: FetchVulnerabilitiesInput,
    ) => {
      try {
        const results = await client.query<{
          getArtifactVulnerabilities: ArtifactVulnerabilitiesResponse;
        }>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            getArtifactVulnerabilitiesInput,
          },
        });
        if (!results.data) {
          logger.error('Failed to fetch vulnerabilities');
          return null;
        }
        return results.data.getArtifactVulnerabilities;
      } catch (e) {
        logger.error('Failed to fetch vulnerabilities', e);
        return null;
      }
    },
  };
};

export interface FetchVulnerabilitiesInput {
  vulnDepIssues: string[];
  vulnDepBaseIssues: string[];
  vulnDepInstructionIssues: string[];
}

export interface ArtifactVulnerabilitiesResponse {
  vulnDepIssues: ScaVulnerability[];
  vulnDepBaseIssues: ScaVulnerability[];
  vulnDepInstructionIssues: ScaVulnerability[];
}

export default getArtifactVulnerabilities;
