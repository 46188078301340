import { Theme, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { createNewDate } from '../Aggregation/aggregation-utils';
import { CustomLink } from '../Aggregation/elements/CustomLink';
import { CicdItem } from './types/app-flow-types';

export const AppFlowItemInfo: FC<AppFlowItemInfoProps> = ({ info }) => {
  const { classes } = useStyles();
  const date = createNewDate(info.latestDate);

  return (
    <div className={classes.container}>
      <Typography
        variant='body1'
        color='text.secondary'
        mb={0.5}
        className={classes.spaceDown}>
        {info.system}
      </Typography>
      <Typography variant='body2' color='text.primary' component='div'>
        <div className={classes.container}>
          {date && date !== null ? (
            <span>
              Last pipeline run:
              {` ${formatDistanceToNow(date, { addSuffix: true })}`}
            </span>
          ) : (
            <span>Last pipeline run: {info.latestDate}</span>
          )}
        </div>
        {info.location.length > 0 && (
          <div>
            <Typography color='text.secondary' variant='body2'>
              Found in:
            </Typography>
            {info.location.map((locate, index) => {
              return (
                <CustomLink
                  href={locate.link}
                  value={`${index + 1}. ${locate.foundIn}`}
                  key={index}
                />
              );
            })}
          </div>
        )}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  spaceDown: {
    marginBlockEnd: theme.spacing(1),
  },
}));

interface AppFlowItemInfoProps {
  info: CicdItem;
}

export default AppFlowItemInfo;
