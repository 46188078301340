import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import { DateUnit, IDateRange } from 'ox-common-types';
import TopBarStore from '../stores/top-bar-store';
import { endOfDay } from 'date-fns';

export const calcDateRange = ({ unit, limit }: IDateRange) => {
  const endOFDay = endOfDay(new Date()).getTime();

  switch (unit) {
    case DateUnit.Day:
      return [subDays(endOFDay, limit).getTime(), endOFDay];
    case DateUnit.Week:
      return [subWeeks(endOFDay, limit).getTime(), endOFDay];
    case DateUnit.Month:
      return [subMonths(endOFDay, limit).getTime(), endOFDay];
    default:
      return [subWeeks(endOFDay, limit).getTime(), endOFDay];
  }
};

export const setLoadingTopBarActions = (isLoading: boolean) => {
  TopBarStore.isLoadingTopBarActions = isLoading;
};

export const setDateRange = (dateRange: IDateRange) => {
  TopBarStore.dateRange = dateRange;
};
