import { createAsyncState } from 'async-utils';
import { Nullable, Order } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import {
  ApplicationInfo,
  ApplicationRow,
  ApplicationsStoreCategories,
  MinifiedApp,
} from '../applications-types';

export const defaultOrderBy = {
  field: 'BusinessPriority',
  direction: Order.Desc,
};

export const initialApplicationsStoreValues = {
  applications: undefined,
  loading: createAsyncState(),
  offset: 0,
  total: 0,
  selectedAppId: null,
  selectedApp: undefined,
  orderField: defaultOrderBy.field,
  orderDirection: defaultOrderBy.direction,
  orderByCategory: undefined,
  selected: {},
  allApps: [],
  loadingSingleApp: false,
  lastDrawerHeight: undefined,
  filtersOpen: true,
  appFilterValue: '',
  applicationIssuesScore: undefined,
  totalIrrelevantApps: 0,
  bpSelectedApp: 0,
  selectedPosition: { pos: 0 },
  topOffset: 0,
  isLoadingApplicationIssuesScore: true,
  isApplicationDrawerOpen: true,
  selectedSourceControl: {},
  showLimitationModal: false,
  hoveredRowAppId: null,
  loadingDefineBranch: false,
};

const baseStore = createStore<AppFilterStoreState>(
  initialApplicationsStoreValues,
  'Applications Store',
);

const ApplicationsStore = derive(
  {
    categories: (get): ApplicationsStoreCategories | undefined => {
      const { applications } = get(baseStore);
      if (applications?.length) {
        return applications[applications.length - 1].categories.map(
          category => {
            const { categoryName, catId } = category;
            return {
              categoryName,
              catId,
            };
          },
        );
      }
    },
    allSelected: get => {
      const { selected, applications } = get(baseStore);
      const keys = Object.keys(selected);
      const fakeApps = applications
        ?.filter(app => app.fakeApp === true)
        .map(app => app.appName);
      const keysFilter = keys.filter(key => !fakeApps?.includes(key));

      return keysFilter && keysFilter.length > 0
        ? Object.keys(selected).every(key => selected[key])
        : false;
    },
    selectedCount: get => {
      const { selected } = get(baseStore);
      return Object.keys(selected).reduce(
        (acc, key) => (selected[key] ? acc + 1 : acc),
        0,
      );
    },
    isFakeAppSelected: get => {
      const { selected, applications } = get(baseStore);
      if (!applications) return false;
      const isFakeAppSelected = (applications as ApplicationRow[]).some(
        app => app.fakeApp && selected[app.appId],
      );
      return isFakeAppSelected;
    },
    selectedApps: get => {
      const { selected, applications } = get(baseStore);
      return applications?.filter(app => selected[app.appId]);
    },
    selectedAppsIds: get => {
      const { selected } = get(baseStore);
      return Object.keys(selected).filter(key => selected[key]);
    },
  },
  { proxy: baseStore },
);

export interface AppFilterStoreState {
  applications?: ApplicationRow[];
  loading: ReturnType<typeof createAsyncState>;
  selectedAppId: Nullable<string>;
  offset: number;
  total: number;
  orderField: string;
  orderDirection: Order;
  orderByCategory?: string;
  selected: { [key: string]: boolean };
  selectedSourceControl: { [key: string]: boolean };
  allApps: MinifiedApp[];
  selectedApp?: ApplicationInfo;
  loadingSingleApp: boolean;
  lastDrawerHeight?: number;
  filtersOpen: boolean;
  appFilterValue: string;
  applicationIssuesScore?: {
    data: { [key: string]: number[] | undefined };
    labels: number[];
  };
  totalIrrelevantApps: number;
  bpSelectedApp: number;
  selectedPosition: { pos: number };
  topOffset: number;
  isLoadingApplicationIssuesScore: boolean;
  isApplicationDrawerOpen: boolean;
  showLimitationModal: boolean;
  hoveredRowAppId: Nullable<string>;
  loadingDefineBranch: boolean;
}

export default ApplicationsStore;
