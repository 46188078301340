import { User } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { logger } from 'logging-utils';
import organizationService from '../services';
import { LogName } from '@oxappsec/audit-log-service';

export const auditUserLogin = async (
  user: User,
  logAction: LogName.Login | LogName.Logout,
) => {
  try {
    await organizationService.auditUserLogin.execute(logAction);
  } catch (err) {
    logger.error(
      `Failed to audit user login for ${user.name} with email ${user.email}`,
    );
    datadogLogs.logger.error(
      `Audit: Failed to audit user login for ${user.name} with email ${user.email}`,
    );
  }
};
