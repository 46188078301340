import { createStore } from 'store-utils';
import { VulnerabilitiesStoreState } from '../types';

const NotReachableVulnerabilitiesStore = createStore<VulnerabilitiesStoreState>(
  {
    offset: 0,
    total: 0,
    data: [],
    loading: false,
    searchValue: '',
    contextOptions: [],
    severityFactors: [],
  },
  'Not Reachable Vulnerabilities Store',
);

export default NotReachableVulnerabilitiesStore;
