import { snapshot } from 'valtio';

import ReasonFiltersStore, {
  ReasonFilterType,
} from '../stores/reason-filters-store';

export const addFilter = (filter: ReasonFilterType) => {
  ReasonFiltersStore.filters.push(filter);
};

export const removeFilter = (filter: ReasonFilterType) => {
  const { filters } = snapshot(ReasonFiltersStore);
  ReasonFiltersStore.filters = filters.filter(f => f !== filter);
};

export const updateFilters = (filter: ReasonFilterType) => {
  if (filtersExists(filter)) {
    removeFilter(filter);
    return;
  }
  addFilter(filter);
};

export const resetFilters = () => {
  ReasonFiltersStore.filters = [];
};

export const filtersExists = (filter: ReasonFilterType) => {
  const { filters } = snapshot(ReasonFiltersStore);
  return filters.includes(filter);
};

export const setLoadingFilters = (isLoading: boolean) => {
  ReasonFiltersStore.isLoadingFilters = isLoading;
};
