import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  AddCommentToIssueInput,
  CommentToIssueResponse,
} from '../../types/issues-types';
import mutation from './add-comment-to-issue.gql';

const addCommentToIssue = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      addCommentToIssue: AddCommentToIssueInput,
    ): Promise<CommentToIssueResponse> => {
      try {
        const results = await client.mutate<AddCommentIssueResponse>({
          mutation,
          variables: { input: addCommentToIssue },
        });
        if (results?.data) return results.data.addCommentToIssue;
      } catch (e) {
        logger.error('Faild to add comment to issue', e);
      }
      return { isUpdated: false };
    },
  };
};

interface AddCommentIssueResponse {
  addCommentToIssue: CommentToIssueResponse;
}

export default addCommentToIssue;
