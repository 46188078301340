import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import { FilterData } from 'ox-filter-utils';
import { GetSbomLibrariesInput } from '../get-libraries/get-libraries-service';
import query from './get-sbom-filters-lazy.gql';
import { openSnackbar } from 'snackbar-utils';

const fetchSbomFiltersLazy = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getApplicationsSbom: GetSbomLibrariesInput,
      cache: boolean = true,
    ) => {
      try {
        const results = await client.query<FetchLibrariesFiltersResponse>({
          query,
          variables: { getApplicationsSbom },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });
        if (!results.data) return null;
        return results.data.getSbomLibrariesFiltersLazy;
      } catch (e) {
        openSnackbar(`Failed to get filter items`, {
          variant: 'error',
        });
        return null;
      }
    },
  };
};

export interface FetchLibrariesFiltersResponse {
  getSbomLibrariesFiltersLazy: {
    filters: FilterData[];
  };
}

export default fetchSbomFiltersLazy;
