import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { TicketingVendor } from '../../types/ticketing-types';
import mutation from './unlink-ticket.gql';

const unlinkTicket = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (unlinkTicketsInput: UnlinkTicketsInput) => {
      try {
        const results = await client.mutate<UnlinkTicketsResponse>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { unlinkTicketsInput },
        });
        return results.data?.unlinkTickets;
      } catch (error) {
        logger.error('Failed to unlink ticket', error);
        return null;
      }
    },
  };
};

export interface UnlinkTicketsInput {
  issueId: string;
  ticketIds: string[];
  ticketingVendor: TicketingVendor;
}

export interface UnlinkTicketsResponse {
  unlinkTickets: {
    acknowledged: boolean;
    deletedCount: number;
  };
}
export default unlinkTicket;
