import { FC } from 'react';
import { Box, Typography } from '@mui/material';

const DevIssueHeaderCell: FC<
  React.PropsWithChildren<DevIssueHeaderCellProps>
> = ({ title, children }) => {
  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Typography variant='body2' color='text.secondary'>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export interface DevIssueHeaderCellProps {
  title: string;
}

export default DevIssueHeaderCell;
