import { createStore, derive } from 'store-utils';
import { Nullable } from 'ox-common-types';
import { GlobalDataViewSelector } from '../../DataViewSelector/global-data-view-selector-store';

const initialAddFormStoreValues = {
  isFirstLoad: true,
};

const baseStore = createStore<IPersistParamsState>(
  initialAddFormStoreValues,
  'Persist Params Store',
);

const PersistParamsStore = derive(
  {
    persistParams: (get): Nullable<IPersistParams> => {
      const { isFirstLoad } = get(baseStore);

      const { selectedTagIds, selectedAppOwnersEmails } = get(
        GlobalDataViewSelector,
      );
      // Add more params here

      if (isFirstLoad) {
        // If this is the first load, don't persist any params because no params in the store yet.
        baseStore.isFirstLoad = false;
        return null;
      } else {
        const owners = selectedAppOwnersEmails.join(',') || null;
        const tagIds = selectedTagIds.join(',') || null;
        // The key is the param name, the value is the param value
        return { owners, tagIds };
      }
    },
  },
  { proxy: baseStore },
);

interface IPersistParams {
  [key: string]: Nullable<string>;
}

export interface IPersistParamsState {
  isFirstLoad: boolean;
}

export default PersistParamsStore;
