import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';
import {
  GetSlackUsersAndChannelList,
  GetSlackUsersAndChannelsInput,
} from '../../types/slack-types';
import query from './get-users-and-public-channels.gql';

const getUsersAndPublicChannels = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<GetSlackUsersAndChannelsInput, Nullable<QueryResponse>> => {
  return {
    execute: async (
      input: GetSlackUsersAndChannelsInput,
    ): Promise<Nullable<QueryResponse>> => {
      try {
        const results = await client.query<QueryResponse>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: {
            input,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to get slack channels', error);
        return null;
      }
    },
  };
};

export interface QueryResponse {
  getSlackUsersAndChannelList: GetSlackUsersAndChannelList;
}

export default getUsersAndPublicChannels;
