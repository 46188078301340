import { memo, useCallback } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, Typography } from '@mui/material';
import { CiSquareMinus, CiSquarePlus } from 'react-icons/ci';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/system';
import { toggleBranch } from '../actions/issue-graph-actions';
import { useSpring, animated } from 'react-spring';
import { useNode } from '../hooks/useNode';
import { useNodeText } from '../hooks/useNodeText';
import useCommonGraphStore from '../stores/common-graph-store';

export default memo(({ data, id, isConnectable }: NodeProps) => {
  const { collapsedBranches, highlightedNodes } = useCommonGraphStore();
  const grayedOut = highlightedNodes?.length
    ? !highlightedNodes.includes(id)
    : false;
  const { Icon, iconColor } = useNode(data.type, data);
  const { title, description } = useNodeText(data.type, data.metadata);
  const { classes } = useStyles();
  const style = useSpring({
    from: { opacity: 0, transform: 'scale(0)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { duration: 500 },
  });

  const onExpandClick = useCallback(() => {
    toggleBranch(id, data.metadata.graphPart);
  }, [data.metadata.graphPart, id]);

  return (
    <div style={{ opacity: grayedOut ? 0.2 : 1 }}>
      <animated.div style={style}>
        <Handle
          type='target'
          position={Position.Top}
          style={{ visibility: 'hidden' }}
          isConnectable={isConnectable}
        />
        <Handle
          type='target'
          position={Position.Right}
          style={{ visibility: 'hidden' }}
          isConnectable={isConnectable}
        />
        {data.childrenCount && (
          <IconButton
            size='small'
            onClick={onExpandClick}
            className={classes.expandIcon}>
            {collapsedBranches[id] ? <CiSquarePlus /> : <CiSquareMinus />}
          </IconButton>
        )}
        <div className={classes.container}>
          {Icon && <Icon fill={iconColor} width={80} height={80} />}
          {title && (
            <div className={classes.textContainer}>
              {description && (
                <Typography noWrap variant='caption' color='text.secondary'>
                  {description}
                </Typography>
              )}
              <Typography
                fontSize={16}
                noWrap
                variant='body1'
                color='text.primary'>
                {title}
              </Typography>
            </div>
          )}
        </div>

        <Handle
          type='source'
          style={{ visibility: 'hidden' }}
          position={Position.Bottom}
          isConnectable={isConnectable}
        />
      </animated.div>
    </div>
  );
});

const useStyles = makeStyles()((theme: Theme) => ({
  expandIcon: {
    position: 'absolute',
    top: '5%',
    left: '90%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    position: 'absolute',
    top: '103%',
    marginTop: 1,
    maxWidth: 100,
    display: 'inline-flex',
    backgroundColor:
      theme.palette.mode === 'light'
        ? '#edeef4'
        : theme.palette.background.paper,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
}));
