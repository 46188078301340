import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';
import { SelectedIssuesAggsItemsRes } from '../../types/issues-types';
import query from './get-selected-issues-aggs.gql';

const fetchSelectedIssuesAggsItems = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<Array<string>, Nullable<SelectedIssuesAggsItemsRes[]>> => {
  return {
    execute: async (issueIds: Array<string>) => {
      const results = await client.query<QueryResponse>({
        query,
        fetchPolicy: ApolloClientCache.NetworkOnly,
        variables: { issueIds },
      });

      if (!results.data) return null;
      return results.data.getIssuesWithIssueIds;
    },
  };
};

interface QueryResponse {
  getIssuesWithIssueIds: SelectedIssuesAggsItemsRes[];
}

export default fetchSelectedIssuesAggsItems;
