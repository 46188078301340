import { AppPages, navigate } from 'app-navigator';
import { FilterData, FilterItems } from 'ox-filter-utils';
import ApiInventoryFiltersStore from './api-inventory-filters-store';
import { loadApiInventories } from '../actions/api-inventory-actions';
import { getApiInventoryOpenFilterItems } from '../actions/api-inventory-filters-actions';
import { debounce } from 'lodash-es';

export const setTotalInventoryFilters = (totalFilters: number) => {
  ApiInventoryFiltersStore.totalFilters = totalFilters;
};

export const clearFilters = () => {
  ApiInventoryFiltersStore.filterBy = {};
  ApiInventoryFiltersStore.numberOfFilters = 0;
  navigate(AppPages.ApiInventory);
  loadApiInventories({ update: true });
  getApiInventoryOpenFilterItems();
};

export const handleSearchFilterType = debounce(
  (searchInput: string, filterType: string) => {
    setSearchValue(filterType, searchInput);
    getApiInventoryOpenFilterItems();
  },
  500,
);

const setSearchValue = (type: string, searchValue: string) => {
  const index = ApiInventoryFiltersStore.searchValues.findIndex(
    key => key.fieldName === type,
  );
  if (index !== -1) {
    ApiInventoryFiltersStore.searchValues[index].value = searchValue;
  } else {
    ApiInventoryFiltersStore.searchValues.push({
      fieldName: type,
      value: searchValue,
    });
  }
};

export const setLazyFilters = (
  resultsFilters: FilterData[],
  filterItems: FilterItems,
) => {
  Object.keys(filterItems).forEach(key => {
    const selectedResultFilterItem = resultsFilters.find(
      item => item.type === key,
    );
    if (
      ApiInventoryFiltersStore.lazyFilters &&
      ApiInventoryFiltersStore.lazyFilters[key] &&
      selectedResultFilterItem
    ) {
      ApiInventoryFiltersStore.lazyFilters[key].items =
        selectedResultFilterItem.items;
    }
  });
};
