import { createSimpleAsyncAction } from 'async-utils';
import { isEmpty } from 'lodash-es';
import { Nullable } from 'ox-common-types';
import {
  ConditionalFilter,
  FilterData,
  FilterItems,
  getOpenFilterItemIds,
} from 'ox-filter-utils';
import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../../app/stores/top-bar-store';
import issuesService from '../../common/services';
import createIssueStoreActions from '../../common/store-actions/create-issue-store-actions';
import { LoadIssuesFiltersParams } from '../../common/types/issues-types';
import IssuesStore, { initialIssuesState } from '../stores/issues-store';

export const setDataToFiltersInStore = (
  results: FilterData[],
  filterItems: FilterItems,
) => {
  Object.keys(filterItems).forEach(key => {
    const selectedResultFilterItem = results.find(item => item.type === key);
    if (
      IssuesStore.filterItems &&
      IssuesStore.filterItems[key] &&
      selectedResultFilterItem
    ) {
      IssuesStore.filterItems[key].items = selectedResultFilterItem.items;
    }
  });
};

export const onOpenFilterItem = createSimpleAsyncAction(
  async (
    type?: Nullable<string>,
    isOpen?: boolean,
    params?: LoadIssuesFiltersParams,
  ) => await getIssuesOpenFilterItemsFromServer(type, isOpen, params),
  {
    asyncState: IssuesStore.loadingSingleFilter,
    errorMessage: 'Failed to load filter',
  },
);

export const getIssuesOpenFilterItems = createSimpleAsyncAction(
  async (
    type?: Nullable<string>,
    isOpen?: boolean,
    params?: LoadIssuesFiltersParams,
  ) => await getIssuesOpenFilterItemsFromServer(type, isOpen, params),
  {
    asyncState: IssuesStore.loadingFilters,
    errorMessage: 'Failed to load issues filters',
  },
);
export const getSelectedIssuesIds = () => {
  const { selected } = snapshot(IssuesStore);
  return Object.keys(selected);
};

const getIssuesOpenFilterItemsFromServer = async (
  type?: Nullable<string>,
  isOpen?: boolean,
  params?: LoadIssuesFiltersParams,
) => {
  const currentFilterItem =
    IssuesStore.filterItems && type && IssuesStore.filterItems[type];

  if (currentFilterItem) {
    currentFilterItem.isOpen = Boolean(isOpen);
    currentFilterItem.isLoading = Boolean(isOpen);
  }
  const { cache = true, limit = 100, offset } = params || {};
  const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
    GlobalDataViewSelector,
  );
  const { filterItems, conditionalFilters } = snapshot(IssuesStore);
  const {
    offset: storeOffset,
    filterIssuesBy,
    searchValues,
    topLevelSearchValue,
  } = snapshot(IssuesStore);
  const { dateRange } = snapshot(TopBarStore);
  const [from, to] = calcDateRange(dateRange);
  const filterItem = filterItems && type && filterItems[type];
  const openFilterItemIds = filterItems
    ? getOpenFilterItemIds(filterItems)
    : [];
  const filtersApiParams = {
    owners: selectedAppOwnersEmails,
    tagIds: selectedTagIds,
    offset: offset || storeOffset,
    limit,
    dateRange: { from, to },
    search: searchValues,
    openItems: openFilterItemIds,
    topLevelSearch: topLevelSearchValue,
  };

  const isFiltersExist = !isEmpty(filterIssuesBy);
  const isConditionalFiltersExist = !isEmpty(conditionalFilters);

  const currentFilterItemIsClosed = filterItem && !filterItem.isOpen;
  const allFiltersClosed = !openFilterItemIds || openFilterItemIds.length === 0;

  if (allFiltersClosed || currentFilterItemIsClosed) {
    if (currentFilterItem) {
      currentFilterItem.isLoading = false;
    }
    return;
  }

  if (isConditionalFiltersExist) {
    filtersApiParams['conditionalFilters'] = conditionalFilters;
  } else if (isFiltersExist) {
    filtersApiParams['filters'] = filterIssuesBy;
  }

  const results = await issuesService.getIssuesConditionalFiltersLazy.execute(
    filtersApiParams,
    cache,
  );

  if (currentFilterItem) {
    currentFilterItem.isLoading = false;
  }
  if (results?.filters && filterItems) {
    setDataToFiltersInStore(results.filters, filterItems);
  }
};

const issueStoreActions = createIssueStoreActions(
  IssuesStore,
  initialIssuesState,
);

export const setConditionalFiltersInStore = (
  conditionalFilters: ConditionalFilter[],
) => {
  IssuesStore.conditionalFilters = conditionalFilters;
};

export const setFilterIssuesByInStore = (filters: {}) => {
  IssuesStore.filterIssuesBy = filters;
};

export const setLoadingJiraTicketsStatus = (loading: boolean) => {
  IssuesStore.loadingJiraTicketsStatus = loading;
};

export const setTopLevelSearchValue = (searchInputValue: string) => {
  IssuesStore.topLevelSearchValue = searchInputValue;
};

export const setHoveredRowIssueId = (issueId: Nullable<string>) => {
  IssuesStore.hoveredRowIssueId = issueId;
};

// https://stackoverflow.com/questions/29844074/es6-export-all-values-from-object
export const setStoreIssues = issueStoreActions.setStoreIssues;
export const setStoreTotalIssues = issueStoreActions.setStoreTotalIssues;
export const setStoreTotalIssuesFiltered =
  issueStoreActions.setStoreTotalIssuesFiltered;
export const setStoreIssuesOffset = issueStoreActions.setStoreIssuesOffset;
export const setStoreIssuesFiltersType =
  issueStoreActions.setStoreIssuesFiltersType;
export const setStoreIssuesFiltersTypeSearch =
  issueStoreActions.setStoreIssuesFiltersTypeSearch;
export const setModalVisibility = issueStoreActions.setModalVisibility;
export const closeIssuesDrawer = issueStoreActions.closeIssuesDrawer;
export const closeIssueModalWithoutNavigate =
  issueStoreActions.closeIssueModalWithoutNavigate;
export const openIssueModal = issueStoreActions.openIssueModal;
export const setSelectedIssue = issueStoreActions.setSelectedIssue;
export const toggleFiltersPanel = issueStoreActions.toggleFiltersPanel;
export const clearFilters = issueStoreActions.clearFilters;
export const clearStore = issueStoreActions.clearStore;
export const setPolicyDisableModal = issueStoreActions.setPolicyDisableModal;
export const setApplicationIrrelevantModal =
  issueStoreActions.setApplicationIrrelevantModal;
export const setShowCreateJiraTicketDialog =
  issueStoreActions.setShowCreateJiraTicketDialog;
export const setShowCreateBulkJiraTicketDialog =
  issueStoreActions.setShowCreateBulkJiraTicketDialog;
export const setShowLinkJiraTicketDialog =
  issueStoreActions.setShowLinkJiraTicketDialog;
export const setShowUnlinkJiraTicketDialog =
  issueStoreActions.setShowUnlinkJiraTicketDialog;
export const setShowSendSlackMessageDialog =
  issueStoreActions.setShowSendSlackMessageDialog;
export const openQuickFixModal = issueStoreActions.openQuickFixModal;
export const setQuickFixModalVisibility =
  issueStoreActions.setQuickFixModalVisibility;
export const setPolicyFixModal = issueStoreActions.setPolicyFixModal;
export const setAutoFixOnIssue = issueStoreActions.setAutoFixOnIssue;
export const setPrDeatils = issueStoreActions.setPrDeatils;
export const openQuickFixPermissionDeniedModal =
  issueStoreActions.openQuickFixPermissionDeniedModal;
export const setQuickFixPermissionDeniedModalVisibility =
  issueStoreActions.setQuickFixPermissionDeniedModalVisibility;
export const setFilesDiffModalVisibility =
  issueStoreActions.setFilesDiffModalVisibility;
export const setSliderValuePriority = issueStoreActions.setSliderValuePriority;
export const setLastDrawerHeight = issueStoreActions.setLastDrawerHeight;
export const pushSlackNotificationToRawIssue =
  issueStoreActions.pushSlackNotificationToRawIssue;
export const setSelectedIssueId = issueStoreActions.setSelectedIssueId;
export const setExclusionIssueComment =
  issueStoreActions.setExclusionIssueComment;
export const setExclusionIssueCommentString =
  issueStoreActions.setExclusionIssueCommentString;
export const setExclusionPolicyComment =
  issueStoreActions.setExclusionPolicyComment;
export const setExclusionAppComment = issueStoreActions.setExclusionAppComment;
export const setFalsePostiveIssueComment =
  issueStoreActions.setFalsePostiveIssueComment;
export const addJiraTicketToRawIssue =
  issueStoreActions.addJiraTicketToRawIssue;
export const setJiraTicketsToRawIssue =
  issueStoreActions.setJiraTicketsToRawIssue;
export const setRowIssueTickets = issueStoreActions.setRowIssueTickets;
export const addJiraTicketToSelectedIssue =
  issueStoreActions.addJiraTicketToSelectedIssue;
export const addBulkJiraTicketsToSelectedIssues =
  issueStoreActions.addBulkJiraTicketsToSelectedIssues;
export const removeJiraTicketsFromSelectedIssue =
  issueStoreActions.removeJiraTicketsFromSelectedIssue;
export const removeJiraTicketsFromRawIssue =
  issueStoreActions.removeJiraTicketsFromRawIssue;
export const setDrawerJiraTicketsStatus =
  issueStoreActions.setDrawerJiraTicketsStatus;
export const setDrawerTicketsStatus = issueStoreActions.setDrawerTicketsStatus;
export const setLoadingIssue = issueStoreActions.setLoadingIssue;
export const setStoreIssuesStatistics =
  issueStoreActions.setStoreIssuesStatistics;
export const setStoreTotalResolvedIssues =
  issueStoreActions.setStoreTotalResolvedIssues;
export const setStoreTotalActiveIssues =
  issueStoreActions.setStoreTotalActiveIssues;
export const setCommentModal = issueStoreActions.setCommentModal;
export const setIssueCommentTemp = issueStoreActions.setIssueCommentTemp;
export const setIssueComment = issueStoreActions.setIssueComment;
export const setIssueCommentTempValue =
  issueStoreActions.setIssueCommentTempValue;
export const setLoadingJiraStatusUpdate =
  issueStoreActions.setLoadingJiraStatusUpdate;
export const setSearchValues = issueStoreActions.setSearchValues;
export const clearFilter = issueStoreActions.clearFilter;
export const clearConditionalFilter = issueStoreActions.clearConditionalFilter;
export const setSnoozeIssueExpiredDate =
  issueStoreActions.setSnoozeIssueExpiredDate;
export const setSearchValue = issueStoreActions.setSearchValue;
export const onSearchValueChange = issueStoreActions.onSearchValueChange;
export const setShowConfirmDownloadCSVDialog =
  issueStoreActions.setShowConfirmDownloadCSVDialog;
export const setChangeSeverityModal = issueStoreActions.setChangeSeverityModal;
export const setShowBulkChangeSeverityModal =
  issueStoreActions.setShowBulkChangeSeverityModal;
export const onOpenBulkChangeSeverityModal =
  issueStoreActions.onOpenBulkChangeSeverityModal;
export const onOpenBulkIssuesCommentModal =
  issueStoreActions.onOpenBulkIssuesCommentModal;
export const setShowBulkIssueCommentModal =
  issueStoreActions.setShowBulkIssueCommentModal;
export const setChangeSeverity = issueStoreActions.setChangeSeverity;
export const setFalsePositiveToRawIssue =
  issueStoreActions.setFalsePositiveToRawIssue;
export const selectIssue = issueStoreActions.selectIssue;
export const toggleSelectAllIssues = issueStoreActions.toggleSelectAllIssues;
export const resetSelected = issueStoreActions.resetSelected;
export const setIsLoadingApplicationStatistics =
  issueStoreActions.setIsLoadingApplicationStatistics;
