import { createStore } from 'store-utils';

const AppCuesStore = createStore<AppCuesStoreState>(
  {
    firstResultsEventFired: false,
  },
  'App Cues Store',
);

interface AppCuesStoreState {
  firstResultsEventFired: boolean;
}

export default AppCuesStore;
