import { SeverityEnum, severities } from 'severity-utils';
import { snapshot } from 'valtio';
import { ResolvedIssuesOverviewResponse } from '../services/resolved-issues-overview/resolved-issues-overview';
import { MttrItem } from '../stores/resolved-issues-overview-store';
import ResolvedIssuesStore from '../stores/resolved-issues-store';

const includedSeverities = [
  SeverityEnum.Appox,
  SeverityEnum.Critical,
  SeverityEnum.High,
  SeverityEnum.Medium,
];

const resolveSeverityName = (key: string) => {
  return severities.find(s => s.key === key)?.title;
};

type MttrResponse =
  ResolvedIssuesOverviewResponse['getMttrForResolvedIssues']['mttrDays'];

export const createMttrItems = (mttrResponse: MttrResponse): MttrItem[] => {
  const { issuesFiltersType } = snapshot(ResolvedIssuesStore);
  const existingSeverities = issuesFiltersType?.criticality;

  return (
    severities
      // filter by included severites
      .filter(s => includedSeverities.includes(s.key as SeverityEnum))
      // filter by existing severities (based on filters set by the user)

      .map(s => {
        const isExistingSeverity = existingSeverities?.some(
          es => es.label === resolveSeverityName(s.key),
        );
        return {
          severityKey: s.key,
          severityLabel: s.title,
          days: isExistingSeverity ? mttrResponse[s.key] : undefined,
        };
      })
  );
};
