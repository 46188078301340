import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { BulkIssuesCommentInput } from '../../types/issues-types';
import mutation from './upsert-bulk-comment-to-Issues.gql';
import { Nullable } from '@oxappsec/ox-consolidated-gpt-types';

const upsertBulkCommentToIssues = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      bulkIssuesCommentInput: BulkIssuesCommentInput,
    ): Promise<Nullable<boolean>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: { input: bulkIssuesCommentInput },
        });
        if (results?.data) return results.data.upsertBulkCommentToIssues;
      } catch (e) {
        logger.error('Faild to upsert bulk comment for selected issues', e);
      }
      return null;
    },
  };
};

interface MutationResponse {
  upsertBulkCommentToIssues: boolean;
}

export default upsertBulkCommentToIssues;
