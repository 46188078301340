import { Typography } from '@mui/material';
import { AppSpinner } from 'common-components';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import ScanStore from '../../../new-scan/store/scan-store';
import { fetchIssueChatGptContent } from '../actions/chat-gpt-actions';
import IssueCardChatGPT from '../components/IssueCard/IssueCardChatGPT';
import { FetchChatGptContentInput } from '../services/fetch-chat-gpt-content/fetch-chat-gpt-content-service';
import ChatGPTStore from '../stores/chat-gpt-store';
import { splitGptResponse, wrapCodeTags } from '../utils/gpt-utils';
import { setGptError } from '../store-actions/chat-gpt-store-actions';

const ChatGptContainer: FC<
  React.PropsWithChildren<
    FetchChatGptContentInput & { issueLang?: string; isPrAvailable?: boolean }
  >
> = props => {
  const { questionsAndAnswersPerIssue, loading, error } = useSnapshot(
    ChatGPTStore,
    {
      sync: true,
    },
  );
  const { isDemoEnabled } = useSnapshot(ScanStore);
  const { issueLang, issueId } = props;
  const [gptContentParts, setGptContentParts] = useState<
    { question: string; answer: string | ReactElement }[]
  >([]);

  useEffect(() => {
    setGptError(false);
  }, [issueId]);

  useEffect(() => {
    if (!questionsAndAnswersPerIssue[props.issueId] && !error) {
      const fetchGPTInput = { ...props };
      delete fetchGPTInput.issueLang;
      delete fetchGPTInput.isPrAvailable;
      fetchIssueChatGptContent(fetchGPTInput);
    } else if (!!questionsAndAnswersPerIssue[props.issueId] && !error) {
      const { answer, question } = questionsAndAnswersPerIssue[props.issueId];
      const questionsAndAnswers = splitGptResponse(question, answer);
      const wrappedCodeTagsAnswers = questionsAndAnswers.map(
        (contentObj, i) => {
          return {
            question: contentObj.question,
            answer: wrapCodeTags(
              contentObj.answer,
              issueLang,
              props.isPrAvailable &&
                i === questionsAndAnswers.length - 1 &&
                isDemoEnabled,
            ),
          };
        },
      );
      setGptContentParts(wrappedCodeTagsAnswers);
    }
  }, [error, isDemoEnabled, issueLang, props, questionsAndAnswersPerIssue]);

  const { answer, question } = questionsAndAnswersPerIssue[props.issueId] || {};

  if (error) {
    return (
      <Typography variant='body1' color='error' textAlign='center'>
        Oops! Something went wrong... Please try again later
      </Typography>
    );
  }
  const isLoading = loading && !questionsAndAnswersPerIssue[props.issueId];

  return isLoading ? (
    <AppSpinner text='Loading..' />
  ) : (
    <div style={{ margin: -16 }}>
      <IssueCardChatGPT
        questionsAndAnswers={gptContentParts}
        firstQuestion={question}
        firstAnswer={answer ? wrapCodeTags(answer, issueLang) : ''}
      />
    </div>
  );
};

export default ChatGptContainer;
