import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { WhatsNewPoller } from 'whats-new-ui';
import CreateOrganizationContainer from '../../organizations/containers/CreateOrganizationContainer';
import PageNotFound from '../pages/page-not-found/PageNotFound';
import ChangeTitleOnRouteChange from './routing/ChangeTitleOnRouteChange';
import { PageLoader } from './routing/PageLoader';
import PrivateRoute from './routing/PrivateRoute';
import PublicRoute from './routing/PublicRoute';
import { allRoutes } from './routing/all-routes';
import Auth0ErrorHandler from './user/Auth0ErrorHandler';
import PersistParamsOnPathNameChange from './routing/PersistParamsOnPathNameChange';

export const AppRoutes: FC = () => {
  return (
    <>
      <Auth0ErrorHandler>
        <Routes>
          <Route element={<PublicRoute />}>
            {allRoutes.public.map(({ path, element: Page }) => (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <Page />
                  </Suspense>
                }
              />
            ))}
          </Route>

          <Route element={<PrivateRoute />}>
            {allRoutes.private.map(({ path, element: Page }) => (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <Page />
                  </Suspense>
                }
              />
            ))}
          </Route>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Auth0ErrorHandler>

      <ChangeTitleOnRouteChange />
      <CreateOrganizationContainer />
      <PersistParamsOnPathNameChange />
      <WhatsNewPoller />
    </>
  );
};
