import { orgManagementClient } from 'api-clients';
import { settingsClient } from 'api-clients';
import { reportClient } from 'api-clients';

import getSettings from './get-settings/get-settings';
import getSingleSettings from './get-single-settings/get-single-settings';
import updateSettings from './update-settings/update-settings';
import getSettingsMap from './get-settings-map/get-settings-map';
import resetSettings from './reset-settings/reset-settings';
import deleteAllMembers from './delete-all-members/delete-all-members';
import deleteOrganization from './delete-organization/delete-organization';
import defineAppBranchesToScan from './define-app-branches-to-scan/define-app-branches-to-scan';

const settingsService = {
  deleteOrganization: deleteOrganization(orgManagementClient),
  deleteAllMembers: deleteAllMembers(orgManagementClient),
  updateSettings: updateSettings(settingsClient),
  resetSettings: resetSettings(settingsClient),
  getSingleSettings: getSingleSettings(settingsClient),
  getSettings: getSettings(settingsClient),
  getSettingsMap: getSettingsMap(settingsClient),
  defineAppBranchesToScan: defineAppBranchesToScan(reportClient),
};

export default settingsService;
