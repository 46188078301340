import { AppPages } from 'app-navigator';
import { Nullable } from 'ox-common-types';
import { FC, useCallback, useState } from 'react';
import { Panel } from 'reactflow';
import VulnerabilityGraph from '../../../../IssueItem/ScaVulnerabilitiesTable/VulnerabilityGraph';
import VulnerabilitySwitcher from '../../../../IssueItem/ScaVulnerabilitiesTable/VulnerabilitySwitcher';
import {
  DependencyEdge,
  DependencyNode,
} from '../../../../../types/issues-types';

const ScaDependencyGraph: FC<
  React.PropsWithChildren<ScaDependencyGraphProps>
> = ({ dependencyGraph, appId }) => {
  const [showAll, setShowAll] = useState(false);
  const toggleAll = useCallback(() => {
    setShowAll(prev => !prev);
  }, []);
  const onNodeClick = useCallback(
    (_, node) => {
      const url = encodeURI(`${AppPages.Sbom}?sbomId=${appId}@@${node.id}`);
      window.open(url, '_blank');
    },
    [appId],
  );

  return (
    <div key='Dependency Graph' style={{ height: '100%', width: '100%' }}>
      {dependencyGraph?.nodes?.length ? (
        <VulnerabilityGraph
          onNodeClick={onNodeClick}
          nodeType={showAll ? 'tb' : 'lr'}
          nodes={showAll ? dependencyGraph.allNodes : dependencyGraph.nodes}
          edges={showAll ? dependencyGraph.allEdges : dependencyGraph.edges}>
          <Panel position='top-left'>
            <VulnerabilitySwitcher checked={showAll} handleChange={toggleAll} />
          </Panel>
        </VulnerabilityGraph>
      ) : null}
    </div>
  );
};

export interface ScaDependencyGraphProps {
  dependencyGraph: Nullable<{
    nodes: DependencyNode[];
    edges: DependencyEdge[];
    allNodes: DependencyNode[];
    allEdges: DependencyEdge[];
  }>;
  appId: string;
}

export default ScaDependencyGraph;
