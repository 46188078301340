import { exclusionClient, reportClient } from 'api-clients';
import getIrrelevantAppsResult from './get-irrelevant-apps-results/get-irrelevant-apps-results';
import getApplicationsFiltersLazy from '../../applications/services/get-applications-filters-lazy/get-applications-filters-lazy';
import removeAppsExclusion from './remove-apps-exclusion/remove-apps-exclusion';

const irrelevantAppsService = {
  getIrrelevantApps: getIrrelevantAppsResult(reportClient),
  getIrrelevantAppsFilters: getApplicationsFiltersLazy(reportClient),
  removeAppsExclusion: removeAppsExclusion(exclusionClient),
};

export default irrelevantAppsService;
