import { createStore, derive } from 'store-utils';
import { Phase } from '../scan-types';

export const initialState = {
  scanned: 0,
  total: 0,
  scanProgressItems: [],
};

const baseStore = createStore<GradualStoreState>(initialState, 'GradualStore');

const GradualStore = derive(
  {
    info: get => {
      const { scanned, total } = get(baseStore);
      let text = 'Initializing gradual updates...';
      if (total > 0) {
        if (scanned === total) {
          text = `Finalizing scan results for ${total} apps`;
        } else {
          text = `${scanned} out of ${total} applications
          were scanned`;
        }
      }
      return {
        text,
        value: total ? (scanned * 100) / total : 0,
      };
    },
  },
  {
    proxy: baseStore,
  },
);

export interface GradualStoreState {
  scanned: number;
  total: number;
  scanProgressItems: { text: string; percentage: number; phase: Phase }[];
}
export default GradualStore;
