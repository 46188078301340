import { createStore, derive } from 'store-utils';
import { snapshot } from 'valtio';
import { CellIndex } from '../types/oscar-hover-types';
import {
  createHardDimmedColumnsArray,
  createSoftDimmedColumnsArray,
} from '../utils/oscar-hover-utils';
import OscarStore from './oscar-store';

const baseStore = createStore<OscarHoverStoreState>(
  {
    hoverCell: undefined,
  },
  'Oscar Hover Store',
);

const OscarHoverStore = derive(
  {
    softDimmedColumnIndexes: get => {
      const { hoverCell } = get(baseStore);
      const { tactics } = snapshot(OscarStore);
      if (!hoverCell) {
        return [];
      }
      return createSoftDimmedColumnsArray(hoverCell, tactics.length);
    },
    hardDimmedColumnIndexes: get => {
      const { hoverCell } = get(baseStore);
      if (!hoverCell) {
        return [];
      }
      return createHardDimmedColumnsArray(hoverCell);
    },
  },
  { proxy: baseStore },
);

interface OscarHoverStoreState {
  hoverCell?: CellIndex;
}

export default OscarHoverStore;
