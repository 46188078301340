import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchIssuesInput,
  FetchIssuesFiltersLazyResponse,
} from '../../types/issues-types';
import query from './get-issues-filters-lazy.gql';
import { openSnackbar } from 'snackbar-utils';

const fetchIssuesFiltersLazy = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (getIssuesInput: FetchIssuesInput, cache = true) => {
      try {
        const results = await client.query<FetchIssuesFiltersLazyResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NoCache,
          variables: { getIssuesInput: getIssuesInput },
        });

        if (!results.data) return null;
        return results.data.getIssuesFiltersLazyV2;
      } catch (e) {
        logger.error('Failed to fetch issues filters', e);
        openSnackbar('Failed to fetch issues filters', {
          variant: 'error',
        });
        return null;
      }
    },
  };
};

export default fetchIssuesFiltersLazy;
