import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import NodeTooltip from '../NodeTooltip';
import { Nullable } from 'ox-common-types';

const Maintanance: FC<React.PropsWithChildren<MaintananceProps>> = ({
  notMaintained,
  openIssues,
}) => {
  const theme = useTheme();
  const colors = theme.palette.categoryRiskColor;
  return (
    <NodeTooltip title={`Maintenance Check`}>
      {notMaintained === true && (
        <Typography variant='caption' color={colors.high}>
          Not Maintained Library
        </Typography>
      )}
      {notMaintained === false && (
        <Typography variant='caption' color={colors.passed}>
          Maintained Library
        </Typography>
      )}
      {notMaintained === null && (
        <Typography variant='caption' color={colors.info}>
          No Info about Maintanance
        </Typography>
      )}
      <Typography variant='caption'>Open Issues: {openIssues}</Typography>
    </NodeTooltip>
  );
};

export interface MaintananceProps {
  notMaintained: Nullable<boolean>;
  openIssues: number;
}

export { Maintanance };
