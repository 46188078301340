import { env } from 'env-utils';

const ldClientSideId = env().REACT_APP_LD_CLIENT_SIDE_ID || '';

const getLDConfig = () => {
  return {
    ldClientSideId,
  };
};

export { getLDConfig };
