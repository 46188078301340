import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  ConnectorsByFamilyObject,
  ConnectorsByFamilyObjectResponse,
} from '../../connectors-types';
import query from './get-connectors-by-family.gql';

const getConnectorsByFamily = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      disableCache: boolean = true,
    ): Promise<ConnectorsByFamilyObject[]> => {
      try {
        const results = await client.query<{
          getConnectorsByFamily: ConnectorsByFamilyObjectResponse[];
        }>({
          query,
          fetchPolicy: 'no-cache',
        });
        return results.data.getConnectorsByFamily.map(response => {
          const familyConnectors = response.connectors.map(
            connectorResponse => connectorResponse.connector,
          );
          const familyObject = { ...response, connectors: familyConnectors };
          return familyObject;
        });
      } catch (error) {
        logger.error(`Could not load connectors by family ${error}`);
        return [];
      }
    },
  };
};

export default getConnectorsByFamily;
