import { capitalize } from '@mui/material';
import { isArray, isEmpty, isNull, isString } from 'lodash-es';
import {
  DynamicJiraField,
  IAggregationRow,
  IssueTypeField,
  JiraComponent,
  JiraEpic,
  JiraIssueType,
  JiraPriority,
  JiraProject,
  JiraTicket,
  JiraTicketStatus,
  JiraUser,
  Nullable,
  FieldSchemaType,
} from 'ox-common-types';
import { snapshot } from 'valtio';
import { generateAggregationsTable } from '../jira-utils';
import dayjs from 'dayjs';
import JiraTicketStore, { fieldsKeysDict } from '../store/jira-ticket-store';
import { PreSelectableIssueFields } from '../types/jira-ticket-types';

export const setLoadingCreateNewJiraTicket = (isLoading: boolean) => {
  JiraTicketStore.loading = isLoading;
};

export const setLoadingJiraProjects = (isLoading: boolean) => {
  JiraTicketStore.loadingProjects = isLoading;
};

export const setLoadingJiraAssignees = (isLoading: boolean) => {
  JiraTicketStore.loadingAssignees = isLoading;
};

export const setLoadingJiraReporters = (isLoading: boolean) => {
  JiraTicketStore.loadingReporters = isLoading;
};

export const setLoadingJiraComponents = (isLoading: boolean) => {
  JiraTicketStore.loadingComponents = isLoading;
};

export const setLoadingJiraEpics = (isLoading: boolean) => {
  JiraTicketStore.loadingEpics = isLoading;
};

export const setLoadingLabels = (isLoading: boolean) => {
  JiraTicketStore.loadingLabels = isLoading;
};

export const setLoadingDependentByProjectFields = (isLoading: boolean) => {
  JiraTicketStore.loadingDependentByProjectFields = isLoading;
};

export const setLoadingJiraTicketsStatusUpdates = (isLoading: boolean) => {
  JiraTicketStore.loadingJiraTicketsStatusUpdates = isLoading;
};

export const setValidationObject = (
  validationObject: Nullable<{
    [key: string]: {
      message: string;
    };
  }>,
) => {
  JiraTicketStore.validationObject = validationObject;
};

export const setSelectableTicketFields = (fields: {
  priorities: JiraPriority[];
  labels: string[];
}) => {
  JiraTicketStore.fields = fields;
};

export const setLables = (labels: string[]) => {
  const { fields } = snapshot(JiraTicketStore);
  if (!isNull(fields)) {
    JiraTicketStore.fields = {
      ...fields,
      labels,
    };
  } else {
    JiraTicketStore.fields = {
      priorities: [],
      labels,
    };
  }
};

export const setProjects = (projects: JiraProject[]) => {
  JiraTicketStore.projects = projects;
};

export const setJiraTicketsStatusUpdates = (
  payload: Nullable<JiraTicketStatus[]>,
) => {
  JiraTicketStore.jiraTicketsStatus = payload;
};

export const resetDependentByProjectFields = () => {
  JiraTicketStore.dependentByProjectFields = null;
};

export const setDependentByProjectField = (
  payload: JiraUser[] | JiraIssueType[] | JiraComponent[] | JiraEpic[],
  fieldName: 'epics' | 'issueTypes' | 'reporters' | 'assigness' | 'components',
) => {
  const { dependentByProjectFields } = snapshot(JiraTicketStore);
  if (dependentByProjectFields) {
    JiraTicketStore.dependentByProjectFields = {
      ...dependentByProjectFields,
      [fieldName]: payload,
    };
  } else {
    const dependentByProjectFields = {
      issueTypes: [],
      reporters: [],
      assigness: [],
      components: [],
      epics: [],
    };
    JiraTicketStore.dependentByProjectFields = {
      ...dependentByProjectFields,
      [fieldName]: payload,
    };
  }
};

export const setPreSelectableIssueFields = (
  preSelectableIssueFields: PreSelectableIssueFields,
) => {
  JiraTicketStore.preSelectableIssueFields = preSelectableIssueFields;
};

export const setBulkIssuesId = (issuesId: string[]) => {
  JiraTicketStore.bulkActionIssuesId = issuesId;
};

export const resetJiraTicketForm = () => {
  JiraTicketStore.jiraTicket = {
    components: null,
    summary: '',
    issueType: null,
    epicLink: null,
    project: null,
    assignee: null,
    fallbackAssignee: null,
    reporter: null,
    priority: null,
    labels: null,
    issueId: '',
    issueName: '',
    appName: '',
    appId: '',
    category: '',
    description: null,
    aggregations: null,
    aggregationsCount: null,
    comment: null,
    dynamicFields: null,
    aggItemsIds: null,
    fieldsMetadata: null,
  };
};

export const setDefaultJiraTicketFieldsValues = (
  selectedIssue,
  selectedAggs,
) => {
  const { preSelectableIssueFields } = snapshot(JiraTicketStore);
  const {
    issueType = null,
    project = null,
    assignee = null,
    fallbackAssignee = null,
    reporter = null,
    epic = null,
    components = [],
  } = preSelectableIssueFields || {};
  const table: Nullable<IAggregationRow[]> = generateAggregationsTable(
    selectedIssue.aggregations,
    selectedAggs,
  );

  JiraTicketStore.jiraTicket = {
    summary: selectedIssue.mainTitle,
    issueType: project ? issueType : null,
    project,
    assignee: project ? assignee : null,
    fallbackAssignee: project ? fallbackAssignee : null,
    reporter: project ? reporter : null,
    components,
    epicLink: epic,
    priority: null,
    labels: null,
    issueName: selectedIssue.name,
    issueId: selectedIssue.issueId,
    appName: selectedIssue.app.name,
    appId: selectedIssue.app.id,
    category: selectedIssue.category.name,
    aggregations: table,
    aggregationsCount: selectedIssue.aggregations.items.length,
    comment: null,
    dynamicFields: null,
    description: {
      secondTitle: selectedIssue.secondTitle,
      category: selectedIssue.category.name,
      policyName: selectedIssue.policy.name,
      appName: selectedIssue.app.name,
      fixRecommendation: selectedIssue.recommendation,
      fixLink: selectedIssue.fixLink,
      oxIssueURL: `${window.location.origin}/issues?issueId=${selectedIssue.issueId}`,
    },
    aggItemsIds: selectedIssue.aggregations.items
      .filter(i => selectedAggs.findIndex(sa => sa.aggId === i.aggId) !== -1)
      .map(i => i.aggId),
    fieldsMetadata:
      issueType?.fields?.reduce((acc, curr) => {
        acc[curr.key] = curr;
        return acc;
      }, {} as { [key: string]: IssueTypeField }) || null,
  };
};

export const setBulkDefaultJiraTicketFieldsValues = () => {
  const { preSelectableIssueFields } = snapshot(JiraTicketStore);
  const {
    issueType = null,
    project = null,
    assignee = null,
    fallbackAssignee = null,
    reporter = null,
    epic = null,
    components = [],
  } = preSelectableIssueFields || {};

  JiraTicketStore.jiraTicket = {
    summary: null,
    issueType: project ? issueType : null,
    project,
    assignee: project ? assignee : null,
    fallbackAssignee: project ? fallbackAssignee : null,
    reporter: project ? reporter : null,
    components,
    epicLink: epic,
    priority: null,
    labels: null,
    comment: null,
    dynamicFields: null,
    fieldsMetadata:
      issueType?.fields?.reduce((acc, curr) => {
        acc[curr.key] = curr;
        return acc;
      }, {} as { [key: string]: IssueTypeField }) || null,
  };
};

export const setJiraTicket = (jiraTicket: JiraTicket) => {
  JiraTicketStore.jiraTicket = jiraTicket;
};

export const setIsLoadingSelectableTicketFields = (isLoading: boolean) => {
  JiraTicketStore.isLoadingSelectableTicketFields = isLoading;
};

export const calcValidationObject = (...skipFields: string[]) => {
  const validationSchema: {
    [key: string]: {
      message: string;
    };
  } = {};

  const {
    jiraTicket,
    fieldsMetadata,
    dynamicTypeFieldsMetadata,
    displayAllJiraFields,
  } = snapshot(JiraTicketStore);

  if (!jiraTicket.project) {
    validationSchema[fieldsKeysDict.project] = {
      message: `Please select project!`,
    };
  }

  if (!jiraTicket.issueType?.fields) {
    validationSchema[fieldsKeysDict.issuetype] = {
      message: `Please select issue type!`,
    };
  }
  const epicField =
    jiraTicket.issueType &&
    jiraTicket.issueType.fields.find(field => {
      return field.name.toLocaleLowerCase().includes('epic');
    });

  if (
    epicField?.required &&
    !epicField.hasDefaultValue &&
    !jiraTicket.epicLink
  ) {
    validationSchema['epicLink'] = {
      message: 'Missing epic link!',
    };
  }

  for (const [key, value] of Object.entries(fieldsKeysDict)) {
    if (fieldsMetadata && fieldsMetadata[key]) {
      if (
        fieldsMetadata[key].required &&
        !fieldsMetadata[key].hasDefaultValue &&
        (isNull(jiraTicket[value]) ||
          (isString(jiraTicket[value]) &&
            !(jiraTicket[value] as string).trim()) ||
          (isArray(jiraTicket[value]) &&
            (jiraTicket[value] as []).length === 0))
      ) {
        validationSchema[key] = {
          message: `${capitalize(key)} is required field for '${
            jiraTicket.project?.key
          }' project!`,
        };
      }
    }
  }
  const { dynamicFields } = jiraTicket;
  if (isNull(dynamicFields) && !displayAllJiraFields) {
    dynamicTypeFieldsMetadata?.forEach(dynamicField => {
      validationSchema[dynamicField.key] = {
        message: `${dynamicField.name} is required field for '${jiraTicket.project?.key}' project!`,
      };
    });
  } else {
    dynamicTypeFieldsMetadata?.forEach(dynamicField => {
      if (!dynamicField.required && displayAllJiraFields) {
        return;
      }
      const fieldInput = dynamicFields?.[dynamicField.key];
      if (isEmpty(fieldInput)) {
        validationSchema[dynamicField.key] = {
          message: `${dynamicField.name} is required field for '${jiraTicket.project?.key}' project!`,
        };
      }
      if (isArray(fieldInput) && fieldInput.length === 0) {
        validationSchema[dynamicField.key] = {
          message: `${dynamicField.name} is required field for '${jiraTicket.project?.key}' project!`,
        };
      }
      if (
        fieldInput &&
        fieldInput.hasOwnProperty('value') &&
        isEmpty((fieldInput as DynamicJiraField).value)
      ) {
        validationSchema[dynamicField.key] = {
          message: `${dynamicField.name} is required field for '${jiraTicket.project?.key}' project!`,
        };
      }
      if (dynamicField.schema?.type === FieldSchemaType.Date) {
        if (
          fieldInput &&
          fieldInput.hasOwnProperty('value') &&
          !isEmpty((fieldInput as DynamicJiraField).value)
        ) {
          const date = (fieldInput as DynamicJiraField).value;
          if (
            !dayjs(date).isValid() &&
            (!dayjs(date).isSame(new Date()) ||
              !dayjs(date).isAfter(new Date()))
          ) {
            validationSchema[dynamicField.key] = {
              message: `Invalid date`,
            };
          }
        }
      }
    });
  }
  if (skipFields.length > 0) {
    for (const field of skipFields) {
      delete validationSchema[field];
    }
  }
  setValidationObject(validationSchema);
  return isEmpty(validationSchema);
};

export const setDisableSubmit = (value: boolean) => {
  JiraTicketStore.disableSubmit = value;
};
