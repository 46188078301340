import CreateOrganizationStore from '../stores/create-organization-store';

export const setOrgName = (orgDisplayName: string) => {
  CreateOrganizationStore.orgDisplayName = orgDisplayName;
};

export const closeCreateOrgModal = (): void => {
  setModalVisibility(false);
};

export const openCreateOrgModal = (): void => {
  setModalVisibility(true);
};

const setModalVisibility = (payload: boolean) => {
  CreateOrganizationStore.isModalVisible = payload;
};

export const setOrgCreatedSuccessfully = (orgCreatedSuccessfully: boolean) => {
  CreateOrganizationStore.isOrgCreatedSuccessfully = orgCreatedSuccessfully;
};

export const setMessage = (message: string) => {
  CreateOrganizationStore.message = message;
};

export const setIsLoading = (isLoading: boolean) => {
  CreateOrganizationStore.isLoading = isLoading;
};

export const setRemoveDemoOrg = (value: boolean) => {
  CreateOrganizationStore.removeDemoOrg = value;
};
