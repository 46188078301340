import { useSnapshot } from 'valtio';
import OscarHoverStore from '../stores/oscar-hover-store';
import { resolveColumnDimType } from '../utils/oscar-hover-utils';

export const useIsHoveringCell = ({
  column,
  row,
}: {
  column: number;
  row: number;
}) => {
  const { hoverCell, hardDimmedColumnIndexes, softDimmedColumnIndexes } =
    useSnapshot(OscarHoverStore);

  return {
    isHovering:
      !!hoverCell && hoverCell.column === column && hoverCell.row === row,
    dimType: resolveColumnDimType(
      column,
      hardDimmedColumnIndexes,
      softDimmedColumnIndexes,
    ),
  };
};
