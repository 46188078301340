import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache, ServiceExecute } from 'ox-common-types';
import query from './get-org-status.gql';

const getOrgStatus = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<void, QueryResponse['getOrgStatus']> => {
  return {
    execute: async (): Promise<QueryResponse['getOrgStatus']> => {
      const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
      const results = await client.query<QueryResponse>({
        query,
        fetchPolicy,
      });

      return results.data.getOrgStatus;
    },
  };
};

interface QueryResponse {
  getOrgStatus: {
    hasData: boolean;
  };
}

export default getOrgStatus;
