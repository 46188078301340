import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import { ITag, OxTagType } from '../../../applications/applications-types';
import query from './get-all-tags.gql';

const getAllTags = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getAllTagsInput: GetAllTagsInput,
    ): Promise<Nullable<QueryResponse>> => {
      try {
        const results = await client.query<QueryResponse>({
          query,
          fetchPolicy: ApolloClientCache.CacheFirst,
          variables: {
            getTagsInput: getAllTagsInput,
          },
        });

        if (!results || !results.data) {
          logger.error('Failed to fetch ox tags');
          return null;
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to fetch ox tags', error);
        return null;
      }
    },
  };
};

interface GetAllTagsInput {
  filters?: {
    tagType?: OxTagType[];
  };
}
interface QueryResponse {
  getAllTags: { tags: ITag[] };
}

export default getAllTags;
