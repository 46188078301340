import { Theme, Tooltip, Typography } from '@mui/material';
import { IconType } from 'ox-common-types';
import { FC, ReactNode } from 'react';
import { MdHelpOutline as QuestionMarkIcon } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';
import { AppFlowItem } from './AppFlowItem';
import { AppFlowToolTip } from './AppFlowToolTip';
import { AppFlowToolTipItem } from './types/app-flow-types';
import {
  generateCategoryToolTipIcon,
  generateTypesMap,
  getRegistryicon,
  isCloudDeploymentType,
} from './utils/app-flow-utils';

export const AppFlowItemBox: FC<AppFlowItemBoxProps> = ({
  flowIcon: FlowIcon,
  appFlowItemType,
  iconSize,
  service,
  appFlowItemTypeApp,
  serviceIcon,
  flows,
  toolTip,
}) => {
  const { classes } = useStyles();
  const flowsSubTypes = generateTypesMap(flows);
  return (
    <div className={classes.container}>
      <div className={classes.flows}>
        {flowsSubTypes &&
          [...flowsSubTypes]?.map((flow, index) => {
            const MainFlowIcon = generateCategoryToolTipIcon(
              flow[1][0]?.type,
              flow[0],
            );
            const MainRegistryFlowIcon = getRegistryicon(flow[0]);

            return (
              <AppFlowItem
                flowIcon={
                  isCloudDeploymentType(flow[1][0].type)
                    ? MainFlowIcon
                    : MainRegistryFlowIcon || FlowIcon || QuestionMarkIcon
                }
                key={index}
                iconSize={iconSize}
                servicesNumber={flow[1].length}
                toolTip={<AppFlowToolTip appFlowType={flow[1]} />}
              />
            );
          })}
      </div>
      <Tooltip arrow title={<div>{toolTip}</div>}>
        <div className={classes.container}>
          <Typography variant='subtitle2' color='text.primary'>
            {appFlowItemType}
          </Typography>
          <Typography variant='caption' color='text.secondary' mt={1}>
            {appFlowItemTypeApp}
          </Typography>
          <div className={classes.service}>
            {serviceIcon && FlowIcon && (
              <FlowIcon height={20} style={{ marginInlineEnd: '5px' }} />
            )}
            <Typography variant='subtitle1' color='text.primary' mt={0.5}>
              {service}
            </Typography>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  service: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flows: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface AppFlowItemBoxProps {
  flowIcon?: IconType | string;
  toolTip: ReactNode;
  appFlowItemType?: string;
  servicesNumber?: number;
  iconSize: number;
  service?: string;
  appFlowItemTypeApp?: string;
  serviceIcon?: boolean;
  flows?: AppFlowToolTipItem[];
}
