import { isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import jiraService from '../services';
import {
  resetDependentByProjectFields,
  setIsLoadingSelectableTicketFields,
  setPreSelectableIssueFields,
  setProjects,
  setSelectableTicketFields,
} from '../store-actions/jira-ticket-store-actions';
import JiraTicketStore from '../store/jira-ticket-store';
import { InternalssueInfo } from '../types/jira-ticket-types';
import { getDependentByProjectFields } from './get-dependent-by-project-fields-action';

export const getAllSelectableTicketFields = async (
  issueInfo: InternalssueInfo,
) => {
  try {
    setIsLoadingSelectableTicketFields(true);
    resetDependentByProjectFields();

    const { fields } = snapshot(JiraTicketStore);
    if (!isNull(fields)) {
      const results = await jiraService.getPreSelectTicketFields.execute(
        issueInfo,
      );
      if (isNull(results)) {
        logger.error(
          '[Invalid response] - faild to get jira ticket pre selected fields',
        );
        return;
      } else {
        if (results.getPreSelectFields && results.getLatestProjects) {
          setLatestProjects(
            results.getPreSelectFields,
            results.getLatestProjects,
          );
          setPreSelectableIssueFields(results.getPreSelectFields);
          if (!isNull(results.getPreSelectFields.project)) {
            await getDependentByProjectFields(
              results.getPreSelectFields.project.key,
            );
          }
        }
      }
      return;
    }
    const results = await jiraService.getAllSelectableTicketFields.execute(
      issueInfo,
    );

    if (isNull(results)) {
      throw new Error('Invalid response');
    }

    setSelectableTicketFields({
      labels: [],
      priorities: results.listPriorities,
    });

    if (!isNull(results.getPreSelectFields)) {
      setPreSelectableIssueFields(results.getPreSelectFields);
      setLatestProjects(results.getPreSelectFields, results.getLatestProjects);
      if (results.getPreSelectFields.project) {
        await getDependentByProjectFields(
          results.getPreSelectFields.project.key,
        );
      }
    } else {
      setProjects(results.getLatestProjects);
    }
  } catch (error) {
    logger.error(error);
    openSnackbar('Jira service is currently unavailable', {
      variant: 'error',
    });
  } finally {
    setIsLoadingSelectableTicketFields(false);
  }
};

export function setLatestProjects(preSelectFields, latestProjects) {
  setProjects(
    preSelectFields.project
      ? latestProjects.some(p => p.key === preSelectFields.project?.key)
        ? latestProjects
        : [...latestProjects, preSelectFields.project]
      : latestProjects,
  );
}
