import { Roles } from '@oxappsec/ox-unified-permissions';
import { Nullable } from '../../../../../utilities/ox-common-types/src/typescript-types';

export interface ActiveMember {
  user_id: string;
  name: string;
  picture: string;
  email: string;
  roles: Role[];
  relatedIDs: string[];
  kind: MemberKind.ActiveMember;
  scopes?: Nullable<string>;
  selectedAppOwnersScopeItems?: Nullable<string[]>;
  selectedTagsScopeItems?: Nullable<string[]>;
}

export interface Role {
  id: string;
  name: Roles;
  description: string;
}

export interface MembersScreenResponse {
  activeMembers: ActiveMember[];
  availableRoles: Role[];
  pendingInvitationMembers: PendingInvitationMember[];
  pendingRequests: UserRequestAccess[];
}

export interface InvitationInput {
  inviteeEmail: string;
  inviteeRoles: string[];
  connectionId?: string;
  scopes?: Nullable<string>;
}

export interface PendingInvitationMember {
  id: string;
  inviteeEmail: string;
  created_at: string;
  expires_at: string;
  kind: MemberKind.PendingInvitationMember;
  scopes?: Nullable<string>;
}

export interface UserRolesInput {
  userId: string;
  roleIDs: string[];
}

export interface UserScopesInput {
  userId: string;
  scopes?: Nullable<string>;
}

export interface UpdateMemberInput {
  userId: string;
  scopes?: Nullable<string>;
  roleIDs?: string[];
}

export interface UpdateMemberResponse {
  updateMember: UpdateMemberInput;
}

export interface UpdateUserScopesResponse {
  updateUserScopes: Nullable<string>;
}

export enum TableMemberStatus {
  Active = 'Active',
  Pending = 'Invited',
  Expired = 'Expired',
  RequestAccess = 'Request Access',
}

export enum MemberKind {
  ActiveMember = 'ActiveMember',
  PendingInvitationMember = 'PendingInvitationMember',
  PendingRequestAccess = 'PendingRequestAccess',
}

export enum VerificationText {
  RemoveActiveMember = 'remove',
  DeleteOrganization = 'delete',
}

export interface NewMembersData {
  emails: string[];
  roles: Role[];
  selectedAppOwnersScopeItems?: string[];
  selectedTagsScopeItems?: string[];
}

export interface GetPendingRequestAccessResponse {
  pendingRequests: UserRequestAccess[];
}

export interface UserRequestAccess {
  email: string;
  requestedAt: Date;
  kind: MemberKind.PendingRequestAccess;
}

export interface UpdateRequestAccessInput {
  email: string;
  status: RequestedAccessStatus;
}

export enum RequestedAccessStatus {
  NA = 'NA',
  PENDING = 'PENDING',
  INVITATION_SENT = 'INVITATION_SENT',
  DISMISSED = 'DISMISSED',
}
