import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import {
  GetConnectorScriptInput,
  GetConnectorScriptResponse,
  GetConnectorScriptResult,
} from '../../connectors-types';
import { logger } from 'logging-utils';
import query from './get-connector-script.gql';

const getConnectorScript = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getConnectorScriptInput: GetConnectorScriptInput,
    ): Promise<Nullable<GetConnectorScriptResponse>> => {
      try {
        const results = await client.query<GetConnectorScriptResult>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: { getConnectorScriptInput },
        });

        if (!results.data) {
          throw new Error('failed to get Connector script');
        }

        if (results.data.getConnectorScript) {
          return results.data.getConnectorScript;
        }

        throw new Error('Failed to get Connector Script');
      } catch (error) {
        logger.error(`Failed in getConnectorScript ${error}`);
        return null;
      }
    },
  };
};

export default getConnectorScript;
