import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchIssuesInput,
  FetchIssuesConditionalFiltersResponse,
} from '../../types/issues-types';
import query from './get-issues-conditional-filters-lazy.gql';
import { openSnackbar } from 'snackbar-utils';

const fetchIssuesConditionalFiltersLazy = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (getIssuesInput: FetchIssuesInput, cache = true) => {
      try {
        const results =
          await client.query<FetchIssuesConditionalFiltersResponse>({
            query,
            fetchPolicy: cache
              ? ApolloClientCache.CacheFirst
              : ApolloClientCache.NoCache,
            variables: { getIssuesInput: getIssuesInput },
          });

        if (!results.data) return null;
        return results.data.getIssuesConditionalFiltersLazy;
      } catch (e) {
        logger.error('Failed to fetch issues conditional filters', e);
        openSnackbar('Failed to fetch issues conditional filters', {
          variant: 'error',
        });
        return null;
      }
    },
  };
};

export default fetchIssuesConditionalFiltersLazy;
