import { alpha, Theme } from '@mui/material';
import { FC, forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

export const Hover: FC<React.PropsWithChildren<HoverProps>> = forwardRef<
  HTMLDivElement,
  HoverProps
>((props, ref) => {
  const { classes, cx } = useStyles();

  return (
    <div {...props} ref={ref} className={cx(classes.hover, props.className)} />
  );
});

const useStyles = makeStyles()((theme: Theme) => ({
  hover: {
    border: `1px solid transparent`,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.light, 0.15),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export interface HoverProps {
  className?: string;
}
