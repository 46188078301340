import { Roles } from '@oxappsec/ox-unified-permissions';
import { UserStore } from '../stores/user-roles-store';

export const setUserRoles = (userRoles: Roles[]): void => {
  setReadOnlyRole();
  try {
    if (userRoles.length > 0) {
      updateRolesStore(userRoles);
    } else {
      updateRolesStore([Roles['Read Only']]);
    }
  } catch {
    setReadOnlyRole();
  }
};

const updateRolesStore = (roles: Roles[]) => {
  UserStore.roles = roles;
};

const setReadOnlyRole = () => {
  UserStore.roles = [Roles['Read Only']];
};
