import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';
import {
  FetchIssueDetailsByQueryParams,
  FetchIssuesResponse,
  IssueDetails,
} from '../../types/issues-types';

const fetchIssueDetailsByQuery = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<FetchIssueDetailsByQueryParams, Nullable<IssueDetails>> => {
  return {
    execute: async (
      getIssueDetailsByQueryInput: FetchIssueDetailsByQueryParams,
    ) => {
      const query = getIssueDetailsByQueryInput.query;
      const getSingleIssueInput = {
        issueId: getIssueDetailsByQueryInput.issueId,
      };
      const results = await client.query<FetchIssuesResponse>({
        query,
        fetchPolicy: ApolloClientCache.CacheFirst,
        variables: { getSingleIssueInput },
      });

      if (!results.data) return null;
      return results.data.getSingleIssueInfo;
    },
  };
};

export default fetchIssueDetailsByQuery;
