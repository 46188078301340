import { AttackPathType } from '../attack-path-types';

const NodeSize = {
  [AttackPathType.Action]: 60,
  [AttackPathType.JiraTicket]: 50,
  [AttackPathType.Slack]: 50,
  [AttackPathType.Library]: 60,
  [AttackPathType.Compliance]: 60,
  [AttackPathType.SeverityFactor]: 60,
  [AttackPathType.SeverityFactorCategory]: 50,
  [AttackPathType.Oscar]: 60,
  [AttackPathType.Cwe]: 60,
  [AttackPathType.Cve]: 60,
  [AttackPathType.Commit]: 60,
  [AttackPathType.CommitItem]: 50,
  [AttackPathType.App]: 60,
  [AttackPathType.Language]: 60,
  [AttackPathType.IssueOwner]: 60,
  [AttackPathType.Root]: 80,
  [AttackPathType.Expand]: 60,
  [AttackPathType.Category]: 60,
  [AttackPathType.FirstSeen]: 60,
};

export const getNodeSize = (type: AttackPathType) => NodeSize[type] || 50;
