import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { HubSpotUserActions, logUserActionToForm } from 'hubspot';
import { logger } from 'logging-utils';
import {
  JiraTicket,
  Nullable,
  ServiceExecute,
  TicketProvider,
  scanTypes,
} from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';

import mutation from './create-jira-ticket-service.gql';
import { IssuePages } from '../../../../../../apps/web/src/issues/common/types/issues-types';

const createJiraTicket = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  { createIssueInput: JiraTicket; issuesPage: IssuePages },
  Nullable<CreateJiraTicketResponse>
> => {
  return {
    execute: async ({
      createIssueInput,
      issuesPage,
    }): Promise<Nullable<CreateJiraTicketResponse>> => {
      try {
        const results = await client.mutate<CreateIssueResponse>({
          mutation,
          variables: {
            input: createIssueInput,
            provider: TicketProvider.Jira,
            scanType:
              issuesPage === IssuePages.PipelineIssues
                ? scanTypes.PipelineScan
                : scanTypes.FullScan,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }
        TelemetryManager.setTelemetryAction(
          TelemetryActions.JiraTicket,
          createIssueInput,
        );
        logUserActionToForm(HubSpotUserActions.CreateJiraTicket);
        return results.data.createIssue;
      } catch (error) {
        logger.error('Failed to create jira ticket', error);
        return null;
      }
    },
  };
};

export interface CreateIssueResponse {
  createIssue: CreateJiraTicketResponse;
}

export interface CreateJiraTicketResponse {
  data: Nullable<{
    id: string;
    key: string;
    self: string;
  }>;
  validationError: Nullable<string>;
}

export default createJiraTicket;
