import { Box, Button, Tooltip, useTheme } from '@mui/material';
import { OxCategoriesNames } from '@oxappsec/ox-consolidated-categories';
import CategoryIconsMap from '@oxappsec/ox-consolidated-categories/lib/src/ox-categories/icons';
import { generateIcons } from 'application-utils';
import { RepoTypes } from 'ox-common-types';
import { FC, useCallback } from 'react';
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md';
import { animated, useSpring } from 'react-spring';
import { getCorrelatedIconType } from '../../../../artifacts/utils/artifacts-utils';
import {
  CloudThirdPartyTypes,
  RegistryTypes,
} from '../../../../artifacts/types/artifacts-type';
import { AppPages } from 'app-navigator';

const Arrow = ({ direction }: { direction: 'left' | 'right' }) => {
  const theme = useTheme();
  const leftCorner = { transform: 'translateX(0px)' };
  const rightCorner = { transform: 'translateX(15px)' };
  const props = useSpring({
    from: direction === 'right' ? rightCorner : leftCorner,
    to: direction === 'right' ? leftCorner : rightCorner,
    loop: true,
    config: { duration: 750 },
  });

  const Icon =
    direction === 'right'
      ? MdKeyboardDoubleArrowLeft
      : MdKeyboardDoubleArrowRight;

  return (
    <animated.div style={props}>
      <Icon color={theme.palette.primary.main} size={15} />
    </animated.div>
  );
};

const ContainerIcon = CategoryIconsMap.get(OxCategoriesNames.ContainerSecurity);
const ScaIcon = CategoryIconsMap.get(OxCategoriesNames.OpenSourceSecurity);

const CorrelatedIssue: FC<
  React.PropsWithChildren<CorrelatedIssueProps>
> = props => {
  const { correlatedIssueId, category, appType, registryType, className } =
    props;
  const theme = useTheme();

  const arrowDirection =
    category === OxCategoriesNames.OpenSourceSecurity ? 'left' : 'right';
  const { RepoIcon } = generateIcons(undefined, appType as RepoTypes);

  const CorrelatedIcon = getCorrelatedIconType(
    registryType as RegistryTypes | CloudThirdPartyTypes,
  );

  const onClick = useCallback(() => {
    correlatedIssueId &&
      window.open(
        `${AppPages.ActiveIssues}?issueId=${encodeURIComponent(
          correlatedIssueId,
        )}`,
        '_blank',
      );
  }, [correlatedIssueId]);

  const LeftIcon = RepoIcon || ScaIcon;
  const RightIcon = CorrelatedIcon || ContainerIcon;
  return (
    <Box
      className={className}
      display='flex'
      gap={1.5}
      flexDirection='column'
      alignItems='center'>
      <Box display='flex' flexDirection='row' alignItems='center'>
        {LeftIcon && (
          <Tooltip title={appType}>
            <div style={{ display: 'inline-flex' }}>
              <LeftIcon
                style={{
                  marginBottom: 3,
                  flexShrink: 0,
                  width: 25,
                  height: 25,
                }}
                color={theme.palette.primary.main}
              />
            </div>
          </Tooltip>
        )}

        <Arrow direction={arrowDirection} />
        {RightIcon && (
          <Tooltip title={registryType}>
            <div style={{ display: 'inline-flex' }}>
              <RightIcon
                style={{
                  marginLeft: 15,
                  marginBottom: 3,

                  width: 25,
                  height: 25,
                }}
                color={theme.palette.primary.main}
              />
            </div>
          </Tooltip>
        )}
      </Box>
      <Button
        size='small'
        onClick={onClick}
        sx={{
          textTransform: 'none',
        }}
        variant='outlined'>
        Linked Issue
      </Button>
    </Box>
  );
};

export interface CorrelatedIssueProps {
  correlatedIssueId: string;
  category: string;
  appType?: string;
  registryType?: string;
  className?: string;
}

export default CorrelatedIssue;
