import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';
import { FixIssue, PolicyFixInput } from '../../types/issues-types';
import mutation from './add-policy-fix.gql';

const addPolicyFix = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      input: AddPolicyFixInput,
    ): Promise<{
      success: boolean;
      error: string;
      autoFix: Nullable<FixIssue>;
      comment?: string;
    }> => {
      try {
        const results = await client.mutate<AddPolicyFixResponse>({
          mutation,
          variables: { input },
        });
        if (results?.data?.requestSettingsChange?.success) {
          TelemetryManager.setTelemetryAction(
            TelemetryActions.GitPostureFix,
            input,
          );
        }
        return (
          results?.data?.requestSettingsChange || {
            success: false,
            error: '',
            autoFix: null,
          }
        );
      } catch (e) {
        logger.error('Failed to add fix to the issue', e);
      }
      return { success: false, error: '', autoFix: null };
    },
  };
};

interface AddPolicyFixInput {
  inputs: PolicyFixInput[];
  sourceControlType: string;
  settingType: string;
  issueId?: string;
}

interface AddPolicyFixResponse {
  requestSettingsChange: {
    success: boolean;
    error: string;
    autoFix: Nullable<FixIssue>;
    comment: string;
  };
}

export default addPolicyFix;
