import { setupRoutingObjects } from 'app-navigator';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AppNavigationProvider: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Providing app-navigator the navigate & location objects from react-router-dom
    setupRoutingObjects(navigate, location);
  }, [navigate, location]);

  return null;
};

export default AppNavigationProvider;
