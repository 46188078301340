import { createGraphInfo } from 'issue-trend-utils';
import { ref } from 'valtio';
import { ResolvedIssuesOverviewResponse } from '../services/resolved-issues-overview/resolved-issues-overview';
import { LineChartData } from 'ox-react-components';
import { SeverityType } from 'ox-common-types';

export const createResolvedIssuesTrendInfo = (
  response: ResolvedIssuesOverviewResponse['getResolvedIssuesTrend']['trends'],
): LineChartData => {
  const trendsWithScanDate = response.map(t => ({
    appox: t.appox,
    critical: t.critical,
    high: t.high,
    medium: t.medium,
    low: t.low,
    scanDate: convertDate(t.resolvedIssueDate),
  }));

  const severitiesToShow = [
    SeverityType.Medium,
    SeverityType.High,
    SeverityType.Critical,
    SeverityType.Appoxalypse,
  ];

  return ref(createGraphInfo(trendsWithScanDate, severitiesToShow));
};

const convertDate = (isoDate: string): number => {
  return new Date(isoDate).getTime();
};
