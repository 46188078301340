import { IssueDrawerTabsHeader } from '../../../../types/issues-types';
import IssuesDrawerTabsStore from '../store/issue-drawer-tabs-store';

export const setIssueDrawerTabsInStore = (tabs: IssueDrawerTabsHeader[]) => {
  IssuesDrawerTabsStore.issueDrawerTabs = tabs;
};

export const setIsLoadingTabsContainer = (isLoading: boolean) => {
  IssuesDrawerTabsStore.isLoadingTabsContainer = isLoading;
};

export const setIsErrorTabsContainer = (isError: boolean) => {
  IssuesDrawerTabsStore.isErrorTabsContainer = isError;
};

export const setLoadingInnerTabs = (tabId: string, isLoading: boolean) => {
  IssuesDrawerTabsStore.loadingInnerTabs = { [tabId]: isLoading };
};

export const setErrorInnerTabs = (tabId: string, isError: boolean) => {
  IssuesDrawerTabsStore.errorInnerTabs = { [tabId]: isError };
};

export const setSelectedTab = (tabName: string) => {
  IssuesDrawerTabsStore.selectedTab = tabName;
};

export const resetIssueDrawerTabsStore = () => {
  IssuesDrawerTabsStore.issueDrawerTabs = [];
  IssuesDrawerTabsStore.isLoadingTabsContainer = false;
  IssuesDrawerTabsStore.isErrorTabsContainer = false;
  IssuesDrawerTabsStore.loadingInnerTabs = {};
  IssuesDrawerTabsStore.errorInnerTabs = {};
};
