import IssuesStore, {
  IssuesStoreState,
} from '../../active-issues/stores/issues-store';
import PipelineIssuesStore, {
  PipelineIssuesStoreState,
} from '../../pipeline-issues/stores/pipeline-issues-store';
import { IssuePages } from '../types/issues-types';

export const getStoreByPage = (issuePage: IssuePages) => {
  let store: PipelineIssuesStoreState | IssuesStoreState;
  switch (issuePage) {
    case IssuePages.PipelineIssues:
      store = PipelineIssuesStore;
      break;
    case IssuePages.CurrentIssues:
    default:
      store = IssuesStore;
      break;
  }
  return store;
};
