import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable, ServiceExecute } from 'ox-common-types';
import mutation from './set-enabled-scheduled-scan.gql';
import { EnableScheduleScanResponse } from '../../scan-types';

const setEnabledScheduledScan = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<boolean, Nullable<EnableScheduleScanResponse>> => {
  return {
    execute: async (
      enabledScheduledScan,
    ): Promise<Nullable<EnableScheduleScanResponse>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: { enabledScheduledScan: enabledScheduledScan },
        });

        if (!results || !results.data) {
          throw new Error('Failed to enabled scheduled scan, no data received');
        }

        return results.data;
      } catch (error) {
        logger.error('failed to enabled scheduled scan', error);
        return null;
      }
    },
  };
};

interface MutationResponse {
  setEnabledScheduledScan: boolean;
}

export default setEnabledScheduledScan;
