import { Roles } from '@oxappsec/ox-unified-permissions';
import { ActiveMember, MemberKind } from './members-types';

export const demoMembersList: ActiveMember[] = [
  {
    user_id: 'demo1',
    name: 'Grace Hopper',
    picture: '',
    email: 'grace.hopper@ox.security',
    roles: [
      {
        description:
          'Can view issues and perform remediation/collaboration actions (such as open a PR, create a ticket, send to Slack, and others)',
        id: 'demoRole1',
        name: Roles.Developer,
      },
    ],
    relatedIDs: [],
    kind: MemberKind.ActiveMember,
    scopes: 'tags:Back-End',
  },
  {
    user_id: 'demo2',
    name: 'Alan Turing',
    picture: '',
    email: 'alan.turing@ox.security',
    roles: [
      {
        description:
          'Can view applications and perform certain application-related actions; can view issues and perform all issue-related actions (including the ability to exclude issues and change severity)',
        id: 'demoRole2',
        name: Roles['Dev Manager/Security Champion'],
      },
    ],
    relatedIDs: [],
    kind: MemberKind.ActiveMember,
    scopes: 'tags:DevOps,Business-Critical',
  },
  {
    user_id: 'demo3',
    name: 'Dennis Ritchie',
    picture: '',
    email: ' dannis.ritchie@ox.security',
    roles: [
      {
        description:
          'Has full visibility and management capabilities for applications, issues, policies, workflows, and connectors',
        id: 'demoRole3',
        name: Roles['Policy Manager'],
      },
    ],
    relatedIDs: [],
    kind: MemberKind.ActiveMember,
    scopes: '',
  },
  {
    user_id: 'demo4',
    name: 'Dan Kaminsky',
    picture: '',
    email: 'dan.kaminsky@ox.security',
    roles: [
      {
        description:
          'Has read-only access to all pages; cannot perform actions',
        id: 'demoRole4',
        name: Roles['Read Only'],
      },
    ],
    relatedIDs: [],
    kind: MemberKind.ActiveMember,
    scopes: 'tags:Business-Critical,Front-End,Back-End',
  },
];
