import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import query from './search-input-options.gql';

const searchInputOptions = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (searchInputOptionsInput: SearchInputOptionsInput) => {
      try {
        const results = await client.query<SearchInputOptionsResponse>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { searchInputOptionsInput },
        });
        return results.data.searchInputOptions.options;
      } catch (error) {
        logger.error('Failed to search input options', error);
        return null;
      }
    },
  };
};

export interface SearchInputOptionsInput {
  ticketingVendor: string;
  inputKey: string;
  searchValue: string;
  dependsOnValue?: string;
}

export interface SearchInputOptionsResponse {
  searchInputOptions: {
    options: { id: string; name: string }[];
  };
}
export default searchInputOptions;
