import { Divider, Typography, useTheme } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { SiOpenai } from 'react-icons/si';
import { ReactComponent as OxIcon } from '../../../../assets/icons/ox-built-in.svg';

const IssueCardChatGPT: FC<React.PropsWithChildren<IssueCardChatGPTProps>> = ({
  questionsAndAnswers,
}) => {
  const theme = useTheme();

  return (
    <>
      {questionsAndAnswers.map(contentObj => (
        <React.Fragment key={contentObj.question}>
          <Typography
            variant='body1'
            color={theme.palette.gptContent.fontColor} //'rgb(52, 53, 65)'
            p={3.5}
            display='flex'
            alignItems='center'
            fontFamily={theme.palette.gptContent.fontFamily}
            component='div'
            sx={{ backgroundColor: theme.palette.gptContent.questionBgColor }}>
            <OxIcon
              style={{
                width: '2rem',
                height: '2rem',
                backgroundColor: 'lightblue',
                padding: '5px',
                marginInlineEnd: '24px',
              }}
            />
            <span>{contentObj.question}</span>
          </Typography>
          <Divider />
          <Typography
            p={3.5}
            variant='body1'
            color={theme.palette.gptContent.fontColor}
            display='flex'
            fontFamily={theme.palette.gptContent.fontFamily}
            alignItems='flex-start'
            component='div'
            sx={{
              whiteSpace: 'pre-line',
              backgroundColor: theme.palette.gptContent.answerBgColor,
            }}>
            <div>
              <SiOpenai
                color='white'
                style={{
                  width: '2rem',
                  height: '2rem',
                  backgroundColor: 'rgb(16, 163, 127)',
                  padding: '5px',
                  marginInlineEnd: '24px',
                }}
              />{' '}
            </div>
            <span>{contentObj.answer}</span>
          </Typography>
          <Divider />
        </React.Fragment>
      ))}
    </>
  );
};

export interface IssueCardChatGPTProps {
  questionsAndAnswers: {
    question: string;
    answer: string | ReactElement;
  }[];
  firstQuestion: string;
  firstAnswer: string | ReactElement;
}

export default IssueCardChatGPT;
