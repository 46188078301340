import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  ArtifactTopFilter,
  FetchArtifactsInput,
} from '../../types/artifacts-type';
import query from './get-artifacts-top-filters.gql';

const getArtifactsTopFilters = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getArtifactsInput: FetchArtifactsInput,
      cache: boolean = true,
    ) => {
      try {
        const results = await client.query<ArtifactsTopFiltersOutput>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: { getArtifactsInput },
        });
        if (!results.data) return null;
        return results.data.getArtifactsTopFilters;
      } catch (e) {
        logger.error('Failed to fetch artifacts top filters', e);
        return null;
      }
    },
  };
};

export interface ArtifactsTopFiltersOutput {
  getArtifactsTopFilters: ArtifactTopFilter[];
}

export default getArtifactsTopFilters;
