import { Theme, Typography } from '@mui/material';
import { getDuration } from 'common-utils';
import { Nullable } from 'ox-common-types';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

export const DebugInfo: FC<React.PropsWithChildren<DebugInfoProps>> = ({
  scanStartDate,
  scanFinishDate,
  lastScanDate,
  scanAdditionalInfo,
  scanId,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {lastScanDate && (
        <Typography variant='caption'>
          {lastScanDate?.lastFormatScanDate} at{' '}
          {lastScanDate?.lastFormatScanTime}
        </Typography>
      )}
      {scanId && (
        <div className={classes.item}>
          <Typography variant='caption' color='text.secondary'>
            Scan ID:
          </Typography>
          <Typography variant='caption' color='text.primary'>
            {scanId}
          </Typography>
        </div>
      )}
      {scanStartDate && scanFinishDate && (
        <div className={classes.item}>
          <Typography variant='caption' color='text.secondary'>
            Scan Duration:
          </Typography>
          <Typography variant='caption' color='text.primary'>
            {getDuration(scanStartDate, scanFinishDate)}
          </Typography>
        </div>
      )}
      {Object.keys(scanAdditionalInfo).map(
        key =>
          scanAdditionalInfo[key] !== undefined &&
          scanAdditionalInfo[key] !== null && (
            <div className={classes.item} key={key}>
              <Typography
                variant='caption'
                color='text.secondary'
                sx={{ textTransform: 'capitalize' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
              </Typography>
              <Typography variant='caption' color='text.primary'>
                {Array.isArray(scanAdditionalInfo[key])
                  ? (scanAdditionalInfo[key] as string[]).join(', ')
                  : scanAdditionalInfo[key]}
              </Typography>
            </div>
          ),
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  item: {
    padding: theme.spacing(1),
    gridTemplateColumns: '1fr 1fr',
    display: 'grid',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  copyButton: {
    marginBlockStart: theme.spacing(1),
  },
}));

export interface DebugInfoProps {
  scanStartDate?: Nullable<Date>;
  scanFinishDate?: Nullable<Date>;
  lastScanDate?: Nullable<{
    lastFormatScanDate: string;
    lastFormatScanTime: string;
    relativeDate: string;
  }>;
  scanAdditionalInfo: Record<string, string[] | number | string>;
  scanId?: string;
}
