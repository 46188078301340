import { FC, useCallback } from 'react';
import NodeTooltip from '../NodeTooltip';
import { Typography, ListItem, useTheme, IconButton } from '@mui/material';
import { CustomExtLink } from 'ox-react-components';
import { MdContentCopy as ContentCopyIcon } from 'react-icons/md';

const K8sItem: FC<React.PropsWithChildren<K8sItemProps>> = ({
  metadata,
  items,
  header,
}) => {
  const theme = useTheme();
  const copyToClipboard = useCallback(
    event => {
      const { index } = event.currentTarget.dataset;
      const item = items[index];
      navigator.clipboard.writeText(metadata[item.key]);
    },
    [items, metadata],
  );
  return (
    <NodeTooltip title={header}>
      {items.map(
        (item, index) =>
          metadata[item.key] && (
            <ListItem
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}>
              <Typography variant='caption' color='text.secondary'>
                {item.label}:&nbsp;
              </Typography>

              <Typography variant='caption' style={{ gap: 4 }}>
                {metadata[item.key]}
                {item.link && metadata[item.link] && (
                  <>
                    {' '}
                    <span style={{ marginLeft: 4 }} />
                    <CustomExtLink value='' href={metadata[item.link]} />
                  </>
                )}
                <IconButton
                  data-index={index}
                  onClick={copyToClipboard}
                  size='small'>
                  <ContentCopyIcon color='success' fontSize='small' />
                </IconButton>
              </Typography>
            </ListItem>
          ),
      )}
    </NodeTooltip>
  );
};

export interface K8sItemProps {
  metadata: { [key: string]: string };
  items: Array<{ key: string; label: string; link?: string }>;
  header: string;
}

export default K8sItem;
