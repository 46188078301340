import { useAppStore } from 'app-store';
import { isLocalDevelopment } from 'env-utils';
import { FC } from 'react';
import { setGeneralError } from '../actions/app-general-actions';
import ErrorBoundary from '../components/error-handling/ErrorBoundary';

const ErrorBoundaryContainer: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { generalError } = useAppStore();
  if (isLocalDevelopment()) {
    return <>{children}</>;
  }
  return (
    <ErrorBoundary
      key={`general-error-${generalError}`}
      hasError={generalError}
      setErrorState={setGeneralError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryContainer;
