import { AppPages, navigate } from 'app-navigator';
import { FilterData, FilterItems } from 'ox-filter-utils';
import { loadSbomLibraries } from '../actions/sbom-libraries-actions';
import { getSbomOpenFilterItems } from '../actions/sbom-libraries-filters-actions';

import { debounce } from 'lodash-es';
import SbomFiltersStore from './sbom-filters-store';

export const setTotalSbomFilters = (totalFilters: number) => {
  SbomFiltersStore.totalFilters = totalFilters;
};

export const clearFilters = () => {
  SbomFiltersStore.filterBy = {};
  SbomFiltersStore.numberOfFilters = 0;
  navigate(AppPages.Sbom);
  loadSbomLibraries({ update: true });
};

export const handleSearchFilterType = debounce(
  (searchInput: string, filterType: string) => {
    setSearchValue(filterType, searchInput);
    getSbomOpenFilterItems();
  },
  500,
);

const setSearchValue = (type: string, searchValue: string) => {
  const index = SbomFiltersStore.searchValues.findIndex(
    key => key.fieldName === type,
  );
  if (index !== -1) {
    SbomFiltersStore.searchValues[index].value = searchValue;
  } else {
    SbomFiltersStore.searchValues.push({
      fieldName: type,
      value: searchValue,
    });
  }
};

export const setSbomOpenFilterItemsInStore = (
  responseFilters: FilterData[],
  filterItems: FilterItems,
) => {
  Object.keys(filterItems).forEach(key => {
    const selectedResultFilterItem = responseFilters.find(
      item => item.type === key,
    );
    if (
      SbomFiltersStore.filterItems &&
      SbomFiltersStore.filterItems[key] &&
      selectedResultFilterItem
    ) {
      SbomFiltersStore.filterItems[key].items = selectedResultFilterItem.items;
    }
  });
};

export const clearSbomFiltersStore = () => {
  SbomFiltersStore.filterBy = {};
  SbomFiltersStore.numberOfFilters = 0;
  SbomFiltersStore.searchValues = [];
  SbomFiltersStore.filterItems = null;
  SbomFiltersStore.totalFilters = 0;
  SbomFiltersStore.librariesFilterValue = '';
};
