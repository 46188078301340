import {
  isValidOrgDisplayNameLen,
  isValidOrgDisplayNameStr,
} from 'common-utils';
import { Nullable } from 'ox-common-types';

const INVALID_ORG_DISPLAY_NAME_HELPER_TEXT = 'No special characters allowed';

const INVALID_ORG_DISPLAY_NAME_LEN_HELPER_TEXT =
  'Organization name must be at least 4 characters in length';

export const getInvalidOrgDisplayNameHelperText = (
  orgDisplayName: Nullable<string>,
) => {
  if (!orgDisplayName) {
    return INVALID_ORG_DISPLAY_NAME_LEN_HELPER_TEXT;
  }

  const isValidDisplayName = isValidOrgDisplayNameStr(orgDisplayName);
  if (!isValidDisplayName) {
    return INVALID_ORG_DISPLAY_NAME_HELPER_TEXT;
  }

  const isValidDisplayNameLen = isValidOrgDisplayNameLen(orgDisplayName);
  if (!isValidDisplayNameLen) {
    return INVALID_ORG_DISPLAY_NAME_LEN_HELPER_TEXT;
  }

  return '';
};
