import { createStore, derive } from 'store-utils';

export const initialState = {
  value: 0,
  total: 0,
  info: 'Initializing...',
};

const baseStore = createStore<DiscoveryStoreState>(
  initialState,
  'DiscoveryStore',
);

const DiscoveryStore = derive(
  {
    computedValue: (get): number => {
      const { total, value } = get(baseStore);
      return total ? ((value || 0) * 100) / total : 0;
    },
  },
  {
    proxy: baseStore,
  },
);

export interface DiscoveryStoreState {
  value: number;
  total: number;
  info: string;
}
export default DiscoveryStore;
