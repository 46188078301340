import { FC, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { setCommentModal } from '../../../../../../active-issues/store-actions/issue-store-actions';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import IssueAppInfo from './IssueAppInfo';

const IssueAppInfoTabContainer: FC = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);
  const handleAddComment = useCallback(() => {
    setCommentModal(true);
  }, []);

  return (
    <>
      {selectedIssue && (
        <IssueAppInfo
          isResolvedIssuesPage={false}
          issueDetails={selectedIssue}
          onComment={handleAddComment}
        />
      )}
    </>
  );
};

export default IssueAppInfoTabContainer;
