import { isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import { JiraIssueType } from 'ox-common-types';
import jiraService from '../services';
import {
  setDependentByProjectField,
  setLoadingDependentByProjectFields,
  setValidationObject,
} from '../store-actions/jira-ticket-store-actions';
import { fieldsKeysDict } from '../store/jira-ticket-store';
export const getDependentByProjectFields = async (projectKey: string) => {
  try {
    setLoadingDependentByProjectFields(true);
    setValidationObject(null);
    const results = await jiraService.getDependentByProjectFields.execute(
      projectKey,
    );

    if (isNull(results)) {
      throw new Error('Invalid response');
    }

    const error = results?.getIssueTypes?.error;
    const issueTypes = results?.getIssueTypes?.issueTypes;

    if (!!error) {
      setValidationObject({
        [fieldsKeysDict.project]: {
          message: error,
        },
      });
      return;
    }

    if (isNull(issueTypes)) {
      throw new Error('Invalid response');
    }
    issueTypes && checkForIssueTypeValidationError(issueTypes);
    setDependentByProjectField(issueTypes, 'issueTypes');
    return issueTypes;
  } catch (error) {
    logger.error(error);
    // temporary hiding this snackbar message to provide better user experience for the demo
    // openSnackbar(
    //   `An error occurred while loading the project "${projectKey}" issue types. Please try again later..`,
    //   {
    //     variant: 'warning',
    //   },
    // );
    logger.log('Failed to load issue types for the jira');
  } finally {
    setLoadingDependentByProjectFields(false);
  }
};

const checkForIssueTypeValidationError = (issueTypes: JiraIssueType[]) => {
  for (const issueType of issueTypes) {
    for (const field of issueType.fields) {
      if (field.error) {
        setValidationObject({
          [field.key]: {
            message: field.error,
          },
        });
      }
    }
  }
};
