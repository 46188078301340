import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  FetchOrgScanInfoInput,
  Nullable,
  ServiceExecute,
} from 'ox-common-types';
import { InventoryResponse } from '../../inventory-types';
import query from './fetch-inventory.gql';

const fetchInventory = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<FetchOrgScanInfoInput, Nullable<InventoryResponse>> => {
  return {
    execute: async (
      getDashboardInput: FetchOrgScanInfoInput,
    ): Promise<Nullable<InventoryResponse>> => {
      const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
      try {
        const results = await client.query<{
          getInventoryResult: InventoryResponse;
        }>({
          query,
          fetchPolicy,
          variables: { getDashboardInput },
        });
        if (!results.data) return null;
        return results.data.getInventoryResult;
      } catch (e) {
        logger.error('Failed to fetch inventory', e);
        return null;
      }
    },
  };
};

export default fetchInventory;
