import { darken, useTheme } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { merge } from 'lodash-es';
import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { createChartJSGradient } from './chartjs-utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export type ItemDataset = { key: 'string' };

const defaultOptions: ChartOptions<'line'> = {
  elements: {
    line: {
      // Fill animation bug related to tension settings
      // https://figithub.com/chartjs/Chart.js/issues/11310#issuecomment-1712065734
      // tension: 0.3,
      tension: 0.2,
    },
  },
  responsive: false,
  maintainAspectRatio: false,
  animation: {
    duration: 500,
    easing: 'easeInOutCubic',
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      stacked: true,
      grid: {
        display: true,
      },
    },
  },
};

export const LineChart: FC<LineChartProps> = ({
  labels,
  data,
  colors,
  tooltipItemName,
}) => {
  const theme = useTheme();
  const palleteMode = theme.palette.mode;
  const ref = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ChartJSOrUndefined<'line', number[], number>>(null);
  const [size, setSize] = useState<{ width: number; height: number }>();

  // to get the initial size
  useLayoutEffect(() => {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();
    setSize({ width: rect.width, height: rect.height });
  }, []);

  // watch for container resize
  useResizeObserver(ref, entry => {
    const { width, height } = entry.contentRect;
    chartRef.current?.resize(width, height);
  });

  const dataset = useMemo(():
    | ChartData<'line', number[], number>
    | undefined => {
    if (!data) {
      return undefined;
    }
    return {
      labels,
      datasets: Object.keys(data).map(key => ({
        key,
        data: data[key] || [],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          return chartArea
            ? createChartJSGradient(ctx, chartArea, colors[key], palleteMode)
            : undefined;
        },
        borderColor: colors[key],
        pointBackgroundColor: darken(colors[key], 0.1),
        pointBorderColor: darken(colors[key], 0.1),
      })),
    };
  }, [labels, data, colors, palleteMode]);

  const options = merge(defaultOptions, {
    scales: {
      x: {
        grid: {
          color: theme.palette.divider,
        },
      },
      y: {
        grid: {
          color: theme.palette.divider,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: item =>
            ` ${item.formattedValue} ${
              (item.dataset as unknown as ItemDataset).key
            } ${tooltipItemName}`,
        },
      },
    },
  } as ChartOptions<'line'>);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      ref={ref}>
      {size && (
        <Line
          height={size?.height}
          width={size?.width}
          data={dataset!}
          // clone options, otherwise chart.js doesn't respond
          // to options changes
          options={{ ...options }}
          ref={chartRef}
        />
      )}
    </div>
  );
};

interface LineChartProps extends LineChartData {
  colors: Record<string, string>;
  tooltipItemName: string;
}

export interface LineChartData {
  labels: number[];
  data?: { [key: string]: number[] | undefined };
}
