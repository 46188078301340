import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable, ServiceExecute } from 'ox-common-types';
import { ScanInProgressResponse, ScanSettings } from '../../scan-types';
import query from './scan-settings-and-status.gql';

const scanSettingsAndStatus = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<void, Nullable<QueryResponse>> => {
  return {
    execute: async (): Promise<Nullable<QueryResponse>> => {
      try {
        const results = await client.query<QueryResponse>({
          query,
          fetchPolicy: 'no-cache',
        });

        if (!results.data) {
          logger.error(`failed to get scan settings and status`);
          return null;
        }

        return results.data;
      } catch (error) {
        logger.error(`failed to get scan settings and status`, error);
        return null;
      }
    },
  };
};

interface QueryResponse {
  checkScanInProgress: ScanInProgressResponse;
  getScanSettings: ScanSettings;
}

export default scanSettingsAndStatus;
