import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import query from './dismiss-whats-new.gql';

const dismissWhatNewItems = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (ids: string[]) => {
      try {
        const results = await client.query<void>({
          query,
          variables: {
            ids,
          },
          fetchPolicy: ApolloClientCache.NoCache,
        });
        return results.data;
      } catch (error) {
        logger.error('Failed to dismiss whats-new items', error);
        return null;
      }
    },
  };
};

export default dismissWhatNewItems;
