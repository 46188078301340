import { useAuth0 } from '@auth0/auth0-react';
import { setAuthToken, setShouldTryRefresh, useTokenStore } from 'common-auth';
import { FC, useEffect } from 'react';
import { setShouldForceLogout } from '../../actions/app-general-actions';

const ForceLogoutProvider: FC<React.PropsWithChildren<unknown>> = () => {
  const { logout, getAccessTokenSilently } = useAuth0();
  const { shouldForceLogout, shouldTryRefresh } = useTokenStore();

  useEffect(() => {
    if (shouldTryRefresh) {
      async function refreshToken() {
        const accessToken = await getAccessTokenSilently();
        setAuthToken(accessToken);
        setShouldTryRefresh(false);
      }
      refreshToken();
    }
  }, [getAccessTokenSilently, shouldTryRefresh]);

  useEffect(() => {
    //  Force logout when token is invalid/expired
    if (shouldForceLogout) {
      logout({ returnTo: window.location.origin });
    }
    return () => {
      setShouldForceLogout(false);
    };
  }, [shouldForceLogout, logout]);
  return null;
};

export default ForceLogoutProvider;
