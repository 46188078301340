import {
  Chip,
  IconButton,
  Link,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AppPages,
  navigateToSpecificApp,
  navigateWithParameters,
} from 'app-navigator';
import { CategoryRiskNames, RepoTypes, SeverityAlert } from 'ox-common-types';
import {
  ApplicationCell,
  CustomLink,
  Dash,
  DateFormat,
  IssueBadge,
  MultiLineEllipsis,
  StackedItems,
} from 'ox-react-components';
import React, { useCallback } from 'react';
import { Column } from 'react-data-grid';
import { BiGitRepoForked } from 'react-icons/bi';
import { APIDefinition, ApiInventoryItem, APISource } from '../types';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as SwaggerIcon } from '../../assets/icons/swagger.svg';
import ApiItemFunction from '../components/ApiItemFunction';
import { isActiveIssuesPage } from '../../issues/common/utils/lazy-filters';
import ApiItemCodeLocation from '../components/ApiItemCodeLocation';
import ApiCallstack from 'ox-react-components/src/ApiCallstack/ApiCallstack';
import {
  ConditionalFilter,
  ConditionalFiltersCondition,
} from 'ox-filter-utils';

export const useApiInventoryTable = (classes?: {
  textCenter?: string;
  flexCenter?: string;
  headerCell?: string;
  noPadding?: string;
  columnsContainer?: string;
  textOverFlow?: string;
}) => {
  const baseColumn: Partial<Column<ApiInventoryItem>> = {
    headerCellClass: classes?.headerCell,
    sortable: true,
  };

  const openApps = useCallback(event => {
    const { appid } = event.currentTarget.dataset;
    appid && navigateToSpecificApp(appid);
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const openApiItem = useCallback(event => {
    const { apiid: apiId } = event.currentTarget.dataset;
    if (apiId) {
      navigateWithParameters(AppPages.ApiInventory, {
        filters: {
          apiId: [apiId],
        },
      });
    }
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const { classes: classNames } = useStyles();

  const columns: Column<ApiInventoryItem>[] = [
    ...(!isActiveIssuesPage()
      ? [
          {
            ...baseColumn,
            key: 'index',
            name: '#',
            width: 30,
            minWidth: 30,
            cellClass: classNames.textCenter,
            headerCellClass: classNames.textCenter,
            formatter: ({ row }) => (
              <Typography variant='caption' color='text.secondary'>
                {row.index}
              </Typography>
            ),
          },
        ]
      : []),
    ...(!isActiveIssuesPage()
      ? [
          {
            ...baseColumn,
            key: 'title',
            name: 'Title',
            formatter: ({ row }) => (
              <MultiLineEllipsis variant='body2' lines={3}>
                {row.title || `${row.appName} API`}
              </MultiLineEllipsis>
            ),
          },
        ]
      : []),
    ...(!isActiveIssuesPage()
      ? [
          {
            ...baseColumn,
            key: 'severity',
            name: 'Highest Severity Exposed Issues',
            width: 120,
            headerCellClass: classes?.textOverFlow,
            formatter: ({ row }) =>
              getSeverityComponent(row.issuesBySeverity, row.uuid) || <Dash />,
          },
        ]
      : []),
    {
      ...baseColumn,
      key: 'epName',
      name: 'Endpoint',
      formatter: ({ row }) => (
        <MultiLineEllipsis variant='body2' lines={3}>
          {isActiveIssuesPage() ? (
            <Link
              data-apiid={row.uuid}
              onClick={openApiItem}
              sx={{ fontSize: 8, lineHeight: 1 }}
              target='_blank'>
              <Typography variant='caption' color='#585cfc'>
                {row.epName}
              </Typography>
            </Link>
          ) : (
            row.epName
          )}
        </MultiLineEllipsis>
      ),
    },
    {
      ...baseColumn,
      key: 'methodName',
      name: 'Method',
      width: 150,
      headerCellClass: classes?.flexCenter,
      cellClass: classes?.flexCenter,

      formatter: ({ row }) => <Method name={row.methodName} />,
    },
    {
      ...baseColumn,
      key: 'functions',
      name: 'Functions',
      formatter: ({ row }) =>
        getFunctionsComponent(row.definitions) || <Dash />,
    },
    {
      ...baseColumn,
      key: 'firstSeen',
      name: 'First Seen',
      width: 150,

      formatter: ({ row }) => <DateFormat date={new Date(row.firstSeen)} />,
    },
    ...(isActiveIssuesPage()
      ? [
          {
            ...baseColumn,
            key: 'codeLocations',
            name: 'Issue-Code Locations',
            sortable: false,
            formatter: ({ row }) =>
              row.codeLocations.length ? (
                <StackedItems
                  items={row.codeLocations}
                  ItemComponent={ApiItemCodeLocation}
                  ItemGroupComponent={({ items }) => (
                    <ApiCallstack codeLocations={items} />
                  )}
                  tooltipClasses={{ tooltip: classNames.callStackTooltip }}
                />
              ) : null,
          },
        ]
      : []),
    ...(!isActiveIssuesPage()
      ? [
          {
            ...baseColumn,
            key: 'source',
            name: 'Source',

            width: 80,
            headerCellClass: classes?.flexCenter,
            cellClass: classes?.flexCenter,

            formatter: ({ row }) =>
              getSourceComponent(row.definitions, row.appType),
          },
        ]
      : []),
    ...(!isActiveIssuesPage()
      ? [
          {
            ...baseColumn,
            key: 'appName',
            name: 'App Name',
            formatter: ({ row }) => (
              <ApplicationCell
                sx={{ cursor: 'pointer' }}
                variant='caption'
                color='#5059FF'
                data-appid={row.appId}
                appName={row.appName!}
                onClick={openApps}
                appType={row.appType as RepoTypes}
              />
            ),
          },
        ]
      : []),
  ];

  return columns;
};

const getSeverityComponent = (
  severitiesAlerts: SeverityAlert,
  uuid: string,
) => {
  if (!severitiesAlerts) return null;

  return (
    <IssueBadge
      severitiesAlerts={severitiesAlerts}
      fontSize='0.58rem'
      top={3}
      metadata={uuid}
      handleClick={onIssuesBySeverityClick}
      tooltipPlacement='top'
    />
  );
};

export const getFunctionsComponent = (definitions: APIDefinition[]) => {
  const sourceCodeDefinition = definitions.find(
    ({ source }) => source === APISource.code,
  );

  return sourceCodeDefinition && sourceCodeDefinition.functions?.length ? (
    <StackedItems
      ItemComponent={ApiItemFunction}
      items={sourceCodeDefinition.functions}
    />
  ) : null;
};

const getSourceComponent = (definitions: APIDefinition[], appType?: string) => {
  if (!definitions?.length) {
    return <SwaggerIcon style={{ flexShrink: 0, width: 18, height: 18 }} />;
  }
  const sources = new Set();
  const tooltips = {
    [APISource.code]: [] as APIDefinition[],
    [APISource.codeOpenApi]: [] as APIDefinition[],
  };
  definitions.forEach(d => {
    sources.add(d.source);
    tooltips[d.source].push(d);
  });

  const getTooltipContent = (source: APISource) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {tooltips[source].map(({ link, fileName, source }, index) => (
        <MultiLineEllipsis key={link + index} lines={3}>
          <CustomLink
            href={link}
            value={`API Was Discovered In ${
              source === APISource.code ? 'Code' : 'OpenAPI'
            }`}
          />
        </MultiLineEllipsis>
      ))}
    </div>
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {Array.from(sources).map((source, index) => {
        switch (source) {
          case APISource.code:
            return (
              <Tooltip
                key={source + index}
                title={getTooltipContent(APISource.code)}>
                <div style={{ display: 'inline-flex' }}>
                  <IconButton
                    size='small'
                    onClick={() =>
                      window.open(tooltips[source][0].link, '_blank')
                    }>
                    <BiGitRepoForked
                      style={{ flexShrink: 0, width: 16, height: 16 }}
                    />
                  </IconButton>
                </div>
              </Tooltip>
            );
          case APISource.codeOpenApi:
            return (
              <Tooltip
                key={source + index}
                title={getTooltipContent(APISource.codeOpenApi)}>
                <IconButton
                  size='small'
                  onClick={() =>
                    window.open(tooltips[source][0].link, '_blank')
                  }>
                  <SwaggerIcon
                    style={{ flexShrink: 0, width: 20, height: 20 }}
                  />
                </IconButton>
              </Tooltip>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export const Method = ({ name }: { name: ApiInventoryItem['methodName'] }) => {
  return name ? (
    <Chip
      size={'small'}
      label={name.toUpperCase()}
      sx={{
        backgroundColor: resolveMethodColor(name.toUpperCase()),
        color: 'white',
      }}
    />
  ) : null;
};

export const MethodResponse = ({ code }: { code: string }) => {
  return (
    <Chip
      size={'small'}
      label={code}
      sx={{
        backgroundColor: resolveResponseColor(code),
        color: 'white',
      }}
    />
  );
};

const resolveMethodColor = (name: string) => {
  switch (name) {
    case 'POST':
      return '#009d77';
    case 'PUT':
      return '#e97502';
    case 'GET':
      return '#1491ff';
    case 'DELETE':
      return '#cf3030';
    case 'OPTIONS':
      return '#0b5aa7';
    case 'HEAD':
      return '#9012fe';
    case 'PATCH':
      return '#4fe3c2';
    default:
      return '#727272';
  }
};

const resolveResponseColor = (code: number | string) => {
  const firstDigitStr = String(code)[0];
  switch (firstDigitStr) {
    case '2':
      return '#009d77';
    case '3':
      return '#e97502';
    case '4':
    case '5':
      return '#cf3030';
    default:
      return '#1491ff';
  }
};

const useStyles = makeStyles()((theme: Theme) => ({
  textCenter: {
    justifyContent: 'center',
  },
  callStackTooltip: {
    width: 300,
  },
}));

const onIssuesBySeverityClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const { name, metadata } = event.currentTarget.dataset;
  if (!name || !metadata) return;

  const conditionalFilters: ConditionalFilter[] = [
    {
      condition: ConditionalFiltersCondition.OR,
      fieldName: 'criticality',
      values: [CategoryRiskNames[name]],
    },
    {
      condition: ConditionalFiltersCondition.OR,
      fieldName: 'apiItem',
      values: [metadata],
    },
  ];
  navigateWithParameters(AppPages.ActiveIssues, {
    conditionalFilters: conditionalFilters,
  });

  event.stopPropagation();
  event.preventDefault();
};
