import { Typography } from '@mui/material';
import { AttackPathType } from '../attack-path-types';
import NodeTooltip from '../components/NodeTooltip';
import { Theme } from '@mui/system';
import Languages from '../../applications/components/ApplicationCard/Languages';
import { makeStyles } from 'tss-react/mui';

import {
  Update,
  PrItem,
  License,
  CveItem,
  UserItem,
  ReposItem,
  SlackItem,
  Popularity,
  ServiceItem,
  Maintanance,
  ArtifactItem,
  SBOMReposItem,
  ComplianceItem,
  JiraTicketItem,
  SeverityFactorChange,
} from '../components/tooltips';
import ApiCallstack from 'ox-react-components/src/ApiCallstack/ApiCallstack';
import K8sItem from '../components/tooltips/K8sItem';
import CloudItem from '../components/tooltips/CloudItem';
import { SaasSnippet } from 'ox-react-components';
import MultipleImages from '../components/tooltips/MultipleImages';
import K8sApplicationItem from '../components/tooltips/K8sApplicationItem';
import ApiFunctions from '../components/tooltips/ApiFunctions';

export const useNodeTooltip = (type, metadata, closeTooltip) => {
  const { classes } = useStyles();

  switch (type) {
    case AttackPathType.SlackItem:
      return <SlackItem {...metadata} />;
    case AttackPathType.FirstSeen:
      return (
        <NodeTooltip title='First Seen Info'>
          <Typography variant='caption'>
            Date: <b>{metadata.date}</b>
          </Typography>
        </NodeTooltip>
      );
    case AttackPathType.SBOMReposItem:
      return <SBOMReposItem {...metadata} />;
    case AttackPathType.ReposItem:
      return <ReposItem {...metadata} />;
    case AttackPathType.UserItem:
      return <UserItem {...metadata} />;
    case AttackPathType.ServiceItem:
      return <ServiceItem {...metadata} />;
    case AttackPathType.ComplianceItem:
      return <ComplianceItem {...metadata} />;

    case AttackPathType.PrItem:
      return <PrItem {...metadata} />;

    case AttackPathType.SeverityFactorChange:
      return <SeverityFactorChange {...metadata} />;

    case AttackPathType.CveItem:
      return <CveItem {...metadata} />;
    case AttackPathType.ArtifactItem:
      return <ArtifactItem {...metadata} />;
    case AttackPathType.OscarItem:
      return <NodeTooltip title='OSC&R Details'>{metadata.name}</NodeTooltip>;
    case AttackPathType.CweItem:
      return <NodeTooltip title={'CWE Details'}>{metadata.name}</NodeTooltip>;
    case AttackPathType.CommitItemCommitBy:
      return (
        <NodeTooltip title={'Commit By'}>
          <Typography variant='body1'>{metadata.commitBy}</Typography>
        </NodeTooltip>
      );
    case AttackPathType.CommitItemFile:
      return (
        <NodeTooltip title={'File Name'}>
          <Typography variant='body1'>{metadata.fileName}</Typography>
        </NodeTooltip>
      );
    case AttackPathType.CommitItemCommitDate:
      return (
        <NodeTooltip title={'Commit Date'}>
          <Typography variant='body1'>{metadata.date}</Typography>
        </NodeTooltip>
      );
    case AttackPathType.JiraTicketItem:
      return <JiraTicketItem {...metadata} />;

    case AttackPathType.Popularity:
      return <Popularity {...metadata} />;

    case AttackPathType.Maintenance:
      return <Maintanance {...metadata} />;

    case AttackPathType.AppLanguage:
      return (
        <NodeTooltip title={`Languages`}>
          <Languages className={classes.languages} items={metadata.languages} />
        </NodeTooltip>
      );
    case AttackPathType.License:
      return <License {...metadata} />;

    case AttackPathType.Update:
      return (
        <Update
          libraryVersion={metadata.libraryVersion}
          notUpdated={metadata.notUpdated}
        />
      );

    case AttackPathType.Saas:
      return (
        metadata.extraInfo?.extraInfo &&
        metadata.extraInfo?.extraInfo?.length > 0 && (
          <SaasSnippet items={metadata.extraInfo.extraInfo} />
        )
      );

    case AttackPathType.Api:
      return (
        metadata.codeLocations?.length && (
          <ApiCallstack codeLocations={metadata.codeLocations} />
        )
      );

    case AttackPathType.K8sApplication:
      return <K8sApplicationItem metadata={metadata} header='Workload Info' />;
    case AttackPathType.K8sIngress:
      return (
        <K8sItem
          metadata={metadata}
          items={k8sIngressItems}
          header='K8s Ingress Info'
        />
      );
    case AttackPathType.K8sLoadBalancer:
      return (
        <K8sItem
          metadata={metadata}
          items={k8sLoadBalancer}
          header={'K8s Load Balancer Info'}
        />
      );

    case AttackPathType.K8sImageId:
      return metadata.imageIds && <MultipleImages images={metadata.imageIds} />;

    case AttackPathType.Cloud:
      return metadata.dns && <CloudItem {...metadata} />;
    case AttackPathType.K8s:
      return (
        <K8sItem metadata={metadata} items={k8sItems} header='Cluster Info' />
      );

    case AttackPathType.Image:
      return (
        metadata.imageId && (
          <K8sItem metadata={metadata} items={imageItems} header='Image Info' />
        )
      );
    case AttackPathType.ImageId:
      return (
        <K8sItem metadata={metadata} items={imageIdItems} header='Image Info' />
      );
    case AttackPathType.CloudLoadBalancer:
    case AttackPathType.CloudNetworkLoadBalancer:
    case AttackPathType.ApplicationLoadBalancer:
      return (
        <K8sItem
          metadata={metadata}
          items={loadBalancerItems}
          header='Load Balancer Info'
        />
      );
    case AttackPathType.ApiFunction:
      if (metadata.functions.length > 1) {
        return <ApiFunctions functions={metadata.functions} />;
      } else {
        return null;
      }
    default:
      return null;
  }
};

const useStyles = makeStyles()((theme: Theme) => ({
  languages: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const k8sLoadBalancer = [
  { key: 'name', label: 'Name', link: 'link' },
  { key: 'type', label: 'Type' },
  { key: 'listeningPort', label: 'Listening Ports' },
  { key: 'severityFactors', label: 'Severity Factors' },
  {
    key: 'workloadName',
    label: 'Workload Name',
    link: 'workloadLink',
  },

  { key: 'ingressName', label: 'Ingress Name', link: 'ingressLink' },

  { key: 'ingressRules', label: 'Ingress Rules' },
  { key: 'lbName', label: 'Load Balancer Name', link: 'lbLink' },

  { key: 'lbType', label: 'Load Balancer Type' },
  { key: 'lbListeningPort', label: 'Load Balancer Listening Port' },
  { key: 'lbRoute', label: 'Load Balancer Route' },
  { key: 'region', label: 'Region' },
  { key: 'namespace', label: 'Namespace' },
];

const k8sItems = [
  { key: 'name', label: 'Name', link: 'link' },
  { key: 'type', label: 'Cloud Type' },
  { key: 'region', label: 'Region' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'platform', label: 'Platform' },
];

const k8sIngressItems = [
  { key: 'name', label: 'Name', link: 'consoleUrl' },
  { key: 'rules', label: 'Rules' },
  { key: 'type', label: 'Type' },
  { key: 'severityFactors', label: 'Severity Factors' },
  { key: 'publicInterfaces', label: 'Public Interfaces' },
];

const imageItems = [{ key: 'name', label: 'Name' }];

const imageIdItems = [{ key: 'imageId', label: 'Image ID' }];

const loadBalancerItems = [
  { key: 'name', label: 'Name', link: 'consoleUrl' },
  { key: 'type', label: 'Type' },
  { key: 'cloudType', label: 'Cloud Type' },
  { key: 'routes', label: 'Routes' },
  { key: 'dnsName', label: 'DNS Name' },

  {
    key: 'sg',
    label: 'Security Groups',
    formatter: value => (value ? value?.join(', ') : 'N/A'),
  },

  {
    key: 'WAFEnabled',
    label: 'WAF Enabled',
    formatter: value => (value ? 'Yes' : 'No'),
  },
  { key: 'vpc', label: 'VPC' },
  { key: 'arn', label: 'ARN' },
  {
    key: 'subnets',
    label: 'Subnets',

    formatter: value => (value ? value?.join(', ') : 'N/A'),
  },
  {
    key: 'listeningPorts',
    label: 'Listening Ports',
    formatter: value => (value ? value?.join(', ') : 'N/A'),
  },
  { key: 'severityFactors', label: 'Severity Factors' },
];
