import { createStore } from 'store-utils';

const ConnectorConfigureFormStore = createStore<ConnectorConfigureFormState>(
  {
    token: '',
    name: '',
    password: '',
    gitPassword: '',
    gitUsername: '',
    hostURL: '',
    clientId: '',
    secretKey: '',
    awsRoleArn: '',
    awsExternalId: '',
    awsAccessKey: '',
    awsAccessSecret: '',
    tenant: '',
    projectId: '',
    apiAccessKey: '',
    apiSecretKey: '',
    tenantId: '',
    clientSecret: '',
    subscriptionId: '',
    organizationId: '',
    apiKey: '',
    apiUrl: '',
    appId: '',
    webhookUrl: '',
    optionalFields: {},
    optionalTabsFields: {},
    optionalTabIsConfigured: {},
  },
  'Connector Configure Form',
);

export interface ConnectorConfigureFormState {
  token: string;
  name: string;
  password: string;
  gitPassword: string;
  gitUsername: string;
  hostURL: string;
  clientId: string;
  secretKey: string;
  awsRoleArn: string;
  awsExternalId: string;
  awsAccessKey: string;
  awsAccessSecret: string;
  tenant: string;
  projectId: string;
  apiAccessKey: string;
  apiSecretKey: string;
  tenantId: string;
  clientSecret: string;
  subscriptionId: string;
  organizationId: string;
  apiKey: string;
  apiUrl: string;
  appId: string;
  webhookUrl: string;
  optionalFields: {
    [key: string]: string;
  };
  optionalTabsFields: {
    [tabName: string]: {
      [inputName: string]: string;
    };
  };
  optionalTabIsConfigured: {
    [tabName: string]: boolean;
  };
}

export default ConnectorConfigureFormStore;
