import { OxCategoriesKeys } from '@oxappsec/ox-consolidated-categories';
import { isValidOrgName } from 'common-utils';
import { getInvalidOrgDisplayNameHelperText } from 'organizations';
import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import { Connector } from '../../connectors/connectors-types';
import ConnectorsStore from '../../connectors/stores/connectors-store';
import {
  CodeRepos,
  ConnectionType,
  OnboardingSteps,
} from '../onboarding-types';

const baseStore = createStore<OnboardingStoreState>(
  {
    chosenCodeRepo: null,
    onboardingStep: null,
    originalOrgName: '',
    orgName: '',
    isLoading: false,
    isOnboardingInitInProcess: false,
    tokenOrPassword: '',
    hostUrl: '',
    username: '',
    generalError: false,
    errorMessage: null,
    onboardingConnectorsError: null,
    isSkipModalVisible: false,
    validationError: null,
    onboardingSkipped: false,
    connectionType: ConnectionType.IDP,
  },
  'Onboarding Store',
);

const derivedStore = derive(
  {
    codeRepoConnectors: get => {
      const { connectorsByFamily } = get(ConnectorsStore);
      const sourceControlFamily = connectorsByFamily.find(
        family => family.family === OxCategoriesKeys.SourceControl,
      );
      return sourceControlFamily
        ? sourceControlFamily.connectors.filter(connector =>
            Object.values(CodeRepos).includes(connector.name as CodeRepos),
          )
        : null;
    },
    disableConnect: get => {
      const { chosenCodeRepo, hostUrl, tokenOrPassword, connectionType } =
        get(baseStore);
      const idpOrGitHubApp = connectionType !== ConnectionType.ManualCreds;
      const disableConnect =
        !chosenCodeRepo || (!idpOrGitHubApp && (!hostUrl || !tokenOrPassword));

      return disableConnect;
    },
    isValidOrgDisplayName: get => {
      const { orgName } = get(baseStore);
      return isValidOrgName(orgName);
    },
    invalidOrgDisplayNameHelperText: get => {
      const { orgName } = get(baseStore);
      return getInvalidOrgDisplayNameHelperText(orgName);
    },
  },
  {
    proxy: baseStore,
  },
);

const OnboardingStore = derive(
  {
    connectTooltipMsg: get => {
      const { chosenCodeRepo } = get(baseStore);
      const { disableConnect } = get(derivedStore);
      let connectTooltipMsg = '';
      if (disableConnect && !chosenCodeRepo) {
        connectTooltipMsg = 'Please choose a code repository';
      } else if (disableConnect && chosenCodeRepo) {
        connectTooltipMsg = 'Please fill in the required fields';
      }
      return connectTooltipMsg;
    },
  },
  { proxy: derivedStore },
);

interface OnboardingStoreState {
  chosenCodeRepo: Nullable<Connector>;
  onboardingStep: Nullable<OnboardingSteps>;
  originalOrgName: string;
  orgName: string;
  isLoading: boolean;
  isOnboardingInitInProcess: boolean;
  hostUrl: string;
  username: string;
  tokenOrPassword: string;
  generalError: boolean;
  errorMessage: Nullable<string>;
  onboardingConnectorsError: Nullable<string>;
  isSkipModalVisible: boolean;
  validationError: Nullable<CodeRepos>;
  onboardingSkipped: boolean;
  connectionType: ConnectionType;
}

export default OnboardingStore;
