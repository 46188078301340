import { useAppStore } from 'app-store';
import { isLocalDevelopment } from 'env-utils';
import { FC } from 'react';
import { setPageError } from '../actions/app-general-actions';
import PagesErrorBoundary from '../components/error-handling/PagesErrorBoundary';

const PagesErrorBoundaryContainer: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { pageError } = useAppStore();
  if (isLocalDevelopment()) {
    return <>{children}</>;
  }
  return (
    <PagesErrorBoundary
      key={`${pageError}`}
      hasError={pageError}
      setErrorState={setPageError}>
      {children}
    </PagesErrorBoundary>
  );
};

export default PagesErrorBoundaryContainer;
