import { Typography } from '@mui/material';
import { AppSpinner } from 'common-components';
import { FC, useEffect } from 'react';
import { usePrevious } from 'react-utils';
import { useSnapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import { resetHoverStore } from '../actions/oscar-hover-actions';
import {
  loadOscarIssueMatrix,
  resetOscarIssueStore,
} from '../actions/oscar-issue-matrix-actions';
import OscarMatrix from '../components/OscarMatrix';
import OscarMatrixCell from '../components/OscarMatrixCell';
import OscarMatrixCellChips from '../components/OscarMatrixCellChips';
import OscarMatrixCellCoveredByOx from '../components/OscarMatrixCellCoveredByOx';
import OscarMatrixCellHover from '../components/OscarMatrixCellHover';
import OscarMatrixHeader from '../components/OscarMatrixHeader';
import OscarIssueStore from '../stores/oscar-issue-store';

const OscarIssueMatrixContainer: FC<OscarIssueMatrixContainerProps> = ({
  issueId,
  techniqueIds,
}) => {
  const { tactics, loading, error, flatTechniques } =
    useSnapshot(OscarIssueStore);
  const prevIssueId = usePrevious(issueId);

  const { selectedAppOwnersEmails, selectedTagIds } = useSnapshot(
    GlobalDataViewSelector,
  );

  useEffect(() => {
    loadOscarIssueMatrix(techniqueIds, selectedAppOwnersEmails, selectedTagIds);
  }, [selectedAppOwnersEmails, techniqueIds, selectedTagIds]);

  useEffect(() => {
    issueId !== prevIssueId &&
      loadOscarIssueMatrix(
        techniqueIds,
        selectedAppOwnersEmails,
        selectedTagIds,
      );
  }, [
    techniqueIds,
    issueId,
    prevIssueId,
    selectedAppOwnersEmails,
    selectedTagIds,
  ]);

  // on unmount
  useEffect(() => {
    return () => {
      resetHoverStore();
      resetOscarIssueStore();
    };
  }, []);

  if (loading) {
    return <AppSpinner />;
  }

  if (error) {
    return (
      <Typography variant='body2' color='text.primary' textAlign='center'>
        Failed to load OSC&R Matrix
        <br />
        Please try again later
      </Typography>
    );
  }

  if (techniqueIds.length === 0) {
    return (
      <Typography variant='body2' color='text.primary' textAlign='center'>
        No OSC&R Mapping Found
      </Typography>
    );
  }

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <OscarMatrix columns={tactics.length} padding={false}>
        <OscarMatrixHeader tactics={tactics} />

        {flatTechniques.map(({ columnIndex, rowIndex, technique }) => (
          <div key={`row${rowIndex}col${columnIndex}`}>
            {technique ? (
              <OscarMatrixCellHover
                column={columnIndex}
                row={rowIndex}
                totalColumns={tactics.length}
                noOverflow>
                <OscarMatrixCellCoveredByOx
                  coveredByOx={technique.isCoveredByOx}>
                  <OscarMatrixCell
                    id={technique.id}
                    name={technique.name}
                    showContent={true}
                    linkToIssuesPage={technique.linkToIssuesPage}
                    sumIssues={technique.sumIssues}>
                    <OscarMatrixCellChips
                      issueCounters={technique.issueCounters}
                    />
                  </OscarMatrixCell>
                </OscarMatrixCellCoveredByOx>
              </OscarMatrixCellHover>
            ) : null}
          </div>
        ))}
      </OscarMatrix>
    </div>
  );
};

export interface OscarIssueMatrixContainerProps {
  issueId: string;
  techniqueIds: string[];
}

export default OscarIssueMatrixContainer;
