import { connectorsClient, reportClient } from 'api-clients';
import cancelScan from './cancel-scan/cancel-scan';
import getGradualUpdate from './get-gradual-update/get-gradual-update-service';
import fetchParsedDiscoveryProgress from './get-parsed-discovery-progress/get-parsed-discovery-progress';
import scanAll from './scan-all/scan-all';
import scanSettingsAndStatus from './scan-settings-and-status/scan-settings-and-status';
import setEnabledScheduledScan from './set-enabled-scheduled-scan/set-enabled-scheduled-scan';

const scanService = {
  scanAll: scanAll(connectorsClient),
  scanSettingsAndStatus: scanSettingsAndStatus(connectorsClient),
  cancelScan: cancelScan(connectorsClient),
  getDiscoveryProgress: fetchParsedDiscoveryProgress(reportClient),
  getGradualUpdate: getGradualUpdate(reportClient),
  setEnabledScheduledScan: setEnabledScheduledScan(connectorsClient),
};

export default scanService;
