import { Theme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  handleCellMouseEnter,
  handleCellMouseLeave,
} from '../actions/oscar-hover-actions';
import { useIsHoveringCell } from '../hooks/useIsHoveringCell';
import { DimType } from '../types/oscar-hover-types';

const resolveTransformOrigin = (index: number, length: number) => {
  const isFirstColumn = index === 0;
  const isLastColumn = index === length - 1;

  if (isFirstColumn || isLastColumn) {
    return isFirstColumn ? 'left' : 'right';
  }

  return 'center';
};

const OscarMatrixCellHover: FC<
  PropsWithChildren<OscarMatrixCellHoverProps>
> = ({ column, row, children, totalColumns, noOverflow }) => {
  const { classes, cx } = useStyles();
  const { isHovering, dimType } = useIsHoveringCell({ column, row });
  const transformOrigin = noOverflow
    ? resolveTransformOrigin(column, totalColumns)
    : 'center';

  return (
    <div
      style={{ transformOrigin }}
      className={cx(
        classes.oscarMatrixCellHover,
        isHovering && classes.hover,
        dimType === DimType.Soft && classes.dimmedSoft,
        dimType === DimType.Hard && classes.dimmedHard,
      )}
      onMouseEnter={handleCellMouseEnter}
      onMouseLeave={handleCellMouseLeave}
      data-column={column}
      data-row={row}>
      {children}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  oscarMatrixCellHover: {
    height: '100%',
    position: 'relative',
    background: 'inherit',
    overflow: 'hidden',
    borderRadius: 5,
    transition: theme.transitions.create(['transform', 'opacity'], {
      duration: '100ms',
      easing: 'linear',
    }),
    '&::after': {
      content: '" "',
      position: 'absolute',
      borderRadius: 5,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      boxShadow:
        theme.palette.mode === 'light'
          ? `0 0 5px 2px #aaa`
          : `0 0 10px 2px #000`,
      transition: theme.transitions.create('opacity', {
        duration: '100ms',
        easing: 'linear',
      }),
    },
  },
  hover: {
    transform: 'scale(1.2)',
    zIndex: 2,
    '&::after': {
      opacity: 1,
    },
  },
  dimmedSoft: {
    transition: ['opacity 200ms linear', 'transform 200ms linear'].join(','),
    opacity: 0.3,
  },
  dimmedHard: {
    transition: ['opacity 200ms linear', 'transform 200ms linear'].join(','),
    opacity: 0.1,
  },
}));

export interface OscarMatrixCellHoverProps {
  column: number;
  row: number;
  totalColumns: number;
  noOverflow?: boolean;
}
export default OscarMatrixCellHover;
