import { Button, Theme, Tooltip, Typography } from '@mui/material';
import { Nullable } from 'ox-common-types';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const IssueCommentSection: FC<
  React.PropsWithChildren<IssueCommentSectionProps>
> = ({ comment, onComment }) => {
  const { classes } = useStyles();
  return (
    <Tooltip title={comment || 'Comment on Issue'}>
      {comment ? (
        <Typography
          variant='body2'
          color='text.primary'
          className={classes.singleLine}>
          {comment}
        </Typography>
      ) : (
        <Typography
          variant='body2'
          size='small'
          component={Button}
          onClick={onComment}
          sx={{
            textTransform: 'none',
            width: 110,
          }}>
          Add new comment
        </Typography>
      )}
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  singleLine: {
    flexDirection: 'column',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    display: '-webkit-box',
    lineClamp: 1,
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
}));

export interface IssueCommentSectionProps {
  comment?: Nullable<string>;
  onComment?: () => void;
}

export default IssueCommentSection;
