import { AppPages, navigate } from 'app-navigator';
import { AppEvents } from 'common-events';
import { Nullable, OnboardingIDPCallbackDetail } from 'ox-common-types';
import { openSnackbar } from 'snackbar-utils';
import { Organization } from '../../organizations/organization-types';
import { CodeReposIdsMap, OnboardingSteps } from '../onboarding-types';
import {
  setOnboardingStep,
  setOrgName,
  setOriginalOrgName,
  setValidationError,
} from '../store-actions/onboarding-store-actions';

export const setupOnboardingListeners = () => {
  document.addEventListener(AppEvents.Onboarding.ConnectIdpSuccess, () => {
    setOnboardingStep(OnboardingSteps.ChooseRepos);
    navigate(AppPages.Onboarding);
  });

  document.addEventListener(
    AppEvents.Onboarding.ConnectIdpFailed,
    (event: CustomEventInit<OnboardingIDPCallbackDetail>) => {
      setOnboardingStep(OnboardingSteps.Connect);
      setValidationError(
        CodeReposIdsMap.get(event.detail?.connectorId || '') || null,
      );
      openSnackbar(
        event.detail?.error ||
          'There was a problem validating your credentials, there might be a problem with your access policy',
        { variant: 'error', autoHideDuration: 30000 },
      );
      navigate(AppPages.Onboarding);
    },
  );

  document.addEventListener(
    AppEvents.Organizations.ChosenOrgChanged,
    (event: CustomEventInit<Nullable<Organization>>) => {
      setOriginalOrgName(event.detail?.display_name || '');
      setOrgName(event.detail?.display_name || '');
    },
  );
};
