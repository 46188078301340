import { FilterType, FilterTypeToFilter } from 'ox-filter-utils';
import { ReactComponent as ExpressjsIcon } from '../assets/icons/expressjs.svg';
import { ReactComponent as DjangoIcon } from '../assets/icons/django.svg';
import { ReactComponent as FlaskIcon } from '../assets/icons/flask.svg';
import { ReactComponent as FastApiIcon } from '../assets/icons/fastapi.svg';
import { ReactComponent as SpringbootIcon } from '../assets/icons/springboot.svg';
import { ReactComponent as PlayFrameworkIcon } from '../assets/icons/play-framework.svg';
import { ReactComponent as GinIcon } from '../assets/icons/gin.svg';
import { ReactComponent as KtorIcon } from '../assets/icons/ktor.svg';
import { SiKoa, SiNestjs } from 'react-icons/si';
import { DiDotnet } from 'react-icons/di';
import { APIDefinition, ApiFrameworks, APISource } from './types';

// todo align with other pages - make an api call to get this info
export const apiInventoryFilters = [
  {
    id: FilterTypeToFilter.Apps,
    label: FilterType.apps,
    isSearchAble: true,
  },
  {
    id: FilterTypeToFilter.Titles,
    label: FilterType.titles,
    isSearchAble: true,
  },
  {
    id: FilterTypeToFilter.Endpoints,
    label: FilterType.endpoints,
    isSearchAble: true,
  },

  {
    id: FilterTypeToFilter.Methods,
    label: FilterType.methods,
    isSearchAble: false,
  },
  {
    id: FilterTypeToFilter.Framework,
    label: FilterType.framework,
    isSearchAble: false,
  },
  {
    id: FilterTypeToFilter.Languages,
    label: FilterType.languages,
    isSearchAble: true,
  },
  {
    id: FilterTypeToFilter.Source,
    label: FilterType.source,
    isSearchAble: false,
  },
  {
    id: FilterTypeToFilter.Reachability,
    label: FilterType.Reachability,
    isSearchAble: false,
  },
  {
    id: FilterTypeToFilter.Severity,
    label: FilterType.severity,
    isSearchAble: false,
  },
];

export const resolveFrameworkIcon = (framework: ApiFrameworks) => {
  switch (framework) {
    case ApiFrameworks.Django:
      return DjangoIcon;
    case ApiFrameworks.FastAPI:
      return FastApiIcon;
    case ApiFrameworks.Flask:
      return FlaskIcon;
    case ApiFrameworks.ExpressJS:
      return ExpressjsIcon;
    case ApiFrameworks.SpringBoot:
      return SpringbootIcon;
    case ApiFrameworks.Play:
      return PlayFrameworkIcon;
    case ApiFrameworks.Gin:
      return GinIcon;
    case ApiFrameworks.Ktor:
      return KtorIcon;
    case ApiFrameworks.NestJS:
      return props => <SiNestjs {...props} color='#f4004a' />;
    case ApiFrameworks.Koa:
      return props => <SiKoa {...props} color='black' />;
    case ApiFrameworks.MicrosoftAspNetCoreMvc:
      return props => <DiDotnet {...props} color='black' />;
  }
};

export const issuesStatisticsCards = [
  { type: FilterTypeToFilter.Category, header: FilterType.category },
  {
    type: FilterTypeToFilter.Criticality,
    header: FilterType.criticality,
  },
  { type: FilterTypeToFilter.IssueOwner, header: FilterType.issueOwners },
  {
    type: FilterTypeToFilter.SourceTool,
    header: FilterType.sourceTools,
  },
];

export const containsSourceCode = (definitions: APIDefinition[]): boolean =>
  !!definitions.find(({ source }) => source === APISource.code);
