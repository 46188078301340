import { Divider, Theme } from '@mui/material';
import { FC, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useSnapshot } from 'valtio';
import { WhatsNewPageStore } from 'whats-new-logic';
import { WhatsNewItemRenderer } from './WhatsNewItemRenderer';

const WhatsNewGroupedList: FC = () => {
  const { classes } = useStyles();
  const { groups } = useSnapshot(WhatsNewPageStore);

  return (
    <ul className={classes.whatsNewGroupedList}>
      {Object.keys(groups).map(key => (
        <Fragment key={key}>
          <li>
            <Divider className={classes.divider}>{key}</Divider>
          </li>
          <ol className={classes.innerList}>
            {(groups[key] || []).map(item => (
              <li key={item.id} className={classes.item}>
                <WhatsNewItemRenderer
                  type={item.type}
                  description={item.description}
                  links={item.links}
                />
              </li>
            ))}
          </ol>
        </Fragment>
      ))}
    </ul>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  whatsNewGroupedList: {
    marginBlockEnd: theme.spacing(2),
  },
  innerList: {
    listStyle: 'outside',
    paddingInline: theme.spacing(2),
  },
  divider: {
    padding: theme.spacing(2, 0),
    fontWeight: 400,
    zIndex: 0,
    listStyle: 'none',
    '&:before': {
      borderTopStyle: 'dashed',
      borderColor: theme.palette.text.secondary,
    },
    '&:after': {
      borderTopStyle: 'dashed',
      borderColor: theme.palette.text.secondary,
    },
  },
  item: {
    padding: theme.spacing(0.3, 0),
  },
}));

export default WhatsNewGroupedList;
