import { ScaVulnerability } from '../../../../apps/web/src/issues/common/types/issues-types';

export const filterVulnerabilities = (
  rows: ScaVulnerability[],
  searchValue: string,
  contextValues: string[],
) => {
  return rows?.filter(row => {
    const matchesSearch = searchValue
      ? row.cve?.toLowerCase().includes(searchValue.toLowerCase())
      : true;

    if (!matchesSearch) return false;

    if (!contextValues?.length) return true;

    const factorNamesSet = new Set(
      row.cveSeverityFactor?.map(factor => factor.sfName as string),
    );

    const matchesFactors = contextValues.every(val => factorNamesSet.has(val));

    return matchesFactors;
  });
};
