import getViewSettings from './get-view-settings/get-view-settings';
import getUserViewSettings from './get-user-view-settings/get-user-view-settings';
import updateRoleViewSettings from './update-role-view-settings/update-role-view-settings';
import updateUserViewSettings from './update-user-view-settings/update-user-view-settings';
import resetUserViewSettings from './reset-user-view-settings/reset-user-view-settings';
import resetRoleViewSettings from './reset-role-view-settings/reset-role-view-settings';
import { settingsClient } from 'api-clients';

const viewPreferencesService = {
  getViewSettings: getViewSettings(settingsClient),
  updateUserViewSettings: updateUserViewSettings(settingsClient),
  updateRoleViewSettings: updateRoleViewSettings(settingsClient),
  getUserViewSettings: getUserViewSettings(settingsClient),
  resetUserViewSettings: resetUserViewSettings(settingsClient),
  resetRoleViewSettings: resetRoleViewSettings(settingsClient),
};

export default viewPreferencesService;
