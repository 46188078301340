import { oscarClient } from 'api-clients';
import oscarIssueMatrixService from './oscar-issue-matrix-service';
import oscarMatrixService from './oscar-matrix-service';
import oscarApplicationMatrixService from './oscar-application-matrix-service';

const oscarService = {
  fetchMatrix: oscarMatrixService(oscarClient),
  fetchIssueMatrix: oscarIssueMatrixService(oscarClient),
  fetchApplicationMatrix: oscarApplicationMatrixService(oscarClient),
};

export default oscarService;
