import { Tooltip } from '@mui/material';
import { CustomExtLink, MultiLineEllipsis } from 'ox-react-components';
import { ApiInventoryItem } from '../types';
import ApiCallstack from 'ox-react-components/src/ApiCallstack/ApiCallstack';

function ApiItemCodeLocation({
  item: codeLocation,
  inTooltip = false,
}: {
  inTooltip?: boolean;
  item: Required<ApiInventoryItem>['codeLocations'][number];
}) {
  const parsedFilename = codeLocation.link.match(/([^/]+?)(?:#L\d+)?$/);
  const filename =
    parsedFilename && parsedFilename[1] ? parsedFilename[1] : codeLocation.link;

  return (
    <Tooltip
      title={<ApiCallstack codeLocations={[codeLocation]} />}
      placement='left'>
      <span>
        <MultiLineEllipsis variant='body2' lines={3}>
          <CustomExtLink
            href={codeLocation.link}
            value={inTooltip ? codeLocation.link : filename}
          />
        </MultiLineEllipsis>
      </span>
    </Tooltip>
  );
}

export default ApiItemCodeLocation;
