import { LoadingButton } from '@mui/lab';
import { Button, Stack, Tooltip } from '@mui/material';
import { FC, PropsWithChildren, ReactNode, SyntheticEvent } from 'react';

export const ButtonGroup: FC<PropsWithChildren<IButtonGroupProps>> = props => {
  const {
    size,
    onCancel,
    onClick,
    isLoading,
    buttonCancelLabel,
    buttonCancelTooltip,
    buttonActionLabel,
    buttonActionTooltip,
    buttonActionColor,
    isActionDisabled,
    startIcon,
    onActionBtnHover,
  } = props;

  return (
    <Stack gap={2} width='100%' justifyContent='flex-end' direction='row'>
      <Tooltip arrow title={buttonCancelTooltip}>
        <span>
          <Button
            size={size}
            variant='outlined'
            color='primary'
            tabIndex={2}
            onClick={onCancel}>
            {buttonCancelLabel}
          </Button>
        </span>
      </Tooltip>
      <Tooltip arrow title={buttonActionTooltip} onOpen={onActionBtnHover}>
        <span>
          <LoadingButton
            startIcon={startIcon}
            tabIndex={1}
            loading={isLoading}
            size={size}
            variant='contained'
            color={buttonActionColor}
            disabled={isActionDisabled}
            onClick={onClick}>
            {buttonActionLabel}
          </LoadingButton>
        </span>
      </Tooltip>
    </Stack>
  );
};

interface IButtonGroupProps {
  onCancel: () => void;
  onClick: () => void;
  onActionBtnHover?: (event: SyntheticEvent) => void;
  isLoading?: boolean;
  buttonCancelLabel: string;
  buttonCancelTooltip: string;
  buttonActionLabel: string;
  buttonActionTooltip: string;
  buttonActionColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  isActionDisabled: boolean;
  startIcon?: ReactNode;
  size?: 'small' | 'medium' | 'large';
}
