import { Tooltip } from '@mui/material';
import { CustomExtLink, MultiLineEllipsis } from 'ox-react-components';
import { ApiInventoryItemFunction } from '../types';

const ApiItemFunction = ({
  item: { function: functionName, link },
}: {
  item: ApiInventoryItemFunction;
}) => {
  if (link) {
    return (
      <Tooltip title={link} placement='left' arrow>
        <span>
          <MultiLineEllipsis variant='body2' lines={3}>
            <CustomExtLink href={link} value={functionName} />
          </MultiLineEllipsis>
        </span>
      </Tooltip>
    );
  }

  return <span>{functionName}</span>;
};

export default ApiItemFunction;
