import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { SettingsObj } from '../../types/settings-types';
import query from './get-settings.gql';

const getSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (filters?: Partial<SettingsObj>) => {
      try {
        const results = await client.query<{
          getSettings: GetSettingsResponse;
        }>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { filters },
        });

        return results.data.getSettings.settings;
      } catch (error) {
        logger.error('Failed to get settings', error);
        return null;
      }
    },
  };
};

export interface GetSettingsResponse {
  settings: SettingsObj[];
}
export default getSettings;
