import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import {
  SendSlackNotificationInput,
  sendSlackNotificationRes,
} from '../../types/slack-types';

import mutation from './send-slack-notification.gql';

const sendSlackNotification = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      input: SendSlackNotificationInput,
    ): Promise<Nullable<MutationResponse>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: {
            input,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to send slack notification', error);
        return null;
      }
    },
  };
};

export interface MutationResponse {
  sendSlackNotification: sendSlackNotificationRes;
}

export default sendSlackNotification;
