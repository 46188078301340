import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import IssueCardAdditionalInfo from '../../../../IssueCard/IssueCardAdditionalInfo';

const MoreInfoTabContainer: FC = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);

  return (
    <>
      {selectedIssue && (
        <IssueCardAdditionalInfo
          key='More Info'
          violationInfoTitle={''}
          sourceTools={selectedIssue.sourceTools}
          cwe={selectedIssue.cweList}
          extraInfo={selectedIssue.extraInfo}
          fixLink={selectedIssue.fixLink}
          branchScanned={selectedIssue.originBranchName}
          language={
            selectedIssue.aggregations?.items?.[0] &&
            selectedIssue.aggregations.items?.[0]?.language
          }
        />
      )}
    </>
  );
};

export default MoreInfoTabContainer;
