import { getCurrentTheme } from 'app-theme';
import { AppSeverity, OneOfValues, SeverityAlert } from 'ox-common-types';

export const getSeverity = (value: number): OneOfValues<typeof AppSeverity> => {
  if (value > 66) {
    return AppSeverity.High;
  }
  if (value > 33) {
    return AppSeverity.Medium;
  }
  return AppSeverity.Low;
};

export const resolveScoreColors = (value: number): [string, string] => {
  const { critical, high, medium, low, passed, appoxalypse } =
    getCurrentTheme().palette.categoryRiskColor;
  if (value <= 25) return [low, passed];
  if (value <= 50) return [passed, medium];
  if (value <= 75) return [medium, high];
  return [critical, appoxalypse];
};

export const severities = [
  { key: 'appox', title: 'Appoxalypse', value: 5 },
  { key: 'critical', title: 'Critical', value: 4 },
  { key: 'high', title: 'High', value: 3 },
  { key: 'medium', title: 'Medium', value: 2 },
  { key: 'low', title: 'Low', value: 1 },
  { key: 'info', title: 'Info', value: 0 },
];

export enum SeverityEnum {
  Appox = 'appox',
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Info = 'info',
}

export const SeverityConst = {
  Appox: 'appox',
  Critical: 'critical',
  High: 'high',
  Medium: 'medium',
  Low: 'low',
  Info: 'info',
} as const;

export const selectTopSeverities = (
  alerts?: SeverityAlert,
  show = severities.length, // how many items to show
) => {
  if (alerts) {
    return Object.entries(alerts)
      .filter(sev => sev && sev[1] && sev[1] !== 0)
      .slice(0, show);
  }
  return null;
};

export const sumIssues = (issues: SeverityAlertIndexed) => {
  return severities.reduce((acc, s) => acc + (issues[s.key] || 0), 0);
};

interface SeverityAlertIndexed extends SeverityAlert {
  [key: string]: number | undefined;
}
