import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable, ServiceExecute } from 'ox-common-types';
import { PendingInvitationMember } from '../../types/members-types';
import mutation from './resend-invitation.gql';

const resendInvitation = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<string, Nullable<PendingInvitationMember>> => {
  return {
    execute: async (
      invitationId: string,
    ): Promise<Nullable<PendingInvitationMember>> => {
      try {
        const results = await client.mutate<{
          resendInvitation: PendingInvitationMember;
        }>({
          mutation,
          variables: { invitationId },
        });

        if (!results || !results.data) {
          return null;
        }

        return results.data.resendInvitation;
      } catch {
        return null;
      }
    },
  };
};

export default resendInvitation;
