import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { IGetDisclaimerRes } from '@oxappsec/ox-consolidated-gpt-types';
import { logger } from 'logging-utils';
import { ApolloClientCache, ServiceExecute } from 'ox-common-types';
import query from './fetch-chat-gpt-disclaimer.gql';

const fetchChatGptDisclaimer = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  undefined,
  FetchChatGptDisclaimerOutput['getDisclaimer'] | undefined
> => {
  return {
    execute: async () => {
      try {
        const results = await client.query<FetchChatGptDisclaimerOutput>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
        });
        if (!results.data) return;
        return results.data.getDisclaimer;
      } catch (error) {
        logger.error(`Got an error trying to fetch GPT disclaimer: ${error}`);
        return;
      }
    },
  };
};

export interface FetchChatGptDisclaimerOutput {
  getDisclaimer: IGetDisclaimerRes;
}

export default fetchChatGptDisclaimer;
