import { isProd } from 'env-utils';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

export const createStore: <T extends ObjectOf<T>>(
  initialObject: T,
  storeName: string,
) => T = (initialObject, storeName: string) => {
  const store = proxy(initialObject);

  if (!isProd()) {
    devtools(store, storeName);
  }
  return store;
};

type ObjectOf<T> = { [P in keyof T]: T[P] };
