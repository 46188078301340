import { AppTag, ITag } from '../../applications/applications-types';
import TagsStore from '../store/tags-store';
import { snapshot } from 'valtio';

export const setLoadingTagsOptions = (payload: boolean) => {
  TagsStore.loadingEditAppTagsData = payload;
};

export const setLoadingSubmit = (payload: boolean) => {
  TagsStore.loadingSubmit = payload;
};

export const setEditApplicationsTagsData = (
  appIds: string[],
  payload: { tags: ITag[]; appsTags: AppTag[] },
) => {
  const tagsInfoMap = payload.tags.reduce(
    (
      acc: {
        [key: string]: ITag;
      },
      curr: ITag,
    ) => {
      acc[curr.tagId] = curr;
      return acc;
    },
    {},
  );

  const assignedTags = payload.appsTags.reduce(
    (
      acc: {
        [key: string]: string[];
      },
      curr: AppTag,
    ) => {
      if (!appIds.includes(curr.appId)) return acc;
      const { tag } = curr;
      if (acc[tag.tagId] && !acc[tag.tagId].includes(curr.appId))
        acc[tag.tagId].push(curr.appId);
      if (!acc[tag.tagId]) acc[tag.tagId] = [curr.appId];
      return acc;
    },
    {},
  );

  TagsStore.tags = tagsInfoMap;
  TagsStore.assignedTags = assignedTags;
};

export const getTagById = (tagId: string) => {
  const { tags } = snapshot(TagsStore);
  return tags && tags[tagId];
};

export const getTagByName = (tagName: string) => {
  const { tags } = snapshot(TagsStore);

  return tags && Object.values(tags).find(tag => tag.displayName === tagName);
};
