import { Animations, ChartOptions } from 'chart.js';
import { SeverityType, severitiesHeatMap } from 'ox-common-types';
import { capitalizeFirstLetter } from 'string-utils';

export const SEVERITY_DID_NOT_CHANGE =
  'Severity did not change in the selected period';
export const SEVERITY_STABLE =
  'Severity was stable in the start of the selected period';

export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const severityAxis = [
  SeverityType.Info,
  SeverityType.Low,
  SeverityType.Medium,
  SeverityType.High,
  SeverityType.Critical,
  SeverityType.Appoxalypse,
];

export const generateIssueKey = (date: Date) => new Date(date).getTime();

export const getXTick = (labels: number[], index: number) => {
  const date = new Date(labels[index]);
  return formatDate(date);
};

export const getYTick = (label: string | number) => {
  return capitalizeFirstLetter(severityAxis[label] || '');
};

export const getTitles = (
  index,
  manualSeverityChangeReason,
  severityChangedReasonsAdded,
  severityChangeReasonsRemoved,
) => {
  const reasons: string[] = [];
  if (manualSeverityChangeReason[index])
    reasons.push(manualSeverityChangeReason[index]);

  if (severityChangedReasonsAdded[index])
    reasons.push(
      `${severityChangedReasonsAdded[index].length} Severity Factors added`,
    );

  if (severityChangeReasonsRemoved[index])
    reasons.push(
      `${severityChangeReasonsRemoved[index].length} Severity Factors removed`,
    );

  return reasons.join(', ');
};

export const getBeforeFooter = (context, toolSeverities) => {
  return `Tool severity: ${capitalizeFirstLetter(
    toolSeverities[context[0].dataIndex],
  )}`;
};

export const getLabel = context => [
  ` ${capitalizeFirstLetter(severityAxis[context.formattedValue] || '')}`,
];

export const getFooter = (
  context,
  severityChangedReasonsAdded,
  severityChangeReasonsRemoved,
) => [
  `Added severity factors: ${
    severityChangedReasonsAdded[context[0].dataIndex]?.join(', ') || 'None'
  }`,
  `Removed severity factors: ${
    severityChangeReasonsRemoved[context[0].dataIndex]?.join(', ') || 'None'
  }`,
];

export const getOptions = (
  labels,
  colors,
  severityChangedReasonsAdded,
  severityChangeReasonsRemoved,
  manualSeverityChangeReason,
  toolSeverities,
): ChartOptions<'line'> => {
  return {
    elements: {
      line: {
        tension: 0.3,
      },
    },
    responsive: true,
    animation: Animations[0],
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          callback: (_value, index, _values) => getXTick(labels, index),
          font: {
            weight: 'bold',
          },
        },
      },
      y: {
        min: 0,
        max: severityAxis.length - 1,
        ticks: {
          callback: (label, _index, _labels) => getYTick(label),
          color: context => colors[severityAxis[context.tick.value]],
          font: {
            weight: 'bold',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Severity Trend',
        display: true,
      },
      tooltip: {
        footerFont: {
          weight: 'normal',
        },
        callbacks: {
          title: context =>
            getTitles(
              context[0].dataIndex,
              manualSeverityChangeReason,
              severityChangedReasonsAdded,
              severityChangeReasonsRemoved,
            ),
          beforeFooter: context => getBeforeFooter(context, toolSeverities),
          label: context => getLabel(context),
          footer: context =>
            getFooter(
              context,
              severityChangedReasonsAdded,
              severityChangeReasonsRemoved,
            ),
        },
      },
    },
  };
};

export const resolveSeverityValue = (key: string) =>
  severitiesHeatMap.find(s => s.key === key)?.value.toString() || '';
