import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { severityToName } from 'ox-common-types';
import { TrendIcon } from '../TrendIcon/TrendIcon';
import { CveSeverity, CveSeverityIconMap } from '../Vulnerabilities/icon-utils';
import { SeverityColor } from 'severity-utils';
import toNumber from 'lodash/toNumber';

const SeverityFactorsBreakdown = ({
  originalSeverity,
  oxSeverity,
  factors,
  severityTitle,
}: SeverityFactorsBreakdownProps) => {
  const theme = useTheme();

  const oxSeverityFormatted = toNumber(oxSeverity)
    ? severityToName[oxSeverity as number]
    : oxSeverity;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ px: 1, py: 0.5, border: 'none' }} align='left'>
            <b>{severityTitle}</b>
          </TableCell>
          <TableCell sx={{ px: 1, py: 0.5, border: 'none' }} align='right'>
            <SeverityColor severity={originalSeverity}>
              {color => (
                <span
                  style={{
                    color,
                    textTransform: 'capitalize',
                    whiteSpace: 'nowrap',
                  }}>
                  <b>{originalSeverity}</b>
                </span>
              )}
            </SeverityColor>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {factors?.map(factor => {
          const Icon = CveSeverityIconMap.get(factor.sfName as CveSeverity);
          return (
            Math.abs(factor.sfChangedNumber) >= 1 && (
              <TableRow key={factor.sfName}>
                <TableCell
                  sx={{ borderBottom: 'none', px: 1, py: 0.5 }}
                  align='left'>
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='flex-start'
                    gap={0.5}>
                    <TrendIcon size={16} value={factor.sfChangedNumber} />
                    {Icon && <Icon width={16} />}
                    {factor.sfName}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    py: 0.5,
                    borderBottom: 'none',
                    color:
                      factor.sfChangedNumber > 0
                        ? theme.palette.categoryRiskColor.high
                        : theme.palette.categoryRiskColor.passed,
                  }}
                  align='right'>
                  <b>{factor.sfChangedNumber}</b>
                </TableCell>
              </TableRow>
            )
          );
        })}
      </TableBody>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              borderTop: `2px solid ${theme.palette.divider}`,
              borderBottom: 'none',
              px: 1,
              py: 0.5,
            }}
            align='left'>
            <b>OX Severity</b>
          </TableCell>
          <TableCell
            sx={{
              borderTop: `2px solid ${theme.palette.divider}`,
              borderBottom: 'none',
              px: 1,
              py: 0.5,
            }}
            align='right'>
            <SeverityColor severity={oxSeverityFormatted}>
              {color => (
                <span
                  style={{
                    color,
                    textTransform: 'capitalize',
                    whiteSpace: 'nowrap',
                  }}>
                  <b>{oxSeverityFormatted}</b>
                </span>
              )}
            </SeverityColor>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

export interface SeverityFactorsBreakdownProps {
  originalSeverity: string;
  oxSeverity: string | number;
  severityTitle: string;
  factors: { sfName: string; sfChangedNumber: number }[];
}

export default SeverityFactorsBreakdown;
