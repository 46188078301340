import { Theme, Typography } from '@mui/material';
import { AppPages } from 'app-navigator';
import pluralize from 'pluralize';
import { FC } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { ImTree } from 'react-icons/im';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as PoliciesIcon } from '../../../assets/icons/policies.svg';
import { ReactComponent as SystemsIcon } from '../../../assets/icons/systems.svg';
import {
  DiscoveredPolicies,
  OrgScanInfo,
} from '../../../dashboard/dashboard-types';

const NonHistoricalData: FC<
  React.PropsWithChildren<NonHistoricalDataProps>
> = ({ orgScanInfo, discoveredPolicies }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.nonHistorical}>
      <div className={classes.section}>
        <div className={classes.iconSection}>
          <ImTree style={{ color: '#585cfc' }} />
          <Typography
            className={classes.iconTypography}
            color='primary'
            variant='body2'>
            Repos
          </Typography>
          <RouterLink to={AppPages.Applications}>
            <FaExternalLinkAlt
              className={classes.linkIcon}
              style={{ color: '#585cfc' }}
            />
          </RouterLink>
        </div>
        <div>
          <Typography color='text.primary' variant='caption' component='div'>
            {orgScanInfo.totalScanRepos}{' '}
            {pluralize('Repo', orgScanInfo.totalScanRepos)} Scanned
          </Typography>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.iconSection}>
          <SystemsIcon />
          <Typography
            className={classes.iconTypography}
            color='primary'
            variant='body2'>
            Systems
          </Typography>
          <RouterLink to={AppPages.Connectors}>
            <FaExternalLinkAlt
              className={classes.linkIcon}
              style={{ color: '#585cfc' }}
            />
          </RouterLink>
        </div>
        <div>
          <Typography color='text.primary' variant='caption' component='div'>
            {orgScanInfo.systemsTexts.map((t, index) => (
              <span className={classes.nonHistoricalText} key={`${t}_${index}`}>
                {t}
              </span>
            ))}
          </Typography>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.iconSection}>
          <PoliciesIcon />
          <Typography
            className={classes.iconTypography}
            color='primary'
            variant='body2'>
            Policies
          </Typography>
          <RouterLink to={AppPages.Policies}>
            <FaExternalLinkAlt
              className={classes.linkIcon}
              style={{ color: '#585cfc' }}
            />
          </RouterLink>
        </div>
        <div>
          <Typography color='text.primary' variant='caption' component='div'>
            <span className={classes.nonHistoricalText}>
              {discoveredPolicies.enabled} policy rules created & tuned to your
              org
            </span>
            <span className={classes.nonHistoricalText}>
              {discoveredPolicies.disabled} additional policy rules can be
              enabled
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  section: {
    display: 'flex',
    alignItems: 'start',
  },
  iconSection: {
    gap: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 85,
  },
  nonHistorical: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginInlineStart: theme.spacing(1),
    paddingInline: theme.spacing(1),
    paddingBlock: theme.spacing(1),
  },
  nonHistoricalText: {
    display: 'flex',
  },
  linkIcon: {
    fontSize: 8,
    lineHeight: 1,
    cursor: 'pointer',
  },
  iconTypography: {
    display: 'inline-flex',
    alignItems: 'baseline',
  },
}));
export interface NonHistoricalDataProps {
  orgScanInfo: Pick<
    OrgScanInfo,
    'policiesTexts' | 'systemsTexts' | 'totalScanRepos'
  >;
  discoveredPolicies: DiscoveredPolicies;
}

export default NonHistoricalData;
