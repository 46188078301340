import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import mutation from './set-business-priority.gql';

const setPriority = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async ({ priority, appId }: SetPriorityInput) => {
      try {
        const results = await client.mutate<{
          setPriority: SetPriorityResponse;
        }>({
          mutation,
          variables: { priorityInput: { priority, appId } },
        });

        return results?.data?.setPriority?.hasModifiedPriority;
      } catch (error) {
        logger.error(`Failed to set app priority for ${appId}`, error);
        return null;
      }
    },
  };
};

export interface SetPriorityInput {
  appId: string[];
  priority: number;
}

export interface SetPriorityResponse {
  hasModifiedPriority: boolean;
}

export default setPriority;
