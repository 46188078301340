import { IconType, RepoTypes } from 'ox-common-types';
import { BiGitRepoForked } from 'react-icons/bi';
import { FaGithub } from 'react-icons/fa';
import { MdInsertDriveFile as InsertDriveFileIcon } from 'react-icons/md';
import { ReactComponent as ArtifactoryStorageIcon } from './icons/artifactory-storage-icon.svg';
import { ReactComponent as AWSLogo } from './icons/aws-logo.svg';
import { ReactComponent as AzureCloudIcon } from './icons/azure-cloud-icon.svg';
import { ReactComponent as AzureIcon } from './icons/azure-repos.svg';
import { ReactComponent as AzureTfsIcon } from './icons/azure-vs.svg';
import { ReactComponent as BitbucketIcon } from './icons/bitBucket-icon.svg';
import { ReactComponent as GcpCloudIcon } from './icons/gcp-cloud-icon.svg';
import { ReactComponent as GcpLogo } from './icons/gcp-logo.svg';
import { ReactComponent as GitlabIcon } from './icons/gitlab-icon.svg';
import { ReactComponent as TfsAzureIcon } from './icons/tfs-icon.svg';
import { ReactComponent as TfvcIcon } from './icons/tfsec.svg';
import { ReactComponent as GCRIcon } from './icons/google-container.svg';
import { ReactComponent as GARIcon } from './icons/google-artifact.svg';
import { ReactComponent as EcrIcon } from './icons/ECR.svg';
import { ReactComponent as JfrogIcon } from './icons/jfrog.svg';
import { ReactComponent as DockerHubIcon } from './icons/docker-hub.svg';
import { ReactComponent as HarborIcon } from './icons/harbor.svg';
import { ReactComponent as NexusIcon } from './icons/nexus.svg';
import { ReactComponent as ACRIcon } from './icons/azure-acr.svg';
import { ReactComponent as AWSCodecommitIcon } from './icons/aws-codecommit.svg';
import { ReactComponent as KubernetesIcon } from './icons/kubernetes-icon.svg';
import { SiGerrit } from 'react-icons/si';

export const resolveRepoIcon = (repoType: string, fakeApp?: boolean) => {
  const source = repoType?.toLowerCase() as RepoTypes;
  if (realFakeApp(source)) {
    return repoIconsMap.get(source) || null;
  }
  if (fakeApp) {
    return BiGitRepoForked;
  }
  return repoIconsMap.get(source) || null;
};

export const repoIconsMap = new Map<RepoTypes, IconType>([
  [RepoTypes.Azure, AzureIcon],
  [RepoTypes.AzureRepos, AzureIcon],
  [RepoTypes.AzureReposTFVC, TfvcIcon],
  [RepoTypes.AzureCloud, AzureCloudIcon],
  [RepoTypes.AzureTfs, AzureTfsIcon],
  [RepoTypes.AzureTFS, AzureTfsIcon],
  [RepoTypes.Bitbucket, BitbucketIcon],
  [RepoTypes.BitbucketStash, BitbucketIcon],
  [RepoTypes.GitHub, FaGithub],
  [RepoTypes.GitHubSettings, FaGithub],
  [RepoTypes.GitLab, GitlabIcon],
  [RepoTypes.GitLabSmall, GitlabIcon],
  [RepoTypes.AWS, AWSLogo],
  [RepoTypes.GCP, GcpLogo],
  [RepoTypes.GcpCloud, GcpCloudIcon],
  [RepoTypes.KubernetesCloud, KubernetesIcon],
  [RepoTypes.Artifactory, InsertDriveFileIcon],
  [RepoTypes.AwsCloud, AWSLogo],
  [RepoTypes.ArtifactoryStorage, ArtifactoryStorageIcon],
  [RepoTypes.TFS, TfsAzureIcon],

  [RepoTypes.ECR, EcrIcon],
  [RepoTypes.GCP_CONTAINER, GCRIcon],
  [RepoTypes.GCP_ARTIFACTS, GARIcon],
  [RepoTypes.GITLAB_REGISTRY, GitlabIcon],
  [RepoTypes.JFROG_REGISTRY, JfrogIcon],
  [RepoTypes.DOCKER_HUB, DockerHubIcon],
  [RepoTypes.AZURE_CONTAINER_REGISTRY, ACRIcon],
  [RepoTypes.NEXUS_CONTAINER_REGISTRY, NexusIcon],
  [RepoTypes.GOHARBOR_CONTAINER_REGISTRY, HarborIcon],
  [RepoTypes.Gerrit, SiGerrit],
  [RepoTypes.AWS_CODECOMMIT, AWSCodecommitIcon],
  [RepoTypes.Kubernetes, KubernetesIcon],
]);

// TODO: Move this to common-utils
const realFakeApp = (repoType: string) => {
  const checkTypes =
    repoType === RepoTypes.Artifactory ||
    repoType === RepoTypes.AWS ||
    repoType === RepoTypes.Azure ||
    repoType === RepoTypes.ArtifactoryStorage ||
    repoType === RepoTypes.GCP ||
    repoType === RepoTypes.GcpCloud ||
    repoType === RepoTypes.KubernetesCloud ||
    repoType === RepoTypes.GitHubSettings ||
    repoType === RepoTypes.GitHub ||
    repoType === RepoTypes.Git_1 ||
    repoType === RepoTypes.AzureTFS ||
    repoType === RepoTypes.AzureTfs;
  if (checkTypes) {
    return true;
  }
  return false;
};
