import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { GetApplicationsInput, QueryResponse } from '../../applications-types';
import query from './get-applications-csv.gql';

const getApplicationsCSV = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getApplicationsInput: GetAppsCSVExportInput) => {
      try {
        const results = await client.query<{
          getApplicationsCSV: QueryResponse;
        }>({
          query,
          variables: { getApplicationsInput },
          fetchPolicy: ApolloClientCache.NetworkOnly,
        });

        return results.data.getApplicationsCSV;
      } catch (error) {
        logger.error('Failed to fetch applications csv', error);
        return null;
      }
    },
  };
};

type GetAppsCSVExportInput = Pick<
  GetApplicationsInput,
  | 'filters'
  | 'search'
  | 'owners'
  | 'tagIds'
  | 'orderBy'
  | 'systemFilter'
  | 'applicationFilters'
>;

export default getApplicationsCSV;
