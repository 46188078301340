import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { openSnackbar } from 'snackbar-utils';
import {
  FetchIssuesInput as GetCicdIssuesInput,
  PipelineIssuesLazyFiltersResponse,
} from '../../../common/types/issues-types';
import query from './get-pipeline-issues-filter-items.gql';

const GetCICDIssuesFilterItems = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getCicdIssuesInput: GetCicdIssuesInput,
      cache: boolean = true,
    ) => {
      try {
        const results = await client.query<PipelineIssuesLazyFiltersResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NoCache,
          variables: { getCicdIssuesInput },
        });
        if (!results.data) return null;

        return results.data.getCICDIssuesConditionalFilters;
      } catch (e) {
        const errorMessage =
          'Failed to fetch pipeline issues conditional filters';
        openSnackbar(errorMessage, {
          variant: 'error',
        });
        logger.error(errorMessage, e);
      }
    },
  };
};

export default GetCICDIssuesFilterItems;
