import { navigateToExclusions } from 'app-navigator';
import { createSimpleAsyncAction } from 'async-utils';
import { logger } from 'logging-utils';
import exclusionsService from '../services';
import ExclusionsStore from '../stores/exclusions-store';
import { LoadExclusionsParams } from '../types/exclusion-types';
import { setExclusionsFiltersLazy } from '../store-actions/exclusions-store-actions';
import { snapshot } from 'valtio';
import { getFilteredFilters } from '../utils/exclusion-utils';

export const loadExclusionsFilters = createSimpleAsyncAction(
  async (params?: LoadExclusionsParams) => {
    const { cache = true, type, isOpen, limit = 100 } = params || {};
    const { lazyFilters, filterExclusionsBy } = snapshot(ExclusionsStore);
    const filtersCloned = lazyFilters.map(item => {
      const currentItem = { ...item };
      if (currentItem.id === type) {
        currentItem.isOpen = Boolean(isOpen);
        currentItem.isLoading = Boolean(isOpen);
      }
      return currentItem;
    });
    setExclusionsFiltersLazy(filtersCloned);
    const currentFilterItem = filtersCloned.find(item => item.id === type);
    const openFilterItemIds = filtersCloned.reduce((acc: string[], item) => {
      if (item.isOpen) {
        acc.push(item.id);
      }
      return acc;
    }, []);

    const apiParams = {
      filters: filterExclusionsBy,
      limit: limit,
      openItems: openFilterItemIds,
    };

    const currentFilterItemIsClosed =
      currentFilterItem && !currentFilterItem.isOpen;

    if (currentFilterItemIsClosed) {
      if (currentFilterItem) {
        currentFilterItem.isLoading = false;
      }
      setExclusionsFiltersLazy(filtersCloned);
      return;
    }

    try {
      const result = await exclusionsService.getExclusionsFiltersLazy.execute(
        apiParams,
        cache,
      );

      if (result && filtersCloned.length > 0) {
        const updatedResults = filtersCloned.map(item => {
          const currentItem = { ...item };
          const resultsItem = result.exclusionsFilters.find(
            resItem => resItem.id === currentItem.id,
          );
          if (resultsItem) {
            currentItem.items = resultsItem.items;
          }
          if (currentItem.id === type) {
            currentItem.isLoading = false;
          }
          return currentItem;
        });
        setExclusionsFiltersLazy(updatedResults);
      } else if (result) {
        setExclusionsFiltersLazy(result.exclusionsFilters);
      }
    } catch (e) {
      logger.error(`There was an error to loading exclusions filters`, e);
    } finally {
      if (currentFilterItem) {
        currentFilterItem.isLoading = false;
      }
    }
  },
  {
    asyncState: ExclusionsStore.loadingFilters,
    errorMessage: 'Failed to load exclusions filters',
  },
);

export const setInitialFilters = (encodedFilters: string | null) => {
  if (!encodedFilters) return;
  let filters = {};
  try {
    filters = JSON.parse(decodeURI(encodedFilters));
  } catch {}
  ExclusionsStore.filterExclusionsBy = filters;
};

export const clearAllFilters = () => {
  ExclusionsStore.filterExclusionsBy = {};
  ExclusionsStore.totalFilteredExclusions = 0;
  ExclusionsStore.numberOfFilters = 0;
  navigateToExclusions();
};
export const toggleFiltersPanel = () => {
  ExclusionsStore.filtersOpen = !ExclusionsStore.filtersOpen;
};

export const clearFilter = (type: string) => {
  ExclusionsStore.filterExclusionsBy[type] = [];
  const filteredFilters = getFilteredFilters(
    ExclusionsStore.filterExclusionsBy,
  );
  if (
    !filteredFilters ||
    (filteredFilters && Object.keys(filteredFilters).length === 0)
  ) {
    clearAllFilters();
    return;
  }
  navigateToExclusions(ExclusionsStore.filterExclusionsBy);
};

export const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (ExclusionsStore.filterExclusionsBy[e.target.id]) {
    const index = ExclusionsStore.filterExclusionsBy[e.target.id].indexOf(
      e.target.value,
    );
    if (index !== -1) {
      if (
        Object.values(ExclusionsStore.filterExclusionsBy[e.target.id]).length >
        1
      ) {
        ExclusionsStore.filterExclusionsBy[e.target.id].splice(index, 1);
      } else {
        delete ExclusionsStore.filterExclusionsBy[e.target.id];
      }
    } else {
      ExclusionsStore.filterExclusionsBy[e.target.id].push(e.target.value);
    }
  } else {
    ExclusionsStore.filterExclusionsBy[e.target.id] = [e.target.value];
  }
  navigateToExclusions(ExclusionsStore.filterExclusionsBy);
};
