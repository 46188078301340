import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import {
  AddProfileInput,
  INewProfileCreatedRes,
  INewProfileCreatedResponsePayload,
} from '../../types/policy-profile-types';
import mutation from './add-new-profile-service.gql';

const addNewProfile = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<AddProfileInput, INewProfileCreatedResponsePayload> => {
  return {
    execute: async (
      addProfileInput: AddProfileInput,
    ): Promise<INewProfileCreatedResponsePayload> => {
      const results = await client.mutate<INewProfileCreatedRes>({
        mutation,
        variables: {
          addProfileInput,
        },
      });

      if (results?.data?.addProfile) {
        return results.data.addProfile;
      }

      throw new Error('Add new profile call failed');
    },
  };
};

export default addNewProfile;
