import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  AppOwnersByAppIdInput,
  AppOwnersByRole,
} from '../../application-owners-types';
import query from './get-app-owners-by-appIds.gql';
import { ApolloClientCache } from 'ox-common-types';

const getApplicationsOwnersByAppIds = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (input: AppOwnersByAppIdInput) => {
      try {
        const results = await client.query<{
          getAppOwnersByAppIds: AppOwnersByRole[];
        }>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { input },
        });

        return results.data.getAppOwnersByAppIds;
      } catch (error) {
        logger.error('Failed to fetch applications owners', error);
        return null;
      }
    },
  };
};

export default getApplicationsOwnersByAppIds;
