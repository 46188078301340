import { aggregationsColumnsConfig as config } from '@oxappsec/ox-consolidated-configs';
import { AggregationsTable } from 'ox-react-components';
import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';

const AggregationsAdditionalTabsTableContainer: FC<
  React.PropsWithChildren<IssueDrawerTabProps>
> = ({ setIsLoading, setIsError, tabId }) => {
  const { selectedIssue } = useSnapshot(IssuesStore) || {};

  if (!selectedIssue) return null;
  const aggregation = selectedIssue?.additionalTabs.find(
    additionalTab => additionalTab.type === tabId,
  );
  const sourceTool =
    selectedIssue.sourceTools && selectedIssue.sourceTools.length > 0
      ? selectedIssue.sourceTools[0]
      : '';

  return (
    <>
      {aggregation && (
        <AggregationsTable
          withSelection={false}
          key={aggregation.type}
          config={config[aggregation.type]}
          info={selectedIssue.violationInfoTitle}
          items={
            aggregation.aggItems?.length
              ? aggregation.aggItems
              : selectedIssue.aggregations.items
          }
          exclusionCategory={selectedIssue.exclusionCategory}
          isInfo
          sourceToolType={sourceTool}
        />
      )}
    </>
  );
};

export default AggregationsAdditionalTabsTableContainer;
