import {
  Badge,
  BadgeProps,
  Button,
  Theme,
  Typography,
  darken,
  styled,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { MenuItemConfig } from '../config/main-menu-config';

export const AppMenuRawItem: FC<AppMenuRawItemProps> = ({
  href,
  isActive,
  isOpen,
  Icon,
  label,
  aside,
  badge,
  className,
}) => {
  const { classes, cx } = useStyles();
  const iconMarkup = <Icon className={classes.icon} />;

  const button = (
    <Button
      fullWidth
      className={cx(classes.button, isOpen && classes.hover)}
      color={isActive ? 'primary' : 'inherit'}
      classes={{
        textInherit: classes.defaultColor,
      }}>
      <div className={classes.iconContainer}>
        {badge ? (
          <StyledBadge badgeContent={badge} color='primary'>
            {iconMarkup}
          </StyledBadge>
        ) : (
          <>{iconMarkup}</>
        )}
      </div>

      <Typography variant='body1' className={classes.label}>
        {label}
      </Typography>
      {aside}
    </Button>
  );

  if (!href) {
    return button;
  }

  return (
    <NavLink to={href!} className={cx(classes.appMenuRawItem, className)}>
      {button}
    </NavLink>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  appMenuRawItem: {
    textDecoration: 'none',
    display: 'flex',
    width: '100%',
    color: 'inherit',
    height: 40,
  },
  button: {
    width: '100%',
    justifyContent: 'start',
    overflow: 'hidden',
    paddingInline: theme.spacing(2),
    borderRadius: 0,
    minWidth: 0,
  },
  hover: {
    backgroundColor: darken(theme.palette.divider, 0.2),
  },
  iconContainer: {
    minWidth: 35,
    flexShrink: 1,
    display: 'inline-flex',
    position: 'relative',
  },
  icon: {
    width: '1.3em',
    height: '1.3em',
  },
  label: {
    textTransform: 'none',
    height: `${theme.typography.body1.lineHeight}em`,
    textOverflow: 'clip',
    overflow: 'hidden',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    textAlign: 'start',
  },
  defaultColor: {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey['600']
        : theme.palette.text.primary,
  },
  badge: {
    position: 'absolute',
    top: -10,
    right: -2,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '0.6em',
    borderRadius: '100vw',
    padding: theme.spacing('0px', '3px'),
    textTransform: 'none',
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: -3,
    right: -5,
    padding: '4px',
    width: '40px',
    fontSize: '12px',
    scale: '0.7',
  },
}));

interface AppMenuRawItemProps {
  href?: string;
  isActive: boolean;
  isOpen: boolean;
  Icon: MenuItemConfig['icon'];
  label: string;
  aside?: ReactNode;
  badge?: string;
  className?: string;
}
