import {
  PermissionsConfig,
  PermissionScope,
} from '@oxappsec/ox-unified-permissions';
import { issueDrawerTabsList } from '../../issues/active-issues/issue-drawer-tabs-list';
import { PageIconsByDisplayName } from '../../menu/config/main-menu-config';
import { hasPermissions } from '../../permissions/permissions-utils';
import { PageSetting } from '../types';
import { Nullable } from 'ox-common-types';

export const resolvePageIcon = (item: PageSetting, parent?: string) => {
  switch (parent) {
    case 'Issues-Active Issues':
      return item.iconKey && issueDrawerTabsList[item.iconKey]?.icon;
    default:
      return PageIconsByDisplayName[item.page];
  }
};

export const isViewEnabled = (
  path: string,
  preferences: PageSetting[] = [],
): boolean => {
  const ids = path.split('-');

  const findSetting = (
    settings: PageSetting[],
    pathSegments: string[],
  ): PageSetting | null => {
    for (const setting of settings) {
      if (setting.page === pathSegments[0]) {
        if (pathSegments.length === 1) {
          return setting;
        } else {
          if (!setting.children) {
            return null;
          }
          return findSetting(setting.children, pathSegments.slice(1));
        }
      }
    }
    return null;
  };

  const setting = findSetting(preferences, ids);

  // If the setting is not found, return true by default
  if (!setting) return true;

  return setting.value === true;
};

export const getViewPreferencesInput = (
  viewPreferences: PageSetting[],
  parent?: string,
) => {
  return viewPreferences.reduce((acc, item) => {
    const id = parent ? `${parent}-${item.page}` : item.page;
    if (item.children) {
      acc.push(...getViewPreferencesInput(item.children, id));
    }
    acc.push({
      id,
      value: item.value,
    });
    return acc;
  }, [] as { id: string; value: boolean }[]);
};

export const filterPreferencesByPermissions = (
  viewPrefferences: PageSetting[],
  permissions?: Nullable<PermissionsConfig>,
) => {
  return viewPrefferences.reduce((acc, item) => {
    if (
      item.permissionType &&
      !hasPermissions(
        [
          {
            permissionType: item.permissionType,
            permissionScope: PermissionScope.Read,
          },
        ],
        permissions,
      )
    ) {
      return acc;
    }

    acc.push({
      ...item,
      children: item.children
        ? filterPreferencesByPermissions(item.children, permissions)
        : undefined,
    });

    return acc;
  }, [] as PageSetting[]);
};
