import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable, ServiceExecute } from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';
import {
  ExcludeAlertsResponse,
  Exclusion,
  ReportFPInput,
} from '../types/exclusion-types';
import mutation from './report-false-positive.gql';

const reportFalsePositiveAlert = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<ReportFPInput, Nullable<ExcludeAlertsResponse>> => {
  return {
    execute: async (
      reportFPInput: ReportFPInput,
    ): Promise<Nullable<ExcludeAlertsResponse>> => {
      const results = await client.mutate<ReportFalsePositiveResponse>({
        mutation,
        variables: { input: reportFPInput },
      });
      if (results.data?.reportAlertAsFalsePositive) {
        reportFPInput.isExclude &&
          TelemetryManager.setTelemetryAction(
            TelemetryActions.ReportFalsePositiveAlert,
            reportFPInput.reportedAlertInput,
          );
        return results.data.reportAlertAsFalsePositive.exclusionInfo;
      }
      throw new Error('Failed to report false positive alert');
    },
  };
};

export interface ReportFalsePositiveResponse {
  reportAlertAsFalsePositive: {
    exclusionInfo: Nullable<{
      exclusions: Exclusion[];
    }>;
  };
}

export default reportFalsePositiveAlert;
