import { createAsyncState } from 'async-utils';
import { createStore } from 'store-utils';
import { WhatsNewGroupedItems } from '../whats-new-types';

export const WhatsNewPageStore = createStore<WhatsNewStoreState>(
  {
    loading: createAsyncState(),
    groups: {
      may234: [],
    },
  },
  'WhatsNew Page Store',
);

interface WhatsNewStoreState {
  groups: WhatsNewGroupedItems;
  loading: ReturnType<typeof createAsyncState>;
}
