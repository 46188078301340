import { FC, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { severityToName } from 'ox-common-types';
import { SeverityColor } from 'severity-utils';
import { ScaVulnerability } from '../../../../apps/web/src/issues/common/types/issues-types';
import React from 'react';
import { InfoIcon } from '../InfoIcon';
import SeverityFactorsBreakdown from '../SeverityFactorsBreakdown/SeverityFactorsBreakdown';

const OxSeverityCell: FC<React.PropsWithChildren<OxSeverityCellProps>> = ({
  row,
}) => {
  const theme = useTheme();
  const title = useMemo(() => {
    return row.cveSeverityFactor?.some(
      f => Math.abs(f.sfChangedNumber) >= 1,
    ) ? (
      <SeverityFactorsBreakdown
        severityTitle='CVSS Severity'
        oxSeverity={row.oxSeverity}
        originalSeverity={row.originalSeverity}
        factors={row.cveSeverityFactor}
      />
    ) : (
      ''
    );
  }, [row]);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 0.5,
      }}>
      <SeverityColor severity={severityToName[row.oxSeverity]}>
        {color => (
          <span
            style={{
              color,
            }}>
            <b>{severityToName[row.oxSeverity]}</b>
          </span>
        )}
      </SeverityColor>
      {title && (
        <InfoIcon
          placement='left'
          customColor={theme.palette.primary.main}
          title={title}
        />
      )}
    </Box>
  );
};

export interface OxSeverityCellProps {
  row: ScaVulnerability;
}

export default OxSeverityCell;
