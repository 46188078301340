import { Box, Button, Typography } from '@mui/material';
import { AppSpinner, ProgressSpinner } from 'common-components';
import { FC, useEffect, useState } from 'react';

export const SnackbarWrapper: FC<
  React.PropsWithChildren<SnackbarWrapperProps>
> = ({
  children,
  closeFunction,
  isShowingSpinner,
  hideCloseIcon,
  id,
  spinnerVariant = 'indeterminate',
  value,
  valueCb,
  maxwidth,
}) => {
  if (spinnerVariant === 'determinate' && value === undefined && !valueCb) {
    throw new Error(
      'Cannot use determinate spinner without specifying a value prop',
    );
  }
  const [valueState, setValueState] = useState(value);
  useEffect(() => {
    if (spinnerVariant === 'determinate' && !!valueCb) {
      const timer = setInterval(() => {
        const newValue = valueCb();
        setValueState(newValue);
      }, 2500);
      return () => {
        clearInterval(timer);
      };
    }
  }, [spinnerVariant, valueCb]);
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent={hideCloseIcon ? 'flex-start' : 'space-between'}
      maxWidth={maxwidth || 700}>
      {isShowingSpinner && (
        <span style={{ marginInlineEnd: '12px' }}>
          {spinnerVariant === 'determinate' ? (
            <ProgressSpinner value={valueState!} />
          ) : (
            <AppSpinner disablePositionAbsolute noText size={20} />
          )}
        </span>
      )}

      <Typography
        variant='body2'
        id={id}
        display='flex'
        flexWrap='wrap'
        component='span'>
        {children}
      </Typography>

      {!hideCloseIcon && (
        <Button
          variant='text'
          color='inherit'
          aria-label='close'
          sx={{ marginInlineStart: 1 }}
          onClick={closeFunction}>
          dismiss
        </Button>
      )}
    </Box>
  );
};

export interface SnackbarWrapperProps {
  closeFunction: () => void;
  isShowingSpinner?: boolean;
  hideCloseIcon?: boolean;
  id?: string;
  spinnerVariant?: 'indeterminate' | 'determinate';
  value?: number;
  valueCb?: () => number;
  maxwidth?: number;
}
