import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ERelevance } from '../../../irrelevant-apps/irrelevant-apps-types';
import mutation from './remove-app-exclusion.gql';

const removeAppExclusion = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (removeAppExclusionInput: RemoveAppExclusionInput) => {
      try {
        const variables = {
          exclusionId: removeAppExclusionInput.exclusionId,
          relevance: removeAppExclusionInput.relevance,
        };
        const results = await client.mutate<{
          removeAppExclusion: RemoveAppExclusionResponse;
        }>({
          mutation,
          variables: variables,
        });

        if (!results || !results.data) {
          logger.error(
            'Received an invalid response from remove application exclusion',
          );
          throw new Error('Failed to remove application exclusion');
        }

        return results.data.removeAppExclusion;
      } catch (error) {
        logger.error(
          `Failed to remove exclusion for ${removeAppExclusionInput.exclusionId}`,
          error,
        );
        throw new Error('Failed to remove application exclusion');
      }
    },
  };
};

export interface RemoveAppExclusionInput {
  appName: string;
  exclusionId: string;
  relevance: ERelevance;
}

export interface RemoveAppExclusionResponse {
  isSucceded: boolean;
}

export default removeAppExclusion;
