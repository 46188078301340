import { FC } from 'react';
import { Typography } from '@mui/material';
import NodeTooltip from '../NodeTooltip';

const ServiceItem: FC<React.PropsWithChildren<ServiceItemProps>> = ({
  resource,
  service,
  region,
  accountName,
}) => {
  return (
    <NodeTooltip title='Service Details'>
      <Typography variant='caption'>
        Resource: <b>{resource}</b>
      </Typography>
      <Typography variant='caption'>
        Service: <b>{service}</b>
      </Typography>
      <Typography variant='caption'>
        Region: <b>{region}</b>
      </Typography>
      <Typography variant='caption'>
        Account: <b>{accountName}</b>
      </Typography>
    </NodeTooltip>
  );
};

export interface ServiceItemProps {
  resource: string;
  service: string;
  region: string;
  accountName: string;
}

export { ServiceItem };
