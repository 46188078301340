import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchArtifactsInput,
  FetchArtifactsResponse,
} from '../../types/artifacts-type';
import query from './get-artifacts.gql';

const fetchArtifacts = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getArtifactsInput: FetchArtifactsInput, cache: boolean) => {
      try {
        const results = await client.query<FetchArtifactsResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: { getArtifactsInput },
        });
        if (!results.data) {
          logger.error('Failed to fetch artifacts');
          return null;
        }
        return results.data.getArtifacts;
      } catch (e) {
        logger.error('Failed to fetch artifacts', e);
        return null;
      }
    },
  };
};

export interface Input {
  getArtifactsInput: FetchArtifactsInput;
  cache?: boolean;
}
export type Output = { getArtifacts: FetchArtifactsResponse };

export default fetchArtifacts;
