import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ActiveMember } from '../../application-owners-types';
import query from './get-active-members-suggestions.gql';

const getActiveMembersSuggestions = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async () => {
      try {
        const results = await client.query<{
          getMembers: ActiveMember[];
        }>({
          query,
        });
        return results.data.getMembers;
      } catch {
        return null;
      }
    },
  };
};

export default getActiveMembersSuggestions;
