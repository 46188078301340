import { Badge, Theme, Tooltip } from '@mui/material';
import { IconType } from 'ox-common-types';
import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AppFlowIcon } from './AppFlowIcon';

export const AppFlowItem: FC<AppFlowItemProps> = ({
  flowIcon: FlowIcon,
  iconSize,
  servicesNumber,
  toolTip,
}) => {
  const { classes } = useStyles();

  return (
    <Tooltip arrow title={<>{toolTip}</>} className={classes.service}>
      <div>
        {FlowIcon &&
          (servicesNumber ? (
            <Badge badgeContent={servicesNumber} color='primary'>
              <AppFlowIcon iconSize={iconSize}>
                <FlowIcon className={classes.icon} />
              </AppFlowIcon>
            </Badge>
          ) : (
            <AppFlowIcon iconSize={iconSize}>
              <FlowIcon className={classes.icon} />
            </AppFlowIcon>
          ))}
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
  },
  service: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface AppFlowItemProps {
  flowIcon?: IconType | string;
  iconSize: number;
  servicesNumber: number;
  toolTip?: ReactNode;
}
