import { FC, useCallback } from 'react';

import { IconButton, ListItem, Typography, useTheme } from '@mui/material';
import NodeTooltip from '../NodeTooltip';
import { CustomExtLink } from 'ox-react-components';
import { MdContentCopy as ContentCopyIcon } from 'react-icons/md';

const MultipleImages: FC<React.PropsWithChildren<MultipleImagesProps>> = ({
  images,
}) => {
  const theme = useTheme();
  const copyToClipboard = useCallback(
    event => {
      const { imageindex, field } = event.currentTarget.dataset;
      const item = images[imageindex];
      navigator.clipboard.writeText(item[field]);
    },
    [images],
  );

  return (
    <NodeTooltip title={'Workload Images'}>
      {images.map((image, i) => (
        <>
          <Typography
            sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
            variant='caption'
            color='text.secondary'>
            {image.imageId ? image.imageId.split('/')?.pop() : `Image ${i + 1}`}
            {image.imageLink && (
              <CustomExtLink value='' href={image.imageLink} />
            )}
          </Typography>
          {items.map(
            (item, index) =>
              image[item.key] && (
                <ListItem
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'flex-start',
                  }}>
                  <Typography
                    sx={{ minWidth: 60 }}
                    variant='caption'
                    color='text.secondary'>
                    {item.label}:&nbsp;
                  </Typography>

                  <Typography
                    variant='caption'
                    component='span'
                    style={{
                      gap: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}>
                    {image[item.key]}

                    <IconButton
                      data-imageindex={i}
                      data-field={item.key}
                      onClick={copyToClipboard}
                      size='small'>
                      <ContentCopyIcon color='success' fontSize='small' />
                    </IconButton>
                  </Typography>
                </ListItem>
              ),
          )}
        </>
      ))}
    </NodeTooltip>
  );
};

const items = [
  { key: 'imageId', label: 'Image ID' },
  { key: 'hash', label: 'Hash' },
  { key: 'tag', label: 'Tag' },
];
export interface MultipleImagesProps {
  images: Record<string, string>[];
}

export default MultipleImages;
