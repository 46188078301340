import { SeverityType } from 'ox-common-types';

export const resolveRiskLevel = (score: number) => {
  if (score > 125) {
    return SeverityType.Appoxalypse;
  } else if (score > 25) {
    return SeverityType.Critical;
  } else if (score > 15) {
    return SeverityType.High;
  } else if (score > 5) {
    return SeverityType.Medium;
  } else if (score === 0) {
    return SeverityType.Info;
  }
  return SeverityType.Low;
};
