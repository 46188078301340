import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import mutation from './delete-app-owner.gql';
import { logger } from 'logging-utils';
import { Acknowledge } from '../update-app-owner-name-v2/update-app-owner-name';

const deleteAppOwnerV2 = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: DeleteTagsInput): Promise<boolean> => {
      try {
        const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
        const results = await client.mutate<DeleteAppOwnerRes>({
          mutation,
          fetchPolicy,
          variables: {
            ...input,
          },
        });

        return results.data?.deleteAppOwner.acknowledge ?? false;
      } catch (error) {
        logger.error('Failed to delete app owner', error);
        return false;
      }
    },
  };
};

export interface DeleteAppOwnerRes {
  deleteAppOwner: Acknowledge;
}

export interface DeleteTagsFilter {
  tagId: string;
}

export interface DeleteTagsInput {
  input: DeleteTagsFilter;
}

export default deleteAppOwnerV2;
