import { Button, Theme, Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ModalDialog } from './ModalDialog';

export const ModalConfirmationDialog: FC<
  PropsWithChildren<ModalConfirmationDialogProps>
> = ({
  onConfirmation,
  onCloseConfirmationModal,
  isOpen,
  confirmationButtonText,
  cancelButtonText,
  title,
  dialogBody,
  overrideCancelButtonFunction,
}) => {
  const { classes } = useStyles();

  return (
    <ModalDialog
      modalColor=''
      fullWidth
      aria-labelledby='modal-dialog'
      className={classes.confirmationDialog}
      open={isOpen}
      modalHeader={title}
      modalFooter={
        <div
          className={
            !!cancelButtonText ? classes.footer : classes.footerOneButton
          }>
          {!!cancelButtonText && (
            <Button
              size='small'
              color='inherit'
              onClick={
                overrideCancelButtonFunction || onCloseConfirmationModal
              }>
              {cancelButtonText}
            </Button>
          )}
          <Button
            size='small'
            variant='contained'
            color='primary'
            sx={{ borderRadius: 50, minWidth: 100 }}
            onClick={onConfirmation}>
            {confirmationButtonText}
          </Button>
        </div>
      }
      onClose={onCloseConfirmationModal}>
      <div className={classes.body}>
        <Typography variant='body1'>{dialogBody}</Typography>
      </div>
    </ModalDialog>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  confirmationDialog: {
    maxWidth: 500,
    margin: 'auto',
  },
  body: {
    paddingBlockStart: theme.spacing(3),
    paddingBlockEnd: theme.spacing(5),
  },
  footer: {
    paddingBlockStart: theme.spacing(2),
    paddingBlockEnd: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  footerOneButton: {
    width: '100%',
    textAlign: 'center',
  },
}));

interface ModalConfirmationDialogProps {
  onConfirmation?: React.MouseEventHandler<HTMLButtonElement>;
  onCloseConfirmationModal?: () => void;
  title: string;
  dialogBody: string | ReactNode;
  confirmationButtonText: string;
  cancelButtonText?: string;
  isOpen: boolean;
  overrideCancelButtonFunction?: () => void;
}
