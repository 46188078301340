import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export const AppLayout: FC<PropsWithChildren<AppLayoutProps>> = ({
  header,
  aside,
  children,
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const matchesBigScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <div
        className={cx(
          classes.appLayout,
          !matchesBigScreen && classes.appLayoutScroll,
        )}>
        {header}
        <div className={classes.content}>
          {matchesBigScreen && <div className={classes.aside}>{aside}</div>}
          <div className={classes.container}>{children}</div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  appLayout: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },
  appLayoutScroll: {
    height: 'auto',
  },
  content: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      overflowY: 'hidden',
      height: '100%',
    },
  },
  container: {
    overflow: 'hidden',
    height: '100%',
  },
  aside: {
    borderInlineEnd: `1px solid ${theme.palette.divider}`,
    overflowY: 'auto',
  },
}));

interface AppLayoutProps {
  aside: ReactNode;
  header: ReactNode;
}
