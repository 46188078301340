import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import { GetOrgSbomInput } from '../../applications-types';
import query from './export-sbom-to-csv.gql';

const getSelectedSbomCSVExport = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (exportSbomToCsvInput: GetOrgSbomInput) => {
      try {
        const results = await client.query<QueryResponse>({
          query,
          variables: { exportSbomToCsvInput },
          fetchPolicy: ApolloClientCache.NoCache,
        });

        if (!results.data) return null;

        return results.data.exportSbomToCSV;
      } catch (error) {
        logger.error('Failed to fetch org sbom', error);
        return null;
      }
    },
  };
};

interface getSelectedSbomCSVExportRes {
  requestId: string;
}
export interface QueryResponse {
  exportSbomToCSV: Nullable<getSelectedSbomCSVExportRes>;
}

export default getSelectedSbomCSVExport;
