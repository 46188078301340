import {
  pushSlackNotificationToRawIssue as pushSlackNotificationToRawIssueCurrentIssues,
  setShowSendSlackMessageDialog as setShowSendSlackMessageDialogCurrentIssues,
} from '../../active-issues/store-actions/issue-store-actions';
import {
  pushSlackNotificationToRawIssue as pushSlackNotificationToRawIssuePipelineIssues,
  setShowSendSlackMessageDialog as setShowSendSlackMessageDialogPipelineIssues,
} from '../../pipeline-issues/store-actions/pipeline-issues-store-actions';
import { IssuePages } from '../types/issues-types';

export const getIssueActionsByIssuesPage = (issuesPage: IssuePages) => {
  const isPipelineIssuesPage = issuesPage === IssuePages.PipelineIssues;

  const [showSlackDialog, pushSlackNotificationToRawIssue] =
    isPipelineIssuesPage
      ? [
          setShowSendSlackMessageDialogPipelineIssues,
          pushSlackNotificationToRawIssuePipelineIssues,
        ]
      : [
          setShowSendSlackMessageDialogCurrentIssues,
          pushSlackNotificationToRawIssueCurrentIssues,
        ];

  return {
    showSlackDialog,
    pushSlackNotificationToRawIssue,
  };
};
