import { IconType } from 'ox-common-types';
export interface ApplicationFlow {
  cicdInfo: CicdItem[];
  artifacts: AppFlowToolTipItem[];
  orchestrators: AppFlowToolTipItem[];
  cloudDeployments: AppFlowToolTipItem[];
  kubernetes: AppFlowToolTipItem[];
  repository: AppFlowToolTipItem[];
}

export enum ApplicationFlowItemType {
  Repo = 'Repository',
  CICD = 'CI/CD',
  CloudDeployment = 'Cloud Deployment',
  Artifacts = 'Registry',
  Registry = 'Registry',
  Orchestrator = 'Orchestrator',
  Kubernetes = 'Kubernetes',
}

export interface AppFlowItemTooltipsProp {
  [key: string]: {
    title: string;
    content: string[];
    link?: string;
    icon?: IconType;
  };
}

export interface AppFlowToolTipItem {
  hash?: string;
  name: string;
  size?: string;
  system?: string;
  date: string;
  type: string;
  subType: string;
  location?: LocationAppFlow[];
  link?: string;
  linkName?: string;
  k8sType: string;
  imageName: string;
  cluster: string;
  region: string;
}

export interface LocationAppFlow {
  runBy: string;
  foundBy: string;
  foundIn: string;
  link: string;
}

export enum CiCdTypes {
  AzurePipeLine = 'Azure Pipelines',
  GithubActions = 'GitHub Actions',
  Gitlab = 'GitLab CI/CD',
  GitLab = 'Gitlab',
  Jenkins = 'Jenkins',
  CircleCI = 'Circle CI',
  DroneCI = 'Drone CI',
  Buildkite = 'Buildkite',
  BitbucketPipelines = 'Bitbucket Pipelines',
  TravisCI = 'Travis CI',
  CloudBuild = 'Cloud Build',
  Generic = 'Generic',
  TeamCity = 'TeamCity',
}

export enum ArtifactsSubTypes {
  Docker = 'Docker Container',
  Zip = 'Zip Archive',
  Serverless = 'Serverless',
  CloudFront = 'CloudFront',
  NPM = 'NPM',
  YarnSemanticRelease = 'Yarn-Semantic-Release',
}

export enum OrchestratorTypes {
  Unknown = '',
  Ansible = 'Ansible',
  Terraform = 'Terraform',
  TerraformPlan = 'Terraform-Plan',
  Helm = 'Helm',
}

export interface CicdItem {
  type: string;
  system: CiCdTypes;
  latestDate: string;
  location: CicdLocation[];
}

export interface CicdLocation {
  foundBy: string;
  foundIn: string;
  link: string;
}

export enum KubernetesSubTypes {
  Unknown = '',
  Pod = 'Pod',
  Deployment = 'Deployment',
  Service = 'Service',
  Secret = 'Secret',
  PersistentVolume = 'PersistentVolume',
  Ingress = 'Ingress',
  CronJob = 'CronJob',
  Cluster = 'Cluster',
  K8Container = 'K8 Container',
  Kubernetes = 'Kubernetes',
}

export enum ArtifactorySecEventSystem {
  Generic = 'Generic',
  ECR = 'Amazon ECR',
  NPM = 'NPM',
  GitlabRegistry = 'Gitlab Registry',
  LAMBDA = 'Lambda',
  GCPContainer = 'Google Container Registry',
  GCPArtifact = 'Google Artifact Registry',
  JfrogRegistry = 'JFrog Registry',
  DockerHub = 'Docker Hub',
  ACR = 'Azure Container Registry',
  JFrogArtifactory = 'JFrog Artifactory',
  DockerContainer = 'Docker Container',
  GCR = 'Google Container Registry',
  QueryIO = 'Query IO',
}

export enum ArtifactorySecEventType {
  Unknown = '',
  Docker = 'Docker Container',
  Zip = 'Zip Archive',
  Generic = 'Generic',
  Serverless = 'Serverless',
}
