import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import mutation from './delete-member.gql';

const deleteMember = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (userId: string, userEmail: string): Promise<boolean> => {
      try {
        const results = await client.mutate<{
          deleteMember: boolean;
        }>({
          mutation,
          variables: { userId, userEmail },
        });

        if (!results || !results.data) {
          return false;
        }

        return results.data.deleteMember;
      } catch (error) {
        logger.warn(`failed to delete member, ${error}`);
        return false;
      }
    },
  };
};

export default deleteMember;
