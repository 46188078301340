import { env } from 'env-utils';
import { envToBoolean } from './utils/feature-flags-utils';

export enum FeatureFlagKeys {
  scheduledScansSettings = 'scheduledScansSettings',
  scheduledScanTimeSettings = 'scheduledScanTimeSettings',
  excludeFileOrFoldersSettings = 'excludeFileOrFoldersSettings',
  awsCodeCommitCloudformation = 'awsCodeCommitCloudformation',
  reslovedIssues = 'reslovedIssues',
  savedFilters = 'savedFilters',
  protectingXDevsBadge = 'protectingXDevsBadge',
  secretsManagement = 'secretsManagement',
  kubernetesConnectorFamily = 'kubernetesConnectorFamily',
  oxCanRunFastestScan = 'oxCanRunFastestScan',
  oxCanRunFastScan = 'oxCanRunFastScan',
  directEksConnection = 'directEksConnection',
  eksPrivateLink = 'eksPrivateLink',
  aksRunCommandConnectorEnabled = 'aksRunCommandConnectorEnabled',
  excludeByRuleIdFromAllRepos = 'excludeByRuleIdFromAllRepos',
  rbacAppOwners = 'rbacAppOwners',
  rbacTags = 'rbacTags',
  shouldViewIssueTrend = 'shouldViewIssueTrend',
  shouldTrackSeverity = 'shouldTrackSeverity',
  oxPipelineWorkflowsUiEnabled = 'oxPipelineWorkflowsUiEnabled',
  oxPipelineHideConfiguration = 'oxPipelineHideConfiguration',
  defineBranch = 'defineBranch',
  developerCountByCommits = 'developerCountByCommits',
  enableDefaultPipelineSettings = 'enableDefaultPipelineSettings',
  showPipelineDurationStats = 'showPipelineDurationStats',
  scanImagesByTags = 'scanImagesByTags',
  rbacRoles = 'rbacRoles',
  automationFamilyKey = 'automationFamilyKey',
  issueSeverityBreakdownEnabled = 'issueSeverityBreakdownEnabled',
  microsoftOutlookConnector = 'microsoftOutlookConnector',
  shouldShowTicketStatusFilter = 'shouldShowTicketStatusFilter',
  syncOxGroupScopes = 'syncOxGroupScopes',
  syncOxGroupRoles = 'syncOxGroupRoles',
  servicePrincipalConnectorEnabled = 'servicePrincipalConnectorEnabled',
  showConnectorSettings = 'showConnectorSettings',
  extraScanSettingsEnabled = 'extraScanSettingsEnabled',
  disableFullScanOnShift = 'disableFullScanOnShift',
  executiveReportPage = 'executiveReportPage',
  executiveReportPageOuNesting = 'executiveReportPageOuNesting',
  viewPreferencesEnabled = 'viewPreferencesEnabled',
  appOwnersMigration = 'appOwnersMigration',
  allowUserToRequestAccess = 'allowUserToRequestAccess',
  allowUserToRequestAccessConfig = 'allowUserToRequestAccessConfig',
  shouldShowAutoProvisioning = 'shouldShowAutoProvisioning',
  enableMultiTokens = 'enableMultiTokens',
  enableCBOM = 'enableCbom',
  gitlabGroups = 'gitlabGroups',
  azureBoardsConnector = 'azureBoardsConnector',
  asanaConnector = 'asanaConnector',
  serviceNowConnector = 'serviceNowConnector',
  githubIssuesConnector = 'githubIssuesConnector',
  executiveReportsResolvedIssues = 'executiveReportsResolvedIssues',
}

export interface FeatureFlags {
  [FeatureFlagKeys.enableCBOM]: boolean;
  [FeatureFlagKeys.viewPreferencesEnabled]: boolean;
  [FeatureFlagKeys.showConnectorSettings]: boolean;
  [FeatureFlagKeys.disableFullScanOnShift]: boolean;
  [FeatureFlagKeys.issueSeverityBreakdownEnabled]: boolean;
  [FeatureFlagKeys.enableDefaultPipelineSettings]: boolean;
  [FeatureFlagKeys.showPipelineDurationStats]: boolean;
  [FeatureFlagKeys.scheduledScanTimeSettings]: boolean;
  [FeatureFlagKeys.scheduledScansSettings]: boolean;
  [FeatureFlagKeys.excludeFileOrFoldersSettings]: boolean;
  [FeatureFlagKeys.awsCodeCommitCloudformation]: boolean;
  [FeatureFlagKeys.reslovedIssues]: boolean;
  [FeatureFlagKeys.savedFilters]: boolean;
  [FeatureFlagKeys.protectingXDevsBadge]: boolean;
  [FeatureFlagKeys.secretsManagement]: boolean;
  [FeatureFlagKeys.kubernetesConnectorFamily]: boolean;
  [FeatureFlagKeys.oxCanRunFastestScan]: boolean;
  [FeatureFlagKeys.oxCanRunFastScan]: boolean;
  [FeatureFlagKeys.directEksConnection]: boolean;
  [FeatureFlagKeys.eksPrivateLink]: boolean;
  [FeatureFlagKeys.aksRunCommandConnectorEnabled]: boolean;
  [FeatureFlagKeys.excludeByRuleIdFromAllRepos]: boolean;
  [FeatureFlagKeys.rbacAppOwners]: boolean;
  [FeatureFlagKeys.shouldViewIssueTrend]: boolean;
  [FeatureFlagKeys.shouldTrackSeverity]: boolean;
  [FeatureFlagKeys.oxPipelineWorkflowsUiEnabled]: boolean;
  [FeatureFlagKeys.oxPipelineHideConfiguration]: boolean;
  [FeatureFlagKeys.rbacTags]: boolean;
  [FeatureFlagKeys.defineBranch]: boolean;
  [FeatureFlagKeys.developerCountByCommits]: boolean;
  [FeatureFlagKeys.scanImagesByTags]: boolean;
  [FeatureFlagKeys.rbacRoles]: boolean;
  [FeatureFlagKeys.automationFamilyKey]: boolean;
  [FeatureFlagKeys.microsoftOutlookConnector]: boolean;
  [FeatureFlagKeys.syncOxGroupScopes]: boolean;
  [FeatureFlagKeys.syncOxGroupRoles]: boolean;
  [FeatureFlagKeys.extraScanSettingsEnabled]: boolean;
  [FeatureFlagKeys.servicePrincipalConnectorEnabled]: boolean;
  [FeatureFlagKeys.executiveReportPage]: boolean;
  [FeatureFlagKeys.executiveReportPageOuNesting]: boolean;
  [FeatureFlagKeys.appOwnersMigration]: boolean;
  [FeatureFlagKeys.allowUserToRequestAccess]: boolean;
  [FeatureFlagKeys.allowUserToRequestAccessConfig]: boolean;
  [FeatureFlagKeys.shouldShowAutoProvisioning]: boolean;
  [FeatureFlagKeys.enableMultiTokens]: boolean;
  [FeatureFlagKeys.gitlabGroups]: boolean;
  [FeatureFlagKeys.azureBoardsConnector]: boolean;
  [FeatureFlagKeys.asanaConnector]: boolean;
  [FeatureFlagKeys.serviceNowConnector]: boolean;
  [FeatureFlagKeys.githubIssuesConnector]: boolean;
  [FeatureFlagKeys.executiveReportsResolvedIssues]: boolean;
}

export const OnPremFeatureFlags: Record<FeatureFlagKeys, boolean> = {
  enableCbom: envToBoolean(env()[`REACT_APP_FEATURE_FLAG_ENABLE_CBOM`], false),
  viewPreferencesEnabled: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_VIEW_PREFERENCES_ENABLED`],
    false,
  ),
  showConnectorSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SHOW_CONNECTOR_SETTINGS`],
    false,
  ),
  disableFullScanOnShift: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_DISABLE_FULL_SCAN_ON_SHIFT`],
    false,
  ),
  issueSeverityBreakdownEnabled: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ISSUE_SEVERITY_BREAKDOWN_ENABLED`],
    false,
  ),
  enableDefaultPipelineSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ENABLE_DEFAULT_PIPELINE_SETTINGS`],
    false,
  ),
  showPipelineDurationStats: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SHOW_PIPELINE_DURATION_STATS`],
    false,
  ),
  scheduledScansSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SCHEDULED_SCANS_SETTINGS`],
    true,
  ),
  scheduledScanTimeSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SCHEDULED_SCAN_TIME_SETTINGS`],
    true,
  ),
  excludeFileOrFoldersSettings: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXCLUDE_FILE_OR_FOLDERS_SETTINGS`],
    false,
  ),
  awsCodeCommitCloudformation: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_AWS_CODE_COMMIT_CLOUDFORMATION`],
    true,
  ),
  reslovedIssues: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_RESOLVED_ISSUES`],
    false,
  ),
  savedFilters: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SAVED_FILTERS`],
    false,
  ),
  protectingXDevsBadge: true,
  [FeatureFlagKeys.secretsManagement]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SECRETS_MANAGEMENT`],
    false,
  ),
  [FeatureFlagKeys.kubernetesConnectorFamily]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_KUBERNETES_CONNECTOR_FAMILY`],
    false,
  ),
  [FeatureFlagKeys.oxCanRunFastestScan]: false,
  [FeatureFlagKeys.oxCanRunFastScan]: false,

  [FeatureFlagKeys.directEksConnection]: false,
  [FeatureFlagKeys.eksPrivateLink]: false,
  [FeatureFlagKeys.aksRunCommandConnectorEnabled]: false,
  [FeatureFlagKeys.excludeByRuleIdFromAllRepos]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXCLUDE_BY_RULE_ID_FROM_ALL_REPOS`],
    false,
  ),
  [FeatureFlagKeys.rbacAppOwners]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_RBAC_APP_OWNERS`],
    false,
  ),
  [FeatureFlagKeys.shouldViewIssueTrend]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ISSUE_TREND`],
    false,
  ),
  [FeatureFlagKeys.shouldTrackSeverity]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_TRACK_SEVERITY`],
    false,
  ),
  [FeatureFlagKeys.oxPipelineWorkflowsUiEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_PIPELINE_WORKFLOWS_UI_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.oxPipelineHideConfiguration]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_PIPELINE_HIDE_CONFIGURATION`],
    false,
  ),
  [FeatureFlagKeys.rbacTags]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_RBAC_TAGS`],
    false,
  ),
  [FeatureFlagKeys.defineBranch]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_DEFINE_BRANCH`],
    false,
  ),
  [FeatureFlagKeys.developerCountByCommits]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_DEVELOPER_COUNT_BY_COMMITS`],
    false,
  ),
  [FeatureFlagKeys.scanImagesByTags]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SCAN_IMAGES_BY_TAGS`],
    false,
  ),
  [FeatureFlagKeys.rbacRoles]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_RBAC_ROLES`],
    false,
  ),
  [FeatureFlagKeys.automationFamilyKey]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_AUTOMATION_CONNECTOR`],
    false,
  ),
  [FeatureFlagKeys.microsoftOutlookConnector]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_MICROSOFT_OUTLOOK_CONNECTOR`],
    false,
  ),
  [FeatureFlagKeys.shouldShowTicketStatusFilter]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SHOULD_SHOW_TICKET_STATUS_FILTER`],
    false,
  ),
  [FeatureFlagKeys.syncOxGroupScopes]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SYNC_OX_GROUP_SCOPES`],
    false,
  ),
  [FeatureFlagKeys.syncOxGroupRoles]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SYNC_OX_GROUP_ROLES`],
    false,
  ),
  [FeatureFlagKeys.extraScanSettingsEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXTRA_SCAN_SETTINGS_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.servicePrincipalConnectorEnabled]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SERVICE_PRINCIPAL_CONNECTOR_ENABLED`],
    false,
  ),
  [FeatureFlagKeys.executiveReportPage]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXECUTIVE_REPORT_PAGE`],
    false,
  ),
  [FeatureFlagKeys.executiveReportPageOuNesting]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXECUTIVE_REPORT_OU_NESTING`],
    false,
  ),
  [FeatureFlagKeys.appOwnersMigration]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_APP_OWNERS_MIGRATION`],
    false,
  ),
  [FeatureFlagKeys.allowUserToRequestAccess]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ALLOW_USER_TO_REQUEST_ACCESS`],
    false,
  ),
  [FeatureFlagKeys.allowUserToRequestAccessConfig]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ALLOW_USER_TO_REQUEST_ACCESS_CONFIG`],
    false,
  ),
  [FeatureFlagKeys.shouldShowAutoProvisioning]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SHOULD_SHOW_AUTO_PROVISIONING`],
    false,
  ),
  [FeatureFlagKeys.enableMultiTokens]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ENABLE_MULTI_TOKENS`],
    false,
  ),
  [FeatureFlagKeys.gitlabGroups]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_GITLAB_GROUPS`],
    false,
  ),
  [FeatureFlagKeys.azureBoardsConnector]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_AZURE_BOARDS_CONNECTOR`],
    false,
  ),
  [FeatureFlagKeys.asanaConnector]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_ASANA_CONNECTOR`],
    false,
  ),
  [FeatureFlagKeys.serviceNowConnector]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_SERVICE_NOW_CONNECTOR`],
    false,
  ),
  [FeatureFlagKeys.githubIssuesConnector]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_GITHUB_ISSUES_CONNECTOR`],
    false,
  ),
  [FeatureFlagKeys.executiveReportsResolvedIssues]: envToBoolean(
    env()[`REACT_APP_FEATURE_FLAG_EXECUTIVE_REPORTS_RESOLVED_ISSUES`],
    false,
  ),
};
