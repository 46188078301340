import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import {
  OscarApplicationMatrixResponse,
  RawOscarTactic,
} from '../types/oscar-matrix-types';
import query from './oscar-application-matrix-service.gql';

const fetchApplicationMatrixService = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (appId: string): Promise<RawOscarTactic[]> => {
      const results = await client.query<OscarApplicationMatrixResponse>({
        query,
        fetchPolicy: ApolloClientCache.NoCache,
        variables: {
          appId,
        },
      });
      if (!results.data) return [];
      return results.data.getApplicationTechniquesInOscarMatrix.tactics;
    },
  };
};

export default fetchApplicationMatrixService;
