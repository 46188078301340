import { jiraClient } from 'api-clients';
import createJiraTicket from './create-jira-ticket/create-jira-ticket-service';
import getAllSelectableTicketFields from './get-all-selectable-ticket-fields/get-all-selectable-ticket-fields';
import getAssignees from './get-assignees/get-assignees';
import getComponents from './get-components/get-components';
import getDependentByProjectFields from './get-dependent-by-project-fields/get-dependent-by-project-fields';
import getEpics from './get-epics/get-epics';
import getTicketIssues from './get-issues/get-issues';
import getPreSelectTicketFields from './get-pre-select-ticket-fields/get-pre-select-ticket-fields';
import getReporters from './get-reporters/get-reporters';
import searchProjects from './search-projects/search-projects';
import getLabels from './get-labels/get-labels';
import linkJiraTicket from './link-jira-ticket-to-issue/link-jira-ticket-to-issue';
import unlinkJiraTickets from './unlink-jira-tickets-from-issue/unlink-jira-tickets-from-issue';
import getPrioritiesAndLatestProjects from './get-priorities-and-latest-projects/get-priorities-and-latest-projects';
import createBulkJiraTickets from './create-bulk-jira-tickets/create-bulk-jira-tickets';

const jiraService = {
  linkJiraTicket: linkJiraTicket(jiraClient),
  unlinkJiraTickets: unlinkJiraTickets(jiraClient),
  createJiraTicket: createJiraTicket(jiraClient),
  createBulkJiraTickets: createBulkJiraTickets(jiraClient),
  getAllSelectableTicketFields: getAllSelectableTicketFields(jiraClient),
  getPreSelectTicketFields: getPreSelectTicketFields(jiraClient),
  getDependentByProjectFields: getDependentByProjectFields(jiraClient),
  searchProjects: searchProjects(jiraClient),
  searchAssignee: getAssignees(jiraClient),
  searchReporter: getReporters(jiraClient),
  searchLabels: getLabels(jiraClient),
  searchComponents: getComponents(jiraClient),
  searchEpics: getEpics(jiraClient),
  getTicketIssues: getTicketIssues(jiraClient),
  getPrioritiesAndLatestProjects: getPrioritiesAndLatestProjects(jiraClient),
};

export default jiraService;
