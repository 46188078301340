import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  FetchIssueInput,
  FetchPipelineSingleIssueResponse,
} from '../../../common/types/issues-types';
import query from './get-pipeline-issue.gql';

const fetchIssue = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getSingleIssueInput: FetchIssueInput) => {
      const results = await client.query<FetchPipelineSingleIssueResponse>({
        query,
        fetchPolicy: 'network-only',
        variables: { getSingleIssueInput },
      });
      if (!results.data) return null;
      return results.data.getCICDIssue;
    },
  };
};

export default fetchIssue;
