import { Nullable } from 'ox-common-types';
import { Organization } from '../organization-types';

const getLastLoggedInOrg = (): Nullable<Organization> => {
  const localStorageOrg = localStorage.getItem('lastLoggedInOrg');
  const lastLoggedInOrg: Nullable<Organization> = localStorageOrg
    ? JSON.parse(localStorageOrg)
    : null;

  return lastLoggedInOrg;
};

const setLastLoggedInOrg = (organization: Organization): void => {
  localStorage.setItem('lastLoggedInOrg', JSON.stringify(organization));
};

const removeOrgFromLocalStorage = (): void => {
  localStorage.removeItem('lastLoggedInOrg');
};

const getLastLoggedInOrgID = (): string | undefined => {
  const lastLoggedInOrg = getLastLoggedInOrg();

  return lastLoggedInOrg ? lastLoggedInOrg.id : undefined;
};

export {
  getLastLoggedInOrg,
  setLastLoggedInOrg,
  getLastLoggedInOrgID,
  removeOrgFromLocalStorage,
};
