import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable, ServiceExecute } from 'ox-common-types';
import { CancelScanInput, CancelScanResponse } from '../../scan-types';
import mutation from './cancel-scan.gql';

const cancelScan = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<string, Nullable<CancelScanResponse>> => {
  return {
    execute: async (scanId): Promise<Nullable<CancelScanResponse>> => {
      try {
        const cancelScanInput: CancelScanInput = {
          scanId,
        };

        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: { cancelScanInput },
        });

        if (!results || !results.data) {
          throw new Error('Failed to cancel scan, no data received');
        }

        return results.data.cancelScan;
      } catch (error) {
        logger.error('failed to cancel scan', error);
        return null;
      }
    },
  };
};

interface MutationResponse {
  cancelScan: CancelScanResponse;
}

export default cancelScan;
