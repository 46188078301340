import { User } from '@auth0/auth0-react';
import {
  setAppIsEmptyOrg,
  setAppIsInitialized,
  setAppIsReady,
} from 'app-store';
import { AppEvents } from 'common-events';
import { setupWhatsNewListeners } from 'whats-new-logic';
import { setupApplicationsListeners } from '../../applications/listeners/applications-listeners';
import { setupArtifactsListeners } from '../../artifacts/listeners/artifacts-listeners';
import { setupConnectorsListeners } from '../../connectors/listeners/connectors-listeners';
import { setupInventoryListeners } from '../../dashboard-inventory/listeners/dashboard-inventory-listeners';
import { setupDashboardListeners } from '../../dashboard/listeners/dashboard-listeners';
import { setupExclusionsListeners } from '../../exclusions/listeners/exclusions-listeners';
import { setupIssuesListeners } from '../../issues/active-issues/listeners/issues-listeners';
import { setupOnboardingListeners } from '../../onboarding/listeners/onboarding-listeners';
import { setupAppListeners } from '../listeners/app-listeners';
import organizationService from '../services';

export const initApp = () => {
  setupConnectorsListeners();
  setupOnboardingListeners();
  setupIssuesListeners();
  setupApplicationsListeners();
  setupAppListeners();
  setupWhatsNewListeners();
  setupDashboardListeners();
  setupInventoryListeners();
  setupArtifactsListeners();
  setupExclusionsListeners();
  setAppIsInitialized(true);
};

export const checkIfEmptyOrg = async () => {
  const { hasData } = await organizationService.getOrgStatus.execute();
  setAppIsReady(true);
  setAppIsEmptyOrg(!hasData);
  return hasData;
};

export const dispatchAppReady = (user: User) => {
  document.dispatchEvent(
    new CustomEvent(AppEvents.loading.ready, { detail: { user } }),
  );
};
