import { Node } from 'reactflow';
import { AttackPathType } from '../attack-path-types';
import { getMaxChildsInRow } from './edge-utils';
import { getNodeSize } from './get-node-size';
import {
  getHorizontalPadding,
  getVerticalPadding,
} from '../configs/padding-config';
import { getChildDirection } from '../configs/direction-config';
import { getParentNodePosition } from '../configs/position-config';

export const layout = (nodes, edges) => {
  const parentPositions = nodes.map((n, i) => ({
    ...n,
    position: getParentNodePosition(n.data.type, n),
  }));

  const nodesMap = parentPositions.reduce((acc, n) => {
    acc[n.id] = n;
    return acc;
  }, {});

  const root = parentPositions.find(n => n.data.type === AttackPathType.Root);
  const q = [root];
  nodesMap[root.id].level = 0;
  const used = {};
  while (q.length) {
    const item = q.pop();
    const children = edges
      .filter(e => e.source === item.id && nodesMap[e.target])
      .map(e => nodesMap[e.target])
      .filter(n => n.data.type !== AttackPathType.Saas);

    const sorted = [...children].sort((n1, n2) =>
      n1.data.metadata.order > n2.data.metadata.order ? 1 : -1,
    );
    sorted.forEach((c, i) => {
      if (!used[c.id]) {
        nodesMap[c.id].level = nodesMap[item.id].level + 1;

        if (!nodesMap[c.id].position) {
          nodesMap[c.id].position = getChildPosition(
            c,
            i,
            children.length,
            item,
            nodesMap[item.id].level,
          );
        }

        used[c.id] = true;
        q.unshift(c);
      }
    });
  }

  return Object.values<Node>(nodesMap).filter((n: Node) => n.position);
};

const getChildPosition = (child, index, length, parent, depth) => {
  const padding = getHorizontalPadding(parent.data.type);
  const childsInRow = getMaxChildsInRow(parent.data.type);
  const level = Math.floor(index / childsInRow);

  const direction = getChildDirection(parent.data.type);
  const verticalOffset =
    getVerticalPadding(parent.data.type) * (direction === 'Top' ? -1 : 1);
  const parentPosition = parent.position;
  const size = getNodeSize(child.data.type);

  switch (direction) {
    case 'Bottom':
    case 'Top': {
      length = Math.min(childsInRow, length);
      let totalWidth = (length - 1) * size + (length - 1) * padding;
      let startX = parentPosition.x - totalWidth / 2 + 10;

      let childX = startX + (index % childsInRow) * (size + padding);
      let childY = parentPosition.y + verticalOffset + level * 90;

      return { x: childX, y: childY };
    }

    case 'Left': {
      let totalHeight = (length - 1) * size + (length - 1) * padding;
      let startY = parentPosition.y - totalHeight / 2 + 5;
      let childY = startY + index * (size + padding);
      let childX = parentPosition.x - verticalOffset;
      return { x: childX, y: childY };
    }
  }
};
