import { Box, Typography } from '@mui/material';
import { TrendIcon } from 'ox-react-components';
import { FC } from 'react';

const SeverityChangeReason: FC<
  React.PropsWithChildren<SeverityChangeReasonProps>
> = props => {
  const { value, text, shortName, withDescription } = props;
  return (
    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
      <TrendIcon
        tooltip={withDescription ? getSeverityChangeTooltip(value) : ''}
        size={16}
        value={value}
      />
      <Typography variant='body2' color='text.primary'>
        {shortName}
      </Typography>

      {withDescription && (
        <>
          {'—'}
          <Typography variant='body2' color='text.primary'>
            {text}
          </Typography>
        </>
      )}
    </Box>
  );
};

const getSeverityChangeTooltip = (value: number) => {
  if (value < 0) {
    return 'This lowers the severity of the issue.';
  }
  if (value > 0) {
    return 'This increases the severity of the issue.';
  }

  return 'The severity of the issue remains unchanged.';
};

export interface SeverityChangeReasonProps {
  value: number;
  text: string;
  shortName: string;
  withDescription?: boolean;
}

export default SeverityChangeReason;
