import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  IssueSeverityResponse,
  ResetIssueSeverityInput,
} from '../../types/issues-types';
import mutation from './reset-issue-severity.gql';

const resetIssueSeverity = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      resetSeverity: ResetIssueSeverityInput,
    ): Promise<IssueSeverityResponse> => {
      try {
        const results = await client.mutate<ResetIssueSeverityResponse>({
          mutation,
          variables: { issueId: resetSeverity.issueId },
        });
        if (results?.data?.resetIssueSeverity)
          return results.data.resetIssueSeverity;
      } catch (e) {
        logger.error('Faild to change severity to issue', e);
      }
      return { resetIssueSeverity: false };
    },
  };
};

interface ResetIssueSeverityResponse {
  resetIssueSeverity: IssueSeverityResponse;
}

export default resetIssueSeverity;
