import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import ConnectorsStore from '../../connectors/stores/connectors-store';
import { shouldDisableScanAll } from '../../connectors/utils/connectors-utils';

export enum ScanPhase {
  ScanStarted = 'ScanStarted',
  Discovery = 'Discovery',
  Gradual = 'Gradual',
  Done = 'Done',
  Error = 'Error',
  Finalizing = 'finalizing',
  ConnectDataSources = 'connectDataSources',
}

const baseStore = createStore<ScanStoreState>(
  {
    scanID: null,
    phase: ScanPhase.Done,
    isCancelScanLoading: false,
    cancelScanErrorMessage: null,
    isCancelScanModalVisible: false,
    scanCount: 0,
    maxScansToRun: 0,
    isExceedScanLimitModalVisible: false,
    maxReposToScan: 0,
    errorMessage: null,
    isDeltaScan: null,
    isDemoEnabled: false,
    realScanId: null,
    enabledScheduledScans: true,
  },
  'New Scan Store',
);

const ScanStore = derive(
  {
    isScanAllDisabled: (get): boolean => {
      const { phase } = get(baseStore);
      const isScanning =
        phase === ScanPhase.ScanStarted ||
        phase === ScanPhase.Discovery ||
        phase === ScanPhase.Gradual ||
        phase === ScanPhase.ConnectDataSources ||
        phase === ScanPhase.Finalizing;
      const { connectors } = get(ConnectorsStore);
      return shouldDisableScanAll(connectors, isScanning);
    },
    isExceedScanLimit: (get): boolean => {
      const { scanCount, maxScansToRun } = get(baseStore);
      return scanCount >= maxScansToRun;
    },
    isScanning: (get): boolean => {
      const { phase } = get(baseStore);
      return (
        phase === ScanPhase.ScanStarted ||
        phase === ScanPhase.Discovery ||
        phase === ScanPhase.Gradual ||
        phase === ScanPhase.ConnectDataSources ||
        phase === ScanPhase.Finalizing
      );
    },
  },
  {
    proxy: baseStore,
  },
);

export interface ScanStoreState {
  scanID: Nullable<string>;
  phase: ScanPhase;
  isCancelScanLoading: boolean;
  cancelScanErrorMessage: Nullable<string>;
  errorMessage: Nullable<string>;
  isCancelScanModalVisible: boolean;
  scanCount: number;
  isExceedScanLimitModalVisible: boolean;
  maxScansToRun: number;
  maxReposToScan: number;
  isDeltaScan: Nullable<boolean>;
  isDemoEnabled: boolean;
  realScanId: Nullable<string>;
  enabledScheduledScans: boolean;
}

export default ScanStore;
