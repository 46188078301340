import { OneOfValues } from 'ox-common-types';
import { createStore } from 'store-utils';

const ReasonFiltersStore = createStore<ReasonFiltersState>(
  {
    filters: new Array<ReasonFilterType>(),
    isLoadingFilters: false,
  },
  'Reasons Filters Store',
);

export interface ReasonFiltersState {
  filters: Array<string>;
  isLoadingFilters: boolean;
}
export type ReasonFilterType = OneOfValues<typeof REASON_FILTER_OPTIONS>;

export const NO_CODE_CHANGES = 'No code changes';

export const REASON_FILTER_OPTIONS = {
  'Archived Repo': 'Archived',
  'No Access for Cloning': 'FailedClone',
  'No Relevant Files': 'NoFiles',
  [NO_CODE_CHANGES]: 'NoCodeChanges',
  'Repository has been set by client to be irrelevant': 'SetByClient',
} as const;

export default ReasonFiltersStore;
