import { logger } from 'logging-utils';
import { AppOwnerType, Nullable } from 'ox-common-types';
import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { UNASSIGNED_OWNER_VALUE } from '../../../../../apps/web/src/app/components/DataViewSelector/data-view-selector-types';
import { GlobalDataViewSelector } from '../../../../../apps/web/src/app/components/DataViewSelector/global-data-view-selector-store';
import { setSelectedAppOwnersEmails } from '../../../../../apps/web/src/app/components/DataViewSelector/global-data-view-selector-store-actions';
import { loadApplications } from '../../../../../apps/web/src/applications/actions/applications-actions';
import { dispatchUpdateOwners } from '../events/update-owners-event';
import { default as applicationOwnerService } from '../services';
import {
  setAppOwnersByRoleInStore,
  setIsLoadingAppOwnersInStore,
} from '../store-actions/app-owners-store-actions';
import { AppOwnersStore } from '../stores/app-owners-store';
import { getActiveMembers } from './active-members-actions';
import { AppOwnersByRole } from '../application-owners-types';

export const fetchAssignedAppOwners = async (
  appOwnersMigrationFlag: boolean,
) => {
  AppOwnersStore.isLoading = true;
  let owners: Nullable<AppOwnerType[]>;
  try {
    if (appOwnersMigrationFlag) {
      owners = await applicationOwnerService.getApplicationsOwnersV2.execute();
    } else {
      owners = await applicationOwnerService.getApplicationsOwners.execute();
    }

    if (owners) {
      const { selectedAppOwnersEmails } = snapshot(GlobalDataViewSelector);
      const appOwners = owners.filter(i => i.email);
      const assignedAppOwnersEmails = appOwners.map(
        (o: AppOwnerType) => o.email,
      );
      assignedAppOwnersEmails.push(UNASSIGNED_OWNER_VALUE);

      AppOwnersStore.assignedAppOwners = owners;

      if (
        !selectedAppOwnersEmails.every(o => assignedAppOwnersEmails.includes(o))
      ) {
        const displaySelectedAppOwnersUpdated = selectedAppOwnersEmails.filter(
          (o: string) => assignedAppOwnersEmails.includes(o),
        );
        setSelectedAppOwnersEmails([...displaySelectedAppOwnersUpdated]);
      }
    }
  } finally {
    AppOwnersStore.isLoading = false;
  }
};

export const getActiveMembersSuggestions = async () => {
  const activeMembers = await getActiveMembers();
  if (activeMembers) {
    AppOwnersStore.activeMembers = activeMembers.map(member => {
      const { name, email } = member;
      return { name, email };
    });
  }
};

export const submitSetApplicationsOwnersByRole = async (
  appOwnersMigrationFlag: boolean = false,
) => {
  const { selectedOwnersByRole } = snapshot(AppOwnersStore);
  const showError = () => {
    logger.error(
      'Failed to update selected application owners',
      selectedOwnersByRole,
    );
    openSnackbar('Failed to update selected application owners', {
      variant: 'error',
    });
  };
  try {
    setIsLoadingAppOwnersInStore(true);
    let response:
      | ({ isUpdated: boolean } | null | undefined)
      | ({ acknowledge: boolean } | null | undefined);

    if (appOwnersMigrationFlag) {
      response =
        await applicationOwnerService.setApplicationsOwnersByRoleV2.execute(
          selectedOwnersByRole,
        );
    } else {
      response =
        await applicationOwnerService.setApplicationsOwnersByRole.execute(
          selectedOwnersByRole,
        );
    }

    if (response) {
      fetchAssignedAppOwners(appOwnersMigrationFlag);
      dispatchUpdateOwners();
      loadApplications({ cache: false });
      openSnackbar('Selected application owners assigned successfully!', {
        variant: 'success',
      });
    } else {
      showError();
    }
    return response;
  } catch (error) {
    showError();
  } finally {
    AppOwnersStore.isLoading = false;
  }
};

export const setOwnersByRoleFromServer = async (
  selectedRole: string,
  selectedAppIds: string[],
  appOwnersMigrationFlag: boolean = false,
) => {
  let ownersByRoleResponse: AppOwnersByRole[] | null = [];

  setIsLoadingAppOwnersInStore(true);
  if (appOwnersMigrationFlag) {
    ownersByRoleResponse =
      await applicationOwnerService.getApplicationsOwnersByAppIdsV2.execute({
        appIds: selectedAppIds,
        roles: [selectedRole],
      });
  } else {
    ownersByRoleResponse =
      await applicationOwnerService.getApplicationsOwnersByAppIds.execute({
        appIds: selectedAppIds,
        roles: [selectedRole],
      });
  }

  if (ownersByRoleResponse) {
    setAppOwnersByRoleInStore(ownersByRoleResponse);
  }
  setIsLoadingAppOwnersInStore(false);
};
