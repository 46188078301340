import { useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';
import SbomChecks from '../../../../../../../sbom/components/SbomInfoTab/SbomChecks';
import getIssueSbomDataQuery from '../../get-issue-sbom-data.gql';
import issuesService from '../../../../../services';
import { SbomLibraryItem } from '../../../../../../../sbom/sbom-types';
import { Nullable } from 'ox-common-types';

const SBOMChecksTabContainer: React.FC<IssueDrawerTabProps> = ({
  setIsLoading,
  setIsError,
}) => {
  const { selectedIssue } = useSnapshot(IssuesStore);
  const [sbomInfoData, setSbomInfoData] =
    useState<Nullable<SbomLibraryItem>>(null);

  const getIssueSbomData = useCallback(async () => {
    if (selectedIssue) {
      setIsLoading(true);
      const result = await issuesService.getIssueDetailsByQuery.execute({
        issueId: selectedIssue.issueId,
        query: getIssueSbomDataQuery,
      });
      setIsLoading(false);
      if (result?.sbom) {
        result?.sbom && setSbomInfoData(result?.sbom);
      } else {
        setIsError(true);
      }
    }
  }, [selectedIssue, setIsError, setIsLoading]);

  useEffect(() => {
    getIssueSbomData();
  }, [getIssueSbomData]);

  useEffect(() => {
    return () => {
      setIsError(false);
      setIsLoading(false);
    };
  }, [setIsError, setIsLoading]);

  return sbomInfoData ? <SbomChecks sbom={sbomInfoData} /> : null;
};

export default SBOMChecksTabContainer;
