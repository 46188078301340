import ChatGPTStore from '../stores/chat-gpt-store';

export const setQuestionAndAnswerForIssue = (
  issueId: string,
  answer: string,
  question: string,
) => {
  ChatGPTStore.questionsAndAnswersPerIssue[issueId] = {
    answer,
    question,
  };
};

export const setLoadingGptContent = (isLoading: boolean) => {
  ChatGPTStore.loading = isLoading;
};

export const setGptDisclaimerModalOpen = (isOpen: boolean) => {
  ChatGPTStore.isDisclaimerModalOpen = isOpen;
};

export const setGptSnippetModalOpen = (isOpen: boolean) => {
  ChatGPTStore.isSnippetModalOpen = isOpen;
};

export const setGptDisclaimerText = (text: string) => {
  ChatGPTStore.disclaimerText = text;
};

export const setGptError = (didError: boolean) => {
  ChatGPTStore.error = didError;
};

export const setNeverShowDisclaimer = (dontShowDisclaimer: boolean) => {
  ChatGPTStore.neverShowDisclaimer = dontShowDisclaimer;
};

export const setMoveToGptTab = (shouldMove: boolean) => {
  ChatGPTStore.moveToGptTab = shouldMove;
};

export const setDisclaimerAcceptedForIssue = (issueId: string) => {
  ChatGPTStore.disclaimersAcceptedByIssue[issueId] = true;
};

export const setDoNotShowSnippetAgain = (doNotShowSnippet: boolean) => {
  ChatGPTStore.doNotShowSnippetAgain = doNotShowSnippet;
};
