import { Divider, Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { SeverityType } from 'ox-common-types';
import {
  IssueDetails,
  SeverityMappingToName,
} from '../../../../../types/issues-types';
import IssueCardDescription from '../../../../IssueCard/IssueCardDescription';
import IssueCardRecommendation from '../../../../IssueCard/IssueCardRecommendation';
import IssueCardHeader from '../../../../IssueCard/IssueCardHeader';

const IssueSummaryTab: FC<React.PropsWithChildren<IssueSummaryTabProps>> = ({
  selectedIssue,
  filterIssuesBy,
  isEnabledChatGpt,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.issueCard}>
      <div className={classes.twoCols}>
        <div>
          <IssueCardHeader
            oxSeverity={
              SeverityMappingToName(
                selectedIssue.originalSeverity,
              ) as SeverityType
            }
            severityFactorsDiff={selectedIssue.severityFactorsDiff}
            category={selectedIssue.category?.name}
            categoryId={selectedIssue.category?.categoryId}
            appType={selectedIssue.app.type}
            registryType={selectedIssue.correlatedRegistry}
            correlatedIssueId={selectedIssue.correlatedIssueId}
            originalSeverity={selectedIssue.originalToolSeverity}
            previousSeverity={selectedIssue.previousSeverity}
            severityBreakdown={selectedIssue.severityBreakdown}
            severity={selectedIssue.severity}
            overrideSeverity={selectedIssue.overrideSeverity}
            filters={filterIssuesBy}
          />

          <Divider sx={{ mx: 2 }} orientation='vertical' flexItem />
        </div>
      </div>
      <Divider sx={{ mb: 1, mt: 2 }} />
      <div className={classes.twoCols}>
        {selectedIssue.secondTitleMarkup && (
          <div>
            <IssueCardDescription
              isEnabledChatGpt={isEnabledChatGpt}
              learnMoreUrl={selectedIssue.learnMore[0]}
              issueId={selectedIssue.issueId}
              isGptAvailable={selectedIssue.isGPTFixAvailable}
              isResolvedIssuesPage={false}>
              {selectedIssue.secondTitleMarkup}
            </IssueCardDescription>
          </div>
        )}
        {selectedIssue.recommendationMarkup &&
          selectedIssue.secondTitleMarkup && (
            <Divider sx={{ mx: 2 }} orientation='vertical' flexItem />
          )}
        {selectedIssue.recommendationMarkup && (
          <div>
            <IssueCardRecommendation
              withActions
              isResolvedIssuesPage={false}
              fixLink={selectedIssue.fixLink}
              issueId={selectedIssue.issueId}
              fixAvailable={selectedIssue.isFixAvailable}
              prAvailable={selectedIssue.isPRAvailable}>
              {selectedIssue.recommendationMarkup}
            </IssueCardRecommendation>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  category: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  categoryIcon: {
    width: '1em',
    height: '1em',
    flexShrink: 0,
    color: theme.palette.primary.main,
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  issueCard: {
    backgroundColor: theme.palette.background.paper,
  },
  appTypeSummeryScore: {
    display: 'flex',
  },
  appTypeSummery: {
    display: 'flex',
  },
  repoSummery: {
    display: 'flex',
    flexDirection: 'row',
  },
  twoCols: {
    display: 'flex',
    '& > div': {
      flex: '1 1 100%',
    },
  },
}));

interface IssueSummaryTabProps {
  selectedIssue: IssueDetails;
  filterIssuesBy: {};
  isEnabledChatGpt: boolean;
}

export default IssueSummaryTab;
