import organizationService from '../../services';
import { ScopeNames } from '@oxappsec/ox-unified-permissions';
import {
  deleteDataViewSelectorOptions,
  setLoadingDelete,
  setLoadingIdUpdate,
  setLoadingNameUpdate,
  updateDataViewSelectorOptions,
  updateDataViewSelectorOptionsNewId,
} from './global-data-view-selector-store-actions';
import { openSnackbar } from 'snackbar-utils';
import { getDataViewLabels, getScopeDisplayName } from './data-view-utils';

export const updateDataViewName = async (
  scopeName: ScopeNames,
  newName: string,
  id: string,
  appOwnersMigrationFlag: boolean = false,
) => {
  setLoadingNameUpdate(true);
  const { scopeNameLabel } = getDataViewLabels(scopeName);

  const response =
    scopeName === ScopeNames.AppOwner
      ? appOwnersMigrationFlag
        ? await organizationService.updateAppOwnerNameV2.execute({
            input: {
              tagId: id,
              name: newName,
            },
          })
        : await organizationService.updateAppOwnerName.execute({
            name: newName,
            email: id,
          })
      : null; // need to implement this for tags

  if (response) {
    updateDataViewSelectorOptions(id, newName);
    openSnackbar(`${scopeNameLabel} updated successfully`, {
      variant: 'success',
    });
  } else {
    openSnackbar(`Failed to update ${scopeNameLabel}`, {
      variant: 'error',
    });
  }

  setLoadingNameUpdate(false);
  return response;
};

export const updateDataViewId = async (
  scopeName: ScopeNames,
  oldId: string,
  newId: string,
  appOwnersMigrationFlag: boolean = false,
) => {
  setLoadingIdUpdate(true);
  const { idLabel } = getDataViewLabels(scopeName);

  const response =
    scopeName === ScopeNames.AppOwner
      ? appOwnersMigrationFlag
        ? await organizationService.updateAppOwnerEmailV2.execute({
            input: {
              tagId: oldId,
              email: newId,
            },
          })
        : await organizationService.updateAppOwnerEmail.execute({
            oldEmail: oldId,
            newEmail: newId,
          })
      : null; // tags are not support for id update

  if (response) {
    updateDataViewSelectorOptionsNewId(oldId, newId);
    openSnackbar(`${idLabel} updated successfully`, {
      variant: 'success',
    });
  } else {
    openSnackbar(`Failed to update ${idLabel}`, {
      variant: 'error',
    });
  }

  setLoadingIdUpdate(false);
  return response;
};

export const deleteDataViewOption = async (
  scopeName: ScopeNames,
  name: string,
  id: string,
  appOwnersMigrationFlag: boolean = false,
) => {
  setLoadingDelete(true);
  const scopeDisplayName = getScopeDisplayName(scopeName);

  const response =
    scopeName === ScopeNames.AppOwner
      ? appOwnersMigrationFlag
        ? await organizationService.deleteAppOwnerV2.execute({
            input: {
              tagId: id,
            },
          })
        : await organizationService.deleteAppOwner.execute({
            email: id,
          })
      : null; // TODO: need to implement this for tags once tags are supported

  if (response) {
    deleteDataViewSelectorOptions(id);
    openSnackbar(`${scopeDisplayName} (${name}) deleted successfully`, {
      variant: 'success',
    });
  } else {
    openSnackbar(`Failed to delete ${scopeDisplayName} (${name})`, {
      variant: 'error',
    });
  }

  setLoadingDelete(false);
  return response;
};
