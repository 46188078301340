import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  CircularProgress,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { AppPages } from 'app-navigator';
import { important } from 'ox-common-types';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { createNewOrganization } from '../../organizations/api/organizations-api';
import { setRemoveDemoOrg } from '../../organizations/store-actions/create-org-store-actions';
import { useSnapshot } from 'valtio';
import ScanStore from '../store/scan-store';

const DemoScanButton = () => {
  const { classes } = useStyles();

  const { user, loginWithRedirect } = useAuth0();
  const [loading, setLoading] = useState(false);
  const { isScanning } = useSnapshot(ScanStore);

  const handleCreateDemoOrg = useCallback(async () => {
    setRemoveDemoOrg(true);
    setLoading(true);
    const orgName = user?.nickname ? `${user.nickname} Org` : 'MyFirstOrg';
    const organization = await createNewOrganization(orgName);
    if (!organization) {
      return;
    }
    loginWithRedirect({
      organization: `${organization.id}`,
      appState: {
        returnTo: AppPages.Onboarding,
      },
    });
  }, [loginWithRedirect, user]);

  return (
    <>
      <Backdrop open={loading} sx={{ color: '#fff' }}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Tooltip arrow placement='bottom' title={'Connect real data'}>
        <span>
          <LoadingButton
            sx={{ textTransform: 'none', cursor: 'pointer' }}
            color='primary'
            loading={loading || isScanning}
            classes={{ disabled: classes.disabledScan }}
            variant={'contained'}
            onClick={handleCreateDemoOrg}>
            <Typography variant='caption' sx={{ whiteSpace: 'nowrap' }}>
              Connect Real Data Now
            </Typography>
          </LoadingButton>
        </span>
      </Tooltip>
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  disabledScan: {
    color: important('rgba(255, 255, 255, 0.3)'),
    backgroundColor: important('rgba(255, 255, 255, 0.12)'),
    border: important('none'),
  },
}));

export default DemoScanButton;
