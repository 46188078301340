export enum RepoTypes {
  GitHub = 'github',
  GitLab = 'gitlab',
  GitLabSmall = 'gitlab',
  Azure = 'azure',
  AzureRepos = 'azure repos (git)',
  AzureReposTFVC = 'azure repos (tfvc)',
  Buildkite = 'buildkite',
  AzureCloud = 'azure-cloud',
  AzureTfs = 'azure-tfs',
  AzureTFS = 'Azure TFS',
  Bitbucket = 'bitbucket',
  BitbucketStash = 'bitbucket-stash',
  UnattachedEvents = 'unattachedevents',
  AWS = 'aws',
  GCP = 'gcp',
  Artifactory = 'artifactory',
  GcpCloud = 'gcp-cloud',
  KubernetesCloud = 'kubernetes-cloud',
  AwsCloud = 'aws-cloud',
  Git = 'git',
  ArtifactoryStorage = 'artifactory-storage',
  GitHubSettings = 'github-settings',
  TFS = 'azuretfs',
  Gerrit = 'gerrit',
  ECR = 'amazon ecr',
  GCP_CONTAINER = 'google container registry',
  GCP_ARTIFACTS = 'google artifact registry',
  GITLAB_REGISTRY = 'gitlab registry',
  JFROG_REGISTRY = 'jfrog artifactory',
  DOCKER_HUB = 'docker hub',
  AZURE_CONTAINER_REGISTRY = 'azure container registry',
  NEXUS_CONTAINER_REGISTRY = 'nexus container registry',
  GOHARBOR_CONTAINER_REGISTRY = 'goharbor container registry',
  AWS_CODECOMMIT = 'aws codecommit',
  Git_1 = 'Git',
  Kubernetes = 'kubernetes',
}
