import { createIssuesActions } from '../../common/actions/create-issues-actions';
import issuesService from '../../common/services';
import * as storeActions from '../store-actions/issue-store-actions';
import IssuesStore from '../stores/issues-store';

const issuesActions = createIssuesActions(
  IssuesStore,
  issuesService,
  storeActions,
);
export const invalidateIssuesCachedQueries =
  issuesActions.invalidateIssuesCachedQueries;
export const loadIssues = issuesActions.loadIssues;
export const setIssueDrawer = issuesActions.setIssueDrawer;
export const clearSelectedIssue = issuesActions.clearSelectedIssue;
export const setLoadingIssue = issuesActions.setLoadingIssue;
export const handleIssueRowClick = issuesActions.handleIssueRowClick;
export const sortIssues = issuesActions.sortIssues;
export const handleSearchFilterType = issuesActions.handleSearchFilterType;
export const setMenuIssueId = issuesActions.setMenuIssueId;
export const loadStatistics = issuesActions.loadStatistics;
export const addingComment = issuesActions.addingComment;
export const deleteComment = issuesActions.deleteComment;
export const loadingIssueComment = issuesActions.setLoadingIssueComment;
export const updateJiraTicketsLatestStatus =
  issuesActions.updateJiraTicketsLatestStatus;
export const updateTicketsDetailByProvider =
  issuesActions.updateTicketsDetailByProvider;
export const exportIssuesToCSVfile = issuesActions.exportIssuesToFile;
export const changeSeverity = issuesActions.changeSeverity;
export const bulkChangeSeverity = issuesActions.bulkChangeSeverity;
export const resetSeverity = issuesActions.resetSeverity;
export const getIssue = issuesActions.getIssue;
export const exportToolsIssuesToCSVfile =
  issuesActions.exportToolsIssuesToCSVfile;
export const upsertBulkCommentToIssues =
  issuesActions.upsertBulkCommentToIssues;
