export interface WhatsNewItem {
  id: string;
  title: string;
  description: string;
  expiration: Date;
  type: WhatsNewType;
  createdAt: Date;
  links: WhatsNewLinkItem[];
}

export interface RawWhatsNewItem {
  id: string;
  title: string;
  description: string;
  expiration: string;
  type: WhatsNewType;
  createdAt: string;
  links?: WhatsNewLinkItem[];
}

export interface WhatsNewLinkItem {
  URL: string;
  type: 'internal' | 'external';
  text?: string;
}

export interface GetWhatsNewResponse {
  getWhatsNew: {
    items: RawWhatsNewItem[];
  };
}

export interface GetWhatsNewHistoryResponse {
  getWhatsNewHistory: {
    items: RawWhatsNewItem[];
  };
}

export enum WhatsNewType {
  ProductFeature = 'ProductFeature',
  SecurityVulnerability = 'SecurityVulnerability',
  NewUIVersionAvailable = 'NewUIVersionAvailable',
}

export type WhatsNewGroupedItems = Record<string, WhatsNewItem[]>;
