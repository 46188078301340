import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  GetConnectorResourcesInput,
  GetConnectorResourcesResponse,
} from '../../connectors-types';
import query from './get-connector-resources.gql';

const getConnectorResources = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getConnectorResourcesInput: GetConnectorResourcesInput,
      disableCache?: boolean,
    ) => {
      try {
        const results = await client.query<GetConnectorResourcesResponse>({
          query,
          variables: { getConnectorResourcesInput },
          fetchPolicy: disableCache ? 'no-cache' : undefined,
        });

        return results.data;
      } catch (error) {
        logger.error(`Connector's resources query failed: ${error}`);
        logger.error(error);
        throw error;
      }
    },
  };
};

export default getConnectorResources;
