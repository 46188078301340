import { Chip, Theme, Tooltip, TooltipProps, useTheme } from '@mui/material';
import { CategoryId } from '@oxappsec/ox-consolidated-categories/lib/src/ox-categories/types';
import { CategoryRiskNames, SeverityAlert } from 'ox-common-types';
import React, { FunctionComponent } from 'react';
import { selectTopSeverities } from 'severity-utils';
import { makeStyles } from 'tss-react/mui';

export const IssueBadge: FunctionComponent<
  React.PropsWithChildren<IssueBadgeProps>
> = ({
  severitiesAlerts,
  fontSize,
  handleClick,
  top,
  textColor,
  backgroundColor,
  tooltipPlacement,
  metadata,
  appName,
  category,
  isCve,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const topSeverities = selectTopSeverities(severitiesAlerts, top);

  if (!severitiesAlerts) return null;
  const indexedCategoryRiskNames = CategoryRiskNames as Record<string, string>;
  return (
    <div className={classes.riskIssuesContainer}>
      {topSeverities &&
        topSeverities.map((key, index) => {
          // for someone who did slice(0,2) - use top param from props!!!
          return (
            <Tooltip
              arrow
              title={
                key &&
                key[0] &&
                `${indexedCategoryRiskNames[key[0]]} ${isCve ? 'CVE' : ''} `
              }
              key={index}
              placement={tooltipPlacement || 'bottom'}>
              <Chip
                onClick={handleClick}
                variant='outlined'
                label={key && key[1] && key[1]}
                data-name={key && key[0] && key[0]}
                data-code={appName}
                data-metadata={metadata}
                data-category={category}
                size='small'
                sx={{
                  backgroundColor,
                  borderColor:
                    theme.palette.categoryRiskColor[
                      key && key[0] ? key[0] : 'none'
                    ],
                  borderWidth: 2,
                  fontSize: fontSize
                    ? fontSize
                    : theme.typography.body2.fontSize,
                  fontWeight: 600,
                  color: textColor || theme.palette.text.primary,
                }}
              />
            </Tooltip>
          );
        })}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  riskIssuesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export interface IssueBadgeProps {
  severitiesAlerts?: SeverityAlert;
  fontSize?: string;
  handleClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  top?: number;
  tooltipPlacement?: TooltipProps['placement'];
  backgroundColor?: string;
  textColor?: string;
  metadata?: string;
  appName?: string;
  isCve?: boolean;
  category?: CategoryId;
}
