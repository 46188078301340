import { AppOwner, AppOwnerRole, AppOwnerRoleType } from 'ox-common-types';
import { snapshot } from 'valtio';
import { ApplicationRow } from '../../../../../apps/web/src/applications/applications-types';
import { AppOwnersStore } from '../stores/app-owners-store';
import { AppOwnersByRole } from '../application-owners-types';

interface Params {
  selectedRole: AppOwnerRoleType;
  diffEmail?: string;
  reason?: string;
  selectedApplicationRows?: ApplicationRow[];
}

export enum AppOwnersChangeReason {
  RemoveOption = 'removeOption',
  SelectOption = 'selectOption',
  AddOwnerToAllApps = 'addOwnerToAllApps',
  Clear = 'clear',
}

export const changeAppOwnersInStore = (params: Params) => {
  const { appOwnersSuggestions, modifiedApplications } =
    snapshot(AppOwnersStore);

  const removeOwner = (app: ApplicationRow) => {
    let selectedOwner = app.appOwners?.find(
      owner => owner.email === params.diffEmail,
    );
    if (selectedOwner && selectedOwner.roles?.length === 1) {
      const appOwners = app.appOwners?.filter(
        owner => owner.email !== params.diffEmail,
      );
      return appOwners;
    } else if (selectedOwner && selectedOwner.roles?.length > 1) {
      selectedOwner = { ...selectedOwner };
      selectedOwner.roles = selectedOwner.roles?.filter(
        role => role !== params.selectedRole,
      );
      const appOwners = app.appOwners?.map(owner => {
        if (owner.email === params.diffEmail && selectedOwner) {
          return selectedOwner;
        }
        return owner;
      });
      return appOwners;
    } else {
      return app.appOwners;
    }
  };

  const addOwner = (app: ApplicationRow) => {
    const appOwners = app.appOwners ? [...app.appOwners] : [];
    const selectedOwner = appOwnersSuggestions.find(
      owner => owner.email === params.diffEmail,
    );

    let ownerExist = appOwners.find(owner => owner.email === params.diffEmail);
    if (ownerExist) {
      ownerExist = { ...ownerExist };
      ownerExist.roles = [...ownerExist.roles, params.selectedRole];
      return appOwners.map(owner => {
        if (owner.email === params.diffEmail && ownerExist) {
          return ownerExist;
        }
        return owner;
      });
    } else if (selectedOwner) {
      appOwners.push({ ...selectedOwner, roles: [params.selectedRole] });
      return appOwners;
    } else {
      return appOwners;
    }
  };

  const addOwnerToAllApps = (app: ApplicationRow) => {
    const appOwners = app.appOwners ? [...app.appOwners] : [];
    const selectedOwner = appOwnersSuggestions.find(
      owner => owner.email === params.diffEmail,
    );
    let ownerExist = appOwners.find(owner => owner.email === params.diffEmail);
    if (!ownerExist && selectedOwner) {
      appOwners.push({ ...selectedOwner, roles: [params.selectedRole] });
      return appOwners;
    } else if (ownerExist && !ownerExist.roles.includes(params.selectedRole)) {
      ownerExist = { ...ownerExist };
      ownerExist.roles = [...ownerExist.roles, params.selectedRole];
      return appOwners.map(owner => {
        return owner.email === ownerExist?.email ? ownerExist : owner;
      });
    }
    return appOwners;
  };

  const removeAllOwnersInRole = (app: ApplicationRow) => {
    const appOwners = app.appOwners?.reduce((acc: AppOwner[], owner) => {
      if (!owner.roles.includes(params.selectedRole)) {
        acc.push(owner);
      } else if (
        owner.roles.includes(params.selectedRole) &&
        owner.roles.length > 1
      ) {
        const _owner = { ...owner };
        _owner.roles = _owner.roles.filter(
          role => role !== params.selectedRole,
        );
        acc.push(_owner);
      }
      return acc;
    }, []);

    return appOwners;
  };

  const modifiedApps: ApplicationRow[] | undefined = modifiedApplications?.map(
    app => {
      const _app = { ...app };
      switch (params.reason) {
        case AppOwnersChangeReason.RemoveOption:
          _app.appOwners = removeOwner(_app);
          break;
        case AppOwnersChangeReason.SelectOption:
          _app.appOwners = addOwner(_app);
          break;
        case AppOwnersChangeReason.AddOwnerToAllApps:
          _app.appOwners = addOwnerToAllApps(_app);
          break;
        case AppOwnersChangeReason.Clear:
          _app.appOwners = removeAllOwnersInRole(_app);
          break;
      }
      return _app;
    },
  );

  if (modifiedApps) {
    AppOwnersStore.disableEditAppOwnersModalSubmit = false;
    AppOwnersStore.modifiedApplications = [...modifiedApps];
  }
};

export const addNewOwnerToStore = (owner: AppOwner) => {
  const ownerExist = AppOwnersStore.assignedAppOwners.find(
    o => o.email === owner.email,
  );
  if (!ownerExist) {
    AppOwnersStore.assignedAppOwners.push({
      name: owner.name,
      email: owner.email,
    });
    AppOwnersStore.appOwnersSuggestions.push({
      name: owner.name,
      email: owner.email,
    });
    return AppOwnersStore.assignedAppOwners;
  } else {
    return null;
  }
};

export const getAppOwnerNameByEmail = (email: string) => {
  const { assignedAppOwners } = snapshot(AppOwnersStore);
  const appOwner = assignedAppOwners.find(owner => owner.email === email);
  return appOwner?.name || email;
};

export const setAppOwnersByRoleInStore = (ownersByRole: AppOwnersByRole[]) => {
  const { selectedOwnersByRole } = snapshot(AppOwnersStore);
  const filteredRoles = selectedOwnersByRole.filter(role =>
    ownersByRole.find(o => o.role !== role.role),
  );
  const newOwnersData = [...filteredRoles, ...ownersByRole];

  AppOwnersStore.selectedOwnersByRole = newOwnersData;
};

export const updateAppOwnersByRoleInStore = (
  ownersByRole: AppOwnersByRole[],
) => {
  AppOwnersStore.disableEditAppOwnersModalSubmit = false;
  setAppOwnersByRoleInStore(ownersByRole);
};

export const clearAppOwnersStore = () => {
  AppOwnersStore.selectedOwnersByRole = [];
  AppOwnersStore.selectedRole = AppOwnerRole.Dev;
};

export const setIsLoadingAppOwnersInStore = (isLoading: boolean) => {
  AppOwnersStore.isLoading = isLoading;
};

export const setSelectedRoleInStore = (role: AppOwnerRoleType) => {
  AppOwnersStore.selectedRole = role;
};
