import { ReactComponent as InfoIcon } from '../assets/info.svg';
import { ReactComponent as SeverityIcon } from '../assets/severity.svg';
import { FaFolderOpen } from 'react-icons/fa';
import { ReactComponent as CweIcon } from '../assets/cwe.svg';
import { ReactComponent as OscarIcon } from '../assets/oscar.svg';
import { FaUserCircle } from 'react-icons/fa';
import { ReactComponent as DateIcon } from '../assets/calendar_month.svg';
import { ReactComponent as FileIcon } from '../assets/description.svg';
import { ReactComponent as ComplianceIcon } from '../assets/fact_check.svg';
import { ReactComponent as JiraIcon } from '../assets/jira.svg';
import { ReactComponent as CommitIcon } from '../assets/commit.svg';
import { ReactComponent as SlackIcon } from '../assets/slack.svg';
import { ReactComponent as ActionIcon } from '../assets/bolt.svg';

import { MdOutlineUpdate } from 'react-icons/md';
import { resolveLanguageIcon, resolveRepoIcon } from 'common-icons';
import { generateIcons, resolveIssueCategoryIcon } from 'application-utils';
import { FunctionComponent } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IoIosCode } from 'react-icons/io';
import { Avatar, Box, Typography } from '@mui/material';
import { AppPages, navigateWithParameters } from 'app-navigator';
import {
  MdCardTravel,
  MdDomainVerification,
  MdDiversity3,
  MdHistoryEdu,
} from 'react-icons/md';

import { FaShareNodes } from 'react-icons/fa6';
import { resolveFrameworkIcon } from '../../api-inventory/api-inventory-utils';
import { BsCodeSquare } from 'react-icons/bs';
import { IoEarthOutline } from 'react-icons/io5';
import { FaRegImages } from 'react-icons/fa';
import { AiOutlineGlobal } from 'react-icons/ai';
import { BiGitRepoForked } from 'react-icons/bi';
import { CiHashtag } from 'react-icons/ci';
import { FaCodePullRequest, FaUserGroup } from 'react-icons/fa6';
import { FiPackage } from 'react-icons/fi';
import { MdOutlineWebhook } from 'react-icons/md';
import { getServiceIcon } from 'ox-react-components/src/AppFlow/utils/cloud-deployment-icons-utils';
import { ReactComponent as TagIcon } from '../assets/gpp_maybe.svg';
import { severities } from 'severity-utils';
import { BiTargetLock } from 'react-icons/bi';
import IntelligenceIcon from '../assets/intelligence';
import { ReactComponent as DetectionIcon } from '../assets/detection.svg';
import { ReactComponent as AmazonIcon } from '../assets/amazon.svg';
import { ReactComponent as AzureIcon } from '../assets/azure.svg';
import { ReactComponent as GoogleCloudIcon } from '../assets/google-cloud.svg';

import {
  getCheckColor,
  getComplianceIcon,
  getSeverityCategoryIcon,
  getSeverityChangeIcon,
  getVulnerabilityIcon,
  resolvePriorityColor,
} from '../utils/utils';
import { SaasBomIcons } from './saasbom-config';
import { artifactIconMap } from 'ox-react-components';
export const NodeConfig = {
  ApplicationLoadBalancer: metadata => ({
    Icon: resolveCloudIcon(metadata.cloudType?.toLowerCase()),
  }),
  CloudLoadBalancer: metadata => ({
    Icon: resolveCloudIcon(metadata.cloudType?.toLowerCase()),
  }),
  CloudNetworkLoadBalancer: metadata => ({
    Icon: resolveCloudIcon(metadata.cloudType?.toLowerCase()),
  }),
  Image: metadata => ({
    Icon: artifactIconMap.get(metadata.registryType),
    size: 60,
  }),
  ImageArtifact: (metadata, theme) => ({
    Icon: FaExternalLinkAlt,
    backgroundColor: theme.palette.primary.main,
    iconColor: 'white',
    onClick: () => window.open(metadata.link, '_blank'),
  }),
  ImageId: metadata => ({
    Icon: FaRegImages,
    iconColor: 'rgb(71 122 232)',
  }),
  K8sApplication: () => ({ Icon: resolveRepoIcon('kubernetes') }),
  K8sIngress: () => ({ Icon: resolveRepoIcon('kubernetes') }),
  K8sLoadBalancer: () => ({ Icon: resolveRepoIcon('kubernetes') }),
  // CloudLoadBalancer: () => ({ Icon: resolveRepoIcon('kubernetes') }),
  ApiSource: () => ({
    Icon: BiGitRepoForked,
  }),

  ApiCount: () => ({
    Icon: CiHashtag,
  }),
  Internet: () => ({
    Icon: AiOutlineGlobal,
    size: 60,
    iconColor: 'white',
    backgroundColor: '#5C94ED',
  }),

  CloudRegion: () => ({ Icon: IoEarthOutline, iconColor: '#5C94ED' }),
  CloudLink: (metadata, theme) => ({
    Icon: FaExternalLinkAlt,

    backgroundColor: theme.palette.primary.main,
    iconColor: 'white',
    onClick: () =>
      window.open(
        decodeURIComponent(decodeURIComponent(metadata.link)),
        '_blank',
      ),
  }),
  CloudDns: () => ({ Icon: AiOutlineGlobal, iconColor: '#5C94ED' }),

  K8sLink: (metadata, theme) => ({
    Icon: FaExternalLinkAlt,

    backgroundColor: theme.palette.primary.main,
    iconColor: 'white',
    onClick: () =>
      window.open(
        decodeURIComponent(decodeURIComponent(metadata.link)),
        '_blank',
      ),
  }),
  K8s: metadata => ({
    Icon: resolveRepoIcon('kubernetes'),
    size: 60,
  }),
  Cloud: metadata => ({
    Icon: resolveCloudIcon(metadata?.cloudType?.toLowerCase()),
    size: 60,
  }),
  K8sImageId: metadata => ({
    Icon: FaRegImages,
    iconColor: 'rgb(71 122 232)',
  }),

  ApiFunction: metadata => ({
    Icon: BsCodeSquare,
  }),
  ApiFramework: metadata => ({
    Icon: resolveFrameworkIcon(metadata.framework.toLowerCase()),
  }),
  ApiLink: (metadata, theme) => ({
    Icon: FaExternalLinkAlt,
    backgroundColor: theme.palette.primary.main,
    iconColor: 'white',
    onClick: () =>
      navigateWithParameters(
        AppPages.ApiInventory,
        {
          filters: { apps: [metadata.appName] },
        },
        false,
      ),
  }),
  Api: (metadata, theme) => ({
    Icon: FaShareNodes,
    size: 60,
    backgroundColor: metadata.total
      ? theme.palette.categoryRiskColor['info']
      : '#FFC051',
    iconColor: 'white',
  }),
  ApiFileName: () => ({ Icon: FileIcon }),
  AppLink: (metadata, theme) => ({
    Icon: FaExternalLinkAlt,
    backgroundColor: theme.palette.primary.main,
    iconColor: 'white',
    onClick: () =>
      window.open(`${AppPages.Applications}?appId=${metadata.id}`, '_blank'),
  }),
  IssueOwner: (_, theme) => ({
    Icon: FaUserCircle,
    size: 60,
    iconColor: theme.palette.primary.main,
  }),
  Oscar: () => ({ Icon: OscarIcon, iconScale: 0.6, size: 60 }),
  OscarItem: metadata => ({
    Icon: OscarIcon,
    iconScale: 0.6,
    size: metadata.single ? 60 : 40,
  }),
  Cwe: (metadata, theme) => ({
    Icon: CweIcon,
    iconScale: 0.6,
    size: 60,
    borderColor:
      theme.palette.categoryRiskColor[metadata.severity?.toLowerCase()],
  }),
  CweItem: (metadata, theme) => {
    const color =
      theme.palette.categoryRiskColor[metadata.severity?.toLowerCase()];
    return {
      Icon: CweIcon,
      iconScale: 0.6,
      size: metadata.single ? 60 : 40,
      borderColor: color,
      badge: (
        <Box
          display='inline-flex'
          borderRadius={5}
          px={1}
          py={0.5}
          sx={{ backgroundColor: color }}>
          <Typography color='white' variant='body2'>
            {metadata.severity}
          </Typography>
        </Box>
      ),
    };
  },
  Compliance: () => ({ Icon: ComplianceIcon, size: 60, iconColor: '#5C94ED' }),
  ComplianceItem: metadata => ({
    Icon: getComplianceIcon(metadata.standardProvider),
  }),
  SeverityFactor: () => ({ Icon: SeverityIcon, size: 80, iconScale: 1 }),
  Commit: () => ({ Icon: CommitIcon, size: 60 }),
  CommitItem: metadata => ({
    Icon: CommitIcon,
    size: metadata.single ? 60 : 50,
  }),
  CommitItemCommitBy: () => ({ Icon: FaUserCircle }),
  User: () => ({ Icon: FaUserGroup, size: 60, iconColor: '#5C94ED' }),
  Repos: () => ({ Icon: IoIosCode, size: 60 }),
  ReposItem: () => ({ Icon: IoIosCode, size: 50 }),
  Webhook: () => ({ Icon: MdOutlineWebhook, size: 50, iconColor: '#5C94ED' }),
  SBOMRepos: () => ({ Icon: IoIosCode, size: 60 }),
  SBOMReposItem: () => ({ Icon: IoIosCode, size: 50 }),
  Artifact: () => ({ Icon: FiPackage, size: 60, iconColor: '#6837FF' }),
  ArtifactItem: () => ({ Icon: FiPackage, size: 50, iconColor: '#6837FF' }),
  ArtifactIntegrity: () => ({
    Icon: FiPackage,
    size: 60,
    iconColor: '#6837FF',
  }),
  ArtifactIntegrityItem: () => ({
    Icon: FiPackage,
    size: 5,
    iconColor: '#6837FF',
  }),
  WebhookItem: () => ({
    Icon: MdOutlineWebhook,
    size: 50,
    iconColor: '#5C94ED',
  }),
  CommitItemCommitDate: () => ({ Icon: DateIcon }),
  CommitItemCommitReviewedBy: () => ({ Icon: FaUserCircle }),
  CommitItemFile: () => ({ Icon: FileIcon }),
  Library: (metadata, theme) => ({
    Icon: FaFolderOpen,
    size: 60,
    iconColor: metadata.libraryIssue
      ? theme.palette.categoryRiskColor.high
      : theme.palette.categoryRiskColor.passed,
  }),
  Popularity: (metadata, theme) => ({
    Icon: MdDiversity3,
    iconColor: getCheckColor(metadata.notPopular, theme),
  }),
  Maintenance: (metadata, theme) => ({
    Icon: MdDomainVerification,
    iconColor: getCheckColor(metadata.notMaintained, theme),
  }),
  License: (metadata, theme) => ({
    Icon: MdHistoryEdu,
    iconColor: getCheckColor(metadata.licenseIssue, theme),
  }),
  Update: (metadata, theme) => ({
    Icon: MdOutlineUpdate,
    iconColor: getCheckColor(metadata.notUpdated, theme),
  }),
  Action: () => ({ Icon: ActionIcon, size: 60 }),
  Slack: () => ({ Icon: SlackIcon, size: 50 }),
  SlackItem: () => ({ Icon: SlackIcon, size: 30 }),
  JiraTicket: () => ({ Icon: JiraIcon, size: 50 }),
  JiraTicketItem: () => ({ Icon: JiraIcon, size: 30 }),
  Pr: () => ({ Icon: FaCodePullRequest, size: 50 }),
  PrItem: () => ({ Icon: FaCodePullRequest, size: 30 }),
  BusinessPriority: (metadata, theme) => {
    const color = resolvePriorityColor(metadata.priority, theme);
    return {
      Icon: MdCardTravel,
      borderColor: color,
      backgroundColor: 'white',
      iconColor: '#DA6F0D',
      badge: (
        <Box
          display='inline-flex'
          borderRadius={5}
          px={2}
          py={0.5}
          sx={{ backgroundColor: color }}>
          <Typography color='white' variant='body2'>
            {Math.ceil(metadata.priority)}
          </Typography>
        </Box>
      ),
    };
  },
  Cve: (metadata, theme) => {
    const color =
      theme.palette.categoryRiskColor[metadata.originalSeverity?.toLowerCase()];
    return {
      Icon: BiTargetLock,
      borderColor: color,
      iconColor: color,
      size: 60,
    };
  },
  Tag: () => ({ size: 40, Icon: TagIcon }),
  TagItem: metadata => ({
    size: 40,
    Icon: TagIcon,
    withBadgePulse: true,
    badge: (
      <Box
        display='inline-flex'
        borderRadius={5}
        px={2}
        py={0.5}
        sx={{ backgroundColor: '#42445A' }}>
        <Typography color='white' variant='body2'>
          {metadata.type}
        </Typography>
      </Box>
    ),
  }),
  Detection: () => ({ Icon: DetectionIcon, iconScale: 1, size: 80 }),
  Intelligence: (metadata, theme) => ({
    Icon: IntelligenceIcon,
    size: 80,
    iconScale: 1,
    iconColor:
      theme.palette.categoryRiskColor[metadata.severity?.toLowerCase()],
  }),
  FirstSeen: () => ({ Icon: DateIcon, size: 60 }),
  SeverityFactorCategory: (metadata, theme) => ({
    Icon: getSeverityCategoryIcon(metadata.changeCategory),
    iconColor:
      metadata.changeNumber === 0
        ? theme.palette.categoryRiskColor.info
        : metadata.changeNumber > 0
        ? theme.palette.categoryRiskColor.high
        : theme.palette.categoryRiskColor.passed,
    size: 60,
  }),
  SeverityFactorChange: metadata => ({
    Icon: getSeverityChangeIcon(metadata.changeNumber),
    withPulse: metadata.changeNumber > 0,
  }),

  CveItem: (metadata, theme) => {
    const severity = metadata.originalSeverity?.toLocaleLowerCase();
    let color = theme.palette.categoryRiskColor[severity];
    const value = severities.find(
      s => s.title?.toLocaleLowerCase() === severity,
    )?.value;
    return {
      Icon: getVulnerabilityIcon(metadata.vulType),
      iconScale: 0.6,
      size: metadata.single ? 60 : 40,
      borderColor: color,
      widthBadgePulse: value && value >= 4,
      badge: metadata.cvss && (
        <Box
          display='inline-flex'
          borderRadius={5}
          px={1}
          py={0.5}
          sx={{ backgroundColor: color }}>
          <Typography variant='body2' color='white'>
            {metadata.cvss}
          </Typography>
        </Box>
      ),
    };
  },
  UserItem: (metadata, theme) => ({
    Icon: () => <Avatar src={metadata.userAvatar} />,
    size: 50,
  }),
  Service: metadata => {
    const { RepoIcon } =
      metadata.type && generateIcons(undefined, metadata.type?.toLowerCase());
    return {
      Icon: RepoIcon || InfoIcon,
      size: 60,
    };
  },
  ServiceItem: metadata => ({
    Icon: getServiceIcon(metadata.service) || InfoIcon,
    size: 50,
  }),
  App: (metadata, theme) => {
    const { RepoIcon } = generateIcons(undefined, metadata.type?.toLowerCase());
    return {
      Icon: RepoIcon,
      backgroundColor: resolvePriorityColor(metadata.priority, theme),
      size: 60,
    };
  },
  Language: (metadata, theme) => ({
    size: 60,
    Icon: resolveLanguageIcon(metadata.name),
    iconColor: theme.palette.primary.main,
  }),
  AppLanguage: metadata => ({
    Icon: resolveLanguageIcon(metadata?.language?.language?.toLowerCase()),
  }),
  Root: (metadata, theme) => {
    let color =
      theme.palette.categoryRiskColor[metadata.severity?.toLocaleLowerCase()];
    return {
      borderColor: color,
      backgroundColor: color,
      withBadgePulse: true,
      size: 80,
      iconColor: 'white',
      Icon:
        (resolveIssueCategoryIcon(
          metadata.categoryName,
        ) as FunctionComponent) || null,
      badge: (
        <Box
          display='inline-flex'
          borderRadius={5}
          px={2}
          py={0.5}
          sx={{ backgroundColor: color }}>
          <Typography color='white' variant='body2'>
            {metadata.severity}
          </Typography>
        </Box>
      ),
    };
  },
  Saas: (metadata, theme) => {
    const { displayName } = metadata;
    return {
      ...SaasBomIcons[displayName],
      size: 40,
    };
  },
};

export const resolveCloudIcon = (type: string) => {
  switch (type) {
    case 'aws':
      return AmazonIcon;
    case 'azure':
      return AzureIcon;
    case 'google':
      return GoogleCloudIcon;
  }
};
