import { Tabs, TabsProps } from '@mui/material';
import { FC } from 'react';

export const OxTabs: FC<React.PropsWithChildren<OxTabsProps>> = props => {
  const { selectedTab, children, ...tabsProps } = props;

  return (
    <Tabs
      data-testid='ox-tabs'
      value={selectedTab}
      indicatorColor='primary'
      {...tabsProps}>
      {children}
    </Tabs>
  );
};

interface OxTabsProps extends TabsProps {
  selectedTab: number | string;
}
