import PoliciesProfileUIStateStore from '../../store/policies-profile-ui';

export const setShowSaveAsModal = (visible: boolean) => {
  PoliciesProfileUIStateStore.showSaveAsModal = visible;
};

export const setShowUnSavedProfileChangesModal = (visible: boolean) => {
  PoliciesProfileUIStateStore.showUnSavedProfileChanges = visible;
};

export const enablePoliciesSaveButton = (enableSave: boolean) => {
  PoliciesProfileUIStateStore.disableSavePoliciesButton = !enableSave;
};

export const setSavePoliciesInProgress = (inProgress: boolean) => {
  PoliciesProfileUIStateStore.savePoliciesInProgress = inProgress;
};

export const setLoadingProfilePoliciesByCategory = (inProgress: boolean) => {
  PoliciesProfileUIStateStore.loadingProfilePoliciesByCategory = inProgress;
};

export const setLoadingProfile = (inProgress: boolean) => {
  PoliciesProfileUIStateStore.loadingProfileOverview = inProgress;
};

export const setChangeProfileInContextId = (profileInContextId: string) => {
  PoliciesProfileUIStateStore.changeProfileInContextId = profileInContextId;
};

export const setGeneralPoliciesPageError = (
  generalPoliciesPageError: {
    errorTitle: string;
    errorMsg: string;
  } | null,
) => {
  PoliciesProfileUIStateStore.generalPoliciesPageError =
    generalPoliciesPageError;
};

export const setCreateNewProfileInProgress = (inProgress: boolean) => {
  PoliciesProfileUIStateStore.createNewProfileInProgress = inProgress;
};
