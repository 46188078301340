import { isValidOrgName } from 'common-utils';
import { getInvalidOrgDisplayNameHelperText } from 'organizations';
import { createStore, derive } from 'store-utils';

const baseStore = createStore<CreateOrganizationState>(
  {
    orgDisplayName: '',
    isModalVisible: false,
    isCreateOrgEnabled: false,
    isOrgCreatedSuccessfully: false,
    message: '',
    isLoading: false,
    removeDemoOrg: false,
  },
  'Create Organization Store',
);

const CreateOrganizationStore = derive(
  {
    isValidOrgDisplayName: get => {
      const { orgDisplayName } = get(baseStore);
      return isValidOrgName(orgDisplayName);
    },
    invalidOrgDisplayNameHelperText: get => {
      const { orgDisplayName } = get(baseStore);
      return getInvalidOrgDisplayNameHelperText(orgDisplayName);
    },
  },
  {
    proxy: baseStore,
  },
);

interface CreateOrganizationState {
  orgDisplayName: string;
  isModalVisible: boolean;
  isCreateOrgEnabled: boolean;
  isOrgCreatedSuccessfully: boolean;
  message: string;
  isLoading: boolean;
  removeDemoOrg: boolean;
}

export default CreateOrganizationStore;
