import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import IssueCardPRApplied from '../../../../IssueCard/IssueCardPRApplied';

const PrAppliedTabContainer: FC = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);

  return (
    <>
      {selectedIssue?.fixIssue && (
        <IssueCardPRApplied prDeatils={selectedIssue.prDeatils} />
      )}
    </>
  );
};

export default PrAppliedTabContainer;
