import {
  FormControlLabel,
  IconButton,
  Radio,
  Theme,
  Typography,
} from '@mui/material';
import { DateUnit, IDateRange } from 'ox-common-types';
import { ChangeEvent, FC } from 'react';
import {
  MdArrowDropDown as ArrowDropDownIcon,
  MdArrowDropUp as ArrowDropUpIcon,
  MdRadioButtonChecked as RadioButtonCheckedIcon,
  MdRadioButtonUnchecked as RadioButtonUncheckedIcon,
} from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

const icon = <RadioButtonUncheckedIcon fontSize='small' />;
const checkedIcon = <RadioButtonCheckedIcon fontSize='small' />;

const DateRadioOption: FC<React.PropsWithChildren<DateRadioOptionProps>> = ({
  handleChange,
  filter,
  limit,
  unit,
  handleLimitChange,
}) => {
  const { classes } = useStyles();
  const checked = filter.unit === unit && filter.limit === limit;
  return (
    <FormControlLabel
      className={classes.radioItem}
      disableTypography
      control={
        <Radio
          size='small'
          name='unit'
          onChange={handleChange}
          checked={checked}
          value={unit}
          icon={icon}
          checkedIcon={checkedIcon}
        />
      }
      label={
        <div className={classes.optionLabel}>
          {handleLimitChange && (
            <span className={classes.limit}>
              <IconButton
                disabled={filter.unit !== unit}
                onClick={handleLimitChange(limit + 1)}
                size='small'
                className={classes.limitButton}>
                <ArrowDropUpIcon
                  color={filter.unit !== unit ? 'disabled' : 'primary'}
                  fontSize='medium'
                />
              </IconButton>
              <IconButton
                disabled={filter.unit !== unit}
                onClick={handleLimitChange(limit - 1)}
                size='small'
                className={classes.limitButton}>
                <ArrowDropDownIcon
                  color={filter.unit !== unit ? 'disabled' : 'primary'}
                  fontSize='medium'
                />
              </IconButton>
            </span>
          )}
          <Typography
            textTransform='capitalize'
            variant='caption'
            color={checked ? 'primary' : 'text.primary'}>
            {limit} {unit}
            {limit > 1 ? 's' : ''}
          </Typography>
        </div>
      }
    />
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  optionLabel: {
    gap: theme.spacing(1.5),
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  limit: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  limitButton: {
    padding: '0 !important',
    width: '0.7rem',
    height: '0.7rem',
  },
  radioItem: {
    margin: '0 !important',
    '&:hover': {
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

export interface DateRadioOptionProps {
  limit: number;
  unit: DateUnit;
  filter: IDateRange;
  handleChange?: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  handleLimitChange?: (limit: number) => (() => void) | undefined;
}

export default DateRadioOption;
