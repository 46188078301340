import { Box, Link, Typography, useTheme } from '@mui/material';
import { Nullable } from 'ox-common-types';
import { ModalDialog } from 'ox-react-components';
import { FC } from 'react';

const ExceedScanLimitModal: FC<
  React.PropsWithChildren<ExceedScanLimitModalProps>
> = props => {
  const theme = useTheme();
  const { open, onClose, maxScansAllowed } = props;

  return (
    <ModalDialog
      modalColor={theme.palette.info.main}
      aria-label='Scan quota exceeded'
      open={open}
      onClose={onClose}
      modalHeader='Scan quota exceeded'>
      <Box flexGrow={1} mb={2} gap={1} display='flex'>
        <Typography color={theme.palette.text.primary} variant='body2'>
          Your membership tier is limited to
        </Typography>
        <Typography
          color={theme.palette.text.primary}
          variant='body2'
          fontWeight='bold'>
          {maxScansAllowed} scans
        </Typography>
      </Box>
      <Box>
        <Typography color={theme.palette.text.primary} variant='body2'>
          Please contact us to change your current plan:{' '}
          <Link href={'https://www.ox.security/contact'} target='_blank'>
            Contact Us
          </Link>
        </Typography>
      </Box>
    </ModalDialog>
  );
};

interface ExceedScanLimitModalProps {
  open: boolean;
  onClose: () => void;
  maxScansAllowed: Nullable<number>;
}

export default ExceedScanLimitModal;
