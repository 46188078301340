import { Alert, AlertColor, AlertProps } from '@mui/material';
import { forwardRef } from 'react';
import {
  MdErrorOutline as ErrorIcon,
  MdInfo as InfoIcon,
  MdCheckCircleOutline as SuccessIcon,
  MdWarningAmber as WarningIcon,
} from 'react-icons/md';

const iconsMap = new Map<AlertColor, React.ReactNode>([
  ['info', <InfoIcon />],
  ['warning', <WarningIcon />],
  ['success', <SuccessIcon />],
  ['error', <ErrorIcon />],
]);

export const IconAlert = forwardRef<HTMLDivElement, AlertProps>(
  (props, ref) => {
    const { color } = props;
    const icon = color ? iconsMap.get(color) : null;
    return <Alert icon={icon} {...props} ref={ref} />;
  },
);
