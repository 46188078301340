import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import mutation from './remove-alert-exclusion.gql';

const removeAlertExclusion = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (removeAlertExclusionInput: RemoveAlertExclusionInput) => {
      try {
        const results = await client.mutate<{
          removeAlertExclusion: RemoveAlertExclusionResponse;
        }>({
          mutation,
          variables: { exclusionId: removeAlertExclusionInput.exclusionId },
        });

        if (!results || !results.data) {
          logger.error(
            'Received an invalid response from remove issue exclusion',
          );
          throw new Error('Failed to remove issue exclusion');
        }

        return results.data.removeAlertExclusion;
      } catch (error) {
        logger.error(
          `Failed to remove exclusion for ${removeAlertExclusionInput.exclusionId}`,
          error,
        );
        throw new Error('Failed to remove issue exclusion');
      }
    },
  };
};

export interface RemoveAlertExclusionInput {
  exclusionId: string;
  issueName: string;
}

export interface RemoveAlertExclusionResponse {
  id: string;
}

export default removeAlertExclusion;
