import { useAuth0 } from '@auth0/auth0-react';
import { OrganizationsStore } from 'organizations';
import { FC, SyntheticEvent, useCallback, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import OrganizationSelector from '../components/OrganizationSelector';
import { dispatchChosenOrgUpdatedEvent } from '../dispatchers/organizations-dispatchers';
import { Organization } from '../organization-types';
import { setChosenOrganization } from '../store-actions/organization-store-actions';
import { setLastLoggedInOrg } from '../utils/local-storage-utils';

const OrganizationSelectorContainer: FC = () => {
  const { loginWithRedirect, user } = useAuth0();
  const { organizations, chosenOrganization, isLoading } =
    useSnapshot(OrganizationsStore);

  useEffect(() => {
    dispatchChosenOrgUpdatedEvent(chosenOrganization);
  }, [chosenOrganization]);

  const handleChange = useCallback(
    (
      _event: SyntheticEvent<Element, Event> | null,
      organization: Organization,
    ) => {
      setLastLoggedInOrg(organization);
      setChosenOrganization(organization);
      loginWithRedirect({
        organization: `${organization.id}`,
        appState: {
          returnTo: window.location.pathname,
        },
      });
    },
    [loginWithRedirect],
  );

  // TODO: move this useEffect to somewhere else
  useEffect(() => {
    if (
      user &&
      user.email_verified &&
      organizations.length > 0 &&
      (!chosenOrganization || !user.org_id)
    ) {
      if (
        organizations.length === 1 ||
        !chosenOrganization ||
        !organizations.some(org => org.id === chosenOrganization.id)
      ) {
        handleChange(null, organizations[0]);
      } else {
        handleChange(null, chosenOrganization);
      }
    }
  }, [user, organizations, chosenOrganization, handleChange]);

  if (!user?.org_id) {
    return null;
  }

  return organizations.length > 1 ? (
    <OrganizationSelector
      organizations={organizations}
      lastLoggedInOrg={chosenOrganization}
      isLoading={isLoading ?? true}
      handleChange={handleChange}
    />
  ) : null;
};

export default OrganizationSelectorContainer;
