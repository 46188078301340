import {
  FieldSchemaType,
  IssueTypeField,
  JiraComponent,
  JiraEpic,
  JiraIssueType,
  JiraPriority,
  JiraProject,
  JiraTicket,
  JiraTicketStatus,
  JiraUser,
  Nullable,
} from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import { PreSelectableIssueFields } from '../types/jira-ticket-types';
import ScanStore from '../../../../../apps/web/src/new-scan/store/scan-store';
import { default as MockData } from '../../../../../apps/web/src/issues/common/mocks/getPreSelectFields.json';
import { default as MockComponents } from '../../../../../apps/web/src/issues/common/mocks/jira-components-search.json';
import { default as MockEpics } from '../../../../../apps/web/src/issues/common/mocks/jira-epics-search.json';
import { default as MockUsersAssignees } from '../../../../../apps/web/src/issues/common/mocks/jira-users-search.json';
import { default as MockIssueTypes } from '../../../../../apps/web/src/issues/common/mocks/getIssueTypes.json';
import { env } from 'env-utils';

const baseStore = createStore<JiraTicketState>(
  {
    loading: false,
    loadingProjects: false,
    loadingAssignees: false,
    loadingReporters: false,
    loadingComponents: false,
    loadingLabels: false,
    loadingEpics: false,
    loadingDependentByProjectFields: false,
    isLoadingSelectableTicketFields: false,
    loadingJiraTicketsStatusUpdates: false,
    dependentByProjectFields: null,
    fields: null,
    projects: null,
    preSelectableIssueFields: null,
    validationObject: null,
    jiraTicket: {
      summary: '',
      issueType: null,
      project: null,
      assignee: null,
      fallbackAssignee: null,
      reporter: null,
      priority: null,
      epicLink: null,
      labels: null,
      issueId: '',
      issueName: '',
      appName: '',
      appId: '',
      category: '',
      description: null,
      aggregations: null,
      aggregationsCount: null,
      comment: null,
      components: null,
      dynamicFields: null,
      aggItemsIds: null,
    },
    bulkActionIssuesId: null,
    jiraTicketsStatus: null,
    disableSubmit: false,
    displayAllJiraFields: false,
  },
  'Jira Ticket Store',
);

export const fieldsKeysDict = {
  project: 'project',
  issuetype: 'issueType',
  assignee: 'assignee',
  reporter: 'reporter',
  summary: 'summary',
  ...(env()['REACT_APP_DESCRIPTION_JIRA_FIELD']
    ? {}
    : { description: 'description' }),
  labels: 'labels',
  priority: 'priority',
  components: 'components',
} as const;

const JiraTicketStore = derive(
  {
    fieldsMetadata: get => {
      const { jiraTicket } = get(baseStore);
      const fieldsMetadata: Nullable<{ [key: string]: IssueTypeField }> =
        jiraTicket.issueType?.fields?.reduce((acc, curr) => {
          acc[curr.key] = curr;
          return acc;
        }, {} as { [key: string]: IssueTypeField }) || null;
      if (fieldsMetadata) {
        jiraTicket.reporter = fieldsMetadata?.['reporter']
          ? jiraTicket.reporter
          : null;
        jiraTicket.assignee = fieldsMetadata?.['assignee']
          ? jiraTicket.assignee
          : null;
      }

      return fieldsMetadata;
    },
    dynamicTypeFieldsMetadata: get => {
      const { jiraTicket, displayAllJiraFields } = get(baseStore);
      return (
        jiraTicket.issueType?.fields?.filter(field => {
          const filterOnlyRequiredAndNonDefaultValue = !displayAllJiraFields
            ? field.required && !field.hasDefaultValue
            : true;
          return (
            !!!fieldsKeysDict[field.key] &&
            field?.schema?.type &&
            Object.values(FieldSchemaType).includes(field.schema.type) &&
            filterOnlyRequiredAndNonDefaultValue
          );
        }) || null
      );
    },
    demoAbleProjects: get => {
      const { projects } = get(baseStore);
      const { isDemoEnabled } = get(ScanStore);
      if (isDemoEnabled) {
        return MockData.data.getLatestProjects;
      } else {
        return projects;
      }
    },
    demoAbleDependentByProjectFields: get => {
      const { dependentByProjectFields } = get(baseStore);
      const { isDemoEnabled } = get(ScanStore);
      if (isDemoEnabled) {
        return {
          issueTypes: MockIssueTypes.data.getIssueTypes
            .issueTypes as JiraIssueType[],
          assigness: MockUsersAssignees.data.searchAssignee as JiraUser[],
          reporters: MockUsersAssignees.data.searchAssignee as JiraUser[],
          components: MockComponents.data
            .getComponentsByProjectKey as JiraComponent[],
          epics: MockEpics.data.getEpicsByProjectKey as JiraEpic[],
        };
      } else {
        return dependentByProjectFields;
      }
    },
    demoAbleFields: get => {
      const { fields } = get(baseStore);
      const { isDemoEnabled } = get(ScanStore);
      if (isDemoEnabled) {
        return {
          priorities: MockData.data.listPriorities as JiraPriority[],
          labels: MockData.data.listLabels as string[],
        };
      } else {
        return fields;
      }
    },
  },

  {
    proxy: baseStore,
  },
);

interface JiraTicketState {
  loading: boolean;
  loadingProjects: boolean;
  loadingAssignees: boolean;
  loadingReporters: boolean;
  loadingLabels: boolean;
  loadingComponents: boolean;
  loadingEpics: boolean;
  loadingDependentByProjectFields: boolean;
  isLoadingSelectableTicketFields: boolean;
  loadingJiraTicketsStatusUpdates: boolean;
  projects: Nullable<JiraProject[]>;
  validationObject: Nullable<{
    [key: string]: {
      message: string;
    };
  }>;
  dependentByProjectFields: Nullable<{
    issueTypes: Nullable<JiraIssueType[]>;
    assigness: Nullable<JiraUser[]>;
    reporters: Nullable<JiraUser[]>;
    components: Nullable<JiraComponent[]>;
    epics: Nullable<JiraEpic[]>;
  }>;
  fields: Nullable<{
    priorities: JiraPriority[];
    labels: string[];
  }>;
  preSelectableIssueFields: Nullable<PreSelectableIssueFields>;
  jiraTicket: JiraTicket;
  jiraTicketsStatus: Nullable<JiraTicketStatus[]>;
  disableSubmit: boolean;
  bulkActionIssuesId: Nullable<string[]>;
  displayAllJiraFields: boolean;
}

export default JiraTicketStore;
