import { policiesClient } from 'api-clients';
import addNewProfile from './add-new-profile-service';
import createDefaultProfile from './create-default-profile-service';
import fetchActiveProfileOverview from './fetch-active-profile-overview-service';
import fetchProfileDetailsById from './fetch-profile-overview-by-id-service';
import updateProfileById from './update-profile-service';

const profilesAPI = {
  fetchActiveProfileOverview: fetchActiveProfileOverview(policiesClient),
  fetchProfileDetailsById: fetchProfileDetailsById(policiesClient),
  updateProfileById: updateProfileById(policiesClient),
  createDefaultProfile: createDefaultProfile(policiesClient),
  addNewProfile: addNewProfile(policiesClient),
};

export default profilesAPI;
