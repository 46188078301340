import { env } from 'env-utils';

export const CUSTOM_SCOPES = `${env().REACT_APP_AUTH0_AUDIENCE}/scopes`;

export enum ScopeOptions {
  EntireOrganization = 'EntireOrganization',
  UserOnly = 'UserOnly',
  CustomScope = 'CustomScope',
}

export const SCOPE_SELECTION_OPTIONS = {
  [ScopeOptions.EntireOrganization]: {
    value: ScopeOptions.EntireOrganization,
    label: 'Entire organization',
    helperText: 'All applications and issues in the organization',
  },
  [ScopeOptions.UserOnly]: {
    value: ScopeOptions.UserOnly,
    label: "User's applications only",
    helperText: 'Applications and issues for which the user is the App owner.',
  },
  [ScopeOptions.CustomScope]: {
    value: ScopeOptions.CustomScope,
    label: 'Custom',
    helperText:
      'Applications and issues with the App owners/tags selected below',
  },
};
