import { Nullable } from 'ox-common-types';

export interface Connection {
  id: string;
  name: string;
  displayName: string;
  connectionType: ConnectionType;
  strategy: string;
  realms: string[];
  is_domain_connection: boolean;
  icons: ThemedIcons;
  enabled: boolean;
  isSetupRequired: boolean;
  domain?: string;
  clientId?: string;
  clientSecret?: string;
  useCommonEndpoint?: boolean;
  shouldSyncGroupRoles?: boolean;
  shouldSyncGroupScopes?: boolean;
  shouldAllowAutoProvisioning?: boolean;
  syncGroupRolesPrefix?: string;
  emailVerification?: EmailVerification;
  instructions?: ConnectionInstructions;
  signInEndpoint?: string;
  cert?: string;
}

export enum ConnectionType {
  Github = 'Github',
  GoogleOauth2 = 'GoogleOauth2',
  Auth0UsernamePassword = 'Auth0UsernamePassword',
  AzureAD = 'AzureAD',
  Okta = 'Okta',
  SAML = 'SAML',
  PingFederate = 'PingFederate',
}

export interface ConnectionIcon {
  iconURL: string;
}

export enum ThemeType {
  Light = 'light',
  Dark = 'dark',
}

export interface ThemedIcons {
  [ThemeType.Dark]: ConnectionIcon;
  [ThemeType.Light]: ConnectionIcon;
}

export interface ConfigureConnectionInput {
  connectionId: string;
}

export interface ConnectionInput {
  name?: string;
  displayName?: string;
  strategy: string;
  domain: string;
  clientId: string;
  clientSecret: string;
  useCommonEndpoint: boolean;
  shouldSyncGroupRoles: boolean;
  shouldSyncGroupScopes: boolean;
  shouldAllowAutoProvisioning: boolean;
  syncGroupRolesPrefix: Nullable<string>;
  emailVerification?: EmailVerification;
  signInEndpoint?: string;
  cert?: string;
}

export enum EmailVerification {
  never_set_emails_as_verified = 'never_set_emails_as_verified',
  always_set_emails_as_verified = 'always_set_emails_as_verified',
}

export enum ConnectionInputField {
  domain = 'domain',
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  useCommonEndpoint = 'useCommonEndpoint',
  signInEndpoint = 'signInEndpoint',
  cert = 'cert',
  syncGroupRolesPrefix = 'syncGroupRolesPrefix',
}

export interface ConnectionFormFields {
  domain: string;
  clientId: string;
  clientSecret: string;
  useCommonEndpoint: boolean;
  shouldSyncGroupRoles: boolean;
  shouldSyncGroupScopes: boolean;
  shouldAllowAutoProvisioning: boolean;
  syncGroupRolesPrefix: Nullable<string>;
  strategy: string;
  signInEndpoint: string;
  cert: string;
}

export interface ConnectionInstructions {
  linkLabel: string;
  linkTooltip: string;
  link: string;
  title: string;
  details: string[];
}

export enum ConnectionError {
  FailedLoadConnections = 'Failed to load login options, please try again later',
  FailedToSave = 'Failed to save connection, please try again later',
  FailedToAddConnection = 'Failed to allow connection, please try again later',
  FailedToRemoveConnection = 'Failed to revoke connection, please try again later',
}

export enum ButtonTooltip {
  AddConnection = 'Allow connection',
  SaveConnection = 'Save connection',
  RemoveConnection = 'Revoke connection',
  ReadOnlyUser = 'Read only user cannot edit connection settings',
  SelfConnection = 'Cannot revoke connection, please log in with a different option in order to remove this connection option',
}
