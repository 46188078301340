import ConnectorConfigureFormStore, {
  ConnectorConfigureFormState,
} from '../stores/connector-configure-form-store';
import ConnectorConfigureStore from '../stores/connector-configure-store';

export const setNameInput = (value: string) => {
  ConnectorConfigureFormStore.name = value;
};

export const setProjectIdInput = (value: string) => {
  ConnectorConfigureFormStore.projectId = value;
};

export const setOrganizationIdInput = (value: string) => {
  ConnectorConfigureFormStore.organizationId = value;
};

export const setApiKeyInput = (value: string) => {
  ConnectorConfigureFormStore.apiKey = value;
};

export const setApiUrlInput = (value: string) => {
  ConnectorConfigureFormStore.apiUrl = value;
};

export const setAppIdInput = (value: string) => {
  ConnectorConfigureFormStore.appId = value;
};

export const setApiAccessKeyInput = (value: string) => {
  ConnectorConfigureFormStore.apiAccessKey = value;
};

export const setApiSecretKeyInput = (value: string) => {
  ConnectorConfigureFormStore.apiSecretKey = value;
};

export const setAwsRoleArnInput = (value: string) => {
  ConnectorConfigureFormStore.awsRoleArn = value;
};

export const setAwsAccessKeyInput = (value: string) => {
  ConnectorConfigureFormStore.awsAccessKey = value;
};

export const setAwsAccessSecretInput = (value: string) => {
  ConnectorConfigureFormStore.awsAccessSecret = value;
};

export const setAwsExternalIdInput = (value: string) => {
  ConnectorConfigureFormStore.awsExternalId = value;
};

export const setClientIdInput = (value: string) => {
  ConnectorConfigureFormStore.clientId = value;
};

export const setSecretAccessKeyInput = (value: string) => {
  ConnectorConfigureFormStore.secretKey = value;
};

export const setTokenInput = (value: string) => {
  ConnectorConfigureFormStore.token = value;
};

export const setHostUrlInput = (value: string) => {
  ConnectorConfigureFormStore.hostURL = value;
};

export const setPasswordInput = (value: string) => {
  ConnectorConfigureFormStore.password = value;
};

export const setGitPasswordInput = (value: string) => {
  ConnectorConfigureFormStore.gitPassword = value;
};

export const setGitUserNameInput = (value: string) => {
  ConnectorConfigureFormStore.gitUsername = value;
};

export const setTenantInput = (value: string) => {
  ConnectorConfigureFormStore.tenant = value;
};

export const setTenantIdInput = (value: string) => {
  ConnectorConfigureFormStore.tenantId = value;
};

export const setWebhookUrlInput = (value: string) => {
  ConnectorConfigureFormStore.webhookUrl = value;
};

export const setClientSecretInput = (value: string) => {
  ConnectorConfigureFormStore.clientSecret = value;
};

export const setSubscriptionIdInput = (value: string) => {
  ConnectorConfigureFormStore.subscriptionId = value;
};

export const setOptionalFields = (
  initialTabs: ConnectorConfigureFormState['optionalFields'],
) => {
  ConnectorConfigureFormStore.optionalFields = initialTabs;
};

export const setOptionalTabIsConfigured = (
  tabName: string,
  isConfigured: boolean,
) => {
  ConnectorConfigureFormStore.optionalTabIsConfigured[tabName] = isConfigured;
};

export const setInitialOptionalTabsFields = (
  initialTabs: ConnectorConfigureFormState['optionalTabsFields'],
) => {
  const initial = {};
  Object.keys(initialTabs).forEach(key => {
    const upperCaseKey = key[0].toUpperCase() + key.substring(1);
    initial[upperCaseKey] = initialTabs[key];
    if (
      !!initial[upperCaseKey] &&
      Object.keys(initial[upperCaseKey]).length > 0
    ) {
      ConnectorConfigureFormStore.optionalTabIsConfigured[upperCaseKey] = true;
    }
  });
  ConnectorConfigureFormStore.optionalTabsFields = initial;
};

export const setOptionalTabsFields = (
  tabName: string,
  fieldName: string,
  fieldValue: string,
) => {
  if (ConnectorConfigureFormStore.optionalTabsFields[tabName]) {
    ConnectorConfigureFormStore.optionalTabsFields = {
      ...ConnectorConfigureFormStore.optionalTabsFields,
      [tabName]: {
        ...ConnectorConfigureFormStore.optionalTabsFields[tabName],
        [fieldName]: fieldValue,
      },
    };
  } else {
    ConnectorConfigureFormStore.optionalTabsFields[tabName] = {
      [fieldName]: fieldValue,
    };
  }
};

export const resetFormInputs = () => {
  ConnectorConfigureFormStore.hostURL = '';
  ConnectorConfigureFormStore.token = '';
  ConnectorConfigureFormStore.password = '';
  ConnectorConfigureFormStore.name = '';
  ConnectorConfigureFormStore.clientId = '';
  ConnectorConfigureFormStore.secretKey = '';
  ConnectorConfigureFormStore.awsRoleArn = '';
  ConnectorConfigureFormStore.awsAccessKey = '';
  ConnectorConfigureFormStore.awsAccessSecret = '';
  ConnectorConfigureFormStore.tenant = '';
  ConnectorConfigureFormStore.projectId = '';
  ConnectorConfigureFormStore.organizationId = '';
  ConnectorConfigureFormStore.apiKey = '';
  ConnectorConfigureFormStore.apiAccessKey = '';
  ConnectorConfigureFormStore.apiSecretKey = '';
  ConnectorConfigureFormStore.tenantId = '';
  ConnectorConfigureFormStore.clientSecret = '';
  ConnectorConfigureFormStore.subscriptionId = '';
  ConnectorConfigureFormStore.webhookUrl = '';
  ConnectorConfigureFormStore.optionalFields = {};
};

export const resetAWSExternalIdFormInput = () => {
  ConnectorConfigureFormStore.awsExternalId = '';
};

export const setIsConfirmationOpen = (isOpen: boolean) => {
  ConnectorConfigureStore.isConfirmationOpen = isOpen;
};

export const setIsStartNewScanModalOpen = (isOpen: boolean) => {
  ConnectorConfigureStore.isStartNewScanModalOpen = isOpen;
};

export const setIsRepoLimitModalOpen = (isOpen: boolean) => {
  ConnectorConfigureStore.isRepoLimitModalOpen = isOpen;
};
