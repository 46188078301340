import { FC, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../../active-issues/stores/issues-store';
import VulnerabilitiesTable from 'ox-react-components/src/Vulnerabilities/VulnerabilitiesTable';
import {
  fetchReachableVulnerabilities,
  searchReachableVulnerabilities,
  sortReachableVulnerabilities,
} from '../actions/vulnerabilities-action';
import ReachableVulnerabilitiesStore from '../stores/reachable-vulnerabilities-store';
import { useInfinityScroll } from 'react-utils';
import { resetReachableVulnerabilities } from './store-actions';

const ReachableVulnerabilitiesTableContainer: FC = () => {
  const { selectedIssueId } = useSnapshot(IssuesStore);
  const { data, loading, contextOptions, orderDirection, orderField } =
    useSnapshot(ReachableVulnerabilitiesStore);
  const { onScroll } = useInfinityScroll({
    threshold: 0.9,
    load: fetchReachableVulnerabilities,
    loading,
  });
  useEffect(() => {
    fetchReachableVulnerabilities(false);
    return resetReachableVulnerabilities;
  }, [selectedIssueId]);

  return (
    <VulnerabilitiesTable
      rows={data}
      onScroll={onScroll}
      loading={loading}
      contextOptions={contextOptions}
      onSearch={searchReachableVulnerabilities}
      onSort={sortReachableVulnerabilities}
      orderField={orderField}
      orderDirection={orderDirection}
    />
  );
};

export default ReachableVulnerabilitiesTableContainer;
