import { useAuth0 } from '@auth0/auth0-react';
import { FC } from 'react';

const RequiresAuthentication: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isAuthenticated, user } = useAuth0();

  if (isAuthenticated && user?.org_id) {
    return <>{children}</>;
  }
  return null;
};

export default RequiresAuthentication;
