import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import { Nullable } from 'ox-common-types';
import NodeTooltip from '../NodeTooltip';

const Update: FC<React.PropsWithChildren<UpdateProps>> = ({
  notUpdated,
  libraryVersion,
}) => {
  const theme = useTheme();
  const colors = theme.palette.categoryRiskColor;
  return (
    <NodeTooltip title={`Update Check`}>
      {notUpdated === true && (
        <Typography variant='caption' color={colors.high}>
          Not Updated Library
        </Typography>
      )}
      {notUpdated === null && (
        <Typography variant='caption' color={colors.info}>
          No info about library updates
        </Typography>
      )}

      {notUpdated === false && (
        <Typography variant='caption' color={colors.passed}>
          Updated Library
        </Typography>
      )}

      <Typography variant='caption'>
        Library Version: {libraryVersion}
      </Typography>
    </NodeTooltip>
  );
};

export interface UpdateProps {
  notUpdated: Nullable<boolean>;
  libraryVersion: string;
}

export { Update };
