import { Severities } from 'ox-common-types';

export interface Parameter {
  description: string;
  in: string;
  name: string;
  required: boolean;
}
export interface ApiInventoryItemResponse {
  description: string;
  code: string;
}

export interface ApiInventoryItemCommit {
  commitInfo: {
    authorName: string;
    authorEmail: string;
    committerName: string;
    committerEmail: string;
    commitId: string;
    message: string;
    authorDate: string;
    commitDate: string;
  };
  match: string;
  fileName: string;
  link: string;
  snippet: string;
  snippetLineNumber: number;
  startLineNumber: number;
}

export interface ApiInventoryItem {
  id?: string;
  scanId?: string;
  title: string;
  description: string;
  version: string;
  openapi: string;
  servers: string[];
  epName: string;
  methodName: string | undefined;
  methodDescription: string;
  methodOperationId: string;
  methodResponses: ApiInventoryItemResponse[];
  methodSummary: string;
  methodTags: string[];
  methodParameters: Parameter[];
  appId?: string;
  appType?: string;
  appName?: string;
  fileName: string[];
  index?: number;
  firstSeen: Date;
  framework: ApiFrameworks;
  language?: string;
  definitions: APIDefinition[];
  uuid?: string;
  issuesBySeverity?: Severities;
  codeLocations?: { link: string; callBranch: string[] }[];
  commits?: ApiInventoryItemCommit[];
}

export interface ApiInventoryItemFunction {
  function: string;
  line: number;
  snippet: string;
  filepath: string;
  link: string;
}

export interface APIDefinition {
  source: APISource;
  fileName: string;
  link: string;
  llmTitle;
  llmDescription;
  functions?: ApiInventoryItemFunction[];
}

export enum APISource {
  code = 'code',
  codeOpenApi = 'code_open_api',
}

export enum ApiFrameworks {
  Flask = 'flask',
  Django = 'django',
  FastAPI = 'fastapi',
  SpringBoot = 'springboot',
  ExpressJS = 'expressjs',
  Gin = 'gin',
  NestJS = 'nestjs',
  Koa = 'koa',
  Play = 'play',
  MicrosoftAspNetCoreMvc = 'microsoftaspnetcoremvc',
  Ktor = 'ktor',
}

export enum ApiInventoryDrawerTabs {
  parameters = 'Parameters',
  responses = 'Responses',
  generalInfo = 'General Info',
  llmInfo = 'LLM Info',
  issuesStatistics = 'Exposed Issue Statistics',
  commits = 'Commit',
}
