import { ButtonBase, ButtonBaseProps, lighten, Theme } from '@mui/material';
import { important } from 'ox-common-types';
import { FC, ReactElement } from 'react';
import {
  MdOutlinePlayCircleFilled as ArrowCircleRightIcon,
  MdFilterAlt as FilterAltIcon,
} from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

export const SidebarButton: FC<
  ButtonBaseProps & { position?: 'left' | 'right'; Icon?: ReactElement }
> = props => {
  const { classes, cx } = useStyles();
  const { Icon, ...rest } = props;
  return (
    <ButtonBase
      {...rest}
      className={cx(
        classes.openButton,
        props.position === 'right' ? classes.left : classes.right,
      )}>
      <ArrowCircleRightIcon
        className={cx(props.position === 'right' && classes.buttonRight)}
      />
      {Icon ? Icon : <FilterAltIcon />}
    </ButtonBase>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  openButton: {
    minHeight: '50px',
    minWidth: '32px',
    displayy: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.1)',
    color: important(lighten(theme.palette.text.primary, 0.5)),
    backgroundColor: important(theme.palette.background.paper),
    paddingInline: important(theme.spacing(1)),
    '& > svg': {
      fontSize: '1rem',
    },
  },
  left: {
    borderTopLeftRadius: important('10px'),
    borderBottomLeftRadius: important('10px'),
  },
  right: {
    borderTopRightRadius: important('10px'),
    borderBottomRightRadius: important('10px'),
  },
  buttonRight: {
    transform: 'rotate(180deg)',
  },
}));
