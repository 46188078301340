import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import { FetchIssuesInput } from '../../../common/types/issues-types';
import query from './resolved-issues-overview.gql';

export const fetchResolvedIssuesOverview = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getResolvedIssuesInput: FetchIssuesInput,
      cache: boolean = true,
    ) => {
      const results = await client.query<ResolvedIssuesOverviewResponse>({
        query,
        fetchPolicy: cache
          ? ApolloClientCache.CacheFirst
          : ApolloClientCache.NoCache,
        variables: { getResolvedIssuesInput },
      });
      if (!results.data) return null;
      return results.data;
    },
  };
};

export interface ResolvedIssuesOverviewResponse {
  getMttrForResolvedIssues: {
    mttrDays: {
      info: number;
      low: number;
      medium: number;
      high: number;
      critical: number;
      appox: number;
    };
  };
  getResolvedIssuesTrend: {
    trends: [
      {
        resolvedIssueDate: string;
        info: number;
        low: number;
        medium: number;
        high: number;
        critical: number;
        appox: number;
      },
    ];
  };
}
