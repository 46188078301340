import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache, ServiceExecute } from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';
import {
  ExcludeAlertInput,
  ExcludeAlertsResponse,
  Exclusion,
} from '../types/exclusion-types';
import mutation from './exclude-alert.gql';

const excludeAlert = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<ExcludeAlertInput, ExcludeAlertsResponse> => {
  return {
    execute: async (
      input: ExcludeAlertInput,
    ): Promise<ExcludeAlertsResponse> => {
      const results = await client.mutate<ExcludeAlertResponse>({
        mutation,
        fetchPolicy: ApolloClientCache.NoCache,
        variables: { input },
      });
      if (results.data?.excludeAlert.exclusions) {
        TelemetryManager.setTelemetryAction(
          TelemetryActions.ExcludeAlert,
          input,
        );
        return results.data.excludeAlert;
      }
      throw new Error('Failed to exclude alert, no data');
    },
  };
};

export interface ExcludeAlertResponse {
  excludeAlert: {
    exclusions: Exclusion[];
  };
}

export default excludeAlert;
