import { Box, Theme, Typography } from '@mui/material';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { MdErrorOutline as ErrorOutlineIcon } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as OxIcon } from './assets/ox-icon.svg';

export const GeneralErrorPage: FunctionComponent<
  PropsWithChildren<GeneralErrorPageProps>
> = ({ errorMsg, errorTitle, showOxIcon }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.content}>
      {showOxIcon && <OxIcon style={{ width: '5em' }} />}
      <ErrorOutlineIcon className={classes.icon} />
      <Typography variant='h6' color='textPrimary'>
        {errorTitle}
      </Typography>
      {typeof errorMsg === 'string' && (
        <Typography variant='body2'>{errorMsg}</Typography>
      )}
      {typeof errorMsg !== 'string' && errorMsg}
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    gap: theme.spacing(1),
    flexGrow: 1,
    marginTop: '20%',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.primary,
  },
  icon: {
    fill: theme.palette.primary.main,
  },
}));

export interface GeneralErrorPageProps {
  errorTitle: string;
  errorMsg: string | ReactNode;
  showOxIcon?: boolean;
}
