import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { HubSpotUserActions, logUserActionToForm } from 'hubspot';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchExclusionsInput,
  FetchExclusionsResponse,
} from '../types/exclusion-types';
import query from './get-exclusions.gql';

const getExclusions = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getExclusionsInput: FetchExclusionsInput,
      cache: boolean = true,
    ) => {
      try {
        const results = await client.query<FetchExclusionsResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: { getExclusionsInput },
        });
        if (!results.data) return null;
        logUserActionToForm(HubSpotUserActions.Exclusion);
        return results.data.getExclusions;
      } catch (e) {
        logger.error(`Failed to fetch exclusions: ${e}`, e);
        return null;
      }
    },
  };
};

export default getExclusions;
