import { IconButton, Theme } from '@mui/material';
import { Children, FC, SyntheticEvent, useCallback } from 'react';
import {
  MdArrowBackIosNew as ArrowBackIosNewOutlinedIcon,
  MdArrowForwardIos as ArrowForwardIosNewOutlinedIcon,
} from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

export const Carousel: FC<React.PropsWithChildren<CarouselProps>> = ({
  children,
  tabIndex,
  onChange,
}) => {
  const { classes } = useStyles();
  const numberOfPanels = Children.count(children);

  const createNavigationHandler = useCallback(
    (newIndex: number) => (e: SyntheticEvent) => {
      if (numberOfPanels === 1) return;
      if (tabIndex === numberOfPanels - 1) {
        if (newIndex < tabIndex) {
          onChange(e, newIndex);
        } else {
          onChange(e, 0);
        }
      } else if (tabIndex === 0) {
        if (newIndex < tabIndex) {
          onChange(e, numberOfPanels - 1);
        } else {
          onChange(e, newIndex);
        }
      } else {
        onChange(e, newIndex);
      }
    },
    [numberOfPanels, onChange, tabIndex],
  );

  return (
    <div className={classes.carousel}>
      <div className={classes.aside}>
        <IconButton
          size='medium'
          color={'primary'}
          onClick={createNavigationHandler(tabIndex - 1)}>
          <ArrowBackIosNewOutlinedIcon fontSize='inherit' />
        </IconButton>
      </div>
      <div className={classes.main}>{children}</div>
      <div className={classes.aside}>
        <IconButton
          size='medium'
          color={'primary'}
          onClick={createNavigationHandler(tabIndex + 1)}>
          <ArrowForwardIosNewOutlinedIcon fontSize='inherit' />
        </IconButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  carousel: {
    display: 'flex',
    alignItems: 'stretch',
    height: '100%',
  },
  main: {
    flexGrow: 1,
    overflow: 'hidden',
    alignSelf: 'start',
  },
  aside: {
    marginInline: theme.spacing(1),
    alignSelf: 'center',
  },
}));

interface CarouselProps {
  tabIndex: number;
  onChange: (e: SyntheticEvent, newValue: number) => void;
}
