import { Theme, Typography } from '@mui/material';
import { DateFormat, OwnerCell } from 'ox-react-components';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FixIssue } from '../../types/issues-types';
import { resolveAutoFix } from '../../utils/issue-utils';

const IssueCardFixApplied: FC<
  React.PropsWithChildren<IssueCardFixAppliedProps>
> = ({ autoFix }) => {
  const fixTitle = resolveAutoFix(autoFix.fixTitle);
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <div>
        <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
          Fix Name:
        </Typography>
        <Typography variant='body2' color='text.primary'>
          {fixTitle}
        </Typography>
        <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
          Source Control Type:
        </Typography>
        <Typography variant='body2' color='text.primary'>
          {autoFix.sourceControlType || 'N/A'}
        </Typography>
      </div>
      <div>
        <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
          Fix Applied By:
        </Typography>
        <OwnerCell owners={[autoFix.fixAppliedBy]} />
        {autoFix?.fixDate && (
          <div>
            <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
              Fix applied date:
            </Typography>
            <DateFormat date={new Date(autoFix.fixDate)} color='text.primary' />
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(15),
  },
}));

export interface IssueCardFixAppliedProps {
  autoFix: FixIssue;
}

export default IssueCardFixApplied;
