import { Box, Paper, PaperProps, Theme } from '@mui/material';
import { BoxProps } from '@mui/system';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

export const PanelContainer: FC<
  React.PropsWithChildren<PanelContainerProps>
> = ({ children, ...rest }) => {
  const { classes } = useStyles();

  return (
    <Box
      borderRadius='10px'
      component={Paper}
      elevation={0}
      className={classes.panelContainer}
      data-testid='panel-container'
      {...rest}>
      {children}
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  panelContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    overflow: 'hidden',
    paddingInline: theme.spacing(1.5),
    paddingBlockEnd: theme.spacing(1.5),
    paddingBlockStart: theme.spacing(2.5),
  },
}));

type PanelContainerProps = BoxProps | PaperProps;
