import { createStore } from 'store-utils';

const localStorageString = localStorage.getItem('theme');

const LayoutStore = createStore<LayoutStoreState>(
  {
    themeType: localStorageString === 'dark' ? 'dark' : 'light',
  },
  'Layout Store',
);

export interface LayoutStoreState {
  themeType: 'light' | 'dark';
}

export default LayoutStore;
