import { FC, useMemo } from 'react';
import { SmoothStepEdge, SmoothStepEdgeProps } from 'reactflow';
import { AttackPathType } from '../attack-path-types';
import { getEdgeColor } from '../configs/edge-colors-config';

const StepEdge: FC<React.PropsWithChildren<SmoothStepEdgeProps>> = props => {
  return (
    <SmoothStepEdge
      {...props}
      style={{ strokeWidth: 2, stroke: getEdgeColor(AttackPathType.Saas, {}) }}
      targetX={props.targetX + 30}
      sourceX={props.sourceX}
      sourceY={props.sourceY - 3}
      targetY={props.targetY - 3}
      pathOptions={useMemo(() => ({ borderRadius: 20 }), [])}
    />
  );
};

export interface ArrowStepEdgeProps {}

export default StepEdge;
