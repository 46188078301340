import { orgManagementClient } from 'api-clients';
import getRequestAccessConfiguration from './get-request-access-configuration/get-request-access-configuration';
import setAllowUserToRequestAccess from './set-allow-user-to-request-access/set-allow-user-to-request-access';
import setAllowUserToCreateOrg from './set-allow-user-to-create-org/set-allow-user-to-create-org';
import canUserRequestAccess from './can-user-request-access/can-user-request-access';
import requestAccess from './request-access/request-access';

const requestAccessService = {
  getRequestAccessConfiguration:
    getRequestAccessConfiguration(orgManagementClient),
  setAllowUserToRequestAccess: setAllowUserToRequestAccess(orgManagementClient),
  setAllowUserToCreateOrg: setAllowUserToCreateOrg(orgManagementClient),
  canUserRequestAccess: canUserRequestAccess(orgManagementClient),
  requestAccess: requestAccess(orgManagementClient),
};

export default requestAccessService;
