import { TicketingActionFields } from '@oxappsec/policy-workflow-service';
import { CreateTicketStore, InputOption } from '../store/create-ticket-store';
import { TicketFieldConfig } from '../types/ticketing-types';

const setInputControlObject = (fieldKey: string, value: string | null) => {
  CreateTicketStore.inputControlObject[fieldKey] = value || null;
};

const setInputSelectedControlValue = (
  fieldKey: string,
  value: string | InputOption | (string | InputOption)[] | null,
) => {
  CreateTicketStore.inputSelectedControlObject[fieldKey] = value;
};

const initInputValuesWorkflow = (values: TicketingActionFields['ticket']) => {
  if (values) {
    Object.keys(values).forEach(key => {
      setInputSelectedControlValue(key, values[key]);
      setInputControlObject(key, values[key]);
    });
  }
};

const resetInputSelectedControlObject = () => {
  CreateTicketStore.inputSelectedControlObject = {};
};

const resetInputControlObject = () => {
  CreateTicketStore.inputControlObject = {};
};

const setInputOptions = (fieldKey: string, value: InputOption[]) => {
  CreateTicketStore.inputOptions[fieldKey] = value;
};

const resetInputOptions = () => {
  CreateTicketStore.inputOptions = {};
};

const setIsLoadingInputFields = (isLoading: boolean) => {
  CreateTicketStore.isLoadingInputFields = isLoading;
};

const setFieldsConfig = (fieldsConfig: TicketFieldConfig[]) => {
  CreateTicketStore.fieldsConfig = fieldsConfig;
};

const setIsLoadingInputOptions = (fieldKey: string, isLoading: boolean) => {
  CreateTicketStore.isLoadingOptions[fieldKey] = isLoading;
};

const setIsErrorLoadingInputFields = (isError: boolean) => {
  CreateTicketStore.isErrorLoadingInputFields = isError;
};

export const createTicketStoreActions = {
  setInputControlObject,
  setInputSelectedControlValue,
  initInputValuesWorkflow,
  resetInputSelectedControlObject,
  resetInputControlObject,
  setInputOptions,
  resetInputOptions,
  setIsLoadingInputFields,
  setFieldsConfig,
  setIsLoadingInputOptions,
  setIsErrorLoadingInputFields,
};
