import { ScopeNames } from '@oxappsec/ox-unified-permissions/src/scopes';
import { createStore, derive } from 'store-utils';
import TagsStore from '../../../tags/store/tags-store';
import { AppOwnersStore } from 'app-owners-logic';
import { AppStore } from 'app-store/src/app-store';
import { extractScopeItems } from '../../../members/actions/update-member-actions';
import { GlobalDataViewSelectorOption } from './data-view-selector-types';
import { Nullable } from 'ox-common-types';

const baseStore = createStore<GlobalDataViewSelectorStoreState>(
  {
    selectedAppOwnersEmails: [],
    selectedTagIds: [],
    filterValue: '',
    isModalVisible: false,
    selectedOption: null,
    isLoadingNameUpdate: false,
    isLoadingIdUpdate: false,
    isLoadingDeleteOption: false,
  },
  'globalDataViewSelector Store',
);

export const GlobalDataViewSelector = derive(
  {
    dataViewSelectorOptions: get => {
      const { assignedAppOwners } = get(AppOwnersStore);
      const { tags } = get(TagsStore);
      const { user } = get(AppStore);

      const tagsScopeItemsIds = extractScopeItems(
        user?.scopes,
        ScopeNames.Tags,
      );
      const appOwnersScopeItemsIds = extractScopeItems(
        user?.scopes,
        ScopeNames.AppOwner,
      );

      const AllAppOwnersOptions = assignedAppOwners.map(owner => ({
        id: owner.email,
        displayName: owner.name,
        scopeName: ScopeNames.AppOwner,
      }));

      const appOwnerOptions = user?.scopes
        ? AllAppOwnersOptions.filter(option =>
            appOwnersScopeItemsIds?.includes(option.id),
          )
        : AllAppOwnersOptions;

      const tagsOptions = tags
        ? Object.keys(tags).reduce(
            (acc: GlobalDataViewSelectorOption[], key) => {
              const currentTag = tags[key];
              if (tagsScopeItemsIds?.includes(currentTag.tagId)) {
                acc.push({
                  id: currentTag.tagId,
                  displayName: currentTag.displayName,
                  scopeName: ScopeNames.Tags,
                });
              }
              return acc;
            },
            [],
          )
        : [];

      return [...appOwnerOptions, ...tagsOptions];
    },
  },
  {
    proxy: baseStore,
  },
);
interface GlobalDataViewSelectorStoreState {
  selectedAppOwnersEmails: string[];
  selectedTagIds: string[];
  filterValue: string;
  isModalVisible: boolean;
  selectedOption: Nullable<GlobalDataViewSelectorOption>;
  isLoadingNameUpdate: boolean;
  isLoadingIdUpdate: boolean;
  isLoadingDeleteOption: boolean;
}
