import * as React from 'react';
import { useLocation } from 'react-router';

export interface IPathNameChangeListenerProps {
  onPathNameChange(locationPathName: string): void;
}

const PathNameChangeListener: React.FC<
  React.PropsWithChildren<IPathNameChangeListenerProps>
> = ({ onPathNameChange }) => {
  const location = useLocation();

  React.useEffect(() => {
    onPathNameChange(location.pathname);
  }, [location.pathname, onPathNameChange]);

  return null;
};

export default PathNameChangeListener;
