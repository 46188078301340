import { Theme, lighten } from '@mui/material';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const OscarMatrixCellCoveredByOx: FC<
  PropsWithChildren<OscarMatrixCellCoveredByOxProps>
> = ({ coveredByOx, children }) => {
  const { classes, cx } = useStyles();
  const [showCoveredByOx, setCoveredByOx] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      coveredByOx && setCoveredByOx(true);
    }, 1000);
  }, [coveredByOx]);

  return (
    <div
      className={cx(
        classes.oscarMatrixCellCoveredByOx,
        showCoveredByOx && classes.active,
      )}>
      {children}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  oscarMatrixCellCoveredByOx: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 5,
    background:
      theme.palette.mode === 'light'
        ? '#F9FAFC'
        : theme.palette.background.default,
    border:
      theme.palette.mode === 'light'
        ? '1px solid #E5E5E5'
        : `1px solid ${lighten(theme.palette.background.default, 0.3)}`,

    transition: theme.transitions.create(
      ['background', 'border', 'transform'],
      { duration: 1000 },
    ),
    '&::after': {
      boxShadow:
        theme.palette.mode === 'light'
          ? `0 0 5px 1px ${theme.palette.primary.main}`
          : `0 0 5px 1px ${theme.palette.primary.dark}`,
    },
  },
  active: {
    background: theme.palette.mode === 'light' ? '#f7f6ff' : '#14162f',
    border:
      theme.palette.mode === 'light'
        ? `1px solid #6837FF`
        : `1px solid ${theme.palette.primary.dark}`,
  },
}));

export interface OscarMatrixCellCoveredByOxProps {
  coveredByOx: boolean;
}

export default OscarMatrixCellCoveredByOx;
