import { FaKey } from 'react-icons/fa6';
import { FaWrench } from 'react-icons/fa';
import { FaFileImport } from 'react-icons/fa';
import { FaArrowsTurnToDots } from 'react-icons/fa6';
import { FaShareNodes } from 'react-icons/fa6';
import { CgMoreO } from 'react-icons/cg';
import { Nullable } from 'ox-common-types';

export const getEvidenceIcon = (evidence?: Nullable<string>) => {
  return EvidencyIconMap[evidence as keyof typeof EvidencyIconMap] ?? CgMoreO;
};

const EvidencyIconMap = {
  deps: FaArrowsTurnToDots,
  import: FaFileImport,
  token: FaKey,
  usage: FaWrench,
  vanilla: FaShareNodes,
};
