import { Theme, Typography } from '@mui/material';
import { AppPages } from 'app-navigator';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Score, { ScoreProps } from './Score';

const ScoreWithLabel: FC<React.PropsWithChildren<ScoreWithLabelProps>> = ({
  label,
  value,
  severityType,
  appLink,
  businessPriority,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.scoreWithLabel}>
      <Score
        value={value}
        severityType={severityType}
        businessPriority={businessPriority}
      />
      <Typography
        className={classes.appLink}
        to={appLink && AppPages.Applications + `?appId=${appLink}`}
        component={appLink ? RouterLink : 'div'}
        variant='body2'
        color='text.primary'>
        {label}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  scoreWithLabel: {
    display: 'inline-flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  appLink: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export interface ScoreWithLabelProps extends ScoreProps {
  label: string;
  appLink?: string;
  businessPriority?: boolean;
}

export default ScoreWithLabel;
