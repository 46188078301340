import { reportClient } from 'api-clients';
import getSupplyChain from '../../supply-chain/services/get-supply-chain/get-supply-chain-service';
import getIssuesScore from './get-issues-score.service';
import fetchOrgScanInfoResults from './get-org-scan-info/get-org-scan-info';
import getCategoriesInPrevent from './get-categories-in-prevent/get-categories-in-prevent';

const dashboardService = {
  getIssuesScore: getIssuesScore(reportClient),
  getSupplyChain: getSupplyChain(reportClient),
  fetchOrgScanInfoResults: fetchOrgScanInfoResults(reportClient),
  getCategoriesInPrevent: getCategoriesInPrevent(reportClient),
};

export default dashboardService;
