import { slackClient } from 'api-clients';
import getPublicChannels from './get-public-channels/get-public-channels';
import sendSlackNotification from './send-slack-notification/send-slack-notification';
import getUsersAndPublicChannels from './get-users-and-public-channels/get-users-and-public-channels';
const slackService = {
  getPublicChannels: getPublicChannels(slackClient),
  sendSlackNotification: sendSlackNotification(slackClient),
  getUsersAndPublicChannels: getUsersAndPublicChannels(slackClient),
};

export default slackService;
