import { LoadingButtonProps } from '@mui/lab';
import { Button, CircularProgress, useTheme } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { MdLogin as LoginIcon } from 'react-icons/md';

const LoginButton: FC<React.PropsWithChildren<LoadingButtonProps>> = props => {
  const { loading, ...buttonProps } = props;
  const theme = useTheme();

  return (
    <Button
      startIcon={<LoginIcon />}
      variant='contained'
      color='primary'
      disabled={loading}
      {...buttonProps}>
      {loading ? <CircularProgress size={theme.spacing(2)} /> : 'Login'}
    </Button>
  );
};

export default LoginButton;
