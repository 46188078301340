import { Divider, Link, Theme, Typography } from '@mui/material';
import { AppPages } from 'app-navigator';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { DiscoveredPolicies } from '../../../dashboard/dashboard-types';
import NonHistoricalData, { NonHistoricalDataProps } from './NonHistoricalData';

const NonHistoricalDataTooltip: FC<
  React.PropsWithChildren<NonHistoricalDataTooltipProps>
> = ({ orgScanInfo, discoveredPolicies, numberOfDevs }) => {
  const { classes, cx } = useStyles();

  return (
    <>
      <div className={classes.popper}>
        <div role='rowheader' className={cx(classes.row, classes.header)}>
          <Typography variant='body1' color='text.primary'>
            Protecting {numberOfDevs} Developers
          </Typography>
        </div>
        <Divider />
        <div role='row' className={classes.row}>
          <NonHistoricalData
            discoveredPolicies={discoveredPolicies}
            orgScanInfo={orgScanInfo}
          />
          <Link
            className={classes.systemLink}
            component={RouterLink}
            to={AppPages.Connectors}>
            <Typography variant='body2' color='primary'>
              Connect more Systems
            </Typography>
          </Link>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  popper: {
    zIndex: theme.zIndex.drawer + 2,
    borderRadius: theme.shape.borderRadius,
  },
  systemLink: {
    paddingInline: theme.spacing(2),
    textTransform: 'capitalize',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': { textDecoration: 'underline' },
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    paddingBlock: theme.spacing(1.5),
    paddingInline: theme.spacing(2),
  },
  closeButton: {
    alignSelf: 'end',
  },
  header: {
    paddingInlineStart: theme.spacing(3.5),
  },
}));

export interface NonHistoricalDataTooltipProps extends NonHistoricalDataProps {
  activePoliciesCount: number;
  discoveredPolicies: DiscoveredPolicies;
  numberOfDevs: number;
}

export default NonHistoricalDataTooltip;
