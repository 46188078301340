import { Button, ButtonProps, Theme, Typography } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Circle } from './Circle';

export const FilterButton = <T extends unknown>({
  color,
  label,
  count,
  isActive,
  className,
  onClick,
  value,
  ...rest
}: FilterButtonProps<T>) => {
  const { classes, cx } = useStyles();

  const handleClick = useCallback(() => {
    onClick && onClick(value!);
  }, [value, onClick]);

  return (
    <Button
      className={cx(classes.filterButton, className)}
      color={isActive ? 'primary' : 'inherit'}
      onClick={handleClick}
      {...rest}>
      <Circle color={color} className={classes.circle} />
      <Typography
        variant='body1'
        fontWeight={600}
        component='span'
        className={classes.count}
        sx={{ lineHeight: 1 }}>
        {count.toLocaleString()}
      </Typography>
      <Typography
        variant='body1'
        fontWeight={400}
        component='span'
        sx={{ lineHeight: 1 }}>
        {label}
      </Typography>
    </Button>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  filterButton: {
    display: 'flex',
    justifyContent: 'start',
    textTransform: 'none',
    padding: theme.spacing(0.5, 1, 0.5, 0),
  },
  circle: {
    flexShrink: 0,
    marginInlineEnd: theme.spacing(1.5),
  },
  count: {
    marginInlineEnd: theme.spacing(0.5),
  },
}));

interface FilterButtonProps<T>
  extends Omit<ButtonProps, 'color' | 'onClick' | 'value'> {
  count: number;
  label: string;
  color: string;
  isActive: boolean;
  // value will be passed in the onClick callback
  value?: T;
  onClick?: (value: T) => void;
}
