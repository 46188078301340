import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { SearchInput } from 'ox-filter-utils';
import { SbomLibraryItem } from '../../sbom-types';
import query from './get-libraries.gql';

const getSbomLibraries = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getSbomLibrariesInput: GetSbomLibrariesInput,
      cache = true,
    ) => {
      try {
        const results = await client.query<{
          getSbomLibraries: SbomLibrariesResponse;
        }>({
          query,
          variables: { getSbomLibrariesInput },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });

        return results.data.getSbomLibraries;
      } catch (error) {
        logger.error('Failed to fetch sbom libraries', error);
        return null;
      }
    },
  };
};

export interface GetSbomLibrariesInput {
  limit?: number;
  offset: number;
  search?: string;
  filters?: {};
  sbomSearch?: SearchInput[];
  owners?: string[];
  openItems?: string[];
}

export interface SbomLibrariesResponse {
  sbomLibs: SbomLibraryItem[];
  offset: number;
  total: number;
  totalFilteredSbomLibs: number;
}

export default getSbomLibraries;
