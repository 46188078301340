import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import { v4 as uuidv4 } from 'uuid';
import { Organization } from '../../organization-types';
import mutation from './create-organization.gql';

// Name of the organization you would like to create. This is the name that an end-user would type in the pre-login prompt to identify which organization they wanted to log in through. Unique logical identifier, which cannot be changed. May contain lowercase alphabetical characters, numbers, underscores (_), and dashes (-). Can start with a number. Must be between 3 and 50 characters.
const sanitizeOrgName = (str: string): string => {
  return str
    .replace(/[^a-z0-9_-]/gim, '')
    .trim()
    .toLowerCase();
};
const createValidOrgNam = (orgName: string): string => {
  const sanitizedOrgName = sanitizeOrgName(orgName);
  const randomString = uuidv4().substring(0, 8);
  return `${randomString}-${sanitizedOrgName}`;
};

const createOrganization = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      orgDisplayName: string,
    ): Promise<Nullable<Organization>> => {
      const validOrgName = createValidOrgNam(orgDisplayName);

      const createOrganizationInput = {
        organizationInfo: {
          name: validOrgName,
          display_name: orgDisplayName,
        } as CreateOrganizationInput,
      };

      const results = await client.mutate<CreateOrganizationResponse>({
        mutation,
        variables: createOrganizationInput,
      });

      if (!results.data) {
        throw new Error('failed to create organization');
      }

      return results.data.createOrganization;
    },
  };
};

interface CreateOrganizationInput {
  name: string;
  display_name: string;
}

interface CreateOrganizationResponse {
  createOrganization: Organization;
}

export default createOrganization;
