import { Divider, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import withDisabledIfUnauthorized from '../../../../permissions/higher-order-components/withDisabledIfUnauthorized';
import {
  Exclusions,
  IssueAction,
  JiraTicketRef,
} from '../../types/issues-types';
import IssueCardActionV2, {
  IssueActionProps,
} from '../IssueItem/IssueActionV2';

const IssueActionsV2: FC<React.PropsWithChildren<IssueActionsProps>> = ({
  actions,
  issueId,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.issueActions} data-testid='issue-actions'>
      <Divider orientation='vertical' flexItem />
      {actions.map((actionsBlock, i) => (
        <Stack gap={0.5} spacing={0.5} direction='row' key={i}>
          {actionsBlock.map((action, index) => {
            const key = `${action.tooltipText.split(' ').join('-')}-${index}`;
            const { onClick, ...props } = action;
            const IssueCardAction =
              withDisabledIfUnauthorized<IssueActionProps>(IssueCardActionV2, {
                requiredPermissions: action.permissions.map(permission => ({
                  permissionType: permission.permissionType,
                  permissionScope: permission.permissionScope,
                })),
              });
            return (
              <IssueCardAction
                id={action?.actionId}
                key={key}
                issueId={issueId}
                {...props}
                onClick={onClick}
              />
            );
          })}
          {i !== actions.length - 1 && (
            <Divider orientation='vertical' flexItem />
          )}
        </Stack>
      ))}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  issueActions: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}));

export interface IssueActionsProps {
  actions: IssueAction[][];
  issueId: string;
  tickets?: JiraTicketRef[];
  exclusionsCategories?: Exclusions[];
}

export default IssueActionsV2;
