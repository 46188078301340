import React from 'react';
import { Typography } from '@mui/material';

export const Dash = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50px',
    }}>
    <Typography variant='body2' color='text.secondary'>
      —
    </Typography>
  </div>
);
