import { isValidOrgName } from 'common-utils';
import { getInvalidOrgDisplayNameHelperText } from 'organizations';
import { createStore, derive } from 'store-utils';

const baseStore = createStore<OrgSettingsStoreState>(
  {
    isRemoveOrgModalVisible: false,
    isLoadingRemoveOrg: false,
    isLoadingUpdateOrgDisplayName: false,
    isOrgDisplayNameUpdated: false,
    orgDisplayName: '',
    removeOrgVerificationValue: '',
    errorMessage: '',
  },
  'Org Settings Store',
);

const OrgSettingsStore = derive(
  {
    isValidOrgDisplayName: get => {
      const { orgDisplayName } = get(baseStore);
      return isValidOrgName(orgDisplayName);
    },
    invalidOrgDisplayNameHelperText: get => {
      const { orgDisplayName } = get(baseStore);
      return getInvalidOrgDisplayNameHelperText(orgDisplayName);
    },
  },
  {
    proxy: baseStore,
  },
);

interface OrgSettingsStoreState {
  isRemoveOrgModalVisible: boolean;
  isLoadingRemoveOrg: boolean;
  isLoadingUpdateOrgDisplayName: boolean;
  isOrgDisplayNameUpdated: boolean;
  orgDisplayName: string;
  removeOrgVerificationValue: string;
  errorMessage: string;
}

export default OrgSettingsStore;
