import {
  Box,
  FormControlLabel,
  FormGroup,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ScopeNames } from '@oxappsec/ox-unified-permissions/src/scopes';
import { AppStoreState } from 'app-store/src/app-store';
import { ChangeEvent, FC, useCallback } from 'react';
import { FaUserTag } from 'react-icons/fa6';
import { IoMdPricetag } from 'react-icons/io';
import { makeStyles } from 'tss-react/mui';
import { AppOwnerDefault } from './DataViewSelectorContainer';
import { GlobalDataViewSelectorOption } from './data-view-selector-types';
import {
  openDataViewSelectorModal,
  setSelectedOption,
} from './global-data-view-selector-store-actions';

const DataViewSelector: FC<
  React.PropsWithChildren<DataViewSelectorOptionsListProps>
> = ({
  entireOrg,
  unassignedOwner,
  onOptionSelect,
  options,
  ownersCount,
  user,
  filterValue,
  selectedAppOwnersEmails,
  selectedTagIds,
}) => {
  const { classes } = useStyles();

  const onOptionClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      option: GlobalDataViewSelectorOption,
    ) => {
      e.preventDefault();
      setSelectedOption(option);
      openDataViewSelectorModal();
    },
    [],
  );

  const entireScopeValue = user?.scopes
    ? AppOwnerDefault.EntireScope
    : AppOwnerDefault.EntireOrganization;

  return (
    <div role='row'>
      <FormGroup>
        <FormControlLabel
          className={classes.checkboxItem}
          disableTypography
          control={
            <Checkbox
              disabled={options.length === 0}
              onChange={onOptionSelect}
              checked={entireOrg}
              value={entireScopeValue}
              className={classes.checkbox}
            />
          }
          label={
            <Typography
              lineHeight={2}
              className={classes.text}
              color={entireOrg ? 'primary' : 'text.primary'}
              variant='caption'>
              {entireScopeValue}
            </Typography>
          }
        />
        {ownersCount && !user?.scopes ? (
          <FormControlLabel
            className={classes.checkboxItem}
            disableTypography
            control={
              <Checkbox
                disabled={options.length === 0}
                onChange={onOptionSelect}
                checked={unassignedOwner}
                value={AppOwnerDefault.UnAssigned}
                className={classes.checkbox}
              />
            }
            label={
              <Typography
                lineHeight={2}
                className={classes.text}
                color={unassignedOwner ? 'primary' : 'text.primary'}
                variant='caption'>
                {AppOwnerDefault.UnAssigned}
              </Typography>
            }
          />
        ) : (
          <></>
        )}

        {(!!filterValue
          ? options.filter(option =>
              option.displayName.toLowerCase().includes(filterValue),
            )
          : options
        ).map((option, index) => {
          const isOptionChecked = [
            ...selectedAppOwnersEmails,
            ...selectedTagIds,
          ].includes(option.id);
          return (
            <FormControlLabel
              className={classes.checkboxItem}
              disableTypography
              key={index}
              control={
                <Checkbox
                  onChange={onOptionSelect}
                  checked={isOptionChecked}
                  value={option.id}
                  className={classes.checkbox}
                />
              }
              label={
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  width='100%'
                  pr={1.5}>
                  <Typography
                    lineHeight={2}
                    className={classes.textOverflow}
                    variant='caption'
                    color={isOptionChecked ? 'primary' : 'text.primary'}>
                    {option.displayName}
                  </Typography>
                  <Tooltip arrow title={'View details'} placement='top'>
                    <Box flex={0} onClick={e => onOptionClick(e, option)}>
                      {option.scopeName === ScopeNames.AppOwner ? (
                        <FaUserTag className={classes.listItemIcon} />
                      ) : (
                        <IoMdPricetag className={classes.listItemIcon} />
                      )}
                    </Box>
                  </Tooltip>
                </Box>
              }
            />
          );
        })}
      </FormGroup>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  textOverflow: {
    maxWidth: 120,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    flexGrow: 1,
  },
  text: {
    textAlign: 'center',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
  },
  checkboxItem: {
    margin: '0 !important',
    '&:hover': {
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.04)',
    },
  },
  checkbox: {
    transform: 'scale(0.7)',
    padding: theme.spacing(0.7),
  },
  listItemIcon: {
    fill: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
}));

export interface DataViewSelectorOptionsListProps {
  entireOrg: boolean;
  unassignedOwner?: boolean;
  filterValue: string;
  ownersCount: number;
  options: GlobalDataViewSelectorOption[];
  onOptionSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  user?: AppStoreState['user'];
  selectedAppOwnersEmails: string[];
  selectedTagIds: string[];
}

export default DataViewSelector;
