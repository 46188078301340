import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { LogName } from '@oxappsec/audit-log-service';
import { logger } from 'logging-utils';
import mutation from './audit-user-login.gql';

const auditUserLogin = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      logAction: LogName.Login | LogName.Logout,
    ): Promise<void> => {
      try {
        await client.mutate<MutationResponse>({
          mutation,
          variables: { input: { action: logAction } },
        });
      } catch (error) {
        logger.error('audit user login failed', error);
      }
    },
  };
};

interface MutationResponse {
  acknowledge: boolean;
}

export default auditUserLogin;
