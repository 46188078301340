import { Edge, getIncomers, MarkerType, Node, Position } from 'reactflow';
import { DependencyEdge, DependencyNode } from '../../../types/issues-types';

export const getGraphByCondition = (
  allNodes: Node[],
  allEdges: Edge[],
  cond: (node?: Node) => boolean,
) => {
  const roots = allNodes.filter(
    node => getIncomers(node, allNodes, allEdges).length === 0,
  );
  const visited = new Set();
  const edgesSet = new Set();
  const dfs = (nodeName: string, path: Edge[]) => {
    const node = allNodes.find(n => n.id === nodeName);
    visited.add(nodeName);
    if (cond(node)) {
      path.forEach(e => edgesSet.add(e.id));
      path = [];
    }

    const children = allEdges.filter(e => e.source === nodeName);
    children.forEach(child => {
      if (!visited.has(child.target)) {
        dfs(child.target, path.concat(child));
      }
    });
  };

  roots.forEach(r => dfs(r.id, []));

  const edges = allEdges.filter(edge => edgesSet.has(edge.id));
  const nodes = allNodes.filter(node =>
    edges.some(e => node.id === e.source || node.id === e.target),
  );

  roots.forEach(r => !nodes.some(n => n.id === r.id) && nodes.push(r));

  return { edges, nodes };
};

export const formatNodes = (
  nodes: DependencyNode[],
  selectedNode?: string,
  nodeType?: string,
): Node<DependencyNode>[] =>
  nodes.map(node => {
    return {
      id: node.id,
      data: {
        id: node.id,
        label: node.name,
        vulnerable: node.vulnerable,
        issues: node.issues,
        selected: selectedNode?.endsWith(node.id),
      },
      type: 'special',
      sourcePosition: nodeType === 'tb' ? Position.Bottom : Position.Right,
      targetPosition: nodeType === 'tb' ? Position.Top : Position.Left,
      position: {
        x: node.position!.x,
        y: node.position!.y,
      },
    };
  });

export const markVulnerablePath = (
  nodeName: string,
  path: Edge[],
  edges: Edge[],
  nodes: Node<DependencyNode>[],
  visited: Set<string>,
) => {
  const node = nodes.find(n => n.id === nodeName);
  if (node && !visited.has(nodeName)) {
    visited.add(nodeName);
    if (node.data.vulnerable) {
      path.forEach(edge => {
        edge.data = { vulnerable: true };
      });
    }

    edges.forEach(edge => {
      if (edge.source === node.id) {
        markVulnerablePath(
          edge.target,
          path.concat(edge),
          edges,
          nodes,
          visited,
        );
      }
    });
  }
};

export const getFormattedData = (
  nodes: DependencyNode[],
  edges: DependencyEdge[],
  selectedNode?: string,
  nodeType?: string,
) => {
  const allNodes = formatNodes(nodes, selectedNode, nodeType);

  let allEdges: Edge[] = edges.map(edge => ({
    id: `${edge.v}_${edge.w}`,
    source: edge.v,
    target: edge.w,
  }));

  const roots = allNodes.filter(
    node => !allEdges.some(e => e.target === node.id),
  );
  const visited = new Set<string>();
  roots.forEach(root =>
    markVulnerablePath(root.id, [], allEdges, allNodes, visited),
  );
  allEdges = allEdges.map(edge => ({
    ...edge,
    style: {
      ...(edge.data?.vulnerable && {
        stroke: 'red',
      }),
    },
    markerEnd: {
      type: MarkerType.Arrow,
      width: 25,
      height: 25,
      ...(edge.data?.vulnerable && {
        color: 'red',
      }),
    },
  }));
  // const { edges: vulnerableEdges, nodes: vulnerableNodes } =
  //   getGraphByCondition(allNodes, allEdges, node => node?.data.vulnerable);
  return {
    allNodes,
    allEdges,
  };
};
