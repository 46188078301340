export const envToBoolean = (
  value: string | undefined,
  defaultValue: boolean,
): boolean => {
  return value === undefined ? defaultValue : value.toLowerCase() === 'true';
};

export const kebabToCamel = (str: string) => {
  if (!str.includes('-')) {
    return str;
  }

  const words = str.split('-');
  const camelCased = words
    .map((word, index) => {
      if (index !== 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    })
    .join('');

  return camelCased;
};
