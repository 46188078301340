import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  JiraPriority,
  JiraProject,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import {
  InternalssueInfo,
  PreSelectableIssueFields,
} from '../../types/jira-ticket-types';
import query from './get-all-selectable-ticket-fields.gql';

const getAllSelectableTicketFields = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<InternalssueInfo, Nullable<GetAllFieldsResponse>> => {
  return {
    execute: async (input): Promise<Nullable<GetAllFieldsResponse>> => {
      try {
        const results = await client.query<GetAllFieldsResponse>({
          query,
          variables: {
            provider: TicketProvider.Jira,
            input,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error(
          'Failed to fetch all selectable and pre selectable ticket fields',
          error,
        );
        return null;
      }
    },
  };
};

export interface GetAllFieldsResponse {
  getLatestProjects: JiraProject[];
  listLabels: string[];
  listPriorities: JiraPriority[];
  getPreSelectFields: PreSelectableIssueFields;
}

export default getAllSelectableTicketFields;
