import { Theme, Tooltip } from '@mui/material';
import { Nullable } from 'ox-common-types';
import { FC, ReactElement } from 'react';
import { FiArrowDownRight, FiArrowRight, FiArrowUpRight } from 'react-icons/fi';
import { makeStyles } from 'tss-react/mui';

export const TrendIcon: FC<React.PropsWithChildren<TrendIconProps>> = props => {
  const { classes } = useStyles();
  const { value, tooltip } = props;
  let Icon: Nullable<ReactElement> = null;

  if (value < 0) {
    Icon = <FiArrowDownRight className={classes.down} />;
  } else if (value > 0) {
    Icon = <FiArrowUpRight className={classes.up} />;
  } else {
    Icon = <FiArrowRight className={classes.neutral} />;
  }

  return (
    <Tooltip arrow placement='top' title={tooltip || ''}>
      <div style={{ display: 'inline-flex' }}>{Icon}</div>
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  up: {
    color: theme.palette.error.main,
  },
  down: {
    color: theme.palette.success.main,
  },
  neutral: {
    color: theme.palette.text.secondary,
  },
}));
export interface TrendIconProps {
  size?: number;
  value: number;
  tooltip?: string;
}
