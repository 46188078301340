import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  darken,
  IconButton,
  Theme,
  Typography,
  lighten,
} from '@mui/material';
import { stopPropagation } from 'common-utils';
import { important } from 'ox-common-types';
import { FilterElements, FilterType } from 'ox-filter-utils';
import React, { FC, useCallback, useState } from 'react';
import {
  MdClose as CloseIcon,
  MdExpandMore as ExpandMoreIcon,
} from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

export const FilterBox: FC<React.PropsWithChildren<FilterBoxProps>> = ({
  header,
  children,
  filtersNumber,
  type,
  handleClear,
  onOpenChange,
  isLoading,
  onToggleSelectAllItems,
  items,
}) => {
  const { classes, cx } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(() => {
    const oldState = isOpen;
    setIsOpen(oldValue => {
      if (type && onOpenChange) {
        onOpenChange(type, !oldState);
      }
      return !oldValue;
    });
  }, [isOpen, onOpenChange, type]);

  const onClearClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    stopPropagation(event);
    handleClear && type && handleClear(type);
  };

  const handleToggleSelectAllItemsClicked = (type: string) => {
    if (onToggleSelectAllItems) {
      onToggleSelectAllItems(type);
    }
  };
  const isSelectAllChecked =
    Boolean(filtersNumber && filtersNumber > 0) &&
    items?.length === filtersNumber;

  const isIntermediateChecked = Boolean(
    !isSelectAllChecked && filtersNumber && filtersNumber > 0 && items,
  );

  const isShowCheckboxWhenSelectedItems =
    isSelectAllChecked || isIntermediateChecked;

  const isSelectAllPropsProvided =
    onToggleSelectAllItems && items && items.length > 0;

  // To show the select all checkbox please pass items list and onToggleSelectAllItems function
  const isShowSelectAllCheckbox =
    isSelectAllPropsProvided &&
    (isShowCheckboxWhenSelectedItems || (isOpen && !isLoading));

  return (
    <div
      className={classes.filterBox}
      data-testid={`filter_box filter_header_${header}`}>
      <Button
        component='div'
        onClick={handleChange}
        className={cx(
          classes.header,
          isShowSelectAllCheckbox
            ? classes.headerWithCheckbox
            : classes.headerWithoutCheckbox,
        )}
        endIcon={
          isLoading ? (
            <div className={classes.circularProgress}>
              <CircularProgress size={14} />
            </div>
          ) : (
            <ExpandMoreIcon className={isOpen ? classes.rotate : ''} />
          )
        }>
        <div className={classes.filterHeader}>
          {isShowSelectAllCheckbox && type && (
            <Checkbox
              color='primary'
              size='small'
              className={classes.checkbox}
              onClick={stopPropagation}
              onChange={() => {
                handleToggleSelectAllItemsClicked(type);
              }}
              checked={isSelectAllChecked}
              indeterminate={isIntermediateChecked}
            />
          )}
          <Typography
            color='text.primary'
            variant='subtitle2'
            fontSize='0.725rem'>
            {header}
          </Typography>
          {filtersNumber && filtersNumber > 0 ? (
            <>
              <Typography
                color='primary.main'
                variant='subtitle2'
                fontSize='0.725rem'
                ml={1}>
                ({filtersNumber})
              </Typography>

              {handleClear && (
                <IconButton
                  onClick={onClearClick}
                  size='small'
                  sx={{ ml: 0.5 }}>
                  {<CloseIcon style={{ width: '0.8rem', height: '0.8rem' }} />}
                </IconButton>
              )}
            </>
          ) : null}
        </div>
      </Button>
      <Collapse in={isOpen} unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  filterBox: {
    boxShadow: `0px 1px 7px ${darken(theme.palette.background.paper, 0.2)}`,
    border: `1px solid ${lighten(theme.palette.divider, 0.5)}`,
    borderRadius: theme.shape.borderRadius,
  },
  header: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    width: '100%',
    paddingInline: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    justifyContent: important('space-between'),
    color: important(theme.palette.text.primary),
    textTransform: important('none'),
  },
  headerWithoutCheckbox: {
    backgroundColor: 'red',
    padding: important(theme.spacing(0.5, 2)),
  },
  headerWithCheckbox: {
    padding: theme.spacing(0.5, 2, 0.5, 0),
  },
  checkbox: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  filterHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    width: 15,
  },
}));

export interface FilterBoxProps {
  header: FilterType;
  filtersNumber?: number;
  handleClear?: (type: string) => void;
  type?: string;
  onOpenChange?: (type: string, isOpen: boolean) => void;
  isLoading?: boolean;
  onToggleSelectAllItems?: (type: string) => void;
  items?: FilterElements[];
}
