import {
  ClickAwayListener,
  Divider,
  IconButton,
  Popper,
  RadioGroup,
  SelectChangeEvent,
  Theme,
  Typography,
} from '@mui/material';
import { DateUnit, IDateRange } from 'ox-common-types';
import { ChangeEvent, FC, MouseEvent, useCallback, useState } from 'react';
import {
  MdDateRange as DateRangeIcon,
  MdKeyboardArrowDown as KeyboardArrowDownOutlinedIcon,
  MdKeyboardArrowUp as KeyboardArrowUpOutlinedIcon,
} from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import DateRadioOption from './DateRadioOption';
import { navigateWithParameters } from 'app-navigator';
import { currentPage } from '../../../../../../utilities/app-navigator/src/app-navigator';

const DateRangeSelector: FC<
  React.PropsWithChildren<DateRangeSelectorProps>
> = ({ filter, onChange, disabled = false }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [limit, setLimit] = useState(1);

  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      !disabled && setAnchorEl(open ? null : event.currentTarget);
    },
    [disabled, open],
  );

  const onClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChange =
    (limit: number) =>
    (event: SelectChangeEvent | ChangeEvent<HTMLInputElement>) => {
      const updatedDateFilter: IDateRange = {
        limit,
        unit: event.target.value as DateUnit,
      };
      const _currentPage = currentPage();
      navigateWithParameters(_currentPage, {
        unit: updatedDateFilter.unit,
        limit: updatedDateFilter.limit.toString(),
      });
      onChange && onChange(updatedDateFilter);
    };

  const handleLimitChange = (limit: number) => {
    if (limit > 12 || limit === 0) {
      return;
    }
    return () => {
      const newFilter = {
        ...filter,
        limit,
      };
      searchParams.set('unit', newFilter.unit);
      searchParams.set('limit', newFilter.limit.toString());
      setSearchParams(searchParams);
      setLimit(limit);
      onChange && onChange(newFilter);
    };
  };

  const id = open ? 'select-date-range-pop-over' : undefined;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={classes.controlWidth}>
        <div
          onClick={handleClick}
          aria-describedby={id}
          className={classes.controlLabel}>
          <DateRangeIcon fontSize='small' />
          <Typography
            display='inline-flex'
            flexGrow={1}
            alignItems='center'
            justifyContent='center'
            textTransform='capitalize'
            variant='body2'
            noWrap>
            {filter.limit}{' '}
            {filter.limit === 1 ? filter.unit : `${filter.unit}s`}
          </Typography>
          {open ? (
            <IconButton color='inherit' size='small'>
              <KeyboardArrowUpOutlinedIcon />
            </IconButton>
          ) : (
            <IconButton color='inherit' size='small'>
              <KeyboardArrowDownOutlinedIcon />
            </IconButton>
          )}
        </div>
        <Popper
          placement='bottom'
          className={classes.popper}
          id={id}
          open={open}
          anchorEl={anchorEl}>
          <div className={classes.controlWidth}>
            <RadioGroup>
              {options.map((option, index) => (
                <DateRadioOption
                  key={index}
                  handleChange={handleChange(option.limit)}
                  filter={filter}
                  {...option}
                />
              ))}
              <Divider />
              <DateRadioOption
                handleLimitChange={handleLimitChange}
                handleChange={handleChange(limit)}
                filter={filter}
                unit={DateUnit.Day}
                limit={limit}
              />
            </RadioGroup>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const options = [
  { unit: DateUnit.Week, limit: 1 },
  { unit: DateUnit.Week, limit: 2 },
  { unit: DateUnit.Month, limit: 1 },
  { unit: DateUnit.Month, limit: 2 },
  { unit: DateUnit.Month, limit: 3 },
];

interface DateRangeSelectorProps {
  filter: IDateRange;
  onChange?: (dateRange: IDateRange) => void;
  disabled?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  popper: {
    zIndex: theme.zIndex.drawer + 2,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows['6'],
    backgroundColor: theme.palette.background.paper,
  },
  controlLabel: {
    cursor: 'pointer',
    height: '2.1rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    paddingBlock: theme.spacing(0.5),
    paddingInline: theme.spacing(1),
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  controlWidth: {
    width: '8rem',
  },
}));

export default DateRangeSelector;
