import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import { snapshot } from 'valtio';
import { ScanSettings } from '../scan-types';
import ScanStore, { ScanPhase } from './scan-store';

export const setScanPhase = (phase: ScanPhase) => {
  ScanStore.phase = phase;
};

export const setScanId = (scanID: Nullable<string>) => {
  ScanStore.scanID = scanID;
};

export const setRealScanId = (realScanId: Nullable<string>) => {
  ScanStore.realScanId = realScanId;
};

export const setIsDeltaScan = (isFullScan: boolean) => {
  ScanStore.isDeltaScan = !isFullScan;
};

export const setIsLoadingCancelScan = (payload: boolean) => {
  ScanStore.isCancelScanLoading = payload;
};

export const setCancelScanErrorMessage = (message: Nullable<string>) => {
  ScanStore.cancelScanErrorMessage = message;
};

export const incrementScanCount = () => {
  const { scanCount } = snapshot(ScanStore);
  ScanStore.scanCount = scanCount + 1;
};

export const decrementScanCount = () => {
  const { scanCount } = snapshot(ScanStore);
  if (!scanCount) {
    return;
  }

  ScanStore.scanCount = scanCount - 1;
};

export const openCancelScanModal = (): void => {
  setCancelScanModalVisibility(true);
};

export const closeCancelScanModal = (): void => {
  setCancelScanModalVisibility(false);
  setCancelScanErrorMessage(null);
};

const setCancelScanModalVisibility = (payload: boolean): void => {
  ScanStore.isCancelScanModalVisible = payload;
};

export const openExceedScanLimitModal = (): void => {
  setExceedScanLimitModalVisibility(true);
};

export const closeExceedScanLimitModal = (): void => {
  setExceedScanLimitModalVisibility(false);
};

const setExceedScanLimitModalVisibility = (payload: boolean): void => {
  ScanStore.isExceedScanLimitModalVisible = payload;
};

export const dismissScanError = () => {
  setScanPhase(ScanPhase.Done);
  setScanError(null);
};

export const setScanError = (value: Nullable<string>) => {
  ScanStore.errorMessage = value;
  // If the value is null it means we're resetting the error
  if (value) {
    logger.error(
      `Error during scan, phase: ${ScanStore.phase}, error: ${value}`,
    );
    setScanPhase(ScanPhase.Error);
  }
};

export const setDemoEnabled = (isDemoEnabled: boolean) => {
  ScanStore.isDemoEnabled = isDemoEnabled;
};

export const setScanSettings = (scanSettings: ScanSettings) => {
  ScanStore.scanCount = scanSettings.scanCount;
  ScanStore.maxScansToRun = scanSettings.maxScansToRun;
  ScanStore.maxReposToScan = scanSettings.maxReposToScan;
  setDemoEnabled(scanSettings.isDemoEnabled);
};

export const setEnabledScheduledScans = (enabledScheduledScans: boolean) => {
  ScanStore.enabledScheduledScans = enabledScheduledScans;
};
