import { User as Auth0User } from '@auth0/auth0-react';
import { Roles } from '@oxappsec/ox-unified-permissions';
import { env } from 'env-utils';
import { Nullable } from 'ox-common-types';

export const CUSTOM_ROLES = `${env().REACT_APP_AUTH0_AUDIENCE}/roles`;

export interface User extends Auth0User {
  roles: Nullable<Roles[]>;
}
