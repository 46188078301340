import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import { AppBranchesToScanInput } from '../../types/settings-types';
import mutation from './define-app-branches-to-scan.gql';

const defineAppBranchesToScan = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      input: AppBranchesToScanInput,
    ): Promise<Nullable<Boolean>> => {
      try {
        const results = await client.mutate<UpdateBranchPerAppRes>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            input,
          },
        });
        if (!results.data || !results.data.defineAppBranchesToScan) {
          return null;
        }
        return results.data.defineAppBranchesToScan;
      } catch (error) {
        logger.error('Failed to update settings', error);
        return null;
      }
    },
  };
};

interface UpdateBranchPerAppRes {
  defineAppBranchesToScan: boolean;
}

export default defineAppBranchesToScan;
