import {
  CategoryDisplayName,
  OxCategoriesNames,
} from '@oxappsec/ox-consolidated-categories';
import CategoryIconsMap from '@oxappsec/ox-consolidated-categories/lib/src/ox-categories/icons'
import { CategoryKey } from '@oxappsec/ox-consolidated-categories/lib/src/ox-categories/types';
import { MdPolicy } from 'react-icons/md';

export const generateCategoryIcon = (category: CategoryDisplayName) => {
  return CategoryIconsMap.get(category);
};

export const generateCategoryIconByKey = (
  category: CategoryKey | 'AnyPolicy',
) => {
  if (category === 'AnyPolicy') {
    return MdPolicy;
  }
  return generateCategoryIcon(OxCategoriesNames[category]);
};
