import { AppEvents } from 'common-events';
import { Nullable } from 'ox-common-types';
import { Organization } from '../organization-types';

export const dispatchChosenOrgUpdatedEvent = (
  chosenOrg: Nullable<Organization>,
) => {
  document.dispatchEvent(
    new CustomEvent<Nullable<Organization>>(
      AppEvents.Organizations.ChosenOrgChanged,
      {
        detail: chosenOrg,
      },
    ),
  );
};
