import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import { Organization } from '../../organization-types';
import query from './get-organizations.gql';

const getOrganizations = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (): Promise<Nullable<Organization[]>> => {
      try {
        const results = await client.query<{
          getOrganizations: Organization[];
        }>({
          query,
        });
        return results.data.getOrganizations;
      } catch (error) {
        return null;
      }
    },
  };
};

export default getOrganizations;
