import { Button, Theme, Typography, useTheme } from '@mui/material';
import { AppPages, navigate, navigateWithParameters } from 'app-navigator';
import { SeverityAlert, severitiesHeatMap } from 'ox-common-types';
import { ConditionalFiltersCondition } from 'ox-filter-utils';
import { FC, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FilterButton } from './FilterButton';

export const SeverityBox: FC<React.PropsWithChildren<SeverityBoxProps>> = ({
  severityBreakDown,
  totalIssues,
  className,
  onClick,
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();

  const handleClick = useCallback((label: string) => {
    const conditionalFilters = [
      {
        condition: ConditionalFiltersCondition.OR,
        fieldName: 'criticality',
        values: [label],
      },
    ];
    navigateWithParameters(AppPages.ActiveIssues, {
      conditionalFilters: conditionalFilters,
    });
  }, []);

  const indexedSeverityBreakDown = severityBreakDown as unknown as Record<
    string,
    number
  >;

  return (
    <div className={cx(classes.severityBox, className)}>
      <div className={classes.severities}>
        {Object.keys(severityBreakDown)
          .filter(s => indexedSeverityBreakDown[s] > 0)
          .map(severity => {
            const count = indexedSeverityBreakDown[severity];
            const label = severitiesHeatMap.find(
              s => s.key === severity,
            )?.title;
            const color = theme.palette.categoryRiskColor[severity];

            if (!label || !count) {
              return null;
            }
            return (
              <FilterButton
                key={label}
                count={count}
                label={label}
                color={color}
                isActive={false}
                value={label}
                onClick={onClick || handleClick}
              />
            );
          })}
      </div>
      {totalIssues && (
        <Button
          onClick={() => navigate(AppPages.ActiveIssues)}
          color='inherit'
          sx={{
            textTransform: 'none',
            marginBlockStart: 0.5,
            textAlign: 'start',
            paddingLeft: 0,
          }}>
          <Typography
            variant='body2'
            fontWeight='bold'
            className={classes.totalIssues}>
            Total Issues: {totalIssues?.toLocaleString()}
          </Typography>
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  severityBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
  },
  severities: {
    display: 'inherit',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    alignItems: 'flex-start',
  },
  totalIssues: {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

export interface SeverityBoxProps {
  severityBreakDown: SeverityAlert;
  totalIssues?: number;
  className?: string;
  onClick?: (label: string) => void;
}
