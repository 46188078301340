import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  JiraProject,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import query from './search-projects.gql';

const getProjects = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<string, Nullable<GetProjectsResponse>> => {
  return {
    execute: async (
      searchString: string,
    ): Promise<Nullable<GetProjectsResponse>> => {
      try {
        const results = await client.query<GetProjectsResponse>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: {
            searchString,
            provider: TicketProvider.Jira,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to fetch projects', error);
        return null;
      }
    },
  };
};

export interface GetProjectsResponse {
  searchProjects: JiraProject[];
}

export default getProjects;
