import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  IChatCompletionResponse,
  IGetChatCompletionInput,
} from '@oxappsec/ox-consolidated-gpt-types';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';
import query from './fetch-chat-gpt-content.gql';

const fetchChatGptContent = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  FetchChatGptContentInput,
  Nullable<FetchChatGptOutput['getChatCompletion']>
> => {
  return {
    execute: async (fetchChatGptContentInput: FetchChatGptContentInput) => {
      try {
        const results = await client.query<FetchChatGptOutput>({
          query,
          fetchPolicy: ApolloClientCache.CacheFirst,
          variables: { input: fetchChatGptContentInput },
        });
        if (!results.data) return null;
        return results.data.getChatCompletion;
      } catch (error) {
        logger.error(`Got an error trying to fetch GPT content: ${error}`);
        return null;
      }
    },
  };
};

export interface FetchChatGptContentInput extends IGetChatCompletionInput {}
export interface FetchChatGptOutput {
  getChatCompletion: IChatCompletionResponse;
}

export default fetchChatGptContent;
