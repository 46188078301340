export const GENERAL_COLORS = [
  '#6EB0F2',
  '#0E72BB',
  '#08436F',
  '#5C378C',
  '#823895',
  '#C24995',
  '#EC717F',
  '#F38D73',
  '#40ABC0',
  '#8CA856',
];
