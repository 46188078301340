import { AppPages } from 'app-navigator';
import { AttackPathType, StandardCategory } from '../attack-path-types';
import { Theme } from '@mui/material';
import { ReactComponent as DecreaseIcon } from '../assets/decrease.svg';
import { ReactComponent as IncreaseIcon } from '../assets/increase.svg';
import { ReactComponent as InfoIcon } from '../assets/info.svg';
import { ReactComponent as ReachableIcon } from '../assets/door_open.svg';
import { ReactComponent as DamageIcon } from '../assets/destruction.svg';
import { ReactComponent as NoChangeIcon } from '../assets/static.svg';
import { ReactComponent as ComplianceIcon } from '../assets/fact_check.svg';
import { ReactComponent as CveIcon } from '../assets/cve.svg';
import { ReactComponent as ExploitableIcon } from '../assets/exploit.svg';
import { ReactComponent as Soc2Icon } from '../assets/SOC2.svg';
import { ReactComponent as NistIcon } from '../assets/NIST.svg';
import { ReactComponent as PciIcon } from '../assets/PCI.svg';
import { ReactComponent as IsoIcon } from '../assets/ISO.svg';
import { ReactComponent as CisIcon } from '../assets/CIS.svg';
import { FaGithub } from 'react-icons/fa';
import { Nullable } from 'ox-common-types';
import useCommonGraphStore from '../stores/common-graph-store';
import useIssueGraphStore from '../stores/issue-graph-store';

export const getRelatedTab = (type: AttackPathType, aggType: string) => {
  switch (type) {
    case AttackPathType.User:
    case AttackPathType.Commit:
      return aggType;
    case AttackPathType.Compliance:
      return 'Compliance';
    case AttackPathType.JiraTicket:
      return 'JIRA';

    case AttackPathType.Cwe:
      return 'CWE';
    case AttackPathType.Cve:
      return 'Vulnerabilities';
    case AttackPathType.Oscar:
      return 'OSC&R';
  }
  return '';
};

export const handleExternalLinkClick = (
  type: AttackPathType,
  aggType: string,
) => {
  const { issueId } = useCommonGraphStore.getState();
  switch (type) {
    case AttackPathType.Pr:
    case AttackPathType.Slack:
      issueId &&
        window.open(
          `${AppPages.ActiveIssues}?issueId=${encodeURIComponent(issueId)}`,
        );

      break;

    default:
      useCommonGraphStore.setState({
        fullMode: false,
      });
      issueId &&
        window.open(
          `${AppPages.ActiveIssues}?issueId=${encodeURIComponent(
            issueId,
          )}&tab=${encodeURIComponent(getRelatedTab(type, aggType))}`,
        );
  }
};

export const resolvePriorityColor = (priority: number, theme: Theme) => {
  const colors = theme.palette.categoryRiskColor;
  if (priority > 66) {
    return colors.high;
  } else if (priority > 33) {
    return colors.medium;
  } else {
    return colors.info;
  }
};

export const getSeverityCategoryIcon = category => {
  switch (category) {
    case 'Reachable':
      return ReachableIcon;
    case 'Exploitable':
      return ExploitableIcon;
    case 'Damage':
      return DamageIcon;
  }
  return InfoIcon;
};

export const getVulnerabilityIcon = category => {
  switch (category) {
    case 'CVE':
      return CveIcon;
    case 'GHSA':
      return FaGithub;
    default:
      return CveIcon;
  }
};

export const getComplianceIcon = (compliance: StandardCategory) => {
  switch (compliance) {
    case StandardCategory.CIS:
      return CisIcon;
    case StandardCategory.NIST:
      return NistIcon;
    case StandardCategory.ISO:
      return IsoIcon;
    case StandardCategory.SOC2:
      return Soc2Icon;
    case StandardCategory.PCI:
      return PciIcon;
    default:
      return ComplianceIcon;
  }
};

export const getSeverityChangeIcon = changeNumber => {
  if (changeNumber === 0) {
    return NoChangeIcon;
  }
  return changeNumber > 0 ? IncreaseIcon : DecreaseIcon;
};

export const getCheckColor = (value: Nullable<boolean>, theme: Theme) => {
  const colors = theme.palette.categoryRiskColor;

  if (value === true) {
    return colors.high;
  }
  if (value === false) {
    return colors.passed;
  }

  return colors.info;
};

export const getNodeIdByType = (type: AttackPathType) => {
  const { originalNodes } = useIssueGraphStore.getState();
  const node = originalNodes.find(node => node.data.type === type);
  return node?.id;
};
