import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable, ServiceExecute } from 'ox-common-types';
import { ITag } from '../../../applications/applications-types';
import mutation from './add-new-tags.gql';

const addNewTags = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<AddTagInput, Nullable<MutationResponse>> => {
  return {
    execute: async (
      input: AddTagInput,
    ): Promise<Nullable<MutationResponse>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: {
            input,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to create new applications tags', error);
        return null;
      }
    },
  };
};

export interface AddTagInput {
  tagsInput: Pick<ITag, 'displayName' | 'name' | 'tagType'>[];
}

export interface MutationResponse {
  addTags: {
    tags: ITag[];
  };
}

export default addNewTags;
