import React from 'react';
import { Stack } from '@mui/material';

function StackedItemsGroup<T>({
  items,
  ItemComponent,
}: {
  items: T[];
  ItemComponent: React.ComponentType<{ item: T; inTooltip?: boolean }>;
}) {
  return (
    <Stack
      p={1}
      maxWidth={400}
      direction='column'
      flexWrap='wrap'
      alignItems='left'
      gap={1}>
      {items.map((item, index) => (
        <ItemComponent inTooltip={true} key={index} item={item} />
      ))}
    </Stack>
  );
}

export default StackedItemsGroup;
