import { AppPages } from 'app-navigator';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeyPress } from 'react-utils';
import { useSnapshot } from 'valtio';
import { useIsLoadingConnectors } from '../../connectors/store-actions/connectors-store-actions';
import { scanAll, useIsScanning } from '../actions/scan-actions';
import ScanButton from '../components/ScanButton';
import ScanStore from '../store/scan-store';
import { openExceedScanLimitModal } from '../store/scan-store-actions';
import withDisabledIfUnauthorized, {
  WithDisabledIfUnauthorizedProps,
} from '../../permissions/higher-order-components/withDisabledIfUnauthorized';
import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';

import { useOxFlags } from '../../feature-flags/actions/feature-flags-actions';

const DeltaScanButton: FC<WithDisabledIfUnauthorizedProps> = ({ disabled }) => {
  const isLoadingConnectors = useIsLoadingConnectors();
  const isScanning = useIsScanning();
  const { isExceedScanLimit, isScanAllDisabled, isDeltaScan } =
    useSnapshot(ScanStore);
  const shiftPressed = useKeyPress('Shift');
  const altOptionKeyPressed = useKeyPress('Alt');
  const { disableFullScanOnShift } = useOxFlags();
  const navigate = useNavigate();

  const handleScan = useCallback(() => {
    if (isExceedScanLimit) {
      openExceedScanLimitModal();
      return;
    }
    const isDemo = false;
    const isFullScan = disableFullScanOnShift ? false : shiftPressed;
    const isContainerFullScan = altOptionKeyPressed;
    scanAll(isDemo, isFullScan, isContainerFullScan);
    navigate(AppPages.Dashboard);
  }, [
    isExceedScanLimit,
    disableFullScanOnShift,
    shiftPressed,
    navigate,
    altOptionKeyPressed,
  ]);

  const getTitle = useCallback(() => {
    let title = 'Will scan using all configured connectors';
    if (shiftPressed) {
      title = 'Will run a FULL scan using all configured connectors';
    }
    if (altOptionKeyPressed) {
      title = 'Will run a container FULL scan using all configured connectors';
    }
    if (altOptionKeyPressed && shiftPressed) {
      title =
        'Will run a code and container FULL scan using all configured connectors';
    }
    if (isLoadingConnectors) {
      return 'Loading..';
    }
    if (isScanning) {
      title = 'Scan in progress.';
    }
    if (disabled) {
      return 'You need permissions to perform this action';
    }
    if (isScanAllDisabled) {
      return 'Ensure a valid token is available in the connectors screen.';
    }

    return title;
  }, [
    altOptionKeyPressed,
    shiftPressed,
    isLoadingConnectors,
    isScanning,
    isScanAllDisabled,
    disabled,
  ]);

  return (
    <ScanButton
      value={
        !isLoadingConnectors &&
        (isScanning ? `${isDeltaScan ? 'Δ ' : ''}Scanning...` : 'Scan Now')
      }
      tooltip={getTitle()}
      disabled={isScanAllDisabled || isLoadingConnectors || disabled}
      onClick={handleScan}
      variant={isScanning ? 'outlined' : 'contained'}
      withProgress={isLoadingConnectors}
    />
  );
};

export default withDisabledIfUnauthorized(DeltaScanButton, {
  requiredPermissions: [
    {
      permissionScope: PermissionScope.Edit,
      permissionType: PermissionTypes.Scan,
    },
  ],
});
