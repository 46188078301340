import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { LazyFiltersResponse, SearchInput } from 'ox-filter-utils';
import query from './get-api-inventories-filters-lazy.gql';
import { ApolloClientCache } from 'ox-common-types';

export interface ApiInventoryFiltersLazyResponse {
  getApiSecurityFiltersLazy: LazyFiltersResponse;
}

export interface GetApiInventoryFiltersLazyInput {
  filters: {};
  search: string;
  owners: string[];
  tagIds: string[];
  openItems: string[];
  filterSearch: SearchInput[];
}

const getApiInventoriesFiltersLazy = (
  client: ApolloClient<NormalizedCacheObject>,
  cache = true,
) => {
  return {
    execute: async (getApiSecurityInput: GetApiInventoryFiltersLazyInput) => {
      const results = await client.query<ApiInventoryFiltersLazyResponse>({
        query,
        variables: { getApiSecurityInput },
        fetchPolicy: cache
          ? ApolloClientCache.CacheFirst
          : ApolloClientCache.NetworkOnly,
      });
      if (!results.data) return null;
      return results.data.getApiSecurityFiltersLazy;
    },
  };
};

export default getApiInventoriesFiltersLazy;
