import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache, ServiceExecute } from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';
import mutation from './exclude-bulk.gql';
import { ExcludeAlertInput } from '../../types/exclusion-types';

const excludeBulk = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<Array<ExcludeAlertInput>, ExcludeBulkAlertsRes[]> => {
  return {
    execute: async (
      input: Array<ExcludeAlertInput>,
    ): Promise<ExcludeBulkAlertsRes[]> => {
      const results = await client.mutate<QueryResponse>({
        mutation,
        fetchPolicy: ApolloClientCache.NoCache,
        variables: { input },
      });
      if (results.data?.excludeBulkAlerts) {
        TelemetryManager.setTelemetryAction(
          TelemetryActions.BulkExcludeAlerts,
          input,
        );
        return results.data.excludeBulkAlerts;
      }
      throw new Error('Failed to exclude bulk alers, no data');
    },
  };
};

interface QueryResponse {
  excludeBulkAlerts: ExcludeBulkAlertsRes[];
}

interface ExcludeBulkAlertsRes {
  totalExclusions: number;
}

export default excludeBulk;
