import { FC } from 'react';
import { Typography } from '@mui/material';
import NodeTooltip from '../NodeTooltip';

const ComplianceItem: FC<React.PropsWithChildren<ComplianceItemProps>> = ({
  standard,
  category,
  control,
}) => {
  return (
    <NodeTooltip title='Compliance Item'>
      <Typography variant='caption'>
        Standard: <b>{standard}</b>
      </Typography>
      <Typography variant='caption'>
        Category: <b>{category}</b>
      </Typography>
      <Typography variant='caption'>
        Control: <b>{control}</b>
      </Typography>
    </NodeTooltip>
  );
};

export interface ComplianceItemProps {
  standard: string;
  category: string;
  control: string;
}

export { ComplianceItem };
