import { omitBy } from 'lodash-es';
import { SeverityConst, SeverityEnum } from 'severity-utils';
import {
  IssueCounters,
  RawIssueCounters,
  Technique,
} from '../types/oscar-matrix-types';
import { createLinkToIssues } from '../utils/oscar-routing-utils';

export const createIssueCounters = (
  rawIssueCounters: RawIssueCounters,
  techniqueId: string,
  techniquName: string,
) => {
  return convertRawIssueCounters(
    parseRawIssueCounters(rawIssueCounters),
    techniqueId,
    techniquName,
  );
};

const parseRawIssueCounters = (
  issueCounters: RawIssueCounters,
): Partial<RawIssueCounters> => {
  const nonEmpty = omitBy(issueCounters, n => n === 0);

  // We're assumin the severities are sorted by desc order in the .gql file
  const top3 = Object.keys(nonEmpty).slice(0, 3);
  return toObject(top3, nonEmpty);
};

const sortedSeverities = Object.keys(SeverityConst).map(str =>
  str.toLowerCase(),
);

const convertRawIssueCounters = (
  counters: Partial<RawIssueCounters>,
  techniquId: Technique['id'],
  techniquName: Technique['name'],
): Partial<IssueCounters> => {
  const sortedKeys = Object.keys(counters).sort(
    (a, b) => sortedSeverities.indexOf(a) - sortedSeverities.indexOf(b),
  );

  return (sortedKeys as SeverityEnum[]).reduce((acc, severity) => {
    acc[severity] = {};
    acc[severity].linkToIssuesPage = createLinkToIssues(
      techniquId,
      techniquName,
      severity,
    );
    acc[severity].count = counters[severity];
    return acc;
  }, {});
};

// HELPERS
const toObject = (keys: string[], data: Object) => {
  return keys.reduce((acc, curr) => {
    acc[curr] = data[curr];
    return acc;
  }, {});
};
