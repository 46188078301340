import { Box } from '@mui/material';
import { FC } from 'react';

export const AppFlowIcon: FC<React.PropsWithChildren<AppFlowIconProps>> = ({
  iconSize,
  children,
}) => (
  <Box
    sx={{
      '& svg': {
        width: iconSize,
        height: iconSize,
        marginInlineStart: '5px',
      },
    }}>
    {children}
  </Box>
);

interface AppFlowIconProps {
  iconSize: number;
}
