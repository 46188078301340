import { User } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { USER_LOGINS_COUNT } from 'env-utils';
import { logger } from 'logging-utils';
import organizationService from '../services';

export const registerUserConsent = async (user: User) => {
  if (user[USER_LOGINS_COUNT] <= 2) {
    datadogLogs.logger.info(
      `Registration: Audit consent for ${user.name} with email ${user.email}`,
    );
    try {
      const res = await organizationService.addUserConsent.execute();
      if (!res) {
        throw new Error('User consent registration failed');
      }
    } catch (err) {
      logger.error(err);
      datadogLogs.logger.error(
        `Registration: Failed to persist consent for ${user.name} with email ${user.email}`,
      );
    }
  }
};
