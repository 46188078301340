import {
  CategoryDisplayName,
  getCategoryByName,
  OxCategoriesNames,
} from '@oxappsec/ox-consolidated-categories';
import { logger } from 'logging-utils';
import {
  Connector,
  ConnectorsByFamilyObject,
} from '../../connectors/connectors-types';
import { resolveBadgeStatus } from '../../connectors/utils/connectors-utils';
import { SupplyChainResponseItem } from '../types/supply-chain-backend-types';
import {
  SupplyChainConnector,
  SupplyChainItem,
} from '../types/supplychain-types';

export const createSupplyChainConnectors = (
  item: SupplyChainResponseItem,
  connectorsByFamily: ConnectorsByFamilyObject[],
): SupplyChainItem['connectors'] => {
  const categoryName = item.header.label as CategoryDisplayName;
  const categoryConfig = getCategoryByName(categoryName);
  if (!categoryConfig) {
    logger.error(`Category with name ${categoryName} was not found`);
    return;
  }

  const connectors = connectorsByFamily.find(
    f => f.family === categoryConfig.key,
  )?.connectors;

  const supplyChainConnectors = connectors?.map(createSupplyChainConnector);
  const customIconCategories: CategoryDisplayName[] = [
    OxCategoriesNames.DevProccess,
    OxCategoriesNames.SecurityToolsCoverage,
    OxCategoriesNames.ArtifactsIntegrity,
    OxCategoriesNames.CICDSecurity,
    OxCategoriesNames.APISecurity,
  ];
  const showOnlyCustomIcon = customIconCategories.includes(categoryName);
  const addOxConnectorIconToList = !!connectors?.some(connector => {
    return connector.isOxBuiltIn && connector.isConfigured;
  });
  return {
    items: supplyChainConnectors,
    showOnlyCustomIcon,
    addOxConnectorIconToList,
    oxConnectorTooltipTitle: `${
      categoryConfig.customOxIconTooltip || categoryName
    }`,
  };
};

const createSupplyChainConnector = (
  connector: Connector,
): SupplyChainConnector => {
  let { name, badgeIcon, iconURL, isOxBuiltIn, connectorStatus } = connector;
  const connectorToUse = connector.connectorToUse || connector;

  return {
    displayName: `${connector.displayName}${
      connector.comingSoon ? ' - Coming Soon!' : ''
    }`,
    connectorStatus,
    name: name,
    iconURL: iconURL,
    status: resolveBadgeStatus(connectorToUse),
    isBuiltIn: isOxBuiltIn,
    badgeIcon:
      badgeIcon ||
      (connector.aliasFor ? connector.iconURL : connectorToUse.iconURL),
  };
};

export const createConnectorsPanelData = (
  connectorsByFamily: ConnectorsByFamilyObject[],
) => {
  let discoveredConnectorsAmount = 0;
  const connectorsPanelData = connectorsByFamily.reduce(
    (acc: ConnectorsByFamilyObject[], connectorFamilyObj) => {
      const filteredConnectorsArr = connectorFamilyObj.connectors.filter(
        connector => {
          if (connector.isOxBuiltIn) {
            return false;
          }
          if (connector.comingSoon && !connector.isDiscovered) {
            return false;
          }
          if (!connector.isDiscovered && !connector.isConfigured) {
            return false;
          }
          if (
            !connector.isDiscovered &&
            connector.isOxBuiltIn &&
            !connector.isConfigured
          ) {
            return false;
          }
          return true;
        },
      );
      if (filteredConnectorsArr.length === 0) {
        return acc;
      }
      filteredConnectorsArr.sort((a, b) => {
        if (a.isOxBuiltIn && !b.isOxBuiltIn && b.isConfigured) {
          return 1;
        }
        if (b.isOxBuiltIn && !a.isOxBuiltIn && a.isConfigured) {
          return -1;
        }
        if (a.isConfigured && !b.isConfigured) {
          return -1;
        }
        if (b.isConfigured && !a.isConfigured) {
          return 1;
        }
        return 0;
      });
      const clonedObj = {
        ...connectorFamilyObj,
        connectors: filteredConnectorsArr,
      };
      const discoveredSystems = filteredConnectorsArr.filter(
        connector => !connector.isOxBuiltIn,
      );
      discoveredConnectorsAmount += discoveredSystems.length;
      acc.push(clonedObj);
      return acc;
    },
    [],
  );
  return { connectorsPanelData, discoveredConnectorsAmount };
};
