import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ServiceExecute } from 'ox-common-types';
import { Role, UserRolesInput } from '../../types/members-types';
import mutation from './add-user-roles.gql';

const addUserRoles = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<UserRolesInput, Role[]> => {
  return {
    execute: async (addUserRolesInput: UserRolesInput): Promise<Role[]> => {
      try {
        const results = await client.mutate<{
          addUserRoles: Role[];
        }>({
          mutation,
          variables: { addUserRolesInput },
        });

        if (!results || !results.data) {
          return [];
        }

        return results.data.addUserRoles;
      } catch (error) {
        logger.log('error', error);
        return [];
      }
    },
  };
};

export default addUserRoles;
