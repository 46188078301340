import { Box, SliderThumb, useTheme } from '@mui/material';
import { FC } from 'react';

const OxSliderThumb: FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...other
}) => {
  const theme = useTheme();
  return (
    <SliderThumb {...other} data-testid='slider-thumb'>
      {children}
      <Box
        sx={{
          background: theme.palette.secondary.main,
          width: 6,
          height: 6,
          borderRadius: '50%',
        }}
      />
    </SliderThumb>
  );
};

export default OxSliderThumb;
