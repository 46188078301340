import { Box, ClassNameMap, Tooltip, Typography } from '@mui/material';
import { CustomLink, MultiLineEllipsis } from 'ox-react-components';
import { Column } from 'react-data-grid';
import { ComplianceItem } from '../../../types/issues-types';

const CellWithTooltip = ({
  value,
  link,
  tooltip,
}: {
  value: string;
  link?: string;
  tooltip?: string;
}) => (
  <Tooltip
    arrow
    title={
      value ? (
        <Typography variant='body1' color='text.primary'>
          {tooltip || value}
        </Typography>
      ) : (
        ''
      )
    }>
    <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
      <MultiLineEllipsis variant='body2' lines={3}>
        {link ? <CustomLink href={link} value={value} /> : value}
      </MultiLineEllipsis>
    </Box>
  </Tooltip>
);

export const useTableConfig = ({
  text,
}: ClassNameMap<'text'>): Column<ComplianceItem>[] => {
  return [
    {
      name: 'Standard',
      key: 'standard',
      minWidth: 100,
      maxWidth: 150,
      formatter: ({ row }) => (
        <CellWithTooltip value={row.standard} link={row.standardLink} />
      ),
    },
    {
      name: 'Category',
      key: 'category',
      width: 150,
      minWidth: 150,
      formatter: ({ row }) => (
        <CellWithTooltip
          value={row.category || 'Not Available'}
          link={row.categoryLink}
        />
      ),
    },
    {
      name: 'Control',
      key: 'control',
      width: 90,
      formatter: ({ row }) => (
        <CellWithTooltip
          value={row.control || 'Not Available'}
          link={row.controlLink}
        />
      ),
    },

    {
      name: 'Description',
      key: 'description',

      formatter: ({ row }) => <CellWithTooltip value={row.description} />,
    },
  ];
};
