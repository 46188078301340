import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  MonitorConnectorResourcesInput,
  MonitorConnectorResourcesResponse,
} from '../../connectors-types';
import mutation from './monitor-connector-resources.gql';

const monitorConnectorResources = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      monitorConnectorResourceInput: MonitorConnectorResourcesInput,
    ) => {
      try {
        const results = await client.mutate<MonitorConnectorResourcesResponse>({
          mutation,
          variables: { monitorConnectorResourceInput },
        });

        return results.data;
      } catch (error) {
        logger.error(error);
        return null;
      }
    },
  };
};

export default monitorConnectorResources;
