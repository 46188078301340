import { Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const IssueSummarySection: FC<
  React.PropsWithChildren<IssueSummarySectionProps>
> = ({ children, title, width }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.section} style={{ width }}>
      <Typography
        variant='body2'
        color='text.secondary'
        className={classes.spaceDown}>
        {title}
      </Typography>
      {children}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  spaceDown: {
    marginBlockEnd: theme.spacing(1),
  },
}));

export interface IssueSummarySectionProps {
  title: string;
  width?: number;
}

export default IssueSummarySection;
