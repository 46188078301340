import { getCurrentTheme } from 'app-theme';
import { AttackPathType } from '../attack-path-types';
import { getCheckColor } from '../utils/utils';

export const edgeColorsConfig = {
  [AttackPathType.Root]: (metadata, theme, metadataFrom) => {
    const color =
      theme.palette.categoryRiskColor[
        metadata.originalSeverity?.toLocaleLowerCase()
      ];
    return color;
  },
  [AttackPathType.SeverityFactorChange]: metadata => {
    const change = metadata.changeNumber;
    return change < 0 ? '#33C600' : '#FA1414';
  },
  [AttackPathType.App]: () => '#5059FF',
  [AttackPathType.Saas]: (metadata, theme, metadataFrom) => '#2684FF',
  [AttackPathType.IssueOwner]: () => '#FA1414',
  [AttackPathType.SeverityFactorCategory]: () => '#FA1414',
  [AttackPathType.Commit]: () => '#FA1414',
  [AttackPathType.CommitItem]: () => '#FA1414',
  [AttackPathType.Category]: () => '#FA1414',
  [AttackPathType.Language]: () => '#FA1414',
  [AttackPathType.User]: () => '#FA1414',
  [AttackPathType.UserItem]: () => '#FA1414',
  [AttackPathType.BusinessPriority]: () => '#FA1414',
  [AttackPathType.SeverityFactor]: () => 'rgb(179, 155, 255)',
  [AttackPathType.OscarItem]: (metadata, theme, metadataFrom) => {
    const severity = metadata.severity?.toLowerCase();
    let color = theme.palette.categoryRiskColor[severity];
    return color || '#90C0FF';
  },
  [AttackPathType.Oscar]: (metadata, theme, metadataFrom) => {
    const severity = metadata.severity?.toLowerCase();
    let color = theme.palette.categoryRiskColor[severity];
    return color || '#90C0FF';
  },
  [AttackPathType.Compliance]: () => '#90C0FF',
  [AttackPathType.ComplianceItem]: () => '#90C0FF',
  [AttackPathType.Cwe]: (metadata, theme, metadataFrom) => {
    const severity = metadata.severity?.toLowerCase();
    let color = theme.palette.categoryRiskColor[severity];
    return color || '#90C0FF';
  },
  [AttackPathType.CweItem]: (metadata, theme, metadataFrom) => {
    const severity = metadata.severity?.toLowerCase();
    let color = theme.palette.categoryRiskColor[severity];
    return color;
  },
  [AttackPathType.Cve]: (metadata, theme, metadataFrom) => {
    const severity = metadata.originalSeverity?.toLowerCase();
    let color = theme.palette.categoryRiskColor[severity];
    return color || '#90C0FF';
  },
  [AttackPathType.CveItem]: (metadata, theme, metadataFrom) => {
    const severity = metadata.originalSeverity?.toLowerCase();
    let color = theme.palette.categoryRiskColor[severity];
    return color;
  },
  [AttackPathType.Library]: metadata =>
    metadata.libraryIssue ? 'red' : '#45D700',
  [AttackPathType.Update]: (metadata, theme, metadataFrom) =>
    getCheckColor(metadata.notUpdated, theme),
  [AttackPathType.License]: (metadata, theme, metadataFrom) =>
    getCheckColor(metadata.licenseIssue, theme),
  [AttackPathType.Popularity]: (metadata, theme, metadataFrom) =>
    getCheckColor(metadata.notPopular, theme),
  [AttackPathType.Maintenance]: (metadata, theme, metadataFrom) =>
    getCheckColor(metadata.notMaintained, theme),
  [AttackPathType.Action]: () => '#F19100',
  [AttackPathType.JiraTicket]: () => '#ACCCFF',
  [AttackPathType.JiraTicketItem]: () => '#ACCCFF',
  [AttackPathType.Slack]: () => '#ACCCFF',
  [AttackPathType.SlackItem]: () => '#ACCCFF',
  [AttackPathType.CommitItemCommitBy]: () => '#5F5F7D',
  [AttackPathType.CommitItemFile]: () => '#00BAC6',
  [AttackPathType.FirstSeen]: () => '#903EE3',
  [AttackPathType.CommitItemCommitDate]: () => '#903EE3',
  [AttackPathType.AppLink]: () => '#5059FA',

  [AttackPathType.Detection]: () => '#5059FF',
  [AttackPathType.Intelligence]: () => 'rgb(250, 20, 20)',
  [AttackPathType.Api]: metadata =>
    metadata.exposeIssue ? '#f58535' : '#c3c3c8',
  [AttackPathType.K8s]: (metadata, theme, metadataFrom) =>
    metadataFrom.exposeIssue === false ? '#c3c3c8' : '#326CE5',
  [AttackPathType.K8sGroup]: (metadata, theme, metadataFrom) =>
    metadataFrom.exposeIssue === false ? '#c3c3c8' : '#326CE5',
  [AttackPathType.Cloud]: metadata => '#f58535',
  [AttackPathType.CloudDns]: metadata => '#f58535',
  [AttackPathType.CloudRegion]: metadata => '#f58535',
  [AttackPathType.CloudLink]: metadata => '#f58535',
  [AttackPathType.Internet]: metadata => '#326CE5',
  [AttackPathType.ApiFileName]: () => '#FFC051',
  [AttackPathType.ApiFramework]: () => '#FFC051',
  [AttackPathType.ApiFunction]: () => '#FFC051',
  [AttackPathType.K8sLink]: () => '#6837FF',
  [AttackPathType.K8sImageId]: () => '#326CE5',

  [AttackPathType.K8sIngress]: () => '#326CE5',
  [AttackPathType.K8sApplication]: () => '#326CE5',
  [AttackPathType.K8sLoadBalancer]: () => '#326CE5',
  [AttackPathType.Image]: () => '#326CE5',
  [AttackPathType.ImageId]: () => '#326CE5',
  [AttackPathType.ImageArtifact]: () => '#326CE5',
};

export const edgeExpand = {
  [AttackPathType.Expand]: (metadata, theme, metadataFrom) => {
    return edgeColorsConfig[metadata.targetType]
      ? edgeColorsConfig[metadata.targetType](metadata, theme, metadataFrom)
      : '#90C0FF';
  },
};

export const getEdgeColor = (type, metadataTo, metadataFrom = {}) => {
  const colorsObject = { ...edgeColorsConfig, ...edgeExpand };
  const getColorFunction = colorsObject[type];
  const theme = getCurrentTheme();
  return getColorFunction
    ? getColorFunction(metadataTo, theme, metadataFrom)
    : undefined;
};
