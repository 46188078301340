import {
  ConditionalFilter,
  FilterDetails,
  FilterTypeToFilter,
} from 'ox-filter-utils';
import { CSSProperties, ChangeEvent, FC } from 'react';
import { FilterItem } from '../FilterItem/FilterItem';
import { FilterItemStatistics } from '../FilterItem/FilterItemStatistics';

export const VirtualFilterItem: FC<
  React.PropsWithChildren<VirtualFilterItemProps>
> = ({
  index,
  style,
  data,
  checked,
  excludeFilterChecked,
  onFilterChange,
  filterTypeToFilterBy,
  itemHeight,
  disabled,
  isStatistic,
  additionalFilters,
  loadingFilter,
  isShowExcludeFilterCheckbox,
}) => {
  const filter = data[index];
  if (isStatistic) {
    return (
      <div style={style}>
        <FilterItemStatistics
          key={filter.name + index}
          name={filter.name}
          percent={filter.percent}
          color={filter.color}
          count={filter.count}
          categoryIcon={filter.categoryIcon}
          filterTypeToFilterBy={filterTypeToFilterBy}
          splitLabel={filter.splitLabel}
          itemHeight={itemHeight}
          id={filter.id}
          isStatistic={isStatistic}
          changeNumber={filter.changeNumber}
          additionalFilters={additionalFilters}
        />
      </div>
    );
  } else {
    return (
      <div style={style}>
        <FilterItem
          splitLabel={filter.splitLabel}
          key={filter.name + index}
          name={filter.name}
          percent={filter.percent}
          color={filter.color}
          count={filter.count}
          categoryIcon={filter.categoryIcon}
          checked={checked}
          onFilterChange={onFilterChange}
          filterTypeToFilterBy={filterTypeToFilterBy}
          itemHeight={itemHeight}
          disabled={disabled}
          id={filter.id}
          isStatistic={isStatistic}
          changeNumber={filter.changeNumber}
          loadingFilters={!!loadingFilter}
          excludeFilterChecked={excludeFilterChecked}
          isShowExcludeFilterCheckbox={isShowExcludeFilterCheckbox}
        />
      </div>
    );
  }
};

interface VirtualFilterItemProps {
  index: number;
  checked: boolean;
  style: CSSProperties;
  data: FilterDetails[];
  filterTypeToFilterBy?: FilterTypeToFilter;
  onFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  itemHeight: number;
  disabled?: boolean;
  isStatistic?: boolean;
  additionalFilters?: {};
  loadingFilter?: boolean;
  conditionalFilters?: ConditionalFilter[];
  excludeFilterChecked: boolean;
  isShowExcludeFilterCheckbox: boolean;
}
