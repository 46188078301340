import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { important } from 'ox-common-types';
import { OxDataGrid } from 'ox-react-components';
import { OxSearchBox } from 'ox-react-components/src/OxSearcBox/OxSearchBox';
import { FC, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ScaVulnerability } from '../../../../apps/web/src/issues/common/types/issues-types';
import { useVulnerabilityTable } from './useVulnerabilityTable';
import ContextAutocomplete from './ContextAutocomplete';
import { CveSeverity } from './icon-utils';
import { filterVulnerabilities } from './utils';

const VulnerabilitiesTable: FC<
  React.PropsWithChildren<VulnerabilitiesTableProps>
> = props => {
  const { classes } = useStyles();

  const scaColumns = useVulnerabilityTable(classes);

  const [searchValue, setSearchValue] = useState('');
  const [contextValues, setContextValues] = useState<string[]>([]);

  const {
    rows,
    onSort,
    onRowClick,
    onScroll,
    loading,
    onSearch,
    contextOptions,
    orderField,
    orderDirection,
  } = props;

  const cveOptions = useMemo(
    () =>
      contextOptions
        ? contextOptions.reduce((acc, item) => {
            acc[item.sfName as CveSeverity] = item.count;
            return acc;
          }, {} as Record<CveSeverity, number>)
        : rows.reduce((acc, row) => {
            row.cveSeverityFactor?.forEach(
              factor => (acc[factor.sfName] = (acc[factor.sfName] || 0) + 1),
            );
            return acc;
          }, {} as Record<CveSeverity, number>),
    [contextOptions, rows],
  );

  const sortColumns = useMemo(
    () =>
      orderField && orderDirection
        ? [
            {
              columnKey: orderField,
              direction: orderDirection,
            },
          ]
        : undefined,
    [orderDirection, orderField],
  );

  if (!rows) return null;

  const filteredRows = onSearch
    ? rows
    : filterVulnerabilities(rows, searchValue, contextValues);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <ContextAutocomplete
          options={cveOptions}
          value={contextValues}
          onChange={(_, newValue) => {
            onSearch && onSearch(searchValue, newValue);
            setContextValues(newValue);
          }}
        />

        <div className={classes.searchContainer}>
          <OxSearchBox
            value={searchValue}
            onChange={e => {
              onSearch && onSearch(e.target.value, contextValues);
              setSearchValue(e.target.value);
            }}
            placeholder='Search by Vulnerability ID'
          />
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'hidden',
        }}>
        {filteredRows.length > 0 ? (
          <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'auto' }}>
            <OxDataGrid
              sortColumns={sortColumns}
              onSort={onSort}
              loading={loading}
              onScroll={onScroll}
              onRowClick={onRowClick}
              key={'Reachable Vulnerabilities'}
              className={classes.table}
              columns={scaColumns}
              rows={filteredRows}
              rowHeight={70}
              headerRowHeight={35}
            />
          </Box>
        ) : (
          <Typography mt={2}>No matching items found</Typography>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  table: {
    height: '100%',
    '--rdg-header-background-color': important(
      theme.palette.background.default,
    ),
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    display: '-webkit-box',
    whiteSpace: 'pre-wrap',
    lineClamp: 2,
    WebkitLineClamp: 2,
    lineHeight: 1,
    WebkitBoxOrient: 'vertical',
  },
  searchContainer: {
    width: 300,
    marginBottom: theme.spacing(1),
  },
}));

export interface VulnerabilitiesTableProps {
  rows: ScaVulnerability[];
  onRowClick?: (row: ScaVulnerability) => void;
  onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  loading?: boolean;
  onSearch?: (search: string, contextOptions: string[]) => void;
  contextOptions?: { sfName: string; count: number }[];
  onSort?: (field?: string) => void;
  orderField?: string;
  orderDirection?: 'ASC' | 'DESC';
}

export default VulnerabilitiesTable;
