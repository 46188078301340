import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  JiraUser,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import query from './get-assignees.gql';

const getAssignees = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  { searchString: string; projectKey?: string },
  Nullable<GetAssigneesResponse>
> => {
  return {
    execute: async ({
      searchString,
      projectKey,
    }): Promise<Nullable<GetAssigneesResponse>> => {
      try {
        const results = await client.query<GetAssigneesResponse>({
          query,
          fetchPolicy: ApolloClientCache.NetworkOnly,
          variables: {
            query: searchString,
            projectKey,
            provider: TicketProvider.Jira,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to fetch assignees', error);
        return null;
      }
    },
  };
};

export interface GetAssigneesResponse {
  searchAssignee: JiraUser[];
}

export default getAssignees;
