import { logger } from 'logging-utils';
import { Nullable, OneOfValues } from 'ox-common-types';

export const createAsyncState = (
  initialStatus?: OneOfValues<typeof LoadingStatus>,
): AsyncLoadingState => {
  return {
    status: initialStatus || LoadingStatus.Idle,
    err: null,
    isPending() {
      return this.status === LoadingStatus.Pending;
    },
    isError() {
      return this.status === LoadingStatus.Error;
    },
    isIdle() {
      return this.status === LoadingStatus.Idle;
    },
    isSuccess() {
      return this.status === LoadingStatus.Success;
    },
  };
};

export const LoadingStatus = {
  Idle: 'idle',
  Pending: 'pending',
  Success: 'success',
  Error: 'error',
} as const;

export type AsyncLoadingState = {
  status: OneOfValues<typeof LoadingStatus>;
  err: Nullable<Error>;
  isPending(): boolean;
  isError(): boolean;
  isIdle(): boolean;
  isSuccess(): boolean;
};

export const createSimpleAsyncAction =
  <T extends Array<unknown>, U>(
    asyncFunction: (...args: T) => U,
    options: {
      asyncState: AsyncLoadingState;
      errorMessage?: string;
    },
  ) =>
  async (...args: T) => {
    options.asyncState.status = LoadingStatus.Pending;
    try {
      await asyncFunction(...args);
      options.asyncState.status = LoadingStatus.Success;
    } catch (err) {
      logger.error(options.errorMessage || 'Unhandled error has occured', err);
      options.asyncState.err = err as Error;
      options.asyncState.status = LoadingStatus.Error;
    }
  };
