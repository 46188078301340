import { Phase, ScanProgressItem } from './scan-types';

export const resolveScanPhaseText = ({
  total,
  count,
  phase,
}: ScanProgressItem) => {
  const percentage = total ? (count / total) * 100 : 0;
  switch (phase) {
    case Phase.Artifact:
      return {
        text: `Artifact Scan: ${count} / ${total}`,
        phase,
        percentage,
      };
    case Phase.Repo:
      return {
        text: `Repo Scan: ${count} / ${total}`,
        percentage,
        phase,
      };
    case Phase.Cloud:
      return {
        text: `Cloud Scan: ${percentage.toFixed(0)}%`,
        percentage,
        phase,
      };
    case Phase.ExternalTools:
      return {
        text: `External Issues Import: ${percentage.toFixed(0)}%`,
        percentage,
        phase,
      };
  }
  return { text: '', percentage: 0, phase };
};
