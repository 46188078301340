import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { AppOwnersByRole } from '../../application-owners-types';
import mutation from './set-applications-owners-by-role.gql';

const setApplicationsOwnersByRole = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (appOwnerInput: AppOwnerInput) => {
      try {
        const results =
          await client.mutate<SetApplicationsOwnersByRoleResponse>({
            mutation,
            variables: { appOwnerInput: { ownersByRoleInput: appOwnerInput } },
          });

        return results.data?.setApplicationsOwnersByRole;
      } catch (error) {
        logger.error('Failed to set applications owners', error);
        return null;
      }
    },
  };
};

interface SetApplicationsOwnersByRoleResponse {
  setApplicationsOwnersByRole: {
    isUpdated: boolean;
  };
}

type AppOwnerInput = AppOwnersByRole[];

export default setApplicationsOwnersByRole;
