import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ServiceExecute } from 'ox-common-types';
import { ScanAllInput, ScanOutput } from '../../scan-types';
import mutation from './scan-all.gql';

const scanAll = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<ScanAllInput, ScanOutput> => {
  return {
    execute: async ({
      isDemo,
      isFullScan,
      isContainerFullScan,
    }): Promise<ScanOutput> => {
      if (!window.navigator.onLine) {
        throw new Error('Failed to run a scan - no network connection');
      }
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: { isDemo, isFullScan, isContainerFullScan },
        });

        if (!results.data) {
          throw new Error('Failed to run a scan, no data received');
        }

        return results.data.scanAll;
      } catch (error) {
        logger.error(
          `An error occurred trying to initiate a scan: ${error}`,
          error,
        );
        throw new Error(
          'Encountered an error while trying to initiate a scan, please try again later',
        );
      }
    },
  };
};

interface MutationResponse {
  scanAll: ScanOutput;
}

export default scanAll;
