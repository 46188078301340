import { AttackPathType } from '../attack-path-types';
import useAttackPathGraphStore from '../stores/attack-path-store';
import useIssueGraphStore from '../stores/issue-graph-store';

const parentNodePositionConfig = {
  [AttackPathType.FirstSeen]: () => ({ x: -550, y: 120 }),
  [AttackPathType.Root]: () => ({ x: 0, y: 0 }),
  [AttackPathType.App]: () => {
    const { originalNodes: nodes } = useIssueGraphStore.getState();
    const { originalNodes: attackPathNodes } =
      useAttackPathGraphStore.getState();

    if (
      nodes.find(n => n.data.type === AttackPathType.Library) &&
      attackPathNodes.length
    ) {
      return { x: 720, y: 12 };
    }
    return { x: 320, y: 12 };
  },
  [AttackPathType.Saas]: node => {
    const { order, siblingsCount } = node.data.metadata;
    const { offset } = useAttackPathGraphStore.getState();
    if (siblingsCount > 5) {
      let column = Math.floor(order / 5);
      let positionInColumn = order % 5;
      let x = column * 100;
      let y = (positionInColumn - 2) * 150 + (column % 2 === 1 ? 75 : 0);
      return {
        x: x + offset,
        y: 22 + y,
      };
    } else {
      return {
        y: 22 + (order - (siblingsCount - 1) / 2) * 85,
        x: offset,
      };
    }
  },
  [AttackPathType.IssueOwner]: () => ({ x: -550, y: -120 }),
  [AttackPathType.Intelligence]: () => ({ x: 5, y: 320 }),
  [AttackPathType.Oscar]: () => ({ x: -100, y: 268 + 300 }),
  [AttackPathType.OscarItem]: node =>
    node.data.metadata.single ? { x: -100, y: 268 + 300 } : null,
  [AttackPathType.Cve]: () => ({ x: 400, y: 268 + 300 }),
  [AttackPathType.CveItem]: node =>
    node.data.metadata.single ? { x: 400, y: 268 + 300 } : null,
  [AttackPathType.Cwe]: () => ({ x: 9, y: 950 }),
  [AttackPathType.CweItem]: node =>
    node.data.metadata.single ? { x: 9, y: 950 } : null,
  [AttackPathType.Compliance]: () => ({ x: 150, y: 268 + 300 }),
  [AttackPathType.ComplianceItem]: node =>
    node.data.metadata.single ? { x: 150, y: 268 + 300 } : null,
  [AttackPathType.Library]: () => ({ x: 700, y: 268 + 300 }),
  [AttackPathType.SeverityFactor]: () => ({ x: 5, y: -284 }),
  [AttackPathType.Detection]: () => ({ x: -320, y: 5 }),
  [AttackPathType.Action]: () => ({ x: -450, y: 500 }),
  [AttackPathType.User]: () => ({ x: -550, y: 9 }),
  [AttackPathType.Repos]: () => ({ x: -550, y: 9 }),
  [AttackPathType.SBOMRepos]: () => ({ x: -550, y: 9 }),
  [AttackPathType.Commit]: () => ({ x: -550, y: 9 }),
  [AttackPathType.Webhook]: () => ({ x: -550, y: 9 }),
  [AttackPathType.Service]: () => ({ x: -550, y: 9 }),
  [AttackPathType.Artifact]: () => ({ x: -550, y: 9 }),
  [AttackPathType.ArtifactIntegrity]: () => ({ x: -550, y: 9 }),
  [AttackPathType.CommitItem]: node =>
    node.data.metadata.single ? { x: -550, y: 9 } : null,
  [AttackPathType.ReposItem]: node =>
    node.data.metadata.single ? { x: -550, y: 9 } : null,
  [AttackPathType.SBOMReposItem]: node =>
    node.data.metadata.single ? { x: -550, y: 9 } : null,
  [AttackPathType.UserItem]: node =>
    node.data.metadata.single ? { x: -550, y: 9 } : null,
  [AttackPathType.ArtifactItem]: node =>
    node.data.metadata.single ? { x: -550, y: 9 } : null,
  [AttackPathType.WebhookItem]: node =>
    node.data.metadata.single ? { x: -550, y: 9 } : null,
  [AttackPathType.ServiceItem]: node =>
    node.data.metadata.single ? { x: -550, y: 9 } : null,
  [AttackPathType.ArtifactIntegrityItem]: node =>
    node.data.metadata.single ? { x: -550, y: 9 } : null,
};

export const getParentNodePosition = (type, node) => {
  const positionFunction = parentNodePositionConfig[type];
  return positionFunction ? positionFunction(node) : null;
};
