import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import {
  IUpdateProfileRes,
  IUpdateProfileResponsePayload,
  UpdateProfileInput,
} from '../../types/policy-profile-types';
import mutation from './update-profile-service.gql';

const updateProfileById = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<UpdateProfileInput, IUpdateProfileResponsePayload> => {
  return {
    execute: async (
      updateProfileInput: UpdateProfileInput,
    ): Promise<IUpdateProfileResponsePayload> => {
      await client.cache.reset();
      const results = await client.mutate<IUpdateProfileRes>({
        mutation,
        variables: {
          updateProfileInput,
        },
      });

      if (results?.data?.updateProfile) {
        return results.data.updateProfile;
      }

      throw new Error('Update profile service call failed');
    },
  };
};

export default updateProfileById;
