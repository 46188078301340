import { TableCell, TableRow, Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const SbomTabRow: FC<React.PropsWithChildren<SbomTabRowProps>> = props => {
  const { classes } = useStyles();
  const { title, children, withBorder } = props;
  return (
    <TableRow
      component='div'
      sx={{
        width: '100%',
        minHeight: 63,
      }}>
      {title && title}
      <TableCell
        component='div'
        align='right'
        className={classes.content}
        sx={{ ...(!withBorder && { border: 'none' }) }}>
        {children}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    minHeight: 63,
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2),
  },
}));

export interface SbomTabRowProps {
  title?: JSX.Element;
  withBorder?: boolean;
}

export default SbomTabRow;
