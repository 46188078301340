import { setParamsToUrl } from 'app-navigator';
import { FC, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import PathNameChangeListener from './PathNameChangeListener';
import persistParamsStore from './store/persist-params-store';

const PersistParamsOnPathNameChange: FC = () => {
  const { persistParams } = useSnapshot(persistParamsStore, { sync: true });

  const handlePathNameChange = useCallback(() => {
    if (persistParams) {
      setParamsToUrl(persistParams);
    }
  }, [persistParams]);

  return <PathNameChangeListener onPathNameChange={handlePathNameChange} />;
};

export default PersistParamsOnPathNameChange;
