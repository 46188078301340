import { Nullable } from 'ox-common-types';

import {
  CanUserRequestAccessResponse,
  RequestedAccessInfo,
} from '../request-access-types';
import RequestAccessStore from '../stores/request-access-store';
import { snapshot } from 'valtio';

export const setIsLoadingRequestAccess = (isLoading: boolean) => {
  RequestAccessStore.isLoadingRequestAccess = isLoading;
};

export const setRequestAccessPurpose = (purpose: string) => {
  RequestAccessStore.requestAccessPurpose = purpose;
};

export const setCanUserRequestAccessResponse = (
  canUserRequestAccessResponse: Nullable<CanUserRequestAccessResponse>,
) => {
  RequestAccessStore.canUserRequestAccessInfo = canUserRequestAccessResponse;
};

export const updateRequestAccessInfo = (
  requestedAccessInfos: RequestedAccessInfo[],
) => {
  const { canUserRequestAccessInfo } = snapshot(RequestAccessStore);

  if (!canUserRequestAccessInfo) {
    return;
  }
  const newCanUserRequestAccessInfo = { ...canUserRequestAccessInfo };

  newCanUserRequestAccessInfo.requestedAccessInfos = requestedAccessInfos;
  newCanUserRequestAccessInfo.hasRequestedAccess = true;

  RequestAccessStore.canUserRequestAccessInfo = {
    ...newCanUserRequestAccessInfo,
  };
};
