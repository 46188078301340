import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import query from './get-api-inventory-issues-statistics.gql';
import { FilterIssueType } from 'ox-filter-utils';

const fetchIssuesStatistics = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getIssuesInput: { filters: { apiItem: string[] } },
      cache = true,
      isDemo: boolean = false,
    ) => {
      try {
        const results = await client.query<{
          getIssuesFilters: FilterIssueType;
        }>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NoCache,
          variables: { getIssuesInput: getIssuesInput, isDemo: isDemo },
        });

        if (!results.data) return null;

        return results.data.getIssuesFilters;
      } catch (e) {
        logger.error('Failed to fetch issues filters', e);
        return null;
      }
    },
  };
};

export default fetchIssuesStatistics;
