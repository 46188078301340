import { createSimpleAsyncAction } from 'async-utils';
import whatsNewService from '../services';
import { WhatsNewPageStore } from '../stores/whats-new-page-store';
import { groupItems } from '../utils/whats-new-group-utils';
import { createWhatsNewItem } from '../utils/whats-new-utils';

export const loadWhatsNewHistory = createSimpleAsyncAction(
  async () => {
    const rawResults = await whatsNewService.getWhatsNewHistory.execute();
    if (rawResults) {
      const results = rawResults.map(createWhatsNewItem);
      const groups = groupItems(results);
      WhatsNewPageStore.groups = groups;
    }
  },
  {
    asyncState: WhatsNewPageStore.loading,
    errorMessage: 'Failed to fetch whats-new page items',
  },
);
