import { onError } from '@apollo/client/link/error/index.js';
import { logger } from 'logging-utils';

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      logger.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) {
    logger.error(`[Network error]: ${networkError}`);
  }
});
