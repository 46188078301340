import { Theme } from '@mui/material';
import { FC } from 'react';
import { BsMouse } from 'react-icons/bs';
import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

export const ScrollDownIndicator: FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ className }) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.scrollDownIndicator, className)}>
      <BsMouse className={classes.mouse} />
      <svg className={classes.arrows} viewBox='0 0 60 72'>
        <path className='a1' d='M0 0 L30 32 L60 0'></path>
        <path className='a2' d='M0 20 L30 52 L60 20'></path>
        <path className='a3' d='M0 40 L30 72 L60 40'></path>
      </svg>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  scrollDownIndicator: {
    borderRadius: '50%',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1, 2.5),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  mouse: {
    fontSize: '1.3em',
  },
  arrows: {
    flexShrink: 0,
    width: '0.9em',
    '& path': {
      stroke: theme.palette.primary.main,
      fill: 'transparent',
      strokeWidth: 2,
      animation: `${keyframes`
            0% {
                opacity: 0;
            }
            40% {
                opacity: 1;
            }
            80% {
                opacity: 0;
            }
            100% {
              opacity: 0;
            }
            `} 2s infinite`,
    },
    '& path.a1': {
      animationDelay: '-1s',
    },
    '& path.a2': {
      animationDelay: '-0.5s',
    },
    '& path.a3 ': {
      animationDelay: '0s',
    },
  },
}));
