import { ScopeNames } from '@oxappsec/ox-unified-permissions/src/scopes';
import { snapshot } from 'valtio';
import { Nullable } from '../../../../../utilities/ox-common-types/src/typescript-types';
import { extractScopeItems } from '../actions/update-member-actions';
import InviteMemberStore from '../stores/invite-user-store';
import MembersStore from '../stores/members-store';
import {
  ActiveMember,
  PendingInvitationMember,
  Role,
} from '../types/members-types';
import {
  SCOPE_SELECTION_OPTIONS,
  ScopeOptions,
} from '../types/user-scope-types';
import { enrichPendingInvitationMember } from '../utils/members-utils';

const resetInviteMemberStoreState = (): void => {
  InviteMemberStore.hasErrorMessage = false;
  InviteMemberStore.errorMessage = '';
  InviteMemberStore.newMembersData = {
    emails: [],
    roles: [],
    selectedAppOwnersScopeItems: [],
  };
  InviteMemberStore.selectedMember = null;
  InviteMemberStore.isUpdateRolesModalVisible = false;
  InviteMemberStore.currentScopeOptionSelected =
    SCOPE_SELECTION_OPTIONS[ScopeOptions.EntireOrganization].value;
};

export const closeInviteUserModal = (): void => {
  resetInviteMemberStoreState();
  setInviteUpdateMembersModalVisibility(false);
};

export const openInviteUserModal = (): void => {
  setInviteUpdateMembersModalVisibility(true);
};

export const setInviteUpdateMembersModalVisibility = (
  payload: boolean,
): void => {
  InviteMemberStore.isModalVisible = payload;
};

export const setUpdateRolesModalVisibility = (payload: boolean): void => {
  InviteMemberStore.isUpdateRolesModalVisible = payload;
};

export const setEmails = (payload: string[]) => {
  InviteMemberStore.newMembersData.emails = payload;
};

export const setRolesInStore = (payload: Role[]) => {
  InviteMemberStore.newMembersData.roles = payload;
};

export const checkIsInvitationExpired = (invitationExpiration: string) => {
  const dateNowISOString = new Date().toISOString();
  return dateNowISOString > invitationExpiration;
};

export const getInvitationExpirationLocalTime = (
  invitationExpiration: string,
) => {
  return new Date(invitationExpiration).toLocaleString();
};

export const closeUpdateUserRolesModal = (): void => {
  setUpdateRolesModalVisibility(false);
};

export const setSelectedMember = (member: ActiveMember): void => {
  const extractedAppOwnersScopeItems = extractScopeItems(
    member.scopes,
    ScopeNames.AppOwner,
  );

  const extractedTagsScopeItems = extractScopeItems(
    member.scopes,
    ScopeNames.Tags,
  );

  InviteMemberStore.selectedMember = {
    ...member,
    selectedAppOwnersScopeItems: extractedAppOwnersScopeItems,
    selectedTagsScopeItems: extractedTagsScopeItems,
  };
};

export const updateUserRoles = (userId: string, userRoles: Role[]): void => {
  const { activeMembers: members } = snapshot(MembersStore);

  const foundMember = members.find(m => m.user_id === userId);
  if (!foundMember) {
    return;
  }

  const activeMember = { ...foundMember };
  activeMember.roles = userRoles;
  const updatedMembers = members.map(m => {
    return m.user_id !== userId ? m : activeMember;
  });

  MembersStore.activeMembers = updatedMembers;
};

export const setSelectedRoles = (roles: Role[]) => {
  if (InviteMemberStore.selectedMember) {
    InviteMemberStore.selectedMember.roles = roles;
  } else {
    InviteMemberStore.newMembersData.roles = roles;
  }
};

export const setAppOwnersScopeItemsInStore = (
  appOwnersScopesItems: string[],
) => {
  if (InviteMemberStore.selectedMember) {
    InviteMemberStore.selectedMember.selectedAppOwnersScopeItems =
      appOwnersScopesItems;
  } else {
    InviteMemberStore.newMembersData.selectedAppOwnersScopeItems =
      appOwnersScopesItems;
  }
};

export const setTagsScopeItemsInStore = (tagsScopesItems: string[]) => {
  if (InviteMemberStore.selectedMember) {
    InviteMemberStore.selectedMember.selectedTagsScopeItems = tagsScopesItems;
  } else {
    InviteMemberStore.newMembersData.selectedTagsScopeItems = tagsScopesItems;
  }
};

export const setScopeOptionInStore = (newScopeOption: string) => {
  InviteMemberStore.currentScopeOptionSelected = newScopeOption;
};

export const updateUserScopesInStore = (
  userId: string,
  scopes?: Nullable<string>,
): void => {
  const { activeMembers } = snapshot(MembersStore);
  const updatedMembers = activeMembers.map(member => {
    const currentMember = { ...member };
    if (currentMember.user_id === userId) {
      currentMember.scopes = scopes;
    }
    return currentMember;
  });

  MembersStore.activeMembers = updatedMembers;
};

export const setPendingInvitationMembersInStore = (
  invitations: PendingInvitationMember[],
): void => {
  InviteMemberStore.pendingInvitationMembers =
    enrichPendingInvitationMember(invitations);
};
