import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { FetchArtifactsInput } from '../../types/artifacts-type';
import query from './get-artifacts-filters-lazy.gql';
import { LazyFiltersResponse } from 'ox-filter-utils';
import { openSnackbar } from 'snackbar-utils';

const getArtifactsFiltersLazy = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (getArtifactsInput: FetchArtifactsInput, cache = true) => {
      try {
        const results = await client.query<ArtifactsFiltersLazyResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: { getArtifactsInput },
        });
        if (!results.data) {
          return null;
        } else {
          return results.data.getArtifactsFiltersLazy;
        }
      } catch (e) {
        openSnackbar('Failed to fetch artifacts filters', { variant: 'error' });
        logger.error('Failed to fetch artifacts filters', e);
        return null;
      }
    },
  };
};

export interface ArtifactsFiltersLazyResponse {
  getArtifactsFiltersLazy: LazyFiltersResponse;
}

export default getArtifactsFiltersLazy;
