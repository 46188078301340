import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';
import { Organization } from '../organization-types';

const OrgInfoStore = createStore<OrgInfoStoreState>(
  {
    organization: null,
  },
  'Organization Info Store',
);

interface OrgInfoStoreState {
  organization: Nullable<Organization>;
}

export default OrgInfoStore;
