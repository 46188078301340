import { FC, ReactElement, useCallback, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

const CollapseText: FC<React.PropsWithChildren<CollapseTextProps>> = ({
  children,
  header,
  withCollapse,
}) => {
  const [collapsed, setCollapsed] = useState(!withCollapse || false);
  const toggleCollapse = useCallback(() => setCollapsed(prev => !prev), []);
  const theme = useTheme();
  return (
    <Paper
      onClick={toggleCollapse}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 0.5,
        padding: 1,
        borderRadius: 4,
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
      }}>
      <Box display='flex' flexDirection='row' alignItems='center' gap={0.5}>
        {withCollapse && (
          <IconButton color='primary' size='small'>
            {collapsed ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </IconButton>
        )}
        <Typography color='primary' variant='body2'>
          {header}
        </Typography>
      </Box>

      <Collapse mountOnEnter={false} unmountOnExit={true} in={collapsed}>
        {children}
      </Collapse>
    </Paper>
  );
};

export interface CollapseTextProps {
  header: string | ReactElement;
  withCollapse: boolean;
}

export default CollapseText;
