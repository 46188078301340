import { Nullable } from 'ox-common-types';
import { Polling } from 'polling-utils';
import { DiscoveryResponse } from '../scan-types';
import scanService from '../services';
import DiscoveryStore, { initialState } from '../store/discovery-store';
import ScanStore, { ScanPhase } from '../store/scan-store';
import {
  setRealScanId,
  setScanError,
  setScanPhase,
} from '../store/scan-store-actions';
import { startGradualPhase } from './gradual-actions';

export const startDiscoveryPhase = () => {
  setScanPhase(ScanPhase.Discovery);
  discoveryPoller.startPolling();
};

export const endDiscoveryProgress = () => {
  discoveryPoller.cancelPolling();
};

export const pollDiscovery = () => {
  if (ScanStore.realScanId) {
    return scanService.getDiscoveryProgress.execute({
      scanID: ScanStore.realScanId,
    });
  } else if (ScanStore.scanID) {
    return scanService.getDiscoveryProgress.execute({
      scanID: ScanStore.scanID,
    });
  }
  return null;
};

export const handleDiscoveryCallback = (
  response: Nullable<DiscoveryResponse>,
) => {
  if (response) {
    const { isStillScanning, reportData, metadata } = response;
    const {
      scanned,
      total,
      progressEvent: { label },
    } = reportData;
    setDiscoveryState(scanned, total, label);
    setRealScanId(metadata.realScanId);
    return isStillScanning;
  } else {
    return true;
  }
};

export const doneCallback = () => {
  startGradualPhase();
  clearStore();
};

export const clearStore = () => {
  discoveryPoller.cancelPolling();
  Object.keys(initialState).map(
    key => (DiscoveryStore[key] = initialState[key]),
  );
};

const discoveryPoller = new Polling<Nullable<DiscoveryResponse>>(
  pollDiscovery,
  handleDiscoveryCallback,
  setScanError,
  doneCallback,
);

export const setDiscoveryState = (
  value: number,
  total: number,
  info: string,
) => {
  DiscoveryStore.total = total;
  DiscoveryStore.value = value;
  DiscoveryStore.info = info;
};
