import { snapshot, useSnapshot } from 'valtio';
import { AppStore, AppStoreState } from './app-store';
import { LDClient } from 'launchdarkly-react-client-sdk';
export const setAppUser = (user: AppStoreState['user']) => {
  AppStore.user = user;
};

export const setAppIsReady = (isReady: AppStoreState['isReady']) => {
  AppStore.isReady = isReady;
};

export const setAppIsInitialized = (
  initialized: AppStoreState['initialized'],
) => {
  AppStore.initialized = initialized;
};

export const setAppIsFirstLogin = (
  isFirstLogin: AppStoreState['isFirstLogin'],
) => {
  AppStore.isFirstLogin = isFirstLogin;
};

export const setAppGeneralError = (
  generalError: AppStoreState['generalError'],
) => {
  AppStore.generalError = generalError;
};

export const setAppIsEmptyOrg = (isEmptyOrg: AppStoreState['isEmptyOrg']) => {
  AppStore.isEmptyOrg = isEmptyOrg;
};

export const setAppPageError = (pageError: AppStoreState['pageError']) => {
  AppStore.pageError = pageError;
};

export const getAppStore = () => {
  return snapshot(AppStore);
};

export const useAppStore = () => {
  return useSnapshot(AppStore);
};

export const setLdClient = (ldClient: LDClient) => {
  AppStore.ldClient = ldClient;
};
