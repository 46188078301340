import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

export const Circle: FC<CircleProps> = ({ color, className }) => {
  const { classes, cx } = useStyles();
  return (
    <span
      style={{
        backgroundColor: color,
      }}
      className={cx(classes.circle, className)}
    />
  );
};

const useStyles = makeStyles()(() => ({
  circle: {
    height: 8,
    width: 8,
    borderRadius: '100vh',
    display: 'inline-flex',
  },
}));

interface CircleProps {
  color: string;
  className?: string;
}
