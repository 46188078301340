import { getAppStore } from 'app-store';
import { isOxUserEmail } from 'common-utils';
import { isProd, isRemoteSite } from 'env-utils';
import { logger } from 'logging-utils';
import {
  HubSpotActionsFormFields,
  HubSpotActionsFormInput,
  HubSpotFeedbackFormInput,
  HubSpotFormsIds,
  HubSpotOxAppFormFields,
  HubSpotOxAppFormInput,
  HubSpotOxBaseFormFields,
  HubSpotUserActions,
} from '../hubspot-types';
import { getCookieByName } from '../utils/cookies-utils';

export const submitHubSpotForm = async (
  formInput:
    | HubSpotOxAppFormInput
    | HubSpotFeedbackFormInput
    | HubSpotActionsFormInput,
  formGuid: HubSpotFormsIds,
) => {
  const { user } = getAppStore();
  if (!user) {
    return;
  }
  formInput[HubSpotOxBaseFormFields.Email] =
    formInput[HubSpotOxBaseFormFields.Email] || user.email;
  formInput[HubSpotOxBaseFormFields.UserId] =
    formInput[HubSpotOxBaseFormFields.UserId] || user.sub;

  const isOxUser =
    isOxUserEmail(formInput[HubSpotOxBaseFormFields.Email]) &&
    !formInput[HubSpotOxBaseFormFields.Email]!.startsWith('yaniv');

  if (!shouldTrack() || isOxUser) {
    return;
  }

  const portalId = '20204725';
  const reqUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;
  const hubspotCookie = getCookieByName('hubspotutk');
  const xhr = new XMLHttpRequest();

  const fields: {
    objectTypeId: '0-1';
    name: string;
    value: string | boolean;
  }[] = Object.keys(formInput)
    .filter(key => !!(formInput as Record<string, string>)[key])
    .map(key => {
      return {
        objectTypeId: '0-1',
        name: key,
        value: (formInput as Record<string, string>)[key] as string | boolean,
      };
    });

  const reqBody = {
    submittedAt: Date.now(), // This millisecond timestamp is optional.
    fields,
    context: {
      hutk: hubspotCookie, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      pageUri: window.location.href,
      pageName: document.title,
    },
  };

  const finalData = JSON.stringify(reqBody);
  xhr.open('POST', reqUrl);

  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      logger.log(`[HubSpot] ${xhr.responseText}`); // Returns a 200 response if the submission is successful.
    } else if (xhr.readyState === 4 && xhr.status === 400) {
      logger.error(`[HubSpot] ${xhr.responseText}`); // Returns a 400 error the submission is rejected.
    } else if (xhr.readyState === 4 && xhr.status === 403) {
      logger.error(`[HubSpot] ${xhr.responseText}`); // Returns a 403 error if the portal isn't allowed to post submissions.
    } else if (xhr.readyState === 4 && xhr.status === 404) {
      logger.error(`[HubSpot] ${xhr.responseText}`); // Returns a 404 error if the formGuid isn't found
    }
  };

  xhr.send(finalData);
};

export const shouldTrack = () => isProd() && !isRemoteSite();

export const getHubSpotFieldForSourceControl = (
  sourceControlDisplayName: string,
) => {
  switch (sourceControlDisplayName.toLowerCase()) {
    case 'github':
      return HubSpotOxAppFormFields.GitHubConnected;
    case 'gitlab':
      return HubSpotOxAppFormFields.GitLabConnected;
    case 'bitbucket':
      return HubSpotOxAppFormFields.BitBucketConnected;
    case 'bitbucket stash':
      return HubSpotOxAppFormFields.BitBucketStashConnected;
    case 'azure repos':
      return HubSpotOxAppFormFields.AzureReposConnected;
  }
};

export const getHubSpotFieldForSourceControlRepos = (
  sourceControlDisplayName: string,
) => {
  switch (sourceControlDisplayName.toLowerCase()) {
    case 'github':
      return HubSpotOxAppFormFields.GitHubReposCount;
    case 'gitlab':
      return HubSpotOxAppFormFields.GitLabReposCount;
    case 'bitbucket':
      return HubSpotOxAppFormFields.BitbucketReposCount;
    case 'bitbucket stash':
      return HubSpotOxAppFormFields.BitbucketStashReposCount;
    case 'azure repos':
      return HubSpotOxAppFormFields.AzureReposCount;
    case 'aws codecommit':
      return HubSpotOxAppFormFields.AWSCodeCommitReposCount;
    case 'eks':
      return HubSpotOxAppFormFields.EKS;
  }
};

export const logUserActionToForm = (actionType: HubSpotUserActions) => {
  switch (actionType) {
    case HubSpotUserActions.Exclusion: {
      const formInput: HubSpotActionsFormInput = {
        [HubSpotActionsFormFields.LastExclusionDate]: new Date().toString(),
      };
      submitHubSpotForm(formInput, HubSpotFormsIds.OxAppActionsForm);
      break;
    }
    case HubSpotUserActions.CreateJiraTicket: {
      const formInput: HubSpotActionsFormInput = {
        [HubSpotActionsFormFields.LastJiraCreationDate]: new Date().toString(),
      };
      submitHubSpotForm(formInput, HubSpotFormsIds.OxAppActionsForm);
      break;
    }
    case HubSpotUserActions.InviteMember: {
      const formInput: HubSpotActionsFormInput = {
        [HubSpotActionsFormFields.LastMemberInvitedDate]: new Date().toString(),
      };
      submitHubSpotForm(formInput, HubSpotFormsIds.OxAppActionsForm);
      break;
    }
  }
};
