import { ApolloError } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import {
  OxCategoriesKeys,
  getConnectorsCategoriesByOrder,
} from '@oxappsec/ox-consolidated-categories';
import { cloneDeep } from 'lodash-es';
import { Nullable } from 'ox-common-types';
import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { scanStore } from '../../new-scan/api/new-scan-api';
import {
  Connector,
  ConnectorFieldValue,
  ConnectorScriptTypes,
  ConnectorStatusTitle,
  ConnectorsBitbucketAppErrors,
  ConnectorsByFamilyObject,
  ConnectorsGitHubAppErrors,
  ConnectorsIDPErrors,
  EConnectorFields,
  GetConnectorInput,
  GetConnectorResourcesInput,
  GetConnectorScriptInput,
  GetConnectorScriptResponse,
  MonitorConnectorResourcesInput,
  TokenAndUserCredentials,
} from '../connectors-types';
import connectorService from '../services';
import {
  setConnectors,
  setConnectorsByFamily,
  setFilteredConnectorsByFamily,
  setIsLoadingConnectors,
  setSearchValue,
  updateSingleConnectorsField,
} from '../store-actions/connectors-store-actions';
import ConnectorsStore from '../stores/connectors-store';
import {
  createConnectorStatus,
  findAliasConnectorMatch,
  findConnectorByNameProperty,
  resolveConnectorIcon,
} from '../utils/connectors-utils';
import { isTokenExpired } from '../utils/credentials-utils';
import {
  openConnectorConfigureModal,
  openRepoSelectionModal,
  updateSelectedConnectorField,
} from './connector-configure-actions';
import { logger } from 'logging-utils';
import { CredentialsType } from '../connector-configure-types';
import Mustache from 'mustache';

export const loadConnectors = async (
  disableCache?: boolean,
): Promise<Connector[]> => {
  const result: Connector[] = await connectorService.getConnectors.execute(
    disableCache,
  );
  setConnectors(result);
  setIsLoadingConnectors(false);
  return result;
};

export const loadConnector = async (
  getConnectorInput: GetConnectorInput,
  disableCache?: boolean,
): Promise<Nullable<Connector>> => {
  const result = await connectorService.getConnector.execute(
    getConnectorInput,
    disableCache,
  );
  if (!result) {
    return null;
  }
  return result.getConnector;
};

export const loadConnectorsByFamily = async (
  disableCache?: boolean,
): Promise<ConnectorsByFamilyObject[]> => {
  const result: ConnectorsByFamilyObject[] =
    await connectorService.getConnectorsByFamily.execute(disableCache);
  const deepClone = cloneDeep(result);
  const connectorsArray: Connector[] = [];
  deepClone.forEach(connectorsByFamilyObj => {
    createOXConnectors(connectorsByFamilyObj);
    connectorsArray.push(...connectorsByFamilyObj.connectors);
  });

  enrichConnectors(connectorsArray, deepClone);
  setConnectors(connectorsArray);
  setConnectorsByFamily(deepClone);
  setFilteredConnectorsByFamily(deepClone);
  setIsLoadingConnectors(false);
  return deepClone;
};

const createOXConnectors = (
  connectorsByFamilyObject: ConnectorsByFamilyObject,
) => {
  let builtInConnectorsByCategory = '';
  const aggregatedConnectors: Connector[] = [];
  let isConfigured = false;
  connectorsByFamilyObject.connectors.forEach(connector => {
    if (connector.isOxBuiltIn) {
      builtInConnectorsByCategory = `${
        builtInConnectorsByCategory ? `${builtInConnectorsByCategory}, ` : ''
      } ${connector.displayName}`;
      aggregatedConnectors.push(cloneDeep(connector));
      connector.hideConnector = true;
      if (connector.isConfigured) {
        isConfigured = true;
      }
    }
  });

  if (!!builtInConnectorsByCategory) {
    const category = getConnectorsCategoriesByOrder().find(category => {
      return category.key === connectorsByFamilyObject.family;
    });

    const connectorName = `${
      category?.customConnectorName || category?.displayName
    }`;
    const oxConnector: Connector = {
      id: `OX-${category?.id}`,
      displayName: connectorName,
      [EConnectorFields.name]: connectorName,
      [EConnectorFields.description]: Mustache.render(
        category?.customConnectorDescription || '',
        { aggregatedConnectors: builtInConnectorsByCategory, connectorName },
      ),
      [EConnectorFields.hostURL]: '',
      [EConnectorFields.apiAccessKey]: '',
      [EConnectorFields.iconURL]: '',
      [EConnectorFields.family]: connectorsByFamilyObject.family,
      [EConnectorFields.credentialsType]: CredentialsType.None,
      [EConnectorFields.credentialsTypes]: [],
      [EConnectorFields.credentials]: [],
      [EConnectorFields.isConfigured]: isConfigured,
      [EConnectorFields.isResourceAvailable]: false,
      [EConnectorFields.isOxBuiltIn]: false,
      [EConnectorFields.isOpenSource]: false,
      [EConnectorFields.connectorStatus]: {
        color: 'connected',
        title: ConnectorStatusTitle.Connected,
      },
      [EConnectorFields.aggregatedConnectors]: aggregatedConnectors,
    };
    connectorsByFamilyObject.connectors.unshift(oxConnector);
  }
};

const enrichConnectors = (
  connectors: Connector[],
  allConnectorsByFamily: ConnectorsByFamilyObject[],
) => {
  const { isDemoEnabled } = scanStore();
  connectors.forEach(connector => {
    connector.isConnectorInvalid = isTokenExpired(
      connector.credentials[0]?.tokenExpirationDate,
    );

    if (isDemoEnabled && connector.isDemoEnabled) {
      connector.isConfigured = true;
      connector.credentials = [
        {
          token: '******',
          credentialsType: connector.credentialsType,
          hostURL: '',
        } as unknown as TokenAndUserCredentials,
      ];
      connector.hostURL = connector.hostURL || 'https://example.com';
    }
    const connectorToUse = connector.aliasFor
      ? findAliasConnectorMatch(connector, allConnectorsByFamily)
      : undefined;
    connector.connectorStatus = createConnectorStatus(
      connectorToUse || connector,
    );

    if (connector.dependsOn) {
      const dependsOnConnector = findConnectorByNameProperty(
        connector.dependsOn,
        allConnectorsByFamily,
      );
      if (!dependsOnConnector) {
        logger.error(
          `Failed to find dependsOnConnector with name ${connector.dependsOn}`,
        );
      } else {
        dependsOnConnector.dependencyConnectors =
          dependsOnConnector.dependencyConnectors
            ? [...dependsOnConnector.dependencyConnectors, connector.id]
            : [connector.id];
      }
      connector.disableConnectOrSwitch = dependsOnConnector
        ? !dependsOnConnector.isConfigured
        : true;
      connector.dependsOnDisplayName = dependsOnConnector
        ? dependsOnConnector.displayName
        : '';
      connector.dependsOnId = dependsOnConnector ? dependsOnConnector.id : '';
      connector.dependsOnConnector = dependsOnConnector || undefined;
    }

    connector.connectorToUse = connectorToUse;
    connector.badgeIcon = resolveConnectorIcon(connector.displayName);
  });
};

export const loadConnectorResources = async (
  getConnectorResourcesInput: GetConnectorResourcesInput,
  disableCache?: boolean,
) => {
  try {
    const result = await connectorService.getConnectorResources.execute(
      getConnectorResourcesInput,
      disableCache,
    );
    return result.getConnectorResources;
  } catch (err) {
    let errMsg =
      'Error trying to fetch repositories, please check your credentials and try again later';
    if (err instanceof ApolloError) {
      errMsg = err.graphQLErrors?.[0]?.message || errMsg;
    }
    datadogLogs.logger.error(`loadConnectorResources failed: ${err}`);
    openSnackbar(errMsg, { variant: 'error', autoHideDuration: 15000 });
    return null;
  }
};

export const monitorConnectorResources = async (
  monitorConnectorResourceInput: MonitorConnectorResourcesInput,
) => {
  const result = await connectorService.monitorConnectorResources.execute(
    monitorConnectorResourceInput,
  );
  if (!result) {
    return null;
  }
  return result.monitorConnectorResources.success;
};

export const handleSearchConnector = (searchInput: string) => {
  setSearchValue(searchInput);
  const { connectorsByFamily } = snapshot(ConnectorsStore);
  const newFilteredConnectorsByFamily = connectorsByFamily.reduce(
    (acc: ConnectorsByFamilyObject[], familyObject) => {
      if (
        familyObject.familyDisplayName
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      ) {
        acc.push(familyObject);
        return acc;
      }
      const filteredFamilyConnectors = familyObject.connectors.filter(
        connector =>
          connector.displayName
            .toLowerCase()
            .includes(searchInput.toLowerCase()),
      );
      if (filteredFamilyConnectors.length === 0) {
        return acc;
      }
      acc.push({ ...familyObject, connectors: filteredFamilyConnectors });
      return acc;
    },
    [],
  );
  setFilteredConnectorsByFamily(newFilteredConnectorsByFamily);
};

export const findConnectorById = (connectorId: string) => {
  const { connectors } = snapshot(ConnectorsStore);
  return connectors.find(connector => connector.id === connectorId);
};

export const handleConnectorsPageQuery = (
  connectorId: string | null,
  isIdp: string | null,
  idpError: string | null,
  isGitHubApp: string | null,
  gitHubAppError: string | null,
  isBitbucketApp: string | null,
  bitbucketAppError: string | null,
) => {
  if (idpError) {
    openSnackbar(createIdpErrorMessage(idpError as ConnectorsIDPErrors), {
      variant: 'error',
      key: idpError,
      autoHideDuration: 15000,
    });
  }

  if (bitbucketAppError) {
    openSnackbar(createBitbucketAppErrorMessage(bitbucketAppError), {
      variant: 'error',
      key: bitbucketAppError,
      persist: true,
    });
  }

  if (gitHubAppError) {
    const isWarning =
      gitHubAppError === ConnectorsGitHubAppErrors.InstallationRequest;
    openSnackbar(createGitHubAppErrorMessage(gitHubAppError), {
      variant: isWarning ? 'warning' : 'error',
      key: gitHubAppError,
      persist: true,
    });
  }

  if (connectorId) {
    const connector = findConnectorById(connectorId);
    if (!connector) {
      return;
    }
    openConnectorConfigureModal(
      connector.aliasFor ? findAliasConnectorMatch(connector).id : connector.id,
      connector.id,
    );

    if (connector.family !== OxCategoriesKeys.SourceControl) {
      return;
    }

    const isValidIdp = isIdp && !idpError;
    const isValidGitHubApp = isGitHubApp && !gitHubAppError;
    const isValidBitbucketApp = isBitbucketApp && !bitbucketAppError;
    if (isValidIdp || isValidGitHubApp || isValidBitbucketApp) {
      openRepoSelectionModal(true);
    }
  }
};

const createIdpErrorMessage = (idpError: ConnectorsIDPErrors) => {
  switch (idpError) {
    case ConnectorsIDPErrors.AccessDenied:
      return 'Identity Provider access was denied by the user';
    case ConnectorsIDPErrors.ValidationError:
      return 'There was a problem validating your credentials, there might be a problem with your access policy';
    case ConnectorsIDPErrors.GeneralError:
    case ConnectorsIDPErrors.MissingCode:
    case ConnectorsIDPErrors.MissingState:
      return 'Oops! We encountered a problem connecting, please try again later';
    default:
      return idpError;
  }
};

const createGitHubAppErrorMessage = (e: ConnectorsGitHubAppErrors | string) => {
  switch (e) {
    case ConnectorsGitHubAppErrors.InstallationRequest:
      return (
        'The OX Security GitHub App installation FAILED due to a lack of owner authorization. ' +
        'Organization owners should have received an authorization request via email. ' +
        'After granting permission, they must complete onboarding steps for the installation to proceed'
      );
    case ConnectorsGitHubAppErrors.ValidationError:
      return 'There was a problem validating your OX Security GitHub App installation, please try again later';
    case ConnectorsGitHubAppErrors.MissingCode:
    case ConnectorsGitHubAppErrors.MissingInstallationId:
    case ConnectorsGitHubAppErrors.UnhandledSetupAction:
      return 'Oops! We encountered a problem associating your OX Security GitHub App installation to your OX Security organization, please try again later';
    default:
      return e;
  }
};

const createBitbucketAppErrorMessage = (
  e: ConnectorsBitbucketAppErrors | string,
) => {
  switch (e) {
    case ConnectorsBitbucketAppErrors.ValidationError:
      return 'There was a problem validating your OX Security Bitbucket App installation, please try again later';
    case ConnectorsBitbucketAppErrors.MissingJWT:
    case ConnectorsBitbucketAppErrors.MissingUserUuid:
      return (
        `Oops! We encountered a problem associating your OX Security Bitbucket App installation to your OX Security organization ` +
        `since necessary data wasn't supplied in the redirect. Please try again later`
      );
    default:
      return e;
  }
};

export const resetConnectorsStore = () => {
  const { connectorsByFamily } = snapshot(ConnectorsStore);
  setFilteredConnectorsByFamily(connectorsByFamily);
  setSearchValue('');
};

export const updateConnectorField = (
  connectorId: string,
  fieldName: EConnectorFields,
  fieldValue: ConnectorFieldValue,
) => {
  updateSingleConnectorsField(connectorId, fieldName, fieldValue);
  updateSelectedConnectorField(connectorId, fieldName, fieldValue);
};

export const updateConnectorFields = (
  connectorId: string,
  keyToValueMap: Partial<Record<EConnectorFields, ConnectorFieldValue>>,
) => {
  Object.keys(keyToValueMap).forEach(key => {
    updateConnectorField(
      connectorId,
      key as EConnectorFields,
      keyToValueMap[key],
    );
  });
};

export const getConnectorScript = async (
  connectorScriptType: ConnectorScriptTypes,
): Promise<Nullable<GetConnectorScriptResponse>> => {
  const getConnectorScriptInput: GetConnectorScriptInput = {
    connectorScriptType,
  };
  const result = await connectorService.getConnectorScript.execute(
    getConnectorScriptInput,
  );
  if (!result) {
    return null;
  }
  return result;
};
