import { Box, Link, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { MdErrorOutline as ErrorOutlineIcon } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: theme.spacing(2),
    fill: theme.palette.primary.main,
  },
}));

const PageNotFound: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.content}>
      <img
        src='/images/mosh.svg'
        alt='Mosh Avatar'
        style={{ maxHeight: '60vh' }}
      />
      <ErrorOutlineIcon width='48px' height='48px' className={classes.icon} />
      <Typography variant='h6' color='textPrimary'>
        Oops! The page you tried to reach does not exist (404)
      </Typography>
      <Typography variant='body2'>
        If you think this is a mistake, please contact us at{' '}
        <Link href='mailto:support@ox.security'>support@ox.security</Link>
      </Typography>
    </Box>
  );
};

export default PageNotFound;
