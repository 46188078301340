import { snapshot } from 'valtio';
import {
  Connector,
  ConnectorFieldValue,
  EConnectorFields,
} from '../connectors-types';
import ConnectorConfigureStore from '../stores/connector-configure-store';
import ConnectorsStore from '../stores/connectors-store';

const setModalVisibility = (payload: boolean): void => {
  ConnectorConfigureStore.isModalVisible = payload;
};

export const closeConnectorConfigureModal = (): void => {
  setModalVisibility(false);
};

export const openConnectorConfigureModal = (
  connectorId: string,
  parentConnectorId: string,
): void => {
  const connector = ConnectorsStore.connectors.find(c => c.id === connectorId);
  const connectorToUse = ConnectorsStore.connectors.find(
    c => c.id === parentConnectorId,
  );
  if (connector && connectorToUse) {
    ConnectorConfigureStore.selectedConnector = connector;
    ConnectorConfigureStore.originalConnector = connectorToUse;
  }
  setModalVisibility(true);
};

export const updateSelectedConnectorField = (
  connectorId: string,
  fieldName: EConnectorFields,
  fieldValue: ConnectorFieldValue,
): void => {
  const { selectedConnector } = snapshot(ConnectorConfigureStore);
  if (!selectedConnector || selectedConnector.id !== connectorId) {
    return;
  }

  const updatedConnector = {
    ...selectedConnector,
    [fieldName]: fieldValue,
  } as Connector;
  ConnectorConfigureStore.selectedConnector = updatedConnector;
};

export const setIsLoadingVerifyAndSaveCredentials = (
  isLoading: boolean,
): void => {
  ConnectorConfigureStore.isLoadingVerifyAndSaveCredentials = isLoading;
};

export const setIsLoadingDeleteCredentials = (isLoading: boolean): void => {
  ConnectorConfigureStore.isLoadingRemoveCredentials = isLoading;
};
export const setHostUrlValidationError = (validationError: boolean): void => {
  ConnectorConfigureStore.hostUrlValidationError = validationError;
};

export const setHostUrlErrorMsg = (errMsg: string): void => {
  ConnectorConfigureStore.hostUrlErrorMsg = errMsg;
};

const setRepoSelectionModalVisibility = (isVisible: boolean) => {
  ConnectorConfigureStore.repoSelectionModalOpen = isVisible;
};

export const closeRepoSelectionModal = () => {
  setRepoSelectionModalVisibility(false);
};

export const setIsRepoModalFirstTimeOpen = (isFirstTime: boolean) => {
  ConnectorConfigureStore.isRepoSelectionModalFirstOpen = isFirstTime;
};

export const openRepoSelectionModal = (isFirstTimeOpened?: boolean) => {
  setRepoSelectionModalVisibility(true);
  if (isFirstTimeOpened && typeof isFirstTimeOpened === 'boolean') {
    setIsRepoModalFirstTimeOpen(true);
  }
};
