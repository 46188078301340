import { ScopeNames } from '@oxappsec/ox-unified-permissions/src/scopes';

export const UNASSIGNED_OWNER_VALUE = 'unassignedOwner';

export interface AppOwnersFilterOption {
  email: string;
  name: string;
  checked: boolean;
}

export interface GlobalDataViewSelectorOption {
  id: string;
  displayName: string;
  scopeName: ScopeNames;
}
