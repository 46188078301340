import { IssueDetails } from '../../issues/common/types/issues-types';
import issueTrendService from '../services';
import {
  setChartData,
  setIsEmpty,
  setIsJustOneChange,
  setIssueTrend,
  setIssueTrendError,
  setLabels,
  setLoadingIssueTrend,
  setManualSeverityChangeReason,
  setSeverityChangeReasonsAdded,
  setSeverityChangeReasonsRemoved,
  setToolSeverities,
} from '../store-actions/issue-trend-store-actions';
import { FetchIssueTrendInput } from '../types/issue-trend-types';
import { resolveSeverityValue } from '../utils/issue-trend-utils';

export const fetchIssueTrendAction = async (
  params: FetchIssueTrendInput,
  selectedIssue: IssueDetails,
) => {
  setLoadingIssueTrend(true);
  const results = await issueTrendService.fetchSeverityChangeHistory.execute(
    params,
  );
  if (!results) {
    setIssueTrendError(true);
  } else {
    setIsEmpty(false);
    setIsJustOneChange(false);
    if (results.getIssueTrend.length) {
      if (results.getIssueTrend.length === 1) {
        setIsJustOneChange(true);
        const twoPoints = [
          {
            severity: resolveSeverityValue(selectedIssue.severity),
            date: new Date(params.dateRange.from),
            severityChangeReasonsAdded: [],
            severityChangeReasonsRemoved: [],
            originalToolSeverity: selectedIssue.originalToolSeverity,
            manualSeverityChangeReason: '',
          },
          ...results.getIssueTrend,
        ];
        setIssueTrend(params.issueId, twoPoints);
        setLabels(twoPoints);
      } else {
        setIssueTrend(params.issueId, results.getIssueTrend);
        setLabels(results.getIssueTrend);
      }
    } else {
      setIsEmpty(true);
      const twoPoints = [
        {
          severity: resolveSeverityValue(selectedIssue.severity),
          date: new Date(params.dateRange.from),
          severityChangeReasonsAdded: [],
          severityChangeReasonsRemoved: [],
          originalToolSeverity: selectedIssue.originalToolSeverity,
          manualSeverityChangeReason: '',
        },
        {
          severity: resolveSeverityValue(selectedIssue.severity),
          date: new Date(params.dateRange.to),
          severityChangeReasonsAdded: [],
          severityChangeReasonsRemoved: [],
          originalToolSeverity: selectedIssue.originalToolSeverity,
          manualSeverityChangeReason: '',
        },
      ];
      setIssueTrend(params.issueId, twoPoints);
      setLabels(twoPoints);
    }
    setChartData();
    setSeverityChangeReasonsAdded();
    setSeverityChangeReasonsRemoved();
    setToolSeverities();
    setManualSeverityChangeReason();
  }
  setLoadingIssueTrend(false);
};
