import { Nullable } from 'ox-common-types';
import { Connector } from '../../connectors/connectors-types';
import {
  CodeRepos,
  ConnectionType,
  OnboardingSteps,
} from '../onboarding-types';
import OnboardingStore from '../stores/onboarding-store';

export const setChosenCodeRepo = (chosenCodeRepo: Connector) => {
  OnboardingStore.chosenCodeRepo = chosenCodeRepo;
};

export const setOnboardingStep = (
  onboardingStep: Nullable<OnboardingSteps>,
) => {
  OnboardingStore.onboardingStep = onboardingStep;
};

export const setOrgName = (orgName: string) => {
  OnboardingStore.orgName = orgName;
};

export const setOriginalOrgName = (orgName: string) => {
  OnboardingStore.originalOrgName = orgName;
};

export const setIsLoading = (isLoading: boolean) => {
  OnboardingStore.isLoading = isLoading;
};

export const setInitOnboardingInProcess = (inProcess: boolean) => {
  OnboardingStore.isOnboardingInitInProcess = inProcess;
};

export const setConnectionType = (connectionType: ConnectionType) => {
  OnboardingStore.connectionType = connectionType;
};

export const setHostUrl = (hostUrl: string) => {
  OnboardingStore.hostUrl = hostUrl;
};

export const setTokenOrPassword = (tokenOrPassword: string) => {
  OnboardingStore.tokenOrPassword = tokenOrPassword;
};

export const setOnboardingError = (
  isError: boolean,
  errorMessage: Nullable<string>,
) => {
  OnboardingStore.generalError = isError;
  OnboardingStore.errorMessage = errorMessage;
};

export const setOnboardingConnectorsError = (errorMsg: string) => {
  OnboardingStore.onboardingConnectorsError = errorMsg;
};

export const setUsername = (username: string) => {
  OnboardingStore.username = username;
};

export const setSkipModalVisibility = (isSkipModalVisible: boolean) => {
  OnboardingStore.isSkipModalVisible = isSkipModalVisible;
};

export const setValidationError = (errorConnector: Nullable<CodeRepos>) => {
  OnboardingStore.validationError = errorConnector;
};

export const setOnboardingSkipped = (isSkipped: boolean) => {
  OnboardingStore.onboardingSkipped = isSkipped;
};
