import { User } from '@auth0/auth0-react';
import { AppEvents } from 'common-events';
import { firstTimeLoadWhatsNew } from '../actions/whats-new-load-action';

export const setupWhatsNewListeners = () => {
  document.addEventListener(
    AppEvents.loading.ready,
    (event: CustomEventInit<{ user: User }>) => {
      firstTimeLoadWhatsNew(event.detail?.user);
    },
  );
};
