import { AttackPathType, NodeContent } from '../attack-path-types';
import { ReactComponent as InfoIcon } from '../assets/info.svg';

import { Typography, useTheme } from '@mui/material';
import IntelligenceIcon from '../assets/intelligence';
import { ReactComponent as DetectionIcon } from '../assets/detection.svg';
import { ReactComponent as SeverityIcon } from '../assets/severity.svg';
import { Theme } from '@mui/system';
import { NodeConfig } from '../configs/node-config';

import { onAttackPathExpandClick } from '../utils/attack-path-utils';

export const useNode = (type, data) => {
  const metadata = data.metadata;
  const theme = useTheme();

  const backgroundColor = 'white';
  let node: NodeContent = {
    Icon: InfoIcon,
    size: 40,
    backgroundColor,
    iconColor: 'unset',
    borderColor: null,
    iconScale: 0.4,
  };

  switch (type) {
    case AttackPathType.ExpandAttackPath:
      node.size = 60;
      node.Icon = () => (
        <Typography fontSize={16} color='#90C0FF'>
          +{metadata.count}
        </Typography>
      );
      node.onClick = () => onAttackPathExpandClick(data);
      break;

    default:
      node = {
        ...node,
        ...(NodeConfig[type] && NodeConfig[type](metadata, theme)),
      };
  }

  return node;
};

export const useGroupNode = (type: AttackPathType, data, theme: Theme) => {
  const { metadata } = data;
  const node: NodeContent = {
    Icon: InfoIcon,
    size: 40,
    iconScale: 0.4,
  };
  switch (type) {
    case AttackPathType.Intelligence:
      node.Icon = IntelligenceIcon;

      node.iconColor =
        theme.palette.categoryRiskColor[metadata.severity?.toLowerCase()];
      break;
    case AttackPathType.Detection:
      node.Icon = DetectionIcon;
      break;
    case AttackPathType.SeverityFactor:
      node.Icon = SeverityIcon;
      break;
    default:
      node.Icon = InfoIcon;
  }

  return node;
};
