import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';

import query from './get-graph.gql';
import { AttackPathGraph } from '../../attack-path-types';

const getAttachPathGraph = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (issueId: string): Promise<Nullable<AttackPathGraph>> => {
      try {
        const results = await client.query<AttackPathGraphResponse>({
          query,
          variables: { issueId },
        });

        if (!results || !results.data || !results.data.getIssueGraph) {
          logger.error('Failed to get attack path graph');
          return null;
        }

        return results.data.getIssueGraph;
      } catch (error) {
        logger.error('Failed to get attack path graph');
        return null;
      }
    },
  };
};

interface AttackPathGraphResponse {
  getIssueGraph: AttackPathGraph;
}

export default getAttachPathGraph;
