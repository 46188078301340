import { Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export const EmptyPageAvatarSideBySide: FC<EmptyPageAvatarSideBySideProps> = ({
  text,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.emptyPageAvatar}>
      <div className={classes.main} color='text.primary'>
        {text}
      </div>
      <div>
        <img
          src='/images/mosh.png'
          alt='Ox at work'
          className={classes.image}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  emptyPageAvatar: {
    display: 'flex',
    flexDirection: 'column',
    gridTemplateColumns: 'auto auto',
    margin: 'auto',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  main: {
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
    fontSize: 'clamp(15px, 0.8vw, 1.5rem)',
    maxWidth: 600,
  },
  image: {
    width: '100%',
    height: 'auto',
    minWidth: 400,
    maxWidth: '80vw',
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      maxWidth: '45vw',
    },
  },
}));

export interface EmptyPageAvatarSideBySideProps {
  text: ReactNode;
}
