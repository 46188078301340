import {
  Button,
  ButtonProps,
  CircularProgress,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { important } from 'ox-common-types';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const ScanButton: FC<React.PropsWithChildren<ScanButtonProps>> = props => {
  const { tooltip, onClick, disabled, value, variant, withProgress } = props;
  const { classes } = useStyles();
  return (
    <Tooltip arrow placement='bottom' title={tooltip}>
      <span>
        <Button
          sx={{ textTransform: 'none', cursor: 'pointer' }}
          color='primary'
          classes={{ disabled: classes.disabledScan }}
          disabled={disabled}
          variant={variant}
          onClick={onClick}>
          <Typography variant='caption' sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Typography>
          {withProgress && (
            <CircularProgress size={12} sx={{ color: 'white' }} />
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  disabledScan: {
    color: important('rgba(255, 255, 255, 0.3)'),
    backgroundColor: important('rgba(255, 255, 255, 0.12)'),
    border: important('none'),
  },
}));

export interface ScanButtonProps extends Omit<ButtonProps, 'value'> {
  tooltip: string;
  value?: string | boolean;
  withProgress: boolean;
}

export default ScanButton;
