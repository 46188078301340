import { Link, Typography } from '@mui/material';
import { navigate } from 'app-navigator';
import { FC, PropsWithChildren, useCallback } from 'react';

export const SnackbarMessageWithLink: FC<
  PropsWithChildren<SnackbarMessageWithLinkProps>
> = ({
  message,
  linkTitle,
  linkHref,
  shouldOpenInNewTab = true,
  shouldNavigate = false,
}) => {
  const navigateToPage = useCallback(() => {
    navigate(linkHref);
  }, [linkHref]);

  return (
    <Typography color='inherit' variant='body1'>
      {message}
      {shouldNavigate ? (
        <Link
          onClick={navigateToPage}
          color='text.primary'
          ml={1}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>
          {linkTitle}
        </Link>
      ) : (
        <Link
          color='text.primary'
          ml={1}
          style={{
            textDecoration: 'underline',
          }}
          href={linkHref}
          target={shouldOpenInNewTab ? '_blank' : '_self'}>
          {linkTitle}
        </Link>
      )}
    </Typography>
  );
};

interface SnackbarMessageWithLinkProps {
  message: string;
  linkTitle: string;
  linkHref: string;
  shouldOpenInNewTab?: boolean;
  shouldNavigate?: boolean;
}
