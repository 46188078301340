import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, FetchOrgScanInfoInput } from 'ox-common-types';
import { SupplyChainResponse } from '../../types/supply-chain-backend-types';
import query from './get-supply-chain.gql';

const getSupplyChain = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getSoftwareSupplyChainInput: FetchOrgScanInfoInput,
      cache: boolean = true,
    ) => {
      try {
        const results = await client.query<SupplyChainResponse>({
          query,
          variables: { getSoftwareSupplyChainInput },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NoCache,
        });
        return results.data.getSoftwareSupplyChain;
      } catch (error) {
        logger.error('Failed to fetch supply chain data', error);
        return null;
      }
    },
  };
};

export default getSupplyChain;
