import { Button, Theme, Tooltip, Typography } from '@mui/material';
import { CustomButton } from 'ox-react-components';
import { FC, useCallback } from 'react';
import { BiCodeAlt as BiCodeAltIcon } from 'react-icons/bi';
import { DiGit } from 'react-icons/di';
import { SlMagicWand } from 'react-icons/sl';
import { makeStyles } from 'tss-react/mui';
import {
  setIssueDrawer,
  setMenuIssueId,
} from '../../../active-issues/actions/active-issues-actions';
import {
  openQuickFixModal,
  setFilesDiffModalVisibility,
  setPolicyFixModal,
} from '../../../active-issues/store-actions/issue-store-actions';
import withDisabledIfUnauthorized, {
  WithDisabledIfUnauthorizedProps,
} from '../../../../permissions/higher-order-components/withDisabledIfUnauthorized';
import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';

const IssueCardRecommendation: FC<
  React.PropsWithChildren<IssueCardRecommendationProps>
> = ({
  children,
  fixLink,
  prAvailable,
  issueId,
  fixAvailable,
  isResolvedIssuesPage,
  disabled,
  tooltipText,
  withActions,
}) => {
  const { classes } = useStyles();
  const openPrModal = useCallback(async () => {
    await setIssueDrawer(issueId);
    openQuickFixModal();
    setMenuIssueId(null);
  }, [issueId]);

  const onFix = useCallback(async () => {
    await setIssueDrawer(issueId);
    setPolicyFixModal(true);
    setMenuIssueId(null);
  }, [issueId]);

  const openFilesDiffModal = useCallback(async () => {
    await setIssueDrawer(issueId);
    setFilesDiffModalVisibility(true);
    setMenuIssueId(null);
  }, [issueId]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant='body2' color='text.secondary'>
          Recommendation
        </Typography>
        <div className={classes.actions}>
          {prAvailable && withActions && (
            <>
              {!!!isResolvedIssuesPage && (
                <>
                  <Tooltip
                    title={!!disabled ? tooltipText : ''}
                    placement='top'
                    arrow>
                    <span>
                      <Button
                        disabled={!!disabled}
                        size='small'
                        startIcon={<DiGit />}
                        onClick={openPrModal}
                        sx={{
                          textTransform: 'none',
                        }}
                        variant='contained'>
                        Open PR
                      </Button>
                    </span>
                  </Tooltip>
                  <Button
                    size='small'
                    startIcon={<BiCodeAltIcon />}
                    onClick={openFilesDiffModal}
                    sx={{
                      textTransform: 'none',
                    }}
                    variant='contained'>
                    View Code Fix
                  </Button>
                </>
              )}
            </>
          )}
          {fixAvailable && withActions && (
            <>
              {!!!isResolvedIssuesPage && (
                <Tooltip
                  title={disabled ? tooltipText : ''}
                  placement='top'
                  arrow>
                  <span>
                    <CustomButton
                      disabled={!!disabled}
                      objIcon={<SlMagicWand size='1rem' />}
                      handleClick={onFix}>
                      <Typography variant='body1' color='text.primary'>
                        Fix
                      </Typography>
                    </CustomButton>
                  </span>
                </Tooltip>
              )}
            </>
          )}
          {fixLink && withActions && (
            <>
              {!!!isResolvedIssuesPage && (
                <Tooltip
                  title={disabled ? tooltipText : fixLink}
                  placement='top'
                  arrow>
                  <span>
                    <Button
                      href={fixLink}
                      target='_blank'
                      size='small'
                      disabled={!!disabled}
                      sx={{
                        textTransform: 'none',
                      }}>
                      View Fix Link
                    </Button>
                  </span>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
      <Typography
        color='text.primary'
        variant='body2'
        mt={1}
        sx={{ whiteSpace: 'pre-wrap' }}
        component='div'>
        <div
          dangerouslySetInnerHTML={{
            __html: children,
          }}
        />
      </Typography>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 40,
  },
  actions: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    gap: theme.spacing(1.5),
  },
}));
export interface IssueCardRecommendationProps
  extends WithDisabledIfUnauthorizedProps {
  fixLink: string;
  children: string;
  prAvailable: boolean;
  issueId: string;
  fixAvailable?: boolean;
  isResolvedIssuesPage?: boolean;
  withActions?: boolean;
}

export default withDisabledIfUnauthorized(IssueCardRecommendation, {
  requiredPermissions: [
    {
      permissionScope: PermissionScope.Edit,
      permissionType: PermissionTypes.IssuesFix,
    },
  ],
});
