import { Box, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { SeverityType } from 'ox-common-types';
import { FilterTypeToFilter } from 'ox-filter-utils';
import { FC, useMemo } from 'react';
import { SeverityColor } from 'severity-utils';
import { capitalizeFirstLetter } from 'string-utils';
import { makeStyles } from 'tss-react/mui';
import { IssueDetails, SeverityDiffStatus } from '../../types/issues-types';
import { mappingSeveritisChangeReason } from '../../utils/issue-utils';
import ActionsOptionsTooltip from '../ActionsOptions/ActionsOptionsTooltip';
import SeverityChangeFactor from './SeverityChangeFactor';
import { useSnapshot } from 'valtio';
import SettingsStore from '../../../../settings/stores/settings-store';
import {
  SettingsName,
  SettingsSubType,
} from '../../../../settings/types/settings-types';
import CorrelatedIssue from './CorrelatedIssue';

import { isDevelopment } from 'env-utils';
import IssueCardSeverityDiff from './IssueCardSeverityDiff';
import { InfoIcon } from 'ox-react-components';
import SeverityFactorsBreakdown from 'ox-react-components/src/SeverityFactorsBreakdown/SeverityFactorsBreakdown';
import { useOxFlags } from '../../../../feature-flags/actions/feature-flags-actions';
import { CveSeverity } from 'ox-react-components/src/Vulnerabilities/icon-utils';
import IssueCardCategory from './IssueCardCategory';
import IssueCardSeverity from './IssueCardSeverity';

const IssueCardHeader: FC<IssueCardSeverityProps> = ({
  oxSeverity,
  originalSeverity,
  severityBreakdown,
  severity,
  overrideSeverity,
  filters,
  correlatedIssueId,
  category,
  categoryId,
  appType,
  registryType,
  previousSeverity,
  severityFactorsDiff,
}) => {
  const { classes } = useStyles();
  const { issueSeverityBreakdownEnabled } = useOxFlags();
  const showOriginalSeverity = !originalSeverity
    ? oxSeverity
    : originalSeverity;
  const severitiesByType = mappingSeveritisChangeReason(severityBreakdown);

  const { orgSettingsMapByName } = useSnapshot(SettingsStore);
  const isEnableAdvancedOptionsToolTip = orgSettingsMapByName
    .find(s => s.name === SettingsName.Tooltips)
    ?.settings.find(
      s => s.settingsSubType === SettingsSubType.AdvancedOptionsToolTip,
    )?.enabled;

  const theme = useTheme();
  const CategoryInfo = <IssueCardCategory category={category} />;

  const factors = useMemo(
    () =>
      severityBreakdown
        ?.filter(
          item => Math.abs(item.changeNumber) > 1 && !CveSeverity[item.name],
        )
        .map(item => ({
          sfName: item.name,
          sfChangedNumber: item.changeNumber,
        })),
    [severityBreakdown],
  );
  const severityBlock = (
    <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
      <Typography variant='h1' color='text.secondary'>
        Severity
      </Typography>
      <IssueCardSeverity
        severity={severity}
        isEnableAdvancedOptionsToolTip={isEnableAdvancedOptionsToolTip}
        filters={filters}
      />

      {issueSeverityBreakdownEnabled && factors?.length ? (
        <InfoIcon
          placement='right'
          marginX={0.5}
          customColor={theme.palette.primary.main}
          title={
            <SeverityFactorsBreakdown
              severityTitle='Severity'
              oxSeverity={severity}
              originalSeverity={showOriginalSeverity}
              factors={factors}
            />
          }
        />
      ) : null}
    </Box>
  );

  const originalSeverityBlock = (
    <Typography variant='body2' color='text.secondary'>
      Tool Severity&nbsp;
      <SeverityColor severity={showOriginalSeverity}>
        {color => (
          <span
            style={{
              color,
              textTransform: 'uppercase',
              fontWeight: 600,
            }}>
            {showOriginalSeverity}
          </span>
        )}
      </SeverityColor>
    </Typography>
  );

  const previousSeverityBlock =
    isDevelopment() &&
    previousSeverity?.severity &&
    previousSeverity?.severityChangedDate ? (
      <Tooltip
        title={
          severityFactorsDiff && (
            <IssueCardSeverityDiff
              date={previousSeverity.severityChangedDate}
              diff={severityFactorsDiff}
            />
          )
        }>
        <Typography variant='body2' color='text.secondary'>
          Previous Severity&nbsp;
          <SeverityColor severity={previousSeverity.severity}>
            {color => (
              <span
                style={{
                  color,
                  textTransform: 'uppercase',
                  fontWeight: 600,
                }}>
                {previousSeverity.severity}
              </span>
            )}
          </SeverityColor>
        </Typography>
      </Tooltip>
    ) : null;
  return (
    <div className={classes.issueCardSeverity}>
      <div className={classes.firstColumn}>
        <div className={classes.textContainer}>
          {isEnableAdvancedOptionsToolTip ? (
            <>
              <ActionsOptionsTooltip
                value={categoryId?.toString()!}
                displayValue={category!}
                withFilter
                withName
                filters={filters[FilterTypeToFilter.Category]}
                filterType={FilterTypeToFilter.Category}>
                {CategoryInfo}
              </ActionsOptionsTooltip>
              {severityBlock}
              <ActionsOptionsTooltip
                value={capitalizeFirstLetter(showOriginalSeverity)}
                withFilter
                withName
                filters={filters[FilterTypeToFilter.OriginalSeverity]}
                filterType={FilterTypeToFilter.OriginalSeverity}>
                {originalSeverityBlock}
              </ActionsOptionsTooltip>
              {previousSeverityBlock}
            </>
          ) : (
            <>
              {CategoryInfo}
              {severityBlock}
              {originalSeverityBlock}
              {previousSeverityBlock}
            </>
          )}

          {overrideSeverity && (
            <Typography color='text.primary' fontSize='0.65rem'>
              {`* User changed from OX Severity ${oxSeverity}`}
            </Typography>
          )}
        </div>
        <div style={{ paddingTop: 8 }}>
          {correlatedIssueId && category && (
            <CorrelatedIssue
              correlatedIssueId={correlatedIssueId}
              category={category}
              appType={appType}
              registryType={registryType}
            />
          )}
        </div>
      </div>

      <SeverityChangeFactor
        severitiesByType={severitiesByType}
        filters={filters}
        isEnableAdvancedOptionsToolTip={isEnableAdvancedOptionsToolTip}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  issueCardSeverity: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'flex-start',
  },
  firstColumn: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    paddingTop: 8,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(3),
  },
}));

export interface IssueCardSeverityProps {
  oxSeverity: SeverityType;
  originalSeverity: SeverityType;
  severityBreakdown: IssueDetails['severityBreakdown'];
  severity: string;
  overrideSeverity: boolean;
  filters: {};
  correlatedIssueId?: string;
  category?: string;
  appType?: string;
  registryType?: string;
  categoryId?: string;
  previousSeverity?: {
    severity: string;
    severityChangedDate: string;
  };
  severityFactorsDiff?: {
    shortName: string;
    change: number;
    status: SeverityDiffStatus;
  }[];
}

export default IssueCardHeader;
