import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import query from './get-categories-in-prevent.gql';

const getCategoriesInPrevent = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (): Promise<Nullable<GetCategoriesInPreventResponse>> => {
      const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
      try {
        const results = await client.query<GetCategoriesInPreventResponse>({
          query,
          fetchPolicy,
        });
        if (!results.data) return null;
        return results.data;
      } catch (e) {
        logger.error('Failed to fetch org scan info results, ', e);
        return null;
      }
    },
  };
};

export interface GetCategoriesInPreventResponse {
  getCategoriesInPrevent: string[];
}

export default getCategoriesInPrevent;
