import {
  darken,
  lighten,
  LinearProgress,
  Theme,
  Typography,
} from '@mui/material';
import { important } from 'ox-common-types';
import {
  ConditionalFilter,
  FilterDetails,
  FilterTypeToFilter,
} from 'ox-filter-utils';
import { FC } from 'react';
import { MdFiberManualRecord as FiberManualRecordIcon } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';
import { TrendIcon } from '../TrendIcon/TrendIcon';
import { FilterItemTooltip } from './FilterItemTooltip';
import { conditionalFiltersUtils } from '../../../../apps/web/src/issues/common/utils/conditional-filters-utils';
import { navigateWithParameters, AppPages } from 'app-navigator';

export const FilterItemStatistics: FC<FilterItemStatisticsProps> = ({
  categoryIcon: CategoryIcon,
  name,
  count,
  percent,
  color,
  filterTypeToFilterBy,
  splitLabel,
  itemHeight,
  id,
  changeNumber,
  isStatistic,
  additionalFilters,
}) => {
  const { classes } = useStyles();
  if (name === null) {
    return <></>;
  }

  const handleClick = () => {
    if (!filterTypeToFilterBy || !id) return;
    const filters = {
      [filterTypeToFilterBy]: [id],
      ...additionalFilters,
    };

    const conditionalFilters: ConditionalFilter[] =
      conditionalFiltersUtils.translateFiltersToConditionalFilters(filters);

    navigateWithParameters(AppPages.ActiveIssues, {
      conditionalFilters: conditionalFilters,
    });
  };

  return (
    <FilterItemTooltip title={isStatistic ? '' : name} tooltipEnterDelay={0}>
      <div
        className={classes.filterItem}
        style={{ height: itemHeight, cursor: 'pointer' }}
        onClick={handleClick}>
        <div className={classes.checkbox}></div>
        <span>
          {CategoryIcon &&
            filterTypeToFilterBy === FilterTypeToFilter.Category && (
              <CategoryIcon width={12} height={12} className={classes.icon} />
            )}
          {filterTypeToFilterBy ===
            FilterTypeToFilter.SeverityChangeReasons && (
            <TrendIcon value={changeNumber} />
          )}
          {!CategoryIcon && color && (
            <FiberManualRecordIcon
              style={{
                color,
                display: 'inline-block',
                width: 10,
                height: 10,
              }}
            />
          )}
        </span>

        <Typography
          variant='body2'
          color='text.secondary'
          fontSize='0.7rem'
          component='span'
          className={classes.name}>
          {filterTypeToFilterBy === FilterTypeToFilter.Apps ? splitLabel : name}
        </Typography>

        <Typography variant='body2' color='text.primary' fontSize='0.68rem'>
          {count}
        </Typography>
        <LinearProgress value={percent} variant='determinate' />
      </div>
    </FilterItemTooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  filterItem: {
    display: 'grid',
    gridColumnGap: theme.spacing(0.5),
    gridTemplateColumns: 'auto auto 1fr auto 0.2fr',
    alignItems: 'center',
    paddingInlineEnd: theme.spacing(1.5),
    transition: theme.transitions.create('background', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      background:
        theme.palette.mode === 'light'
          ? lighten(theme.palette.background.default, 0.5)
          : darken(theme.palette.background.default, 0.2),
    },
  },
  checkbox: {
    padding: important(theme.spacing(0.5)),
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    color: theme.palette.primary.main,
    display: 'flex',
  },
}));

export interface FilterItemStatisticsProps extends FilterDetails {
  filterTypeToFilterBy?: FilterTypeToFilter;
  itemHeight: number;
  isStatistic?: boolean;
  changeNumber: number;
  additionalFilters?: {};
}
