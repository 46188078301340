import { isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import { openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { ITag, OxTagTypes } from '../../applications/applications-types';
import tagsService from '../services';
import {
  setEditApplicationsTagsData,
  setLoadingSubmit,
  setLoadingTagsOptions,
} from '../store-actions/tags-store-actions';
import TagsStore from '../store/tags-store';

export const getEditApplicationsTagsData = async (appsIds: string[]) => {
  setLoadingTagsOptions(true);
  try {
    const res = await tagsService.getEditApplicationsTagsData.execute(
      {
        filters: {
          tagType: ['ox', 'simple'],
        },
      },
      {
        filters: {
          appId: appsIds,
          tagType: ['ox', 'simple'],
        },
      },
    );
    if (isNull(res)) {
      throw Error('Invalid Response');
    }
    const {
      getAllTags: { tags },
      getAppTags: { appsTags },
    } = res;
    setEditApplicationsTagsData(appsIds, { tags, appsTags });
  } catch (err) {
    logger.error(err);
    openSnackbar(
      'An error encountered while loading exists tags options. Please try again later...',
      {
        variant: 'error',
      },
    );
  } finally {
    setLoadingTagsOptions(false);
  }
};

export const addNewTags = async (tags: string[]) => {
  const tagsInput: Pick<ITag, 'tagType' | 'displayName' | 'name'>[] = tags.map(
    tag => ({
      displayName: tag,
      name: tag.toLocaleLowerCase(),
      tagType: OxTagTypes.simple,
    }),
  );

  const res = await tagsService.addNewTags.execute({
    tagsInput,
  });

  if (isNull(res)) {
    throw Error('Invalid Response');
  }

  return res.addTags;
};

export const submitEditApplicationsTags = async ({
  appIds,
  modifiedTagsList,
  includeTagInAllAppsList,
}: {
  appIds: string[];
  modifiedTagsList: string[];
  includeTagInAllAppsList: string[];
}) => {
  setLoadingSubmit(true);
  try {
    const { tags, assignedTags } = snapshot(TagsStore);
    if (isNull(tags) || isNull(assignedTags)) {
      logger.info(`tags: ${tags}, assignedTags: ${assignedTags}`);
      return;
    }

    const assignedTagsIds = Object.keys(assignedTags);

    const newTags = modifiedTagsList.filter(tagId => !tags[tagId]);
    const notAssignedTags = modifiedTagsList.filter(
      tagId => tags[tagId] && !!!assignedTags[tagId],
    );
    const removedTagsIds = assignedTagsIds.filter(
      assignedTag => !modifiedTagsList.includes(assignedTag),
    );
    let newTagsRes: {
      tags: ITag[];
    };
    const addedTagsIds = [...notAssignedTags, ...includeTagInAllAppsList];
    if (newTags.length) {
      newTagsRes = await addNewTags(newTags);
      newTagsRes.tags.forEach(t => {
        const { tags } = TagsStore;
        if (tags) {
          tags[t.tagId] = t;
        }
      });
      addedTagsIds.push(...newTagsRes.tags.map(t => t.tagId));
    }

    const res = await tagsService.modifyAppsTags.execute({
      addedTagsIds,
      removedTagsIds,
      appIds,
    });

    if (isNull(res) || !res.modifyAppsTags.acknowledge) {
      throw Error('Invalid Response');
    }
    openSnackbar('Applications tags changes saved successfully!', {
      variant: 'success',
    });
    return {
      addedTagsIds,
      removedTagsIds,
    };
  } catch (error) {
    logger.error(error);
    openSnackbar(
      'An error encountered while saving your changes. Please try again later...',
      {
        variant: 'error',
      },
    );
  } finally {
    setLoadingSubmit(false);
  }
};
