import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  IssueSeverityResponse,
  UpdateIssueSeverityInput,
} from '../../types/issues-types';
import mutation from './update-issue-severity.gql';

const updateIssueSeverity = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      updateSeverity: UpdateIssueSeverityInput,
    ): Promise<IssueSeverityResponse> => {
      try {
        const results = await client.mutate<UpdateIssueSeverityResponse>({
          mutation,
          variables: { input: updateSeverity },
        });
        if (results?.data) return results.data.updateIssueSeverity;
      } catch (e) {
        logger.error('Faild to change severity to issue', e);
      }
      return { updateIssueSeverity: false };
    },
  };
};

interface UpdateIssueSeverityResponse {
  updateIssueSeverity: IssueSeverityResponse;
}

export default updateIssueSeverity;
