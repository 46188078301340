import { Theme, Typography, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';
import { PanelContainer } from './PanelContainer';

export const SimpleTitlePanel: FC<PropsWithChildren<SimpleTitlePanelProps>> = ({
  text,
  children,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <PanelContainer p={2} className={classes.panelContainer}>
      <Typography
        className={classes.title}
        component='div'
        fontWeight={400}
        fontSize={theme.typography.h1.fontSize}
        color='text.primary'>
        {text}
      </Typography>
      {children}
    </PanelContainer>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  panelContainer: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'space-around',
  },
  title: {
    whiteSpace: 'nowrap',
    paddingInlineEnd: theme.spacing(2),
  },
}));

export interface SimpleTitlePanelProps {
  text: string;
}
