import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  ISetUserDisclaimerInput,
  IUserDisclaimer,
} from '@oxappsec/ox-consolidated-gpt-types';
import { logger } from 'logging-utils';
import { Nullable, ServiceExecute } from 'ox-common-types';
import mutation from './set-user-disclaimer.gql';

const setUserDisclaimer = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  ISetUserDisclaimerInput,
  Nullable<SetUserDisclaimerOutput['setUserDisclaimer']>
> => {
  return {
    execute: async (setUserDisclaimerInput: ISetUserDisclaimerInput) => {
      try {
        const results = await client.mutate<SetUserDisclaimerOutput>({
          mutation,
          variables: { input: setUserDisclaimerInput },
        });
        if (!results.data) return null;
        return results.data.setUserDisclaimer;
      } catch (error) {
        logger.error(
          `Got an error trying to set GPT do not show disclaimer: ${error}`,
        );
        return null;
      }
    },
  };
};

export interface SetUserDisclaimerOutput {
  setUserDisclaimer: IUserDisclaimer;
}

export default setUserDisclaimer;
