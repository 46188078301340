import { ApolloError } from '@apollo/client';
import { AppEvents } from 'common-events';
import { isValidOrgName, sanitizeOrgDisplayNameInput } from 'common-utils';
import { logger } from 'logging-utils';
import { snapshot } from 'valtio';
import { deleteDemoOrg } from '../../settings/actions/org-settings-actions';
import { Organization } from '../organization-types';
import registration from '../services';
import {
  setIsLoading,
  setMessage,
  setOrgCreatedSuccessfully,
  setRemoveDemoOrg,
} from '../store-actions/create-org-store-actions';
import CreateOrganizationStore from '../stores/create-organization-store';
import { addOrganization } from './organizations-actions';

const FAILED_TO_CREATE_ORG_DEFAULT_ERROR =
  'Error: failed to create organization';

export const createNewOrganization = async (orgDisplayName: string) => {
  const isValidDisplayName = isValidOrgName(orgDisplayName);
  const { removeDemoOrg } = snapshot(CreateOrganizationStore);
  if (!isValidDisplayName) {
    orgDisplayName = sanitizeOrgDisplayNameInput(orgDisplayName);
  }
  try {
    setIsLoading(true);
    const organization = await registration.createOrganization.execute(
      orgDisplayName,
    );
    setIsLoading(false);

    if (!organization) {
      throw new Error('failed to create org');
    }

    if (removeDemoOrg) {
      await deleteDemoOrg();
      setRemoveDemoOrg(false);
    }
    addOrganization(organization);
    setOrgCreatedSuccessfully(true);

    document.dispatchEvent(
      new CustomEvent(AppEvents.Organizations.NewOrgCreated, {
        detail: (organization as Organization).id,
      }),
    );

    setMessage(
      `Successfully created ${organization.display_name} organization!`,
    );

    return organization;
  } catch (error) {
    logger.error(`failed to create org`, error);
    setIsLoading(false);
    if (error instanceof ApolloError) {
      const errorMsg =
        `Error: ${error.graphQLErrors?.[0]?.message}` ||
        FAILED_TO_CREATE_ORG_DEFAULT_ERROR;
      setMessage(errorMsg);
      return null;
    }

    setOrgCreatedSuccessfully(false);
    setMessage(FAILED_TO_CREATE_ORG_DEFAULT_ERROR);
    return null;
  } finally {
    setRemoveDemoOrg(false);
  }
};
