import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import { IFetchProfilesOverviewRes } from '../../types/policy-profile-types';
import query from './fetch-active-profile-overview-service.gql';

const fetchActiveProfileOverviewService = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<void, IFetchProfilesOverviewRes> => {
  return {
    execute: async (): Promise<IFetchProfilesOverviewRes> => {
      const results = await client.query<{
        getActiveProfileOverview: IFetchProfilesOverviewRes;
      }>({
        query,
      });

      if (results?.data?.getActiveProfileOverview) {
        return results.data.getActiveProfileOverview;
      }

      throw new Error('Failed to retrieve active profiles overview');
    },
  };
};

export default fetchActiveProfileOverviewService;
