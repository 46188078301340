import { Theme, Typography } from '@mui/material';
import { Nullable } from 'ox-common-types';
import { CustomLink, DateFormat, OwnerCell } from 'ox-react-components';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { PullRequest } from '../../../../quick-fix/types/quick-fix-types';

const IssueCardPRApplied: FC<
  React.PropsWithChildren<IssueCardPRAppliedProps>
> = ({ prDeatils }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <div>
        <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
          PR Info:
        </Typography>
        <Typography variant='body2' color='text.primary' mb={1} mt={2}>
          {prDeatils?.prBody}
        </Typography>
        <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
          Repository:
        </Typography>
        <Typography variant='body2' color='text.primary'>
          {prDeatils?.repo}
        </Typography>
        <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
          Branch Name:
        </Typography>
        <div className={classes.pr}>
          <Typography variant='body2' color='text.primary'>
            {prDeatils?.prBranchName}
          </Typography>
          {prDeatils?.prURL && <CustomLink value={''} href={prDeatils.prURL} />}
        </div>
      </div>
      <div>
        <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
          Commiter:
        </Typography>
        {prDeatils?.commiter ? (
          <OwnerCell owners={[prDeatils?.commiter]} />
        ) : (
          <Typography variant='body2' color='text.primary'>
            N/A
          </Typography>
        )}
        {prDeatils?.commitMessage && (
          <div>
            <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
              Commit:
            </Typography>
            <Typography variant='body2' color='text.primary'>
              {prDeatils.commitMessage}
            </Typography>
          </div>
        )}
        {prDeatils?.comment && (
          <div>
            <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
              Comment:
            </Typography>
            <Typography variant='body2' color='text.primary'>
              {prDeatils.comment}
            </Typography>
          </div>
        )}
      </div>
      <div>
        {prDeatils?.date && (
          <div>
            <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
              Date:
            </Typography>
            <DateFormat date={new Date(prDeatils.date)} color='text.primary' />
          </div>
        )}
        {prDeatils?.prStatus && (
          <div>
            <Typography variant='body2' color='text.secondary' mb={1} mt={2}>
              Status:
            </Typography>
            <Typography variant='body2' color='text.primary'>
              {prDeatils.prStatus}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  pr: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(15),
  },
}));

export interface IssueCardPRAppliedProps {
  prDeatils: Nullable<PullRequest>;
}

export default IssueCardPRApplied;
