import { FC, useCallback, useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Button, Theme, useTheme } from '@mui/material';
import ReactFlow, {
  EdgeTypes,
  Panel,
  ReactFlowInstance,
  Controls,
} from 'reactflow';
import Node from '../components/Node';
import FloatingEdge from '../components/FloatingEdge';
import FloatingConnectionLine from '../components/FloatingConnectionLine';
import {
  clearAttackPathStore,
  loadIssueGraph,
  onNodeMouseEnter,
  onNodeMouseLeave,
  toggleFullMode,
} from '../actions/issue-graph-actions';
import useIssueGraphStore from '../stores/issue-graph-store';
import { ModalDialog } from 'ox-react-components';
import useIssuesActions from '../../issues/common/hooks/use-issues-actions';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../issues/active-issues/stores/issues-store';
import CollapseText from '../components/CollapseText';
import GroupNode from '../components/SpecialNode';
import { AppSpinner } from 'common-components';
import GroupedNode from '../components/AggregatedNode';
import ArrowStepEdge from '../components/ArrowStepEdge';
import useAttackPathGraphStore from '../stores/attack-path-store';
import useCommonGraphStore from '../stores/common-graph-store';
import StepEdge from '../components/StepEdge';
import IssueActionsV2 from '../../issues/common/components/IssueCard/IssueActionsV2';
import ExpandNode from '../components/ExpandNode';

const nodeTypes = {
  custom: Node,
  groupNode: GroupNode,
  group: GroupedNode,
  Expand: ExpandNode,
};
const edgeTypes: EdgeTypes = {
  Default: FloatingEdge,
  Arrow: ArrowStepEdge,
  Step: StepEdge,
};
const AttackPath: FC<React.PropsWithChildren<AttackPathProps>> = ({
  issueId,
  issueTitle,
  aggType,
  recommendation,
  description,
}) => {
  const theme = useTheme();
  const actions = useIssuesActions();
  const { selectedIssue } = useSnapshot(IssuesStore);
  const { classes } = useStyles();
  const { nodes, edges } = useIssueGraphStore();

  const { fullMode, loading } = useCommonGraphStore();
  const { nodes: attackPathNodes, edges: attackPathEdges } =
    useAttackPathGraphStore();

  useEffect(() => {
    loadIssueGraph(issueId).then(() => {
      setTimeout(() => {
        reactflowInstance.current?.fitView();
      }, 100);
    });
    useCommonGraphStore.setState({ aggType });

    return clearAttackPathStore();
  }, [aggType, issueId]);

  const fullModeClick = useCallback(() => {
    toggleFullMode();
    setTimeout(() => {
      reactflowInstance.current?.fitView();
    }, 100);
  }, []);

  const reactflowInstance = useRef<ReactFlowInstance | null>(null);
  const onInit = useCallback((instance: ReactFlowInstance) => {
    reactflowInstance.current = instance;
  }, []);

  const Graph = (
    <ReactFlow
      onNodeMouseEnter={onNodeMouseEnter}
      onNodeMouseLeave={onNodeMouseLeave}
      onInit={onInit}
      proOptions={{ hideAttribution: true }}
      minZoom={0.1}
      nodeTypes={nodeTypes}
      disableKeyboardA11y
      nodes={[...nodes, ...attackPathNodes]}
      style={{
        backgroundColor:
          theme.palette.mode === 'light'
            ? '#edeef4'
            : theme.palette.background.paper,
        padding: 0,
        margin: 0,
      }}
      edges={[...edges, ...attackPathEdges]}
      edgeTypes={edgeTypes}
      connectionLineComponent={FloatingConnectionLine}
      fitView>
      <Controls position='bottom-right' />
      <Panel
        position='top-left'
        style={{
          gap: 4,
          maxWidth: '20%',
          display: 'inline-flex',
          flexDirection: 'column',
          maxHeight: 'calc(100% - 30px)',
          overflow: 'auto',
          padding: 8,
        }}>
        <CollapseText withCollapse={false} header='Issue Name'>
          <div style={{ fontSize: 10 }}>{issueTitle}</div>
        </CollapseText>
        <CollapseText withCollapse header='Description'>
          <div
            style={{ fontSize: 10 }}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </CollapseText>

        <CollapseText withCollapse header='Recommendation'>
          <div
            style={{ fontSize: 10 }}
            dangerouslySetInnerHTML={{ __html: recommendation }}
          />
        </CollapseText>
      </Panel>

      <Panel position='top-right'>
        <Box display='flex' flexDirection='row' gap={1}>
          {fullMode && (
            <IssueActionsV2
              issueId={issueId}
              tickets={selectedIssue?.tickets}
              actions={actions}
            />
          )}
          <Button variant='text' onClick={fullModeClick}>
            {fullMode ? 'Close ' : ''} Full Screen
          </Button>
        </Box>
      </Panel>
    </ReactFlow>
  );
  return loading ? (
    <AppSpinner text='Loading Attack Path...' />
  ) : (
    <>
      {Graph}
      <ModalDialog
        hideHeader
        onClose={fullModeClick}
        contentClassName={classes.dialogContainer}
        sx={{ padding: 0, margin: 0 }}
        fullScreen
        open={fullMode}>
        {Graph}
      </ModalDialog>
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    padding: 0,
    margin: 0,
  },
}));

export interface AttackPathProps {
  issueId: string;
  issueTitle: string;
  aggType: string;
  recommendation: string;
  description: string;
}

export default AttackPath;
