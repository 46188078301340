import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchIssuesInput,
  FetchIssuesResponse,
} from '../../types/issues-types';
import query from './get-issues-filters.gql';

const fetchIssuesFilters = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getIssuesInput: FetchIssuesInput,
      cache = true,
      isDemo: boolean = false,
    ) => {
      try {
        const results = await client.query<FetchIssuesResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NoCache,
          variables: { getIssuesInput: getIssuesInput, isDemo: isDemo },
        });
        if (!results.data) return null;
        return results.data.getIssuesFilters;
      } catch (e) {
        logger.error('Failed to fetch issues filters', e);
        return null;
      }
    },
  };
};

export default fetchIssuesFilters;
