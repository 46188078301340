import { reportClient } from 'api-clients';
import getArtifacts from '../services/get-artifacts/get-artifacts';
import getArtifactsFilters from './get-artifacts-filters/get-artifacts-filters';
import getArtifactsTopFilters from './get-artifacts-top-filters/get-artifacts-top-filters';
import getSingleArtifact from './get-single-artifact/get-single-artifact';
import getArtifactVulnerabilities from './get-artifact-vulnerabilities/get-artifact-vulnerabilities';
import getArtifactMisconfigurations from './get-artifact-missconfigurations/get-artifact-missconfigurations';
import getArtifactsFiltersLazy from './get-artifacts-filters-lazy/get-artifacts-filters-lazy';

const artifactsService = {
  getArtifacts: getArtifacts(reportClient),
  getSingleArtifact: getSingleArtifact(reportClient),
  getArtifactsFilters: getArtifactsFilters(reportClient),
  getArtifactsTopFilters: getArtifactsTopFilters(reportClient),
  getArtifactVulnerabilities: getArtifactVulnerabilities(reportClient),
  getArtifactMisconfigurations: getArtifactMisconfigurations(reportClient),
  getArtifactsFiltersLazy: getArtifactsFiltersLazy(reportClient),
};
export default artifactsService;
