import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import {
  DependencyEdge,
  DependencyNode,
  IssueDrawerTabProps,
} from '../../../../../types/issues-types';

import ScaDependencyGraph from './ScaDependencyGraph';
import { Nullable } from 'ox-common-types';

const DependencyGraphTabContainer: FC<
  React.PropsWithChildren<IssueDrawerTabProps>
> = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);
  const dependencyGraph = selectedIssue?.dependencyGraph as Nullable<{
    nodes: DependencyNode[];
    edges: DependencyEdge[];
    allNodes: DependencyNode[];
    allEdges: DependencyEdge[];
  }>;
  return (
    <>
      {selectedIssue && (
        <ScaDependencyGraph
          appId={selectedIssue.app.id}
          dependencyGraph={dependencyGraph}
        />
      )}
    </>
  );
};

export default DependencyGraphTabContainer;
