import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchIssueInput,
  FetchResolvedSingleIssueResponse,
} from '../../../common/types/issues-types';
import query from './get-resolved-issue.gql';

const fetchIssue = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getSingleIssueInput: FetchIssueInput) => {
      const results = await client.query<FetchResolvedSingleIssueResponse>({
        query,
        fetchPolicy: ApolloClientCache.CacheFirst,
        variables: { getSingleIssueInput },
      });
      if (!results.data) return null;
      return results.data.getResolvedIssue;
    },
  };
};

export default fetchIssue;
