import { IconButton, Theme, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { IoCloseCircle } from 'react-icons/io5';
import { Handle, NodeProps, Position } from 'reactflow';
import { closeAggregatedNode } from '../utils/attack-path-utils';
import Node from './Node';
import { FaArrowRightLong } from 'react-icons/fa6';
import { makeStyles } from 'tss-react/mui';
import { ScrollDownIndicator } from 'ox-react-components';

const GroupedNode: FC<React.PropsWithChildren<NodeProps>> = ({ data }) => {
  let { Icon, rows } = data;

  const [showScrollIndicator, setShowScrollIndicator] = useState(
    rows.length > 2,
  );

  const handleScroll = useCallback(() => {
    setShowScrollIndicator(false);
  }, []);

  const { classes, cx } = useStyles();
  return (
    <>
      <Handle
        type='target'
        position={Position.Left}
        style={{
          visibility: 'hidden',
        }}
      />
      <Handle
        type='source'
        position={Position.Right}
        style={{
          visibility: 'hidden',
        }}
      />
      <div className={cx('nowheel', classes.content)}>
        <div className={classes.container} onScroll={handleScroll}>
          {showScrollIndicator && (
            <ScrollDownIndicator className={classes.scrollIndicator} />
          )}
          <IconButton
            size={'small'}
            style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
            onClick={() => closeAggregatedNode(data.parent)}>
            <IoCloseCircle />
          </IconButton>
          <div className={classes.title}>
            {Icon && <Icon width={12} height={12} />}
            <Typography fontSize={10} color='text.secondary'>
              {data.name}
            </Typography>
          </div>
          <div className={classes.content} onScroll={handleScroll}>
            {rows.map((row, index) => {
              return (
                <div className={classes.row}>
                  {row.map((node, index) => {
                    return (
                      <>
                        <Node {...(node as NodeProps)} />
                        {index !== row.length - 1 && (
                          <FaArrowRightLong style={{ width: 15 }} />
                        )}
                      </>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    paddingTop: theme.spacing(2),
  },
  scrollIndicator: {
    position: 'absolute',
    bottom: -10,
    right: -10,
    transform: 'scale(0.5)',
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    position: 'absolute',
    top: 5,
    left: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    height: '100%',
    overflowY: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface GroupedNodeProps {}

export default GroupedNode;
