import { init, identify, event } from '@fullstory/browser';
import { OXCustomEvent, OXFullStoryEventNames } from './types';
import { addFullstoryCustomFieldSuffix } from './fullstory-utils';
import { isDevelopment } from 'env-utils';

export const initFullStory = () => {
  init({
    orgId: 'o-1G54HD-na1',
  });
};

export const setFullStoryUserContext = (user: FullStoryUser) => {
  identify(user.id, {
    displayName: user.name,
    email: user.email,
    orgId: user?.org_id,
  });
};

export const sendFSCustomEvent = (
  eventName: OXFullStoryEventNames,
  eventProps: OXCustomEvent,
) => {
  if (!isDevelopment()) {
    const fsEventProps = addFullstoryCustomFieldSuffix(eventProps);
    event(eventName, fsEventProps);
  }
};

export interface FullStoryUser {
  id: string;
  email: string;
  name: string;
  [key: string]: unknown;
}
