import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import {
  ConnectorEnableDisableResponse,
  DisableConnectorInput,
} from '../../connectors-types';
import mutation from './disable-connector.gql';

const disableConnector = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<DisableConnectorInput, ConnectorEnableDisableResponse> => {
  return {
    execute: async (
      connectorDisableInput: DisableConnectorInput,
    ): Promise<ConnectorEnableDisableResponse> => {
      const results = await client.mutate<DisableConnectorResponse>({
        mutation,
        variables: { connectorDisableInput },
      });

      if (results?.data?.disableConnector) {
        return results.data.disableConnector.connector;
      }

      throw new Error('Failed to disable connector');
    },
  };
};

interface DisableConnectorResponse {
  disableConnector: { connector: ConnectorEnableDisableResponse };
}

export default disableConnector;
