import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchIssuesInput,
  FetchResolvedIssuesFiltersResponse,
} from '../../../common/types/issues-types';
import query from './get-resolved-issues-filters.gql';

const fetchResolvedIssuesFilters = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getResolvedIssuesInput: FetchIssuesInput,
      cache: boolean = true,
    ) => {
      const results = await client.query<FetchResolvedIssuesFiltersResponse>({
        query,
        fetchPolicy: cache
          ? ApolloClientCache.CacheFirst
          : ApolloClientCache.NoCache,
        variables: { getResolvedIssuesInput },
      });
      if (!results.data) return null;
      return results.data.getResolvedIssuesFilters;
    },
  };
};

export default fetchResolvedIssuesFilters;
