import { Box, Theme } from '@mui/material';
import { BoxProps } from '@mui/system';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

export const Panel: FC<React.PropsWithChildren<PanelProps>> = ({
  children,
  ...rest
}) => {
  const { classes, cx } = useStyles();
  const { className, ...props } = rest;

  return (
    <Box
      borderRadius='10px'
      className={cx(classes.panel, className)}
      data-testid='panel-component'
      {...props}>
      {children}
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  panel: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
}));

export type PanelProps = BoxProps;
