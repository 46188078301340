import { Nullable } from 'ox-common-types';
import SlackStore from '../store/slack-store';
import {
  Channel,
  SlackForm,
  GetSlackUsersAndChannelList,
} from '../types/slack-types';

export const setIsLoadingSlackChannels = (isLoading: boolean) => {
  SlackStore.isLoadingSlackChannels = isLoading;
};
export const setIsLoadingSlackParams = (isLoading: boolean) => {
  SlackStore.isLoadingSlackParams = isLoading;
};
export const setIsLoadingSlackChannelsFallback = (isLoading: boolean) => {
  SlackStore.isLoadingSlackChannelsFallback = isLoading;
};

export const setLoadingSubmit = (isLoading: boolean) => {
  SlackStore.loadingSubmit = isLoading;
};

export const setChannels = (
  input: GetSlackUsersAndChannelList | undefined,
  withSuggested = true,
) => {
  if (input) {
    SlackStore.publicChannels = [...input.users, ...input.channels];
    if (withSuggested) {
      SlackStore.fields.selectedChannel = input.suggestedChannel;
    }
  }
};
export const setChannelsWorkflowsFromAPI = (
  input: GetSlackUsersAndChannelList | undefined,
) => {
  if (input) {
    SlackStore.publicChannelsWorkflow = [...input.users, ...input.channels];
  }
};
export const setPublicChannelsWorkflows = (input: Nullable<Channel[]>) => {
  if (input) {
    SlackStore.publicChannelsWorkflow = [...input];
  }
};
export const setPublicChannels = (input: Nullable<Channel[]>) => {
  if (input) {
    SlackStore.publicChannels = [...input];
  }
};
export const setFallbackChannels = (input: Nullable<Channel[]>) => {
  if (input) {
    SlackStore.publicFallbackChannels = [...input];
  }
};
export const setFallbackChannelsFromAPI = (
  input: GetSlackUsersAndChannelList | undefined,
  withSuggested = true,
) => {
  if (input) {
    SlackStore.publicFallbackChannels = [...input.users, ...input.channels];
    if (withSuggested) {
      SlackStore.fields.selectedFallbackChannel = input.suggestedChannel;
    }
  }
};

export const resetFields = () => {
  SlackStore.fields = {
    selectedChannel: null,
    selectedWorkflowChannel: null,
    userComment: '',
  };
};

export const setSelectedChannel = (selectedChannel: Nullable<Channel>) => {
  SlackStore.fields.selectedChannel = selectedChannel;
};
export const setSelectedWorkflowChannel = (
  selectedChannel: Nullable<Channel>,
) => {
  SlackStore.fields.selectedWorkflowChannel = selectedChannel;
};
export const setSelectedFallbackChannel = (
  selectedFallbackChannel: Nullable<Channel>,
) => {
  SlackStore.fields.selectedFallbackChannel = selectedFallbackChannel;
};

export const setUserComment = (userComment: string) => {
  SlackStore.fields.userComment = userComment;
};

export const setForm = (fields: SlackForm) => {
  SlackStore.fields.selectedWorkflowChannel = fields.selectedChannel;
  SlackStore.fields.userComment = fields.userComment;
  SlackStore.fields.selectedFallbackChannel = fields.selectedFallbackChannel;
};
