import { CellIndex, DimType } from '../types/oscar-hover-types';

export const createSoftDimmedColumnsArray = (
  hoverCell: CellIndex,
  columnsLength: number,
) => {
  return Array.from(new Array(columnsLength))
    .map((_, index) => index)
    .filter(
      index => index < hoverCell.column - 1 || index > hoverCell.column + 1,
    );
};

export const createHardDimmedColumnsArray = (hoverCell: CellIndex) => {
  return [hoverCell.column - 1, hoverCell.column + 1];
};

export const resolveColumnDimType = (
  column: number,
  hardDimmedColumnIndexes: number[],
  softDimmedColumnIndexes: number[],
): DimType | undefined => {
  if (hardDimmedColumnIndexes.includes(column)) {
    return DimType.Hard;
  }
  if (softDimmedColumnIndexes.includes(column)) {
    return DimType.Soft;
  }
  return undefined;
};
