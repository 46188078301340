import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';

const JiraTicketLinkageStore = createStore<JiraTicketLinkageState>(
  {
    loadingFindJiraTicket: false,
    loadingSubmitLinkIssue: false,
    loadingUnlinkJiraTickets: false,
    verified: null,
    jiraTicketKey: null,
    summary: null,
  },
  'Jira Ticket Linkage Store',
);

interface JiraTicketLinkageState {
  loadingFindJiraTicket: boolean;
  loadingSubmitLinkIssue: boolean;
  loadingUnlinkJiraTickets: boolean;
  verified: Nullable<boolean>;
  jiraTicketKey: Nullable<string>;
  summary: Nullable<string>;
}

export default JiraTicketLinkageStore;
