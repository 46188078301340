import { FC } from 'react';
import { Typography } from '@mui/material';
import { SeverityColor } from 'severity-utils';
import NodeTooltip from '../NodeTooltip';

const CveItem: FC<React.PropsWithChildren<CveItemProps>> = ({
  id,
  cvss,
  originalSeverity,
}) => {
  return (
    <NodeTooltip title='CVE Details'>
      <Typography variant='caption' color='text.primary'>
        CVE ID: <b>{id}</b>
      </Typography>
      {cvss && (
        <Typography variant='caption'>
          CVSS:{' '}
          <SeverityColor severity={originalSeverity}>
            {color => (
              <span
                style={{
                  color,

                  fontWeight: 600,
                }}>
                {cvss}
              </span>
            )}
          </SeverityColor>
        </Typography>
      )}
      {originalSeverity && (
        <Typography variant='caption'>
          Severity:{' '}
          <SeverityColor severity={originalSeverity}>
            {color => (
              <span
                style={{
                  color,
                  fontWeight: 600,
                }}>
                {originalSeverity}
              </span>
            )}
          </SeverityColor>
        </Typography>
      )}
    </NodeTooltip>
  );
};

export interface CveItemProps {
  id: string;
  cvss: string;
  originalSeverity: string;
}

export { CveItem };
