export enum CodeRepos {
  GitHub = 'GitHub',
  GitLab = 'GitLab',
  AzureCode = 'Azure',
  Bitbucket = 'Bitbucket',
}

export const CodeReposIdsMap = new Map([
  ['1', CodeRepos.GitHub],
  ['2', CodeRepos.GitLab],
  ['3', CodeRepos.Bitbucket],
  ['12', CodeRepos.AzureCode],
]);

export enum OnboardingSteps {
  Login = 'Login',
  Connect = 'Connect',
  ChooseRepos = 'ChooseRepos',
  Discovery = 'Discovery',
}

export type RepoNode = {
  id: string;
  displayId: string;
  name: string;
  isMonitored: boolean;
  resourceType: NodeTypes;
  sourceType?: string;
  iconName?: ResourceIcon;
  children: RepoNode[];
  disabled?: boolean;
  details?: string;
};

export enum NodeTypes {
  Node = 'node',
  Edge = 'edge',
}

export interface FlattenedItem {
  deepness: number;
  parents: string[];
  isIndeterminate: boolean;
  expanded: boolean;
  selected: boolean;
  displayId: string;
  id: string;
  children: RepoNode[];
  name: string;
  disabled?: boolean;
  details?: string;
  sourceType?: string;
  iconName?: ResourceIcon;
}

export enum ResourceIcon {
  branch = 'Branch',
  project = 'Project',
  organization = 'Organization',
  application = 'Application',
  accountId = 'AccountId',
  repo = 'Repository',
}

export interface ControlObject {
  [key: string]: {
    expanded: boolean;
    selected: boolean;
    nodeType: NodeTypes;
    disabled?: boolean;
  };
}

export enum ConnectionType {
  IDP = 'IDP',
  GitHubApp = 'GitHubApp',
  ManualCreds = 'Manual',
  BitbucketApp = 'BitbucketApp',
}
