import { Theme, Tooltip, Typography } from '@mui/material';
import { CustomLink } from 'ox-react-components';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CweList } from '../../types/issues-types';

const IssueCweList: FC<React.PropsWithChildren<IssueCweListProps>> = ({
  items,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      {items.map(item => {
        return (
          <Tooltip
            key={item.name}
            title={
              <Typography color='text.primary' variant='body2'>
                {item.description}
              </Typography>
            }
            arrow
            placement='top'>
            <div className={classes.item}>
              <CustomLink value={item.name} href={item.url} />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
  },
  item: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1.5),
  },
}));

export interface IssueCweListProps {
  items: CweList[];
}

export default IssueCweList;
