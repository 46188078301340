import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Theme, Typography } from '@mui/material';
import CategoryIconsMap from '@oxappsec/ox-consolidated-categories/lib/src/ox-categories/icons';
import { CategoryDisplayName } from '@oxappsec/ox-consolidated-categories';

const IssueCardCategory: FC<
  React.PropsWithChildren<IssueCardCategoryProps>
> = ({ category }) => {
  const { classes } = useStyles();
  const CategoryIcon = CategoryIconsMap.get(category as CategoryDisplayName);
  return (
    <Box display='flex' flexDirection='row' gap={1} alignItems='flex-start'>
      {CategoryIcon && <CategoryIcon className={classes.icon} />}
      <Typography sx={{ fontSize: '0.875rem' }} color='text.secondary'>
        {category}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    flexShrink: 0,
    fontSize: 16,
    marginTop: 3,
    color: theme.palette.primary.main,
  },
}));

export interface IssueCardCategoryProps {
  category?: string;
}

export default IssueCardCategory;
