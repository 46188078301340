import { Nullable } from 'ox-common-types';
import {
  ConditionalFiltersCondition,
  FilterDetails,
  FilterTypeToFilter,
  shouldVirtualize,
} from 'ox-filter-utils';
import { FC, ReactElement } from 'react';
import { FixedSizeList as List } from 'react-window';
import { makeStyles } from 'tss-react/mui';
import { FilterItem } from '../FilterItem/FilterItem';
import { FilterItemStatistics } from '../FilterItem/FilterItemStatistics';
import { VirtualFilterItem } from './VirtualFilterItem';
import { ConditionalFilter } from 'ox-filter-utils';

export const FilterFormItem: FC<
  React.PropsWithChildren<FilterFormItemProps>
> = ({
  filterBy,
  selected,
  onFilterChange,
  filterTypeToFilterBy,
  itemHeight,
  maxHeight,
  disabled,
  isStatistic,
  loadingFilters,
  conditionalFilters,
  additionalFilters,
  isShowExcludeFilterCheckbox,
}) => {
  const { classes } = useStyles();
  let content: Nullable<ReactElement | ReactElement[]> = null;
  const virtualize = shouldVirtualize({
    itemHeight: itemHeight,
    itemsCount: filterBy.length,
    maxHeight: maxHeight,
  });

  const findConditionalFiltersById = (
    id: string,
    condition: ConditionalFiltersCondition,
  ) =>
    conditionalFilters?.find(
      f =>
        f.fieldName === filterTypeToFilterBy &&
        f.condition === condition &&
        f.values.includes(id),
    );

  if (virtualize) {
    content = (
      <List
        height={maxHeight}
        itemCount={filterBy.length}
        itemSize={itemHeight}
        width='100%'
        itemData={filterBy}>
        {props => {
          const filterId = filterBy[props.index]?.id;
          const isConditionalFilterChecked = Boolean(
            findConditionalFiltersById(
              filterId,
              ConditionalFiltersCondition.OR,
            ),
          );
          const excludeFilterChecked = Boolean(
            findConditionalFiltersById(
              filterId,
              ConditionalFiltersCondition.NOT,
            ),
          );
          const checked =
            isConditionalFilterChecked ||
            !!selected?.includes(filterBy[props.index].id);
          return VirtualFilterItem({
            ...props,
            onFilterChange,
            filterTypeToFilterBy,
            checked,
            itemHeight,
            disabled,
            isStatistic,
            additionalFilters,
            loadingFilter: loadingFilters,
            conditionalFilters,
            excludeFilterChecked,
            isShowExcludeFilterCheckbox,
          });
        }}
      </List>
    );
  } else {
    content = filterBy.map((filter, index) => {
      const filterId = filter.id;
      const isORConditionalFilterChecked = Boolean(
        findConditionalFiltersById(filterId, ConditionalFiltersCondition.OR),
      );
      const isExcludeConditionalFilterChecked = Boolean(
        findConditionalFiltersById(filterId, ConditionalFiltersCondition.NOT),
      );
      const checked =
        isORConditionalFilterChecked || !!selected?.includes(filter.id);
      if (isStatistic) {
        return (
          <FilterItemStatistics
            key={filter.name + index}
            name={filter.name}
            percent={filter.percent}
            color={filter.color}
            count={filter.count}
            categoryIcon={filter.categoryIcon}
            filterTypeToFilterBy={filterTypeToFilterBy}
            splitLabel={filter.splitLabel}
            itemHeight={itemHeight}
            id={filter.id}
            isStatistic={isStatistic}
            changeNumber={filter.changeNumber}
            additionalFilters={additionalFilters}
          />
        );
      } else {
        return (
          <FilterItem
            checked={checked}
            key={filter.name + index}
            name={filter.name}
            percent={filter.percent}
            color={filter.color}
            count={filter.count}
            categoryIcon={filter.categoryIcon}
            onFilterChange={onFilterChange}
            filterTypeToFilterBy={filterTypeToFilterBy}
            splitLabel={filter.splitLabel}
            itemHeight={itemHeight}
            id={filter.id}
            changeNumber={filter.changeNumber}
            loadingFilters={!!loadingFilters}
            excludeFilterChecked={isExcludeConditionalFilterChecked}
            isShowExcludeFilterCheckbox={isShowExcludeFilterCheckbox}
          />
        );
      }
    });
  }

  return (
    <div
      className={classes.filterFormItem}
      data-testid='filter-form'
      style={{ maxHeight }}>
      {content}
    </div>
  );
};

const useStyles = makeStyles()({
  filterFormItem: {
    overflowY: 'auto',
  },
});

export interface FilterFormItemProps {
  selected?: string[];
  filterBy: FilterDetails[];
  onFilterChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    condition?: ConditionalFiltersCondition,
  ) => void;
  filterTypeToFilterBy?: FilterTypeToFilter;
  itemHeight: number;
  maxHeight: number;
  disabled?: boolean;
  isStatistic?: boolean;
  loadingFilters?: boolean;
  conditionalFilters?: ConditionalFilter[];
  additionalFilters?: {};
  isShowExcludeFilterCheckbox: boolean;
}
