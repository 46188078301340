import { getCategoriesMap } from '@oxappsec/ox-consolidated-categories';
import { FilterType, FilterTypeToFilter } from 'ox-filter-utils';

export const resolveFilterName = (filterName: string) => {
  return filterName === FilterTypeToFilter.IssueNames
    ? FilterType.issueNames
    : filterName === FilterTypeToFilter.Policy
    ? FilterType.policies
    : filterName === FilterTypeToFilter.IssueOwner
    ? FilterType.issueOwners
    : filterName === FilterTypeToFilter.BusinessPriority
    ? FilterType.appBusinessPriority
    : filterName === FilterTypeToFilter.Category
    ? FilterType.category
    : filterName === FilterTypeToFilter.IssueActions
    ? FilterType.issuesActions
    : filterName === FilterTypeToFilter.CVE
    ? FilterType.CVE
    : filterName === FilterTypeToFilter.AppId
    ? FilterType.apps
    : filterName === FilterTypeToFilter.UniqueLibs
    ? FilterType.UniqueLibs
    : filterName === FilterTypeToFilter.FilePaths
    ? FilterType.FilePaths
    : filterName === FilterTypeToFilter.Severity
    ? FilterType.severity
    : filterName === FilterTypeToFilter.PackageInfo
    ? FilterType.packageInfo
    : filterName === FilterTypeToFilter.PackageManager
    ? FilterType.packageManager
    : filterName === FilterTypeToFilter.Tags
    ? FilterType.appsTags
    : filterName === FilterTypeToFilter.EventType
    ? FilterType.eventType
    : (FilterType as Record<string, string>)[filterName];
};

export const getCategoryById = (id: string) => {
  let categoryName: string = '';
  Object.entries(getCategoriesMap()).map(category => {
    if (category[1].id === Number(id)) {
      categoryName = category[0];
    }
    return categoryName;
  });
  return categoryName;
};
