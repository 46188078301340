import {
  Input,
  InputAdornment,
  InputProps,
  LinearProgress,
  useTheme,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { MdClose as CloseIcon, MdSearch as SearchIcon } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

export const OxSearchBox: FC<OxSearchBoxProps> = ({
  value: propValue,
  onChange: propOnChange,
  isLoading,
  dataTestId,
  ...props
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [isOnFocus, setIsOnFocus] = useState(false);

  const getIconColor = () => {
    return props.disabled ? theme.palette.grey[300] : 'inherit';
  };

  const [value, setValue] = useState<string>(propValue || '');
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      propOnChange && propOnChange(event);
    },
    [propOnChange],
  );

  useEffect(() => {
    setValue(propValue || '');
  }, [propValue]);
  return (
    <>
      <Input
        data-testid={dataTestId}
        placeholder={props.placeholder || 'Search'}
        type='search'
        className={classes.input}
        value={value}
        onChange={onChange}
        onFocus={() => setIsOnFocus(true)}
        onBlur={() => setIsOnFocus(false)}
        endAdornment={
          <InputAdornment className={classes.inputAdornment} position='end'>
            {props.defaultValue || value ? (
              <CloseIcon
                color={getIconColor()}
                className={classes.closeAndSearchIcon}
              />
            ) : (
              <SearchIcon
                className={classes.closeAndSearchIcon}
                color={getIconColor()}
              />
            )}
          </InputAdornment>
        }
        {...props}
      />
      {isLoading && isOnFocus && (
        <LinearProgress className={classes.loadingProgress} />
      )}
    </>
  );
};

const useStyles = makeStyles()(() => ({
  input: {
    paddingLeft: 2,
    fontSize: 'inherit',
    width: '100%',
    '& input': {
      '::-webkit-search-cancel-button': {
        cursor: 'pointer',
        transform: 'scale(2)',
        opacity: 0,
        paddingRight: 2,
        marginLeft: 3,
      },
    },
  },

  inputAdornment: {
    marginLeft: -10,
  },

  closeAndSearchIcon: {
    pointerEvents: 'none',
    marginLeft: -3,
  },
  loadingProgress: {
    maxHeight: 2,
    marginTop: -2,
  },
}));

export interface OxSearchBoxProps extends InputProps {
  value?: string;
  isLoading?: boolean;
  dataTestId?: string;
}
