import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { GradualUpdateResponse } from '../../scan-types';
import query from './get-gradual-update.gql';

const getGradualUpdate = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async ({ scanID }: GradualUpdateInput) => {
      try {
        const results = await client.query<{
          getScannedAppsInfo: GradualUpdateResponse;
        }>({
          query,
          variables: { getScanInfoInput: { scanID } },
          fetchPolicy: 'no-cache',
        });

        return results.data.getScannedAppsInfo;
      } catch (error) {
        logger.error('Failed to fetch gradual update info', error);
        // Throwing the error for it to get caught in the Polling class
        throw error;
      }
    },
  };
};

export interface GradualUpdateInput {
  scanID: string;
}
export default getGradualUpdate;
