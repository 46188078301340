import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchIssuesInput,
  FetchPipelineIssuesFiltersResponse,
} from '../../../common/types/issues-types';
import query from './get-pipeline-issues-filters.gql';

const fetchPipelineIssuesFilters = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getCicdIssuesInput: FetchIssuesInput,
      cache: boolean = true,
    ) => {
      const results = await client.query<FetchPipelineIssuesFiltersResponse>({
        query,
        fetchPolicy: cache
          ? ApolloClientCache.CacheFirst
          : ApolloClientCache.NoCache,
        variables: { getCicdIssuesInput },
      });
      if (!results.data) return null;
      return results.data.getCICDIssuesFilters;
    },
  };
};

export default fetchPipelineIssuesFilters;
