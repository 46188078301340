import { memo, useCallback } from 'react';
import { useStore, EdgeProps, getSimpleBezierPath } from 'reactflow';
import { getEdgeParams } from '../utils/edge-utils';
import { AttackPathType } from '../attack-path-types';
import { getEdgeColor } from '../configs/edge-colors-config';
import useCommonGraphStore from '../stores/common-graph-store';

const FloatingEdge = ({ id, source, target, markerEnd, style }: EdgeProps) => {
  const { highlightedNodes } = useCommonGraphStore();
  const sourceNode = useStore(
    useCallback(store => store.nodeInternals.get(source), [source]),
  );
  const targetNode = useStore(
    useCallback(store => store.nodeInternals.get(target), [target]),
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
  );

  const [edgePath] = getSimpleBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <>
      <path
        id={id}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          strokeWidth: 2,
          opacity: highlightedNodes?.length ? 0.1 : 0.7,
          ...(targetNode.type === AttackPathType.Expand && {
            strokeDasharray: '5,5',
          }),
          stroke: getEdgeColor(targetNode.data.type, targetNode.data.metadata),
        }}
      />
    </>
  );
};

export default memo(FloatingEdge);
