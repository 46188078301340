import { FC } from 'react';
import { Button } from '@mui/material';
import { FaArrowRight } from 'react-icons/fa6';
const LearnMoreItem: FC<React.PropsWithChildren<LearnMoreItemProps>> = ({
  title,
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{ textTransform: 'none', height: 25 }}
      endIcon={<FaArrowRight size={10} />}>
      {title}
    </Button>
  );
};
export interface LearnMoreItemProps {
  title: string;
  onClick;
}

export default LearnMoreItem;
