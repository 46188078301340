import { FC, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../../active-issues/stores/issues-store';
import VulnerabilitiesTable from 'ox-react-components/src/Vulnerabilities/VulnerabilitiesTable';
import {
  fetchNotReachableVulnerabilities,
  searchNotReachableVulnerabilities,
  sortNotReachableVulnerabilities,
} from '../actions/vulnerabilities-action';
import NotReachableVulnerabilitiesStore from '../stores/not-reachable-vulnerabilities-store';
import { useInfinityScroll } from 'react-utils';

import { resetNotReachableVulnerabilities } from './store-actions';

const NotReachableVulnerabilitiesTableContainer: FC = () => {
  const { selectedIssueId } = useSnapshot(IssuesStore);
  const { data, loading, contextOptions, orderDirection, orderField } =
    useSnapshot(NotReachableVulnerabilitiesStore);
  const { onScroll } = useInfinityScroll({
    threshold: 0.9,
    load: fetchNotReachableVulnerabilities,
    loading,
  });
  useEffect(() => {
    fetchNotReachableVulnerabilities(false);
    return resetNotReachableVulnerabilities;
  }, [selectedIssueId]);

  return (
    <VulnerabilitiesTable
      rows={data}
      contextOptions={contextOptions}
      onScroll={onScroll}
      onSearch={searchNotReachableVulnerabilities}
      loading={loading}
      onSort={sortNotReachableVulnerabilities}
      orderField={orderField}
      orderDirection={orderDirection}
    />
  );
};

export default NotReachableVulnerabilitiesTableContainer;
