import { AsyncLoadingState, createAsyncState } from 'async-utils';
import { LineChartData } from 'ox-react-components';
import { createStore } from 'store-utils';

interface ResolvedIssuesOverviewStoreState {
  open: boolean;
  mttrDays?: MttrItem[];
  trendInfo?: LineChartData;
  loading: AsyncLoadingState;
}
export interface MttrItem {
  severityKey: string;
  severityLabel: string;
  days?: undefined;
}

const initialState: ResolvedIssuesOverviewStoreState = {
  open: true,
  mttrDays: undefined,
  trendInfo: undefined,
  loading: createAsyncState(),
};

export const ResolvedIssuesOverviewStore = createStore(
  initialState,
  'Resolved Issues Overview Store',
);
