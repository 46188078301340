import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache, Nullable, Order } from 'ox-common-types';
import { IrrelevantAppsResponse } from '../../irrelevant-apps-types';
import query from './get-irrelevant-apps-results.gql';

const getIrrelevantAppsResult = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getApplicationsInput: GetApplicationsInput,
    ): Promise<Nullable<IrrelevantAppsResponse>> => {
      const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
      const results = await client.query<{
        getApplications: IrrelevantAppsResponse;
      }>({
        query,
        fetchPolicy,
        variables: { getApplicationsInput },
      });
      if (!results.data.getApplications) return null;
      return results.data.getApplications;
    },
  };
};

export interface GetApplicationsInput {
  applicationFilters?: ApplicationsFilters[];
  irrelevancyFilters?: string[];
  orderBy?: {
    direction: Order;
    field: string;
  };
  limit?: number;
  offset?: number;
  filters?: {};
  search?: string;
}

/** for filters */
export enum ApplicationsFilters {
  New = 'new',
  InDevelopment = 'updated',
  DeployedProd = 'deployedProd',
  ExternallyFacing = 'publicVisibility',
  Relevant = 'relevant',
  Irrelevant = 'Irrelevant',
}

/** filters for irrelevancy reasons */
export enum IrrelevantReason {
  Archived = 'Archived Repo',
  FailedClone = 'No Access for Cloning',
  NoFiles = 'No Relevant Files',
  LastCodeChange = 'No Code Changes in 6 Months',
  SetByClient = 'Repository has been set by client to be irrelevant',
}

export default getIrrelevantAppsResult;
