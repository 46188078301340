import { Button, ButtonProps, Theme, useTheme } from '@mui/material';
import { Nullable, OneOfValues, Trend } from 'ox-common-types';
import { FC, ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as DecreaseIcon } from './decrease.svg';
import { ReactComponent as IncreaseIcon } from './increase.svg';

export const StatButton: FC<React.PropsWithChildren<StatButtonProps>> = ({
  active,
  disabled,
  count,
  label,
  delta,
  trend,
  ...buttonProps
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const signText = signTextMap[Math.sign(delta)];
  const deltaText = delta || '';
  const shouldShowTrend = delta !== 0;
  let trendIcon: Nullable<ReactElement> = null;

  if (shouldShowTrend && trend === Trend.Positive) {
    trendIcon = (
      <IncreaseIcon className={classes.increase} style={{ width: '1rem' }} />
    );
  } else if (shouldShowTrend && trend === Trend.Negative) {
    trendIcon = (
      <DecreaseIcon className={classes.decrease} style={{ width: '1rem' }} />
    );
  }

  return (
    <Button
      disabled={disabled}
      color='secondary'
      variant='contained'
      classes={{
        root: cx([
          classes.stat,
          { [classes.active]: active },
          { [classes.disabled]: disabled },
        ]),
      }}
      sx={{
        textTransform: 'none',
        py: 0,
        px: 1,
        color: 'text.primary',
        fontSize: theme.typography.body1.fontSize,
      }}
      {...buttonProps}>
      {count} {label}&nbsp; {signText}
      {deltaText}
      {shouldShowTrend && trendIcon}
    </Button>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  stat: {
    borderRadius: '4px',
    border: `1px solid ${theme.palette.statButton.stat.border} !important`,
    boxShadow: `${theme.shadows[2]} !important`,

    background: `${theme.palette.background.paper} !important`,
    '&:hover': {
      boxShadow: `${theme.shadows[3]} !important`,
      background: `${theme.palette.statButton.hover.background} !important`,
    },
  },
  increase: {
    color: `${theme.palette.statButton.increase.main}`,
    width: '1rem',
    marginLeft: '0.438rem',
  },
  decrease: {
    color: `${theme.palette.statButton.decrease.main}`,
    width: '1rem',
    marginLeft: '0.438rem',
  },
  active: {
    background: `${theme.palette.statButton.active.background} !important`,
    border: `1px solid ${theme.palette.statButton.active.border} !important`,
  },
  disabled: {
    border: `1px solid ${theme.palette.statButton.disabled.background} !important`,
    background: `${theme.palette.statButton.disabled.background} !important`,
  },
}));

export interface StatButtonProps extends ButtonProps {
  active?: boolean;
  count: number;
  label: string;
  delta: number;
  trend: OneOfValues<typeof Trend> | string;
}

export const signTextMap: Record<number, string> = {
  [-1]: '',
  0: '',
  1: '+',
};
