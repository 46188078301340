import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../app/components/DataViewSelector/global-data-view-selector-store';
import SbomFiltersStore from './stores/sbom-filters-store';
import SbomStore from './stores/sbom-store';

export const getSbomApiParams = (limit: number) => {
  const { offset, topSearchValue } = snapshot(SbomStore);
  const { filterBy, searchValues } = snapshot(SbomFiltersStore);
  const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
    GlobalDataViewSelector,
  );

  return {
    offset: offset,
    filters: filterBy,
    search: topSearchValue,
    limit: limit,
    sbomSearch: searchValues,
    owners: selectedAppOwnersEmails,
    tagIds: selectedTagIds,
  };
};
