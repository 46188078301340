/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { env, isProd } from 'env-utils';

type LogLevel = 'log' | 'info' | 'warn' | 'error' | 'debug';

const createLogger =
  (level: LogLevel) =>
  (message?: unknown, ...optionalParams: unknown[]) => {
    if (isProd() && !env()['isDebug']) {
      const msg =
        typeof message === 'string' ? message : JSON.stringify(message);
      if (level === 'error') {
        datadogLogs.logger.error(msg, { additionalInfo: optionalParams });
        datadogRum.addError(msg, { additionalInfo: optionalParams });
      }

      return;
    }
    if (level === 'debug' && !env()['isDebug']) {
      return;
    }
    if (optionalParams.length > 0) {
      console[level](message, ...optionalParams);
    } else {
      console[level](message);
    }
  };

export const logger = {
  log: createLogger('log'),
  info: createLogger('info'),
  error: createLogger('error'),
  warn: createLogger('warn'),
  debug: createLogger('debug'),
};
