import { AppEvents } from 'common-events';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../app/stores/top-bar-store';
import InventoryFiltersStore from '../../dashboard-inventory/stores/inventory-filters-store';
import ScanStore from '../../new-scan/store/scan-store';
import { fetchActiveProfileOverviewAction } from '../../policies/api/policies-api';
import { DashboardResponse } from '../dashboard-types';
import dashboardService from '../services';
import { GetCategoriesInPreventResponse } from '../services/get-categories-in-prevent/get-categories-in-prevent';
import {
  setLoadingPreventCategories,
  setOrgScaneData,
  setPreventCategories,
} from '../store-actions/store-actions';

export const loadDashboardInfo = async (): Promise<boolean | undefined> => {
  let result: Nullable<DashboardResponse> = null;
  try {
    const { dateRange } = snapshot(TopBarStore);
    const [from, to] = calcDateRange(dateRange);
    const { filters } = snapshot(InventoryFiltersStore);
    const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
      GlobalDataViewSelector,
    );
    const { scanID, realScanId } = snapshot(ScanStore);
    result = await dashboardService.fetchOrgScanInfoResults.execute({
      filters,
      ...(realScanId ? { scanId: realScanId } : scanID && { scanId: scanID }),
      owners: selectedAppOwnersEmails,
      tagIds: selectedTagIds,
      dateRange: {
        from,
        to,
      },
    });
    fetchActiveProfileOverviewAction();
  } catch (err) {
    logger.info('Failed to load dashboard info', err);
    return;
  }

  document.dispatchEvent(
    new CustomEvent(AppEvents.DashboardInfo.Loaded, { detail: result }),
  );

  try {
    if (result) {
      handleResponse(result);
    }
    return !!result?.getOrgScanInfo;
  } catch (err) {
    logger.info('Failed to load org score data', err);
  }
};

export const loadPreventCategories = async () => {
  try {
    setLoadingPreventCategories(true);

    const response = await dashboardService.getCategoriesInPrevent.execute();
    if (response) {
      handlePreventCategoriesResponse(response);
      return;
    }

    handlePreventCategoriesResponse({ getCategoriesInPrevent: [] });
  } catch (err) {
    logger.info('Failed to load categories in prevent info', err);
  } finally {
    setLoadingPreventCategories(false);
  }
};

const handleResponse = (result: DashboardResponse) => {
  setOrgScaneData(result.getOrgScanInfo);
};

const handlePreventCategoriesResponse = (
  result: GetCategoriesInPreventResponse,
) => {
  setPreventCategories(result.getCategoriesInPrevent);
};
