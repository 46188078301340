import { FC } from 'react';
import { Link, Typography } from '@mui/material';
import { stopPropagation } from 'common-utils';
import { formatDistanceToNow } from 'date-fns';
import NodeTooltip from '../NodeTooltip';

const JiraTicketItem: FC<React.PropsWithChildren<JiraTicketItemProps>> = ({
  self,
  key,
  status,
  created,
  updated,
}) => {
  return (
    <NodeTooltip title={`Jira Ticket`}>
      <Typography variant='caption'>
        Ticket:{' '}
        <Link onPointerDown={stopPropagation} target='_blank' href={self}>
          #{key}
        </Link>
      </Typography>
      <Typography variant='caption'>
        Status: <b>{status}</b>
      </Typography>
      {created && (
        <Typography variant='caption'>
          Created:{' '}
          <b>
            {formatDistanceToNow(new Date(created), {
              addSuffix: true,
            })}
          </b>
        </Typography>
      )}
      {updated && (
        <Typography variant='caption'>
          Updated:{' '}
          <b>
            {formatDistanceToNow(new Date(updated), {
              addSuffix: true,
            })}
          </b>
        </Typography>
      )}
    </NodeTooltip>
  );
};

export interface JiraTicketItemProps {
  self: string;
  key: string;
  status: string;
  created: string;
  updated: string;
}

export { JiraTicketItem };
