import { GenIcon } from 'react-icons';

export const ParametersTabIcon = GenIcon({
  tag: 'svg',
  attr: { viewBox: '0 0 14 11' },
  child: [
    {
      tag: 'path',
      attr: {
        d: 'M0 10.25C0 9.85156 0.328125 9.5 0.75 9.5H2.01562C2.32031 8.84375 2.97656 8.375 3.75 8.375C4.5 8.375 5.15625 8.84375 5.46094 9.5H11.25C11.6484 9.5 12 9.85156 12 10.25C12 10.6719 11.6484 11 11.25 11H5.46094C5.15625 11.6797 4.5 12.125 3.75 12.125C2.97656 12.125 2.32031 11.6797 2.01562 11H0.75C0.328125 11 0 10.6719 0 10.25ZM4.5 10.25C4.5 9.85156 4.14844 9.5 3.75 9.5C3.32812 9.5 3 9.85156 3 10.25C3 10.6719 3.32812 11 3.75 11C4.14844 11 4.5 10.6719 4.5 10.25ZM8.25 4.625C9 4.625 9.65625 5.09375 9.96094 5.75H11.25C11.6484 5.75 12 6.10156 12 6.5C12 6.92188 11.6484 7.25 11.25 7.25H9.96094C9.65625 7.92969 9 8.375 8.25 8.375C7.47656 8.375 6.82031 7.92969 6.51562 7.25H0.75C0.328125 7.25 0 6.92188 0 6.5C0 6.10156 0.328125 5.75 0.75 5.75H6.51562C6.82031 5.09375 7.47656 4.625 8.25 4.625ZM9 6.5C9 6.10156 8.64844 5.75 8.25 5.75C7.82812 5.75 7.5 6.10156 7.5 6.5C7.5 6.92188 7.82812 7.25 8.25 7.25C8.64844 7.25 9 6.92188 9 6.5ZM11.25 2C11.6484 2 12 2.35156 12 2.75C12 3.17188 11.6484 3.5 11.25 3.5H6.21094C5.90625 4.17969 5.25 4.625 4.5 4.625C3.72656 4.625 3.07031 4.17969 2.76562 3.5H0.75C0.328125 3.5 0 3.17188 0 2.75C0 2.35156 0.328125 2 0.75 2H2.76562C3.07031 1.34375 3.72656 0.875 4.5 0.875C5.25 0.875 5.90625 1.34375 6.21094 2H11.25ZM3.75 2.75C3.75 3.17188 4.07812 3.5 4.5 3.5C4.89844 3.5 5.25 3.17188 5.25 2.75C5.25 2.35156 4.89844 2 4.5 2C4.07812 2 3.75 2.35156 3.75 2.75Z',
      },
      child: [],
    },
  ],
})({});

export const ResponsesTabIcon = GenIcon({
  tag: 'svg',
  attr: { viewBox: '0 0 14 11' },
  child: [
    {
      tag: 'path',
      attr: {
        d: 'M3.4375 4.82031C3.17969 4.58594 3.17969 4.1875 3.4375 3.95312L7.5625 0.390625C7.91406 0.0859375 8.47656 0.34375 8.47656 0.835938V2.75781C11.5234 2.99219 13.7266 3.97656 13.7266 7.04688C13.7266 8.47656 12.8125 9.90625 11.7812 10.6562C11.4766 10.8906 11.0078 10.6094 11.125 10.2109C12.0391 7.32812 11.0312 6.25 8.47656 5.96875V7.9375C8.47656 8.40625 7.91406 8.66406 7.5625 8.35938L3.4375 4.82031ZM0.4375 4.82031C0.179688 4.58594 0.179688 4.1875 0.4375 3.92969L4.5625 0.390625C4.91406 0.0859375 5.5 0.34375 5.5 0.835938V1.1875L1.79688 4.375L5.5 7.5625V7.9375C5.5 8.40625 4.91406 8.66406 4.5625 8.35938L0.4375 4.82031Z',
      },
      child: [],
    },
  ],
})({});

export const BomPageMenuItemIcon = GenIcon({
  tag: 'svg',
  attr: { viewBox: '0 0 15 13' },
  child: [
    {
      tag: 'path',
      attr: {
        d: 'M1.125 0.5C1.73438 0.5 2.25 1.01562 2.25 1.625V3.5H0.375C0.164062 3.5 0 3.33594 0 3.125V1.625C0 1.01562 0.492188 0.5 1.125 0.5ZM6 10.1797C6 10.9297 5.48438 11.6094 4.73438 11.75C3.79688 11.8906 3 11.1641 3 10.25V1.625C3 1.20312 2.83594 0.828125 2.60156 0.5H10.5C11.7188 0.5 12.75 1.53125 12.75 2.75V8.75H6V10.1797ZM6.75 9.5H14.625C14.8125 9.5 15 9.6875 15 9.875C15 11.3281 13.8047 12.5 12.375 12.5H4.5C5.71875 12.5 6.75 11.4922 6.75 10.25V9.5Z',
      },
      child: [],
    },
  ],
});
