import { createStore, derive } from 'store-utils';
import { createConnectivityBannerInfo } from '../actions/connectivity-actions';
import { NoConnectivityBannerInfo } from '../connector-connectivity-types';
import { Connector, ConnectorsByFamilyObject } from '../connectors-types';
import { findConnectivityIssues } from '../utils/connectors-utils';

const baseStore = createStore<ConnectorsStoreState>(
  {
    connectors: [],
    connectorsByFamily: [],
    loadingConnectors: true,
    filteredConnectorsByFamily: [],
    credentialsValidationInProgress: false,
    showValidationMsg: false,
    validationMsgObj: undefined,
    validationError: false,
    singleConnectorValidationInProgress: false,
    showSingleValidationMsg: false,
    singleConnectorIsValid: undefined,
    singleConnectorIsEmptyOfRepos: undefined,
    connectorsDiscoveredNumber: 0,
    scanType: undefined,
    connectivityBannerDismissed: false,
  },
  'Connector Store',
);

const ConnectorsStore = derive(
  {
    connectorsIssues: get => {
      const { connectorsByFamily } = get(baseStore);
      return findConnectivityIssues(connectorsByFamily);
    },
    noConnectionBanner: (get): NoConnectivityBannerInfo | undefined => {
      const { scanType, connectorsByFamily } = get(baseStore);

      // waiting for 'dashboardInfo' to load
      if (!scanType) {
        return;
      }
      const info = createConnectivityBannerInfo(connectorsByFamily);
      return info;
    },
    connectedSourceControlConnectors: get => {
      const { connectors } = get(baseStore);
      return connectors.filter(
        c =>
          c.family === 'SourceControl' &&
          c.isConfigured &&
          !c.isConnectorInvalid,
      );
    },
    connectedDastConnectors: get => {
      const { connectors } = get(baseStore);
      return connectors.filter(
        c =>
          c.family === 'DynamicApplicationSecurityTesting' &&
          c.isConfigured &&
          !c.isConnectorInvalid,
      );
    },
    zapierConnectorURL: get => {
      const { connectors } = get(baseStore);
      let zapierURL = '';
      const zapierConnector = connectors.filter(
        c => c.id === '144' && c.isConfigured && !c.isConnectorInvalid,
      );
      if (zapierConnector) {
        zapierURL = zapierConnector[0]?.credentials[0]?.webhookUrl || '';
      }
      return zapierURL;
    },
    isActiveJiraConnection: get => {
      const { connectors } = get(baseStore);
      const jiraConnector = connectors.find(
        connector => connector.name === 'Jira',
      );
      return Boolean(jiraConnector?.isConfigured);
    },
    connectedTicketingConnectors: (
      get,
    ): Record<
      string,
      { isConfigured: boolean; isConnectorInvalid: boolean }
    > => {
      const { connectorsByFamily } = get(baseStore);
      const ticketManagementFamily = connectorsByFamily.find(
        family => family.family === 'TicketManagement',
      );
      if (!ticketManagementFamily) return {};

      const activeTicketingConnectors =
        ticketManagementFamily.connectors.reduce((acc, connector) => {
          const { isConfigured, isConnectorInvalid } = connector;
          acc[connector.name] = {
            isConfigured,
            isConnectorInvalid: !!isConnectorInvalid,
          };

          return acc;
        }, {} as Record<string, { isConfigured: boolean; isConnectorInvalid: boolean }>);
      return activeTicketingConnectors;
    },
    isActiveSlackConnection: get => {
      const { connectors } = get(baseStore);
      const slackConnector = connectors.find(
        connector => connector.name === 'Slack',
      );
      return Boolean(slackConnector?.isConfigured);
    },
  },
  {
    proxy: baseStore,
  },
);

interface ConnectorsStoreState {
  connectors: Connector[];
  connectorsByFamily: ConnectorsByFamilyObject[];
  loadingConnectors: boolean;
  filteredConnectorsByFamily: ConnectorsByFamilyObject[];
  credentialsValidationInProgress: boolean;
  showValidationMsg: boolean;
  validationMsgObj?: {
    msg: string;
    color: string;
  };
  validationError: boolean;
  singleConnectorValidationInProgress: boolean;
  showSingleValidationMsg: boolean;
  singleConnectorIsValid?: boolean;
  singleConnectorIsEmptyOfRepos?: boolean;
  connectorsDiscoveredNumber: number;
  scanType?: string;
  connectivityBannerDismissed: boolean;
}

export default ConnectorsStore;
