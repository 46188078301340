import { snapshot } from 'valtio';
import OrgSettingsStore from '../stores/org-settings-store';

export const setRemoveOrgModalVisibility = (visible: boolean) => {
  OrgSettingsStore.isRemoveOrgModalVisible = visible;
};

export const setRemoveOrgVerificationValue = (
  verificationValue: string,
): void => {
  OrgSettingsStore.removeOrgVerificationValue = verificationValue;
};

export const setIsLoadingDeleteOrganization = (isLoading: boolean): void => {
  OrgSettingsStore.isLoadingRemoveOrg = isLoading;
};

export const setSettingErrorMessage = (message: string) => {
  OrgSettingsStore.errorMessage = message;
};

export const setIsLoadingUpdateOrgDisplayName = (isLoading: boolean): void => {
  OrgSettingsStore.isLoadingUpdateOrgDisplayName = isLoading;
};

export const setOrgDisplayName = (orgDisplayName: string) => {
  OrgSettingsStore.orgDisplayName = orgDisplayName;
};

export const setIsOrgDisplayNameUpdated = (
  updatedOrgDisplayName: string,
  orgDisplayName: string,
) => {
  const isUpdated = updatedOrgDisplayName !== orgDisplayName;
  OrgSettingsStore.isOrgDisplayNameUpdated = isUpdated;
};

export const disableSaveUpdatedOrgDisplayName = () => {
  OrgSettingsStore.isOrgDisplayNameUpdated = false;
};

export const getUpdatedOrgDisplayName = () => {
  const { orgDisplayName: updatedOrgDisplayName } = snapshot(OrgSettingsStore);
  return updatedOrgDisplayName;
};
