import { isBefore } from 'date-fns';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { logger } from 'logging-utils';
import { OrganizationsStore } from 'organizations';
import { Nullable } from 'ox-common-types';
import { snapshot } from 'valtio';
import { Organization } from '../common-auth-types';
import { TokenStore } from '../stores/token-store';
import { isRemoteSite } from 'env-utils';

export const getAuthToken = async () => {
  const { authToken } = snapshot(TokenStore);
  const { chosenOrganization } = snapshot(OrganizationsStore);
  const lastLoggedInOrg = getLastLoggedInOrg();
  if (chosenOrganization?.id !== lastLoggedInOrg?.id) {
    logger.error(
      'Request scoped to the last logged in organization, please refersh the page and try again.',
    );
    setShouldForceLogout(true);
    return;
  }

  if (!authToken) {
    throw new Error(
      'Tried to get auth token before a token was fetched or saved',
    );
  }
  try {
    const decodedToken = jwtDecode<JwtPayload>(authToken);
    if (!decodedToken.exp) {
      throw new Error('An auth token without exp field is saved in the store');
    }

    const isExpired = isBefore(new Date(decodedToken.exp * 1000), new Date());
    if (isExpired) {
      if (isRemoteSite()) {
        setShouldForceLogout(true);
        return;
      }
      setShouldTryRefresh(true);
      return new Promise((success, fail) => {
        const cancelInterval = setInterval(() => {
          const { shouldTryRefresh, authToken } = snapshot(TokenStore);
          if (!shouldTryRefresh) {
            clearInterval(cancelInterval);
            success(authToken);
          }
        }, 100);
      });
    }
  } catch (err) {
    logger.error('Invalid auth token, could not check its validity');
    throw err;
  }

  return authToken;
};

export const setShouldForceLogout = (forceLogout: boolean) => {
  TokenStore.shouldForceLogout = forceLogout;
};

export const setShouldTryRefresh = (forceRefresh: boolean) => {
  TokenStore.shouldTryRefresh = forceRefresh;
};

export const setAuthToken = (token: string) => {
  TokenStore.authToken = token;
};

const getLastLoggedInOrg = (): Nullable<Organization> => {
  const localStorageOrg = localStorage.getItem('lastLoggedInOrg');
  const lastLoggedInOrg: Nullable<Organization> = localStorageOrg
    ? JSON.parse(localStorageOrg)
    : null;

  return lastLoggedInOrg;
};
