import { Box, Button, Theme, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { makeStyles } from 'tss-react/mui';
import { BsClipboard as ClipboardIcon } from 'react-icons/bs';
import { AiOutlineCheck as CheckIcon } from 'react-icons/ai';

const CodeBlockWithTitle: FC<
  React.PropsWithChildren<CodeBlockWithTitleProps>
> = ({ title, code, language }) => {
  const { classes } = useStyles();
  const [copiedSuccessfully, setCopiedSuccessfully] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(code);
    setCopiedSuccessfully(true);
    setTimeout(() => {
      setCopiedSuccessfully(false);
    }, 2500);
  };

  return (
    <Box className={classes.codeContainer}>
      <div className={classes.titleBar}>
        <Typography
          color='rgba(217,217,227)'
          className={classes.title}
          fontSize='12px'>
          {title}
        </Typography>
        <Button
          startIcon={
            copiedSuccessfully ? (
              <CheckIcon style={{ fontSize: '14px' }} />
            ) : (
              <ClipboardIcon style={{ fontSize: '14px' }} />
            )
          }
          variant='text'
          size='small'
          className={classes.copyButton}
          onClick={handleCopyClick}>
          {copiedSuccessfully ? 'Copied!' : 'Copy code'}
        </Button>
      </div>
      <SyntaxHighlighter
        language={language?.toLowerCase() || 'javascript'}
        wrapLongLines
        customStyle={{ margin: 0, background: '#000000' }}
        style={vscDarkPlus}>
        {code}
      </SyntaxHighlighter>
    </Box>
  );
};

export interface CodeBlockWithTitleProps {
  title: string;
  language?: string;
  code: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  codeContainer: {
    borderRadius: 4,
  },
  titleBar: {
    backgroundColor: '#343542',
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    padding: 16,
  },
  title: {
    flexGrow: 1,
  },
  copyButton: {
    textTransform: 'none',
    color: '#fff',
    fontSize: '12px',
  },
}));

export default CodeBlockWithTitle;
