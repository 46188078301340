import { createStore, derive } from 'store-utils';
import { WhatsNewItem, WhatsNewType } from '../whats-new-types';

const baseStore = createStore<WhatsNewStoreState>(
  {
    items: [],
    uiLoadTimestamp: new Date(document.lastModified),
  },
  'WhatsNew Store',
);

export const WhatsNewStore = derive(
  {
    dismissable: get => {
      const { items } = get(baseStore);
      return (
        items.length > 0 &&
        items.some(item => item.type !== WhatsNewType.NewUIVersionAvailable)
      );
    },
  },
  { proxy: baseStore },
);

interface WhatsNewStoreState {
  items: WhatsNewItem[];
  uiLoadTimestamp: Date;
}
