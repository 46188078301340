import { User } from '@auth0/auth0-react';
import { Appcues, Loader } from 'appcues-loader';
import { AppEvents } from 'common-events';
import { logger } from 'logging-utils';
import { snapshot } from 'valtio';
import config from './config.json';
import AppCuesStore from './stores/app-cues-store';

let appcues: Appcues;

export const initAppcues = (user: User) => {
  const loader = new Loader(config.accountId);
  loader.load().then((ac: Appcues) => {
    ac.page();
    ac.identify(user.email!, user);
    appcues = ac;
  });
};

export const trackAppcuesEvent = (event: string, eventProperties?: {}) => {
  logger.log(`Tracking app cues event: ${event}`);
  appcues?.track(event, eventProperties);
};

export const trackFirstResultsAppCuesEvent = (eventProperties?: {}) => {
  const { firstResultsEventFired } = snapshot(AppCuesStore);
  if (!firstResultsEventFired) {
    trackAppcuesEvent(AppEvents.Scan.FirstResult, eventProperties);
    AppCuesStore.firstResultsEventFired = true;
  }
};

export const appcuesPage = () => {
  appcues?.page();
};
