import { Nullable } from 'ox-common-types';
import { ApiInventoryItem } from '../types';
import ApiInventoryStore from './api-inventory-store';
import { snapshot } from 'valtio';
import { FilterIssueType } from 'ox-filter-utils';

export const setInventoryOffset = (offset: number) => {
  ApiInventoryStore.offset = offset;
};
export const setApiInventories = (
  inventories: ApiInventoryItem[],
  update: boolean,
) => {
  if (update) {
    ApiInventoryStore.inventories = inventories.map((inventory, i) => ({
      ...inventory,
      index: i + 1,
    }));
  } else {
    const { inventories: currentInventories } = snapshot(ApiInventoryStore);
    ApiInventoryStore.inventories = [
      ...(currentInventories || []),
      ...inventories.map((inventory, i) => ({
        ...inventory,
        index: i + 1 + (currentInventories?.length || 0),
      })),
    ];
  }
};

export const setApiItemIssuesStatistics = (statistics: FilterIssueType) => {
  ApiInventoryStore.apiItemIssuesStatistics = statistics;
};

export const setInventoryTotal = (total: number) => {
  ApiInventoryStore.total = total;
};

export const toggleFiltersPanel = () => {
  ApiInventoryStore.filtersOpen = !ApiInventoryStore.filtersOpen;
};

export const setLastDrawerHeight = (height: number) => {
  ApiInventoryStore.lastDrawerHeight = height;
};

export const setSelectedInventory = (inventory: Nullable<ApiInventoryItem>) => {
  ApiInventoryStore.selectedInventory = inventory;
};
export const setSelectedInventoryId = (inventory: Nullable<string>) => {
  ApiInventoryStore.selectedId = inventory;
};

export const setTopSearchValue = (value: string) => {
  ApiInventoryStore.topSearchValue = value;
};

export const clearApiInventory = () => {
  ApiInventoryStore.offset = 0;
  ApiInventoryStore.total = 0;
  ApiInventoryStore.selectedInventory = null;
  ApiInventoryStore.selectedId = null;
  ApiInventoryStore.topSearchValue = '';
};
