import { FC } from 'react';
import { List, ListItem, useTheme } from '@mui/material';
import { CustomLink } from 'ox-react-components';

const ApiFunctions: FC<React.PropsWithChildren<ApiFunctionsProps>> = ({
  functions,
}) => {
  const theme = useTheme();
  return (
    <List>
      {functions.map(({ function: func, link }, index) => {
        return (
          <ListItem
            sx={{
              px: 0,
              borderBottom:
                index !== functions.length - 1
                  ? `1px solid ${theme.palette.divider}`
                  : 'unset',
              display: 'flex',
              gap: 1,
              alignItems: 'center',
            }}
            key={func}>
            {index + 1}.
            <CustomLink value={func} href={link} />
          </ListItem>
        );
      })}
    </List>
  );
};

export interface ApiFunctionsProps {
  functions: { function: string; link: string }[];
}

export default ApiFunctions;
