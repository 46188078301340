import { Theme } from '@mui/material';
import { CSSProperties, FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const OscarMatrixCell: FC<PropsWithChildren<OscarMatrixCellProps>> = ({
  id,
  name,
  showContent,
  linkToIssuesPage,
  sumIssues,
  children,
}) => {
  const { classes, cx } = useStyles();
  const linkable = sumIssues > 0;
  const title = `${id}: ${name}`;

  const content = linkable ? (
    <Link to={linkToIssuesPage} className={cx(classes.name, classes.link)}>
      {title}
    </Link>
  ) : (
    <span className={classes.name}>{title}</span>
  );

  return (
    <div className={classes.oscarMatrixCell}>
      {showContent && content}
      {children}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  oscarMatrixCell: {
    minHeight: 100,
    height: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textDecoration: 'none',
    position: 'relative',
    zIndex: 3,
  },
  name: {
    ...(theme.typography.body2 as CSSProperties),
    color: theme.palette.text.primary,
  },
  link: {
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}));

export interface OscarMatrixCellProps {
  id: string;
  name: string;
  showContent: boolean;
  linkToIssuesPage: string;
  sumIssues: number;
}

export default OscarMatrixCell;
