export const getLocalTime = (epochTimeSec: number) => {
  const date = new Date(epochTimeSec * 1000);

  return date.toLocaleString();
};

export const getOneYearFromNow = () => {
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  return oneYearFromNow;
};

export const isValidDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getFullYear();

  if (year && (year < 2022 || year > 3000)) {
    return false;
  }

  if (month && (month === 0 || month > 12)) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (year && (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))) {
    monthLength[1] = 29;
  }

  return day && day > 0 && day <= monthLength[month - 1];
};
