import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import { Role, UserRolesInput } from '../../types/members-types';
import mutation from './delete-user-roles.gql';

const deleteUserRoles = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<UserRolesInput, Role[]> => {
  return {
    execute: async (deleteUserRolesInput: UserRolesInput): Promise<Role[]> => {
      try {
        const results = await client.mutate<{
          deleteUserRoles: Role[];
        }>({
          mutation,
          variables: { deleteUserRolesInput },
        });

        if (!results || !results.data) {
          return [];
        }

        return results.data.deleteUserRoles;
      } catch (error) {
        return [];
      }
    },
  };
};

export default deleteUserRoles;
