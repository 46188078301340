import { Box, TextField, Typography } from '@mui/material';
import { ButtonGroup } from 'ox-react-components';
import { FC, useCallback } from 'react';
import { setOrgName } from '../store-actions/create-org-store-actions';

const CreateOrganizationForm: FC<CreateOrganizationFormProps> = props => {
  const {
    onCreateOrg,
    onClose,
    isLoading,
    orgDisplayName,
    isValidOrgDisplayName,
    invalidOrgDisplayNameHelperText,
  } = props;

  const onOrgNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrgName(event.target.value);
    },
    [],
  );

  const onSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      onCreateOrg();
    },
    [onCreateOrg],
  );

  return (
    <form onSubmit={onSubmit}>
      <TextField
        required
        variant='outlined'
        label='Organization Name'
        name='organizationName'
        defaultValue={orgDisplayName}
        onChange={onOrgNameChange}
        fullWidth
        helperText={
          <Typography component='span' color='text.secondary'>
            {invalidOrgDisplayNameHelperText}
          </Typography>
        }
      />
      <Box mt={2}>
        <ButtonGroup
          onCancel={onClose}
          onClick={onCreateOrg}
          isLoading={isLoading}
          buttonCancelLabel='Cancel'
          buttonCancelTooltip='Cancel operation'
          buttonActionLabel='Create'
          buttonActionTooltip='Create organization'
          buttonActionColor='primary'
          isActionDisabled={!isValidOrgDisplayName}
        />
      </Box>
    </form>
  );
};
export interface CreateOrganizationFormProps {
  onCreateOrg: () => void;
  onClose: () => void;
  isLoading: boolean;
  orgDisplayName: string;
  isValidOrgDisplayName: boolean;
  invalidOrgDisplayNameHelperText: string;
}

export default CreateOrganizationForm;
