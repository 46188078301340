import { createStore } from 'store-utils';
import { ITag } from '../../applications/applications-types';
import { Nullable } from 'ox-common-types';

const TagsStore = createStore<TagsState>(
  {
    loadingEditAppTagsData: false,
    loadingSubmit: false,
    tags: null,
    assignedTags: null,
  },
  'Tags Store',
);

interface TagsState {
  loadingEditAppTagsData: boolean;
  loadingSubmit: boolean;
  tags: Nullable<{
    [key: string]: ITag;
  }>;
  assignedTags: Nullable<{
    [key: string]: string[];
  }>;
}

export default TagsStore;
