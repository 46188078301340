import { IconButton, Tooltip } from '@mui/material';
import { FC, useCallback } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { setFilter } from '../../../active-issues/actions/filter-issues-actions';

const ApplyOnlyFilter: FC<React.PropsWithChildren<ApplyOnlyFilterProps>> = ({
  value,
  filterType,
  id,
  isAvailable,
}) => {
  const addToFilter = useCallback(() => {
    if (value && !id) {
      setFilter(filterType, value, true);
    } else if (id) {
      setFilter(filterType, id, true);
    }
  }, [filterType, id, value]);

  return (
    <Tooltip arrow title={'Apply only this filter'} placement='top'>
      <span>
        <IconButton
          onClick={addToFilter}
          size='small'
          color='primary'
          disabled={isAvailable}>
          <BiFilterAlt fontSize='1rem' />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export interface ApplyOnlyFilterProps {
  value?: string;
  id?: string;
  filterType: string;
  isAvailable?: boolean;
}

export default ApplyOnlyFilter;
