import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';
import { Connection } from '../types/connection-types';

const ConnectionsStore = createStore<ConnectionsStoreState>(
  {
    connections: [],
    isLoading: false,
    errorMessage: null,
  },
  'Connections Store',
);

interface ConnectionsStoreState {
  connections: Connection[];
  isLoading: boolean;
  errorMessage: Nullable<string>;
}

export default ConnectionsStore;
