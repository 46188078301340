import { CloudTypes, IconType } from 'ox-common-types';
import { ReactComponent as AWSLogo } from '../../assets/aws-logo.svg';
import { ReactComponent as AzureLogo } from '../../assets/azure-logo.svg';
import { ReactComponent as GcpLogo } from '../../assets/gcp-logo.svg';
import {
  AppFlowToolTipItem,
  ApplicationFlowItemType,
} from '../types/app-flow-types';
import { CloudDeploymentTypes } from '../types/cloud-deployment-types';
import { ReactComponent as GenericIcon } from '../../assets/generic-icon.svg';

export const createCloudDeploymentTypes = (
  cloudDeploymentItems: AppFlowToolTipItem[],
) => {
  if (cloudDeploymentItems) {
    return [
      cloudDeploymentItems.filter(
        cloudDeployment => cloudDeployment.type === CloudDeploymentTypes.AWS,
      ),
      cloudDeploymentItems.filter(
        cloudDeployment => cloudDeployment.type === CloudDeploymentTypes.GCP,
      ),
      cloudDeploymentItems.filter(
        cloudDeployment => cloudDeployment.type === CloudDeploymentTypes.Azure,
      ),
    ];
  }
};

export const cloudDeploymentTypeIconMap = new Map<
  CloudDeploymentTypes | ApplicationFlowItemType | CloudTypes,
  IconType
>([
  [CloudDeploymentTypes.AWS, AWSLogo],
  [CloudDeploymentTypes.Azure, AzureLogo],
  [CloudDeploymentTypes.GCP, GcpLogo],
  [CloudDeploymentTypes.Generic, GenericIcon],
]);
