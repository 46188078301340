import { IconButton, Tooltip } from '@mui/material';
import * as React from 'react';
import { MdDarkMode as DarkModeIcon } from 'react-icons/md/index';
import { MdLightMode as LightModeIcon } from 'react-icons/md';
import { useSnapshot } from 'valtio';
import { toggleTheme } from '../../actions/app-theme-actions';
import LayoutStore from '../../stores/layout-store';

const ToggleThemeButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { themeType } = useSnapshot(LayoutStore);
  const title = `Switch to ${themeType === 'dark' ? 'light' : 'dark'} mode`;

  return (
    <Tooltip arrow title={title} placement='bottom'>
      <IconButton onClick={toggleTheme} color='inherit' size='small'>
        {themeType === 'light' ? (
          <DarkModeIcon
            style={{
              width: '1.5rem !important',
              height: '1.5rem !important',
            }}
          />
        ) : (
          <LightModeIcon
            style={{
              width: '1.5rem !important',
              height: '1.5rem !important',
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ToggleThemeButton;
