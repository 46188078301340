import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  ExclusionsFilters,
  ExclusionsFiltersLazyResponse,
} from '../../types/exclusion-types';
import query from './get-exclusions-filters-lazy.gql';

const getExclusionsFiltersLazy = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      exclusionsFiltersLazyInput: {
        filters: ExclusionsFilters;
        openItems: string[];
        limit: number;
      },
      cache = true,
    ) => {
      try {
        const results = await client.query<ExclusionsFiltersLazyResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: { exclusionsFiltersLazyInput },
        });

        if (!results.data) return null;
        return results.data.getExclusionsFiltersLazy;
      } catch (e) {
        logger.error(`Failed to fetch exclusions filters: ${e}`, e);
        return null;
      }
    },
  };
};

export default getExclusionsFiltersLazy;
