import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  Nullable,
  ServiceExecute,
  TicketProvider,
  scanTypes,
} from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';
import mutation from './link-jira-ticket-to-issue.gql';
import { LinkJiraTicketToIssueInput } from '../../types/jira-ticket-types';
import { IssuePages } from '../../../../../../apps/web/src/issues/common/types/issues-types';

const linkJiraTicket = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  { input: LinkJiraTicketToIssueInput; issuesPage: IssuePages },
  Nullable<LinkJiraTicketToIssueResponse>
> => {
  return {
    execute: async ({
      input,
      issuesPage,
    }): Promise<Nullable<LinkJiraTicketToIssueResponse>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: {
            input,
            provider: TicketProvider.Jira,
            scanType:
              issuesPage === IssuePages.PipelineIssues
                ? scanTypes.PipelineScan
                : scanTypes.FullScan,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }
        TelemetryManager.setTelemetryAction(TelemetryActions.LinkToJiraTicket);
        return results.data.linkJiraTicketToIssue;
      } catch (error) {
        logger.error('Failed to link issue to jira ticket', error);
        return null;
      }
    },
  };
};

interface MutationResponse {
  linkJiraTicketToIssue: LinkJiraTicketToIssueResponse;
}

export interface LinkJiraTicketToIssueResponse {
  data: Nullable<{
    id: string;
    key: string;
    self: string;
  }>;
  validationError: Nullable<string>;
}

export default linkJiraTicket;
