import {
  getParamFromUrl,
  navigateToArtifacts,
  AppPages,
  currentPage,
} from 'app-navigator';
import { createSimpleAsyncAction } from 'async-utils';
import { AppEvents } from 'common-events';
import { Nullable } from 'ox-common-types';
import { trackAppcuesEvent } from 'telemetry';
import { snapshot } from 'valtio';
import { getConnectorsByFamily } from '../../connectors/api/connectors-api';
import { createConnectorsPanelData } from '../../supply-chain/utils/supply-chain-connectors-utils';
import { createSupplyChainInfo } from '../../supply-chain/utils/supply-chain-utils';
import artifactsService from '../services';
import {
  setIsOpenArtifactDrawer,
  setArtifacts,
  setArtifactsOffset,
  setArtifactsTotalNumber,
  setConfIssues,
  setDepBaseIssuesVulnerabilities,
  setDepInstructionIssuesVulnerabilities,
  setDepIssuesVulnerabilities,
  setLoadingSingleArtifact,
  setSecretIssues,
  setSelectedArtifact,
  setSupplyChainItems,
  setTotalFilteredArtifacts,
  setSelectedArtifactId,
} from '../store-actions/artifacts-store-actions';
import ArtifactsStore from '../stores/artifacts-store';
import { LoadArtifactsParams } from '../types/artifacts-type';
import { getArtifactsRequestParams } from '../utils/artifacts-utils';
import {
  getArtifactsFilterItems,
  loadArtifactsTopFilters,
  setInitialFilters,
} from './artifacts-filters-actions';
import { OxCategoriesNames } from '@oxappsec/ox-consolidated-categories';

export const loadArtifacts = createSimpleAsyncAction(
  async (params?: LoadArtifactsParams) => {
    const { isLoadMoreItems = false, limit = 50, cache = true } = params || {};
    if (!isLoadMoreItems) {
      ArtifactsStore.offset = 0;
    }
    const { offset, total, isArtifactDrawerOpen } = snapshot(ArtifactsStore);
    if (total < offset) {
      return;
    }

    const requestParams = getArtifactsRequestParams(limit);
    const response = await artifactsService.getArtifacts.execute(
      requestParams,
      cache,
    );

    if (response) {
      const isArtifactsPage = currentPage() === AppPages.Artifacts;
      const selectedArtifactIdFromUrl = getParamFromUrl('artifactId');
      const { artifacts, offset, total, totalFilteredArtifacts } = response;
      const isArtifactInList = artifacts.some(
        artifact => artifact.id === selectedArtifactIdFromUrl,
      );
      const responseFirstItemId = artifacts[0]?.id;

      setArtifacts(artifacts, isLoadMoreItems);
      setArtifactsTotalNumber(total);
      setArtifactsOffset(offset);
      setTotalFilteredArtifacts(totalFilteredArtifacts);

      if (!isArtifactDrawerOpen || !isArtifactsPage) {
        return;
      }

      if (selectedArtifactIdFromUrl && isArtifactInList) {
        setSelectedArtifactId(selectedArtifactIdFromUrl);
      } else if (responseFirstItemId) {
        setSelectedArtifactId(responseFirstItemId);
      }

      navigateToArtifacts();
    }
  },
  {
    asyncState: ArtifactsStore.loading,
    errorMessage: 'Failed to load artifacts',
  },
);

export const loadMoreArtifacts = () => loadArtifacts({ isLoadMoreItems: true });

export const setArtifactDrawerItem = async (
  artifactId: string,
): Promise<void> => {
  setLoadingSingleArtifact(true);
  const result = await artifactsService.getSingleArtifact.execute({
    artifactId,
  });
  if (result) {
    trackAppcuesEvent(AppEvents.Artifacts.ArtifactSelected);
    setSelectedArtifact(result);

    const connectorsByFamily = getConnectorsByFamily();
    const { connectorsPanelData } =
      createConnectorsPanelData(connectorsByFamily);

    const supplyChainItems = createSupplyChainInfo(
      result.supplyChainInfo,
      connectorsPanelData,
    );
    const filteredData = supplyChainItems.filter(
      item =>
        !(
          item?.header?.categoryName ===
            OxCategoriesNames.DynamicApplicationSecurityTesting &&
          item.connectors?.items === undefined
        ),
    );
    setSupplyChainItems(filteredData);
    setIsOpenArtifactDrawer(true);
    fetchArtifactVulnerabilities({
      vulnDepIssues: result?.vulnDepIssues || [],
      vulnDepBaseIssues: result?.vulnDepBaseIssues || [],
      vulnDepInstructionIssues: result?.vulnDepInstructionIssues || [],
    });
    fetchArtifactMisconfigurations({
      confIssues: result?.confIssues || [],
      secretIssues: result?.secretIssues || [],
    });
  }

  setLoadingSingleArtifact(false);
};

export const closeArtifactDrawer = () => {
  setIsOpenArtifactDrawer(false);
  setSelectedArtifact(null);
};

export const fetchArtifactVulnerabilities = async (ids: {
  vulnDepIssues: string[];
  vulnDepBaseIssues: string[];
  vulnDepInstructionIssues: string[];
}) => {
  const vulnerabilities =
    await artifactsService.getArtifactVulnerabilities.execute({ ...ids });

  setDepIssuesVulnerabilities(vulnerabilities?.vulnDepIssues || []);
  setDepBaseIssuesVulnerabilities(vulnerabilities?.vulnDepBaseIssues || []);
  setDepInstructionIssuesVulnerabilities(
    vulnerabilities?.vulnDepInstructionIssues || [],
  );
};

export const fetchArtifactMisconfigurations = async (ids: {
  confIssues: string[];
  secretIssues: string[];
}) => {
  const issues = await artifactsService.getArtifactMisconfigurations.execute({
    confIssues: ids.confIssues || [],
    secretIssues: ids.secretIssues || [],
  });

  if (issues?.confIssues) {
    setConfIssues(issues.confIssues);
  } else {
    setConfIssues([]);
  }

  if (issues?.secretIssues) {
    setSecretIssues(issues.secretIssues);
  } else {
    setSecretIssues([]);
  }
};

export const loadArtifactsAndFilters = (
  cache: boolean,
  filtersFromUrl: Nullable<string>,
) => {
  getArtifactsFilterItems({ cache: cache });

  setInitialFilters(filtersFromUrl);
  loadArtifacts({ cache: cache });
  loadArtifactsTopFilters(cache);
};
