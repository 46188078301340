import { ActiveMember, MemberKind } from '../application-owners-types';
import { default as applicationOwnerService } from '../services';

export const getActiveMembers = async () => {
  const activeMembers =
    await applicationOwnerService.getActiveMembers.execute();
  if (activeMembers) {
    return reduceRelatedMembersIDs(activeMembers).map(m => ({
      user_id: m.user_id,
      name: m.name,
      email: m.email,
    }));
  }
};

const reduceRelatedMembersIDs = (activeMembers: ActiveMember[]) => {
  return Object.values(
    activeMembers.reduce(
      (acc: Record<string, ActiveMember>, cur: ActiveMember) => {
        const am: ActiveMember = { ...cur };
        am.relatedIDs = [];
        const found = acc[am.email];
        if (found) {
          found.relatedIDs = [...found.relatedIDs, am.user_id];
        } else {
          acc[am.email] = { ...am, kind: MemberKind.ActiveMember };
        }
        return acc;
      },
      {},
    ),
  );
};
