import { RawWhatsNewItem, WhatsNewItem } from '../whats-new-types';

export const createWhatsNewItem = (rawItem: RawWhatsNewItem): WhatsNewItem => {
  return {
    id: rawItem.id,
    type: rawItem.type,
    title: rawItem.title,
    description: rawItem.description,
    expiration: new Date(rawItem.expiration),
    createdAt: new Date(rawItem.createdAt),
    links: rawItem.links || [],
  };
};
