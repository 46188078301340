import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';

import query from './get-issue-trend.gql';
import {
  FetchIssueTrendInput,
  FetchIssueTrendResponse,
} from '../../types/issue-trend-types';

const fetchIssueTrend = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<FetchIssueTrendInput, Nullable<FetchIssueTrendResponse>> => {
  return {
    execute: async (issueTrendInput: FetchIssueTrendInput) => {
      const results = await client.query<FetchIssueTrendResponse>({
        query,
        fetchPolicy: ApolloClientCache.NetworkOnly,
        variables: { getIssueTrendInput: issueTrendInput },
      });

      if (!results.data) return null;
      return results.data;
    },
  };
};

export default fetchIssueTrend;
