import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { AppOwnersByRole } from '../../application-owners-types';
import mutation from './set-applications-owners-by-role.gql';

const setApplicationsOwnersByAppIdsV2 = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (appOwnerInput: AppOwnerInput) => {
      try {
        const results =
          await client.mutate<SetApplicationsOwnersByRoleResponse>({
            mutation,
            variables: { input: { ownersByRoleInput: appOwnerInput } },
          });

        return results.data?.setAppOwners;
      } catch (error) {
        logger.error('Failed to set applications owners', error);
        return null;
      }
    },
  };
};

interface SetApplicationsOwnersByRoleResponse {
  setAppOwners: {
    acknowledge: boolean;
  };
}

type AppOwnerInput = AppOwnersByRole[];

export default setApplicationsOwnersByAppIdsV2;
