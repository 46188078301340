import { Theme, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';

export const SeverityColor: FC<SeverityColorProps> = ({
  severity,
  children,
}) => {
  const theme = useTheme();
  const color =
    theme.palette.categoryRiskColor[
      typeof severity === 'string' ? severity.toLowerCase() : severity
    ];
  return children(color);
};

export interface SeverityColorProps {
  severity: keyof Theme['palette']['categoryRiskColor'];
  children: (color: string) => ReactElement;
}
