import { connectorsClient } from 'api-clients';
import addCredentials from './add-credentials/add-credentials';
import configureGitHubAppInstallation from './configure-github-app-installation/configure-github-app-installation';
import configureBitbucketAppInstallation from './configure-bitbucket-app-installation/configure-bitbucket-app-installation';
import configureIdentityProvider from './configure-identity-provider/configure-identity-provider';
import disableConnector from './disable-connector/disable-connector';
import enableConnector from './enable-connector/enable-connector';
import getConnectorResources from './get-connector-resources/get-connector-resources';
import getConnector from './get-connector/get-connector';
import getConnectorsByFamily from './get-connectors-by-family/get-connectors-by-family';
import getConnectors from './get-connectors/get-connectors';
import monitorConnectorResources from './monitor-connector-resources/monitor-connector-resources';
import removeCredentials from './remove-credentials/remove-credentials';
import updateCredentials from './update-credentials/update-credentials';
import validateAllCredentials from './validate-credentials/validate-all-credentials';
import validateSingleConnector from './validate-single-connector/validate-single-connector';
import getConnectorScript from './get-connector-script/get-connector-script';
import bulkConnectorEnableDisable from './bulk-connector-enable-disable/bulk-connector-enable-disable';
import getAdditionalTabsSettings from './get-additional-tabs-settings/get-additional-tabs-settings';
import saveConnectorAdditionalResources from './save-additional-tabs-settings/save-additional-tabs-settings';

const connectorService = {
  getConnectors: getConnectors(connectorsClient),
  getConnector: getConnector(connectorsClient),
  getConnectorsByFamily: getConnectorsByFamily(connectorsClient),
  addCredentials: addCredentials(connectorsClient),
  removeCredentials: removeCredentials(connectorsClient),
  enableConnector: enableConnector(connectorsClient),
  disableConnector: disableConnector(connectorsClient),
  bulkConnectorEnableDisable: bulkConnectorEnableDisable(connectorsClient),
  configureIdentityProvider: configureIdentityProvider(connectorsClient),
  configureGitHubAppInstallation:
    configureGitHubAppInstallation(connectorsClient),
  configureBitbucketAppInstallation:
    configureBitbucketAppInstallation(connectorsClient),
  validateAllCredentials: validateAllCredentials(connectorsClient),
  validateSingleConnector: validateSingleConnector(connectorsClient),
  getConnectorResources: getConnectorResources(connectorsClient),
  monitorConnectorResources: monitorConnectorResources(connectorsClient),
  updateCredentials: updateCredentials(connectorsClient),
  getConnectorScript: getConnectorScript(connectorsClient),
  getAdditionalTabsSettings: getAdditionalTabsSettings(connectorsClient),
  saveAdditionalTabsSettings:
    saveConnectorAdditionalResources(connectorsClient),
};

export default connectorService;
