import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import mutation from './update-app-owner-name.gql';
import { logger } from 'logging-utils';

const updateAppOwnerName = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (input: UpdateAppOwnerNameInput): Promise<boolean> => {
      try {
        const fetchPolicy: FetchPolicy = ApolloClientCache.NoCache;
        const results = await client.mutate<UpdateAppOwnerNameResponse>({
          mutation,
          fetchPolicy,
          variables: { input },
        });

        return results.data?.updateAppOwnerName ?? false;
      } catch (error) {
        logger.error('Failed to update app owner name', error);
        return false;
      }
    },
  };
};

interface UpdateAppOwnerNameResponse {
  updateAppOwnerName: boolean;
}

export interface UpdateAppOwnerNameInput {
  name: string;
  email: string;
}

export default updateAppOwnerName;
