import { createStore, derive } from 'store-utils';
import { PageSetting, ViewSettingType } from '../types';

const baseStore = createStore<ViewPreferencesFormState>(
  {
    viewPreferences: [],
    role: ViewSettingType.User,
    selectedPage: '',
    isLoadingSettings: false,
  },
  'View Preferences Store',
);

export const findPages = (
  pages: PageSetting[],
  path: string[],
): PageSetting[] => {
  if (!path.length) return pages;
  const pageIndex = pages.findIndex(item => item.page === path[0]);
  if (pageIndex === -1) return [];
  return pages[pageIndex].children
    ? findPages(pages[pageIndex].children || [], path.slice(1))
    : [];
};

export const getUpdatedPages = (
  pages: PageSetting[],
  value: boolean,
  path: string[],
): PageSetting[] => {
  if (!path.length) {
    return pages;
  }

  return pages.map(page => {
    if (page.page === path[0]) {
      if (path.length === 1) {
        const updatedChildren = page.children?.map(child => ({
          ...child,
          value: child.disabled ? child.value : value, // Only update if not disabled
        }));

        return {
          ...page,
          value: page.disabled ? page.value : value, // Only update if not disabled
          children: updatedChildren,
        };
      } else {
        return {
          ...page,
          children: page.children
            ? getUpdatedPages(page.children, value, path.slice(1))
            : [],
        };
      }
    }

    return page;
  });
};

const ViewPreferencesFormStore = derive(
  {
    subPages: get => {
      const { viewPreferences, selectedPage } = get(baseStore);
      return findPages(viewPreferences, selectedPage.split('-'));
    },
    isAllPagesSelected: get => {
      const { viewPreferences } = get(baseStore);
      return viewPreferences.every(item => item.value);
    },
    isAllSubPagesSelected: get => {
      const { selectedPage, viewPreferences } = get(baseStore);
      return findPages(viewPreferences, selectedPage.split('-')).every(
        item => item.value,
      );
    },
  },
  { proxy: baseStore },
);

interface ViewPreferencesFormState {
  viewPreferences: PageSetting[];
  role: string;
  selectedPage: string;
  isLoadingSettings: boolean;
}

export default ViewPreferencesFormStore;
