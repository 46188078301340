import { Theme } from '@mui/material';
import { IconType } from 'ox-common-types';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const IconWrapper: FC<React.PropsWithChildren<IconWrapperProps>> = ({
  size = 'small',
  icon: Icon,
  color = 'primaryLight',
}) => {
  const { classes, cx } = useStyles();

  return (
    <Icon
      className={cx(
        classes.iconWrapper,
        { [classes.small]: size === 'small' },
        { [classes.large]: size === 'large' },
        { [classes.primaryLight]: color === 'primaryLight' },
        { [classes.textPrimary]: color === 'textPrimary' },
      )}
    />
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  iconWrapper: {
    marginInlineEnd: theme.spacing(1),
    display: 'inline-block',
  },
  small: {
    width: '0.8rem',
    maxHeight: '0.8rem',
    height: 'auto',
  },
  large: {
    width: '1.25rem',
    maxHeight: '1.25rem',
    height: 'auto',
  },
  primaryLight: {
    color: theme.palette.primary.light,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
}));

interface IconWrapperProps {
  size?: 'small' | 'large';
  icon: IconType;
  color?: 'primaryLight' | 'textPrimary';
}

export default IconWrapper;
