import { GraphInfoOutput, createGraphInfo } from 'issue-trend-utils';
import { SeverityType } from 'ox-common-types';
import { ref, snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../app/stores/top-bar-store';
import InventoryFiltersStore from '../../dashboard-inventory/stores/inventory-filters-store';
import ScanStore from '../../new-scan/store/scan-store';
import dashboardService from '../services';
import DashboardStore from '../stores/dashboard-store';

export const fetchIssuesTrend = async () => {
  DashboardStore.loadingTrendGraph = true;
  const { filters } = snapshot(InventoryFiltersStore);
  const { dateRange } = snapshot(TopBarStore);
  const { isScanning } = snapshot(ScanStore);
  const [from, to] = calcDateRange(dateRange);
  const { scanID, realScanId } = snapshot(ScanStore);
  const { selectedTagIds, selectedAppOwnersEmails } = snapshot(
    GlobalDataViewSelector,
  );

  const response = await dashboardService.getIssuesScore.execute(
    {
      limit: 5,
      filters,
      ...(realScanId ? { scanId: realScanId } : scanID && { scanId: scanID }),
      owners: selectedAppOwnersEmails,
      tagIds: selectedTagIds,
      dateRange: { from, to },
    },
    !isScanning,
  );
  if (response) {
    const severitiesToShow = [
      SeverityType.Medium,
      SeverityType.High,
      SeverityType.Critical,
      SeverityType.Appoxalypse,
    ];
    const data: { data: GraphInfoOutput['data']; labels: number[] } = ref(
      createGraphInfo(response, severitiesToShow),
    );
    const hasLabels = data.labels.length > 0;

    if (hasLabels) {
      DashboardStore.issuesScore = data;
    } else {
      DashboardStore.issuesScore = undefined;
    }
    DashboardStore.noIssuesScore = !hasLabels;
  }
  DashboardStore.loadingTrendGraph = false;
};
