const Icon = props => {
  const { fill, ...otherProps } = props;
  return (
    <svg
      width='96'
      height='108'
      viewBox='0 0 96 108'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}>
      <path
        d='M44.3862 6.52284C46.5409 5.27884 49.1956 5.27884 51.3503 6.52284L87.2545 27.2522C89.4092 28.4962 90.7365 30.7952 90.7365 33.2832V74.7418C90.7365 77.2298 89.4092 79.5288 87.2545 80.7728L51.3503 101.502C49.1956 102.746 46.5409 102.746 44.3862 101.502L8.48201 80.7728C6.32733 79.5288 5 77.2298 5 74.7418V33.2832C5 30.7952 6.32733 28.4962 8.48201 27.2522L44.3862 6.52284Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M53.8503 2.19272L89.7545 22.922C93.4562 25.0592 95.7365 29.0089 95.7365 33.2832V74.7418C95.7365 79.0162 93.4562 82.9658 89.7545 85.103L53.8503 105.832C50.1486 107.969 45.5879 107.969 41.8862 105.832L5.98201 85.103C2.28033 82.9658 0 79.0162 0 74.7418V33.2832C0 29.0089 2.28033 25.0592 5.98201 22.922L41.8862 2.19272C45.5879 0.055552 50.1486 0.055553 53.8503 2.19272ZM51.3503 6.52284C49.1956 5.27884 46.5409 5.27884 44.3862 6.52284L8.48201 27.2522C6.32733 28.4962 5 30.7952 5 33.2832V74.7418C5 77.2298 6.32733 79.5288 8.48201 80.7728L44.3862 101.502C46.5409 102.746 49.1956 102.746 51.3503 101.502L87.2545 80.7728C89.4092 79.5288 90.7365 77.2298 90.7365 74.7418V33.2832C90.7365 30.7952 89.4092 28.4962 87.2545 27.2522L51.3503 6.52284Z'
        fill='url(#paint0_linear_24812_17765)'
      />
      <path
        d='M44.3854 12.5228C46.5401 11.2788 49.1947 11.2788 51.3494 12.5228L82.0575 30.2522C84.2122 31.4962 85.5395 33.7952 85.5395 36.2832V71.7418C85.5395 74.2298 84.2122 76.5288 82.0575 77.7728L51.3494 95.5022C49.1947 96.7462 46.5401 96.7462 44.3854 95.5022L13.6773 77.7728C11.5226 76.5288 10.1953 74.2298 10.1953 71.7418V36.2832C10.1953 33.7952 11.5226 31.4962 13.6773 30.2522L44.3854 12.5228Z'
        fill='white'
      />
      <path
        d='M44.3854 12.5228C46.5401 11.2788 49.1947 11.2788 51.3494 12.5228L82.0575 30.2522C84.2122 31.4962 85.5395 33.7952 85.5395 36.2832V71.7418C85.5395 74.2298 84.2122 76.5288 82.0575 77.7728L51.3494 95.5022C49.1947 96.7462 46.5401 96.7462 44.3854 95.5022L13.6773 77.7728C11.5226 76.5288 10.1953 74.2298 10.1953 71.7418V36.2832C10.1953 33.7952 11.5226 31.4962 13.6773 30.2522L44.3854 12.5228Z'
        fill={fill}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M55.6438 5.08377L86.3519 22.8131C91.1641 25.5915 94.1286 30.7261 94.1286 36.2828V71.7415C94.1286 77.2982 91.1642 82.4328 86.3519 85.2112L55.6438 102.941C50.8315 105.719 44.9026 105.719 40.0903 102.941L9.38221 85.2112C4.56995 82.4328 1.60547 77.2982 1.60547 71.7415V36.2828C1.60547 30.7261 4.56994 25.5915 9.38221 22.8131L40.0903 5.08377C44.9026 2.30541 50.8315 2.30541 55.6438 5.08377ZM51.3491 12.5225C49.1944 11.2785 46.5397 11.2785 44.385 12.5225L13.6769 30.2518C11.5223 31.4958 10.1949 33.7948 10.1949 36.2828V71.7415C10.1949 74.2295 11.5223 76.5285 13.677 77.7725L44.385 95.5018C46.5397 96.7458 49.1944 96.7458 51.3491 95.5018L82.0571 77.7725C84.2118 76.5285 85.5392 74.2295 85.5392 71.7415V36.2828C85.5392 33.7948 84.2118 31.4958 82.0571 30.2518L51.3491 12.5225Z'
        fill='url(#paint1_linear_24812_17765)'
      />
      <path
        d='M32.0664 68.4855C31.0492 68.4855 30.1783 68.0983 29.4538 67.3241C28.7294 66.5498 28.3672 65.6191 28.3672 64.5318C28.3672 63.4446 28.7294 62.5138 29.4538 61.7396C30.1783 60.9653 31.0492 60.5782 32.0664 60.5782H32.552C32.6907 60.5782 32.8371 60.6111 32.9913 60.677L41.4071 51.6825C41.3454 51.5178 41.3146 51.3613 41.3146 51.213V50.6941C41.3146 49.6069 41.6768 48.6761 42.4013 47.9019C43.1257 47.1276 43.9966 46.7405 45.0139 46.7405C46.0312 46.7405 46.902 47.1276 47.6265 47.9019C48.3509 48.6761 48.7131 49.6069 48.7131 50.6941C48.7131 50.76 48.6823 51.0895 48.6206 51.6825L53.3372 56.7234C53.4913 56.6575 53.6378 56.6246 53.7765 56.6246H54.7476C54.8863 56.6246 55.0327 56.6575 55.1868 56.7234L61.753 49.7057C61.6914 49.541 61.6605 49.3845 61.6605 49.2362V48.7173C61.6605 47.6301 62.0228 46.6993 62.7472 45.9251C63.4716 45.1508 64.3425 44.7637 65.3598 44.7637C66.3771 44.7637 67.248 45.1508 67.9724 45.9251C68.6968 46.6993 69.0591 47.6301 69.0591 48.7173C69.0591 49.8046 68.6968 50.7353 67.9724 51.5096C67.248 52.2838 66.3771 52.6709 65.3598 52.6709H64.8743C64.7356 52.6709 64.5891 52.638 64.435 52.5721L57.8688 59.5898C57.9305 59.7545 57.9613 59.911 57.9613 60.0593V60.5782C57.9613 61.6654 57.5991 62.5962 56.8746 63.3705C56.1502 64.1447 55.2793 64.5318 54.262 64.5318C53.2447 64.5318 52.3739 64.1447 51.6494 63.3705C50.925 62.5962 50.5628 61.6654 50.5628 60.5782V60.0593C50.5628 59.911 50.5936 59.7545 50.6552 59.5898L45.9387 54.5489C45.7845 54.6148 45.6381 54.6478 45.4994 54.6478H45.0139C44.9522 54.6478 44.6439 54.6148 44.0891 54.5489L35.6732 63.5434C35.7349 63.7082 35.7657 63.8647 35.7657 64.0129V64.5318C35.7657 65.6191 35.4035 66.5498 34.6791 67.3241C33.9546 68.0983 33.0837 68.4855 32.0664 68.4855Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_24812_17765'
          x1='47.8683'
          y1='4.51251'
          x2='47.8683'
          y2='103.513'
          gradientUnits='userSpaceOnUse'>
          <stop stop-color='white' stop-opacity='0' />
          <stop offset='1' stop-color={fill} />
        </linearGradient>
        <linearGradient
          id='paint1_linear_24812_17765'
          x1='47.867'
          y1='10.5121'
          x2='47.867'
          y2='97.5121'
          gradientUnits='userSpaceOnUse'>
          <stop stop-color={fill} />
          <stop offset='1' stop-color='white' stop-opacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Icon;
