import { List, ListItem, Typography } from '@mui/material';
import { FC } from 'react';
import { SeverityChangedReason } from '../../../types/issues-types';
import { mappingSeveritiesItems } from '../../../utils/issue-utils';
import SeverityChangeReason from './SeverityChangeReason';

const SeverityChangeReasonList: FC<
  React.PropsWithChildren<SeverityChangeReasonProps>
> = props => {
  const { data, withDescription } = props;
  const sevItems = mappingSeveritiesItems(data);
  return (
    <div>
      {Object.entries(sevItems).map((item, index) => {
        return (
          <div key={index}>
            <Typography color='text.secondary' variant='body2'>
              {item[0]}
            </Typography>
            <List>
              {item &&
                item[1].map((change, i) => (
                  <ListItem key={`${i}_${change.shortName}`} sx={{ px: 1 }}>
                    <SeverityChangeReason
                      value={change.changeNumber}
                      shortName={change.shortName}
                      text={change.reason}
                      withDescription={withDescription}
                    />
                  </ListItem>
                ))}
            </List>
          </div>
        );
      })}
    </div>
  );
};

export interface SeverityChangeReasonProps {
  data: SeverityChangedReason[];
  withDescription?: boolean;
}

export default SeverityChangeReasonList;
