import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  JiraIssueType,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import query from './get-dependent-by-project-fields.gql';

const getDependentByProjectFields = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<string, Nullable<GetIssuesTypesResponse>> => {
  return {
    execute: async (
      projectKey: string,
    ): Promise<Nullable<GetIssuesTypesResponse>> => {
      try {
        const results = await client.query<GetIssuesTypesResponse>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            provider: TicketProvider.Jira,
            projectKey,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to fetch dependent by project fields', error);
        return null;
      }
    },
  };
};

export interface GetIssuesTypesResponse {
  getIssueTypes: {
    issueTypes: Nullable<JiraIssueType[]>;
    error: Nullable<string>;
  };
}

export default getDependentByProjectFields;
