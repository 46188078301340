import { Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export const VerticalSplitPane: FC<VerticalSplitPaneProps> = ({
  content,
  children,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.verticalSplitPane}>
      <div className={classes.content}>{content}</div>
      {children({ className: classes.drawer })}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  verticalSplitPane: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    overflow: 'hidden',
    flex: '1 1 auto',
    paddingBlockEnd: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
  },
}));

export interface VerticalSplitPaneProps {
  content: ReactNode;
  children(options: { className: string }): ReactNode;
}
