import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

export const MultiLineEllipsis: FC<
  React.PropsWithChildren<MultiLineEllipsisProps>
> = ({ lines, lineHeightCustom, className, ...restOfProps }) => {
  const { classes } = useStyles();

  return (
    <Typography
      {...restOfProps}
      className={[classes.multiLineElipsis, className].join(' ')}
      style={{
        lineClamp: lines,
        WebkitLineClamp: lines,
        lineHeight: lineHeightCustom ? lineHeightCustom : 1.2,
      }}
    />
  );
};

const useStyles = makeStyles()(() => ({
  multiLineElipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    display: '-webkit-box',
    whiteSpace: 'pre-wrap',
    WebkitBoxOrient: 'vertical',
  },
}));

export interface MultiLineEllipsisProps extends TypographyProps {
  lines: number;
  lineHeightCustom?: number;
}
