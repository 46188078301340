import { Box, Button } from '@mui/material';
import { AppPages, navigateWithParameters } from 'app-navigator';
import { closeSnackbar } from 'snackbar-utils';

export const ISSUE_RESOLVED_SNACKBAR_KEY = 'issue-resolved-snackbar-key';

const handleGoToIssueClicked = (issueId: string) => {
  navigateWithParameters(AppPages.ResolvedIssues, {
    issueId,
  });
  closeSnackbar(ISSUE_RESOLVED_SNACKBAR_KEY);
};

export const GetIssueFallbackSnackBarContent = (issueId: string) => (
  <Box display='flex' alignItems='center' mt={0.2}>
    Congratulations! This issue is resolved and can be found on the resolved
    issues page.
    <Button
      sx={{
        textDecoration: 'underline',
        textTransform: 'none',
        color: 'white',
      }}
      size='small'
      variant='text'
      onClick={() => handleGoToIssueClicked(issueId)}>
      Go to resolved Issue
    </Button>
  </Box>
);
