import { useTheme } from '@mui/material';
import { ModalDialog } from 'ox-react-components';
import { FC } from 'react';
import DataViewModalForm from './DataViewModalForm';
import { GlobalDataViewSelectorOption } from './data-view-selector-types';
import { getScopeDisplayName } from './data-view-utils';

const DataViewModal: FC<DataViewModalProps> = props => {
  const theme = useTheme();

  const {
    open,
    onClose,
    isLoading,
    dataViewSelectorOption,
    isValidDisplayName,
    shouldShowSSOSyncGroupString,
    isLoadingNameUpdate,
    isLoadingIdUpdate,
    isLoadingDeleteOption,
  } = props;

  const scopeDisplayName = getScopeDisplayName(
    dataViewSelectorOption.scopeName,
  );

  return (
    <ModalDialog
      modalColor={theme.palette.info.main}
      aria-label={scopeDisplayName}
      open={open}
      onClose={onClose}
      closeOnClickOutSide={true}
      modalHeader={scopeDisplayName}>
      <DataViewModalForm
        onClose={onClose}
        isLoading={isLoading}
        dataViewSelectorOption={dataViewSelectorOption}
        isValidDisplayName={isValidDisplayName}
        shouldShowSSOSyncGroupString={shouldShowSSOSyncGroupString}
        isLoadingNameUpdate={isLoadingNameUpdate}
        isLoadingIdUpdate={isLoadingIdUpdate}
        isLoadingDeleteOption={isLoadingDeleteOption}
      />
    </ModalDialog>
  );
};

interface DataViewModalProps {
  open: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isEditNameEnabled: boolean;
  isLoading: boolean;
  dataViewSelectorOption: GlobalDataViewSelectorOption;
  isValidDisplayName: boolean;
  shouldShowSSOSyncGroupString: boolean;
  isLoadingNameUpdate: boolean;
  isLoadingIdUpdate: boolean;
  isLoadingDeleteOption: boolean;
}

export default DataViewModal;
