import { important } from 'ox-common-types';
import { OxDataGrid } from 'ox-react-components';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { DataGridHandle, SortDirection } from 'react-data-grid';
import { makeStyles } from 'tss-react/mui';
import config from '../config/api-inventory-config.json';

import { ApiInventoryItem } from '../types';
import { useApiInventoryTable } from '../hooks/use-api-inventory-table';
import {
  loadApiInventories,
  selectInventory,
  sortApiInventory,
} from '../actions/api-inventory-actions';
import { useInfinityScroll } from 'react-utils';

const ApiInventoryTable: FC<ApiInventoryTableProps> = ({
  inventories,
  loading,
  selectedId,
  offset,
  orderDirection,
  orderField,
}) => {
  const { classes } = useStyles();
  const columns = useApiInventoryTable(classes);
  const tableRef = useRef<DataGridHandle>(null);

  useEffect(() => {
    if (offset === 0) {
      tableRef.current?.scrollToRow(0);
    }
  }, [offset]);

  const { onScroll } = useInfinityScroll({
    load: loadApiInventories,
    threshold: 0.9,
    loading,
  });
  const onRowClick = useCallback((row: ApiInventoryItem) => {
    selectInventory(row.id);
  }, []);

  const sortColumnsInfo = useMemo(() => {
    return orderField && orderDirection
      ? [
          {
            columnKey: orderField,
            direction: orderDirection,
          },
        ]
      : undefined;
  }, [orderField, orderDirection]);

  return (
    <div className={classes.tableContainer}>
      <OxDataGrid
        tableRef={tableRef}
        activeRowValue={selectedId}
        activeRowKey='id'
        rowHeight={config.table.rowHeight}
        onScroll={onScroll}
        columns={columns}
        rows={inventories}
        loading={loading}
        onSort={sortApiInventory}
        sortColumns={sortColumnsInfo}
        onRowClick={onRowClick}
      />
    </div>
  );
};

const useStyles = makeStyles()({
  tableContainer: {
    borderRadius: 10,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  textCenter: {
    textAlign: important('center'),
  },
  flexCenter: {
    justifyContent: 'center',
  },
  headerCell: {
    overflowWrap: 'break-word',
  },
  noPadding: {
    paddingInline: important('0'),
  },
  textOverFlow: {
    lineHeight: 1,
    whiteSpace: 'normal', // allow wrapping of text
    wordWrap: 'break-word', // allow long words to wrap
    overflowWrap: 'break-word', // same as wordWrap but with better handling of non-English languages
    maxWidth: '100%',
  },
  columnsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
});

export interface ApiInventoryTableProps {
  inventories: ApiInventoryItem[];
  orderDirection?: SortDirection;
  orderField?: string;
  loading: boolean;
  selectedId?: string;
  offset: number;
}

export default ApiInventoryTable;
