export interface OXCustomEvent {
  orgId: OXCustomEventObject;
  [key: string]: OXCustomEventObject;
}

export interface OXFullstoryCustomEvent {
  orgId_str: string;
  [key: string]: FullstoryPropTypes;
}

export interface OXCustomEventObject {
  type: CustomEventValueTypes;
  value: FullstoryPropTypes;
}

export type FullstoryPropTypes =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[];

export enum CustomEventValueTypes {
  String = 'str',
  Integer = 'int',
  Real = 'real',
  Date = 'date',
  Bool = 'bool',
  StringList = 'strs',
  IntegerList = 'ints',
  RealList = 'reals',
  DateList = 'dates',
  BoolList = 'bools',
}

export enum OXFullStoryEventNames {
  ConnectedSourceControl = 'Connected Source Control',
  exportedIssues = 'Exported Issues',
}
