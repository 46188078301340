import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import mutation from './delete-organization.gql';

const deleteOrganization = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (): Promise<boolean> => {
      const results = await client.mutate<{
        deleteOrganization: boolean;
      }>({
        mutation,
      });

      if (!results || !results.data) {
        return false;
      }

      return results.data.deleteOrganization;
    },
  };
};

export default deleteOrganization;
