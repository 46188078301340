import { deleteCookie } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { isDevelopment, isProd } from 'env-utils';
import { logger } from 'logging-utils';
import { dataDogLogsConfig, dataDogRumConfig } from './config/data-dog-config';

export function initDataDogRum() {
  const { applicationId, clientToken, ...restConfig } = dataDogRumConfig;
  if (!applicationId || !clientToken) {
    if (isProd()) {
      logger.error(
        'Cannot init data dog telemetry, missing required parameters',
      );
    }
    return;
  }
  deleteCookie('_dd_s');
  datadogRum.init({
    applicationId,
    clientToken,
    ...restConfig,
  });
}

export function startDDRumSession() {
  datadogRum.startSessionReplayRecording();
}

export function initDataDogLogs() {
  const { clientToken, ...restConfig } = dataDogLogsConfig;
  if (!clientToken) {
    if (isProd()) {
      logger.error('Cannot init data dog logs, missing required parameters');
    }
    return;
  }
  if (isDevelopment()) {
    restConfig.forwardErrorsToLogs = false;
  }
  datadogLogs.init({
    clientToken,
    ...restConfig,
  });
}

export function setDataDogUser(user: IUser) {
  datadogRum.setUser(user);
}

export function setDataDogContext(context: Record<string, unknown>) {
  datadogRum.setRumGlobalContext(context);
}

export function addDataDogLogsContext(key: string, value: unknown) {
  datadogLogs.addLoggerGlobalContext(key, value);
}

export function setDataDogAction(name: string, payload?: Object) {
  datadogRum.addAction(name, payload);
}

export interface IUser {
  id?: string;
  email?: string;
  name?: string;
  [key: string]: unknown;
}
export interface IDatadogRumConfig {
  applicationId: string;
  clientToken: string;
  site: string;
  service: string;
  version: string;
  env: string;
  sampleRate: number;
  trackInteractions: boolean;
  defaultPrivacyLevel: DefaultPrivacyLevel;
}
