import { LinearProgress, Theme, Typography } from '@mui/material';
import { AppPages } from 'app-navigator';
import { Panel } from 'ox-react-components';
import { FC, useEffect } from 'react';
import { FaBullhorn } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useSnapshot } from 'valtio';
import { WhatsNewPageStore, loadWhatsNewHistory } from 'whats-new-logic';
import WhatsNewGroupedList from '../components/WhatsNewGroupedList';

export const WhatsNewPageContainer: FC = () => {
  const { classes } = useStyles();
  const { loading } = useSnapshot(WhatsNewPageStore);

  useEffect(() => {
    loadWhatsNewHistory();
  }, []);

  return (
    <div>
      <Panel p={2} className={classes.header}>
        <FaBullhorn className={classes.icon} />
        <Typography
          variant='h6'
          color='primary'
          className={classes.headerTitle}>
          What's New
        </Typography>
        <NavLink to={AppPages.ReleaseNotes} className={classes.link}>
          <Typography variant='body1' color='primary'>
            See full release notes here
          </Typography>
        </NavLink>
      </Panel>
      {loading.isError() && (
        <Typography variant='body2' color='text.secondary'>
          Unable to load What's New items, please try again later
        </Typography>
      )}
      {loading.isPending() && <LinearProgress />}
      {loading.isSuccess() && <WhatsNewGroupedList />}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    maxHeight: 56,
  },
  icon: {
    width: '1.3rem',
    height: '1.3rem',
    color: theme.palette.primary.main,
  },
  headerTitle: {
    gap: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
    flexGrow: 1,
    marginBlockStart: 1,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
