import {
  CircularProgress,
  Collapse,
  Fade,
  IconButton,
  Theme,
} from '@mui/material';
import {
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { makeStyles } from 'tss-react/mui';

export const LazyCollapsible: FC<PropsWithChildren<LazyCollapsibleProps>> = ({
  children,
  open,
  loading,
  collapsed,
  collapsedSize = 50,
  onToggle,
  moreActions,
}) => {
  const { classes } = useStyles();

  const handleToggle = useCallback(() => {
    onToggle(!open);
  }, [open, onToggle]);

  const toggleButton = (
    <div className={classes.actions} style={{ height: collapsedSize }}>
      <div className={classes.actionsMore}>{moreActions}</div>
      <div className={classes.actionsButton}>
        {loading ? (
          <CircularProgress size='1rem' />
        ) : (
          <IconButton onClick={handleToggle} size='small'>
            {open ? <MdExpandLess /> : <MdExpandMore />}
          </IconButton>
        )}
      </div>
    </div>
  );

  return (
    <div className={classes.paper}>
      <Collapse
        in={open}
        collapsedSize={collapsedSize}
        classes={{
          wrapper: !open ? classes.wrapper : undefined,
        }}>
        <>
          {/* open with content */}
          {open && <div>{children}</div>}

          {/* fade in the collapsed content */}
          {!open && (
            <Fade in={!open} timeout={1000}>
              {collapsed}
            </Fade>
          )}
        </>

        {toggleButton}
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    paddingInline: theme.spacing(1),
    borderRadius: 10,
    position: 'relative',
  },
  actions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    display: 'grid',
    gridTemplateColumns: 'auto 2rem',
  },
  actionsMore: {
    display: 'flex',
    alignItems: 'center',
  },
  actionsButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    height: '100%',
    alignItems: 'center',
  },
}));

interface LazyCollapsibleProps {
  collapsedSize?: number;
  open: boolean;
  loading: boolean;
  collapsed: ReactElement;
  onToggle(open: boolean): void;
  moreActions?: ReactNode;
}
