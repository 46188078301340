import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import query from './can-user-request-access.gql';
import { CanUserRequestAccessResponse } from '../../request-access-types';

const canUserRequestAccess = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (): Promise<Nullable<CanUserRequestAccessResponse>> => {
      try {
        const results = await client.query<CanUserRequestAccessQuery>({
          query,
        });

        if (!results || !results.data) {
          return null;
        }

        return results.data.canUserRequestAccess;
      } catch {
        return null;
      }
    },
  };
};

interface CanUserRequestAccessQuery {
  canUserRequestAccess: CanUserRequestAccessResponse;
}

export default canUserRequestAccess;
