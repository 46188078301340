import { IconType } from 'ox-common-types';
import { ConnectorStatus } from '../../connectors/connectors-types';
import { BadgeStatusType } from './badgeIcon.types';

export interface SupplyChainItem {
  header: SupplyChainHeader;
  issues: SupplyChainIssue;
  connectors?: {
    items?: SupplyChainConnector[];
    showOnlyCustomIcon: boolean;
    addOxConnectorIconToList: boolean;
    oxConnectorTooltipTitle?: string;
  };
  coverage?: SupplyChainCoverage;
  type: SupplyChainItemType;
}

export interface SupplyChainHeader {
  label: string;
  categoryName: string;
  link?: string; // optional - in case a category couldn't be resolved
  score?: string;
}

export interface SupplyChainIssue {
  label?: SupplyChainIssuesLabel;
  counters: SupplyChainIssueCounters[];
  componentType?: IssuesComponentType;
}
export interface SupplyChainIssueCounters {
  value: number;
  severity: string;
  issuePageFilters: string;
}

export interface SupplyChainIssuesLabel {
  text: string;
  link?: string;
}

export enum IssuesComponentType {
  Counters = 'Counters',
  Checked = 'Checked',
  Label = 'Label',
  NotApplicable = 'NotApplicable',
}

export interface SupplyChainConnector {
  name: string;
  iconURL: string;
  status: BadgeStatusType;
  isBuiltIn: boolean;
  badgeIcon: IconType | string;
  connectorStatus: ConnectorStatus;
  displayName: string;
}

export interface SupplyChainCoverage {
  value: number;
  reasons: string[];
  covered: boolean | null;
}

export enum SupplyChainItemType {
  Element = 'element',
  Category = 'category',
}

export enum SupplyChainHeaderTypes {
  cicd = 'CI/CD',
  registry = 'Registry',
  sourceControl = 'Source Control',
  cloudDeployment = 'Cloud Deployment',
}
