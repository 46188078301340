import { Paper, Theme, Typography, useTheme } from '@mui/material';
import { IssueBadge } from 'ox-react-components';
import { memo } from 'react';
import { CiWarning } from 'react-icons/ci';
import { Handle, NodeProps } from 'reactflow';
import { makeStyles } from 'tss-react/mui';

const VulnerabilityNode = memo(
  ({ id, data, isConnectable, sourcePosition, targetPosition }: NodeProps) => {
    const theme = useTheme();
    const { classes } = useStyles();
    return (
      <>
        <Handle
          type='source'
          position={sourcePosition!}
          style={{ visibility: 'hidden' }}
          isConnectable={isConnectable}
        />
        <Paper
          className={classes.node}
          elevation={15}
          sx={{
            border: `3px solid ${resolveBorderColor(data, theme)}`,
            backgroundColor: resolveBackground(data, theme),
          }}>
          {data.vulnerable && <CiWarning color='red' />}
          <Typography color={resolveColor(data, theme)} variant='caption'>
            {data.label}
          </Typography>
          {/* <Chip
          size='small'
          label={`XSS`}
          variant='outlined'
          sx={{
            ml: 0.5,
            backgroundColor:
              theme.palette.mode === 'light' ? '#F7F9FE' : '#0F1321',
          }}
        /> */}

          <div className={classes.issues}>
            <IssueBadge
              backgroundColor={
                theme.palette.mode === 'light' ? '#F7F9FE' : '#0F1321'
              }
              severitiesAlerts={data.issues}
            />
          </div>
        </Paper>
        <Handle
          type='target'
          position={targetPosition!}
          style={{ visibility: 'hidden' }}
          isConnectable={isConnectable}
        />
      </>
    );
  },
);

const resolveBackground = (data, theme: Theme) => {
  if (data.vulnerable) {
    return '#FFD580';
  }
  if (data.selected) {
    return '#9A8AFE';
  }

  return theme.palette.mode === 'light' ? '#F7F9FE' : '#0F1321';
};

const resolveBorderColor = (data, theme: Theme) => {
  if (data.selected) {
    return '#9A8AFE';
  }
  if (data.vulnerable) {
    return '#FFD580';
  }

  return theme.palette.divider;
};

const resolveColor = (data, theme: Theme) => {
  if (data.vulnerable) {
    return 'red';
  }
  if (data.selected) {
    return theme.palette.mode === 'dark'
      ? theme.palette.text.secondary
      : '#000';
  }
  return theme.palette.text.secondary;
};

const useStyles = makeStyles()((theme: Theme) => ({
  node: {
    borderRadius: theme.spacing(2),
    width: 250,
    height: 50,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    gap: 1,
    cursor: 'pointer',
    // backgroundColor: theme.palette.mode === 'light' ? '#F7F9FE' : '#0F1321',
  },
  vulnerabilityType: {
    top: -15,
    right: -15,
    zIndex: 100,
    position: 'absolute',
  },
  issues: { bottom: -15, zIndex: 100, position: 'absolute' },
}));

export interface VulnerabilityNodeProps {}

export default VulnerabilityNode;
