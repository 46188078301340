import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable, ServiceExecute } from 'ox-common-types';
import mutation from './add-user-consent.gql';

const addUserConsent = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<void, Nullable<boolean>> => {
  return {
    execute: async (): Promise<Nullable<boolean>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data.addUserConsent;
      } catch (error) {
        logger.error('Add user consent failed', error);
        return null;
      }
    },
  };
};

interface MutationResponse {
  addUserConsent: boolean;
}

export default addUserConsent;
