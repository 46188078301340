import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ERelevance } from '../../irrelevant-apps-types';
import mutation from './remove-apps-exclusion.gql';

const removeAppsExclusion = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (removeAppExclusionInput: RemoveAppsExclusionInput) => {
      try {
        const results = await client.mutate<{
          removeAppsExclusion: RemoveAppsExclusionResponse;
        }>({
          mutation,
          variables: { input: removeAppExclusionInput },
        });

        if (!results || !results.data) {
          logger.error(
            'Received an invalid response from remove application exclusion',
          );
          throw new Error('Failed to remove application exclusion');
        }

        return results.data.removeAppsExclusion;
      } catch (error) {
        logger.error(
          `Failed to remove exclusion for ${removeAppExclusionInput.exclusionIds}`,
          error,
        );
        throw new Error('Failed to remove application exclusion');
      }
    },
  };
};

export interface RemoveAppsExclusionInput {
  exclusionIds: string[];
  relevance: ERelevance;
}

export interface RemoveAppsExclusionResponse {
  removeAppsExclusion: RemoveAppSingleExclusionRes[];
}

export interface RemoveAppSingleExclusionRes {
  isSucceeded: boolean;
  exclusionId: string;
}

export default removeAppsExclusion;
