import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import { ICreateDefaultProfileInput } from '../../types/policy-profile-types';
import mutation from './create-default-profile-service.gql';

const createDefaultProfile = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<ICreateDefaultProfileInput, void> => {
  return {
    execute: async (
      ceedOxProfileInput: ICreateDefaultProfileInput,
    ): Promise<void> => {
      try {
        await client.mutate({
          fetchPolicy: 'no-cache',
          mutation,
          variables: {
            ceedOxProfileInput,
          },
        });
      } catch (e) {
        throw new Error('Failed create default profile');
      }
    },
  };
};

export default createDefaultProfile;
