import { Box, Typography } from '@mui/material';
import { IconType } from 'ox-common-types';
import { ExternalLinkButton } from 'ox-react-components';
import { FC, ReactNode } from 'react';
import IconWrapper from './IconWrapper';

const GeneralInfoSection: FC<React.PropsWithChildren<InfoFieldProps>> = ({
  icon,
  valueIcon,
  title,
  value,
  link,
  flex,
}) => {
  return (
    <div>
      <Box display='flex' alignItems='center' mb={1}>
        {icon && <IconWrapper size='small' icon={icon} />}
        <Typography
          color='text.secondary'
          variant='body2'
          component='span'
          sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
          {title}
        </Typography>
      </Box>
      <Box display='flex' alignItems='center' gap={0.5} flexDirection='row'>
        {valueIcon && <IconWrapper size='small' icon={valueIcon} />}
        <Typography color='text.primary' variant='body2' component='span'>
          {value}
        </Typography>

        <Typography
          color='text.primary'
          variant='body1'
          sx={{ wordBreak: 'break-word' }}>
          {link && (
            <ExternalLinkButton
              sx={{ fontSize: 10 }}
              size='small'
              href={link}
              target='_blank'
            />
          )}
        </Typography>
      </Box>
    </div>
  );
};

interface InfoFieldProps {
  icon?: IconType;
  title: string;
  value?: string | ReactNode;
  link?: string;
  valueIcon?: IconType;
  flex?: number | string;
}

export default GeneralInfoSection;
