import { reportClient } from 'api-clients';
import getApiInventories from './get-api-inventories/get-api-inventories';
import getApiInventoriesFiltersLazy from './get-api-inventories-filters-lazy/get-api-inventories-filters-lazy';
import getSingleApiInventory from './get-single-api-inventory/get-single-api-inventory';
import getExportedApiItems from './get-exported-api-items/get-exported-api-items';
import fetchIssuesStatistics from './get-api-inventory-issues-statistics/get-api-inventory-issues-statistics-service';

const apiInventoryService = {
  getIssuesStatistics: fetchIssuesStatistics(reportClient),
  getApiInventories: getApiInventories(reportClient),
  getApiInventoriesFiltersLazy: getApiInventoriesFiltersLazy(reportClient),
  getSingleInventory: getSingleApiInventory(reportClient),
  exportApiItems: getExportedApiItems(reportClient),
};

export default apiInventoryService;
