import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';

export const OrganizationsStore = createStore<OrganizationsStoreState>(
  {
    organizations: [],
    chosenOrganization: null,
    isLoading: null,
  },
  'Organization Store 2',
);

interface OrganizationsStoreState {
  organizations: Organization[];
  chosenOrganization: Nullable<Organization>;
  isLoading: Nullable<boolean>;
}

interface Organization {
  id: string;
  name: string;
  display_name: string;
  branding: Nullable<Branding>;
}

interface Branding {
  logo_url: string;
  colors: Record<string, unknown>;
}
