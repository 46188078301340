import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ScaVulnerability } from '../../types/issues-types';
import query from './get-vulnerabilities.gql';

const fetchScaVulnerabilities = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (input: GetVulnerabiltiesInput) => {
      const results = await client.query<{
        getScaVulnerabilities: VulnerabilitiesResponse;
      }>({
        query,
        variables: { getScaVulnerabilitiesInput: input },
      });

      if (!results.data) return null;
      return results.data.getScaVulnerabilities;
    },
  };
};

export interface VulnerabilitiesResponse {
  vulnerabilities: ScaVulnerability[];
  offset: number;
  total: number;
  severityFactorBreakdown: { sfName: string; count: number }[];
}

export interface GetVulnerabiltiesInput {
  issueId: string;
  offset: number;
  tab: string;
  search: string;
  cveSeverityFactors: string[];
  orderField?: string;
  orderDirection?: string;
}

export default fetchScaVulnerabilities;
