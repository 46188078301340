import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchIssuesInput,
  FetchIssuesResponse,
} from '../../types/issues-types';
import query from './get-issues.gql';

const fetchIssues = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getIssuesInput: FetchIssuesInput,
      cache: boolean = true,
    ) => {
      try {
        const results = await client.query<FetchIssuesResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: { getIssuesInput: getIssuesInput },
        });
        if (!results.data) return null;
        return results.data.getIssues;
      } catch (e) {
        logger.error('Failed to fetch issues', e);
        return null;
      }
    },
  };
};

export interface Input {
  getIssuesInput: FetchIssuesInput;
  cache?: boolean;
  isDemo?: boolean;
}
export type Output = { getIssues: FetchIssuesResponse };

export default fetchIssues;
