import { OxExclusionLevel, OxExclusionMode } from '@oxappsec/exclusion-service';
import { snapshot, useSnapshot } from 'valtio';
import {
  disabledPolicy,
  excludeAlert,
  getNearlyExpiredExclusions,
  irrelevantApp,
} from '../../../exclusions/actions/exclusions-actions';
import { getExcludeAlertInput } from '../../../exclusions/utils/exclusion-utils';
import IssuesStore from '../../active-issues/stores/issues-store';
import PipelineIssuesStore from '../../pipeline-issues/stores/pipeline-issues-store';
import IssuesExclusionsStore from '../stores/issues-exclusions-store';
import { openErrorSnackbar } from 'snackbar-utils';

export const onIrrelevantApp = () => {
  const { selectedIssue } = snapshot(IssuesStore);
  if (selectedIssue) {
    irrelevantApp({
      appId: selectedIssue.app.id,
      appName: selectedIssue.app.name,
      issueName: selectedIssue.mainTitle,
      policyId: selectedIssue.policy.id,
      policyName: selectedIssue.policy.name,
      issueId: selectedIssue.issueId,
      appType: selectedIssue.app.type,
      policyCategory: selectedIssue.category.name,
      comment: selectedIssue.exclusionAppComment,
    });
  }
};

export const onDisablePolicy = () => {
  const { selectedIssue } = snapshot(IssuesStore);
  if (selectedIssue) {
    disabledPolicy({
      appId: selectedIssue.app.id,
      appName: selectedIssue.app.name,
      issueName: selectedIssue.mainTitle,
      policyId: selectedIssue.policy.id,
      policyName: selectedIssue.policy.name,
      issueId: selectedIssue.issueId,
      appType: selectedIssue.app.type,
      policyCategory: selectedIssue.category.name,
      comment: selectedIssue.exclusionPolicyComment,
    });
  }
};

export const onExcludeIssue = () => {
  const { selectedAggs, excludeOxRuleId } = snapshot(IssuesExclusionsStore);
  const { selectedIssue } = snapshot(IssuesStore);
  const { selectedIssue: pipelineSelectedIssue } =
    snapshot(PipelineIssuesStore);
  const currentSelectedIssue = selectedIssue || pipelineSelectedIssue;
  const excludeLevel = currentSelectedIssue?.exclusions.find(
    exclusion => exclusion.oxRuleId === excludeOxRuleId,
  )?.level;

  const snackbarError =
    'Please select at least one aggregation item to exclude the issue';

  if (selectedIssue) {
    if (
      selectedAggs &&
      selectedAggs.length === 0 &&
      excludeLevel === OxExclusionLevel.aggItem
    ) {
      openErrorSnackbar(snackbarError);
      return;
    }
    const excludeAlertInput = getExcludeAlertInput({
      selectedIssue,
      exclusionMode: OxExclusionMode.fullScan,
      selectedAggs: selectedAggs,
    });

    excludeAlert(excludeAlertInput, selectedIssue.mainTitle);
  } else if (pipelineSelectedIssue) {
    if (selectedAggs && selectedAggs.length === 0) {
      openErrorSnackbar(snackbarError);
      return;
    }
    const pipelineExcludeAlertInput = getExcludeAlertInput({
      selectedIssue: pipelineSelectedIssue,
      exclusionMode: OxExclusionMode.pipelineScan,
      selectedAggs: selectedAggs,
    });

    excludeAlert(pipelineExcludeAlertInput, pipelineSelectedIssue.mainTitle);
  }
  getNearlyExpiredExclusions();
};

export const useIssuesExclusions = () => useSnapshot(IssuesExclusionsStore);
