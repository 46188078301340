import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, TicketRef } from 'ox-common-types';
import { TicketingVendor } from '../../types/ticketing-types';
import mutation from './link-ticket.gql';
import { IssuePages } from '../../../issues/common/types/issues-types';

const linkTicket = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (linkTicketInput: LinkTicketInput) => {
      try {
        const results = await client.mutate<LinkTicketResponse>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { linkTicketInput },
        });
        return results.data?.linkTicket;
      } catch (error) {
        logger.error('Failed to link ticket', error);
        return null;
      }
    },
  };
};

export interface LinkTicketInput {
  issueId: string;
  scanType: 'fullScan' | 'pipelineScan';
  ticketId: string;
  ticketingVendor: TicketingVendor;
  issueType: IssuePages;
}

export interface LinkTicketResponse {
  linkTicket: TicketRef;
}
export default linkTicket;
