import { Order } from 'ox-common-types';
import { ScaVulnerability } from '../../../../../types/issues-types';

export interface VulnerabilitiesStoreState {
  data: ScaVulnerability[];
  total: number;
  offset: number;
  loading: boolean;
  searchValue: string;
  contextOptions: CveContext[];
  severityFactors: string[];
  orderField?: string;
  orderDirection?: Order;
}

export enum VulnerabilityTab {
  Direct = 'Direct',
  Indirect = 'Indirect',
}

export interface CveContext {
  sfName: string;
  count: number;
}

export const defaultOrderBy = {
  field: 'cve',
  direction: Order.Asc,
};
