import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable, ServiceExecute } from 'ox-common-types';
import query from './get-exported-api-items.gql';
import { GetApiInventoriesInput } from '../get-api-inventories/get-api-inventories';

export type ExportApiItemsInput = Pick<
  GetApiInventoriesInput,
  'filters' | 'orderBy' | 'owners' | 'search'
>;

export interface ExportApiItemsResponse {
  requestId: string;
}
export interface QueryResponse {
  getApiSecurityItemsCsv: Nullable<ExportApiItemsResponse>;
}

const getExportedApiItems = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<ExportApiItemsInput, Nullable<ExportApiItemsResponse>> => {
  return {
    execute: async (getApiSecurityInput: ExportApiItemsInput) => {
      try {
        const results = await client.query<QueryResponse>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { getApiSecurityInput },
        });
        if (!results.data) return null;

        return results.data.getApiSecurityItemsCsv;
      } catch (error) {
        logger.error('Failed to export csv', error);
        return null;
      }
    },
  };
};

export default getExportedApiItems;
