import { Box, Divider, Typography } from '@mui/material';
import { AppSpinner } from 'common-components';
import React, { FC, useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { fetchIssueTrendAction } from '../actions/issue-trend-action';
import IssueTrendStore from '../stores/issue-trend-store';
import IssuesStore from '../../issues/active-issues/stores/issues-store';
import IssuesTrend from '../components/IssuesTrend';
import TopBarStore from '../../app/stores/top-bar-store';
import { calcDateRange } from '../../app/store-actions/top-bar-store-actions';
import IssuesTrendLastChange from '../components/IssueTrendLastChange';
import {
  SEVERITY_DID_NOT_CHANGE,
  SEVERITY_STABLE,
} from '../utils/issue-trend-utils';

const IssueTrendContainer: FC = () => {
  const { dateRange } = useSnapshot(TopBarStore);
  const rangeUnix = useMemo(() => calcDateRange(dateRange), [dateRange]);
  const { loading, error, severityChangeHistory, isEmpty, isJustOneChange } =
    useSnapshot(IssueTrendStore);
  const { selectedIssue, loadingIssue } = useSnapshot(IssuesStore);

  useEffect(() => {
    const range = {
      from: rangeUnix[0],
      to: rangeUnix[1],
    };
    if (selectedIssue)
      fetchIssueTrendAction(
        {
          issueId: selectedIssue.issueId,
          dateRange: range,
        },
        selectedIssue,
      );
  }, [rangeUnix, selectedIssue]);

  if (error) {
    return (
      <Typography variant='body1' color='error' textAlign='center'>
        Oops! Something went wrong... Please try again later
      </Typography>
    );
  }

  if (loading || loadingIssue) {
    return <AppSpinner text='Loading..' />;
  } else if (severityChangeHistory && selectedIssue) {
    return (
      <Box display='flex' sx={{ height: '100%' }}>
        <Box flex={3} width={250} paddingRight={0.5}>
          <IssuesTrendLastChange
            index={0}
            title='First Change'
            emptyValidation={isEmpty || isJustOneChange}
            emptyMessage={isEmpty ? SEVERITY_DID_NOT_CHANGE : SEVERITY_STABLE}
          />
        </Box>
        <Divider orientation='vertical' flexItem />
        <Box flex={12} minHeight={160} paddingLeft={2} paddingRight={2}>
          <IssuesTrend />
        </Box>
        <Divider orientation='vertical' flexItem />
        <Box flex={3} width={250} paddingLeft={0.5}>
          <IssuesTrendLastChange
            index={severityChangeHistory.length - 1}
            title='Last Change'
            emptyValidation={isEmpty}
            emptyMessage={SEVERITY_DID_NOT_CHANGE}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Typography
        height='100%'
        display='flex'
        color='GrayText'
        alignItems='center'
        justifyContent='center'>
        No data to display
      </Typography>
    );
  }
};

export default IssueTrendContainer;
