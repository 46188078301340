import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable, ServiceExecute } from 'ox-common-types';
import mutation from './modify-apps-tags.gql';

const modifyAppsTags = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<ModifyAppsTagsInput, Nullable<MutationResponse>> => {
  return {
    execute: async (
      input: ModifyAppsTagsInput,
    ): Promise<Nullable<MutationResponse>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: {
            input,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to modifiy applications tags', error);
        return null;
      }
    },
  };
};

export interface ModifyAppsTagsInput {
  addedTagsIds: string[];
  appIds: string[];
  removedTagsIds: string[];
}

export interface MutationResponse {
  modifyAppsTags: {
    acknowledge: boolean;
  };
}

export default modifyAppsTags;
