import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import {
  FetchIssuesInput,
  FetchResolvedIssuesResponse,
} from '../../../common/types/issues-types';
import query from './get-resolved-issues.gql';

const fetchIssues = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getResolvedIssuesInput: FetchIssuesInput,
      cache: boolean = true,
    ) => {
      const results = await client.query<FetchResolvedIssuesResponse>({
        query,
        fetchPolicy: cache
          ? ApolloClientCache.CacheFirst
          : ApolloClientCache.NoCache,
        variables: { getResolvedIssuesInput },
      });

      if (!results.data) return null;
      return results.data.getResolvedIssues;
    },
  };
};

export default fetchIssues;
