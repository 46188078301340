import { FC } from 'react';
import { Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { CustomLink } from 'ox-react-components';
import NodeTooltip from '../NodeTooltip';

const ArtifactItem: FC<React.PropsWithChildren<ArtifactItemProps>> = ({
  image,
  imageLink,
  registryName,
  imageCreatedAt,
}) => {
  return (
    <NodeTooltip title='Artifact Details'>
      <Typography variant='caption'>
        Image:{' '}
        <b>
          <CustomLink value={image} href={imageLink} />
        </b>
      </Typography>
      <Typography variant='caption'>
        Registry: <b>{registryName}</b>
      </Typography>
      <Typography variant='caption'>
        Created:{' '}
        <b>
          {formatDistanceToNow(new Date(imageCreatedAt), {
            addSuffix: true,
          })}
        </b>
      </Typography>
    </NodeTooltip>
  );
};

export interface ArtifactItemProps {
  image: string;
  imageLink: string;
  registryName: string;
  imageCreatedAt: string;
}

export { ArtifactItem };
