import { Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { SeverityColor } from 'severity-utils';
import { makeStyles } from 'tss-react/mui';

import {
  SeverityDiffStatus,
  SeverityMappingToName,
} from '../../issues/common/types/issues-types';
import IssueTrendStore from '../stores/issue-trend-store';
import { useSnapshot } from 'valtio';
import { formatDate, getTitles } from '../utils/issue-trend-utils';
import IssueCardSeverityDiff from '../../issues/common/components/IssueCard/IssueCardSeverityDiff';
import { important } from 'ox-common-types';
import { FaInfoCircle } from 'react-icons/fa';
import IconWrapper from '../../applications/components/ApplicationCard/IconWrapper';

const IssuesTrendLastChange = ({
  emptyValidation,
  title,
  index,
  emptyMessage,
}) => {
  const { classes } = useStyles();

  const theme = useTheme();
  const {
    severityChangeHistory,
    manualSeverityChangeReason,
    severityChangeReasonsRemoved,
    severityChangedReasonsAdded,
  } = useSnapshot(IssueTrendStore);

  return (
    <>
      <div className={classes.title}>
        <IconWrapper icon={FaInfoCircle} />
        <Typography variant='body1' component='span'>
          {title} &nbsp;
        </Typography>
      </div>
      {emptyValidation && (
        <Typography variant='body1' color={theme.palette.info.main}>
          {emptyMessage}
        </Typography>
      )}

      {!emptyValidation && (
        <>
          {' '}
          <Typography variant='body2' color='text.secondary'>
            <span
              style={{
                fontWeight: 600,
              }}>
              {getTitles(
                index,
                manualSeverityChangeReason,
                severityChangedReasonsAdded,
                severityChangeReasonsRemoved,
              )}
            </span>
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Date&nbsp;
            <span
              style={{
                fontWeight: 600,
              }}>
              {severityChangeHistory[index]?.date &&
                formatDate(new Date(severityChangeHistory[index]?.date))}
            </span>
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Severity&nbsp;
            <SeverityColor
              severity={SeverityMappingToName(
                parseInt(severityChangeHistory[index]?.severity),
              )}>
              {color => (
                <span
                  style={{
                    color,
                    textTransform: 'uppercase',
                    fontWeight: 600,
                  }}>
                  {SeverityMappingToName(
                    parseInt(severityChangeHistory[index]?.severity),
                  )}
                </span>
              )}
            </SeverityColor>
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Tool Severity&nbsp;
            <SeverityColor
              severity={severityChangeHistory[index]?.originalToolSeverity}>
              {color => (
                <span
                  style={{
                    color,
                    textTransform: 'uppercase',
                    fontWeight: 600,
                  }}>
                  {severityChangeHistory[index]?.originalToolSeverity}
                </span>
              )}
            </SeverityColor>
          </Typography>
          <Tooltip
            title={
              severityChangedReasonsAdded[index] && (
                <IssueCardSeverityDiff
                  //@ts-ignore
                  date={severityChangeHistory[index]?.date}
                  diff={severityChangedReasonsAdded[index].map(sr => ({
                    shortName: sr,
                    change: 1,
                    status: SeverityDiffStatus.New,
                  }))}
                />
              )
            }>
            <Typography variant='body2' color='text.secondary'>
              Severity Factors Added:{' '}
              <span
                style={{
                  color: theme.palette.success.main,
                  // textTransform: 'uppercase',
                  fontWeight: 600,
                }}>
                {severityChangedReasonsAdded[index]?.length || 0}{' '}
              </span>
              &nbsp;
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              severityChangeReasonsRemoved[index] && (
                <IssueCardSeverityDiff
                  //@ts-ignore
                  date={severityChangeHistory[index]?.date}
                  diff={severityChangeReasonsRemoved[index].map(sr => ({
                    shortName: sr,
                    change: 1,
                    status: SeverityDiffStatus.Removed,
                  }))}
                />
              )
            }>
            <Typography variant='body2' color='text.secondary'>
              Severity Factors Removed:{' '}
              <span
                style={{
                  color: theme.palette.error.main,
                  // textTransform: 'uppercase',
                  fontWeight: 600,
                }}>
                {severityChangeReasonsRemoved[index]?.length || 0}
              </span>
              &nbsp;
            </Typography>
          </Tooltip>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBlockEnd: theme.spacing(2),
    paddingInlineStart: 1,
    '& span': {
      fontWeight: important('600'),
    },
    '& svg': {
      color: theme.palette.primary.light,
    },
  },
}));

export default IssuesTrendLastChange;
