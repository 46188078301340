import { FC, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';

import OscarIssueMatrixContainer from '../../../../../../../oscar/containers/OscarIssueMatrixContainer';

const OscarIssueMatrixTabContainer: FC<
  React.PropsWithChildren<IssueDrawerTabProps>
> = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);

  const techniqueIds = useMemo(
    () => selectedIssue?.oscarData?.map(t => t.id) || [],
    [selectedIssue?.oscarData],
  );

  return (
    <>
      {selectedIssue && (
        <OscarIssueMatrixContainer
          issueId={selectedIssue.id}
          techniqueIds={techniqueIds}
        />
      )}
    </>
  );
};

export default OscarIssueMatrixTabContainer;
