import { logger } from 'logging-utils';
import policiesService from '../../services';
import ProfileStore from '../../store/profiles';
import { IFetchProfilesOverviewRes } from '../../types/policy-profile-types';
import {
  setGeneralPoliciesPageError,
  setLoadingProfile,
} from '../policies-selection-ui-actions/policies-selection-ui-actions';

export default async function fetchActiveProfileOverviewAction(): Promise<void> {
  try {
    setLoadingProfile(true);
    const {
      categories,
      profiles,
      policiesPerCategoriesInfo,
      enabledPolicies,
      totalPolicies,
      profile,
    }: IFetchProfilesOverviewRes = await fetchProfilesSelectionPureAction();

    ProfileStore.activeProfileId = profile.profileId;
    ProfileStore.profiles = profiles;
    ProfileStore.profileInContext = profile;
    ProfileStore.categories = categories;
    ProfileStore.policiesPerCategoriesInfo = policiesPerCategoriesInfo;
    ProfileStore.enabledPolicies = enabledPolicies;
    ProfileStore.totalPolicies = totalPolicies;
  } catch (error) {
    logger.error('fetch ActiveProfileOverview error', { error });
    setGeneralPoliciesPageError({
      errorTitle: 'Missing Active Profile Content',
      errorMsg:
        'Failed to load active profile content, please try again later.',
    });
  } finally {
    setLoadingProfile(false);
  }
}

export async function fetchProfilesSelectionPureAction(): Promise<IFetchProfilesOverviewRes> {
  const result: IFetchProfilesOverviewRes =
    await policiesService.fetchActiveProfileOverview.execute();

  return result;
}
