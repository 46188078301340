import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import mutation from './request-access.gql';
import {
  RequestAccessInput,
  RequestAccessResponse,
} from '../../request-access-types';

const requestAccess = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      input: RequestAccessInput,
    ): Promise<Nullable<RequestAccessResponse>> => {
      try {
        const results = await client.mutate<RequestAccessMutation>({
          mutation,
          variables: { input },
        });

        if (!results || !results.data) {
          return null;
        }

        return results.data.requestAccess;
      } catch {
        return null;
      }
    },
  };
};

interface RequestAccessMutation {
  requestAccess: RequestAccessResponse;
}

export default requestAccess;
