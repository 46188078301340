import { Nullable } from 'ox-common-types';
import { Polling } from 'polling-utils';
import { snapshot } from 'valtio';
import { GradualUpdateResponse } from '../scan-types';
import scanService from '../services';
import GradualStore, { initialState } from '../store/gradual-store';
import ScanStore, { ScanPhase } from '../store/scan-store';
import { setScanError, setScanPhase } from '../store/scan-store-actions';
import { AppEvents } from 'common-events';
import { resolveScanPhaseText } from '../utils';

export const startGradualPhase = () => {
  setScanPhase(ScanPhase.Gradual);
  gradualPoller.startPolling();
};

const doneGradualCallback = () => {
  setScanPhase(ScanPhase.Done);
  document.dispatchEvent(new CustomEvent(AppEvents.Scan.ScanEnd));
  clearStore();
};

export const pollGradual = () => {
  const { scanID, realScanId } = snapshot(ScanStore);
  if (realScanId) {
    return scanService.getGradualUpdate.execute({ scanID: realScanId });
  } else if (scanID) {
    return scanService.getGradualUpdate.execute({ scanID });
  } else {
    return null;
  }
};

export const handleGradualCallback = (
  response: Nullable<GradualUpdateResponse | undefined>,
) => {
  // This fixes the issue of dispatching scan end event on errors in polling
  if (response === undefined) {
    return true;
  }
  if (response) {
    const { isStillScanning } = response;
    setGradualUpdate(response);
    return isStillScanning;
  }
  return false;
};

export const clearStore = () => {
  gradualPoller.cancelPolling();
  Object.keys(initialState).map(key => (GradualStore[key] = initialState[key]));
};

export const setGradualUpdate = ({
  scannedApps,
  totalApps,
  phase,
  scanProgressItems,
}: GradualUpdateResponse) => {
  GradualStore.scanned = scannedApps;
  GradualStore.total = totalApps;
  GradualStore.scanProgressItems = scanProgressItems.map(item =>
    resolveScanPhaseText(item),
  );
  if (
    phase === ScanPhase.ConnectDataSources ||
    phase === ScanPhase.Finalizing
  ) {
    ScanStore.phase = phase as ScanPhase;
  }
};

export const gradualPoller = new Polling<GradualUpdateResponse | undefined>(
  pollGradual,
  handleGradualCallback,
  setScanError,
  doneGradualCallback,
);
