import { currentPage, navigateWithParameters } from 'app-navigator';
import { Nullable } from 'ox-common-types';
import {
  ConditionalFilter,
  ConditionalFiltersCondition,
  FilterItems,
} from 'ox-filter-utils';
import { snapshot } from 'valtio';

const addMissingPropertiesToConditionalFilters = (
  conditionalFilters: ConditionalFilter[],
) => {
  if (!conditionalFilters || conditionalFilters.length === 0) {
    return [];
  }
  return conditionalFilters.map(filter => {
    const _filter = { ...filter };
    if (!_filter.hasOwnProperty('greaterThan')) {
      _filter.greaterThan = null;
    }
    if (!_filter.hasOwnProperty('lessThan')) {
      _filter.lessThan = null;
    }
    if (!_filter.hasOwnProperty('values')) {
      _filter.values = [];
    }
    _filter.values = [..._filter.values];

    return _filter;
  });
};

const generateConditionalFilters = (params: {
  existingConditionalFilters: ConditionalFilter[];
  type: string;
  value: string;
  condition: ConditionalFiltersCondition;
}) => {
  const { type, value, condition, existingConditionalFilters } = params;
  const conditionalFilters = addMissingPropertiesToConditionalFilters(
    existingConditionalFilters,
  );
  const oppositeCondition =
    condition === ConditionalFiltersCondition.OR
      ? ConditionalFiltersCondition.NOT
      : ConditionalFiltersCondition.OR;
  const existingItem = conditionalFilters.find(
    item => item.fieldName === type && item.condition === condition,
  );
  const oppositeItem = conditionalFilters.find(
    item => item.fieldName === type && item.condition === oppositeCondition,
  );

  if (!existingItem) {
    conditionalFilters.push({
      condition,
      fieldName: type,
      values: [value],
    });
  } else {
    const valueExists = existingItem?.values.includes(value);
    existingItem.values = valueExists
      ? existingItem.values.filter(v => v !== value)
      : [...existingItem.values, value];
  }
  if (oppositeItem) {
    oppositeItem.values = oppositeItem.values.filter(v => v !== value);
  }
  const conditionalFiltersNoEmptyItems = conditionalFilters.filter(item => {
    if (item.condition === ConditionalFiltersCondition.BETWEEN) {
      return true;
    } else {
      return item.values.length > 0;
    }
  });
  return conditionalFiltersNoEmptyItems;
};

const translateFiltersToConditionalFilters = (filters: {}) => {
  const conditionalFilters = Object.keys(filters).map(key => {
    const values = filters[key];
    return {
      condition: ConditionalFiltersCondition.OR,
      fieldName: key,
      values: values,
    };
  });
  return conditionalFilters;
};

const onToggleSelectAllFilterItems = (
  type: string,
  store: {
    filterItems: Nullable<FilterItems>;
    conditionalFilters: ConditionalFilter[];
    //...Other store properties that are not relevant for this function
    // This function can be used only if the store use conditionalFilters and filterItems
  },
) => {
  const currentStoreSnapshot = snapshot(store);
  const filterItems: Nullable<FilterItems> = currentStoreSnapshot.filterItems;
  const conditionalFilters: ConditionalFilter[] =
    currentStoreSnapshot.conditionalFilters;
  const allFilterItems = filterItems && filterItems[type]?.items;
  const currentSelectedFilterItems = conditionalFilters.find(
    filterItem => filterItem.fieldName === type,
  )?.values;

  const isAllItemsSelected =
    currentSelectedFilterItems &&
    currentSelectedFilterItems?.length > 0 &&
    filterItems &&
    allFilterItems?.length === currentSelectedFilterItems.length;

  if (isAllItemsSelected) {
    const conditionalFiltersUpdated = conditionalFilters.filter(
      filter => filter.fieldName !== type,
    );
    store.conditionalFilters = conditionalFiltersUpdated;
    navigateWithParameters(currentPage(), {
      conditionalFilters: conditionalFiltersUpdated,
    });

    return;
  }

  const conditionalFiltersValues = allFilterItems?.reduce(
    (acc: string[], item) => {
      acc.push(item.filterId);
      return acc;
    },
    [],
  );
  if (conditionalFiltersValues && conditionalFiltersValues.length > 0) {
    const updatedConditionalFilterItem: ConditionalFilter = {
      condition: ConditionalFiltersCondition.OR,
      fieldName: type,
      values: conditionalFiltersValues,
    };

    const updatedConditionalFilters = conditionalFilters.filter(
      filterItem => filterItem.fieldName !== type,
    );
    updatedConditionalFilters.push(updatedConditionalFilterItem);

    navigateWithParameters(currentPage(), {
      conditionalFilters: updatedConditionalFilters,
    });
  }
};

export const conditionalFiltersUtils = {
  addMissingPropertiesToConditionalFilters,
  generateConditionalFilters,
  translateFiltersToConditionalFilters,
  onToggleSelectAllFilterItems,
};
