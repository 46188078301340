import { AggregationColumn, AggregationItem } from 'ox-common-types';

export const createNewDate = (date: string) => {
  if (date !== null) {
    try {
      const newDate = new Date(date);
      if (!isNaN(newDate.valueOf())) {
        return newDate;
      }
    } catch {
      return null;
    }
  }
};

export const createNewDateFromNumber = (date: string) => {
  if (date !== null) {
    try {
      const newDate = new Date(Number(date));
      if (!isNaN(newDate.valueOf())) {
        return newDate;
      }
    } catch {
      return null;
    }
  }
};

export const filterConfigByTypes = (
  columns: AggregationColumn[] | undefined,
  items: AggregationItem[],
): AggregationColumn[] => {
  if (!columns) return [];
  let filterdColumns: AggregationColumn[] = [];

  columns.map((type: AggregationColumn) => {
    return items.map(item => {
      if (item[type.key]) {
        if (!filterdColumns.find(column => column === type))
          filterdColumns.push(type);
      }
      return filterdColumns;
    });
  });
  return filterdColumns;
};
