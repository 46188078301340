import { getCategoryByName } from '@oxappsec/ox-consolidated-categories';
import { AppPages, encodeForUrl } from 'app-navigator';
import { logger } from 'logging-utils';
import { ConditionalFiltersCondition } from 'ox-filter-utils';
import { ConnectorsByFamilyObject } from '../../connectors/connectors-types';
import { SupplyChainResponseItem } from '../types/supply-chain-backend-types';
import {
  SupplyChainHeaderTypes,
  SupplyChainItem,
  SupplyChainItemType,
} from '../types/supplychain-types';
import { createSupplyChainConnectors } from './supply-chain-connectors-utils';
import { createSupplyChainIssues } from './supply-chain-issues-utils';

export const createSupplyChainInfo = (
  supplyChainResponse: SupplyChainResponseItem[],
  connectorsByFamily: ConnectorsByFamilyObject[],
): SupplyChainItem[] => {
  return (
    supplyChainResponse &&
    supplyChainResponse.map(item => {
      const issues = createSupplyChainIssues(item);
      const connectors = createSupplyChainConnectors(item, connectorsByFamily);
      const itemType = resolveItemType(item);
      const link = createItemLink(item, itemType);

      return {
        header: { ...item.header, link },
        issues,
        connectors,
        coverage: item.coverage,
        type: itemType,
      };
    })
  );
};

const resolveItemType = (item: SupplyChainResponseItem) => {
  const name = item.header.label || item.header.categoryName;
  const isElement = Object.values<string>(SupplyChainHeaderTypes).includes(
    name,
  );

  return isElement ? SupplyChainItemType.Element : SupplyChainItemType.Category;
};

const createItemLink = (
  item: SupplyChainResponseItem,
  itemType: SupplyChainItemType,
) => {
  try {
    const { id } = getCategoryByName(item.header.label);
    return itemType === SupplyChainItemType.Element
      ? createConnectorLink(item.header.label)
      : createCategoryLink(id);
  } catch (err) {
    logger.error('Failed to resolve category ID from name');
  }
};

const createCategoryLink = (categoryId: number): string => {
  const conditionalFilters = [
    {
      condition: ConditionalFiltersCondition.OR,
      fieldName: 'categories',
      values: [categoryId.toString()],
    },
  ];
  const conditionalFiltersEncoded = encodeForUrl(conditionalFilters);
  return `${AppPages.ActiveIssues}?conditionalFilters=${conditionalFiltersEncoded}`;
};

const createConnectorLink = (connectorFamily: string): string => {
  const searchQuery = encodeURIComponent(connectorFamily);
  return `${AppPages.Connectors}?search=${searchQuery}`;
};

export const checkForSupplyChainHeader = (label: string) => {
  if (
    label !== SupplyChainHeaderTypes.cicd &&
    label !== SupplyChainHeaderTypes.cloudDeployment
  ) {
    if (
      label !== SupplyChainHeaderTypes.registry &&
      label !== SupplyChainHeaderTypes.sourceControl
    ) {
      return true;
    }
  } else {
    return false;
  }
};
