import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import { PageSetting } from '../../types';
import mutation from './reset-role-view-settings.gql';

const resetRoleViewSettings = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      input: ResetViewSettingsInput,
    ): Promise<Nullable<PageSetting[]>> => {
      try {
        const results = await client.mutate<SetViewSettingsRes>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            input,
          },
        });
        if (!results.data || !results.data.resetRoleViewSettings) {
          return null;
        }
        return results.data.resetRoleViewSettings.settings;
      } catch (error) {
        logger.error('Failed to reset role view settings', error);
        return null;
      }
    },
  };
};
interface ResetViewSettingsInput {
  role: string;
}
interface SetViewSettingsRes {
  resetRoleViewSettings: { settings: PageSetting[] };
}

export default resetRoleViewSettings;
