import { createSimpleAsyncAction } from 'async-utils';
import { snapshot } from 'valtio';
import ApiInventoryStore, {
  defaultOrderBy,
} from '../stores/api-inventory-store';
import {
  setApiInventories,
  setApiItemIssuesStatistics,
  setInventoryOffset,
  setInventoryTotal,
  setSelectedInventory,
  setSelectedInventoryId,
  setTopSearchValue,
} from '../stores/api-inventory-store-actions';
import apiInventoryService from '../services';
import { setTotalInventoryFilters } from '../stores/api-inventory-filters-store-actions';
import ApiInventoryFiltersStore from '../stores/api-inventory-filters-store';
import { Nullable, Order } from 'ox-common-types';
import { isActiveIssuesPage } from '../../issues/common/utils/lazy-filters';
import IssuesStore from '../../issues/active-issues/stores/issues-store';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import {
  FetchFileResponse,
  fileDownloadService,
  fileDownloadStore,
  handleDownloadProgressPercentage,
  handleFileReadyAction,
} from 'download-utils';
import { closeSnackbar, openSnackbar } from 'snackbar-utils';
import { debounce, isNull } from 'lodash-es';
import { FileDownloadPoller } from 'polling-utils';
import { logger } from 'logging-utils';
import { AppPages, navigate } from 'app-navigator';
import { getApiInventoryOpenFilterItems } from './api-inventory-filters-actions';
import ScanStore from '../../new-scan/store/scan-store';

export const sortApiInventory = (field?: string) => {
  field ? sortByField(field) : sortByDefault();
};

const sortByDefault = () => {
  if (ApiInventoryStore.orderField === defaultOrderBy.field) {
    ApiInventoryStore.orderDirection =
      ApiInventoryStore.orderDirection === Order.Asc ? Order.Desc : Order.Asc;
  } else {
    ApiInventoryStore.orderField = defaultOrderBy.field;
    ApiInventoryStore.orderDirection = defaultOrderBy.direction;
  }
  executeSort();
};

const sortByField = (field: string) => {
  ApiInventoryStore.orderDirection =
    ApiInventoryStore.orderField === field
      ? ApiInventoryStore.orderDirection === Order.Asc
        ? Order.Desc
        : Order.Asc
      : Order.Asc;
  ApiInventoryStore.orderField = field;

  executeSort();
};

const executeSort = () => {
  ApiInventoryStore.offset = 0;
  loadApiInventories({ update: true });
};

export const loadApiInventories = createSimpleAsyncAction(
  async (params?: { update?: boolean; cache?: boolean; search?: string }) => {
    const { update = false, cache = true, search = '' } = params || {};
    const { selectedTagIds, selectedAppOwnersEmails } = snapshot(
      GlobalDataViewSelector,
    );

    if (update) {
      ApiInventoryStore.offset = 0;
    }
    if (search) {
      setTopSearchValue(search);
    }

    const { total, offset, orderField, orderDirection, topSearchValue } =
      snapshot(ApiInventoryStore);

    if (total < offset) return;

    const { scanID, realScanId } = snapshot(ScanStore);

    const response = await apiInventoryService.getApiInventories.execute(
      {
        offset: offset,
        orderBy: {
          field: orderField,
          direction: orderDirection,
        },
        filters: isActiveIssuesPage()
          ? { issueIds: [IssuesStore.selectedIssueId] }
          : ApiInventoryFiltersStore.filterBy,
        search: topSearchValue,
        limit: 50,
        owners: selectedAppOwnersEmails,
        tagIds: selectedTagIds,
        ...(realScanId ? { scanId: realScanId } : scanID && { scanId: scanID }),
      },
      cache,
    );

    if (response && response.apiSecurityItems) {
      const { apiSecurityItems, total, totalFiltered } = response;

      setInventoryOffset(offset + response.apiSecurityItems.length);
      setApiInventories(apiSecurityItems, update);
      setInventoryTotal(total);
      setTotalInventoryFilters(totalFiltered);

      if (apiSecurityItems.length === 1 && !isActiveIssuesPage()) {
        selectInventory(apiSecurityItems[0].id);
      }
    }
  },
  {
    asyncState: ApiInventoryStore.loading,
    errorMessage: 'Failed to load API BOM',
  },
);

export const loadStatistics = createSimpleAsyncAction(
  async (apiItem: string, cache: boolean = true) => {
    const results = await apiInventoryService.getIssuesStatistics.execute(
      {
        filters: { apiItem: [apiItem] },
      },
      cache,
    );

    if (results) {
      setApiItemIssuesStatistics(results);
    }
  },
  {
    asyncState: ApiInventoryStore.apiItemIssuesStatisticsLoading,
    errorMessage: 'Failed to load issues statistics',
  },
);

export const selectInventory = async (inventoryId?: string) => {
  const { inventories } = snapshot(ApiInventoryStore);
  const inventory = inventories?.find(i => i.id === inventoryId) || null;
  setSelectedInventory(inventory);
  setSelectedInventoryId(inventoryId || null);

  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);

  if (inventoryId) {
    params.set('apiInventoryId', inventoryId);
  } else {
    params.delete('apiInventoryId');
  }
  navigate(AppPages.ApiInventory, params.toString());
};

export const exportApiItemsToFile = async ({
  applyFilters,
}: {
  applyFilters: boolean;
}) => {
  const snackBarKey = 'download-csv-api-snackbar';
  const handleDownloadFinished = () => {
    fileDownloadStore.apiItemsCsvDownloadInProgress = false;
    fileDownloadStore.downloadProgress = 0;
    closeSnackbar(snackBarKey);
  };

  try {
    const { orderDirection, orderField, total, topSearchValue } =
      snapshot(ApiInventoryStore);
    const { filterBy } = snapshot(ApiInventoryFiltersStore);
    const { downloadProgress } = snapshot(fileDownloadStore);

    fileDownloadStore.apiItemsCsvDownloadInProgress = true;

    openSnackbar(
      `Exporting APIs to CSV`,
      {
        variant: 'default',
        persist: true,
        key: snackBarKey,
      },
      {
        hideCloseIcon: false,
        isShowingSpinner: true,
        spinnerVariant: 'determinate',
        value: downloadProgress,
        valueCb: () => {
          return snapshot(fileDownloadStore).downloadProgress;
        },
      },
    );

    const response = await apiInventoryService.exportApiItems.execute(
      applyFilters
        ? {
            orderBy: {
              field: orderField,
              direction: orderDirection,
            },
            filters: filterBy,
            search: topSearchValue,
          }
        : { orderBy: {} },
    );

    if (isNull(response) || !response.requestId) {
      handleDownloadFinished();
      openSnackbar(`Failed generating csv, please try again later`, {
        variant: 'error',
      });
      return;
    }

    const poller = new FileDownloadPoller<FetchFileResponse['fetchReadyFile']>(
      () => {
        return fileDownloadService.fetchReadyFile.execute({
          requestId: response.requestId,
        });
      },
      (response: Nullable<FetchFileResponse['fetchReadyFile']>) => {
        if (response) {
          if (response.error) {
            closeSnackbar(snackBarKey);
            openSnackbar(`Failed generating csv, please try again later`, {
              variant: 'error',
            });
            return false;
          }

          handleDownloadProgressPercentage(total);

          if (response.isFileReady) {
            handleFileReadyAction(
              response,
              `Successfully downloaded APIs CSV file`,
            );
          }
          return !response.isFileReady;
        } else {
          return false;
        }
      },
      () => {},
      handleDownloadFinished,
    );

    poller.startPolling();
    setTimeout(() => {
      poller.cancelPolling();
    }, 1200000);
  } catch (error) {
    logger.error(error);
    handleDownloadFinished();
    openSnackbar(`Export to CSV file failed. Please try again later...`, {
      variant: 'warning',
    });
  }
};

export const onTopSearchChange = debounce(
  (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopSearchValue(e.target.value);
    loadApiInventories({ update: true });
    getApiInventoryOpenFilterItems();
  },
  500,
);
