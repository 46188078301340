import { Position } from 'reactflow';
import { AttackPathType } from '../attack-path-types';

function getClosestSideCenter(node, referenceNode) {
  const { width, height, positionAbsolute } = node;
  const referencePosition = referenceNode.positionAbsolute;

  const centerX = positionAbsolute.x + width / 2;
  const centerY = positionAbsolute.y + height / 2;
  const deltaX = referencePosition.x + referenceNode.width / 2 - centerX;
  const deltaY = referencePosition.y + referenceNode.height / 2 - centerY;

  //hack: custom logic for CVE node  edges positions
  if (
    node.data.type === AttackPathType.Intelligence &&
    (referenceNode.data.type === AttackPathType.Cve ||
      referenceNode.data.type === AttackPathType.CveItem ||
      referenceNode.data.type === AttackPathType.Library)
  ) {
    return {
      x: centerX,
      y: positionAbsolute.y + height,
    };
  }

  if (
    referenceNode.data.type === AttackPathType.Intelligence &&
    (node.data.type === AttackPathType.Cve ||
      node.data.type === AttackPathType.CveItem ||
      node.data.type === AttackPathType.Library)
  ) {
    return {
      x: centerX,
      y: positionAbsolute.y,
    };
  }

  if (
    node.data.type === AttackPathType.App &&
    referenceNode.data.type === AttackPathType.Saas
  ) {
    return {
      x: positionAbsolute.x + width,
      y: centerY,
    };
  }

  if (
    referenceNode.data.type === AttackPathType.App &&
    node.data.type === AttackPathType.Saas
  ) {
    return {
      x: positionAbsolute.x,
      y: centerY,
    };
  }

  // Determine which side (top, right, bottom, left) is closest
  if (Math.abs(deltaX) > Math.abs(deltaY)) {
    // Closer to either left or right side
    return {
      x: deltaX > 0 ? positionAbsolute.x + width : positionAbsolute.x,
      y: centerY,
    };
  } else {
    // Closer to either top or bottom side
    return {
      x: centerX,
      y: deltaY > 0 ? positionAbsolute.y + height : positionAbsolute.y,
    };
  }
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const nodeCenterX = node.positionAbsolute.x + node.width / 2;
  const nodeCenterY = node.positionAbsolute.y + node.height / 2;
  const deltaX = intersectionPoint.x - nodeCenterX;
  const deltaY = intersectionPoint.y - nodeCenterY;

  // Determine which side is closest to the intersection point
  if (Math.abs(deltaX) > Math.abs(deltaY)) {
    return deltaX > 0 ? Position.Right : Position.Left;
  } else {
    return deltaY > 0 ? Position.Bottom : Position.Top;
  }
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getClosestSideCenter(source, target);
  const targetIntersectionPoint = getClosestSideCenter(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

const MaxChildsInRow = {
  [AttackPathType.App]: 4,
  [AttackPathType.Library]: 4,
};

export const getMaxChildsInRow = (type: AttackPathType) => {
  return MaxChildsInRow[type] || 3;
};
