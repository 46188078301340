import { Theme } from '@mui/material';
import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export const EmptyPageAvatar: FC<PropsWithChildren<EmptyPageAvatarProps>> = ({
  text,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.emptyPageAvatar}>
      <div className={classes.centered}>
        <div className={cx(classes.bubble, classes.bubbleBottomLeft)}>
          {text}
        </div>
        <img
          srcSet='/images/mosh.png'
          alt='Ox at work'
          className={classes.image}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  emptyPageAvatar: {
    width: '100%',
    height: '100%',
    minWidth: '600px',
    minHeight: '600px',
    position: 'relative',
    overflowY: 'hidden',
  },
  centered: {
    textAlign: 'center',
    width: 600,
    height: 600,
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  '@keyframes wobble': {
    '0%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-5px)' },
    '100%': { transform: 'translateY(0)' },
  },
  bubble: {
    position: 'relative',
    ...(theme.typography.body1 as CSSProperties),
    width: 300,
    background: theme.palette.primary.main,
    borderRadius: '40px',
    padding: theme.spacing(3),
    textAlign: 'start',
    color: theme.palette.common.white,
    animation: '$wobble 2s ease-in-out infinite',
    '&:hover': {
      animationPlayState: 'paused',
    },
  },
  bubbleBottomLeft: {
    '&:before': {
      content: '""',
      width: '0px',
      height: '0px',
      position: 'absolute',
      borderRight: `24px solid ${theme.palette.primary.main}`,
      borderLeft: '12px solid transparent',
      borderTop: `12px solid ${theme.palette.primary.main}`,
      borderBottom: '20px solid transparent',
      right: '32px',
      bottom: '-24px',
    },
  },
  image: {
    maxHeight: 400,
  },
}));

export interface EmptyPageAvatarProps {
  text: ReactNode;
}
