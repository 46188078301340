import { Divider, Theme, Tooltip, Typography } from '@mui/material';
import { CategoryDisplayName } from '@oxappsec/ox-consolidated-categories';
import { resolveIssueCategoryIcon } from 'application-utils';
import { format, formatDistanceToNow } from 'date-fns';
import { OwnerCell } from 'ox-react-components';
import { FC } from 'react';
import { FaMinus } from 'react-icons/fa';
import { makeStyles } from 'tss-react/mui';
import { IssueDetails } from '../../../../../types/issues-types';
import { resolveRiskLevel } from '../../../../../utils/severity-utils';
import IssueCommentSection from '../../../../IssueCard/IssueCommentSection';
import IssueSummarySection from '../../../../IssueCard/IssueSummarySection';
import AppFlowsIssues from '../../../../IssuesAppFlow/AppFlowsIssues';
import ScoreWithLabel from '../../../../IssueItem/ScoreWithLabel';
import { checkArrayLengthsIsZeroAppFlow } from '../../../../../../../applications/utils/application-utils';

const IssueAppInfo: FC<React.PropsWithChildren<IssueAppInfoProps>> = ({
  issueDetails,
  onComment,
  isResolvedIssuesPage,
}) => {
  const { classes } = useStyles();

  const CategoryIcon = resolveIssueCategoryIcon(
    issueDetails.category as unknown as CategoryDisplayName,
  );
  const businessPriorityScore =
    issueDetails.app.businessPriority === 0
      ? '0'
      : issueDetails.app.businessPriority < 1
      ? '<1'
      : issueDetails.app.businessPriority.toFixed(0);
  return (
    <div className={classes.issueCard}>
      <div className={classes.summaryContainer}>
        <IssueSummarySection title='Business Priority & App' width={300}>
          <ScoreWithLabel
            value={businessPriorityScore}
            severityType={resolveRiskLevel(
              businessPriorityScore as unknown as number,
            )}
            label={issueDetails.app.name}
            appLink={issueDetails.app.id}
            businessPriority
          />
        </IssueSummarySection>

        {!isResolvedIssuesPage && (
          <IssueSummarySection title='Comment' width={250}>
            <IssueCommentSection
              comment={issueDetails.comment}
              onComment={onComment}
            />
          </IssueSummarySection>
        )}

        <IssueSummarySection title='Issue Owner' width={200}>
          <OwnerCell owners={issueDetails.owners} />
        </IssueSummarySection>
        {issueDetails.app.owners && issueDetails.app.owners.length > 0 && (
          <IssueSummarySection title='Application Owners' width={200}>
            {issueDetails.app.owners.map(owner => (
              <OwnerCell key={owner.name} owners={[owner.name]} />
            ))}
          </IssueSummarySection>
        )}
        <IssueSummarySection title='First Seen Date' width={200}>
          <Tooltip
            arrow
            placement='top'
            title={
              issueDetails.createdAt
                ? format(
                    new Date(issueDetails.createdAt),
                    'MMMM do, yyyy H:mma',
                  )
                : ''
            }>
            <Typography variant='body2' color='text.primary'>
              {issueDetails.createdAt ? (
                formatDistanceToNow(issueDetails.createdAt, { addSuffix: true })
              ) : (
                <FaMinus />
              )}
            </Typography>
          </Tooltip>
        </IssueSummarySection>

        <IssueSummarySection title='Occurrences' width={150}>
          <Typography variant='body2' color='text.primary'>
            {issueDetails.occurrences}
          </Typography>
        </IssueSummarySection>

        <IssueSummarySection title='Category' width={250}>
          <div className={classes.category}>
            {CategoryIcon && <CategoryIcon className={classes.categoryIcon} />}
            <Typography variant='body2' color='text.primary'>
              {issueDetails.category.name}
            </Typography>
          </div>
        </IssueSummarySection>
      </div>
      {!checkArrayLengthsIsZeroAppFlow(issueDetails.app.applicationFlows) && (
        <>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Typography variant='body2' color='text.secondary' mb={3}>
            Application Flow
          </Typography>
          <AppFlowsIssues
            flow={issueDetails.app.applicationFlows}
            iconSize={30}
            isMonoRepoChild={issueDetails.isMonoRepoChild}
            monoRepoParent={issueDetails.monoRepoParent}
          />
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  category: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  categoryIcon: {
    width: '1em',
    height: '1em',
    flexShrink: 0,
    color: theme.palette.primary.main,
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  issueCard: {
    backgroundColor: theme.palette.background.paper,
  },
  appTypeSummeryScore: {
    display: 'flex',
  },
  appTypeSummery: {
    display: 'flex',
  },
  repoSummery: {
    display: 'flex',
    flexDirection: 'row',
  },
  twoCols: {
    display: 'flex',
    '& > div': {
      flex: '1 1 100%',
    },
  },
}));

export interface IssueAppInfoProps {
  issueDetails: IssueDetails;
  onComment?: () => void;
  isResolvedIssuesPage?: boolean;
}

export default IssueAppInfo;
