import { FC } from 'react';
import { Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import NodeTooltip from '../NodeTooltip';

const SlackItem: FC<React.PropsWithChildren<SlackItemProps>> = ({
  channelName,
  timestamp,
}) => {
  return (
    <NodeTooltip title='Slack'>
      <Typography variant='body2' sx={{ fontWeight: 600 }}>
        Sent to #{channelName}
      </Typography>
      <Typography variant='caption'>
        {formatDistanceToNow(new Date(parseInt(timestamp || '0')), {
          addSuffix: true,
        })}
      </Typography>
    </NodeTooltip>
  );
};

export interface SlackItemProps {
  timestamp: string;
  channelName: string;
}

export { SlackItem };
