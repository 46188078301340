import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import {
  BulkConnectorEnableDisableInput,
  ConnectorEnableDisableResponse,
} from '../../connectors-types';
import mutation from './bulk-connector-enable-disable.gql';

const bulkConnectorEnableDisable = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  BulkConnectorEnableDisableInput,
  ConnectorEnableDisableResponse[]
> => {
  return {
    execute: async (
      bulkConnectorEnableDisableInput: BulkConnectorEnableDisableInput,
    ): Promise<ConnectorEnableDisableResponse[]> => {
      const results = await client.mutate<BulkConnectorEnableDisableResponse>({
        mutation,
        variables: { connectorsToUpdate: bulkConnectorEnableDisableInput },
      });

      if (results?.data?.bulkEnableDisableConnector.length) {
        return results.data.bulkEnableDisableConnector.map(
          resItem => resItem.connector,
        );
      }

      throw new Error('Unknown error occurred');
    },
  };
};

interface BulkConnectorEnableDisableResponse {
  bulkEnableDisableConnector: { connector: ConnectorEnableDisableResponse }[];
}

export default bulkConnectorEnableDisable;
