import { useTheme } from '@mui/system';
import { AttackPathType } from '../attack-path-types';
import { Box, Typography } from '@mui/material';
import { textConfig } from '../configs/text-config';

export const useNodeText = (type, metadata, id = null) => {
  const theme = useTheme();
  const colors = theme.palette.categoryRiskColor;
  switch (type) {
    case AttackPathType.Popularity: {
      const title = (
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography color='text.secondary' fontSize={12}>
            Popularity
          </Typography>
          <Typography
            variant='body2'
            fontSize={10}
            color={
              metadata.notPopular === true
                ? colors.high
                : metadata.notPopular === null
                ? colors.info
                : colors.passed
            }>
            {metadata.notPopular === true
              ? 'Unpopular'
              : metadata.notPopular === null
              ? 'Unknown'
              : 'Popular'}
          </Typography>
        </Box>
      );
      return { title };
    }

    case AttackPathType.Maintenance: {
      const title = (
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography color='text.secondary' fontSize={12}>
            Maintenance
          </Typography>
          <Typography
            variant='body2'
            fontSize={10}
            color={
              metadata.notMaintained === true
                ? colors.high
                : metadata.notMaintained === null
                ? colors.info
                : colors.passed
            }>
            {metadata.notMaintained === true
              ? 'Not Active'
              : metadata.notMaintained === null
              ? 'Unknown'
              : 'Active'}
          </Typography>
        </Box>
      );
      return { title };
    }

    case AttackPathType.Update: {
      const title = (
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography color='text.secondary' fontSize={12}>
            Status
          </Typography>
          <Typography
            variant='body2'
            fontSize={10}
            color={
              metadata.notUpdated === true
                ? colors.high
                : metadata.notUpdated === null
                ? colors.info
                : colors.passed
            }>
            {metadata.notUpdated === true
              ? 'Not Updated'
              : metadata.notUpdated === null
              ? 'Unknown'
              : 'Updated'}
          </Typography>
        </Box>
      );
      return { title };
    }

    case AttackPathType.License: {
      const title = (
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography color='text.secondary' fontSize={12}>
            License
          </Typography>
          <Typography
            variant='body2'
            fontSize={10}
            color={metadata.licenseIssue === true ? 'red' : '#45D700'}>
            {metadata.licenseIssue === true
              ? 'Not Approved'
              : metadata.licenseIssue === null
              ? 'Unknown'
              : 'Approved'}
          </Typography>
        </Box>
      );
      return { title };
    }
  }
  const { title, description, width, fontSize, textBackground } =
    textConfig[type] || {};
  return {
    title: title ? title(metadata) : '',
    description: description ? description(metadata) : '',
    width: width || 75,
    fontSize: fontSize || 12,
    textBackground,
  };
};
