import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { TelemetryActions, TelemetryManager } from 'telemetry';
import {
  Exclusion,
  UpdateExpiredAtByIdInput,
} from '../../types/exclusion-types';
import mutation from './update-expiredAt-by-id.gql';

const updateExpiredAtById = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (updateExpiredAtByIdInput: UpdateExpiredAtByIdInput) => {
      try {
        const variables = {
          exclusionId: updateExpiredAtByIdInput.exclusionId,
          expiredAt: updateExpiredAtByIdInput.expiredAt,
        };
        const results = await client.mutate<{
          updateExpiredAtById: Exclusion;
        }>({
          mutation,
          variables: variables,
        });

        if (!results || !results.data) {
          logger.error(
            'Received an invalid response from updateExpiredAtByIdInput',
          );
          throw new Error('Failed to updateExpiredAtByIdInput');
        }
        TelemetryManager.setTelemetryAction(
          TelemetryActions.UpdateExclusionExpiredAt,
          updateExpiredAtByIdInput,
        );
        return results.data.updateExpiredAtById;
      } catch (error) {
        logger.error(
          `Failed to update exclusion for ${updateExpiredAtByIdInput.exclusionId}`,
          error,
        );
        throw new Error('Failed to updateExpiredAtByIdInput');
      }
    },
  };
};

export default updateExpiredAtById;
