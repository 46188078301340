import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import mutation from './delete-all-members.gql';

const deleteAllMembers = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (): Promise<boolean> => {
      const results = await client.mutate<{
        deleteAllMembers: boolean;
      }>({
        mutation,
      });

      if (!results || !results.data) {
        return false;
      }

      return results.data.deleteAllMembers;
    },
  };
};

export default deleteAllMembers;
