import { reportClient } from 'api-clients';
import getDependencyGraph from './get-dependency-graph/get-dependency-graph-service';
import getSbomLibraries from './get-libraries/get-libraries-service';
import getSingleSbom from './get-single-sbom/get-single-sbom';
import fetchSbomFiltersLazy from './get-sbom-filters-lazy/get-sbom-filters-lazy';
const sbomService = {
  getSbomLibraries: getSbomLibraries(reportClient),
  getDependencyGraph: getDependencyGraph(reportClient),
  getSingleSbom: getSingleSbom(reportClient),
  getSbomFiltersLazy: fetchSbomFiltersLazy(reportClient),
};

export default sbomService;
