import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import mutation from './update-user-view-settings.gql';

const updateUserViewSettings = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      input: UpdateUserViewSettingsInput,
    ): Promise<Nullable<boolean>> => {
      try {
        const results = await client.mutate<SetUserViewSettingsRes>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: {
            input,
          },
        });
        if (!results.data || !results.data.updateUserViewSettings) {
          return null;
        }
        return results.data.updateUserViewSettings;
      } catch (error) {
        logger.error('Failed to update user view settings', error);
        return null;
      }
    },
  };
};
interface UpdateUserViewSettingsInput {
  settings: { id: string; value: boolean }[];
}
interface SetUserViewSettingsRes {
  updateUserViewSettings: boolean;
}

export default updateUserViewSettings;
