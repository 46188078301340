import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  JiraProject,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import {
  InternalssueInfo,
  PreSelectableIssueFields,
} from '../../types/jira-ticket-types';
import query from './get-pre-select-ticket-fields.gql';

const getPreSelectTicketFields = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<InternalssueInfo, Nullable<PreselectedFieldsResponse>> => {
  return {
    execute: async (input): Promise<Nullable<PreselectedFieldsResponse>> => {
      try {
        const results = await client.query<PreselectedFieldsResponse>({
          query,
          variables: {
            provider: TicketProvider.Jira,
            input,
          },
          fetchPolicy: ApolloClientCache.NetworkOnly,
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }

        return results.data;
      } catch (error) {
        logger.error('Failed to fetch fetch preselect ticket fields', error);
        return null;
      }
    },
  };
};

export interface PreselectedFieldsResponse {
  getPreSelectFields: PreSelectableIssueFields;
  getLatestProjects: JiraProject[];
}

export default getPreSelectTicketFields;
