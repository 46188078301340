import { FC, useCallback } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  Position,
  SmoothStepEdgeProps,
  getSmoothStepPath,
  useStore,
} from 'reactflow';
import { getEdgeColor } from '../configs/edge-colors-config';
import { getParentNodePosition } from '../configs/position-config';
import {
  AttackPathType,
  EdgeItemPosition,
  exposedTypesMap,
} from '../attack-path-types';
import { Theme } from '@mui/system';
import { IconWorldOff as TbWorldOff } from '@tabler/icons-react';
import { IconWorldCheck as TbWorldCheck } from '@tabler/icons-react';
import { Typography } from '@mui/material';
import useCommonGraphStore from '../stores/common-graph-store';
import { makeStyles } from 'tss-react/mui';
const ArrowStepEdge: FC<React.PropsWithChildren<SmoothStepEdgeProps>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
  labelStyle,
  labelShowBg,
  labelBgStyle,
  labelBgPadding,
  labelBgBorderRadius,
  style,
  sourcePosition = Position.Bottom,
  targetPosition = Position.Top,
  markerEnd,
  markerStart,
  pathOptions,
  interactionWidth,
  source,
  target,
}) => {
  const { highlightedNodes } = useCommonGraphStore();
  const { label, position } = data || {};
  const { classes, cx } = useStyles();
  const sourceNode = useStore(
    useCallback(store => store.nodeInternals.get(source), [source]),
  );
  const targetNode = useStore(
    useCallback(store => store.nodeInternals.get(target), [target]),
  );

  const appPos = getParentNodePosition(AttackPathType.App, {});

  let centerX = 0;
  //temp calcs for centerX
  const level = sourceNode?.data?.level;
  if (level === undefined) {
    centerX = appPos.x + 250;
  } else {
    if (sourceNode?.data?.metadata?.order === 0) {
      centerX = appPos.x + (level + 1) * 500 + 225;
    } else {
      centerX = appPos.x + (level + 1) * 500 + 150;
    }
  }

  const [path, labelY] = getSmoothStepPath({
    sourceX: sourceX + (sourceNode?.type === 'group' ? -5 : 27),
    sourceY: sourceY - 3,
    sourcePosition,
    targetX: targetX - (targetNode?.type === 'group' ? 0 : 30),
    targetY: targetY - 3,
    targetPosition,
    borderRadius: 20,

    centerX,
  });

  const labelPosition =
    position === EdgeItemPosition.Start ? sourceX + 120 : targetX - 120;

  const exportType = label ? exposedTypesMap.get(label) : null;

  return (
    <>
      <BaseEdge
        path={path}
        labelX={centerX}
        labelY={labelY}
        labelStyle={labelStyle}
        labelShowBg={labelShowBg}
        labelBgStyle={labelBgStyle}
        labelBgPadding={labelBgPadding}
        labelBgBorderRadius={labelBgBorderRadius}
        style={{
          opacity: highlightedNodes?.length ? 0 : 0.7,
          strokeWidth: 2,
          stroke: getEdgeColor(
            targetNode?.data?.type,
            targetNode?.data?.metadata,
            sourceNode?.data?.metadata,
          ),
        }}
        markerEnd={markerEnd}
        markerStart={markerStart}
        interactionWidth={interactionWidth}
      />
      {label &&
        exportType &&
        !(
          sourceNode?.type === 'group' && position === EdgeItemPosition.Start
        ) && (
          <EdgeLabelRenderer>
            <Typography
              color='text.primary'
              component='div'
              className={cx('nodrag nopan', classes.edgeLabel)}
              sx={{
                transform: `translate(-50%, -50%) translate(${labelPosition}px,${
                  sourceY - 4
                }px)`,
                opacity: highlightedNodes?.length ? 0 : 1,
              }}>
              {!exportType.isOnline && (
                <TbWorldOff color='red' size={14} style={{ flexShrink: 0 }} />
              )}
              {exportType.isOnline && (
                <TbWorldCheck
                  color='green'
                  size={14}
                  style={{ flexShrink: 0 }}
                />
              )}
              {exportType.text}
            </Typography>
          </EdgeLabelRenderer>
        )}
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  edgeLabel: {
    position: 'absolute',
    width: 150,
    fontSize: 10,
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 0),
    gap: theme.spacing(0.5),
  },
}));

export interface ArrowStepEdgeProps {}

export default ArrowStepEdge;
