import { IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';
import { MdHelp } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { resolveDocsURL } from '../utils/docs-utils';

const OpenDocsButton: FC<React.PropsWithChildren<unknown>> = () => {
  const currentPage = useLocation();
  const docsUrl = resolveDocsURL(currentPage.pathname);

  return (
    <Tooltip
      arrow
      placement='bottom'
      title='Open OX App documentation in a new tab'>
      <IconButton
        data-testid='open-docs'
        href={docsUrl}
        target='_blank'
        size='small'
        color='inherit'>
        <MdHelp />
      </IconButton>
    </Tooltip>
  );
};

export default OpenDocsButton;
