import { Typography, TypographyProps } from '@mui/material';
import Link from '@mui/material/Link';
import { stopPropagation } from 'common-utils';
import React from 'react';
import { FC } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

export const CustomExtLink: FC<React.PropsWithChildren<CustomExtLinkProps>> = ({
  value,
  href,
  textClassName,
  ...props
}) => {
  return (
    <Link
      href={href}
      onClick={stopPropagation}
      sx={{ fontSize: 8, lineHeight: 1 }}
      target='_blank'>
      <FaExternalLinkAlt style={{ color: '#585cfc', marginRight: '4px' }} />
      <Typography
        variant='caption'
        color='#585cfc'
        {...props}
        className={textClassName}>
        {value}
      </Typography>
    </Link>
  );
};

export interface CustomExtLinkProps extends TypographyProps {
  value: string;
  href?: string;
  textClassName?: string;
}
