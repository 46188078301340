import { isValidEmail } from 'common-utils';
import { isEmpty } from 'lodash-es';
import { Nullable } from 'ox-common-types';
import { snapshot } from 'valtio';
import { GlobalDataViewSelectorOption } from './data-view-selector-types';
import { GlobalDataViewSelector } from './global-data-view-selector-store';
import { ScopeNames } from '@oxappsec/ox-unified-permissions';

export const setSelectedTagIds = (tagIds: string[]) => {
  GlobalDataViewSelector.selectedTagIds = tagIds;
};

export const setSelectedAppOwnersEmails = (emails: string[]) => {
  GlobalDataViewSelector.selectedAppOwnersEmails = emails;
};

export const setFilterValue = (value: string) => {
  GlobalDataViewSelector.filterValue = value;
};

export const openDataViewSelectorModal = () => {
  GlobalDataViewSelector.isModalVisible = true;
};

export const closeDataViewSelectorModal = () => {
  GlobalDataViewSelector.isModalVisible = false;
};

export const setSelectedOption = (
  option: Nullable<GlobalDataViewSelectorOption>,
) => {
  GlobalDataViewSelector.selectedOption = option;
};

export const setLoadingNameUpdate = (isLoading: boolean) => {
  GlobalDataViewSelector.isLoadingNameUpdate = isLoading;
};

export const setLoadingIdUpdate = (isLoading: boolean) => {
  GlobalDataViewSelector.isLoadingIdUpdate = isLoading;
};

export const setLoadingDelete = (isLoading: boolean) => {
  GlobalDataViewSelector.isLoadingDeleteOption = isLoading;
};

export const updateDataViewSelectorOptions = (id: string, name: string) => {
  const { dataViewSelectorOptions, selectedOption } = snapshot(
    GlobalDataViewSelector,
  );

  if (selectedOption?.id === id) {
    setSelectedOption({
      ...selectedOption,
      displayName: name,
    });
  }

  const updatedDataViewSelectorOptions = dataViewSelectorOptions.map(option => {
    if (option.id === id) {
      return {
        ...option,
        displayName: name,
      };
    }

    return option;
  });

  GlobalDataViewSelector.dataViewSelectorOptions =
    updatedDataViewSelectorOptions;
};

export const deleteDataViewSelectorOptions = (id: string) => {
  const { dataViewSelectorOptions, selectedOption } = snapshot(
    GlobalDataViewSelector,
  );

  if (selectedOption?.id === id) {
    setSelectedOption(null);
  }

  const updatedDataViewSelectorOptions = dataViewSelectorOptions.filter(
    option => {
      return option.id !== id;
    },
  );

  GlobalDataViewSelector.dataViewSelectorOptions =
    updatedDataViewSelectorOptions;
};

export const updateDataViewSelectorOptionsNewId = (
  oldId: string,
  newId: string,
) => {
  const { dataViewSelectorOptions, selectedOption } = snapshot(
    GlobalDataViewSelector,
  );

  if (selectedOption?.id === oldId) {
    setSelectedOption({
      ...selectedOption,
      id: newId,
    });
  }

  const updatedDataViewSelectorOptions = dataViewSelectorOptions.map(option => {
    if (option.id === oldId) {
      return {
        ...option,
        id: newId,
      };
    }

    return option;
  });

  GlobalDataViewSelector.dataViewSelectorOptions =
    updatedDataViewSelectorOptions;
};

export const validateViewSelectorId = (
  scopeName: ScopeNames,
  email: string,
) => {
  if (scopeName === ScopeNames.Tags) {
    return null;
  }

  const { dataViewSelectorOptions, selectedOption } = snapshot(
    GlobalDataViewSelector,
  );

  const ownerEmailExist = dataViewSelectorOptions.some(
    option => option.id === email && option.id !== selectedOption?.id,
  );

  if (isEmpty(email)) {
    return 'Missing email';
  }

  if (!isValidEmail(email)) {
    return 'Invalid email';
  }

  if (ownerEmailExist) {
    return 'Email already exist';
  }

  return null;
};

export const validateViewSelectorName = (
  scopeName: ScopeNames,
  name: string,
) => {
  if (scopeName === ScopeNames.Tags) {
    return null;
  }

  const { dataViewSelectorOptions, selectedOption } = snapshot(
    GlobalDataViewSelector,
  );

  const ownerNameExist = dataViewSelectorOptions.some(
    option => option.displayName === name && option.id !== selectedOption?.id,
  );

  const regex = new RegExp('[a-zA-Z]{2,}');

  if (isEmpty(name)) {
    return 'Missing name';
  }

  if (!regex.test(name)) {
    return 'Invalid Name';
  }

  if (ownerNameExist) {
    return 'Name already exist';
  }

  return null;
};
