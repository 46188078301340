import { Button } from '@mui/material';
import { logger } from 'logging-utils';
import React from 'react';
import CodeBlockWithTitle, {
  CodeBlockWithTitleProps,
} from '../components/CodeBlockWithTitle';

export function wrapCodeTags(
  str: string,
  codeLang: string = 'javascript',
  addPrButton?: boolean,
) {
  // Use a regular expression to find all instances of text surrounded by ``` and a newline
  const regex = /```([\s\S]+?)```/g;

  const matchArray = str.match(regex);

  // If no matches are found, return the original string
  if (!matchArray) {
    return React.createElement('span', undefined, str);
  }

  const partsArray = str.split(regex);

  // Loop through each part and wrap the code block parts in a React `PrismAsyncLight` element
  const wrappedParts = partsArray.map((part, index) => {
    if (index % 2 === 0) {
      // Even index represents text parts, so return the text wrapped in a span element
      return React.createElement('span', { key: index }, part);
    } else {
      // Odd index represents code block parts, so return the code block wrapped in a `PrismAsyncLight` element
      const codeText = part.slice(0, -1);
      const props: CodeBlockWithTitleProps = {
        language: codeLang.toLowerCase(),
        code: codeText,
        title: codeLang?.toLowerCase(),
      };
      const HighlightedCodeElement = React.createElement(
        CodeBlockWithTitle,
        props,
      );

      if (addPrButton) {
        const PrButton = React.createElement(Button, {
          variant: 'contained',
          sx: { marginTop: 2 },
          color: 'primary',
          children: 'Open PR',
        });

        const ReactFrag = React.createElement(React.Fragment, {
          children: [HighlightedCodeElement, PrButton],
          key: `${index}-codeBlock`,
        });
        return ReactFrag;
      } else {
        return HighlightedCodeElement;
      }
    }
  });

  // Combine the wrapped parts into a single span element
  const wrappedSpan = React.createElement('span', undefined, wrappedParts);

  return wrappedSpan;
}

export const splitGptResponse = (firstQuestion: string, gptContent: string) => {
  const secondQuestion = 'Assess the risk of this issue';
  const thirdQuestion = 'Provide a solution';
  const forthQuestion = 'Suggest a fix';
  const sections = gptContent.split('<ox-section>');
  return sections
    .map((section, i) => {
      section = section.trim();
      if (
        section.startsWith('1.') ||
        section.startsWith('2.') ||
        section.startsWith('3.') ||
        section.startsWith('4.')
      ) {
        section = section.substring(3);
      }
      const sectionObj = {
        answer: section.trim(),
        question: '',
      };
      switch (i) {
        case 0:
          break;
        case 1:
          sectionObj.question = firstQuestion;
          break;
        case 2:
          sectionObj.question = secondQuestion;
          break;
        case 3:
          sectionObj.question = thirdQuestion;
          break;
        case 4:
          sectionObj.question = forthQuestion;
          break;
        default:
          logger.warn(
            `Encountered an unknown GPT section index: ${i}. section: ${section}`,
          );
      }
      return sectionObj;
    })
    .filter(sectionObj => !!sectionObj.answer && !!sectionObj.question);
};
