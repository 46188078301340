import { createStore, derive } from 'store-utils';
import { UserRequestAccess } from '../types/members-types';
import { Nullable } from 'ox-common-types';

const baseStore = createStore<RequestAccessState>(
  {
    pendingRequests: [],
    isModalVisible: false,
    isLoading: false,
    selectedUserRequestAccess: null,
  },
  'Request Access Store',
);

const RequestAccessStore = derive<
  RequestAccessState,
  RequestAccessDerivedState
>({}, { proxy: baseStore });

export interface RequestAccessState {
  pendingRequests: UserRequestAccess[];
  isModalVisible: boolean;
  isLoading: boolean;
  selectedUserRequestAccess: Nullable<UserRequestAccess>;
}

export interface RequestAccessDerivedState {}

export default RequestAccessStore as RequestAccessState &
  RequestAccessDerivedState;
