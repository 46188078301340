import {
  Button,
  Checkbox,
  FormControlLabel,
  Theme,
  Typography,
  darken,
} from '@mui/material';
import { AppPages } from 'app-navigator';
import { FC, useCallback, useState } from 'react';
import { FaBullhorn } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { WhatsNewItem, WhatsNewType } from 'whats-new-logic';
import { WhatsNewItemRenderer } from './WhatsNewItemRenderer';

export const WhatsNewBanner: FC<React.PropsWithChildren<WhatsNewProps>> = ({
  items,
  onClose,
  dismissable,
}) => {
  const { classes } = useStyles();
  const [dismiss, setDismiss] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDismiss(event.target.checked);
  };

  const handleDismissClose = useCallback(() => {
    onClose && onClose(dismiss);
  }, [dismiss, onClose]);

  return (
    <div className={classes.whatsnew}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <FaBullhorn className={classes.icon} />
          <div>
            <Typography variant='body1' style={{ alignSelf: 'flex-start' }}>
              What's New
            </Typography>
            <Typography
              variant='body2'
              component={NavLink}
              color='inherit'
              to={AppPages.ReleaseNotes}>
              Release Notes
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <ul className={classes.list}>
          {items.map(({ id, type, description, links }) => (
            <li
              key={id}
              className={
                type === WhatsNewType.NewUIVersionAvailable
                  ? classes.noBullet
                  : ''
              }>
              <WhatsNewItemRenderer
                type={type}
                description={description}
                links={links}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={classes.footer}>
        {dismissable && (
          <>
            <FormControlLabel
              color='inherit'
              control={
                <Checkbox
                  size='small'
                  onChange={handleChange}
                  checked={dismiss}
                  sx={{
                    color: 'inherit',
                    '&.Mui-checked': {
                      color: 'inherit',
                    },
                  }}
                />
              }
              label="Don't show these items again"
            />
            <Button
              onClick={handleDismissClose}
              sx={{ color: 'inherit' }}
              className={classes.closeButton}>
              Close
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  whatsnew: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    justifyContent: 'space-between',
    position: 'relative',
    gap: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  header: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  headerTitle: {
    display: 'inline-flex',
    alignItems: 'start',
    gap: theme.spacing(1),
  },
  footer: {
    whiteSpace: 'nowrap',
  },
  list: {
    paddingInline: theme.spacing(2),
    listStyle: 'inside',
    listStylePosition: 'outside',
    maxHeight: 200,
    overflowY: 'auto',
  },
  noBullet: {
    listStyle: 'none',
    position: 'relative',
  },
  icon: {
    marginBlockStart: 3,
  },
  closeButton: {
    '&:hover, &:focus': {
      background: darken(theme.palette.primary.main, 0.2),
    },
  },
}));

export interface WhatsNewProps {
  items: WhatsNewItem[];
  dismissable: boolean;
  onClose: (dismiss: boolean) => void;
}
