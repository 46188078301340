import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { SettingsByName, SettingsObj } from '../../types/settings-types';
import query from './get-settings-map.gql';

const getSettingsMap = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (filters?: Partial<SettingsObj>) => {
      try {
        const results = await client.query<{
          getSettingsMapByName: GetSettingsResponse;
        }>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { filters },
        });
        return results.data.getSettingsMapByName.settingsByName;
      } catch (error) {
        logger.error('Failed to get settings map by name', error);
        return null;
      }
    },
  };
};

export interface GetSettingsResponse {
  settingsByName: SettingsByName[];
}
export default getSettingsMap;
