import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import {
  FeedbackStore,
  changeCategory,
  changeText,
  config,
  saveAndCloseFeedBack,
} from 'feedback-logic';
import { important } from 'ox-common-types';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useSnapshot } from 'valtio';

export const FeedbackForm: FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const { category, text, isValid, sending } = useSnapshot(FeedbackStore);

  return (
    <div className={classes.feedbackForm}>
      <Typography variant='h1' color='text.primary' fontWeight={400} mb={2}>
        We would love to get your feedback &#128578;
      </Typography>
      <FormControl fullWidth>
        <InputLabel id='category-selection'>Category</InputLabel>
        <Select
          id='category-selection'
          autoFocus
          value={category || ''}
          label='Category'
          onChange={changeCategory}
          fullWidth>
          {config.categories.map(cat => (
            <MenuItem key={cat} value={cat}>
              {cat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div>
        <TextField
          multiline
          rows={5}
          fullWidth
          value={text}
          required
          onChange={changeText}
        />
        <Typography variant='body2' textAlign='right' mt={0.5}>
          {(text || '').length} / {config.maxCharacters} chars
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <LoadingButton
          className={classes.button}
          color='primary'
          variant='contained'
          loading={sending.isPending()}
          onClick={saveAndCloseFeedBack}
          disabled={!isValid}>
          Submit
        </LoadingButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  feedbackForm: {
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  buttonContainer: {
    textAlign: 'center',
  },
  button: {
    textTransform: important('none'),
    minWidth: important('50%'),
  },
}));
