import { FC } from 'react';
import { SeverityColor } from 'severity-utils';
import ActionsOptionsTooltip from '../ActionsOptions/ActionsOptionsTooltip';
import { capitalizeFirstLetter } from 'string-utils';
import { FilterTypeToFilter } from 'ox-filter-utils';

const IssueCardSeverity: FC<
  React.PropsWithChildren<IssueCardSeverityProps>
> = ({ severity, isEnableAdvancedOptionsToolTip, filters }) => {
  return (
    <SeverityColor severity={severity}>
      {color => (
        <span
          style={{
            color,
            textTransform: 'uppercase',
            fontWeight: 600,
          }}>
          {isEnableAdvancedOptionsToolTip ? (
            <ActionsOptionsTooltip
              value={capitalizeFirstLetter(severity)}
              withFilter
              withName
              filters={filters[FilterTypeToFilter.Criticality]}
              filterType={FilterTypeToFilter.Criticality}>
              {severity}
            </ActionsOptionsTooltip>
          ) : (
            severity
          )}
        </span>
      )}
    </SeverityColor>
  );
};

export interface IssueCardSeverityProps {
  isEnableAdvancedOptionsToolTip?: boolean;
  filters: {};
  severity: string;
}

export default IssueCardSeverity;
