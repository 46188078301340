export enum BadgeStatusType {
  None = 'None',
  Connected = 'Connected',
  NotConnected = 'NotConnected',
  ConnectionIssue = 'ConnectionIssue',
}

export enum BadgeProductionStageType {
  None = 'None',
  ComingSoon = 'ComingSoon',
  Beta = 'Beta',
}

export interface BadgeIconProps {
  overlap?: 'circular' | 'rectangular';
  productionStage?: BadgeProductionStageType;
  systemByOxBadge?: boolean;
  badgeStatus?: BadgeStatusType;
  badgeIcon:
    | React.FunctionComponent<
        React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
      >
    | string;
  height?: string | number;
  width?: string | number;
  alt?: string;
  marginX?: number;
  marginY?: number;
  badgeShape?: BadgeShape;
  innerIconHeight?: number;
}

export enum IconType {
  Connected = 'Connected',
  NotConnected = 'NotConnected',
  ConnectionIssue = 'ConnectionIssue',
  SystemByOx = 'SystemByOx',
  ComingSoon = 'ComingSoon',
  Beta = 'Beta',
}

export enum BadgeShape {
  Circular = 'Circular',
  Rectangle = 'Rectangle',
}
