import { FC } from 'react';
import { Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import NodeTooltip from '../NodeTooltip';

const ReposItem: FC<React.PropsWithChildren<ReposItemProps>> = ({
  destinationRepoName,
  destinationCreationDate,
  destinationLastModifyDate,
  destinationRepoVisibility,
}) => {
  return (
    <NodeTooltip title='Repo Info'>
      <Typography variant='caption'>
        Repo Name: <b>{destinationRepoName}</b>
      </Typography>
      {destinationCreationDate && (
        <Typography variant='caption'>
          Created:{' '}
          <b>{formatDistanceToNow(new Date(destinationCreationDate))}</b>
        </Typography>
      )}
      {destinationLastModifyDate && (
        <Typography variant='caption'>
          Last Modified:{' '}
          <b>{formatDistanceToNow(new Date(destinationLastModifyDate))}</b>
        </Typography>
      )}
      {destinationRepoVisibility && (
        <Typography variant='caption'>
          Repo Visibility: <b>{destinationRepoVisibility}</b>
        </Typography>
      )}
    </NodeTooltip>
  );
};

export interface ReposItemProps {
  destinationLastModifyDate: string;
  destinationRepoVisibility: string;
  destinationCreationDate: string;
  destinationRepoName: string;
}

export { ReposItem };
