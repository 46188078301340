import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';
import { CanUserRequestAccessResponse } from '../request-access-types';

const RequestAccessStore = createStore<RequestAccessStoreState>(
  {
    canUserRequestAccessInfo: null,
    isLoadingRequestAccess: false,
    requestAccessPurpose: '',
  },
  'Request Access Store',
);

interface RequestAccessStoreState {
  canUserRequestAccessInfo: Nullable<CanUserRequestAccessResponse>;
  isLoadingRequestAccess: boolean;
  requestAccessPurpose: string;
}

export default RequestAccessStore;
