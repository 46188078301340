import { Divider, Link, Theme, Typography } from '@mui/material';
import { resolveRepoIcon } from 'common-icons';
import { RepoTypes } from 'ox-common-types';
import {
  AppFlowGeneralInfo,
  AppFlowItemInfo,
  AppFlowSingleItem,
  AppFlowToolTip,
  ApplicationFlow,
  ApplicationFlowItemType,
  CiCdTypes,
  CloudDeploymentTypes,
  KubernetesSubTypes,
  OrchestratorTypes,
  artifactIconMap,
  checkSystemsRegistry,
  cicdIconsMap,
  cloudDeploymentTypeIconMap,
  createCloudDeploymentTypes,
  createRegistrySystems,
  kubernetesIconMap,
  orchestratorIconMap,
} from 'ox-react-components';
import { FC } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import {
  MdArrowForwardIos as ArrowForwardIosIcon,
  MdInsertDriveFile as InsertDriveFileIcon,
  MdHelpOutline as QuestionMarkIcon,
} from 'react-icons/md';
import { capitalizeFirstLetter } from 'string-utils';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as GenericIcon } from '../../../../assets/icons/generic-icon.svg';

const AppFlowsIssues: FC<React.PropsWithChildren<AppFlowsIssuesProps>> = ({
  flow,
  iconSize,
  monoRepoParent,
  isMonoRepoChild,
}) => {
  const { classes } = useStyles();

  if (!flow) {
    return null;
  }
  if (
    !flow.artifacts &&
    !flow.cicdInfo &&
    !flow.cloudDeployments &&
    !flow.kubernetes &&
    !flow.orchestrators &&
    !flow.repository
  ) {
    return null;
  }
  const haveCICD = flow.cicdInfo && flow.cicdInfo.length > 0;
  const cloudDeployments = createCloudDeploymentTypes(flow.cloudDeployments);
  const registry = createRegistrySystems(flow.artifacts);
  const generalRegistry = checkSystemsRegistry(registry);

  return (
    <div className={classes.container}>
      {flow.repository &&
        flow.repository.map((repo, index) => {
          const RepoIcon = resolveRepoIcon(repo.system as RepoTypes);
          return (
            <AppFlowSingleItem
              flowIcon={RepoIcon || QuestionMarkIcon}
              key={index}
              toolTip={
                <div className={classes.section}>
                  {repo.location && repo.location[0]?.foundIn ? (
                    <div className={classes.link}>
                      <Typography variant='body1' color='text.secondary'>
                        {repo.location && repo.location[0].foundIn}
                      </Typography>
                      <Link
                        sx={{ fontSize: 8, marginInlineStart: '5px' }}
                        href={repo.location && repo.location[0].link}
                        target='_blank'>
                        <FaExternalLinkAlt style={{ color: '#585cfc' }} />
                      </Link>
                    </div>
                  ) : (
                    <Typography variant='body2' color='text.secondary'>
                      N/A
                    </Typography>
                  )}
                  {isMonoRepoChild && (
                    <div className={classes.monorepo}>
                      <Typography variant='body2' color='text.primary' mb={0.5}>
                        Parent Monorepo:
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {monoRepoParent && monoRepoParent}
                      </Typography>
                    </div>
                  )}
                </div>
              }
              appFlowItemType={repo.system}
              iconSize={iconSize}
            />
          );
        })}
      {haveCICD && <ArrowForwardIosIcon className={classes.doubleArrowIcon} />}
      {haveCICD &&
        flow.cicdInfo.map((cicdType, index) => {
          const IconCicd = cicdIconsMap.get(cicdType.system as CiCdTypes);
          return (
            <AppFlowSingleItem
              key={index}
              flowIcon={IconCicd || QuestionMarkIcon}
              appFlowItemType={cicdType.system}
              toolTip={<AppFlowItemInfo info={cicdType} />}
              iconSize={iconSize}
            />
          );
        })}
      {flow.orchestrators && flow.orchestrators.length > 0 && (
        <>
          <ArrowForwardIosIcon className={classes.doubleArrowIcon} />
          <AppFlowSingleItem
            appFlowItemType={flow.orchestrators[0].system}
            flowIcon={
              orchestratorIconMap.get(
                flow.orchestrators[0].system as OrchestratorTypes,
              ) || QuestionMarkIcon
            }
            toolTip={<AppFlowToolTip appFlowType={flow.orchestrators} />}
            servicesNumber={flow.orchestrators.length}
            iconSize={iconSize}
          />
        </>
      )}
      {flow.artifacts.length > 0 && (
        <>
          {flow.orchestrators.length > 0 ||
            (haveCICD && (
              <ArrowForwardIosIcon className={classes.doubleArrowIcon} />
            ))}
          {registry &&
            registry.map((registryType, index) => {
              if (registryType.length > 0) {
                const RegistryTypeIcon = artifactIconMap.get(
                  registryType[0].system,
                );
                return (
                  <AppFlowSingleItem
                    key={index}
                    appFlowItemType={registryType[0].system}
                    flowIcon={RegistryTypeIcon}
                    toolTip={
                      <AppFlowToolTip appFlowType={registryType} header />
                    }
                    servicesNumber={registryType.length}
                    iconSize={iconSize}
                  />
                );
              }
              return null;
            })}
          {!generalRegistry && (
            <AppFlowSingleItem
              flowIcon={GenericIcon}
              appFlowItemType={ApplicationFlowItemType.Artifacts}
              toolTip={
                <div className={classes.containerAppFlowGenral}>
                  <Typography variant='body1' color='text.secondary'>
                    {flow.artifacts[0].system &&
                      capitalizeFirstLetter(flow.artifacts[0].system)}
                  </Typography>
                  {flow.artifacts.map((artifact, index) => {
                    return (
                      <div key={index}>
                        <AppFlowGeneralInfo info={artifact} />
                        <Divider />
                      </div>
                    );
                  })}
                </div>
              }
              iconSize={30}
            />
          )}
        </>
      )}
      {flow.kubernetes && flow.kubernetes.length > 0 && (
        <>
          <ArrowForwardIosIcon className={classes.doubleArrowIcon} />
          <AppFlowSingleItem
            appFlowItemType={flow.kubernetes[0].system}
            flowIcon={
              kubernetesIconMap.get(
                flow.kubernetes[0].system as KubernetesSubTypes,
              ) || InsertDriveFileIcon
            }
            toolTip={<AppFlowToolTip appFlowType={flow.kubernetes} />}
            servicesNumber={flow.kubernetes.length}
            iconSize={iconSize}
          />
        </>
      )}
      {flow.cloudDeployments.length > 0 && (
        <>
          {flow.repository.length > 0 && (
            <ArrowForwardIosIcon className={classes.doubleArrowIcon} />
          )}
          {cloudDeployments &&
            cloudDeployments.map((cloudDeploymentType, index) => {
              if (cloudDeploymentType.length > 0) {
                const CloudDeploymentTypeIcon = cloudDeploymentTypeIconMap.get(
                  cloudDeploymentType[0].type as CloudDeploymentTypes,
                );
                return (
                  <AppFlowSingleItem
                    key={index}
                    appFlowItemType={cloudDeploymentType[0].type}
                    flowIcon={CloudDeploymentTypeIcon}
                    toolTip={
                      <AppFlowToolTip appFlowType={cloudDeploymentType} />
                    }
                    servicesNumber={cloudDeploymentType.length}
                    iconSize={iconSize}
                  />
                );
              }
              return null;
            })}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: theme.spacing(2),
  },
  arrow: {
    marginBlockStart: theme.spacing(1),
  },
  doubleArrowIcon: {
    alignSelf: 'center',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  containerAppFlowGenral: {
    overflowY: 'auto',
    maxHeight: window.innerHeight / 2.5,
  },
  monorepo: {
    display: 'flex',
    flexDirection: 'column',
    marginBlockStart: theme.spacing(1),
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export interface AppFlowsIssuesProps {
  flow?: ApplicationFlow;
  iconSize: number;
  monoRepoParent?: string;
  isMonoRepoChild?: boolean;
}

export default AppFlowsIssues;
