import create from 'zustand';
import { Edge, Node } from 'reactflow';

export interface IssueGraphType {
  nodes: Node[];
  edges: Edge[];
  originalEdges: Edge[];
  originalNodes: Node[];
}

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useIssueGraphStore = create<IssueGraphType>((set, get) => ({
  nodes: [],
  edges: [],
  originalEdges: [],
  originalNodes: [],
}));

export default useIssueGraphStore;
