import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { loginUser } from '../actions/app-general-actions';
import LoginButton from '../components/user/LoginButton';

const LoginButtonContainer: FC<React.PropsWithChildren<unknown>> = () => {
  const { isLoading, loginWithRedirect } = useAuth0();

  const handleLogin = useCallback(() => {
    loginUser(loginWithRedirect);
  }, [loginWithRedirect]);

  return <LoginButton onClick={handleLogin} loading={isLoading} />;
};

export default LoginButtonContainer;
