import { differenceInMilliseconds } from 'date-fns';
import { useState } from 'react';

export const useDebouncer = (timeout: number) => {
  const [tick, setTick] = useState(new Date());

  const isReady = () => {
    const lastCallDiff = Math.abs(differenceInMilliseconds(tick, new Date()));
    return lastCallDiff >= timeout;
  };

  const update = () => {
    setTick(new Date());
  };

  return { isReady, update };
};
