import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { BulkUpdateIssuesSeverityInput } from '../../types/issues-types';
import mutation from './bulk-update-issues-severity.gql';
import { Nullable } from '@oxappsec/ox-consolidated-gpt-types';

const bulkUpdateIssuesSeverity = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      updateSeverity: BulkUpdateIssuesSeverityInput,
    ): Promise<Nullable<boolean>> => {
      try {
        const results = await client.mutate<MutationResponse>({
          mutation,
          variables: { input: updateSeverity },
        });
        if (results?.data) return results.data.updateIssuesSeverity;
      } catch (e) {
        logger.error('bulk change issues severity for failed', e);
      }
      return null;
    },
  };
};

interface MutationResponse {
  updateIssuesSeverity: boolean;
}

export default bulkUpdateIssuesSeverity;
