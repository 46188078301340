import { useAuth0 } from '@auth0/auth0-react';
import { AppPages } from 'app-navigator';
import { FunctionComponent, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { createNewOrganization } from '../actions/create-organization-actions';
import CreateOrganizationModal from '../components/CreateOrganizationModal';
import {
  closeCreateOrgModal,
  setMessage,
} from '../store-actions/create-org-store-actions';
import CreateOrganizationStore from '../stores/create-organization-store';

const CreateOrganizationContainer: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const {
    isModalVisible,
    isCreateOrgEnabled,
    isLoading,
    message,
    orgDisplayName,
    isValidOrgDisplayName,
    isOrgCreatedSuccessfully,
    invalidOrgDisplayNameHelperText,
  } = useSnapshot(CreateOrganizationStore);
  const { loginWithRedirect } = useAuth0();

  const onCreateOrg = useCallback(async () => {
    const organization = await createNewOrganization(orgDisplayName);
    if (!organization) {
      return;
    }

    loginWithRedirect({
      organization: `${organization.id}`,
      appState: {
        returnTo: AppPages.Connectors,
      },
    });
  }, [loginWithRedirect, orgDisplayName]);

  const handleClose = useCallback(() => {
    closeCreateOrgModal();
    setMessage('');
  }, []);

  return (
    <CreateOrganizationModal
      open={isModalVisible}
      onClose={handleClose}
      onCreateOrg={onCreateOrg}
      isCreateOrgEnabled={isCreateOrgEnabled}
      message={message}
      isLoading={isLoading}
      orgDisplayName={orgDisplayName}
      isValidOrgDisplayName={isValidOrgDisplayName}
      isOrgCreatedSuccessfully={isOrgCreatedSuccessfully}
      invalidOrgDisplayNameHelperText={invalidOrgDisplayNameHelperText}
    />
  );
};

export default CreateOrganizationContainer;
