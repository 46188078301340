import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from 'ox-common-types';
import mutation from './set-allow-user-to-create-org.gql';
import {
  SetConfigurationInput,
  SetConfigurationResponse,
} from '../../request-access-types';

const setAllowUserToCreateOrg = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      input: SetConfigurationInput,
    ): Promise<Nullable<SetConfigurationResponse>> => {
      try {
        const results = await client.mutate<SetAllowUserToCreateOrgMutation>({
          mutation,
          variables: { input },
        });

        if (!results || !results.data) {
          return null;
        }

        return results.data.setAllowUserToCreateOrg;
      } catch {
        return null;
      }
    },
  };
};

interface SetAllowUserToCreateOrgMutation {
  setAllowUserToCreateOrg: SetConfigurationResponse;
}

export default setAllowUserToCreateOrg;
