import {
  alpha,
  Divider,
  Fade,
  IconButton,
  LinearProgress,
  Slide,
  Theme,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { important } from 'ox-common-types';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import { MdClose as CloseIcon } from 'react-icons/md';
import { Rnd } from 'react-rnd';
import { useEscape } from 'react-utils';
import { makeStyles } from 'tss-react/mui';
import {
  ResizablePanel,
  ResizablePanelProps,
} from './ResizablePanel/ResizablePanel';
import { ScrollDownIndicator } from './ScrollDownIndicator';

export const BottomDrawer: FC<React.PropsWithChildren<BottomDrawerProps>> = ({
  title,
  className,
  aside,
  children,
  loading,
  defaultHeight,
  onClose,
  onResizeStop,
  maxHeight,
}) => {
  const rndRef = useRef<Rnd>(null);
  const { classes, cx } = useStyles();
  const contentRef = useRef<HTMLDivElement>(null);
  const [scrolled, setScrolled] = useState(false);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEscape({ onEscape: onClose });

  const handleScroll = useCallback(() => {
    !scrolled && setScrolled(true);

    setShowScrollIndicator(false);
  }, [scrolled]);

  const handleAnimationEnd = useCallback(() => {
    if (!contentRef.current) {
      return;
    }
    const { current: el } = contentRef;
    const scrollable = el.scrollHeight > el.clientHeight;
    setShowScrollIndicator(scrollable);
  }, []);

  const handleResizeStop = useCallback(
    (newHeight: number) => {
      setShowScrollIndicator(false);
      onResizeStop && onResizeStop(newHeight);
    },
    [onResizeStop],
  );

  const handleMaximize = useCallback(() => {
    setShowScrollIndicator(false);
    rndRef.current?.updateSize({
      height: '100%',
      width: '100%',
    });
  }, []);

  const handleMinimize = useCallback(() => {
    setShowScrollIndicator(false);
    rndRef.current?.updateSize({ height: '10%', width: '100%' });
  }, []);

  useEffect(() => {
    defaultHeight &&
      rndRef.current?.updateSize({ height: defaultHeight, width: '100%' });
  }, [defaultHeight]);

  return (
    <ResizablePanel
      ref={rndRef}
      className={cx(classes.bottomDrawer, className)}
      defaultHeight={defaultHeight}
      onResizeStop={handleResizeStop}
      maxHeight={maxHeight}>
      <Toolbar data-testid='app-drawer-title' className={classes.toolbar}>
        <div className={classes.titleWrapper}>{title}</div>
        {aside}
        <Tooltip arrow placement='top' title='Minimize drawer'>
          <IconButton onClick={handleMinimize} size='small'>
            <BsArrowDownShort />
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement='top' title='Maximize drawer'>
          <IconButton onClick={handleMaximize} size='small'>
            <BsArrowUpShort />
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement='top' title='Close drawer (ESC)'>
          <IconButton onClick={onClose} size='small'>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      {loading ? <LinearProgress sx={{ maxHeight: '1px' }} /> : <Divider />}
      <div className={classes.content} onScroll={handleScroll} ref={contentRef}>
        <Slide
          style={{ height: '100%' }}
          direction='up'
          in
          onEntered={handleAnimationEnd}>
          <div>{children}</div>
        </Slide>
      </div>
      {showScrollIndicator && (
        <Fade in={!scrolled} timeout={500} appear>
          <div className={classes.scrollIndicator}>
            <ScrollDownIndicator />
          </div>
        </Fade>
      )}
    </ResizablePanel>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  bottomDrawer: {
    display: 'flex',
    flexDirection: 'column',
    position: important('relative'),
    maxHeight: 300,
  },
  toolbar: {
    gap: theme.spacing(0.5),
    flex: '0 0 auto',
    zIndex: 1,
    paddingBlockStart: theme.spacing(1),
  },
  titleWrapper: {
    flexGrow: 1,
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    overflowY: 'scroll',
    marginBottom: theme.spacing(1),
  },
  tooltip: {
    textAlign: 'center',
  },
  scrollIndicator: {
    position: 'absolute',
    bottom: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: theme.zIndex.drawer,
  },
  shadow: {
    boxShadow: `0 10px 8px -10px ${alpha(
      theme.palette.common.black,
      theme.palette.mode === 'dark' ? 1 : 0.5,
    )}`,
  },
}));

export interface BottomDrawerProps {
  title: ReactNode;
  className?: string;
  aside?: ReactNode;
  loading: boolean;
  defaultHeight?: number;
  maxHeight?: ResizablePanelProps['maxHeight'];
  onResizeStop?: (height: number) => void;
  onClose(): void;
}
