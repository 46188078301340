import {
  ClickAwayListener,
  Divider,
  IconButton,
  Popper,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';
import { IssueAction, IssueDetails } from '../../types/issues-types';
import React from 'react';

const IssueCardActionV2: FC<React.PropsWithChildren<IssueActionProps>> = ({
  onClick,
  tooltipText,
  icon: Icon,
  disabled,
  highlight,
  issueId,
  id,
  subMenuActions,
}) => {
  const { classes, cx } = useStyles();
  const [shouldHighlight, setShouldHighlight] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setShouldHighlight(highlight);
  }, [issueId, highlight]);

  const onActionClick = () => {
    if (
      subMenuActions &&
      subMenuActions.filter(action => !action.hideAction).length > 1
    ) {
      setPopperAnchor(document.getElementById(id));
    } else {
      onClick();
    }
  };
  if (!Icon) return null;

  return (
    <>
      <ClickAwayListener onClickAway={() => setPopperAnchor(null)}>
        <Tooltip arrow placement='bottom' title={tooltipText}>
          <div id={id} className={classes.centerIcon}>
            <IconButton
              id={`issue-action-${tooltipText.replaceAll(' ', '-')}`}
              color='primary'
              onClick={onActionClick}
              size={tooltipText !== 'Open PR' ? 'small' : 'medium'}
              className={cx(
                classes.button,
                shouldHighlight && classes.highlighted,
              )}
              onAnimationEnd={() => setShouldHighlight(false)}
              disabled={disabled}>
              {<Icon />}
            </IconButton>
          </div>
        </Tooltip>
      </ClickAwayListener>
      {subMenuActions && (
        <Popper
          id={id}
          placement='top'
          anchorEl={popperAnchor}
          open={Boolean(popperAnchor)}>
          <div className={classes.subMenuContainer}>
            <Typography variant='body2' color='text.primary' fontWeight='bold'>
              {tooltipText}
            </Typography>
            <div className={classes.subMenuOptions}>
              {subMenuActions.map((subAction, index) => {
                if (subAction.hideAction) {
                  return null;
                }
                const SubIcon = subAction.icon;
                return (
                  <React.Fragment key={index}>
                    {index !== 0 && (
                      <Divider
                        key={index + 'divider'}
                        orientation='vertical'
                        flexItem
                      />
                    )}
                    <Tooltip
                      key={subAction.actionId + index}
                      title={subAction.title}>
                      <IconButton
                        className={classes.button}
                        onClick={
                          !subAction.onClickFunctionParams
                            ? subAction.onClick
                            : () => {
                                subAction.onClick(
                                  ...subAction.onClickFunctionParams!,
                                );
                              }
                        }
                        disabled={subAction.disabled}>
                        {SubIcon && <SubIcon size={16} />}
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </Popper>
      )}
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? 'rgba(104, 55, 255, 0.1)'
          : 'rgba(104, 55, 255, 0.2)',
    },
  },
  highlighted: {
    animation: `${keyframes`
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.5);
      }
      100% {
        transform: scale(1);
      }
      `} 2s`,
  },
  centerIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subMenuContainer: {
    margin: 20,
    padding: 2,
    paddingRight: 10,
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1,
    boxShadow: theme.shadows[10],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  subMenuOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
}));

export interface IssueActionProps extends IssueAction {
  issueId: IssueDetails['id'];
  id: string;
}

export default IssueCardActionV2;
