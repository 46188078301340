import { FC } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import IssueCardPolicyInfo from '../../../../IssueCard/IssueCardPolicyInfo';

const PolicyTabContainer: FC = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);

  return (
    <>
      {selectedIssue && (
        <IssueCardPolicyInfo
          key='Policy'
          policyInfo={selectedIssue.policy}
          categoryId={selectedIssue.category?.categoryId}
        />
      )}
    </>
  );
};

export default PolicyTabContainer;
