import { snapshot } from 'valtio';
import SettingsStore from '../stores/settings-store';
import {
  InputTypeSetting,
  SettingsAction,
  SettingsByName,
  SettingsObj,
  SettingsSubType,
  TabQueryParam,
} from '../types/settings-types';

export const setOrgSettingsMapByName = (settings: SettingsByName[]) => {
  SettingsStore.orgSettingsMapByName = settings;
};

export const setOrgSettingsMapByNameResponseState = (
  settings: SettingsByName[],
) => {
  SettingsStore.orgSettingsMapByNameResponseState = settings;
};

export const setSingleSetting = (setting: SettingsObj) => {
  SettingsStore.singleSetting = setting;
};

export const getOrgSettingsByMap = (): SettingsByName[] => {
  return SettingsStore.orgSettingsMapByName;
};

export const setPersonalSettings = (settings: SettingsObj[]) => {
  SettingsStore.personalSettings = settings;
};

export const setCurrentTabValue = (currentTab: TabQueryParam) => {
  SettingsStore.currentTab = currentTab;
};

export const setDefaultTabValue = () => {
  setCurrentTabValue(TabQueryParam.OrgSettings);
};

export const setLoadingUpdate = (loading: boolean) => {
  SettingsStore.loadingUpdate = loading;
};

export const setConfiguredTimeHoverAdvancedOptions = (tooltipTime: number) => {
  SettingsStore.tooltipTimeAdvancedOptions = tooltipTime;
};

export const setRegexArr = (settingsSubType: string, regex: string[]) => {
  const { orgSettingsMapByName } = snapshot(SettingsStore);
  if (orgSettingsMapByName) {
    const orgSettings = SettingsStore.orgSettingsMapByName.find(setting =>
      setting.settings.find(s => s.settingsSubType === settingsSubType),
    );
    orgSettings?.settings.map(setting => (setting.valueList = regex));
  }
};

export const setRegexListObj = (settingsSubType: string, regex: string) => {
  const { orgSettingsMapByName } = snapshot(SettingsStore);
  if (orgSettingsMapByName) {
    const orgSettings = SettingsStore.orgSettingsMapByName.find(setting =>
      setting.settings.find(s => s.settingsSubType === settingsSubType),
    );

    orgSettings?.settings.forEach(setting => {
      if (setting.settingsSubType === settingsSubType) {
        setting.valueList?.push(regex);
      }
    });
  }
};

export const setDisabledTextInputsSubSettings = (
  isDisabled: boolean,
  radioSubSettingId?: string,
) => {
  const { selectedSubSettingId } = snapshot(SettingsStore);
  SettingsStore.disabledRadioButtonsSubSettings[
    radioSubSettingId || selectedSubSettingId
  ] = isDisabled;
};

export const setRegexList = (settingsSubType: string, regex: string) => {
  const { regexList } = snapshot(SettingsStore);
  if (regexList[settingsSubType].length > 0) {
    SettingsStore.regexList[settingsSubType] = [
      ...regexList[settingsSubType],
      regex,
    ];
  } else {
    SettingsStore.regexList[settingsSubType] = [regex];
  }
};

export const setSelectedSubSettingType = (type: SettingsSubType) => {
  SettingsStore.subSettingType = type;
};
export const setSelectedSubSettingId = (id: string) => {
  SettingsStore.selectedSubSettingId = id;
};

export const setUpdateInputModal = (open: boolean) => {
  SettingsStore.openUpdateInputModal = open;
};

export const setInputText = (inputText: string) => {
  SettingsStore.inputText = inputText;
};

export const setInputTextSubSettings = (input: string) => {
  const { orgSettingsMapByName, selectedSubSettingId, subSettingType } =
    snapshot(SettingsStore);
  SettingsStore.inputText = input;
  if (orgSettingsMapByName) {
    const orgSettingsMapByName = SettingsStore.orgSettingsMapByName.find(
      setting =>
        setting.settings.find(s => s.settingsSubType === subSettingType),
    );

    const subSettings = orgSettingsMapByName?.settings[0].subSettings
      ?.find(s => s.idSubSetting === selectedSubSettingId)
      ?.settings.find(se => se.inputTypeSubSetting === InputTypeSetting.Text);

    if (subSettings) {
      subSettings.inputText = input;
    }
  }
};

export const setRegexListArr = (settingsSubType: string, regex: string[]) => {
  SettingsStore.regexList[settingsSubType] = regex;
};

export const setIsButtonLoading = (
  settingsSubType: string,
  action: SettingsAction,
) => {
  const buttonLoadingString = getButtonLoadingString(settingsSubType, action);
  SettingsStore.isButtonLoading[buttonLoadingString] = true;
};

export const unsetIsButtonLoading = (
  settingsSubType: string,
  action: SettingsAction,
) => {
  const buttonLoadingString = getButtonLoadingString(settingsSubType, action);
  SettingsStore.isButtonLoading[buttonLoadingString] = false;
};

export const getButtonLoadingString = (
  settingsSubType: string,
  action: SettingsAction,
) => `${settingsSubType}_${action}`;
