import { Nullable } from 'ox-common-types';
import {
  CanUserRequestAccessResponse,
  RequestedAccessInfo,
  RequestedAccessStatus,
} from '../request-access-types';

export const shouldNavigateToRequestAccess = (
  canUserRequestAccessInfo: Nullable<CanUserRequestAccessResponse>,
) => {
  if (!canUserRequestAccessInfo) {
    return false;
  }

  return (
    canUserRequestAccessInfo.canRequestAccess ||
    canUserRequestAccessInfo.hasRequestedAccess
  );
};

export const getRequestAccessInfo = (
  canUserRequestAccessInfo: CanUserRequestAccessResponse,
) => {
  const { requestedAccessInfos } = canUserRequestAccessInfo;

  if (!requestedAccessInfos || requestedAccessInfos.length === 0) {
    return null;
  }

  const inviteRequest = requestedAccessInfos.find(
    r => r.status === RequestedAccessStatus.INVITATION_SENT,
  );
  if (inviteRequest) {
    return inviteRequest;
  }

  const invitePending = requestedAccessInfos.find(
    r => r.status === RequestedAccessStatus.PENDING,
  );
  if (invitePending) {
    return invitePending;
  }

  const inviteDismissed = requestedAccessInfos.find(
    r => r.status === RequestedAccessStatus.DISMISSED,
  );
  if (inviteDismissed) {
    return inviteDismissed;
  }

  return null;
};

export const statusToText = (
  requestedAccessInfo: Nullable<RequestedAccessInfo>,
) => {
  if (!requestedAccessInfo) {
    return 'Not Requested';
  }

  switch (requestedAccessInfo.status) {
    case RequestedAccessStatus.NA:
      return 'Not Requested';
    case RequestedAccessStatus.PENDING:
      return 'Pending';
    case RequestedAccessStatus.INVITATION_SENT:
      return 'Invitation Sent';
    case RequestedAccessStatus.DISMISSED:
      return 'Dismissed';
  }
};

export const statusToExtendedText = (
  requestedAccessInfo: Nullable<RequestedAccessInfo>,
) => {
  if (!requestedAccessInfo) {
    return 'You have not requested access to this organization.';
  }

  switch (requestedAccessInfo.status) {
    case RequestedAccessStatus.NA:
      return `You have not requested access to this organization.`;
    case RequestedAccessStatus.PENDING:
      return `Your request is pending approval since ${new Date(
        requestedAccessInfo.requestedAt,
      ).toLocaleString()}`;
    case RequestedAccessStatus.INVITATION_SENT:
      return `An invitation has been sent to your email at ${new Date(
        requestedAccessInfo.updatedAt,
      ).toLocaleString()} by ${requestedAccessInfo.updatedBy}`;
    case RequestedAccessStatus.DISMISSED:
      return `Your request has been dismissed at ${new Date(
        requestedAccessInfo.updatedAt,
      ).toLocaleString()} by ${requestedAccessInfo.updatedBy}`;
  }
};
