import { CategoryDisplayName } from '@oxappsec/ox-consolidated-categories';
import {
  generateCategoryIcon,
  resolveRepoIcon,
  resolveContainerIcon,
} from 'common-icons';
import { RepoTypes } from 'ox-common-types';
import React from 'react';

export const generateIcons = (
  category?: CategoryDisplayName,
  repoType?: RepoTypes,
  fakeApp?: boolean,
) => {
  const CategoryIcon = category && resolveIssueCategoryIcon(category);
  const RepoIcon = repoType && resolveRepoIcon(repoType, fakeApp);
  return { CategoryIcon, RepoIcon };
};

export const resolveIssueCategoryIcon = (category: CategoryDisplayName) => {
  const categoryIcon = generateCategoryIcon(category) as
    | React.ElementType
    | undefined;
  return categoryIcon;
};

export const resolveIssueContainerIcon = (containerSecurity: string) => {
  const containerIcon = resolveContainerIcon(containerSecurity) as
    | React.ElementType
    | undefined;

  return containerIcon;
};

export const generateContainerIcon = (container?: string) => {
  const ContainerIcon = container && resolveIssueContainerIcon(container);
  return { ContainerIcon };
};
