import { TextField, Theme, Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { makeStyles } from 'tss-react/mui';

export const ExclusionComment: FC<ExclusionCommentProps> = ({
  excludeComment,
  setExcludeComment,
  message,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography mb={0.5}>{message}</Typography>
      <TextField
        multiline
        rows={3}
        fullWidth
        value={excludeComment}
        required
        onChange={setExcludeComment}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export interface ExclusionCommentProps {
  excludeComment?: string;
  setExcludeComment: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  message: string;
}
