import { IconButton, Tooltip } from '@mui/material';
import { FC, useCallback } from 'react';
import {
  MdChevronLeft as ChevronLeftIcon,
  MdChevronRight as ChevronRightIcon,
} from 'react-icons/md';
import { useSnapshot } from 'valtio';
import { setOpenDrawer } from '../../app/actions/app-bar-actions';
import DrawerStore from '../../app/stores/drawer-store';

export const CollapseButton: FC = () => {
  const { open } = useSnapshot(DrawerStore);

  const toggle = useCallback(() => {
    setOpenDrawer(!open);
  }, [open]);

  return (
    <Tooltip
      arrow
      title={`${open ? 'Collapse' : 'Expand'} Menu`}
      placement='right'>
      <IconButton
        onClick={toggle}
        sx={{ alignSelf: open ? 'end' : 'center', marginInline: open ? 1 : 0 }}>
        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </Tooltip>
  );
};
