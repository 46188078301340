import {
  BiCheck,
  BiInfoCircle,
  BiTargetLock,
  BiTrendingUp,
  BiWindowAlt,
} from 'react-icons/bi';
import { BsClipboard2Check } from 'react-icons/bs';
import { IoTicketOutline } from 'react-icons/io5';
import { DiGit } from 'react-icons/di';
import { GiFamilyTree } from 'react-icons/gi';
import { GoContainer } from 'react-icons/go';
import { MdAccountTree, MdCode, MdPolicy, MdTableRows } from 'react-icons/md';
import { SiOpenai } from 'react-icons/si';
import { SlGraph, SlList, SlMagicWand } from 'react-icons/sl';
import { IconApiApp } from '@tabler/icons-react';
import IssueTrendContainer from '../../issue-trend/containers/IssueTrendContainer';
import AggregationsTableTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/AggregationsTableTab/AggregationsTableTabContainer';
import AttackPathTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/AttackPathTab/AttackPathContainer';
import ChatGPTTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/ChatGPTTab/ChatGPTTabContainer';
import ComplianceTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/ComplianceTab/ComplianceTabContainer';
import DependencyGraphTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/DependencyGraphTab/DependencyGraphTabContainer';
import FixAppliedTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/FixAppliedTab/FixAppliedTabContainer';
import IssueApiInventoriesTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssueApiInventoriesTab/IssueApiInventoriesTabContainer';
import IssueAppInfoTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssueAppInfoTab/IssueAppInfoTabContainer';
import IssueCweListContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssueCweListTab/IssueCweListContainer';
import IssueSummaryTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssueSummaryTab/IssueSummaryTabContainer';
import NotReachableVulnerabilitiesTableTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssuesCardVulnerabilitiesTab/containers/NotReachableVulnerabilitiesTableTabContainer';
import ReachableVulnerabilitiesTableTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssuesCardVulnerabilitiesTab/containers/ReachableVulnerabilitiesTableTabContainer';
import JiraTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/JiraTab/JiraTabContainer';
import MoreInfoTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/MoreInfoTab/MoreInfoTabContainer';
import OscarIssueMatrixTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/OscarIssueMatrixTab/ComplianceTabContainer';
import PolicyTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/PolicyTab/PolicyTabContainer';
import PrAppliedTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/PrAppliedTab/PrAppliedTabContainer';
import SBOMChecksTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/SBOMChecksTab/SBOMChecksTabContainer';
import SBOMInfoTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/SBOMInfoTab/SBOMInfoTabContainer';
import SeverityFactorsTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/SeverityFactorsTab/SeverityFactorsTabContainer';
import AggregationsAdditionalTabsTableContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/AggregationsTableTab/AggregationsAdditionalTabsTableContainer';
import DevSummaryTabContainer from '../common/components/IssueDrawer/IssueDrawerTabs/Tabs/DevSummaryTab/DevSummaryTabContainer';

export const issueDrawerTabsList: {} = {
  summary: {
    component: IssueSummaryTabContainer,
    icon: BiWindowAlt,
  },
  devSummary: {
    component: DevSummaryTabContainer,
    icon: BiWindowAlt,
  },
  attackPath: {
    component: AttackPathTabContainer,
    icon: MdPolicy,
  },
  appInfo: {
    component: IssueAppInfoTabContainer,
    icon: MdTableRows,
  },
  reachableVulnerabilities: {
    component: ReachableVulnerabilitiesTableTabContainer,
    icon: BiTargetLock,
  },
  notReachableVulnerabilities: {
    component: NotReachableVulnerabilitiesTableTabContainer,
    icon: BiTargetLock,
  },
  vulnerabilities: {
    component: NotReachableVulnerabilitiesTableTabContainer,
    icon: BiTargetLock,
  },
  resolvedVulnerabilities: {
    component: ReachableVulnerabilitiesTableTabContainer,
    icon: BiTargetLock,
  },
  aggregation: {
    component: AggregationsTableTabContainer,
    icon: MdCode,
  },
  artifactFiles: {
    component: AggregationsAdditionalTabsTableContainer,
    icon: MdCode,
  },
  cloudResources: {
    component: AggregationsAdditionalTabsTableContainer,
    icon: GoContainer,
  },
  policySecurityScanSCA: {
    component: AggregationsAdditionalTabsTableContainer,
    icon: MdCode,
  },
  policySecurityContainerScan: {
    component: AggregationsAdditionalTabsTableContainer,
    icon: MdCode,
  },
  apis: {
    component: IssueApiInventoriesTabContainer,
    icon: IconApiApp,
  },
  compliance: {
    component: ComplianceTabContainer,
    icon: BsClipboard2Check,
  },
  dependencyGraph: {
    component: DependencyGraphTabContainer,
    icon: GiFamilyTree,
  },
  sbomInfo: {
    component: SBOMInfoTabContainer,
    icon: MdAccountTree,
  },
  sbomChecks: {
    component: SBOMChecksTabContainer,
    icon: BiCheck,
  },
  oscar: {
    component: OscarIssueMatrixTabContainer,
    icon: SlList,
  },
  cwe: {
    component: IssueCweListContainer,
    icon: SlList,
  },
  severityFactors: {
    component: SeverityFactorsTabContainer,
    icon: SlGraph,
  },
  tickets: {
    component: JiraTabContainer,
    icon: IoTicketOutline,
  },
  moreInfo: {
    component: MoreInfoTabContainer,
    icon: BiInfoCircle,
  },
  policy: {
    component: PolicyTabContainer,
    icon: MdPolicy,
  },
  fixApplied: {
    component: FixAppliedTabContainer,
    icon: SlMagicWand,
  },
  prApplied: {
    component: PrAppliedTabContainer,
    icon: DiGit,
  },
  chatGpt: {
    component: ChatGPTTabContainer,
    icon: SiOpenai,
  },
  trend: {
    component: IssueTrendContainer,
    icon: BiTrendingUp,
  },
};
