import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache } from 'ox-common-types';
import {
  OscarIssueMatrixResponse,
  RawOscarTactic,
} from '../types/oscar-matrix-types';
import query from './oscar-issue-matrix-service.gql';

const fetchIssueMatrixService = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      techniquesNames: string[],
      selectedAppOwnersEmails: string[],
      selectedTagIds: string[],
    ): Promise<RawOscarTactic[]> => {
      const results = await client.query<OscarIssueMatrixResponse>({
        query,
        fetchPolicy: ApolloClientCache.NoCache,
        variables: {
          techniqueIds: techniquesNames,
          owners: selectedAppOwnersEmails || null,
          tagIds: selectedTagIds || null,
        },
      });
      if (!results.data) return [];
      return results.data.getTechniquesInOscarMatrix.tactics;
    },
  };
};

export default fetchIssueMatrixService;
