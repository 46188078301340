import { FC } from 'react';
import { useSnapshot } from 'valtio';
import AttackPath from '../../../../../../../attack-path/containers/AttackPath';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { resolveAggregationName } from '../../../../../utils/issue-utils';

const AttackPathTabContainer: FC = () => {
  const { selectedIssue } = useSnapshot(IssuesStore);

  return (
    <>
      {selectedIssue && (
        <AttackPath
          description={selectedIssue.secondTitleMarkup}
          recommendation={selectedIssue.recommendationMarkup}
          issueId={selectedIssue.issueId}
          issueTitle={selectedIssue.mainTitle}
          aggType={resolveAggregationName(selectedIssue.aggregations.type)}
        />
      )}
    </>
  );
};

export default AttackPathTabContainer;
