import { createAsyncState } from 'async-utils';
import { Nullable } from 'ox-common-types';
import { FilterElements, FilterItem } from 'ox-filter-utils';
import { createStore, derive } from 'store-utils';
import {
  Exclusion,
  ExclusionsFilters,
  ExclusionsOrder,
  ExclusionSortKey,
} from '../types/exclusion-types';

const baseStore = createStore<ExclusionsStoreState>(
  {
    exclusions: [],
    loading: createAsyncState(),
    selectedExclusion: null,
    generalError: false,
    errorMessage: '',
    filterExclusionsBy: {},
    showModal: false,
    order: ExclusionsOrder.Desc,
    orderBy: ExclusionSortKey.ExclusionType,
    isDisablePolicyLoading: false,
    isIrrelevantApplicationLoading: false,
    isExcludeAlertLoading: false,
    isBulkExcludeAlertLoading: false,
    isRemoveExclusionLoading: false,
    showMakeRelevantOxDialog: false,
    showMakeRelevantDialog: false,
    totalExclusions: 0,
    totalFilteredExclusions: 0,
    filtersOpen: true,
    filterType: null,
    loadingFilters: createAsyncState(),
    isUpdateExpiredAtById: false,
    updateExpiredAtModalBanner: false,
    updateExpiredAtModal: false,
    removeSnoozeModal: false,
    removeSnoozeLoading: false,
    searchValues: {},
    offset: 0,
    nearlyExpiredExclusions: [],
    isSnoozeBannerSnoozeOpen: false,
    totalNearlyExpiredExclusions: 0,
    snoozeModalCurrentSnoozeDateValue: null,
    lazyFilters: [],
  },
  'Exclusions Store',
);

const ExclusionsStore = derive(
  {
    numberOfFilters: get => {
      const { filterExclusionsBy } = get(baseStore);
      return (
        Object.keys(filterExclusionsBy).reduce(
          (acc, key) => acc + filterExclusionsBy[key].length,
          0,
        ) || 0
      );
    },
    filterItems: get => {
      const { lazyFilters, searchValues, filterExclusionsBy } = get(baseStore);

      return lazyFilters.map(filterItem => {
        const currentFilterItem = { ...filterItem };
        Object.keys(searchValues).forEach(key => {
          if (key === filterItem.id) {
            currentFilterItem.items = filterItem.items.filter(item => {
              return (
                item.label
                  .toLowerCase()
                  .includes(searchValues[key]?.toLowerCase()) ||
                filterExclusionsBy[filterItem.id]?.includes(item.label)
              );
            });
          }
        });
        return currentFilterItem;
      });
    },
  },
  {
    proxy: baseStore,
  },
);

export interface ExclusionsStoreState {
  exclusions: Exclusion[];
  loading: ReturnType<typeof createAsyncState>;
  selectedExclusion: Nullable<Exclusion>;
  generalError: boolean;
  errorMessage: string;
  order: ExclusionsOrder;
  orderBy: ExclusionSortKey;
  filterExclusionsBy: ExclusionsFilters;
  showModal: boolean;
  showMakeRelevantOxDialog: boolean;
  showMakeRelevantDialog: boolean;
  isDisablePolicyLoading: boolean;
  isIrrelevantApplicationLoading: boolean;
  isExcludeAlertLoading: boolean;
  isBulkExcludeAlertLoading: boolean;
  isRemoveExclusionLoading: boolean;
  isUpdateExpiredAtById: boolean;
  totalExclusions: number;
  totalFilteredExclusions: number;
  filtersOpen: boolean;
  loadingFilters: ReturnType<typeof createAsyncState>;
  filterType: Nullable<FilterElements>;
  updateExpiredAtModal: boolean;
  updateExpiredAtModalBanner: boolean;
  removeSnoozeModal: boolean;
  removeSnoozeLoading: boolean;
  searchValues: {};
  offset: number;
  nearlyExpiredExclusions: Exclusion[];
  isSnoozeBannerSnoozeOpen: boolean;
  totalNearlyExpiredExclusions: number;
  snoozeModalCurrentSnoozeDateValue: Nullable<Date>;
  lazyFilters: FilterItem[];
}

export default ExclusionsStore;
