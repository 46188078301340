import { AppPages, navigate } from 'app-navigator';
import { FilterData, FilterItems } from 'ox-filter-utils';
import ApplicationsFiltersStore from './applications-filters-store';
import ApplicationsStore from './applications-store';

export const setTotalAppsFilters = (totalFilters: number) => {
  ApplicationsFiltersStore.totalFilteredApps = totalFilters;
};

export const setSliderBusinessPriority = (range: number[]) => {
  ApplicationsFiltersStore.sliderBusinessPriority = range;
};

export const clearFiltersInStore = () => {
  ApplicationsFiltersStore.filterBy = {};
  ApplicationsFiltersStore.numberOfFilters = 0;
  ApplicationsFiltersStore.sliderBusinessPriority = [0, 100];
  ApplicationsFiltersStore.totalFilteredApps = ApplicationsStore.total;
};

export const clearAllFilters = () => {
  clearFiltersInStore();
  navigate(AppPages.Applications);
};

export const setSearchValues = (searchValue: string, type: string) => {
  ApplicationsFiltersStore.searchValues[type] = searchValue;
};

export const setStoreApplicationsOpenFilters = (
  resultsFilters: FilterData[],
  filterItems: FilterItems,
) => {
  Object.keys(filterItems).forEach(key => {
    const selectedResultFilterItem = resultsFilters.find(
      item => item.type === key,
    );
    if (
      ApplicationsFiltersStore.lazyFilters &&
      ApplicationsFiltersStore.lazyFilters[key] &&
      selectedResultFilterItem
    ) {
      ApplicationsFiltersStore.lazyFilters[key].items =
        selectedResultFilterItem.items;
    }
  });
};
