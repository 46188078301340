import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import { IFetchProfileDetailsRes } from '../../types/policy-profile-types';
import query from './fetch-profile-overview-by-id-service.gql';

const fetchProfileDetailsById = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<string, IFetchProfileDetailsRes> => {
  return {
    execute: async (profileId: string): Promise<IFetchProfileDetailsRes> => {
      await client.cache.reset();
      const results = await client.query<{
        getProfileOverview: IFetchProfileDetailsRes;
      }>({
        query,
        variables: {
          input: {
            profileId,
          },
        },
      });

      if (results?.data?.getProfileOverview) {
        return results.data.getProfileOverview;
      }

      throw new Error('Failed to retrieve profiles overview by id');
    },
  };
};

export default fetchProfileDetailsById;
