import { isValidEmail } from 'common-utils';
import { isEmpty } from 'lodash-es';
import { AppOwnerType, Nullable } from 'ox-common-types';
import { AppOwnersStore } from '../stores/app-owners-store';
import { snapshot } from 'valtio';

export const filterDupAppOwners = (appOwners: AppOwnerType[]) => {
  return appOwners.reduce((acc: AppOwnerType[], val: AppOwnerType) => {
    if (acc.some(o => o.email === val.email)) return acc;
    acc.push(val);
    return acc;
  }, []);
};

export const validateNewOwnerFields = (email: string, name: string) => {
  const { appOwnersSuggestions } = snapshot(AppOwnersStore);
  const ownerEmailExist = appOwnersSuggestions.some(o => o.email === email);
  const ownerNameExist = appOwnersSuggestions.some(o => o.name === name);
  const regex = new RegExp('[a-zA-Z][a-zA-Z]{2,}');
  let invalidEmailMsg = null as Nullable<string>;
  let invalidNameMsg = null as Nullable<string>;

  if (isEmpty(email)) {
    invalidEmailMsg = 'Missing email';
  }
  if (isEmpty(name)) {
    invalidNameMsg = 'Missing owner name';
  }
  if (!isValidEmail(email)) {
    invalidEmailMsg = 'Invalid email';
  }
  if (!regex.test(name)) {
    invalidNameMsg = 'Invalid Name';
  }
  if (ownerEmailExist) {
    invalidEmailMsg = 'Owner email already exist';
  }
  if (ownerNameExist) {
    invalidNameMsg = 'Owner name already exist';
  }

  return { invalidEmailMsg, invalidNameMsg };
};
