import IssueTrendStore from '../stores/issue-trend-store';
import { IssueTrend } from '../types/issue-trend-types';
import { generateIssueKey } from '../utils/issue-trend-utils';

export const setIssueTrend = (
  issueId: string,
  severityChangeHistory: IssueTrend[],
) => {
  IssueTrendStore.severityChangeHistory = severityChangeHistory;
  IssueTrendStore.issueId = issueId;
};

export const setLoadingIssueTrend = (isLoading: boolean) => {
  IssueTrendStore.loading = isLoading;
};

export const setIssueTrendError = (didError: boolean) => {
  IssueTrendStore.error = didError;
};

export const setLabels = (severityChangeHistory: IssueTrend[]) => {
  IssueTrendStore.labels = severityChangeHistory
    ? severityChangeHistory.map(i => generateIssueKey(i.date))
    : [];

  IssueTrendStore.indexLabelsMap = IssueTrendStore.labels.reduce(
    (acc, label, index) => {
      acc[label] = index;
      return acc;
    },
    new Set<number>(),
  );
};

export const setChartData = () => {
  const data = {
    severityChanged: IssueTrendStore.labels.map(() => 0),
  };

  IssueTrendStore.chartData =
    IssueTrendStore.severityChangeHistory.reduce((arr, i) => {
      const index = IssueTrendStore.indexLabelsMap[generateIssueKey(i.date)];
      arr.severityChanged[index] = parseInt(i.severity);
      return arr;
    }, data) || data;
};

export const setSeverityChangeReasonsAdded = () => {
  IssueTrendStore.severityChangedReasonsAdded =
    IssueTrendStore.severityChangeHistory.reduce<string[][]>((arr, i) => {
      const index = IssueTrendStore.indexLabelsMap[generateIssueKey(i.date)];
      if (i.severityChangeReasonsAdded?.length > 0)
        arr[index] = i.severityChangeReasonsAdded.map(i => i.shortName);
      return arr;
    }, []) || [];
};

export const setSeverityChangeReasonsRemoved = () => {
  IssueTrendStore.severityChangeReasonsRemoved =
    IssueTrendStore.severityChangeHistory.reduce<string[][]>((arr, i) => {
      const index = IssueTrendStore.indexLabelsMap[generateIssueKey(i.date)];
      if (i.severityChangeReasonsRemoved?.length > 0)
        arr[index] = i.severityChangeReasonsRemoved.map(i => i.shortName);
      return arr;
    }, []) || [];
};

export const setToolSeverities = () => {
  IssueTrendStore.toolSeverities =
    IssueTrendStore.severityChangeHistory.reduce<string[]>(
      (toolSeverities, i) => {
        const index = IssueTrendStore.indexLabelsMap[generateIssueKey(i.date)];
        toolSeverities[index] = i.originalToolSeverity;
        return toolSeverities;
      },
      [],
    ) || [];
};

export const setManualSeverityChangeReason = () => {
  IssueTrendStore.manualSeverityChangeReason =
    IssueTrendStore.severityChangeHistory.reduce<string[]>(
      (manualSeverityChangeReason, i) => {
        const index = IssueTrendStore.indexLabelsMap[generateIssueKey(i.date)];
        manualSeverityChangeReason[index] = i.manualSeverityChangeReason;
        return manualSeverityChangeReason;
      },
      [],
    ) || [];
};

export const setIsEmpty = (isEmpty: boolean) => {
  IssueTrendStore.isEmpty = isEmpty;
};

export const setIsJustOneChange = (isJustOneChange: boolean) => {
  IssueTrendStore.isJustOneChange = isJustOneChange;
};
