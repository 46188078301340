import { RawOscarTactic, Tactic } from '../types/oscar-matrix-types';
import { createTechnique } from './oscar-technique-model';

export const createTactic = (rawTactic: RawOscarTactic): Tactic => {
  const techniques = rawTactic.techniques.map(createTechnique);
  const techniquesWithIssues = techniques.filter(
    technique => technique.sumIssues > 0,
  ).length;

  return {
    id: rawTactic.name,
    name: rawTactic.name,
    techniques,
    techniquesWithIssues,
  };
};
