import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import {
  ConnectorEnableDisableResponse,
  EnableConnectorInput,
} from '../../connectors-types';
import mutation from './enable-connector.gql';

const enableConnector = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<EnableConnectorInput, ConnectorEnableDisableResponse> => {
  return {
    execute: async (
      connectorEnableInput: EnableConnectorInput,
    ): Promise<ConnectorEnableDisableResponse> => {
      const results = await client.mutate<EnableConnectorResponse>({
        mutation,
        variables: { connectorEnableInput },
      });

      if (results?.data?.enableConnector) {
        return results.data.enableConnector.connector;
      }

      throw new Error('Failed to enable connector');
    },
  };
};

interface EnableConnectorResponse {
  enableConnector: { connector: ConnectorEnableDisableResponse };
}

export default enableConnector;
