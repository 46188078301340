import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { HubSpotUserActions, logUserActionToForm } from 'hubspot';
import { logger } from 'logging-utils';
import {
  JiraTicket,
  Nullable,
  ServiceExecute,
  TicketProvider,
} from 'ox-common-types';
import { TelemetryActions, TelemetryManager } from 'telemetry';

import mutation from './create-bulk-jira-tickets.gql';

const createBulkJiraTickets = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<
  { createIssueInput: JiraTicket; issuesIds: string[] },
  Nullable<CreateTicketsResponse>
> => {
  return {
    execute: async (input): Promise<Nullable<CreateTicketsResponse>> => {
      try {
        const results = await client.mutate<CreateBulkIssuesResponse>({
          mutation,
          variables: {
            input,
            provider: TicketProvider.Jira,
          },
        });

        if (!results || !results.data) {
          throw new Error('Invalid Response');
        }
        TelemetryManager.setTelemetryAction(TelemetryActions.JiraTicket, input);
        logUserActionToForm(HubSpotUserActions.CreateJiraTicket);
        return results.data.createBulkIssues;
      } catch (error) {
        logger.error('Failed to create bulk jira tickets', error);
        return null;
      }
    },
  };
};

export interface CreateBulkIssuesResponse {
  createBulkIssues: CreateTicketsResponse;
}

export interface CreateTicketsResponse {
  data: Nullable<
    {
      id: string;
      key: string;
      self: string;
    }[]
  >;
  validationError: Nullable<string>;
}

export default createBulkJiraTickets;
