import { createAsyncState } from 'async-utils';
import { Nullable } from 'ox-common-types';
import {
  FilterItems,
  getFilterItemsLabels,
  SearchInput,
} from 'ox-filter-utils';
import { createStore, derive } from 'store-utils';
import { apiInventoryFilters } from '../api-inventory-utils';

export const initialApiInventoryFiltersStoreValues = {
  loadingFilters: createAsyncState(),
  inventoriesFilterValue: '',
  inventoriesFiltersType: null,
  filterBy: {},
  totalFilters: 0,
  searchValues: [],
  lazyFilters: getFilterItemsLabels(apiInventoryFilters),
};

const baseStore = createStore<ApiInventoryFiltersStoreState>(
  initialApiInventoryFiltersStoreValues,
  'Api Inventory Filters Store',
);

const ApiInventoryFiltersStore = derive(
  {
    numberOfFilters: get => {
      const { filterBy } = get(baseStore);
      return (
        Object.keys(filterBy).reduce(
          (acc, key) => acc + filterBy[key].length,
          0,
        ) || 0
      );
    },
  },
  { proxy: baseStore },
);

export interface ApiInventoryFiltersStoreState {
  loadingFilters: ReturnType<typeof createAsyncState>;
  filterBy: {};
  searchValues: SearchInput[];
  totalFilters: number;
  inventoriesFilterValue: string;
  lazyFilters: Nullable<FilterItems>;
}

export default ApiInventoryFiltersStore;
