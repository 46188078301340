import { createStore } from 'store-utils';
import { IssueTrend } from '../types/issue-trend-types';

export const IssueTrendStore = createStore<IssueTrendState>(
  {
    loading: false,
    issueId: null,
    severityChangeHistory: [],
    labels: [],
    indexLabelsMap: new Set<number>(),
    chartData: {
      severityChanged: [],
    },
    severityChangedReasonsAdded: [],
    severityChangeReasonsRemoved: [],
    toolSeverities: [],
    manualSeverityChangeReason: [],
    error: false,
    isEmpty: false,
    isJustOneChange: false,
  },
  'Issue Trend Store',
);

export interface IssueTrendState {
  loading: boolean;
  issueId: string | null;
  severityChangeHistory: IssueTrend[];
  error: boolean;
  labels: number[];
  indexLabelsMap: Set<number>;
  chartData: {
    severityChanged: number[];
  };
  severityChangedReasonsAdded: string[][];
  severityChangeReasonsRemoved: string[][];
  toolSeverities: string[];
  manualSeverityChangeReason: string[];
  isEmpty: boolean;
  isJustOneChange: boolean;
}

export default IssueTrendStore;
