import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { setSnackbarActions } from '../actions/snackbar-actions';

export const SnackbarProxy: FC<React.PropsWithChildren<unknown>> = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    setSnackbarActions(enqueueSnackbar, closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]);
  return null;
};
