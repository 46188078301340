export const getIssueActionTooltip = (
  isTruthyCondition: boolean,
  msgOnTruthy: string,
  msgOnFalsy: string,
) => {
  if (isTruthyCondition) {
    return msgOnTruthy;
  }

  return msgOnFalsy;
};
