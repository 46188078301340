import { ScopeNames } from '@oxappsec/ox-unified-permissions';

export const getDataViewLabels = (scopeName: ScopeNames) => {
  if (scopeName === ScopeNames.AppOwner) {
    return {
      scopeNameLabel: 'App Owner Name',
      idLabel: 'App Owner ID',
      ssoLabel: 'SSO Group String',
    };
  }

  return {
    scopeNameLabel: 'Tag Name',
    idLabel: 'Tag ID',
    ssoLabel: 'SSO Group String',
  };
};

export const getSSOGroupString = (
  scopeName: ScopeNames,
  id: string,
  displayName: string,
) => {
  const SSOPrefix =
    scopeName === ScopeNames.AppOwner ? `OXAppOwnerScope` : `OXTagScope`;

  return `${SSOPrefix}-${displayName}-id:${id}`;
};

export const checkIsDataViewEditable = (scopeName: ScopeNames) => {
  return scopeName === ScopeNames.AppOwner;
};

export const checkIsDataViewDeletable = (scopeName: ScopeNames) => {
  return scopeName === ScopeNames.AppOwner;
};

export const getScopeDisplayName = (scopeName: ScopeNames) => {
  return scopeName === ScopeNames.AppOwner ? 'App Owner' : 'App Tag';
};
