import { Theme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

const OscarMatrix: FC<PropsWithChildren<OscarMatrixProps>> = ({
  columns,
  padding = true,
  children,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.matrixWrapper}>
      <div
        className={cx(classes.oscarMatrix, padding && classes.padding)}
        style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
        {children}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  oscarMatrix: {
    display: 'grid',
    gap: theme.spacing(1),
    height: '100%',
    overflowX: 'auto',
    gridAutoRows: 'min-content',
  },
  padding: {
    paddingInline: theme.spacing(2),
  },
  matrixWrapper: {
    height: '100%',
    overflow: 'hidden',
  },
}));

export interface OscarMatrixProps {
  columns: number;
  padding?: boolean;
}

export default OscarMatrix;
