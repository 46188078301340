import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  ApolloClientCache,
  IDateRange,
  InventoryFilterType,
} from 'ox-common-types';
import { calcDateRange } from '../../app/store-actions/top-bar-store-actions';
import {
  OscarMatrixResponse,
  RawOscarTactic,
} from '../types/oscar-matrix-types';
import query from './oscar-matrix-service.gql';

const fetchMatrixService = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      selectedAppOwnersEmails: string[],
      selectedTagIds: string[],
      dateRange?: IDateRange,
      inventoryFilters?: Array<InventoryFilterType>,
    ): Promise<RawOscarTactic[]> => {
      const variables = {
        owners: selectedAppOwnersEmails || null,
        tagIds: selectedTagIds || null,
        inventoryFilters: inventoryFilters && Object.values(inventoryFilters),
      };

      if (dateRange) {
        const [from, to] = calcDateRange(dateRange);
        variables['dateRange'] = {
          from,
          to,
        };
      }

      const results = await client.query<OscarMatrixResponse>({
        query,
        fetchPolicy: ApolloClientCache.NoCache,
        variables,
      });
      if (!results.data) return [];
      return results.data.getOscarMatrix.tactics;
    },
  };
};

export default fetchMatrixService;
