import { createStore } from 'store-utils';
import { User } from '../types/user-role-types';

export const UserStore = createStore<UserStoreState>(
  {
    roles: null,
  },
  'User Roles Store',
);

export type UserStoreState = User;
