import { groupBy } from 'lodash-es';
import { WhatsNewGroupedItems, WhatsNewItem } from '../whats-new-types';

export const groupItems = (items: WhatsNewItem[]): WhatsNewGroupedItems => {
  return groupBy(items, item => toShortDate(item.createdAt));
};

const toShortDate = (date: Date) => {
  return date.toLocaleString('default', { month: 'short', year: 'numeric' });
};
